import { ListItemIcon, Stack, SxProps } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { MouseEventHandler } from 'react';

type MenuItemProps = {
  label: string;
  active?: boolean;
  iconColor?: string;
  labelColor?: string;
  labelVisible?: boolean;
  icon?: React.ReactElement;
  sx?: SxProps;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
};

export default function MenuItem({
  label,
  icon,
  active,
  iconColor = 'text.secondary',
  labelColor = 'text.primary',
  labelVisible,
  onClick,
  sx,
  onMouseEnter,
}: MenuItemProps) {
  return (
    <Stack
      direction="row"
      onMouseEnter={onMouseEnter}
      sx={{
        width: labelVisible ? '100%' : 'auto',
        minHeight: '38px',
        alignItems: 'center',
        left: 0,
        padding: '9px 12px',
        borderRadius: (theme) => theme.shape.borderRadius * 2.5,
        backgroundColor: active ? 'backgroundHover.main' : 'background.default',
        '&:hover': {
          backgroundColor: active ? 'backgroundHover.main' : 'grey.300',
          cursor: 'pointer',
        },
        ...sx,
      }}
      onClick={onClick}
    >
      {icon && (
        <ListItemIcon
          sx={{
            width: 20,
            height: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: labelVisible ? 1 : 0,
            color: iconColor,
            '& .MuiSvgIcon-root': {
              width: 20,
              height: 20,
            },
            minWidth: 'auto',
          }}
        >
          {icon}
        </ListItemIcon>
      )}
      {labelVisible && (
        <ListItemText
          sx={{
            margin: 0,
          }}
          primary={label}
          primaryTypographyProps={{
            noWrap: true,
            typography: 'body2',
            lineHeight: 1.2,
            color: active ? 'text.primary' : labelColor,
            fontSize: 15,
            fontWeight: active ? 'fontWeightBold' : 'fontWeightMedium',
          }}
          secondaryTypographyProps={{
            noWrap: true,
            component: 'span',
            typography: 'caption',
            color: 'text.disabled',
          }}
        />
      )}
    </Stack>
  );
}
