import { ListItemButton, ListItemText, Stack } from '@mui/material';
import { forwardRef } from 'react';
import { SecondarySidebarItem } from '../types';

interface Props {
  data: SecondarySidebarItem;
  isActive: boolean;
  dragRef?: React.RefObject<HTMLDivElement>;
  dataCy?: string;
}

const Item = forwardRef<HTMLDivElement, Props>(({ data, isActive, dragRef, dataCy }) => {
  const { prefix, text, subtext, onClick, sufix, key } = data;
  return (
    <ListItemButton
      selected={isActive}
      onClick={onClick}
      ref={dragRef}
      data-cy={dataCy && `${dataCy}-${key}`}
    >
      {prefix}
      <ListItemText
        primaryTypographyProps={{
          variant: 'subtitle1',
          fontWeight: 'medium',
          noWrap: true,
        }}
        secondaryTypographyProps={{
          variant: 'caption',
          noWrap: true,
        }}
        primary={text}
        secondary={subtext}
      />
      {sufix && (
        <Stack ml={1} alignItems="center">
          {sufix}
        </Stack>
      )}
    </ListItemButton>
  );
});

export default Item;
