import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseGlobalIcon } from 'src/assets/icons/workbaseIcons';
import { usePatchCurrentUserMutation } from 'src/store/api/currentUserApi';

interface Languages {
  value: string;
  label: string;
}
interface DataProps {
  language: string;
  dataCy?: string;
}

export default function LanguageChange({ language, dataCy }: DataProps) {
  const { t } = useTranslation();
  const [patchCurrentUser] = usePatchCurrentUserMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newLanguage = e.target.value;
    patchCurrentUser({ language: newLanguage });
  };

  const allLangs: Languages[] = [
    { value: 'en', label: t('generalSettings.information.languageInformation.englishOption') },
    { value: 'de', label: t('generalSettings.information.languageInformation.germanOption') },
  ];

  return (
    <Stack gap={1.5}>
      <Stack direction="row" gap={1} alignItems="center">
        <SvgIcon component={WorkbaseGlobalIcon} fontSize="small" />
        <Typography variant="body1" fontWeight="bold">
          {t('generalSettings.information.languageInformation.header')}
        </Typography>
      </Stack>
      <FormControl>
        <RadioGroup onChange={handleChange} value={language}>
          {allLangs.map((item) => (
            <FormControlLabel
              key={item.value}
              data-cy={dataCy && `${dataCy}-${item.value}-radio`}
              control={<Radio disableRipple size="small" />}
              label={item.label}
              value={item.value}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
}
