import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ActionMenu, { MenuItemType } from 'src/lib/components/atoms/action-menu';
import WorkbaseAvatar from 'src/lib/components/atoms/avatar/Avatar';
import { IconName, WorkbaseIconByName } from 'src/lib/components/atoms/icon';
import WorkbaseSwitch from 'src/lib/components/atoms/switch';
import WorkbaseDialogComponent from 'src/lib/components/molecules/dialog/Dialog';
import { useBoolean } from 'src/lib/hooks/use-boolean';
import {
  GeneralAccessType,
  ResourceType,
  shareResourceApi,
  useDeleteSharedResourceMutation,
  useLazyPermissionAfterChangeQuery,
  usePatchGeneralAccessMutation,
  usePatchSharedResourceMutation,
} from 'src/store/api/shareResourceApi';
import { ShareLevel } from 'src/lib/types/share-level';
import { sidebarApi } from 'src/store/api/sidebarApi';

function CircleIcon({
  backgroundColor,
  name,
  color,
}: {
  backgroundColor: string;
  name: IconName;
  color: string;
}) {
  return (
    <Box
      sx={{
        height: 32,
        width: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        backgroundColor,
        flexShrink: 0,
      }}
    >
      <WorkbaseIconByName iconName={name} color={color} size={16} />
    </Box>
  );
}

interface Props {
  title?: string;
  description?: string;
  avatarSrc?: string | null;
  icon?: {
    name: IconName;
    color: string;
    backgroundColor: string;
  };
  withRequiredSwitch?: boolean;
  required?: boolean;
  resourceType?: ResourceType;
  id?: string;
  resourceId?: string;
  shareLevel?: ShareLevel;
  canEditShareLevel?: boolean;
  handleModalShow?: () => void;
  handleModalHide?: () => void;
  isGeneralAccess?: boolean;
  generalAccessType?: GeneralAccessType;
}

// eslint-disable-next-line max-statements
export default function SingleAccessControlItem({
  title,
  description,
  avatarSrc,
  icon,
  withRequiredSwitch,
  required,
  id,
  resourceId,
  resourceType,
  shareLevel,
  canEditShareLevel = true,
  handleModalHide,
  handleModalShow,
  isGeneralAccess = false,
  generalAccessType,
}: Props) {
  const { t } = useTranslation();
  const [patchResource] = usePatchSharedResourceMutation();
  const [patchGeneralAccess] = usePatchGeneralAccessMutation();
  const [deleteResource] = useDeleteSharedResourceMutation();
  const [trigger, { data }] = useLazyPermissionAfterChangeQuery();
  const [shareLevelToChange, setShareLevelToChange] = useState<ShareLevel>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [shareLevelAnchorEl, setShareLevelAnchorEl] = useState<null | HTMLElement>(null);
  const shareLevelActionMenuOpen = Boolean(shareLevelAnchorEl);

  const [generalActionTypeAnchorEl, setGeneralActionTypeAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const generalActionTypeActionMenuOpen = Boolean(generalActionTypeAnchorEl);

  const permissionChangeWarningModal = useBoolean();

  const checkShareLevelAfterChange = (newShareLevel: ShareLevel) =>
    trigger({
      resourceType: resourceType as ResourceType,
      resourceId: resourceId as string,
      shareLevel: newShareLevel,
      resourceShareId: id as string,
    });

  const patchMutation = (props: {
    shareLevel?: ShareLevel;
    required?: boolean;
    type?: GeneralAccessType;
  }) => {
    if (isGeneralAccess) {
      patchGeneralAccess({
        resourceType: resourceType as ResourceType,
        resourceId: resourceId as string,
        ...props,
      });
    } else if (
      shareLevel === ShareLevel.manage &&
      props.shareLevel !== ShareLevel.manage &&
      props.shareLevel !== undefined
    ) {
      checkShareLevelAfterChange(props.shareLevel).then((resp) => {
        if (resp.data?.shareLevel === ShareLevel.manage) {
          patchResource({
            resourceType: resourceType as ResourceType,
            resourceId: resourceId as string,
            id: id as string,
            ...props,
          });
        } else {
          permissionChangeWarningModal.onTrue();
          handleModalHide?.();
          setShareLevelToChange(props.shareLevel);
        }
      });
    } else {
      patchResource({
        resourceType: resourceType as ResourceType,
        resourceId: resourceId as string,
        id: id as string,
        ...props,
      });
    }
  };

  const checkmarkIcon = 'WorkbaseCheckmarkBigIcon';

  const handleDeleteResource = () =>
    deleteResource({
      resourceType: resourceType as ResourceType,
      resourceId: resourceId as string,
      sharedWithId: id as string,
    });

  const manageDeleteOptions: MenuItemType[] = [
    {
      value: ShareLevel.manage,
      label: t('permissionsShare.manager'),
      startIcon: 'WorkbasePermissions',
      endIcon: shareLevel === ShareLevel.manage ? checkmarkIcon : undefined,
      endIconColor: 'primary.main',
      onClick: () =>
        shareLevel !== ShareLevel.manage && patchMutation({ shareLevel: ShareLevel.manage }),
      description: t('permissionsShare.managerDescription'),
    },
    {
      value: 'delete',
      label: t('permissionsShare.removeAccess'),
      startIcon: 'WorkbaseDeleteSquareIcon',
      onClick: () => {
        if (shareLevel === ShareLevel.manage) {
          checkShareLevelAfterChange(ShareLevel.none).then((resp) => {
            if (resp.data?.shareLevel === ShareLevel.manage) {
              handleDeleteResource();
            } else {
              permissionChangeWarningModal.onTrue();
              handleModalHide?.();
              setShareLevelToChange(ShareLevel.none);
            }
          });
        } else {
          handleDeleteResource();
        }
      },
      divider: true,
    },
  ];

  const shareLevelMenuItems: MenuItemType[] = [
    {
      value: ShareLevel.view,
      label: t('permissionsShare.view'),
      startIcon: 'WorkbaseEyeViewIcon',
      endIcon: shareLevel === ShareLevel.view ? checkmarkIcon : undefined,
      endIconColor: 'primary.main',
      onClick: () =>
        shareLevel !== ShareLevel.view && patchMutation({ shareLevel: ShareLevel.view }),
    },
    {
      value: ShareLevel.edit,
      label: t('permissionsShare.edit'),
      startIcon: 'WorkbaseEditIcon',
      endIcon: shareLevel === ShareLevel.edit ? checkmarkIcon : undefined,
      endIconColor: 'primary.main',
      onClick: () =>
        shareLevel !== ShareLevel.edit && patchMutation({ shareLevel: ShareLevel.edit }),
    },
    ...(isGeneralAccess ? [] : manageDeleteOptions),
  ];

  const generalAccessTypeMenuItems: MenuItemType[] = [
    {
      value: 'restricted',
      label: t('permissionsShare.restricted'),
      startIcon: 'WorkbaseLockIcon',
      endIcon: generalAccessType === 'restricted' ? checkmarkIcon : undefined,
      endIconColor: 'primary.main',
      onClick: () => patchMutation({ type: 'restricted' }),
    },
    {
      value: 'everyone',
      label: t('permissionsShare.everyone'),
      endIcon: generalAccessType === 'everyone' ? checkmarkIcon : undefined,
      startIcon: 'WorkbaseBusinessPlusIcon',
      endIconColor: 'primary.main',
      onClick: () => patchMutation({ type: 'everyone' }),
    },
  ];

  const generalAccessTypeMenu = (
    <>
      <Button
        color="inherit"
        onClick={(event) => setGeneralActionTypeAnchorEl(event.currentTarget)}
        aria-haspopup="true"
        size="small"
        endIcon="WorkbaseArrowDownIcon"
        sx={{
          alignSelf: 'flex-start',
          marginLeft: '-6px',
          '&&&&.MuiButton-root': {
            minHeight: 'auto',
            maxHeight: '22px',
            padding: '0px 6px',
            backgroundColor: generalActionTypeAnchorEl ? 'backgroundHover.main' : 'transparent',

            '& span': {
              marginLeft: '6px',
            },
          },
          '&&&&.MuiButton-root:hover': {
            backgroundColor: 'backgroundHover.main',
          },
        }}
      >
        {generalAccessTypeMenuItems.find(({ value }) => value === generalAccessType)?.label}
      </Button>
      <ActionMenu
        sx={{
          '& .MuiPaper-root': { width: 326 },
          '& .MuiList-root': {
            maxHeight: 'max-content !important',
          },
        }}
        open={generalActionTypeActionMenuOpen}
        onClose={() => setGeneralActionTypeAnchorEl(null)}
        anchorEl={generalActionTypeAnchorEl}
        items={generalAccessTypeMenuItems}
      />
    </>
  );

  let descriptionText = description;

  if (generalAccessType === 'everyone') {
    if (shareLevel === ShareLevel.edit) {
      descriptionText = t('permissionsShare.everyoneCanEditDescription');
    }
    if (shareLevel === ShareLevel.view) {
      descriptionText = t('permissionsShare.everyoneCanViewDescription');
    }
  }

  if (generalAccessType === 'restricted') {
    descriptionText = t('permissionsShare.restrictedDescription');
  }

  const canEditLevelAndRequired = isGeneralAccess ? generalAccessType === 'everyone' : true;

  let permissionChangeWarningModalContent = '';

  const contentTypeTextDict: Record<ResourceType, string> = {
    training: t('permissionsShare.permissionChangeWarningModal.training'),
    embeddedApp: t('permissionsShare.permissionChangeWarningModal.embeddedApp'),
    page: t('permissionsShare.permissionChangeWarningModal.page'),
    whiteboard: t('permissionsShare.permissionChangeWarningModal.whiteboard'),
    wikiCard: t('permissionsShare.permissionChangeWarningModal.wikiCard'),
  };

  const contentTypeText = resourceType && contentTypeTextDict[resourceType];

  if (data?.shareLevel === ShareLevel.none) {
    permissionChangeWarningModalContent = t(
      'permissionsShare.permissionChangeWarningModal.looseAccess',
      { contentType: contentTypeText }
    );
  } else if (data?.shareLevel !== ShareLevel.manage) {
    permissionChangeWarningModalContent = t(
      'permissionsShare.permissionChangeWarningModal.lowerPermission',
      { contentType: contentTypeText }
    );
  }

  return (
    <Stack
      direction="row"
      gap="10px"
      alignItems="center"
      sx={{
        '&:hover': {
          backgroundColor: 'background.default',
          m: '0 ',
          p: '8px 20px 10px 20px',
          borderRadius: (th) => th.shape.borderRadius,
        },
        m: shareLevelAnchorEl || generalActionTypeAnchorEl ? '0' : '0 20px',
        p:
          shareLevelAnchorEl || generalActionTypeAnchorEl
            ? '8px 20px 10px 20px'
            : '8px 0px 10px 0px',
        backgroundColor:
          shareLevelAnchorEl || generalActionTypeAnchorEl ? 'background.default' : 'transparent',
        borderRadius: (th) => th.shape.borderRadius,
      }}
    >
      {avatarSrc && <WorkbaseAvatar src={avatarSrc} alt={title} sx={{ width: 32, height: 32 }} />}
      {!avatarSrc && icon && <CircleIcon {...icon} />}
      <Stack sx={{ minWidth: 0 }}>
        {isGeneralAccess ? (
          generalAccessTypeMenu
        ) : (
          <Typography variant="body1" fontWeight="medium">
            {title}
          </Typography>
        )}
        <Typography color="grey[500]" variant="body2">
          {descriptionText}
        </Typography>
      </Stack>
      <Stack direction="row" sx={{ ml: 'auto' }} gap={1.5}>
        {shareLevel && canEditLevelAndRequired && (
          <Button
            color="inherit"
            onClick={
              canEditShareLevel ? (event) => setShareLevelAnchorEl(event.currentTarget) : undefined
            }
            aria-haspopup="true"
            size="small"
            sx={{
              pointerEvents: canEditShareLevel ? 'all' : 'none',
              '&&': { color: !canEditShareLevel ? 'grey.500' : 'text.secondary' },
              fontWeight: 'normal',
              '&&&&.MuiButton-root': {
                backgroundColor: shareLevelAnchorEl ? 'backgroundHover.main' : 'transparent',
              },
              '&&&&.MuiButton-root:hover': {
                backgroundColor: 'backgroundHover.main',
              },
            }}
            endIcon={canEditShareLevel ? 'WorkbaseArrowDownIcon' : undefined}
          >
            {shareLevelMenuItems.find(({ value }) => value === shareLevel)?.label}
          </Button>
        )}
        {canEditShareLevel && canEditLevelAndRequired && (
          <ActionMenu
            sx={{
              '& .MuiPaper-root': { minWidth: 326 },
              '& .MuiList-root': {
                maxHeight: 'max-content !important',
              },
            }}
            open={shareLevelActionMenuOpen}
            onClose={() => setShareLevelAnchorEl(null)}
            anchorEl={shareLevelAnchorEl}
            items={shareLevelMenuItems}
          />
        )}
        {withRequiredSwitch && (isGeneralAccess ? generalAccessType === 'everyone' : true) && (
          <>
            <Divider orientation="vertical" flexItem />
            <Stack direction="row" alignItems="center" gap={0.75} ml={1}>
              <Typography variant="body1" color="text.secondary">
                {t('required')}
              </Typography>
              <WorkbaseSwitch
                checked={required}
                onChange={() => patchMutation({ required: !required })}
              />
            </Stack>
          </>
        )}
        <WorkbaseDialogComponent
          title={t('areYouSure')}
          open={permissionChangeWarningModal.value}
          onClose={() => {
            permissionChangeWarningModal.onFalse();
            handleModalShow?.();
          }}
          confirmButton={{
            text: t('confirm'),
            type: 'error',
            onClick: () => {
              if (shareLevelToChange === ShareLevel.none) {
                handleDeleteResource().then((resp: any) => {
                  if (!resp.error) {
                    dispatch(shareResourceApi.util.invalidateTags(['ShareLevel']));
                    dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
                    navigate('/');
                  } else {
                    handleModalShow?.();
                  }
                });
              } else {
                patchResource({
                  resourceType: resourceType as ResourceType,
                  resourceId: resourceId as string,
                  id: id as string,
                  shareLevel: shareLevelToChange,
                }).then((resp: any) => {
                  if (!resp.error) {
                    dispatch(shareResourceApi.util.invalidateTags(['ShareLevel']));
                  } else {
                    handleModalShow?.();
                  }
                });
              }
            },
          }}
        >
          {permissionChangeWarningModalContent}
        </WorkbaseDialogComponent>
      </Stack>
    </Stack>
  );
}
