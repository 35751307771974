import { TabList, TabPanel, TabContext } from '@mui/lab';
import { styled, Tab } from '@mui/material';

const paddingVal = 3;
const borderWidth = 1;
const innerSpace = paddingVal + borderWidth;

const WorkbaseTabList = styled(TabList, { shouldForwardProp: (prop) => prop !== 'fullWidth' })<{
  fullWidth?: boolean;
}>`
  border-radius: ${({ theme }) => theme.shape.borderRadius * 2.5};
  border-width: ${borderWidth}px;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.grey[100]};
  padding: ${paddingVal}px;
  min-height: 0;
  background-color: #fcfcfc;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'max-content')};

  .MuiTabs-flexContainer {
    align-items: center;
    height: 100%;
    .MuiButtonBase-root {
      flex-grow: ${({ fullWidth }) => (fullWidth ? 1 : 0)};
    }
  }

  .MuiTabs-scroller {
    min-height: 30px;
  }

  .MuiTabScrollButton-root {
    display: none;
  }

  .MuiTabs-indicator {
    border-radius: ${({ theme }) => theme.shape.borderRadius - innerSpace}
    background-color: ${({ theme }) => theme.palette.primary.main};
    z-index: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    z-index: 0;
  }
`;

const WorkbaseTab = styled(Tab)`
  margin-right: 0 !important;
  height: 100%;
  z-index: 1;
  position: relative;
  color: #9b9ea5 !important;
  transition: 0.3s;
  min-height: 30px;
  padding: 4px 20px;
  &.Mui-selected {
    color: ${({ theme }) => theme.palette.common.white} !important;
  }
`;

const WorkbaseTabPanel = styled(TabPanel)`
  padding: 0;
`;

const WorkbaseTabListUnderline = styled(TabList, {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})<{
  fullWidth?: boolean;
}>`
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[100]};
  overflow: visible;
  padding: 0 16px;
  .MuiTabs-indicator {
    border-radius: ${({ theme }) => theme.shape.borderRadius * 2.5};
    bottom: -1px;
  }
  .MuiTabs-scroller {
    overflow: visible !important;
  }
  .MuiTabs-flexContainer {
    gap: 8px;
    margin-bottom: 8px;
  }
  .MuiTabs-indicator {
    transform: scale(1.25);
    height: 1.5px;
  }
`;
const WorkbaseTabUnderline = styled(Tab)`
  height: 100%;
  z-index: 1;
  position: relative;
  padding: 5px;
  margin: 0;
  min-height: 30px;
  min-width: 30px;
  color: ${({ theme }) => theme.palette.text.secondary};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  &.Mui-selected {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
`;

export {
  WorkbaseTabList,
  WorkbaseTab,
  WorkbaseTabPanel,
  WorkbaseTabListUnderline,
  WorkbaseTabUnderline,
  TabContext as WorkbaseTabContext,
};
