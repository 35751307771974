import { forwardRef, useEffect } from 'react';

interface Props {
  stream: MediaStream | null;
}

export default forwardRef<HTMLVideoElement, Props>(({ stream }, ref) => {
  useEffect(() => {
    if (ref) {
      if (typeof ref !== 'function' && ref.current) {
        // eslint-disable-next-line no-param-reassign
        ref.current.srcObject = stream;
        ref.current.play();
      }
    }
  }, [stream, ref]);
  return (
    <video
      ref={ref}
      style={{
        objectFit: 'contain',
        position: 'absolute',
        left: -4,
        top: -4,
        width: 'calc(100% + 8px)',
        height: 'calc(100% + 8px)',
      }}
    >
      <track kind="captions" src="" srcLang="en" label="English captions" />
      Your browser does not support the video tag.
    </video>
  );
});
