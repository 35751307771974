import React from 'react';
import { TableRow, TableRowProps } from '@mui/material';

interface WorkbaseTableRowProps extends TableRowProps {
  children: React.ReactNode;
}

function WorkbaseTableRow({ children, sx, ...otherProps }: WorkbaseTableRowProps) {
  return (
    <TableRow
      sx={{
        '&.MuiTableRow-hover:hover': {
          backgroundColor: (theme) => theme.palette.background.default,
        },
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </TableRow>
  );
}

export default WorkbaseTableRow;
