import { List } from '@mui/material';
import { Department } from '../../model/types';
import RolesListItem from './role/RolesListItem';

interface RolesListProps {
  department: Department;
  inputRoleRef: React.RefObject<HTMLInputElement>;
}

export default function RolesList({ department, inputRoleRef }: RolesListProps) {
  return (
    <List>
      {department.roles.map((role, idx) => (
        <RolesListItem
          idx={idx}
          department={department}
          inputRoleRef={inputRoleRef}
          role={role}
          key={role.id}
        />
      ))}
    </List>
  );
}
