import { Stack, Grid, Button } from '@mui/material';

type Buttons = {
  key: string;
  text: string;
  disabled: boolean;
};

type ActionButtonGroupProps = {
  buttons: Buttons[];
  activeButton: string;
  onClick: (value: string) => void;
};

export default function TabButtons({ buttons, activeButton, onClick }: ActionButtonGroupProps) {
  return (
    <Grid
      container
      columnSpacing={2.5}
      rowSpacing={1.25}
      px={1.5}
      pb={1.5}
      border={1}
      borderColor="'grey.300'"
      borderRadius={(theme) =>
        `${theme.shape.borderRadius * 2.5} ${theme.shape.borderRadius * 2.5} ${
          theme.shape.borderRadius * 4
        } ${theme.shape.borderRadius * 4}`
      }
      bgcolor="secondary.background"
      ml="0"
      width="100%"
      justifyContent="center"
    >
      <Stack
        gap={2.5}
        sx={{
          maxWidth: ({ breakpoints }) => breakpoints.values.lg,
          width: '100%',
          alignSelf: 'center',
          pt: '10px',
          overflow: 'auto',
          flexDirection: 'row',
        }}
      >
        {buttons.map(({ key, text, disabled }) => (
          <Grid item key={key}>
            <Button
              data-cy={`${text}-button`}
              onClick={() => onClick(key)}
              color={key === activeButton ? 'primary' : 'inherit'}
              disabled={disabled}
            >
              {text}
            </Button>
          </Grid>
        ))}
      </Stack>
    </Grid>
  );
}
