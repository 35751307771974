import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import { WorkbaseIconByName } from 'src/lib/components/atoms/icon';
import WorkbaseDialogComponent from 'src/lib/components/molecules/dialog/Dialog';
import { useBoolean } from 'src/lib/hooks/use-boolean';
import { ResourceType } from 'src/store/api/searchResourceApi';
import { showNotification } from 'src/lib/components/atoms/notification';
import { useShareLevelQuery } from 'src/store/api/shareResourceApi';
import { ShareLevel } from 'src/lib/types/share-level';
import SearchResourceTextField from './search-text-field/SearchResourceTextField';
import GeneralAccess from './GeneralAccess';
import AccessControlListGroup from './access-list/AccessControlListGroup';
import { TabValue } from './access-list/TrainingAccessControlList';

interface Props {
  name: string;
  resourceId: string;
  resourceType: ResourceType;
}

const copyToClipboard = () => {
  const url = window.location.href;
  const modifiedUrl = url.replace(/\/edit\b/, '');
  navigator.clipboard.writeText(modifiedUrl).then(() => {
    showNotification(t('linkCopiedToClipboard'), 'success');
  });
};

export default function ShareModal({ name, resourceId, resourceType }: Props) {
  const dialog = useBoolean();
  const dialogContentVisibility = useBoolean(true);
  const [activeTabButton, setActiveTabButton] = useState<TabValue>();

  const { data } = useShareLevelQuery({ resourceId, resourceType });

  if (data?.shareLevel !== ShareLevel.manage) return null;
  return (
    <>
      <Tooltip title={t('share')}>
        <IconButton
          onClick={() => {
            dialog.onTrue();
          }}
        >
          <WorkbaseIconByName iconName="WorkbaseShareIcon" />
        </IconButton>
      </Tooltip>
      <WorkbaseDialogComponent
        title={`${t('share')} "${name}"`}
        open={dialog.value}
        onClose={dialog.onFalse}
        cancelButton={false}
        sx={{
          minWidth: 0,
          opacity: dialogContentVisibility.value ? '1' : '0',
          '& .MuiDialogContent-root': { overflow: 'hidden', padding: '0 12px' },
          '.MuiPaper-root >.MuiTypography-root': { paddingBottom: '11px' },
        }}
      >
        <Stack
          gap={1.5}
          sx={{ minWidth: 536, visibility: dialogContentVisibility.value ? 'visible' : 'hidden' }}
        >
          <Stack sx={{ padding: '0 20px' }}>
            <SearchResourceTextField
              resourceId={resourceId}
              resourceType={resourceType}
              activeTabButton={activeTabButton}
            />
            <Typography variant="body1" fontWeight="bold" sx={{ pt: '12px' }}>
              {t('permissionsShare.subtitle')}
            </Typography>
          </Stack>
          <AccessControlListGroup
            activeTabButton={activeTabButton}
            setActiveTabButton={setActiveTabButton}
            resourceType={resourceType}
            resourceId={resourceId}
            handleModalHide={() => dialogContentVisibility.onFalse()}
            handleModalShow={() => dialogContentVisibility.onTrue()}
            currentShareLevel={data?.shareLevel}
          />
          <GeneralAccess resourceId={resourceId} resourceType={resourceType} />
        </Stack>
        <Stack direction="row" gap={2.5} mt={2.5}>
          <Button
            color="inherit"
            startIcon="WorkbaseCopyDuplicateIcon"
            onClick={copyToClipboard}
            sx={{ width: 172 }}
          >
            {t('permissionsShare.copyLinkButton')}
          </Button>
          <Button sx={{ flexGrow: 1 }} onClick={dialog.onFalse}>
            {t('permissionsShare.doneButton')}
          </Button>
        </Stack>
      </WorkbaseDialogComponent>
    </>
  );
}
