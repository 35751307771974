import { Box, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { WorkbaseTrainingsIcon } from 'src/assets/icons/workbaseIcons';
import { WorkbaseIcon } from 'src/lib/components/atoms/icon/WorkbaseIcon';
import { Training } from 'src/store/api/trainings/trainingsApi';
import { useTranslation } from 'react-i18next';
import SingleTraining from './SingleTraining';

interface Props {
  readOnly: boolean;
  trainings: Training[];
  selectedItems?: string[];
  selectedAllItems?: boolean;
  placeholder: ReactNode;
  columnsCount: number;
}

export default function TrainingsContent({
  readOnly,
  trainings,
  selectedAllItems,
  selectedItems = [],
  placeholder,
  columnsCount,
}: Props) {
  const { t } = useTranslation();
  const trainingsToDisplay = selectedAllItems
    ? trainings.filter(({ hasAccess }) => (readOnly ? hasAccess : true))
    : trainings.filter(
        ({ id, hasAccess }) => selectedItems.includes(id) && (readOnly ? hasAccess : true)
      );
  const isEmptyNoPermissionContent =
    trainingsToDisplay?.length === 0 && selectedItems?.length !== 0;

  const columnDict = {
    1: 1,
    2: 2,
    3: 3,
    4: 3,
  };
  const colResizeFactor = columnDict[columnsCount as keyof typeof columnDict];

  return (
    <Box
      sx={{
        borderRadius: (theme) => theme.shape.borderRadius * 2.5,
      }}
    >
      {trainingsToDisplay.length === 0 && (
        <Stack
          alignItems="center"
          sx={{
            marginTop: '12px',
            padding: '60px 0',
            backgroundColor: readOnly ? 'background.default' : 'transparent',
            borderRadius: (theme) => theme.shape.borderRadius * 2.5,
          }}
          gap={0.75}
        >
          <WorkbaseIcon icon={WorkbaseTrainingsIcon} sx={{ width: 60, height: 57 }} />
          {isEmptyNoPermissionContent ? (
            <Typography color="text.secondary">
              {t('editor.trainingsSection.noTrainingsAcessMessage')}
            </Typography>
          ) : (
            <Stack
              direction="row"
              flexWrap="wrap"
              justifyContent="center"
              gap={0.5}
              sx={{ textAlign: 'center' }}
            >
              {placeholder}
            </Stack>
          )}
        </Stack>
      )}
      <Box
        gap={2}
        sx={{
          margin: '-1px',
          display: 'grid',
          gridTemplateColumns: `repeat(${colResizeFactor}, 1fr)`,
        }}
      >
        {trainingsToDisplay.map((training) => (
          <SingleTraining key={training.id} {...training} />
        ))}
      </Box>
    </Box>
  );
}
