import axiosInstance from 'src/axios';
import { API_URL } from 'src/global-config';
import validateEmail from 'src/lib/utils/validateEmail';
import { EmailChipsValueProps } from './use-email-chips';

const validateNewEmployeesEmails = (
  newValues: EmailChipsValueProps[],
  allEmails: EmailChipsValueProps[],
  setAllEmails: (val: EmailChipsValueProps[]) => void
) => {
  const validEmails = newValues.filter((val) => validateEmail(val.label));
  const nonValidEmails = newValues.filter((val) => !validateEmail(val.label));

  const uniqueValues = Array.from(new Set(newValues.map((val) => val.label.toLowerCase()))).map(
    (label) => newValues.find((val) => val.label.toLowerCase() === label)!
  );

  if (allEmails.length < uniqueValues.length && validEmails.length > 0) {
    setAllEmails(
      uniqueValues.map((val, index) => ({
        label: val.label,
        isExists: false,
        isValid: index >= allEmails.length ? validateEmail(val.label) : val.isValid,
        isLoading: validateEmail(val.label) ? index >= allEmails.length : false,
      }))
    );

    axiosInstance
      .post(`${API_URL}/companies/users/email-check`, {
        emails: validEmails.map((val) => val.label),
      })
      .then((resp) => {
        const allValidEmails = resp.data.map((val: { email: string; exists: boolean }) => ({
          label: val.email,
          isValid: !val.exists,
          isExists: val.exists,
          isLoading: false,
        }));

        const combinedArray = uniqueValues.map((item) => {
          const validEmail = allValidEmails.find(
            (emailItem: { label: string }) =>
              emailItem.label.toLowerCase() === item.label.toLowerCase()
          );
          if (validEmail) {
            return validEmail;
          }
          const nonValidEmail = nonValidEmails.find(
            (emailItem) => emailItem.label.toLowerCase() === item.label.toLowerCase()
          );
          if (nonValidEmail) {
            return nonValidEmail;
          }
          return item;
        });

        setAllEmails(
          combinedArray.map((val) => ({
            label: val.label,
            isValid: val.isValid,
            isExists: val.isExists,
            isLoading: false,
          }))
        );
      })
      .catch(() => {});
  } else {
    setAllEmails(
      uniqueValues.map((val) => ({
        label: val.label,
        isValid: val.isValid,
        isLoading: false,
      }))
    );
  }
};

export default validateNewEmployeesEmails;
