import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export default function PortalComponent({
  children,
  elementSelector,
}: {
  children: ReactNode;
  elementSelector: string;
}) {
  const [isPortalReady, setPortalReady] = useState(false);
  useEffect(() => {
    if (document.querySelector(elementSelector)) {
      setPortalReady(true);
    }
  }, [elementSelector]);

  if (!isPortalReady) return null;
  return createPortal(children, document.querySelector(elementSelector) as HTMLElement);
}
