import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router-dom';
import { wikiApi, WikiCardType } from 'src/store/api/wikiApi.slice';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import SvgIconOrEmoji from 'src/ui-components/utils/icon/SvgIconOrEmoji';
import { ListItemIcon } from '@mui/material';
import { resetIndexes, setLastChunkIds, setSmallestIndexForce } from './wikiCards.slice';

export function getNextDivisibleBy10(number: number) {
  if (number % 10 === 0) {
    return number;
  }
  return Math.ceil(number / 10) * 10;
}

export function getEvery10thElementId<T extends { id: string }>(data: T[]) {
  const every10thElement = data
    .map((item, index) => ({ item, index }))
    .filter((_, index) => (index + 1) % 10 === 0)
    .map(({ item }) => item.id);
  return every10thElement;
}

export const resetToTop = (dispatch: Dispatch, id: string) => {
  setTimeout(() => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'instant', block: 'start' });
    dispatch(setLastChunkIds([null]));
    dispatch(resetIndexes());
  }, 500);
};
export const scrollToIndex = (dispatch: Dispatch, id: string, index: number) => {
  setTimeout(() => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'instant', block: 'start' });
    dispatch(setSmallestIndexForce(index));
  }, 500);
};

// eslint-disable-next-line max-params
export function convertData(
  data: WikiCardType[],
  t: TFunction,
  dispatch: Dispatch,
  navigate: NavigateFunction
) {
  return data.map((item, index) => ({
    key: item.id,
    text: item.name || t('wikiPage.unnamedWikiCard'),
    position: item.position,
    subtext: '',
    prefix: (
      <ListItemIcon>
        <SvgIconOrEmoji content={item.icon} fontSize="medium" />
      </ListItemIcon>
    ),
    onClick: () => {
      dispatch(setSmallestIndexForce(index));
      if (window.location.pathname.endsWith('/edit')) {
        navigate(`/wiki/${item.id}/edit`, { replace: true });
      } else {
        const element = document.getElementById(item.id);
        if (!element) {
          dispatch(
            wikiApi.endpoints.fetchWikiCards.initiate({
              startingAfter: null,
              limit: getNextDivisibleBy10(index) + 10,
            }) as unknown as AnyAction
          ).then((res: any) => {
            const ids = getEvery10thElementId(res.data);
            dispatch(setLastChunkIds([null, ...ids]));
            scrollToIndex(dispatch, item.id, index);
          });
        }
        document.getElementById(item.id)?.scrollIntoView({ behavior: 'instant', block: 'start' });
      }
    },
  }));
}
