import { Container, IconButton, Popover, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import SvgIconOrEmoji from 'src/ui-components/utils/icon/SvgIconOrEmoji';
import DebouncedSearchTextField from 'src/ui-components/custom/textfield/search-textfield/DebouncedSearchTextField';
import { usePopupState, bindTrigger, bindPopover } from 'material-ui-popup-state/hooks';
import IconSelectorTabContext from './IconSelectorTabContext';
import useEmojiData from './hooks/use-emojidata';

export interface IconOrEmojiSelectorProps {
  value: string;
  onChange: (selectedIcon: string) => void;
}

function IconOrEmojiSelector({ value, onChange }: IconOrEmojiSelectorProps) {
  const selectorState = usePopupState({ variant: 'popover', popupId: 'icon-selector' });
  const [searchVal, setSearchVal] = useState<string>('');
  const emojiData = useEmojiData();

  useEffect(() => {
    setSearchVal('');
  }, [selectorState.isOpen]);

  const handleIconOrEmojiClick = (newValue: string) => {
    onChange?.(newValue);
    selectorState.close();
  };

  const handleSearch = useCallback(
    (val: string) => {
      setSearchVal(val);
    },
    [setSearchVal]
  );

  return (
    <>
      <IconButton color="inherit" size="medium" {...bindTrigger(selectorState)}>
        <SvgIconOrEmoji content={value} />
      </IconButton>
      <Popover
        {...bindPopover(selectorState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Stack pt={2}>
          <Container maxWidth="xs">
            <DebouncedSearchTextField autoFocus fullWidth size="small" onChange={handleSearch} />
            <IconSelectorTabContext
              emojiData={emojiData}
              icon={value}
              onIconClick={handleIconOrEmojiClick}
              setSearchVal={handleSearch}
              searchVal={searchVal}
            />
          </Container>
        </Stack>
      </Popover>
    </>
  );
}

export default IconOrEmojiSelector;
