import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import ShareModal from 'src/core/share-modal/ShareModal';
import { showNotification } from 'src/lib/components/atoms/notification';
import {
  useDeleteEmbeddedAppMutation,
  useDuplicateEmbeddedAppMutation,
} from 'src/store/api/embeddedAppsApi';
import DeleteDuplicateMenu from 'src/ui-components/custom/delete-duplicate-menu';

interface Props {
  id: string;
  name?: string;
  hasManagePermission?: boolean;
  hasAddPermission?: boolean;
}

export default function EmbeddedAppActions({
  id,
  name,
  hasManagePermission = false,
  hasAddPermission,
}: Props) {
  const navigate = useNavigate();

  const [deleteEmbeddedApp] = useDeleteEmbeddedAppMutation();
  const [duplicateEmbeddedApp] = useDuplicateEmbeddedAppMutation();
  const embededAppName = name || t('embeddedApps.unnamedApp');

  return (
    <>
      {hasManagePermission ? (
        <ShareModal name={embededAppName} resourceId={id} resourceType="embeddedApp" />
      ) : null}
      <DeleteDuplicateMenu
        onDuplicate={
          hasAddPermission
            ? () => {
                duplicateEmbeddedApp(id).then((resp: any) => {
                  if (resp.error) {
                    showNotification(t('notifications.duplicateError'), 'error');
                  } else {
                    navigate(`/embedded/${resp.data.id}/edit`);
                    showNotification(t('notifications.duplicateSuccess'));
                  }
                });
              }
            : undefined
        }
        deleteDialogDescription={t('embeddedAppPage.deleteModal.content')}
        onDelete={
          hasManagePermission
            ? () => {
                deleteEmbeddedApp(id).then((resp: any) => {
                  if (resp.error) {
                    showNotification(t('notifications.deleteError'), 'error');
                  } else {
                    showNotification(t('notifications.deleteSuccess'));
                    navigate('/');
                  }
                });
              }
            : undefined
        }
      />
    </>
  );
}
