import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import WorkbaseBreadcrumbs from 'src/lib/components/molecules/breadcrumbs';
import useMaintainEditPath from 'src/lib/hooks/useMaintainEditPath';
import { useTrainingLessonQuery } from 'src/store/api/trainings/trainingLessonsApi';
import { useGetSingleTrainingQuery } from 'src/store/api/trainings/trainingsApi';
import { useModuleByIdQuery } from 'src/store/api/modulesApi';

interface Props {
  sx: SxProps<Theme>;
}

export default function LessonBreadcrumbs({ sx }: Props) {
  const { t } = useTranslation();
  const params = useParams();
  const trainingId = params.trainingId as string;
  const moduleId = params.moduleId as string;
  const lessonId = params.lessonId as string;
  const { data: trainingData } = useGetSingleTrainingQuery(trainingId);
  const { data: moduleData } = useModuleByIdQuery(moduleId);
  const { data: lessonData } = useTrainingLessonQuery(lessonId);
  const path = useMaintainEditPath();

  const data = [
    {
      key: 'training',
      label: trainingData?.name || t('trainingDetailPage.unnamedTraining'),
      to: `/trainings/${params.trainingId}${path}`,
    },
    {
      key: 'module',
      label: moduleData?.name || t('trainingDetailPage.unnamedModule'),
      to: `/trainings/${trainingId}/${moduleId}${path}`,
    },
    {
      key: 'lesson',
      label: lessonData?.name || t('modulePage.unnamedLesson'),
      to: `/trainings/${trainingId}/${moduleId}/${lessonId}${path}`,
    },
  ];

  return (
    <WorkbaseBreadcrumbs
      data={data}
      sx={{ mb: '10px', ...sx, paddingLeft: '48px', paddingRight: '48px', width: '100%' }}
    />
  );
}
