import React, { useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  InputAdornment,
  MenuItem,
  Paper,
  PaperProps,
  styled,
  Typography,
} from '@mui/material';
import {
  WorkbaseArrowDownIcon,
  WorkbaseCheckmarkBigIcon,
  WorkbaseSearchIcon,
} from 'src/assets/icons/workbaseIcons';
import { WorkbaseIcon, WorkbaseIconType } from '../../atoms/icon';
import { WorkbaseSearchTextFieldWithRightIcon } from '../../atoms/text-field';
import ItemNotFound from '../item-not-found';

interface Option {
  icon?: WorkbaseIconType;
  value: string;
  label: string;
  color?: string;
}
export interface SelectWithSearchProps {
  disabled?: boolean;
  onChange: (value: string) => void;
  value: string;
  options: Option[];
  dataCy?: string;
}

const StyledPaper = styled(Paper)`
  padding: 2px 10px;
  border-radius: ${({ theme }) => theme.shape.borderRadius * 4};
  margin-top: 3px;
`;

function PaperWithEmptyContent({ children, ...props }: PaperProps) {
  const childrenArray = React.Children.toArray(children);
  const isEmpty = childrenArray.filter((el) => el).length === 0;

  return (
    <StyledPaper {...props}>
      {isEmpty && <ItemNotFound />}
      {childrenArray}
    </StyledPaper>
  );
}

function SelectWithSearch({
  options,
  disabled,
  value,
  onChange,
  dataCy,
  ...otherProps
}: SelectWithSearchProps) {
  const [searchValue, setSearchValue] = useState('');
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    if (disabled) return;
    setSearchValue('');
  };

  const filteredOptions = useMemo(
    () =>
      options.filter((option) =>
        option.label
          .trim()
          .toLowerCase()
          .includes(searchValue?.trim().toLowerCase() || '')
      ),
    [options, searchValue]
  );

  return (
    <Autocomplete
      data-cy={dataCy}
      freeSolo
      value={options.find((option) => option.value === value)?.label}
      onChange={(_, option: any) => onChange(option.value)}
      id="free-solo-2-demo"
      disableClearable
      disabled={disabled}
      open={isOpen}
      onOpen={() => setOpen(!isOpen)}
      onClose={() => setOpen(false)}
      slotProps={{
        popper: {
          disablePortal: false,
          sx: { zIndex: 2 },
          modifiers: [
            {
              name: 'preventOverflow',
              enabled: false,
            },
            {
              name: 'flip',
              enabled: false,
            },
          ],
        },
      }}
      PaperComponent={PaperWithEmptyContent}
      options={filteredOptions}
      renderOption={(props, option) => {
        const isSelectedItem = value === option.value;
        return (
          <MenuItem
            data-cy={dataCy && `${dataCy}-menu-option`}
            {...props}
            key={option.value}
            sx={{
              width: '100%',
              pt: 1,
              pb: 1.5,
              pl: 1.5,
              pr: 1.25,
              backgroundColor: 'common.white',
              '&:hover': {
                backgroundColor: 'grey.300',
                borderRadius: (theme) => theme.shape.borderRadius * 2.5,
                '& .MuiTypography-root': {
                  fontWeight: 700,
                },
              },
              '&.MuiMenuItem-root': {
                mb: 0.25,
                borderRadius: (theme) => theme.shape.borderRadius * 2.5,
                '&.MuiAutocomplete-option[aria-selected="true"]': {
                  backgroundColor: 'common.white',
                },
                '& .MuiAutocomplete-option.Mui-focusVisible': {
                  backgroundColor: 'error.main',
                },
                '&:hover': {
                  backgroundColor: 'grey.300',
                },
              },

              color: option.color,
            }}
          >
            <Box
              sx={{
                overflow: 'hidden',
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {option.icon && (
                <WorkbaseIcon
                  icon={option.icon}
                  sx={{ width: 20, height: 20, color: 'text.secondary' }}
                />
              )}
              <Typography
                variant="body1"
                fontWeight={isSelectedItem ? 'bold' : 'medium'}
                sx={{
                  pl: option.icon ? 1 : 0,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {option.label}
              </Typography>
            </Box>
            {isSelectedItem && (
              <WorkbaseIcon
                icon={WorkbaseCheckmarkBigIcon}
                sx={{
                  width: 20,
                  height: 20,
                  color: 'primary.main',
                }}
              />
            )}
          </MenuItem>
        );
      }}
      renderInput={({ InputProps, ...params }) => (
        <WorkbaseSearchTextFieldWithRightIcon
          onClick={handleOpen}
          sx={{
            marginBottom: '3px',
            backgroundColor: disabled ? 'backgroundHover.main' : 'transparent',
            borderRadius: (theme) => theme.shape.borderRadius * 2.5,
            '&&&& input': {
              height: '18px',
              maskImage: `linear-gradient( to left, common.transparent 0%, common.white 20%, common.white 100%, common.transparent 100% )`,
              fontWeight: disabled ? 400 : 700,
            },
          }}
          onChange={(e) => setSearchValue(e.target.value)}
          autoComplete="off"
          InputProps={{
            ...InputProps,
            endAdornment: (
              <InputAdornment
                sx={{
                  cursor: !isOpen ? 'pointer' : 'default',
                }}
                position="end"
              >
                <WorkbaseIcon
                  icon={isOpen ? WorkbaseSearchIcon : WorkbaseArrowDownIcon}
                  sx={{
                    color: isOpen ? 'text.secondary' : 'primary.main',
                    width: 16,
                    height: 16,
                    '&.MuiSvgIcon-root': {
                      mt: 1.25,
                      color: ({ palette }) => palette.text.secondary,
                    },
                  }}
                />
              </InputAdornment>
            ),
          }}
          {...params}
        />
      )}
      {...otherProps}
    />
  );
}
export default SelectWithSearch;
