import { Stack } from '@mui/material';
import { useState } from 'react';
import { WorkbaseSearchTextField } from 'src/lib/components/atoms/text-field';
import { useTrainingSectionsByModuleIdQuery } from 'src/store/api/trainings/trainingSectionsApi';
import Sections from './sections/Sections';

interface Props {
  moduleId: string;
  readOnly: boolean;
  mobileView: boolean;
  onAddLessonCallback?: (id: string) => void;
}

export default function ModuleContent({
  moduleId,
  readOnly,
  mobileView,
  onAddLessonCallback,
}: Props) {
  const [searchVal, setSearchVal] = useState('');
  const { data } = useTrainingSectionsByModuleIdQuery(moduleId);

  if (!data) return null;
  return (
    <Stack sx={{ maxWidth: 1040, width: '100%', minHeight: 0 }}>
      <WorkbaseSearchTextField
        fullWidth
        value={searchVal}
        onChange={(e) => setSearchVal(e.target.value)}
        sx={{
          padding: mobileView ? '0 20px' : 0,
          paddingBottom: 2.5,
          flexGrow: 0,
          borderBottomStyle: mobileView ? 'solid' : 'none',
          borderBottomWidth: 1,
          borderBottomColor: 'grey.300',
          margin: mobileView ? '0 -20px' : 0,
          width: mobileView ? 'calc(100% + 40px)' : '100%',
        }}
      />
      <Sections
        data={data}
        mobileView={mobileView}
        searchTerm={searchVal}
        moduleId={moduleId}
        readOnly={readOnly}
        onAddLessonCallback={onAddLessonCallback}
      />
    </Stack>
  );
}
