import { Stack, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import WorkbaseAvatar from 'src/lib/components/atoms/avatar/Avatar';
import EmptyContent from './EmptyContent';

export interface DashboardHeaderProps {
  firstName: string;
  avatarUrl?: string;
  bottomContent?: React.ReactNode;
  content?: React.ReactNode;
}

const DashboardHeaderContainer = styled(Stack)`
  background-color: ${({ theme }) => theme.palette.background.default};
  border-radius: ${({ theme }) => theme.shape.borderRadius * 4};
`;

const HeaderWrapper = styled(Stack)`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius * 4};
  height: 354px;
  padding: 22px 16px 29px 16px;
`;
const ContentWrapper = styled(Stack)`
  width: 100%;
  max-width: 1248px;
  margin: 0 auto;
`;
const NewsfeedWrapper = styled(Stack)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  color: ${({ theme }) => theme.palette.text.secondary};
  border-radius: ${({ theme }) => theme.shape.borderRadius * 4};
  padding: 20px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  min-height: 241px;
`;

export default function DashboardHeader({
  firstName,
  avatarUrl,
  bottomContent,
  content,
}: DashboardHeaderProps) {
  const { t } = useTranslation();

  function getGreetingText() {
    const greetingMorning = t('dashboardPage.header.greetingMorning');
    const greetingAfternoon = t('dashboardPage.header.greetingAfternoon');
    const greetingEvening = t('dashboardPage.header.greetingEvening');

    const hour = new Date().getHours();
    if (hour < 12 && hour >= 3) {
      return greetingMorning;
    }
    if (hour < 18 && hour >= 12) {
      return greetingAfternoon;
    }
    return greetingEvening;
  }

  return (
    <DashboardHeaderContainer>
      <HeaderWrapper>
        <ContentWrapper>
          <Stack
            direction="row"
            spacing="10px"
            alignItems="center"
            sx={{
              marginBottom: '19px',
            }}
          >
            <WorkbaseAvatar
              src={avatarUrl}
              sx={{
                borderRadius: (theme) => theme.shape.borderRadius * 2.5,
                border: '1px solid',
                borderColor: 'primary.light',
                width: 38,
                height: 38,
              }}
            />
            <Typography variant="h5" sx={{ lineHeight: 1.3 }}>
              {getGreetingText()}, {firstName}
            </Typography>
          </Stack>
          <NewsfeedWrapper>
            {!content && <EmptyContent />}
            {content}
          </NewsfeedWrapper>
        </ContentWrapper>
      </HeaderWrapper>
      {bottomContent && (
        <ContentWrapper
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            pt: '10px',
            pb: '12px',
            pl: '16px',
            pr: '16px',
            maxWidth: '1280px',
          }}
        >
          {bottomContent}
        </ContentWrapper>
      )}
    </DashboardHeaderContainer>
  );
}
