import { createApi } from '@reduxjs/toolkit/query/react';
import { Role, PermissionRoleCount } from 'src/features/employees/model';
import { baseQuery } from 'src/store/api/api';

export const rolesApi = createApi({
  reducerPath: 'roles',
  tagTypes: ['Roles', 'PermissionRoleCount'],
  baseQuery,
  endpoints: (builder) => ({
    roles: builder.query<Role[], void>({
      query: () => '/roles',
      providesTags: ['Roles'],
    }),
    permissionRoleCount: builder.query<PermissionRoleCount[], void>({
      query: () => ({
        url: `/roles/permissions-count`,
      }),
      providesTags: ['PermissionRoleCount'],
    }),
  }),
});

export const { useRolesQuery, usePermissionRoleCountQuery } = rolesApi;
