import { useRef } from 'react';
import { Stack } from '@mui/material';
import useResponsiveness from './useResponsiveness';
import TopBar, { TopBarProps } from './TopBar';
import IconButtonGroup from './icon-button-group/IconButtonGroup';
import { IconButtonData } from './icon-button-group/iconButtonGroup.types';

export interface TopBarWithIconsProps extends Omit<TopBarProps, 'leftContent'> {
  icons: IconButtonData[];
  rightContent?: React.ReactNode;
}
export default function TopBarWithIcons({
  icons,
  rightContent,
  ...restOfProps
}: TopBarWithIconsProps) {
  const topBarRef = useRef(null);
  const childrenContainerRef = useRef(null);
  const dropdownItemsCount = useResponsiveness(topBarRef, childrenContainerRef, icons.length);
  return (
    <TopBar
      ref={topBarRef}
      leftContent={<IconButtonGroup data={icons} dropdownItemsCount={dropdownItemsCount} />}
      rightContent={
        <Stack direction="row" gap={1} alignItems="center" ref={childrenContainerRef}>
          {rightContent}
        </Stack>
      }
      {...restOfProps}
    />
  );
}
