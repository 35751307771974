import { CardContent, Grid } from '@mui/material';

interface Props {
  left: React.ReactNode;
  right: React.ReactNode;
}

export default function CardContentTemplate({ left, right }: Props) {
  return (
    <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          {left}
        </Grid>
        <Grid item xs={4}>
          {right}
        </Grid>
      </Grid>
    </CardContent>
  );
}
