import { ReactNode } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import EmbeddedAppWithParamId from 'src/features/embedded-app/view/EmbeddedAppWithParamId';
import Employees from 'src/features/employees';
import CompanySettings from 'src/features/company-settings';
import CustomSite from 'src/features/custom-site';
import Dashboard from 'src/features/dashboard';
import ModuleOverviewWithModeSwitch from 'src/features/module-overview/view/ModuleOverviewWithModeSwitch';
import TrainingCourse from 'src/features/training-course/view';
import TrainingsPage from 'src/features/trainings-page';
import LayoutWithOneCard from 'src/layouts/LayoutWithOneCard';
import LayoutWithTwoCards from 'src/layouts/LayoutWithTwoCards';
import ModuleOverviewView from 'src/views/module-overview';
import WhiteboardView from 'src/views/whiteboard/WhiteboardView';
import MainLayout from 'src/layouts/MainLayout';
import LessonView from 'src/views/lesson';
import Wiki from 'src/features/wiki';

interface RouteConfig {
  path: string;
  component: ReactNode;
}

const routes: RouteConfig[] = [
  {
    path: '/',
    component: (
      <LayoutWithOneCard>
        <Dashboard />
      </LayoutWithOneCard>
    ),
  },
  {
    path: '/wiki/:id/*',
    component: (
      <LayoutWithOneCard>
        <Wiki />
      </LayoutWithOneCard>
    ),
  },
  {
    path: '/wiki',
    component: (
      <LayoutWithOneCard>
        <Wiki />
      </LayoutWithOneCard>
    ),
  },
  {
    path: '/edit',
    component: (
      <LayoutWithOneCard>
        <Dashboard />
      </LayoutWithOneCard>
    ),
  },
  {
    path: '/settings/*',
    component: (
      <LayoutWithOneCard>
        <CompanySettings />
      </LayoutWithOneCard>
    ),
  },
  {
    path: 'team/*',
    component: (
      <LayoutWithOneCard>
        <Employees />
      </LayoutWithOneCard>
    ),
  },
  {
    path: 'page/:id/*',
    component: (
      <LayoutWithOneCard>
        <CustomSite />
      </LayoutWithOneCard>
    ),
  },
  {
    path: 'embedded/:id/*',
    component: (
      <LayoutWithOneCard>
        {' '}
        <EmbeddedAppWithParamId />{' '}
      </LayoutWithOneCard>
    ),
  },
  {
    path: 'whiteboard/:id/*',
    component: (
      <LayoutWithOneCard>
        <WhiteboardView />
      </LayoutWithOneCard>
    ),
  },
  {
    path: '/trainings/:trainingId/:moduleId/:sectionId/:lessonId/*',
    component: (
      <LayoutWithTwoCards
        contentLeft={<ModuleOverviewWithModeSwitch />}
        contentRight={<LessonView />}
      />
    ),
  },
  {
    path: 'trainings/:trainingId',
    component: (
      <LayoutWithOneCard>
        <TrainingCourse />
      </LayoutWithOneCard>
    ),
  },
  {
    path: 'trainings/:trainingId/edit',
    component: (
      <LayoutWithOneCard>
        <TrainingCourse />
      </LayoutWithOneCard>
    ),
  },
  {
    path: '/trainings/:trainingId/:moduleId/*',
    component: (
      <LayoutWithOneCard>
        <ModuleOverviewView />
      </LayoutWithOneCard>
    ),
  },
  {
    path: 'trainings/',
    component: (
      <LayoutWithOneCard>
        {' '}
        <TrainingsPage />
      </LayoutWithOneCard>
    ),
  },
  {
    path: 'trainings/edit',
    component: (
      <LayoutWithOneCard>
        <TrainingsPage />
      </LayoutWithOneCard>
    ),
  },
];

function AuthorizedRoutes() {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        {routes.map(({ path, component }) => (
          <Route key={path} path={path} element={component} />
        ))}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
}

export default AuthorizedRoutes;
