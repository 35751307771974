import { useRef } from 'react';
import { ListItem, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/store';
import { showNotification } from 'src/lib/components/atoms/notification';
import useVerticalOrderedDnD from 'src/lib/hooks/useVerticalOrderedDnD';
import DndPreview from 'src/lib/components/util/DndPreviewWrapper';
import DndIndicator from 'src/ui-components/custom/dnd-indicator/DndIndicator';
import { moveRoleToNewPosition } from '../../../controller/departments.slice';
import { Department, Role } from '../../../model/types';
import RoleCheckbox from './RoleCheckbox';
import SecondaryRolesAction from '../role-actions /SecondaryRolesAction';
import RoleTextfield from './RoleTextfield';

interface RolesListItemProps {
  role: Role;
  idx: number;
  department: Department;
  inputRoleRef: React.RefObject<HTMLInputElement>;
  withDragLayer?: boolean;
}

const isAdding = false;
const draggingItemType = 'editing-role-item';

export default function RolesListItem({
  role,
  idx,
  department,
  inputRoleRef,
  withDragLayer = true,
}: RolesListItemProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const draggingRef = useRef<HTMLDivElement>(null);

  const onDraggingOnlyDepRoleError = () => {
    showNotification(
      t('companySettings.sidebar.departments.removeLinkModal.moveErrorMessage'),
      'error'
    );
  };

  const onDraggingExistingDepRoleError = () => {
    showNotification(
      t('companySettings.sidebar.departments.removeLinkModal.moveExistingRoleErrorMessage'),
      'error'
    );
  };

  const { hoverState, previewRef } = useVerticalOrderedDnD({
    itemData: { id: role.id as string, index: idx },
    moveItem: ({ id: i, index: hoverIndex }) => {
      if (!isAdding && i !== role.id) {
        dispatch(
          moveRoleToNewPosition({
            moveRoleId: i,
            dropRoleId: role.id,
            order: hoverIndex + 1,
            onDraggingOnlyDepRoleError,
            onDraggingExistingDepRoleError,
          })
        );
      }
    },
    accept: draggingItemType,
    ref: draggingRef,
  });

  const dndIndicatorProps = hoverState.isOverUpperHalf ? { top: -1 } : { bottom: -1 };

  return (
    <ListItem disableGutters ref={isAdding ? undefined : draggingRef} component="div">
      {withDragLayer && (
        <DndPreview previewRef={previewRef}>
          <RolesListItem
            idx={idx}
            department={department}
            inputRoleRef={inputRoleRef}
            role={role}
            withDragLayer={false}
          />
        </DndPreview>
      )}
      {hoverState.isHovered && <DndIndicator {...dndIndicatorProps} />}
      <Stack gap={1} direction="row" width="100%">
        <RoleCheckbox role={role} />
        <RoleTextfield role={role} department={department} inputRoleRef={inputRoleRef} />
        <SecondaryRolesAction role={role} department={department} />
      </Stack>
    </ListItem>
  );
}
