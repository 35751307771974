import { IconButton, Stack } from '@mui/material';
import { WorkbaseIcon } from '../../../atoms/icon/WorkbaseIcon';
import { WorkbaseAIIcon } from '../../../../../assets/icons/workbaseIcons';

export interface AIActionsProps {
  onToggleAI: () => void;
}

export default function AIActions({ onToggleAI }: AIActionsProps) {
  return (
    <Stack direction="row" gap={1}>
      <IconButton
        color="secondary"
        onMouseDown={(e) => {
          e.preventDefault();
          onToggleAI();
        }}
      >
        <WorkbaseIcon icon={WorkbaseAIIcon} sx={{ '&&&': { width: 16, height: 16 } }} />
      </IconButton>
    </Stack>
  );
}
