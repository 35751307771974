import { useState } from 'react';
import {
  WorkbaseCopyDuplicateIcon,
  WorkbaseDeleteIcon,
  WorkbaseThreePointsIcon,
} from 'src/assets/icons/workbaseIcons';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
  Tooltip,
} from '@mui/material';
import { useConfirm } from 'material-ui-confirm';

const styles = { pointerEvents: 'all' };

interface Props {
  onDelete?: () => void;
  onDuplicate?: () => void;
  deleteDialogDescription?: string;
}

export default function DeleteDuplicateMenu({
  onDuplicate,
  onDelete,
  deleteDialogDescription,
}: Props) {
  const { t } = useTranslation();

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) =>
    setMenuAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setMenuAnchorEl(null);

  const confirm = useConfirm();

  const duplicateItem = onDuplicate
    ? [
        {
          value: 'duplicate',
          label: t('duplicate'),
          startIcon: WorkbaseCopyDuplicateIcon,
          onClick: onDuplicate,
        },
      ]
    : [];

  const deleteItem = onDelete
    ? [
        {
          value: 'delete',
          label: t('delete'),
          startIcon: WorkbaseDeleteIcon,
          onClick: () => {
            confirm({
              title: t('areYouSure'),
              description: deleteDialogDescription,
              confirmationButtonProps: {
                color: 'error',
              },
              confirmationText: t('delete'),
              cancellationText: t('cancel'),
              cancellationButtonProps: {
                children: t('cancel'),
                color: 'inherit',
              },
            }).then(() => {
              onDelete();
            });
          },
        },
      ]
    : [];

  const menuItems = [...duplicateItem, ...deleteItem];

  if (menuItems.length === 0) return null;
  if (menuItems.length === 1) {
    return (
      <Tooltip title={menuItems[0].label}>
        <IconButton
          data-cy={`${menuItems[0].value}IconButton`}
          onClick={() => {
            menuItems[0].onClick?.();
          }}
          sx={styles}
        >
          <SvgIcon component={menuItems[0].startIcon} />
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <>
      <Tooltip title={t('options')}>
        <IconButton
          data-cy="deleteDuplicateIconButton"
          onClick={(e) => {
            handleOpenMenu(e);
          }}
          sx={styles}
        >
          <SvgIcon component={WorkbaseThreePointsIcon} />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={menuAnchorEl} onClose={handleCloseMenu} open={isMenuOpen}>
        {menuItems.map((item) => (
          <MenuItem
            key={item.value}
            data-cy={`${item.value}MenuItem`}
            onClick={() => {
              item.onClick?.();
              handleCloseMenu();
            }}
          >
            <ListItemIcon>
              <SvgIcon component={item.startIcon} />
            </ListItemIcon>
            <ListItemText primary={item.label} primaryTypographyProps={{ noWrap: true }} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
