import { useBoolean } from 'src/lib/hooks/use-boolean';
import ensureHttpProtocol from 'src/lib/utils/ensureHttpProtocol';
import WorkbaseLink from 'src/lib/components/molecules/link';
import { useState } from 'react';
import { showNotification } from 'src/lib/components/atoms/notification';
import { imageUpload } from 'src/lib/utils/imageUpload';
import { useEditor } from '../../controller';
import DeleteLinkModal from './modals/DeleteLinkModal';
import AddEditLinkModal from './modals/AddEditLinkModal';

interface Props {
  url?: string;
  imageUrl?: string;
  readOnly: boolean;
  id: string;
  linkName: string;
}

function LinkEditMode({ linkName, id, url, imageUrl }: Omit<Props, 'readOnly'>) {
  const [isLoading, setIsLoading] = useState(false);
  const deleteLinkDialog = useBoolean();
  const editLinkDialog = useBoolean();
  const { handleLinkImageChange } = useEditor();

  const handleFaviconChange = async (file: File) => {
    if (!file) return;
    setIsLoading(true);

    if (file) {
      try {
        const response = await imageUpload(file);
        const { variants } = response.data.result;
        // eslint-disable-next-line
        if (id) handleLinkImageChange(id, variants[0]);
      } catch (err) {
        showNotification('Error', 'error');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleRemoveFaviconIcon = () => {
    if (id) handleLinkImageChange(id, null);
  };

  return (
    <>
      <WorkbaseLink
        id={id}
        onFaviconChange={(e: any) => handleFaviconChange(e)}
        onRemoveFavicon={handleRemoveFaviconIcon}
        isLoading={isLoading}
        href={url ? ensureHttpProtocol(url) : url}
        imageUrl={imageUrl || undefined}
        editable
        onEdit={() => editLinkDialog.onTrue()}
        onRemove={() => deleteLinkDialog.onTrue()}
        sx={{ width: '100%' }}
      >
        {linkName}
      </WorkbaseLink>
      <DeleteLinkModal
        linkName={linkName}
        open={deleteLinkDialog.value}
        onClose={() => deleteLinkDialog.onFalse()}
        id={id}
      />
      <AddEditLinkModal
        editMode
        initialName={linkName}
        initialLink={url}
        open={editLinkDialog.value}
        onClose={() => editLinkDialog.onFalse()}
        id={id}
      />
    </>
  );
}

export default function Link({ url, imageUrl, readOnly, id, linkName }: Props) {
  if (readOnly)
    return (
      <WorkbaseLink
        id={id}
        href={url ? ensureHttpProtocol(url) : url}
        imageUrl={imageUrl || undefined}
        sx={{ flexGrow: 1 }}
      >
        {linkName}
      </WorkbaseLink>
    );

  return <LinkEditMode id={id} linkName={linkName} url={url} imageUrl={imageUrl || undefined} />;
}
