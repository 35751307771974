import { ChangeEvent, useState } from 'react';
import { Alert, Box, Snackbar, Stack, Typography } from '@mui/material';
import { usePatchCurrentUserMutation } from 'src/store/api/currentUserApi';
import { useTranslation } from 'react-i18next';
import { imageUpload } from 'src/lib/utils/imageUpload';
import EditableImageUpload from 'src/ui-components/custom/image-edit/EditableImageUpload';
import EmployeeAvatar from 'src/ui-components/utils/employee-avatar/EmployeeAvatar';

export default function UploadAvatar({ text, url }: { text: string; url: string | null }) {
  const { t } = useTranslation();
  const [patchCurrentUser] = usePatchCurrentUserMutation();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const snackBarHandler = () => {
    setErrorMessage(t('validationErrorMessages.UploadImageErrorMessage'));
    setShowSnackbar(true);
  };
  const handleClose = () => {
    setShowSnackbar(false);
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        const response = await imageUpload(file);
        const { id, variants } = response.data.result;
        await patchCurrentUser({ avatarId: id, avatarUrl: variants[0] });
      } catch (err) {
        snackBarHandler();
      }
    }
  };

  const removeImage = async (): Promise<void> => {
    await patchCurrentUser({ avatarId: null, avatarUrl: '' });
  };

  return (
    <Stack gap={1.5} flexDirection="row" alignItems="center">
      <Box width={92} height={92} minWidth={92}>
        <EditableImageUpload
          src={url || ''}
          altText={text}
          placeholder={<EmployeeAvatar />}
          onChange={handleFileChange}
          onClear={removeImage}
        />
      </Box>
      <Box>
        <Typography variant="body1" fontWeight={700} lineHeight={1.2}>
          {t('generalSettings.information.profileInformation.avatarTitle')}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: '.75rem',
            color: 'grey.500',
            width: 'auto',
          }}
        >
          {t('generalSettings.information.profileInformation.avatarDescription')}
        </Typography>
      </Box>
      <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </Stack>
  );
}
