import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/store';

export const useRoleValidity = (roleName: string, roleId: string, departmentId: string) => {
  const { t } = useTranslation();
  const [roleBlured, setRoleBlured] = useState(false);

  const { departmentsDraftState } = useAppSelector((state) => state.departments);

  const isRoleUniqWithinDepartment = (deptId: string, rId: string, rName: string) => {
    const department = departmentsDraftState.find((dep) => dep.id === deptId);
    if (!department) {
      return false;
    }

    const isUnique = !department.roles.some((role) => {
      if (role.id === rId) return false;
      return role.name.toLowerCase().trim() === rName.toLowerCase().trim();
    });

    return isUnique;
  };

  const isRoleUniq = isRoleUniqWithinDepartment(departmentId, roleId, roleName);

  const isRoleEmpty = roleName.trim() === '';
  const isRoleNameValid = !isRoleEmpty && isRoleUniq;

  let validationMessage = '';
  if (isRoleEmpty || roleName.trim() === '') {
    validationMessage = t('companySettings.sidebar.departments.roleEmptyMessage');
  } else if (!isRoleUniq) {
    validationMessage = t('companySettings.sidebar.departments.roleValidationErrorMessages');
  }

  const showRoleError = roleBlured && !isRoleNameValid;

  return {
    isRoleNameValid,
    setRoleBlured,
    showRoleError,
    validationMessage,
  };
};
