import { Divider, List, Stack } from '@mui/material';
import { Fragment, useEffect, useRef } from 'react';
import useScrollOnDrag from 'src/lib/hooks/use-scroll-on-drag';
import ItemDndWrapper from './ItemDndWrapper';
import { SecondarySidebarItem } from '../types';

interface Props {
  data: SecondarySidebarItem[];
  activeKey: string;
  onMove?: ({ id, hoverIndex }: { id: string; hoverIndex: number }) => void;
  noDivider?: boolean;
  dataCy?: string;
}

export default function ItemList({ data, activeKey, onMove, noDivider = false, dataCy }: Props) {
  const scrollItemRef = useRef<HTMLDivElement>(null);
  const scrollcontainerRef = useScrollOnDrag();
  useEffect(() => {
    if (scrollItemRef.current) {
      scrollItemRef.current.scrollIntoView({
        block: 'nearest',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey, scrollItemRef.current]);
  return (
    <Stack height="100%" overflow="auto" component="div" ref={scrollcontainerRef}>
      <List>
        {data.map(
          (item, idx) =>
            !item.hide && (
              <Fragment key={item.key}>
                <ItemDndWrapper
                  scrollItemRef={scrollItemRef}
                  data={item}
                  dataCy={dataCy}
                  activeKey={activeKey}
                  index={idx}
                  onMove={
                    onMove ? ({ id, index: hoverIndex }) => onMove?.({ id, hoverIndex }) : undefined
                  }
                />
                {!onMove && !noDivider && idx !== data.length - 1 && <Divider />}
              </Fragment>
            )
        )}
      </List>
    </Stack>
  );
}
