import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ResultData, useSearchQuery } from 'src/store/api/searchApi';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Divider,
  InputAdornment,
  ListItemIcon,
  MenuItem,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import debounce from 'lodash.debounce';
import { useAppSelector } from 'src/store';
import { useNavigate } from 'react-router-dom';
import { WorkbaseRecentIcon, WorkbaseSearchIcon } from 'src/assets/icons/workbaseIcons';
import { t } from 'i18next';
import Empty from 'src/ui-components/custom/empty/Empty';
import { getNavigationRoute, removeHtmlTags } from '../controller/utils';
import SingleSearchResult from './SingleSearchResult';
import { SearchHistoryItem } from '../controller/search.slice';

const GLOBAL_SEARCH_WIDTH = 620;

export default function GlobalSearch() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const searchBoxRef = useRef<HTMLDivElement | null>(null);
  const searchHistory = useAppSelector((state) => state.searchHistory.searchHistory);
  const { data } = useSearchQuery(debouncedSearchValue, {
    skip: debouncedSearchValue.trim() === '',
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === '/') {
        const { activeElement } = document;
        const isInputFocused =
          activeElement &&
          (activeElement.tagName === 'INPUT' ||
            activeElement.tagName === 'TEXTAREA' ||
            activeElement.role === 'textbox');

        if (!isInputFocused) {
          event.preventDefault();
          // eslint-disable-next-line max-depth
          if (searchBoxRef.current) {
            const input = searchBoxRef.current;
            // eslint-disable-next-line max-depth
            if (input) {
              input.focus();
              setIsFocused(true);
              setTimeout(() => setOpen(true), 250);
            }
          }
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const debouncedSetDebouncedSearchValue = useRef(debounce(setDebouncedSearchValue, 250)).current;
  const debouncedOnChange = useCallback(
    (val: string) => {
      debouncedSetDebouncedSearchValue(val);
    },
    [debouncedSetDebouncedSearchValue]
  );

  const handleInputChange = (val: string) => {
    setSearchValue(val);
    debouncedOnChange(val);
  };

  const sortedOptions: ResultData[] = useMemo(() => {
    if (!data) return [];
    const companyUsers = data?.filter((item) => item.source === 'company_user');
    const otherItems = data?.filter((item) => item.source !== 'company_user');
    if (companyUsers.length && otherItems.length)
      return [...companyUsers, { id: 'divider', name: 'divider' } as ResultData, ...otherItems];
    if (companyUsers.length && !otherItems.length) return [...companyUsers];
    return [...otherItems];
  }, [data]);

  const renderOption = useCallback(
    (props: Record<string, any>, option: ResultData) => {
      if (option.id === 'divider') {
        // override some props to prevent it from being clickable as default autocomplete item
        return <Divider flexItem {...props} className="" onClick={() => {}} />;
      }

      if (!!data && searchValue && searchValue?.trim() !== '') {
        return <SingleSearchResult item={option} {...props} dataCy="search-result" />;
      }
      if (!searchValue)
        return (
          <MenuItem {...props} data-cy={`search-result-${option.name}`}>
            <Stack direction="row" gap={1} color="text.secondary">
              <ListItemIcon>
                <SvgIcon component={WorkbaseRecentIcon} fontSize="medium" htmlColor="inherit" />
              </ListItemIcon>

              <Typography variant="body1" color="text.secondary" noWrap>
                {removeHtmlTags(option.name as string)}
              </Typography>
            </Stack>
          </MenuItem>
        );
      return null;
    },
    [data, searchValue]
  );
  return (
    <Box width="100%" maxWidth={GLOBAL_SEARCH_WIDTH} position="relative">
      <Autocomplete
        open={isFocused && open}
        data-cy="global-search"
        selectOnFocus={false}
        inputValue={searchValue}
        clearOnBlur={false}
        blurOnSelect
        filterOptions={(x) => x}
        getOptionLabel={() => ''} // bugs when using navigate
        options={searchValue ? sortedOptions : (searchHistory as any)}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'preventOverflow',
                enabled: false,
              },
              {
                name: 'flip',
                enabled: false,
              },
            ],
          },
          paper: {
            sx: {
              '& .MuiAutocomplete-listbox': {
                maxHeight: 60 * 5,
              },
            },
          },
        }}
        noOptionsText={<Empty description={t('notFound')} />}
        onOpen={() => setTimeout(() => setOpen(true), 250)}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(_, value: SearchHistoryItem | ResultData | null) => {
          if (value) {
            const route =
              'navigateRoute' in value ? value.navigateRoute : getNavigationRoute(value);
            setTimeout(() => navigate(route, { relative: 'route' }), 0); // navigate after closing the dropdown
          }
        }}
        renderInput={(params: AutocompleteRenderInputParams) => {
          const inputProps = params.InputProps as any;
          return (
            <TextField
              {...params}
              data-cy="global-search-textfield"
              placeholder={t('searchbar.placeholder')}
              onChange={(e) => handleInputChange(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              ref={searchBoxRef}
              InputProps={{
                ...inputProps,
                startAdornment: (
                  <InputAdornment position="end">
                    <SvgIcon component={WorkbaseSearchIcon} fontSize="small" />
                  </InputAdornment>
                ),
                endAdornment: null,
              }}
            />
          );
        }}
        renderOption={renderOption}
      />
    </Box>
  );
}
