import { Components } from '@mui/material';
import { Theme } from '@mui/material/styles';

const MuiCssBaseline: Components<Theme>['MuiCssBaseline'] = {
  styleOverrides: `
    @font-face {
      font-family: 'Base';
      font-style: normal;
      font-weight: 400;
      src: url('/fonts/Base_Regular.ttf');
    }
    @font-face {
      font-family: 'Base';
      font-style: italic;
      font-weight: 400;
      src: url('/fonts/Base_Regular_Italic.ttf');
    }
    @font-face {
      font-family: 'Base';
      font-style: normal;
      font-weight: 500;
      src: url('/fonts/Base_Medium.ttf');
    }
    @font-face {
      font-family: 'Base';
      font-style: italic;
      font-weight: 500;
      src: url('/fonts/Base_Medium_Italic.ttf');
    }
    @font-face {
      font-family: 'Base';
      font-style: normal;
      font-weight: 700;
      src: url('/fonts/Base_Bold.ttf');
    }
    @font-face {
      font-family: 'Base';
      font-style: italic;
      font-weight: 700;
      src: url('/fonts/Base_Bold_Italic.ttf');
    }

    html, body, #root {
      height: 100%;
    }
  `,
};

export default MuiCssBaseline;
