import { memo } from 'react';
import { IconButton, Stack, useTheme } from '@mui/material';
import { formatDateFromTimeStamp } from 'src/lib/utils/format-time';
import { WorkbaseDeleteIcon, WorkbaseEditIcon } from 'src/assets/icons/workbaseIcons';
import { useTranslation } from 'react-i18next';
import { WorkbaseIcon } from '../../../../lib/components/atoms/icon';
import WorkbaseStatusChip from '../../../../lib/components/atoms/chip/StatusChip';
import {
  WorkbaseTableContainer,
  WorkbaseTable,
  WorkbaseTableHead,
  WorkbaseTableRow,
  WorkbaseTableCell,
  WorkbaseTableBody,
} from '../../../../lib/components/atoms/table';

interface Data {
  id: string;
  name: string;
  createdAt: string;
  expiredAt: string;
  status: 'active' | 'expired';
}

export interface ApiKeysTableProps {
  rows: Data[];
  columnNames: string[];
  onDelete?: (id: string, name: string) => void;
  onEdit?: (id: string, name: string, expiredAt: string) => void;
}

function ApiKeysTable({ rows, columnNames, onDelete, onEdit }: ApiKeysTableProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <WorkbaseTableContainer
      style={{
        height: 'auto',
        overflow: 'auto',
      }}
    >
      <WorkbaseTable aria-label="workbase table">
        <WorkbaseTableHead>
          <WorkbaseTableRow>
            {columnNames.map((column, index) => (
              <WorkbaseTableCell align={index === 0 ? 'left' : 'center'} key={column}>
                {column}
              </WorkbaseTableCell>
            ))}
          </WorkbaseTableRow>
        </WorkbaseTableHead>
        <WorkbaseTableBody>
          {rows.map((row) => (
            <WorkbaseTableRow
              hover
              key={row.id}
              sx={{
                '&:last-child .MuiTableCell-root': { borderBottom: 0 },
              }}
            >
              <WorkbaseTableCell>{row.name}</WorkbaseTableCell>
              <WorkbaseTableCell align="center">
                {formatDateFromTimeStamp(row.createdAt)}
              </WorkbaseTableCell>
              <WorkbaseTableCell
                align="center"
                sx={{
                  color: !row.expiredAt ? theme.palette.grey[500] : 'inherit',
                }}
              >
                {!row.expiredAt
                  ? t('companySettings.apiKeys.table.noExpiry')
                  : formatDateFromTimeStamp(row.expiredAt)}
              </WorkbaseTableCell>
              <WorkbaseTableCell align="center">
                <WorkbaseStatusChip
                  label={t(`companySettings.apiKeys.table.${row.status}`)}
                  status={row.status === 'active' ? 'success' : 'default'}
                />
              </WorkbaseTableCell>
              <WorkbaseTableCell>
                <Stack direction="row" justifyContent="end">
                  <IconButton
                    data-cy="delete-api-key-button"
                    onClick={() => onDelete?.(row.id, row?.name)}
                  >
                    <WorkbaseIcon
                      icon={WorkbaseDeleteIcon}
                      size={16}
                      color={theme.palette.grey[500]}
                      hoverColor={theme.palette.error.main}
                    />
                  </IconButton>
                  <IconButton
                    data-cy="edit-api-key-button"
                    aria-label="edit"
                    onClick={() => onEdit?.(row.id, row?.name, row?.expiredAt)}
                  >
                    <WorkbaseIcon
                      icon={WorkbaseEditIcon}
                      size={16}
                      hoverColor="inherit"
                      color={theme.palette.grey[500]}
                    />
                  </IconButton>
                </Stack>
              </WorkbaseTableCell>
            </WorkbaseTableRow>
          ))}
        </WorkbaseTableBody>
      </WorkbaseTable>
    </WorkbaseTableContainer>
  );
}

export default memo(ApiKeysTable);
