export default function checkIfEmoji(emoji: string) {
  const emojiRegex = /(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu;
  return emojiRegex.test(emoji);
}

export type Emoji = string & { __emojiBrand: never };

export function isEmoji(content: string): content is Emoji {
  return checkIfEmoji(content);
}
