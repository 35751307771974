import { createApi } from '@reduxjs/toolkit/query/react';
import { GetSiteRolesResponse } from 'src/features/employees/model';
import { baseQuery } from './api';

export const trainingRolesApi = createApi({
  reducerPath: 'trainingRolesApi',
  baseQuery,
  endpoints: (builder) => ({
    trainingRoles: builder.query<GetSiteRolesResponse, string>({
      query: (id) => ({
        url: `/trainings/${id}/roles`,
      }),
    }),
    patchTrainingRoles: builder.mutation<
      void,
      { id: string; roles?: string[]; selectAll?: boolean }
    >({
      query: ({ id, roles, selectAll = false }) => ({
        url: `/trainings/${id}/roles`,
        method: 'PATCH',
        body: {
          roles,
          allRoles: selectAll,
        },
      }),
      onQueryStarted: (payload, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          trainingRolesApi.util.updateQueryData('trainingRoles', payload.id, (draft) => {
            draft.roles.forEach((role) => {
              role.assignedToSite = payload.roles?.includes(role.id) ?? false;
            });

            if (payload.selectAll !== undefined) {
              draft.allRoles = payload.selectAll;
            }
          })
        );
        queryFulfilled.catch(patch.undo);
      },
    }),
  }),
});

export const { useTrainingRolesQuery, usePatchTrainingRolesMutation } = trainingRolesApi;
