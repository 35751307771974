import { Box, InputAdornment, Stack } from '@mui/material';
import debounce from 'lodash.debounce';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { WorkbaseTextField } from 'src/lib/components/atoms/text-field/TextField';
import { Training } from 'src/store/api/trainings/trainingsApi';
import { useTranslation } from 'react-i18next';
import TextFieldWithIconSelector from 'src/lib/components/molecules/text-field-with-icon-selector';
import { TrainingType } from 'src/features/editor/model';
import { TrainingModule } from 'src/store/api/trainings/trainingModulesApi';
import WorkbaseIconOrEmoji from 'src/lib/components/atoms/icon/WorkbaseIconOrEmoji';
import TrainingsContent from './TrainingsContent';
import ModulesContent from './modules/ModulesContent';

function Heading({
  value,
  onChange,
  readOnly,
  icon,
  onIconChange,
  trainingsType,
}: Omit<Props, 'trainings' | 'modules' | 'selectedItems' | 'selectedAllItems'>) {
  const { t } = useTranslation();
  const [val, setVal] = useState(value);
  const defaultIcon =
    trainingsType === 'trainings' ? 'WorkbaseTrainingsIcon' : 'WorkbaseModuleIcon';
  const [titleIcon, setTitileIcon] = useState<string>(icon);

  useEffect(() => {
    if (icon === '' || !icon) {
      setTitileIcon(defaultIcon);
    }
  }, [defaultIcon, icon]);

  useEffect(() => {
    setVal(value);
  }, [value]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(
    debounce((updatedVal) => {
      if (!readOnly) {
        onChange(updatedVal);
      }
    }, 300),
    [readOnly]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnIconChange = useCallback(
    debounce((updatedIcon) => {
      if (!readOnly) {
        onIconChange(updatedIcon);
      }
    }, 300),
    [readOnly]
  );

  return (
    <Stack direction="row" alignItems="center">
      {!readOnly && (
        <TextFieldWithIconSelector
          fullWidth
          multiline
          bordered={false}
          className="training-item-title-text-field"
          size="H4"
          value={val}
          placeholder={readOnly ? undefined : t('editor.trainingsSection.titlePlaceholder')}
          disabled={readOnly}
          onChange={(e: any) => {
            if (e.nativeEvent.inputType === 'insertLineBreak') return;
            const newVal = e.target.value;
            setVal(newVal);
            debouncedOnChange(newVal);
          }}
          changeableIconProps={{
            value: titleIcon,
            onChange: (newIcon) => {
              setTitileIcon(newIcon);
              debouncedOnIconChange(newIcon);
            },
          }}
          sx={{
            '&&& .MuiInputBase-root': {
              backgroundColor: 'transparent',
              color: 'text.primary',
              padding: '2px 0 5px 3px',
            },
            '&&& .MuiInputAdornment-root:nth-child(2)': {
              display: 'none',
            },
          }}
        />
      )}
      {readOnly && (
        <WorkbaseTextField
          className="training-item-title-text-field"
          size="H4"
          fullWidth
          multiline
          bordered={false}
          value={val}
          disabled
          sx={{
            '&& .MuiInputBase-root': {
              color: 'text.primary',
              'input, textarea': {
                WebkitTextFillColor: 'inherit !important',
              },
              paddingLeft: '10px',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ color: 'common.black' }}>
                <WorkbaseIconOrEmoji content={titleIcon} color="common.black" size={20} />
              </InputAdornment>
            ),
          }}
        />
      )}
    </Stack>
  );
}

interface Props {
  value: string;
  onChange: (val: string) => void;
  readOnly: boolean;
  trainings: Training[];
  modules: TrainingModule[];
  selectedItems?: string[];
  selectedAllItems?: boolean;
  trainingsType?: TrainingType;
  trainingId?: string;
  icon: string;
  onIconChange: (val: string) => void;
  placeholder?: ReactNode;
  columnsCount: number;
}

export default function Content({
  value,
  onChange,
  readOnly,
  trainings,
  modules,
  selectedAllItems,
  selectedItems = [],
  icon,
  onIconChange,
  trainingsType,
  placeholder,
  trainingId,
  columnsCount,
}: Props) {
  const contentsDict: Record<TrainingType, JSX.Element> = {
    trainings: (
      <TrainingsContent
        readOnly={readOnly}
        trainings={trainings}
        selectedAllItems={selectedAllItems}
        selectedItems={selectedItems}
        placeholder={placeholder}
        columnsCount={columnsCount}
      />
    ),
    modules: (
      <ModulesContent
        readOnly={readOnly}
        modules={modules}
        selectedItems={selectedItems}
        trainingId={trainingId}
        selectedAllItems={selectedAllItems}
      />
    ),
  };

  return (
    <Box
      sx={{
        minHeight: 190,
        borderColor: readOnly ? 'transparent' : 'grey.300',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: (theme) => theme.shape.borderRadius * 2.5,
      }}
    >
      <Heading
        onChange={onChange}
        readOnly={readOnly}
        value={value}
        trainingsType={trainingsType}
        icon={icon}
        onIconChange={onIconChange}
        columnsCount={columnsCount}
      />
      {trainingsType && contentsDict[trainingsType]}
    </Box>
  );
}
