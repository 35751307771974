import storage from 'redux-persist/lib/storage';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'searchHistory',
  storage,
};

export interface SearchHistoryItem {
  name: string;
  navigateRoute: string;
}
interface State {
  searchHistory: SearchHistoryItem[];
}

const initialState: State = {
  searchHistory: [],
};

export const searchHistorySlice = createSlice({
  name: 'searchHistory',
  initialState,
  reducers: {
    setSearchHistory: (state, action: PayloadAction<SearchHistoryItem>) => {
      state.searchHistory = [
        action.payload,
        ...state.searchHistory.filter(
          (item) => item.navigateRoute !== action.payload.navigateRoute
        ),
      ].slice(0, 5);
    },
  },
});

export const { setSearchHistory } = searchHistorySlice.actions;
const searchHistoryReducer = persistReducer(persistConfig, searchHistorySlice.reducer);
export default searchHistoryReducer;
