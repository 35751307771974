type ElementTypeWithHeight = 'spacer' | 'embedded' | 'file' | 'image';

export const MAX_COLUMN_VALUE = 5;

export const INITIAL_HEIGHTS: Record<ElementTypeWithHeight, number> = {
  spacer: 220,
  embedded: 450,
  file: 450,
  image: 450,
};
