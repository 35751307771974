import { Box } from '@mui/material';

interface Props {
  children: React.ReactNode;
  borderColor: string;
  borderWidth: string;
}

export default function ContentWrapper({ children, borderColor, borderWidth }: Props) {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: 662,
        minHeight: 0,
        overflow: 'hidden',
        borderRadius: (theme) => theme.shape.borderRadius * 2.5,
        borderWidth,
        borderStyle: 'solid',
        borderColor,
        backgroundColor: 'background.default',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </Box>
  );
}
