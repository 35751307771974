import { createSlice } from '@reduxjs/toolkit';
import { ShareLevel } from 'src/lib/types/share-level';

const initialState: {
  lastChunkIds: Array<string | null>;
  visibleIndexes: number[];
  smallestIndex: number;
  wasDragged: boolean;
  forceIndex: number | null;
  hasMoreElements: boolean;
  hasMoreElementsTop: boolean;
  blockChangingVisibility: boolean;
  shareLevelFocused: ShareLevel;
} = {
  visibleIndexes: [],
  smallestIndex: 0,
  wasDragged: false,
  forceIndex: null,
  lastChunkIds: new Array<string | null>(null),
  hasMoreElements: true,
  hasMoreElementsTop: false,
  blockChangingVisibility: false,
  shareLevelFocused: ShareLevel.view,
};

const wikiCards = createSlice({
  name: 'wikiCards',
  initialState,
  reducers: {
    setShareLevelFocused: (state, action) => {
      // share level of the currently focused wiki. Obrained together with wikiCards data not to fire ton shareLevel requests
      state.shareLevelFocused = action.payload;
    },
    setBlockChangingVisibility: (state, action) => {
      // using this to prevent adding visible indexes when doing element.scrollIntoView() so that sidebar doesnt flick
      state.blockChangingVisibility = action.payload;
    },
    setWasDragged: (state, action) => {
      // flags whether wikiCards were rearranged so that the "anchors" could be reset
      state.wasDragged = action.payload;
    },
    setHasMoreElements: (state, action) => {
      state.hasMoreElements = action.payload;
    },
    // adding index of the last element of the chunk to fetch the next one
    addLastChunkId: (state, action) => {
      if (state.lastChunkIds.includes(action.payload)) return;
      state.lastChunkIds.push(action.payload);
    },
    removeLastChunkId: (state, action) => {
      state.lastChunkIds = state.lastChunkIds.filter((id) => id !== action.payload);
    },
    setLastChunkIds: (state, action) => {
      state.lastChunkIds = action.payload;
    },
    resetIndexes: (state) => {
      state.smallestIndex = 0;
      state.visibleIndexes = [];
      state.wasDragged = true;
    },
    // setting focus to particular element while it is not yet visible or unreachable
    setSmallestIndexForce: (state, action) => {
      state.forceIndex = action.payload;
      if (state.visibleIndexes.includes(action.payload) || state.visibleIndexes.length === 0) {
        state.smallestIndex = action.payload;
      }
    },
    addVisibleIndex: (state, action) => {
      if (state.blockChangingVisibility) return;
      const index = action.payload;
      if (!state.visibleIndexes.includes(index)) {
        state.visibleIndexes.push(index);
        state.visibleIndexes.sort((a, b) => a - b);
      }
      if (state.forceIndex !== null && state.visibleIndexes.includes(state.forceIndex)) {
        state.smallestIndex = state.forceIndex;
      } else {
        const [smallestIndex] = state.visibleIndexes;
        state.smallestIndex = smallestIndex;
      }
    },
    removeVisibleIndex: (state, action) => {
      if (state.blockChangingVisibility) return;
      const index = action.payload;
      state.visibleIndexes = state.visibleIndexes.filter((i) => i !== index);
      if (action.payload === state.forceIndex) state.forceIndex = null;
      if (state.visibleIndexes.length === 0) {
        state.smallestIndex = 0;
        return;
      }
      if (state.forceIndex !== null && state.visibleIndexes.includes(state.forceIndex)) {
        state.smallestIndex = state.forceIndex;
        return;
      }
      const [smallestIndex] = state.visibleIndexes;
      state.smallestIndex = smallestIndex;
    },
  },
});

export const {
  addVisibleIndex,
  removeVisibleIndex,
  resetIndexes,
  setShareLevelFocused,
  setSmallestIndexForce,
  setHasMoreElements,
  removeLastChunkId,
  addLastChunkId,
  setLastChunkIds,
  setWasDragged,
  setBlockChangingVisibility,
} = wikiCards.actions;

export default wikiCards;
