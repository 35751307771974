import { Box, BoxProps } from '@mui/material';
import { Emoji } from 'src/lib/utils/checkIfEmoji';

interface WorkbaseEmojiProps extends BoxProps {
  emoji: Emoji;
  fontSize?: number | string;
}

export default function WorkbaseEmoji({
  emoji,
  fontSize = 24,
  sx,
  ...otherProps
}: WorkbaseEmojiProps) {
  return (
    <Box lineHeight={`${fontSize}px`} fontSize={fontSize} sx={sx} {...otherProps}>
      {emoji}
    </Box>
  );
}
