import { Box, ButtonBase, MenuItem, Stack } from '@mui/material';
import { useState } from 'react';
import { StyledMenu } from 'src/lib/components/atoms/action-menu/ActionMenu';
import { WorkbaseIcon } from '../../../../../atoms/icon/WorkbaseIcon';
import { WorkbaseArrowDownIcon } from '../../../../../../../assets/icons/workbaseIcons';
import useTextStylesItems from './useTextStylesItems';

interface TextStylesDropdownProps {
  menuWidth: number;
}

export default function TextStylesDropdown({ menuWidth }: TextStylesDropdownProps) {
  const [isOpen, setOpen] = useState<null | HTMLElement>(null);
  const items = useTextStylesItems();
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };
  const handleMenuItemClick = () => {
    setOpen(null);
  };
  const handleClose = () => {
    setOpen(null);
  };

  return (
    <Box>
      <ButtonBase
        onClick={handleOpenMenu}
        aria-controls="heading-menu"
        sx={{
          padding: 1,
          borderRadius: (theme) => theme.shape.borderRadius,
          fontSize: '15px',
          lineHeight: 1.2,
          fontWeight: 500,
          color: 'secondary.contrastText',
          '&:hover': { backgroundColor: 'grey.300' },
          backgroundColor: isOpen ? 'grey.300' : 'transparent',
        }}
      >
        <Stack direction="row" gap={1} alignItems="center">
          {items.find(({ active }) => active)?.placeholder || 'Text'}
          <WorkbaseIcon
            icon={WorkbaseArrowDownIcon}
            sx={{
              width: 6,
              height: 6,
              transition: 'transform 0.15s',
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        </Stack>
      </ButtonBase>
      <StyledMenu
        id="heading-menu"
        anchorEl={isOpen}
        onClose={handleClose}
        open={Boolean(isOpen)}
        sx={{
          transform: 'translateY(5px)',
          '.MuiPaper-root': { borderRadius: 0 },
        }}
      >
        {items.map(({ text, onClick, active, key }) => (
          <MenuItem
            key={key}
            sx={{
              width: menuWidth,
              '&&&': {
                fontSize: 'inherit',
                lineHeight: 'inherit',
                fontWeight: 'inherit',
                '&:hover': {
                  textDecoration: 'underline',
                  backgroundColor: (theme) => `${theme.palette.grey[100]} !important`,
                },
                '&.Mui-selected': {
                  backgroundColor: (theme) => `${theme.palette.grey[100]} !important`,
                },
              },
            }}
            selected={active}
            onClick={() => {
              handleMenuItemClick();
              if (!active) onClick();
            }}
          >
            {text}
          </MenuItem>
        ))}
      </StyledMenu>
    </Box>
  );
}
