import {
  Avatar,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  SvgIcon,
} from '@mui/material';
import { WorkbaseEmployeeIcon } from 'src/assets/icons/workbaseIcons';

interface Props {
  onClick: () => void;
  title: string;
  description?: string;
  avatarUrl?: string | null;
}

export default function SearchEmployeeResultItem({
  onClick,
  title,
  description,
  avatarUrl,
}: Props) {
  return (
    <MenuItem onClick={onClick}>
      {!avatarUrl && (
        <ListItemIcon>
          <SvgIcon component={WorkbaseEmployeeIcon} />
        </ListItemIcon>
      )}
      {avatarUrl && (
        <ListItemAvatar>
          <Avatar src={avatarUrl} />
        </ListItemAvatar>
      )}
      <ListItemText
        primary={title}
        secondary={description}
        primaryTypographyProps={{ noWrap: true }}
        secondaryTypographyProps={{ noWrap: true }}
      />
    </MenuItem>
  );
}
