import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../api';
import { trainingLessonsApi } from './trainingLessonsApi';

export interface VideoProgress {
  watchedDuration: number;
  isWatched: boolean;
}

export const videoRequiredApi = createApi({
  reducerPath: 'videoRequiredApi',
  baseQuery,
  endpoints: (builder) => ({
    patchVideoRequired: builder.mutation<
      void,
      { id: string; isRequired: boolean; lessonId: string }
    >({
      query: ({ id, isRequired }) => ({
        url: `/videos/${id}`,
        method: 'PATCH',
        body: { isRequired },
      }),
      onQueryStarted: ({ lessonId }, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(
            trainingLessonsApi.util.invalidateTags([
              { type: 'TrainingLessonCanMarkAsDone', id: lessonId },
            ])
          );
        });
      },
    }),
  }),
});

export const { usePatchVideoRequiredMutation } = videoRequiredApi;
