import { useEffect } from 'react';

function usePlayerKeyboardControls({
  seekForward,
  seekBackward,
  togglePlayPause,
  toggleMute,
  toggleFullscreen,
  isActive,
}: {
  seekForward: () => void;
  seekBackward: () => void;
  togglePlayPause: () => void;
  toggleMute: () => void;
  toggleFullscreen: () => void;
  isActive: boolean;
}) {
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      const { activeElement } = document;
      const isInputFocused =
        activeElement &&
        (activeElement.tagName === 'INPUT' ||
          activeElement.tagName === 'TEXTAREA' ||
          activeElement.getAttribute('role') === 'textbox');

      if (isInputFocused) return;

      switch (event.key) {
        case 'ArrowRight':
          event.preventDefault();
          seekForward();
          break;
        case 'ArrowLeft':
          event.preventDefault();
          seekBackward();
          break;
        case ' ':
          event.preventDefault();
          togglePlayPause();
          break;
        case 'm':
        case 'M':
          event.preventDefault();
          toggleMute();
          break;
        case 'f':
        case 'F':
          event.preventDefault();
          toggleFullscreen();
          break;
        default:
          break;
      }
    };

    if (isActive) window.addEventListener('keydown', handleKeyDown);

    return () => {
      if (isActive) window.removeEventListener('keydown', handleKeyDown);
    };
  }, [seekForward, seekBackward, togglePlayPause, toggleMute, toggleFullscreen, isActive]);
}

export default usePlayerKeyboardControls;
