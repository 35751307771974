import { useEffect, useState } from 'react';
import useElementWidth from '../../../lib/hooks/use-element-width';

const ITEM_WIDTH = 48;

export default function useResponsiveness(
  topBarRef: React.RefObject<HTMLDivElement>,
  childrenContainerRef: React.RefObject<HTMLDivElement>,
  toolItemsCount: number
): number {
  const topBarWidth = useElementWidth(topBarRef.current);
  const childrenContainerWidth = useElementWidth(childrenContainerRef.current);
  const [elementsInDropdown, setElementsInDropdown] = useState(0);

  useEffect(() => {
    const availableSpace = topBarWidth - (childrenContainerWidth || 0) - ITEM_WIDTH;
    const widthDiff = ITEM_WIDTH * toolItemsCount - availableSpace;

    const calculatedElementsInDropdown = widthDiff <= 0 ? 0 : Math.ceil(widthDiff / ITEM_WIDTH);

    if (calculatedElementsInDropdown > toolItemsCount) {
      setElementsInDropdown(toolItemsCount);
    } else {
      setElementsInDropdown(calculatedElementsInDropdown);
    }
  }, [topBarWidth, childrenContainerWidth, toolItemsCount]);

  return elementsInDropdown;
}
