import { Stack, Typography } from '@mui/material';
import { WorkbaseCircularProgress } from '../../../atoms/progress/CircularProgress';
import CardTitle from './CardTitle';

export interface CardContentProps {
  title: string;
  prefix?: string;
  description?: string;
  progress: number;
  onClick?: () => void;
  dataCy?: string;
}

export default function CardContent({
  title,
  prefix,
  description,
  progress,
  onClick,
  dataCy,
}: CardContentProps) {
  return (
    <Stack
      flexDirection="row"
      gap="10px"
      justifyContent="space-between"
      alignItems="center"
      flexGrow="1"
      sx={{ minWidth: 0 }}
    >
      <Stack
        onClick={(e) => {
          if (onClick) {
            e.preventDefault();
            e.stopPropagation();
            onClick();
          }
        }}
        sx={{ cursor: 'pointer', minWidth: 0 }}
      >
        <CardTitle title={title} prefix={prefix} data-cy={dataCy && `${dataCy}-title`} />
        <Typography
          variant="body2"
          fontWeight="medium"
          color="text.secondary"
          data-cy={dataCy && `${dataCy}-description`}
        >
          {description}
        </Typography>
      </Stack>
      <WorkbaseCircularProgress value={progress} data-cy={dataCy && `${dataCy}-progress`} />
    </Stack>
  );
}
