import { IconButton, Stack } from '@mui/material';
import { WorkbaseIcon } from '../../../atoms/icon/WorkbaseIcon';
import { WorkbaseCaptionIcon } from '../../../../../assets/icons/workbaseIcons';

export interface CaptionActionsProps {
  onAddCaption: () => void;
}

export default function CaptionActions({ onAddCaption }: CaptionActionsProps) {
  return (
    <Stack direction="row" gap={1}>
      <IconButton
        color="inherit"
        onMouseDown={(e) => {
          e.preventDefault();
          onAddCaption();
        }}
      >
        <WorkbaseIcon icon={WorkbaseCaptionIcon} sx={{ '&&&': { width: 16, height: 16 } }} />
      </IconButton>
    </Stack>
  );
}
