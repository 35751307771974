import { useEffect } from 'react';
import { useTheme } from '@mui/material';
import { useSettingsQuery } from '../store/api/settingsApi';

interface SiteInfoProviderProps {
  children: React.ReactNode;
}

export default function SiteInfoProvider({ children }: SiteInfoProviderProps) {
  const { data } = useSettingsQuery();
  const { palette } = useTheme();

  useEffect(() => {
    if (data) {
      const faviconUrl = palette.mode === 'dark' ? data.siteFaviconDarkUrl : data.siteFaviconUrl;
      if (faviconUrl) {
        const link = document.querySelector('link[rel="icon"]') || document.createElement('link');
        link.setAttribute('rel', 'icon');
        link.setAttribute('href', faviconUrl);
        document.head.appendChild(link);
      }

      document.title = data.siteName ?? 'Workbase';
    }
  }, [data, palette.mode]);

  return children;
}
