import { Department } from '../../model/types';

export const validateDepartments = (departments: Department[]) => {
  const departmentNames: string[] = [];

  for (let i = 0; i < departments.length; i += 1) {
    const department = departments[i];
    const roleNames: string[] = [];

    if (!department.name || departmentNames.includes(department.name)) {
      return false;
    }
    departmentNames.push(department.name);
    /* eslint-disable max-depth */
    for (let j = 0; j < department.roles.length; j += 1) {
      const role = department.roles[j];

      if (!role.name || roleNames.includes(role.name) || role.name.trim() === '') {
        return false;
      }

      roleNames.push(role.name);
    }
  }

  return true;
};
