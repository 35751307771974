import { Box, Stack } from '@mui/material';
import { t } from 'i18next';
import {
  WorkbaseTabContext,
  WorkbaseTabListUnderline,
  WorkbaseTabPanel,
  WorkbaseTabUnderline,
} from 'src/lib/components/molecules/tabs-slider';
import { ResourceInfo, ResourceType } from 'src/store/api/shareResourceApi';
import AccessControlList from './AccessControlList';

interface TrainingAccessControlListProps {
  resourceId: string;
  resourceType: ResourceType;
  handleModalShow: () => void;
  handleModalHide: () => void;
  currentShareLevel: string;
  activeTabButton?: TabValue;
  setActiveTabButton?: any;
}
export type TabValue = 'all' | 'required' | 'optional';

export default function TrainingAccessControlList({
  resourceId,
  resourceType,
  handleModalShow,
  handleModalHide,
  currentShareLevel,
  activeTabButton = 'all',
  setActiveTabButton,
}: TrainingAccessControlListProps) {
  const props = {
    resourceId,
    resourceType,
    handleModalShow,
    handleModalHide,
    currentShareLevel,
  };
  const filterDataRquiredfn = (resource: ResourceInfo) => !!(resource && resource.required);
  const filterDataOptionalfn = (resource: ResourceInfo) => !!(resource && !resource.required);

  const tabs: { label: string; content: JSX.Element | string; key: TabValue }[] = [
    {
      key: 'all',
      label: t('permissionsShare.all'),
      content: <AccessControlList {...props} />,
    },
    {
      key: 'required',
      label: t('permissionsShare.required'),
      content: <AccessControlList {...props} filterDataFn={filterDataRquiredfn} />,
    },
    {
      key: 'optional',
      label: t('permissionsShare.optional'),
      content: <AccessControlList {...props} filterDataFn={filterDataOptionalfn} />,
    },
  ];

  return (
    <Box>
      <WorkbaseTabContext value={activeTabButton}>
        <WorkbaseTabListUnderline
          onChange={(_, newVal) => setActiveTabButton && setActiveTabButton(newVal)}
          // TO-DO: move tab styles to WorkbaseTabListUnderline component after designes will be updated in figma
          sx={{
            '&.MuiTabs-root': {
              minHeight: '39px',
              margin: '0 20px',
              padding: '0 12px',
              '&&&& .MuiTabs-scroller': {
                overflow: 'hidden !important',
              },
              '&&&& .MuiTabs-indicator': {
                transform: 'scale(1.0) !important',
                height: '3.5px !important',
              },
              '&&& .MuiTabs-flexContainer': {
                gap: '22px',
                marginBottom: '0',
              },
              '&&& .MuiTab-root': {
                padding: '8px 16px 12px 16px',
                borderRadius: '10px 10px 0 0',
              },
            },
          }}
        >
          {tabs.map(({ label, key }) => (
            <WorkbaseTabUnderline
              key={key}
              label={label}
              value={key.toString()}
              sx={{
                fontSize: '15px',
                fontWeight: 'normal',
                '&.Mui-selected': {
                  color: 'primary.main',
                  fontWeight: 'bold',
                },
              }}
            />
          ))}
        </WorkbaseTabListUnderline>
        <Stack sx={{ mt: '14px' }}>
          {tabs.map(({ key, content }) => (
            <WorkbaseTabPanel key={key} value={key}>
              {content}
            </WorkbaseTabPanel>
          ))}
        </Stack>
      </WorkbaseTabContext>
    </Box>
  );
}
