import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Employee, EmployeePatchRequest } from '../model';

// Helper function to transform Employee to EmployeePatchRequest
export const transformToPatchRequest = (
  otherChangedData: Partial<Employee>
): Partial<EmployeePatchRequest> => {
  const { roles, ...patchRequest } = otherChangedData;

  // Initialize patchRequest with otherChangedData except roles
  const finalPatchRequest: Partial<EmployeePatchRequest> = { ...patchRequest };

  // Transform roles separately
  if (roles) {
    const roleIds = roles.map((role) => role.id);
    finalPatchRequest.roleIds = roleIds;
  }

  return finalPatchRequest;
};

interface EmployeeState {
  employee: Employee | null;
}

const initialState: EmployeeState = {
  employee: null, // Initial state with no employee selected
};

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setEmployee: (state, action: PayloadAction<Employee>) => {
      state.employee = action.payload;
    },
    updateEmployeeDetails: (state, action: PayloadAction<Partial<Employee>>) => {
      if (state.employee) {
        state.employee = { ...state.employee, ...action.payload };
      }
    },
    clearEmployee: (state) => {
      state.employee = null;
    },
  },
});

export const { setEmployee, updateEmployeeDetails, clearEmployee } = employeeSlice.actions;
export default employeeSlice;
