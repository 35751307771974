import { Divider, Stack, CircularProgress } from '@mui/material';
import { ResourceType } from 'src/store/api/searchResourceApi';
import { ResourceInfo, useSharedResourceInfoQuery } from 'src/store/api/shareResourceApi';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import { ShareLevel } from 'src/lib/types/share-level';
import SingleAccessControlItem from './SingleAccessControlItem';

interface Props {
  resourceId: string;
  resourceType: ResourceType;
  handleModalShow: () => void;
  handleModalHide: () => void;
  currentShareLevel: string;
  filterDataFn?: (resource: ResourceInfo) => boolean;
}

export default function AccessControlList({
  resourceId,
  resourceType,
  handleModalShow,
  handleModalHide,
  currentShareLevel,
  filterDataFn = () => true,
}: Props) {
  const { t } = useTranslation();
  const { data, isLoading } = useSharedResourceInfoQuery({ resourceId, resourceType });
  const { data: userData } = useCurrentUserQuery();
  const isTrainingResourceType = resourceType === 'training';

  const currentUserResource = data?.find(({ companyUser }) => userData?.id === companyUser?.id);

  const [isScrolled, setIsScrolled] = useState(false);
  const elementRef = useRef(null);
  const checkScroll = () => {
    const position = (elementRef.current as any)?.scrollTop;

    if (position > 0 && !isScrolled) {
      setIsScrolled(true);
    } else if (position === 0 && isScrolled) {
      setIsScrolled(false);
    }
  };

  return (
    <>
      <Stack
        ref={elementRef}
        sx={{
          maxHeight: 220,
          overflowY: 'auto',
          pt: '1px',
          pb: '1px',
        }}
        onScroll={checkScroll}
      >
        <SingleAccessControlItem
          title={t('permissionsShare.adminAccess')}
          description={t('permissionsShare.adminAccessDescription')}
          icon={{
            backgroundColor: 'grey.300',
            name: 'WorkbaseAccountLockIcon',
            color: 'common.black',
          }}
        />
        {currentUserResource && filterDataFn(currentUserResource) && (
          <SingleAccessControlItem
            title={`${currentUserResource?.companyUser?.firstName} ${
              currentUserResource?.companyUser?.lastName
            } (${t('permissionsShare.you')})`}
            description={currentUserResource?.companyUser?.email}
            avatarSrc={currentUserResource?.companyUser?.avatarUrl}
            icon={{
              backgroundColor: 'grey.300',
              name: 'WorkbaseEmployeeIcon',
              color: 'common.black',
            }}
            withRequiredSwitch={isTrainingResourceType}
            shareLevel={currentUserResource?.shareLevel}
            canEditShareLevel={currentShareLevel === ShareLevel.manage}
            resourceType={resourceType}
            resourceId={resourceId}
            id={currentUserResource?.id}
            required={currentUserResource.required}
          />
        )}
        {isLoading && <CircularProgress sx={{ margin: 'auto' }} />}
        {data
          ?.filter(
            (resource) =>
              (resource.companyUser ? userData?.id !== resource.companyUser.id : true) &&
              filterDataFn(resource)
          )
          .map(({ companyUser, department, role, id, required, shareLevel }) => {
            if (companyUser)
              return (
                <SingleAccessControlItem
                  key={id}
                  title={`${companyUser.firstName} ${companyUser.lastName}`}
                  description={companyUser.email}
                  avatarSrc={companyUser.avatarUrl}
                  withRequiredSwitch={isTrainingResourceType}
                  icon={{
                    backgroundColor: 'grey.300',
                    name: 'WorkbaseEmployeeIcon',
                    color: 'common.black',
                  }}
                  resourceType={resourceType}
                  id={id}
                  required={required}
                  resourceId={resourceId}
                  shareLevel={shareLevel}
                  canEditShareLevel={currentShareLevel === ShareLevel.manage}
                  handleModalHide={handleModalHide}
                  handleModalShow={handleModalShow}
                />
              );

            if (department)
              return (
                <SingleAccessControlItem
                  key={id}
                  title={`${t('permissionsShare.department')}: ${department.name}`}
                  description={`${t('permissionsShare.role', {
                    count: department.rolesCount,
                  })} – ${t('permissionsShare.member', { count: department.companyUsersCount })}`}
                  icon={{
                    backgroundColor: 'purple.light',
                    name: 'WorkbaseDepartmentsIcon',
                    color: 'purple.main',
                  }}
                  withRequiredSwitch={isTrainingResourceType}
                  resourceType={resourceType}
                  id={id}
                  required={required}
                  resourceId={resourceId}
                  shareLevel={shareLevel}
                  handleModalHide={handleModalHide}
                  canEditShareLevel={currentShareLevel === ShareLevel.manage}
                  handleModalShow={handleModalShow}
                />
              );

            if (role)
              return (
                <SingleAccessControlItem
                  key={id}
                  title={`${t('permissionsShare.roleTitle')}: ${role.name}`}
                  description={`${role.department.name} ${t('permissionsShare.department')} – ${t(
                    'permissionsShare.member',
                    { count: role.companyUsersCount }
                  )}`}
                  icon={{
                    backgroundColor: 'alternate.light',
                    name: 'WorkbaseRoleIcon',
                    color: 'info.main',
                  }}
                  withRequiredSwitch={isTrainingResourceType}
                  resourceType={resourceType}
                  id={id}
                  resourceId={resourceId}
                  shareLevel={shareLevel}
                  required={required}
                  handleModalHide={handleModalHide}
                  handleModalShow={handleModalShow}
                  canEditShareLevel={currentShareLevel === ShareLevel.manage}
                />
              );

            return null;
          })}
      </Stack>
      {data && (
        <Divider
          sx={{
            margin: resourceType === 'training' ? '0 20px' : '-12px 20px',
            visibility: isScrolled || data?.length > 3 ? 'visible' : 'hidden',
          }}
          flexItem
        />
      )}
    </>
  );
}
