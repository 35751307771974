import { useCallback, useRef, useState } from 'react';
import fixWebmDuration from 'fix-webm-duration';

export default function useScreenRecordingManager(onRecordingFinishCallback: (blob: Blob) => void) {
  const [startTime, setStartTime] = useState<number | null>(null);
  const [countdownVisible, setCountdownVisible] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isRecordingPaused, setIsRecordingPaused] = useState(false);
  const recordedChunksRef = useRef<Blob[]>([]);
  const [screenStream, setScreenStream] = useState<MediaStream | null>(null);
  const [selectedMicrophone, setSelectedMicrophone] = useState<string>('');
  const [selectedCamera, setSelectedCamera] = useState<string>('');
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  const handleStartRecording = () => {
    setCountdownVisible(true);
  };

  const onRecordedChunksCapture = useCallback((data: Blob) => {
    recordedChunksRef.current.push(data);
  }, []);

  const handleCountdownFinish = useCallback(async () => {
    setCountdownVisible(false);
    setIsRecording(true);
    setStartTime(Date.now());
    const videoStream =
      screenStream ||
      (await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: selectedCamera || undefined,
          aspectRatio: { exact: 16 / 9, ideal: 16 / 9 },
        },
      }));

    const isMicrophoneSelected = !(selectedMicrophone === '' || selectedMicrophone === 'noDevice');
    const audioStream = isMicrophoneSelected
      ? await navigator.mediaDevices.getUserMedia({
          audio: {
            deviceId: selectedMicrophone || undefined,
          },
        })
      : undefined;
    const audioStreamToAdd = (audioStream && audioStream.getAudioTracks()) || [];
    const videoTracks = videoStream.getVideoTracks();

    const combinedStream = new MediaStream([...videoTracks, ...audioStreamToAdd]);

    let mimeType = 'video/webm;codecs=vp8';
    if (!MediaRecorder.isTypeSupported(mimeType)) {
      mimeType = 'video/mp4';
    }
    if (!MediaRecorder.isTypeSupported(mimeType)) {
      mimeType = '';
    }

    mediaRecorderRef.current = new MediaRecorder(combinedStream, {
      videoBitsPerSecond: 8000000,
      mimeType,
    });

    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        onRecordedChunksCapture(event.data);
      }
    };

    mediaRecorderRef.current.start();
  }, [mediaRecorderRef, selectedMicrophone, screenStream, selectedCamera, onRecordedChunksCapture]);

  const handlePauseRecording = () => {
    if (countdownVisible) {
      setCountdownVisible(false);
    } else {
      setIsRecordingPaused(true);
      mediaRecorderRef.current?.pause();
    }
  };

  const handleResumeRecording = () => {
    setIsRecordingPaused(false);
    mediaRecorderRef.current?.resume();
  };

  const handleCompleteRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.onstop = () => {
        const duration = Date.now() - (startTime || 0);
        if (recordedChunksRef.current.length > 0) {
          const blob = new Blob(recordedChunksRef.current, { type: 'video/webm' });
          fixWebmDuration(blob, duration, onRecordingFinishCallback);
        }
      };
      mediaRecorderRef.current?.stop();
    }
    setIsRecordingPaused(false);
    setIsRecording(false);
  };

  return {
    countdownVisible,
    isRecording,
    isRecordingPaused,
    handleStartRecording,
    handleCountdownFinish,
    handlePauseRecording,
    handleResumeRecording,
    handleCompleteRecording,
    onRecordedChunksCapture,
    recordedChunksRef,
    screenStream,
    setScreenStream,
    selectedMicrophone,
    setSelectedMicrophone,
    selectedCamera,
    setSelectedCamera,
  };
}
