import { useNavigate } from 'react-router-dom';
import { Box, Card, Stack, alpha, Typography } from '@mui/material';
import WorkbaseImage from 'src/lib/components/atoms/image/Image';
import { WorkbaseCircularProgress } from 'src/lib/components/atoms/progress/CircularProgress';
import { WorkbaseIcon } from 'src/lib/components/atoms/icon/WorkbaseIcon';
import { useTranslation } from 'react-i18next';

import { WorkbaseLessonIcon } from 'src/assets/icons/workbaseIcons';
import BrandedDefaultImage, {
  DefaultImage,
} from 'src/lib/components/atoms/branded-default-image/BrandedDefaultImage';
import { useTrainingModulesProgressQuery } from 'src/store/api/trainings/trainingModulesApi';
import useSingleModuleResponsivness from './useSingleModuleResponsivness';

interface SingleModuleProps {
  name?: string;
  imageUrl?: string | null;
  readOnly?: boolean;
  id?: string;
  trainingId?: string;
  lessonsCount?: number;
  estimatedTime?: number;
  progress?: number;
  defaultImage: DefaultImage;
}
export default function SingleModule({
  name,
  imageUrl,
  id,
  trainingId,
  lessonsCount,
  estimatedTime,
  defaultImage,
  readOnly,
  progress,
}: SingleModuleProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { cardRef, isOneColVersion } = useSingleModuleResponsivness();

  const lessonsCountLabel = t('lesson', { count: lessonsCount || 0 });
  const estimatedTimeLabel = t('minute', { count: estimatedTime || 0 });

  const { data: progressData } = useTrainingModulesProgressQuery(id as string);

  const handleClick = () => {
    navigate(`/trainings/${trainingId}/${id}${readOnly ? '' : '/edit'}`, { relative: 'route' });
  };

  return (
    <Card
      ref={cardRef}
      onClick={handleClick}
      sx={{
        '&:hover img, &:hover .default-svg': {
          transform: 'scale(1.2)',
        },
        '&: hover': {
          boxShadow: (theme) => `0px 0px 4px 0px ${alpha(theme.palette.common.black, 0.05)}`,
          backgroundColor: 'secondary.background',
        },
        cursor: 'pointer',
        borderRadius: (theme) => theme.shape.borderRadius * 4,
        borderWidth: 1,
        borderColor: 'grey.300',
        borderStyle: 'solid',
        overflow: 'hidden',
        boxShadow: 'none',
        width: '100%',
      }}
    >
      <Stack
        direction={isOneColVersion ? 'column' : 'row'}
        spacing={2.5}
        sx={{
          p: '20px',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            height: '90px',
            width: '150px',
            borderRadius: (theme) => theme.shape.borderRadius * 2.5,
            overflow: 'hidden',
            flexShrink: 0,
          }}
        >
          {imageUrl && (
            <WorkbaseImage
              height={90}
              width={150}
              src={imageUrl || ''}
              alt=""
              style={{
                objectFit: 'cover',
                objectPosition: 'center',
                transition: '0.15s',
                width: '100%',
                height: '100%',
              }}
            />
          )}
          {!imageUrl && (
            <BrandedDefaultImage
              className="default-svg"
              name={defaultImage}
              sx={{ transition: '0.15s' }}
            />
          )}
        </Box>
        <Stack
          direction="row"
          sx={{
            minWidth: 0,
            width: '100%',
          }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack gap={0.75} sx={{ minWidth: 0 }}>
            <Typography variant="h5" color="text.primary" noWrap>
              {name}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <WorkbaseIcon
                icon={WorkbaseLessonIcon}
                color="common.black"
                sx={{ width: 14, height: 13.49, pr: '4px' }}
              />
              {lessonsCountLabel} – {estimatedTimeLabel}
            </Typography>
          </Stack>
          <WorkbaseCircularProgress value={progressData?.progress || progress || 0} size="big" />
        </Stack>
      </Stack>
    </Card>
  );
}
