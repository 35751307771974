import { memo, useState } from 'react';
import { Box } from '@mui/material';
import { Navbar } from 'src/features/navbar';
import WorkbaseSidebar from 'src/features/sidebar';
import { Outlet } from 'react-router-dom';
import {
  CONTENT_RIGHT_GAP,
  SIDEBAR_BOTTOM_SPACING,
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_FULL_WIDTH,
} from './layoutConstants';

function MainLayout() {
  const [isSidebarFullWidth, setSidebarFullWidth] = useState<boolean>(true);

  return (
    <Box
      gap={1}
      pr={2.5}
      pb={2.5}
      bgcolor="background.default"
      overflow="hidden"
      height="100%"
      minWidth="100%"
      width="fit-content"
      maxWidth="100%"
    >
      <Box
        position="fixed"
        height={`calc(100% - ${SIDEBAR_BOTTOM_SPACING}px)`}
        width={isSidebarFullWidth ? SIDEBAR_FULL_WIDTH : SIDEBAR_COLLAPSED_WIDTH}
        zIndex={13}
      >
        <WorkbaseSidebar isFullWidth={isSidebarFullWidth} onFullWidthChange={setSidebarFullWidth} />
      </Box>
      <Box
        position="fixed"
        zIndex={12}
        left={isSidebarFullWidth ? `${SIDEBAR_FULL_WIDTH}px` : `${SIDEBAR_COLLAPSED_WIDTH}px`}
        right={`${CONTENT_RIGHT_GAP}px`}
      >
        <Navbar />
      </Box>
      <Outlet context={{ isSidebarFullWidth, setSidebarFullWidth }} />
    </Box>
  );
}

export default memo(MainLayout);
