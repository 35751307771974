import { Button, Card, Divider, Popover, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HexColorPicker } from 'react-colorful';
import { useEffect, useState } from 'react';
import ColorBox from 'src/ui-components/custom/color-box';
import GeneratedColors from './GeneratedColors';
import CardContentTemplate from './CardContentTemplate';

export default function ColorConfiguration({
  patchSettings,
  baseColor,
}: {
  patchSettings: any;
  baseColor: string;
}) {
  const { t } = useTranslation();
  const [previewColor, setPreviewColor] = useState(baseColor);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isHex = /^#([0-9a-f]{6}){1,2}$/i;

  const handlePatchBrandingBaseColor = (brandingColor: string) => {
    patchSettings({
      brandingBaseColor: brandingColor,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
    handlePatchBrandingBaseColor(previewColor);
  };

  useEffect(() => {
    setPreviewColor(baseColor);
  }, [baseColor]);

  const open = Boolean(anchorEl);
  const id = open ? 'color-popover' : undefined;

  return (
    <Card>
      <CardContentTemplate
        left={
          <ColorBox
            height="100%"
            bgColor={isHex.test(previewColor) ? `${previewColor}` : `${baseColor}`}
          />
        }
        right={
          <Stack direction="column" gap={1} flexShrink={0}>
            <TextField
              size="small"
              data-cy="branding-hex-color"
              value={previewColor}
              label={t('companySettings.siteInformation.brandingColors.colorsTitle')}
              onChange={(e) => {
                setPreviewColor(e.target.value);
              }}
              onBlur={() => {
                handlePatchBrandingBaseColor(previewColor);
              }}
              error={!isHex.test(previewColor)}
              helperText={
                !isHex.test(previewColor) &&
                t('companySettings.siteInformation.brandingColors.hexError')
              }
            />
            <Button
              data-cy="pick-color-button"
              color="inherit"
              onClick={(event) => setAnchorEl(event.target as HTMLButtonElement)}
            >
              {t('companySettings.siteInformation.brandingColors.colorsPickerTitle')}
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <HexColorPicker
                color={previewColor}
                onChange={setPreviewColor}
                style={{ position: 'relative', cursor: 'pointer' }}
              />
            </Popover>
          </Stack>
        }
      />
      <Divider />
      <GeneratedColors color={isHex.test(previewColor) ? previewColor : baseColor} />
    </Card>
  );
}
