import checkIfEmoji from 'src/lib/utils/checkIfEmoji';
import { SvgIconProps } from '@mui/material';
import Emoji from '../../custom/emoji/Emoji';
import IconByName, { IconName } from './SvgIconByName';

export interface SvgIconOrEmojiProps extends Omit<SvgIconProps, 'component' | 'children'> {
  content: string;
}

export default function SvgIconOrEmoji({ content, fontSize, ...restOfProps }: SvgIconOrEmojiProps) {
  const isEmoji = checkIfEmoji(content as string);
  if (isEmoji) return <Emoji emoji={content} fontSize={fontSize} />;
  return <IconByName iconName={content as IconName} fontSize={fontSize} {...restOfProps} />;
}
