import { createApi } from '@reduxjs/toolkit/query/react';
import { t } from 'i18next';
import { setHasMoreElements } from 'src/features/wiki/controller/wikiCards.slice';
import { showNotification } from 'src/lib/components/atoms/notification';
import { baseQuery } from 'src/store/api/api';
import { ShareLevel } from 'src/lib/types/share-level';

export interface WikiCardType {
  id: string;
  name: string;
  icon: string;
  editorJson: any;
  shareLevel: ShareLevel;
  position: string;
}

export interface WikiFetchResponse {
  data: WikiCardType[];
  hasNextPage: boolean;
  hasPrevPage: boolean;
  length: number;
  totalCount: number;
}

export const wikiApi = createApi({
  reducerPath: 'wikiCardsApi',
  baseQuery,
  tagTypes: ['WikiSidebar', 'Wiki', 'WikiItem'],
  endpoints: (builder) => ({
    fetchWikiCards: builder.query<WikiCardType[], { startingAfter?: string | null; limit: number }>(
      {
        query: ({ startingAfter, limit }) => ({
          url: startingAfter
            ? `wiki/cards?startingAfter=${startingAfter}&limit=${limit}&fields=editorJson,shareLevel`
            : `wiki/cards?limit=${limit}&fields=editorJson,shareLevel`,
          method: 'GET',
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: 'WikiItem', id } as const)), // to be able to invalidate single item
                { type: 'Wiki', id: 'LIST' },
              ]
            : [{ type: 'Wiki', id: 'LIST' }],
        transformResponse: (response: any) => response.data,
        onQueryStarted: async ({ limit }, { dispatch, queryFulfilled }) => {
          const { data } = await queryFulfilled;
          dispatch(setHasMoreElements(data.length === limit));
        },
      }
    ),
    fetchWikiCardsSidebar: builder.query<WikiCardType[], void>({
      query: () => ({
        url: `wiki/cards?paginate=false`,
        method: 'GET',
      }),
      providesTags: ['WikiSidebar'],
      transformResponse: (response: any) => response.data,
    }),
    createWikiCard: builder.mutation<WikiCardType, Partial<WikiCardType> | void>({
      query: (newCard) => ({
        url: `wiki/cards`,
        method: 'POST',
        body: newCard,
      }),
      invalidatesTags: ['WikiItem', 'WikiSidebar'],
    }),
    duplicateWikiCard: builder.mutation<WikiCardType, string>({
      query: (id) => ({ url: `wiki/cards/${id}/duplicate`, method: 'POST' }),
      invalidatesTags: ['Wiki', 'WikiSidebar'],
    }),
    deleteWikiCard: builder.mutation<void, string>({
      query: (id) => ({
        url: `wiki/cards/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Wiki', 'WikiSidebar'],
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.catch(() => {
          showNotification(t('wikiPage.deleteError'), 'error');
        });
      },
    }),
    updateCardsOrder: builder.mutation<void, { id: string; position: string }>({
      query: ({ id, position }) => ({
        url: `wiki/cards/${id}`,
        body: { position },
        method: 'PATCH',
      }),
      invalidatesTags: ['Wiki'],
      onQueryStarted: ({ id, position }, { queryFulfilled, dispatch }) => {
        const patchResult = dispatch(
          wikiApi.util.updateQueryData('fetchWikiCardsSidebar', undefined, (draft) => {
            const card = draft.find((cardItem) => cardItem.id === id);
            if (card) {
              card.position = position;
            }
            draft.sort((a, b) => {
              if (a.position < b.position) {
                return -1;
              }
              if (a.position > b.position) {
                return 1;
              }
              return 0;
            });
          })
        );

        queryFulfilled.catch(() => {
          showNotification(t('wikiPage.updateOrderError'), 'error');
          patchResult.undo();
        });
      },
    }),
  }),
});

export const {
  useCreateWikiCardMutation,
  useFetchWikiCardsQuery,
  useDuplicateWikiCardMutation,
  useDeleteWikiCardMutation,
  useLazyFetchWikiCardsQuery,
  useFetchWikiCardsSidebarQuery,
  useUpdateCardsOrderMutation,
} = wikiApi;
