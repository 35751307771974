import { useState } from 'react';
import { Stack, Collapse, Box, alpha, Typography } from '@mui/material';

function PlaybackRateSelector({
  playbackRate,
  smallVariant,
  handlePlaybackRate,
}: {
  playbackRate: number;
  smallVariant: boolean;
  handlePlaybackRate: (rate: number) => void;
}) {
  const [iconHover, setIconHover] = useState(false);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      direction="column"
      onMouseEnter={() => setIconHover(true)}
      position="absolute"
      overflow="hidden"
      onMouseLeave={() => setIconHover(false)}
      sx={{
        backgroundColor: (theme) => alpha(theme.palette.background.default as string, 0.75),
        borderRadius: (theme) => theme.shape.borderRadius,
        width: smallVariant ? '24px' : '50px',
        right: smallVariant ? '55px' : '80px',
        bottom: smallVariant ? '16px' : '16px',
        zIndex: 4,
      }}
    >
      <Collapse in={iconHover} orientation="vertical">
        {[0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2].map((rate) => (
          <Box
            key={rate}
            sx={{
              cursor: 'pointer',
              backgroundColor:
                rate === playbackRate
                  ? (theme) => alpha(theme.palette.background.default as string, 0.75)
                  : 'transparent',
              px: '10px',
              color: 'primary.text',
              fontSize: smallVariant ? '12px' : undefined,
              textAlign: 'center',
              width: '100%',
              '&:hover': {
                backgroundColor: (theme) => theme.palette.action.hover,
              },
            }}
            onClick={() => {
              handlePlaybackRate(rate);
              setIconHover(false);
            }}
          >
            <Typography
              variant={smallVariant ? 'body2' : 'body1'}
              fontWeight="normal"
              sx={{ py: smallVariant ? '3px' : '3px', cursor: 'pointer', textAlign: 'center' }}
            >
              {rate}
            </Typography>
          </Box>
        ))}
      </Collapse>
      <Typography
        variant={smallVariant ? 'body2' : 'body1'}
        fontWeight="medium"
        sx={{ py: smallVariant ? '3px' : '6px', cursor: 'pointer' }}
      >
        {playbackRate}x
      </Typography>
    </Stack>
  );
}

export default PlaybackRateSelector;
