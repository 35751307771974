import { IconButton, Stack, SvgIcon } from '@mui/material';
import { IconItem } from 'src/lib/types/navbar';

interface Props {
  data: IconItem[];
  dataCy?: string;
}

function IconButtons({ data, dataCy }: Props) {
  return (
    <Stack direction="row" gap={2} alignItems="center">
      {data.map((item) => (
        <IconButton
          color={item.isActive ? 'primary' : 'inherit'}
          key={item.key}
          onClick={item.onClick}
          size="medium"
          data-cy={dataCy && `${dataCy}-${item.key}`}
        >
          <SvgIcon fontSize="inherit" component={item.icon.icon} />
        </IconButton>
      ))}
    </Stack>
  );
}

export default IconButtons;
