import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import BaseEditor from '../base-editor/BaseEditor';
import HighlightItem from '../highlight-item';
import { ResizeStopCallbackType } from '../highlight-item/resize/ResizableWrapper';

interface Props {
  onDelete: () => void;
  onDuplicate: () => void;
  value?: string;
  readOnly?: boolean;
  onChange?: (val: string) => void;
  onResizeStop?: ResizeStopCallbackType;
  dragging?: {
    dragRef: ConnectDragSource;
    previewRef: ConnectDragPreview;
  };
  onResizeStart?: () => void;
}

export default function TextItem({
  onDelete,
  onDuplicate,
  value,
  readOnly = false,
  onChange,
  onResizeStop,
  dragging,
  onResizeStart,
}: Props) {
  return (
    <HighlightItem
      editBarProps={{ baseActionsProps: { onDelete, onDuplicate }, textActionsProps: {} }}
      dragging={dragging}
      horizontalResize={{}}
      readOnly={readOnly}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
    >
      {({ EditBarComponent }) => (
        <BaseEditor value={value} readOnly={readOnly} onChange={onChange}>
          {EditBarComponent}
        </BaseEditor>
      )}
    </HighlightItem>
  );
}
