import { UUID } from 'uuidjs';
import { useEditor } from '../../controller';
import { ElementType, GridItem } from '../../model';

export default function useGenerateEditorTopBarItem() {
  const { handleAddItem } = useEditor();

  const generateEditorTopBarItem = ({
    key,
    tooltip,
    elementType,
    icon,
    content,
    extraProps = {},
    onClickCallback,
  }: {
    key: string;
    tooltip: string;
    elementType: ElementType;
    icon: React.ElementType;
    extraProps?: Partial<GridItem>;
    content?: string;
    onClickCallback?: () => void;
  }) => ({
    key,
    onClick: () => {
      handleAddItem({
        id: UUID.generate(),
        columnEnd: 5,
        columnStart: 1,
        elementType,
        content,
        ...extraProps,
      });
      setTimeout(() => {
        (
          document.querySelector(
            '.editor-grid > :nth-last-child(2) .MuiButtonBase-root'
          ) as HTMLButtonElement
        )?.focus();
        onClickCallback?.();
      });
    },
    tooltip,
    icon,
  });

  return generateEditorTopBarItem;
}
