import { t } from 'i18next';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import WorkbaseDialogComponent from 'src/lib/components/molecules/dialog/Dialog';
import debounce from 'lodash.debounce';
import { Stack } from '@mui/material';
import axiosInstance from 'src/axios';
import ensureHttpProtocol from 'src/lib/utils/ensureHttpProtocol';
import { WorkbaseTextField } from 'src/lib/components/atoms/text-field';
import { useEditor } from '../../../controller';

export default function AddEditLinkModal({
  open,
  onClose,
  editMode = false,
  initialName = '',
  initialLink = '',
  id,
}: {
  open: boolean;
  onClose: () => void;
  editMode?: boolean;
  initialName?: string;
  initialLink?: string;
  id?: string;
}) {
  const [name, setName] = useState(initialName);
  const [link, setLink] = useState(initialLink);
  const [isNameValid, setIsNameValid] = useState(false);
  const [nameBlurred, setNameBlurred] = useState(false);
  const dataInitializedRef = useRef(false);

  const { handleAddLink, handleEditLink } = useEditor();

  const onNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setName(e.target.value);
  };

  // TODO: improve the validation script for special links that can be used within some *BASE-1010
  const onLinkChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLink(e.target.value);
  };

  useEffect(() => {
    if (!open || dataInitializedRef.current) return;
    if (editMode) {
      setName(initialName);
      setLink(initialLink);
    } else {
      setName('');
      setLink('');
    }
    dataInitializedRef.current = true;
  }, [editMode, initialName, initialLink, open]);

  useEffect(() => {
    const valid = name.trim() !== '';
    setIsNameValid(valid);
  }, [name]);

  useEffect(() => {
    if (!open) {
      debounce(() => {
        setName('');
        setLink('');
        setNameBlurred(false);
        dataInitializedRef.current = false;
      }, 300)();
    }
  }, [open]);

  return (
    <WorkbaseDialogComponent
      title={
        editMode
          ? t('dashboardPage.header.addEditLinkModal.editTitle')
          : t('dashboardPage.header.addEditLinkModal.addTitle')
      }
      open={open}
      onClose={onClose}
      confirmButton={{
        text: editMode ? t('save') : t('add'),
        onClick: () => {
          if (link !== initialLink) {
            axiosInstance
              .post('/dashboard/links', {
                name: 'dummyName',
                roleId: '083670fc-0995-434b-a098-b606f9391c84',
                url: ensureHttpProtocol(link),
              })
              .then((resp) => {
                if (editMode && id) {
                  handleEditLink({
                    id,
                    name,
                    url: link,
                    faviconUrl: resp.data.url,
                  });
                } else {
                  handleAddLink({ name, url: link, faviconUrl: resp.data.url });
                }
              })
              .catch(() => {
                if (editMode && id) {
                  handleEditLink({ id, name, url: link, faviconUrl: null });
                } else {
                  handleAddLink({ name, url: link, faviconUrl: null });
                }
              });
          } else if (editMode && id) {
            if (name !== initialName) handleEditLink({ id, name });
          }
        },
        disabled: !isNameValid,
      }}
      sx={{
        '.MuiDialogContent-root': { overflow: 'hidden' },
      }}
    >
      <Stack gap={2} sx={{ width: 536, paddingTop: 0.5 }}>
        <WorkbaseTextField
          label={t('dashboardPage.header.addEditLinkModal.nameLabel')}
          value={name}
          onChange={onNameChange}
          onBlur={() => setNameBlurred(true)}
          error={nameBlurred && !isNameValid}
          helperText={
            nameBlurred &&
            !isNameValid &&
            t('dashboardPage.header.addEditLinkModal.nameErrorMessage')
          }
        />
        <WorkbaseTextField
          label={t('dashboardPage.header.addEditLinkModal.linkLabel')}
          value={link}
          onChange={onLinkChange}
        />
      </Stack>
    </WorkbaseDialogComponent>
  );
}
