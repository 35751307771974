import { alpha, Box, debounce, IconButton, styled, useTheme } from '@mui/material';
import ReactPlayer from 'react-player';
import { WorkbaseIcon, WorkbaseIconByName } from 'src/lib/components/atoms/icon';
import { forwardRef, useCallback, useEffect, useReducer, useState, useMemo, Ref } from 'react';
import { t } from 'i18next';
import { WorkbaseEditIcon } from 'src/assets/icons/workbaseIcons';
import { WorkbaseTextField } from 'src/lib/components/atoms/text-field';
import { OnProgressProps } from 'react-player/base';
import { INITIAL_STATE, reducer, WorkbasePlayerProps } from './Player.reducer';
import PlayerOverlay from './PlayerOverlay';
import PlayerControls from './PlayerControls';
import BigPlayIcon from './controls/BigPlayIcon';
import usePlayer from './controller/usePlayer';

const StyledPlayer = styled('div')<{ state: WorkbasePlayerProps; visible: boolean }>`
  position: relative;
  border-radius: ${({ theme }) => theme.shape.borderRadius * 2.5};
  z-index: 100;
  background-color: ${({ theme }) => theme.palette.common.black};
  video,
  .react-player__preview {
    border-radius: ${({ theme }) => theme.shape.borderRadius * 2.5};
  }

  .react-player__preview:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.1), transparent);
    border-radius: ${({ theme }) => theme.shape.borderRadius * 2.5};
  }
  // !important to override react-player inline styles
  .react-player__preview {
    background-repeat: no-repeat !important;
    background-size: contain !important;
  }

  .big-play-icon {
    opacity: ${({ visible }) => (visible ? 1 : 0)};
  }

  .video-player__controls {
    opacity: ${({ visible }) => (visible ? 1 : 0)};
  }
  .video-player__overlay-inner {
    transition: opacity 0.2s ease-in-out;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
  }
`;

function WorkbasePlayer(props: Omit<WorkbasePlayerProps, 'ref'>, ref: Ref<ReactPlayer>) {
  const theme = useTheme();
  const {
    url,
    light,
    height,
    isVideoUploaded,
    onVideoProgress,
    readOnly,
    disableFastForward,
    onTitleChange,
    title,
    watchProgress,
    required,
    fallbackPosterSrc,
    onFallbackPosterLoaded,
    newUploadPercentage,
  } = props;
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { smallVariant, titleValue, setTitleValue, wrapperRef, visible, canPlay } = usePlayer(
    title,
    url,
    dispatch
  );
  const [loadedPosterSrc, setLoadedPosterSrc] = useState<string | undefined | null>(
    fallbackPosterSrc
  );
  const [isPosterLoaded, setIsPosterLoaded] = useState(false);

  useEffect(() => {
    setTitleValue(title);
  }, [setTitleValue, title]);

  useEffect(() => {
    const loadPoster = (imageSrc: string) => {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        setLoadedPosterSrc(imageSrc);
        if (imageSrc === light) {
          setIsPosterLoaded(true);
          onFallbackPosterLoaded?.();
        }
      };
    };

    if (light !== loadedPosterSrc) {
      setIsPosterLoaded(false);
      setLoadedPosterSrc(fallbackPosterSrc || light);

      if (fallbackPosterSrc && !isPosterLoaded) {
        loadPoster(fallbackPosterSrc);
      }
      loadPoster((light as string) || '');
    }
  }, [light, fallbackPosterSrc, isPosterLoaded, loadedPosterSrc, onFallbackPosterLoaded]);

  const handlePreview = (e: Event) => {
    e.stopPropagation();
    dispatch({ type: 'PLAY' });
    dispatch({ type: 'LIGHT', payload: false });
  };

  const handlePlay = () => {
    if (state.ended) {
      dispatch({ type: 'ENDED', payload: false });
    }
    dispatch({ type: 'PLAY' });
  };

  const handleEnded = () => {
    dispatch({ type: 'ENDED', payload: true });
    onVideoProgress({
      isWatched: true,
    });
  };

  const handleProgress = (progress: OnProgressProps) => {
    if (progress.playedSeconds > watchProgress && disableFastForward) {
      const progressData = required
        ? {
            isWatched: state.progress.played === 1,
            watchedDuration: Math.ceil(progress.playedSeconds),
          }
        : { isWatched: true, watchedDuration: Math.ceil(progress.playedSeconds) };
      onVideoProgress(progressData);
    }
    dispatch({ type: 'SEEK', payload: progress });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleTitleChangeDebounced = useCallback(
    debounce((val) => {
      onTitleChange?.(val || '');
    }, 300),
    [onTitleChange]
  );

  const onReady = useCallback(
    (duration: number) => {
      const playerElementRef = ref as React.RefObject<ReactPlayer>;
      if (isVideoUploaded && watchProgress < duration)
        playerElementRef.current?.seekTo(watchProgress as number, 'seconds');
    },
    [ref, watchProgress, isVideoUploaded]
  );

  const editTitleComponent = useMemo(
    () => (
      <WorkbaseTextField
        backgroundColor={alpha(theme.palette.primary.dark as string, 0.9)}
        backgroundBlur={2}
        autoComplete="off"
        size={!smallVariant ? 'H2' : 'small'}
        placeholder={t('editor.videoItem.titlePlaceholder')}
        InputProps={{
          endAdornment: (
            <WorkbaseIcon
              icon={WorkbaseEditIcon}
              sx={{ height: smallVariant ? 8 : 16, width: smallVariant ? 8 : 16 }}
            />
          ),
        }}
        value={titleValue}
        onChange={(e) => {
          handleTitleChangeDebounced(e.target.value);
          setTitleValue(e.target.value);
        }}
        bordered={false}
        sx={{
          top: '10px',
          left: '10px',
          position: 'absolute',
          zIndex: 2,
          '.MuiInputBase-root': {
            height: smallVariant ? '23px' : 'inherit',

            '& input': {
              paddingLeft: smallVariant ? '15px' : '20px',
            },
          },
        }}
      />
    ),
    [
      titleValue,
      smallVariant,
      handleTitleChangeDebounced,
      theme.palette.primary.dark,
      setTitleValue,
    ]
  );
  return (
    <StyledPlayer
      state={state}
      ref={wrapperRef}
      visible={visible || !state.playing}
      tabIndex={0}
      onFocus={() => {
        dispatch({ type: 'ACTIVE', payload: true });
      }}
      onBlur={() => dispatch({ type: 'ACTIVE', payload: false })}
    >
      {!state.light && (
        <BigPlayIcon
          onClick={() => dispatch({ type: 'TOGGLE_PLAY' })}
          isPlaying={state.playing}
          smallVariant={smallVariant}
        />
      )}
      {!readOnly && editTitleComponent}
      <PlayerOverlay
        state={state}
        newUploadPercentage={newUploadPercentage}
        isVideoUploaded={isVideoUploaded}
        title={title}
        readOnly={readOnly}
      />
      <ReactPlayer
        className="editor-vertical-resizable"
        ref={ref}
        url={url}
        width="100%"
        height={state.fullscreen ? '100%' : height}
        // @ts-ignore doesnt capture thumbnail for preview otherwise
        light={state.light && loadedPosterSrc}
        playIcon={
          <Box
            height={smallVariant ? 50 : 100}
            width={smallVariant ? 50 : 100}
            borderRadius="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ backgroundColor: alpha(theme.palette.primary.main as string, 0.75) }}
          >
            <IconButton
              size={smallVariant ? 'medium' : 'large'}
              onClick={canPlay ? handlePlay : undefined}
            >
              <WorkbaseIconByName
                iconName={canPlay ? 'WorkbasePlayIcon' : 'WorkbaseErrorFilledIcon'}
                color="white.main"
              />
            </IconButton>
          </Box>
        }
        loop={state.loop}
        muted={state.muted}
        controls={state.controls}
        playing={state.playing}
        playbackRate={state.playbackRate}
        volume={state.volume}
        onPlay={handlePlay}
        onEnded={handleEnded}
        onPause={() => dispatch({ type: 'PAUSE' })}
        onDuration={(duration) => {
          dispatch({ type: 'DURATION', payload: duration });
          onReady(duration);
        }}
        onProgress={handleProgress}
        onClick={
          canPlay
            ? () => {
                dispatch({ type: 'TOGGLE_PLAY' });
              }
            : undefined
        }
        progressInterval={75}
        onClickPreview={handlePreview}
      />
      {!state.controls && !state.light && (
        <PlayerControls
          smallVariant={smallVariant}
          readOnly={readOnly}
          disableFastForward={disableFastForward}
          watchProgress={watchProgress}
          state={state}
          dispatch={dispatch}
          playerRef={ref as React.RefObject<ReactPlayer>}
          wrapperRef={wrapperRef}
        />
      )}
    </StyledPlayer>
  );
}

export default forwardRef<ReactPlayer, Omit<WorkbasePlayerProps, 'ref'>>(WorkbasePlayer);
