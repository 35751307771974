import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { IconButton, Popper, styled } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { DateValidationError } from '@mui/x-date-pickers';
import { WorkbaseDateIcon } from 'src/assets/icons/workbaseIcons';
import { WorkbaseTextField } from '../../atoms/text-field';

export interface DatepickerProps {
  label?: string;
  fullWidth?: boolean;
  placeholder?: string;
  disabled?: boolean;
  disablePast?: boolean;
  shouldDisableDate?: (day: Dayjs) => boolean;
  onError?: (error: DateValidationError, value: Dayjs | null) => void;
  defaultValue?: dayjs.Dayjs | null;
  value?: dayjs.Dayjs | null;
  name?: string;
  onChange?: (value: Dayjs | null) => void;
}

interface DatePickerInputProps {
  fullWidth?: boolean;
  placeholder?: string;
}

function DatePickerInput({ fullWidth, placeholder, ...props }: DatePickerInputProps) {
  return (
    <WorkbaseTextField
      {...props}
      fullWidth={fullWidth}
      placeholder={placeholder}
      dataCy="data-picker-textfield"
    />
  );
}

const StyledDatePickerPopper = styled(Popper)`
  &&&&& .MuiPickersPopper-paper {
    border-radius: ${({ theme }) => theme.shape.borderRadius * 2.5};
    border: ${({ theme }) => `1px solid ${theme.palette.grey[100]}`};
  }
`;

const StyledOpenPickerIcon = styled(WorkbaseDateIcon)(({ theme }) => ({
  '&:hover': {
    svg: {
      color: theme.palette.primary.main,
    },
  },
}));

const StyledOpenPickerButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
  },
}));

const StyledButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
  },
}));

export default function Datepicker({
  label,
  fullWidth,
  placeholder,
  disabled,
  defaultValue,
  disablePast,
  shouldDisableDate,
  onError,
  value,
  name,
  onChange,
}: DatepickerProps) {
  return (
    <DatePicker
      name={name}
      format="DD.MM.YYYY"
      label={label}
      onChange={onChange}
      value={value}
      defaultValue={defaultValue}
      disablePast={disablePast}
      shouldDisableDate={shouldDisableDate}
      disabled={disabled}
      onError={onError}
      slots={{
        openPickerIcon: StyledOpenPickerIcon,
        openPickerButton: StyledOpenPickerButton,
        textField: DatePickerInput,
        popper: StyledDatePickerPopper,
        previousIconButton: StyledButton,
        nextIconButton: StyledButton,
        switchViewButton: StyledButton,
      }}
      slotProps={{
        textField: { fullWidth, placeholder },
        popper: {
          sx: {
            zIndex: 1300,
          },
          placement: 'bottom-end',
        },
      }}
    />
  );
}
