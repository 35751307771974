import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { usePermissionsQuery } from 'src/features/company-settings/departments/controller/permissionsApi';
import { useAppSelector } from 'src/store';
import BaseSinglePermission, { BaseSinglePermissionProps } from './BaseSinglePermission';

interface GeneralSinglePermissionProps extends BaseSinglePermissionProps {
  code: 'ADD_PAGES' | 'ADD_EMBEDDED_APPS' | 'ADD_TRAININGS' | 'ADD_WHITEBOARDS' | 'ADD_WIKIS';
  hasManagingPermission: boolean;
  tooltipWithoutManagingPermission: string;
}

export default function GeneralSinglePermission(props: GeneralSinglePermissionProps) {
  const { code: propsCode, hasManagingPermission, tooltipWithoutManagingPermission } = props;
  const { t } = useTranslation();
  const { data: permissionsData } = usePermissionsQuery();
  const checkedRolesIds = useAppSelector((state) => state.departments.checkedRoles);
  const deps = useAppSelector((state) => state.departments.departmentsDraftState);

  const checkedRoles = deps
    .flatMap((dep) => dep.roles)
    .filter((role) => checkedRolesIds.includes(role.id));

  const allPermissions =
    permissionsData?.permissionGroups.flatMap((group) => group.permissions) || [];

  const permissionsMap = {
    ADD_PAGES: 'ADMIN_ACCESS_DASHBOARD_AND_PAGES',
    ADD_EMBEDDED_APPS: 'ADMIN_ACCESS_EMBEDDED_APPS',
    ADD_TRAININGS: 'ADMIN_ACCESS_TRAININGS',
    ADD_WHITEBOARDS: 'ADMIN_ACCESS_WHITEBOARDS',
    ADD_WIKIS: 'ADMIN_ACCESS_WIKIS',
  };

  const permissionsTooltipMap = {
    ADD_PAGES: t('companySettings.sidebar.departments.permissions.permissionTooltip.addPages'),
    ADD_EMBEDDED_APPS: t(
      'companySettings.sidebar.departments.permissions.permissionTooltip.addEmbeddedApps'
    ),
    ADD_TRAININGS: t(
      'companySettings.sidebar.departments.permissions.permissionTooltip.addTrainings'
    ),
    ADD_WHITEBOARDS: t(
      'companySettings.sidebar.departments.permissions.permissionTooltip.addWhiteboards'
    ),
    ADD_WIKIS: t('companySettings.sidebar.departments.permissions.permissionTooltip.addWikis'),
  };

  const adminPermissionsCodes = Object.values(permissionsMap);
  const adminPermissions = allPermissions.filter((per) => adminPermissionsCodes.includes(per.code));

  const permissionid = adminPermissions.find(
    ({ code: adminCode }: any) => adminCode === permissionsMap[propsCode]
  )?.id;

  const isChecked = checkedRoles.some(
    (role) => permissionid && role.permissionIds.includes(permissionid)
  );

  return (
    <Tooltip placement="top" title={!hasManagingPermission && tooltipWithoutManagingPermission}>
      <BaseSinglePermission
        {...props}
        disabled={isChecked}
        tooltipText={permissionsTooltipMap[propsCode]}
      />
    </Tooltip>
  );
}
