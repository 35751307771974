import { Box } from '@mui/material';
import { EmbeddedItemContentType } from 'src/features/editor/model';
import { useBoolean } from 'src/lib/hooks/use-boolean';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { WorkbaseIcon } from 'src/lib/components/atoms/icon/WorkbaseIcon';
import { WorkbaseHelpIcon } from 'src/assets/icons/workbaseIcons';
import ensureHttpProtocol from 'src/lib/utils/ensureHttpProtocol';
import EmbeddedItemModal from './EmbeddedItemModal';
import { ResizeStopCallbackType } from '../highlight-item/resize/ResizableWrapper';
import HighlightItem from '../highlight-item';

interface Props {
  onDelete: () => void;
  onDuplicate: () => void;
  readOnly?: boolean;
  onResizeStop?: ResizeStopCallbackType;
  dragging?: any;
  onResizeStart?: () => void;
  height?: number;
  contentType: EmbeddedItemContentType;
  content: string;
  onUpdate: (props: { contentType: EmbeddedItemContentType; content: string }) => void;
}

export default function EmbeddedItem({
  onDelete,
  onDuplicate,
  readOnly,
  onResizeStop,
  dragging,
  onResizeStart,
  height,
  contentType,
  content,
  onUpdate,
}: Props) {
  const ref = useRef<any>();
  const dialog = useBoolean();
  const [hasError, setHasError] = useState(false);
  const iframeProps =
    contentType === 'url'
      ? {
          src: ensureHttpProtocol(content),
        }
      : {
          srcDoc: content,
        };

  useEffect(() => {
    setHasError(false);
  }, [content]);

  function getPointerEventsVal(focused: boolean) {
    if (readOnly) return 'auto';
    return focused ? 'auto' : 'none';
  }

  return (
    <HighlightItem
      editBarProps={{
        baseActionsProps: { onDelete, onDuplicate },
        embeddedActionsProps: { onClick: () => dialog.onTrue() },
      }}
      horizontalResize={{}}
      dragging={dragging}
      sx={{ border: 'none' }}
      verticalResize={{}}
      readOnly={readOnly}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
    >
      {({ EditBarComponent, focused }) => (
        <Box
          className="embedded-box"
          sx={{
            minHeight: 160,
            overflow: 'hidden',
            borderRadius: (theme) => theme.shape.borderRadius * 2.5,
            border: (theme) => `2px solid ${theme.palette.grey[100]}`,
            height,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {EditBarComponent}
          {hasError ? (
            <WorkbaseIcon icon={WorkbaseHelpIcon} sx={{ width: 30, height: 30 }} />
          ) : (
            <iframe
              ref={ref}
              {...iframeProps}
              style={{
                pointerEvents: getPointerEventsVal(focused),
                border: 'none',
                minHeight: 160,
              }}
              title="Embedded Website"
              width="100%"
              sandbox="allow-scripts allow-popups allow-forms allow-same-origin allow-popups-to-escape-sandbox allow-downloads allow-storage-access-by-user-activation"
              height="100%"
              onLoad={() => {
                const innerDoc = ref.current.contentDocument || ref.current.contentWindow.document;
                if (!innerDoc.body.innerHTML) {
                  setHasError(true);
                } else {
                  setHasError(false);
                }
              }}
            />
          )}
          {!readOnly && (
            <EmbeddedItemModal
              open={dialog.value}
              initialContent={content}
              initialContentType={contentType}
              onClose={dialog.onFalse}
              saveButtonText={t('editor.embeddedItemModal.saveConfirmButton')}
              onConfirm={onUpdate}
            />
          )}
        </Box>
      )}
    </HighlightItem>
  );
}
