import PortalComponent from 'src/lib/components/util/PortalComponent';
import { TopBar } from 'src/ui-components/layout/top-bar';
import WhiteboardIconNameSelector from './WhiteboardIconNameSelector';
import WhiteboardActionButtons from './WhiteboardActionButtons';

interface Props {
  id: string;
}

function WhiteboardTopBar({ id }: Props) {
  // TODO: remove portal after layouts / bouncing revamp
  return (
    <PortalComponent elementSelector="#main-layout-content-card">
      <TopBar
        leftContent={<WhiteboardIconNameSelector id={id} />}
        rightContent={<WhiteboardActionButtons id={id} />}
      />
    </PortalComponent>
  );
}

export default WhiteboardTopBar;
