import 'react-toastify/dist/ReactToastify.css';
import { WorkbaseCheckmarkBigIcon, WorkbaseCloseCircleIcon } from 'src/assets/icons/workbaseIcons';
import { cssTransition, Id, toast, ToastContainer } from 'react-toastify';
import { Grid, styled } from '@mui/material';
import throttle from 'lodash.throttle';
import { WorkbaseIcon } from '../icon/WorkbaseIcon';

const StyledContainer = styled(ToastContainer)`
  @keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes opacityOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  &&&.Toastify__toast-container {
    width: max-content;
    margin-top: 54px;
  }
  .Toastify__toast {
    margin-bottom: 0;
    padding: 16px 20px;
    width: max-content;
    margin-left: auto;
    margin-top: -66px;
    transition: all 0.1s ease-in-out;
    opacity: 0;
    z-index: 1;
    pointer-events: none;
    &:nth-of-type(1) {
      &:not(.animation-out) {
        opacity: 1;
      }
      z-index: 999;
      pointer-events: all;
      &.animation-out + .Toastify__toast {
        opacity: 1;
      }
      &.animation-in + .Toastify__toast {
        opacity: 1;
      }
    }
    &:nth-of-type(2) {
      opacity: 0.6;
      z-index: 998;
      transform: translateY(10px);
    }
    &:nth-of-type(3) {
      opacity: 0.4;
      z-index: 997;
      transform: translateY(20px);
    }
  }
  .animation-in {
    animation: slideInFromTop 0.3s ease-in-out;
  }
  .animation-out {
    animation: opacityOut 0.2s ease-in;
  }
`;

export function StyledToastContainer() {
  return <StyledContainer newestOnTop />;
}

const iconsDict = {
  success: {
    icon: WorkbaseCheckmarkBigIcon,
    color: 'success.main',
    backgroundColor: 'green.light',
  },
  error: {
    icon: WorkbaseCloseCircleIcon,
    color: 'error.main',
    backgroundColor: 'red.light',
  },
};
const transitionAnimation = cssTransition({
  enter: 'animation-in',
  exit: 'animation-out',
  collapse: false,
});

const allToastIds: Id[] = [];

function updateToasts() {
  allToastIds.forEach((id) => {
    toast.update(id, { autoClose: false });
  });
  toast.update(allToastIds[allToastIds.length - 1], { autoClose: 3000 });
}

export const showNotification = (message: string, type: 'success' | 'error' = 'success') => {
  const icon = (
    <WorkbaseIcon
      icon={iconsDict[type].icon}
      color={iconsDict[type].color as 'success' | 'error'}
      sx={{
        borderRadius: '50%',
        width: 20,
        height: 20,
        marginRight: '8px',
        backgroundColor: iconsDict[type].backgroundColor,
      }}
    />
  );

  const content = (
    <Grid
      container
      alignItems="center"
      sx={{ fontSize: '15px', lineHeight: 1.2, fontWeight: 700, color: 'text.secondary' }}
    >
      {icon}
      {message}
    </Grid>
  );

  const newToastId = toast(content, {
    position: 'top-right',
    hideProgressBar: true,
    closeOnClick: true,
    autoClose: false,
    closeButton: false,
    transition: transitionAnimation,
    onClose: () => {
      allToastIds.pop();
      updateToasts();
    },
  });
  allToastIds.push(newToastId);
  updateToasts();
};

export const throttledShowNotification = throttle(
  (message: string, type: 'success' | 'error' = 'success') => {
    showNotification(message, type);
  },
  1000
);
