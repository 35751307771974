import {
  Alert,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  Popover,
  Container,
} from '@mui/material';
import { useCurrentUserQuery, usePatchCurrentUserMutation } from 'src/store/api/currentUserApi';
import AccessSettings from 'src/features/quickmenu/view/AccessSettings';
import { useTranslation } from 'react-i18next';
import { useUserPermissionsQuery } from 'src/store/api/userPermissionsApi';
import { PermissionCodes } from 'src/lib/enums/permissions';
import { WorkbaseCloseIcon } from 'src/assets/icons/workbaseIcons';
import GeneralSettings from './general-settings/GeneralSettings';
import LanguageChange from './LanguageChange';
import LogoutBtns from './LogoutBtns';
import ThemeSettings from './ThemeSettings';

const centeredProgress = (
  <Stack position="absolute" left="50%" top="50%" sx={{ transform: 'translate(-50%, -50%)' }}>
    <CircularProgress />
  </Stack>
);

export default function Quickmenu({
  onClose,
  isOpen,
  anchorEl,
  dataCy,
}: {
  onClose?: () => void;
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  dataCy?: string;
}) {
  const { t } = useTranslation();
  const { data, isLoading, error, refetch } = useCurrentUserQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { data: userPermissions } = useUserPermissionsQuery();
  const hasAdminAccessToDashboardsPages = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.ADMIN_ACCESS_DASHBOARD_AND_PAGES
  );
  const hasAdminAccessToEmbeddedApps = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.ADMIN_ACCESS_EMBEDDED_APPS
  );
  const hasAdminAccessToTrainings = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.ADMIN_ACCESS_TRAININGS
  );
  const hasAdminAccessToWhiteboards = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.ADMIN_ACCESS_WHITEBOARDS
  );
  const hasAdminAccessToWikis = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.ADMIN_ACCESS_WIKIS
  );

  const hasAccessControl =
    data?.isOwner ||
    hasAdminAccessToDashboardsPages ||
    hasAdminAccessToEmbeddedApps ||
    hasAdminAccessToTrainings ||
    hasAdminAccessToWhiteboards ||
    hasAdminAccessToWikis;

  const [patchCurrentUser] = usePatchCurrentUserMutation();

  const changeHasAccessSettings = (hasAccess: boolean) => {
    patchCurrentUser({ adminAccessToAllContent: hasAccess });
  };

  if (error) {
    return (
      <Alert
        severity="error"
        action={
          <Button onClick={refetch} size="small">
            {t('validationErrorMessages.labelErrorTryAgainButton')}
          </Button>
        }
      >
        {t('validationErrorMessages.labelUserInfoErrorDescription')}
      </Alert>
    );
  }
  return (
    <Popover
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      // make menu align with content
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Stack p={2} maxWidth={320} gap={2}>
        {isLoading ? (
          centeredProgress
        ) : (
          <>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">{t('generalSettings.header')}</Typography>
              <IconButton onClick={onClose} size="medium">
                <SvgIcon fontSize="medium" component={WorkbaseCloseIcon} />
              </IconButton>
            </Stack>
            <Container maxWidth="xs" disableGutters>
              <Stack direction="column" gap={2}>
                {data && (
                  <>
                    {hasAccessControl && (
                      <AccessSettings
                        dataCy={dataCy}
                        initialChecked={data.adminAccessToAllContent}
                        onChange={changeHasAccessSettings}
                      />
                    )}
                    <ThemeSettings />
                    <GeneralSettings {...data} dataCy={dataCy} />
                    <LanguageChange {...data} dataCy={dataCy} />
                    <LogoutBtns
                      accessToMultipleCompanies={data.accessToMultipleCompanies}
                      dataCy={dataCy}
                    />
                  </>
                )}
              </Stack>
            </Container>
          </>
        )}
      </Stack>
    </Popover>
  );
}
