import { useEffect, useRef } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store';
import { CircularProgress } from '@mui/material';
import { useExchangeTokenMutation, useRefreshTokenMutation } from './auth.api';
import { setAuthState } from './auth.slice';

interface AuthProviderProps {
  children: React.ReactNode;
}

const fullPageSpinner = (
  <CircularProgress
    sx={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
  />
);

function TokenExchange({ queryToken }: { queryToken: string }) {
  const dispatch = useAppDispatch();
  const [exchangeToken, { error, isSuccess, data }] = useExchangeTokenMutation();

  useEffect(() => {
    dispatch(
      setAuthState({ token: undefined, expirationTime: undefined, refreshToken: undefined })
    );
    exchangeToken(queryToken);
  }, [queryToken, exchangeToken, dispatch]);

  useEffect(() => {
    if (isSuccess && data) {
      const { accessToken, accessTokenExpiresIn, refreshToken } = data;
      const expirationTime = Date.now() + accessTokenExpiresIn;
      dispatch(setAuthState({ token: accessToken, expirationTime, refreshToken }));
    }
  }, [isSuccess, data, dispatch]);

  if (error) {
    return <Navigate to="/unauthorized" />;
  }

  if (isSuccess) {
    return <Navigate to="/" />;
  }

  return fullPageSpinner;
}

function RefreshTokenProvider({ children }: AuthProviderProps) {
  const { refreshToken, expirationTime, token } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [handleRefreshToken, { data, error }] = useRefreshTokenMutation();
  const refreshIntervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const refreshAdvanceTime = 5 * 60 * 1000;
    const shouldRefresh = expirationTime && Date.now() < expirationTime - refreshAdvanceTime;
    function refreshAccessToken() {
      if (refreshToken) {
        handleRefreshToken(refreshToken);
      }
    }

    if (shouldRefresh && expirationTime) {
      const intervalTime = expirationTime - Date.now() - refreshAdvanceTime;
      refreshIntervalRef.current = setInterval(refreshAccessToken, intervalTime);
    } else {
      refreshAccessToken();
    }

    return () => {
      if (refreshIntervalRef.current) {
        clearInterval(refreshIntervalRef.current);
      }
    };
  }, [refreshToken, expirationTime, handleRefreshToken]);

  useEffect(() => {
    if (data) {
      const { accessToken, accessTokenExpiresIn } = data;
      const newExpirationTime = Date.now() + accessTokenExpiresIn;
      dispatch(
        setAuthState({
          token: accessToken,
          expirationTime: newExpirationTime,
        })
      );
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(
        setAuthState({ token: undefined, expirationTime: undefined, refreshToken: undefined })
      );
    }
  }, [error, dispatch]);

  if (error) {
    return <Navigate to="/unauthorized" />;
  }

  if ((expirationTime && Date.now() > expirationTime) || !token) {
    return fullPageSpinner;
  }

  return children;
}

function AuthProvider({ children }: AuthProviderProps) {
  const refreshToken = useAppSelector((state) => state.auth.refreshToken);
  const [serachParams] = useSearchParams();
  const queryToken = serachParams.get('exchangeToken');

  if (!refreshToken && !queryToken) {
    return <Navigate to="/unauthorized" />;
  }

  if (queryToken) {
    return <TokenExchange queryToken={queryToken} />;
  }

  return <RefreshTokenProvider>{children}</RefreshTokenProvider>;
}

export default AuthProvider;
