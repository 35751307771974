import { Box, Card, CardContent, CardHeader, Checkbox, Stack, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/store';
import { checkRoles, uncheckRoles } from '../../../controller/departments.slice';
import DepartmentHeader from './DepartmentHeader';
import DepartmentContent from './DepartmentContent';
import { Department } from '../../../model/types';

interface DepartmentProps {
  departments: Department[];
  department: Department;
  inputDepartmentRef: React.RefObject<HTMLInputElement>;
}

export default function DepartmentComponent({
  departments,
  department,
  inputDepartmentRef,
}: DepartmentProps) {
  const dispatch = useAppDispatch();
  const checkedRoles = useAppSelector((state) => state.departments.checkedRoles);
  const { palette } = useTheme();

  const allDepRoles = department.roles;
  const isEveryRoleChecked = allDepRoles.every((role) => checkedRoles.includes(role.id));

  return (
    <Stack direction="row" alignItems="flex-start" width="100%">
      <Box mt={2}>
        <Checkbox
          data-cy="department-checkbox"
          checked={isEveryRoleChecked}
          onChange={() => {
            if (isEveryRoleChecked) {
              dispatch(uncheckRoles(allDepRoles.map((role) => role.id)));
            } else {
              dispatch(checkRoles(allDepRoles.map((role) => role.id)));
            }
          }}
        />
      </Box>
      <Stack width="100%">
        <Card variant="outlined">
          <Stack bgcolor={palette.mode === 'light' ? 'primary.light' : 'primary.dark'}>
            <CardHeader
              title={
                <DepartmentHeader
                  department={department}
                  departmentId={department.id}
                  departments={departments}
                  inputDepartmentRef={inputDepartmentRef}
                />
              }
            />
          </Stack>
          <CardContent>
            <DepartmentContent department={department} />
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
}
