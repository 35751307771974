import { Divider, Stack } from '@mui/material';
import Links from 'src/features/editor/view/links';
import { useAppSelector } from 'src/store';
import { Link } from 'src/features/editor/model';
import RoleSelector from './RoleSelector';
import { Role } from '../controller/api';

interface Props {
  isEditing: boolean;
  roles: Role[];
  links: Link[];
}

export default function RoleSelectorWithLinks({ isEditing, roles, links }: Props) {
  const selectedRole = useAppSelector((state) => state.dashboard.selectedRole);
  const shouldShowDivider = roles.length > 1;
  return (
    <Stack sx={{ width: '100%' }}>
      {selectedRole && <RoleSelector selectedRole={selectedRole} roles={roles} />}
      {((links?.length && !isEditing) || isEditing) && shouldShowDivider && (
        <Divider sx={{ mt: 1.5, mb: 2.5 }} />
      )}
      <Links readOnly={!isEditing} roles={roles} />
    </Stack>
  );
}
