import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Employee } from 'src/features/employees/model';
import { ImageProgressCard } from 'src/lib/components/molecules/progress-card';
import WorkbaseCollapsible from 'src/lib/components/molecules/сollapsible/Collapsible';
import { throttledShowNotification } from 'src/lib/components/atoms/notification';
import { t } from 'i18next';
import {
  TrainingsProgress,
  DetailedTrainingWithAccess,
} from 'src/store/api/trainings/trainingsApi';
import { useTrainingModulesByTrainingIdQuery } from 'src/store/api/trainings/trainingModulesApi';
import { useAppSelector } from 'src/store';
import normalizeString from 'src/lib/utils/normalizeString';
import ModulesList from './modules/ModulesList';

interface Props {
  trainingsProgress: TrainingsProgress[];
  training: DetailedTrainingWithAccess;
  employee: Employee;
  searchVal: string;
}
// eslint-disable-next-line max-statements
export default function SingleTraining({
  training,
  trainingsProgress,
  employee,
  searchVal,
}: Props) {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const { data: trainingModulesData } = useTrainingModulesByTrainingIdQuery(training.id, {
    skip: !isOpen,
  });
  const sectionQueries = useAppSelector((state) => state.trainingSectionsApi.queries);
  const lessonQueries = useAppSelector((state) => state.trainingLessonsApi.queries);
  const sectionQueriesKeys = Object.keys(sectionQueries);

  const sections = trainingModulesData
    ?.flatMap(({ id }) => {
      const queryKey = sectionQueriesKeys.find((key) => key.includes(id));
      const query = queryKey && (sectionQueries[queryKey] as any);
      return query?.data?.filter(({ status }: any) => status === 'published');
    })
    .filter((section) => section);

  const filteredSection = sections?.filter(
    (section) => !!section && normalizeString(section.name).includes(searchVal || '')
  );

  const lessonQueriesKeys = Object.keys(lessonQueries);

  const lessons = sections
    ?.flatMap(({ id }) => {
      const queryKey = lessonQueriesKeys.find((key) => key.includes(id));
      const query = queryKey && (lessonQueries[queryKey] as any);
      return query?.data?.filter((lesson: any) => lesson.status === 'published');
    })
    .filter((lesson) => lesson);

  const filteredLessons = lessons?.filter(
    (lesson) => !!lesson && normalizeString(lesson.name).includes(searchVal)
  );

  const modulesCountLabel = t('modules', { count: training.modulesCount || 0 });
  const sectionsCountLabel = t('sections', { count: training.sectionsCount || 0 });
  const lessonsCountLabel = t('lesson', { count: training.lessonsCount || 0 });
  const estimatedTimeLabel = t('minute', { count: training.estimatedTimeToComplete || 0 });

  const filteredModules = (trainingModulesData || []).filter(
    ({ name, status }) => normalizeString(name).includes(searchVal || '') && status === 'published'
  );

  const normalizedTrainingName = normalizeString(training.name);
  const isSearchMatch = normalizedTrainingName.includes(searchVal);

  const hasFilteredContent =
    isOpen &&
    (filteredModules.length > 0 ||
      isSearchMatch ||
      (filteredSection && filteredSection?.length > 0) ||
      (filteredLessons && filteredLessons?.length > 0));

  const shouldDisplay = isOpen ? hasFilteredContent : isSearchMatch;

  return (
    <WorkbaseCollapsible
      dataCy="employee-single-training-progress"
      key={training.id}
      defaultExpanded={isOpen}
      onChange={() => setOpen(!isOpen)}
      title={
        <ImageProgressCard
          dataCy="training-image-progress-card"
          image={training.imageUrl}
          title={training.name || t('employeePage.progress.unnamedTraining')}
          description={`${modulesCountLabel} – ${sectionsCountLabel} – ${lessonsCountLabel} – ${estimatedTimeLabel}`}
          progress={trainingsProgress?.find(({ id }) => id === training.id)?.progress || 0}
          onClick={() => {
            if (training.blockAccess) {
              throttledShowNotification(t('employeePage.progress.noAccessTraining'), 'error');
            } else {
              navigate(`/trainings/${training.id}`);
            }
          }}
        />
      }
      backgroundColor="secondary.background"
      sx={{
        '&&&& .MuiAccordionSummary-content': { margin: '20px 16px 20px 0' },
        display: shouldDisplay ? 'block' : 'none',
      }}
    >
      <ModulesList
        blockAccess={training.blockAccess}
        trainingModulesData={trainingModulesData || []}
        employee={employee}
        searchVal={searchVal}
      />
    </WorkbaseCollapsible>
  );
}
