import EditViewProtectedWrapper from 'src/core/EditViewProtectedWrapper';
import ModeSwitch from 'src/core/ModeSwitch';
import EditorWithContextProvider from './EditorWithContextProvider';
import { EditorProps } from './Editor';

interface Props extends Omit<EditorProps, 'readOnly'> {
  canEdit: boolean;
  canView: boolean;
}

export default function EditorWithModeSwitch({ canEdit, canView, ...restOfProps }: Props) {
  const editableContent = (
    <ModeSwitch>
      {(isEditing) => <EditorWithContextProvider {...restOfProps} readOnly={!isEditing} />}
    </ModeSwitch>
  );
  const notEditableContent = <EditorWithContextProvider {...restOfProps} readOnly />;
  return (
    <EditViewProtectedWrapper canEdit={canEdit} canView={canView}>
      {canEdit ? editableContent : notEditableContent}
    </EditViewProtectedWrapper>
  );
}
