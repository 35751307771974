import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showNotification } from 'src/lib/components/atoms/notification';
import { useRolesQuery } from 'src/store/api/roles.api';
import { useCreateEmployeesMutation } from 'src/store/api/employees/employeesApi';
import { Role } from '../../model';
import NewEmployeeDialog from './NewEmployeeDialog';
import { EmailChipsValueProps } from './controller/use-email-chips';
import validateNewEmployeesEmails from './controller/helper';

export default function NewEmployee({ open, onClose }: { open: boolean; onClose: () => void }) {
  const { t } = useTranslation();
  const [rolesSelected, setRolesSelected] = useState<string[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [createEmployees] = useCreateEmployeesMutation({});
  const [allEmails, setAllEmails] = useState<EmailChipsValueProps[]>([]);
  const { data: rolesData } = useRolesQuery();
  const onRolesChange = (options: string[]) => {
    setRolesSelected(options);
  };

  useEffect(() => {
    if (open && rolesData) {
      setRoles(rolesData);
    }
    if (!open) {
      setRolesSelected([]);
      setAllEmails([]);
    }
  }, [open, rolesData]);

  const allRoles = useMemo(
    () =>
      roles
        ? roles.map((role) => ({
            id: role.id,
            label: `${role.department.name} - ${role.name}`,
          }))
        : [],
    [roles]
  );
  const hasInvalidEmails = allEmails.some((obj) => !obj.isValid);
  const validatingEmails = allEmails.some((obj) => obj.isLoading);

  const disabledConfirmButton =
    allEmails.length === 0 || hasInvalidEmails || validatingEmails || rolesSelected.length === 0;

  const handleChipsCardOnChange = (values: EmailChipsValueProps[]) => {
    validateNewEmployeesEmails(values, allEmails, setAllEmails);
  };

  const handleEmployeeAdd = () => {
    createEmployees({
      emails: allEmails.map((email) => email.label),
      roles: rolesSelected,
      allRoles:
        allRoles
          .map((role) => role.id)
          .sort()
          .join(',') === rolesSelected.sort().join(','),
    }).then((resp: any) => {
      if (resp?.error) {
        showNotification(t('addEmployees.notificationFail'), 'error');
      } else {
        showNotification(
          allEmails.length > 1
            ? t('addEmployees.notificationSuccess.multiplePeopleAdded')
            : t('addEmployees.notificationSuccess.onePersonAdded')
        );
      }
    });
    onClose();
  };

  return (
    <NewEmployeeDialog
      open={open}
      onClose={onClose}
      allRoles={allRoles}
      disabledConfirmButton={disabledConfirmButton}
      handleEmployeeAdd={handleEmployeeAdd}
      handleChipsCardOnChange={handleChipsCardOnChange}
      allEmails={allEmails}
      hasInvalidEmails={hasInvalidEmails}
      onRolesChange={onRolesChange}
      rolesSelected={rolesSelected}
    />
  );
}
