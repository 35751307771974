import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';
import { rolesApi } from 'src/store/api/roles.api';
import { departmentsApi } from 'src/features/company-settings/departments/controller/departmentsApi';
import {
  addUserCountToRole,
  removeUserCountFromRole,
} from 'src/features/company-settings/departments/controller/departments.slice';
import {
  Employee,
  EmployeePatchRequest,
  NewEmployeesRequestPayload,
  Role,
  BaseEmployeeType,
} from '../../../features/employees/model';
import { sidebarApi } from '../sidebarApi';

interface ChangeEmailResponse {
  cooldown: number;
}

export interface EmployeeProgress {
  id: string;
  progress: number;
}

export const employeesApi = createApi({
  reducerPath: 'employeeApi',
  tagTypes: [
    'Employees',
    'EmployeesTrainingProgress',
    'EmployeesByRoleId',
    'SearchEmployeeResults',
  ],
  baseQuery,
  endpoints: (builder) => ({
    // queries
    employees: builder.query<Employee[], void>({
      query: () => '/companies/users',
      providesTags: ['Employees'],
    }),
    employeesByRoleId: builder.query<BaseEmployeeType[], string>({
      query: (roleId) => `roles/${roleId}/users`,
      providesTags: ['EmployeesByRoleId'],
    }),
    employeesTrainingProgress: builder.query<EmployeeProgress[], void>({
      query: () => '/companies/users/trainings-progress',
      providesTags: ['EmployeesTrainingProgress'],
    }),
    searchEmployeeResults: builder.query<BaseEmployeeType[], { roleId: string; query: string }>({
      query: ({ roleId, query }) => ({
        url: `roles/${roleId}/users/add/search?query=${query}`,
      }),
      providesTags: ['SearchEmployeeResults'],
    }),
    // mutations
    postAssignUserToRole: builder.mutation<BaseEmployeeType[], { roleId: string; userId: string }>({
      query: ({ roleId, userId }) => ({
        url: `roles/${roleId}/users/add`,
        method: 'POST',
        body: { userId },
      }),
      onQueryStarted: ({ roleId }, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(
            departmentsApi.util.updateQueryData('departments', undefined, (draft) =>
              draft.map((dep) => ({
                ...dep,
                // eslint-disable-next-line max-nested-callbacks
                roles: dep.roles.map((role) =>
                  role.id === roleId ? { ...role, usersCount: (role.usersCount || 0) + 1 } : role
                ),
              }))
            )
          );
          dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
          dispatch(addUserCountToRole(roleId));
        });
      },
      invalidatesTags: () => ['EmployeesByRoleId', 'SearchEmployeeResults'],
    }),
    unAssignUserFromRole: builder.mutation<BaseEmployeeType[], { roleId: string; userId: string }>({
      query: ({ roleId, userId }) => ({
        url: `roles/${roleId}/users/${userId}`,
        method: 'DELETE',
      }),
      onQueryStarted: ({ roleId, userId }, { dispatch, queryFulfilled }) => {
        const disp = dispatch(
          employeesApi.util.updateQueryData('employeesByRoleId', roleId, (draft) =>
            draft.filter((user) => user.id !== userId)
          )
        );
        queryFulfilled.then(() => {
          dispatch(
            departmentsApi.util.updateQueryData('departments', undefined, (draft) =>
              draft.map((dep) => ({
                ...dep,
                // eslint-disable-next-line max-nested-callbacks
                roles: dep.roles.map((role) =>
                  role.id === roleId ? { ...role, usersCount: (role.usersCount || 1) - 1 } : role
                ),
              }))
            )
          );
          dispatch(removeUserCountFromRole(roleId));
          dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
        });
        queryFulfilled.catch(disp.undo);
      },
      invalidatesTags: ['SearchEmployeeResults'],
    }),
    patchEmployee: builder.mutation<Employee, { id: string; data: Partial<EmployeePatchRequest> }>({
      query: ({ id, data }) => ({
        url: `/companies/users/${id}`,
        body: data,
        method: 'PATCH',
      }),
      invalidatesTags: ['EmployeesByRoleId'],
      onQueryStarted: async ({ id, data }, { dispatch, queryFulfilled }) => {
        const { data: rolesData } = await dispatch(rolesApi.endpoints.roles.initiate(undefined));
        const roleMap: { [id: string]: Role } = {};
        if (rolesData) {
          rolesData.forEach((role) => {
            roleMap[role.id] = role;
          });
        }
        const disp = dispatch(
          employeesApi.util.updateQueryData('employees', undefined, (draft) =>
            draft.map((employee) => {
              if (employee.id === id) {
                // Map roleIds to roles
                const updatedRoles =
                  data.roleIds?.map((roleId) => ({
                    id: roleId,
                    name: roleMap[roleId].name,
                    department: roleMap[roleId].department,
                  })) || employee.roles;

                return { ...employee, ...data, roles: updatedRoles };
              }
              return employee;
            })
          )
        );

        queryFulfilled.then(() => {
          dispatch(departmentsApi.util.invalidateTags(['Departments']));
          dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
        });

        queryFulfilled.catch(disp.undo);
      },
    }),
    createEmployees: builder.mutation<void, NewEmployeesRequestPayload>({
      query: (data) => ({
        url: `/companies/users/batch`,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: ['EmployeesByRoleId', 'Employees'],
    }),
    changeEmail: builder.mutation<ChangeEmailResponse, { id: string; email: string }>({
      query: (data) => ({
        url: `/companies/users/${data.id}/change-email`,
        body: { email: data.email },
        method: 'POST',
      }),
      invalidatesTags: ['EmployeesByRoleId'],
      onQueryStarted: ({ id, email }, { dispatch, queryFulfilled }) => {
        const disp = dispatch(
          employeesApi.util.updateQueryData('employees', undefined, (draft) =>
            draft.map((employee) =>
              employee.id === id ? { ...employee, pendingEmailChange: email } : employee
            )
          )
        );
        queryFulfilled
          .then(({ data }) => {
            dispatch(employeesApi.util.updateQueryData('emailChangeCooldown', id, () => data));
          })
          .catch(disp.undo);
      },
    }),
    emailChangeCooldown: builder.query<ChangeEmailResponse, string>({
      query: (id) => `/companies/users/${id}/change-email`,
    }),
    cancelEmailChange: builder.mutation<void, string>({
      query: (id) => ({
        url: `/companies/users/${id}/change-email/cancel`,
        method: 'POST',
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(employeesApi.endpoints.employees.initiate(undefined, { forceRefetch: true }));
        });
      },
    }),
  }),
});

export const {
  useEmployeesQuery,
  useEmployeesTrainingProgressQuery,
  useEmployeesByRoleIdQuery,
  useSearchEmployeeResultsQuery,
  usePatchEmployeeMutation,
  useCreateEmployeesMutation,
  useChangeEmailMutation,
  useEmailChangeCooldownQuery,
  useCancelEmailChangeMutation,
  usePostAssignUserToRoleMutation,
  useUnAssignUserFromRoleMutation,
} = employeesApi;
