import { Stack } from '@mui/material';
import {
  TrainingModule,
  useTrainingModulesProgressQuery,
} from 'src/store/api/trainings/trainingModulesApi';
import ReadOnlyContent from './ReadOnlyContent';
import EditContent from './EditContent';

interface ModuleHeaderProps {
  data: TrainingModule;
  readOnly: boolean;
  mobileView: boolean;
}

export default function ModuleHeader({ data, readOnly, mobileView }: ModuleHeaderProps) {
  const { data: progressData } = useTrainingModulesProgressQuery(data.id);

  return (
    <Stack
      height={mobileView ? 'auto' : 202}
      sx={{
        border: mobileView ? 'none' : '1px solid',
        borderColor: 'grey.300',
        borderRadius: (theme) => theme.shape.borderRadius * 4,
        marginBottom: '20px',
        width: '100%',
        backgroundColor: mobileView ? 'transparent' : 'secondary.background',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <Stack
        height={mobileView ? 'auto' : 160}
        sx={{
          verticalAlign: 'center',
          flexDirection: mobileView ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          maxWidth: '1040px',
          margin: mobileView ? 0 : '20px',
          width: '100%',
        }}
      >
        {readOnly && (
          <ReadOnlyContent
            trainingModule={data}
            mobileView={mobileView}
            progress={progressData?.progress || 0}
          />
        )}
        {!readOnly && (
          <EditContent
            trainingModule={data}
            mobileView={mobileView}
            progress={progressData?.progress || 0}
          />
        )}
      </Stack>
    </Stack>
  );
}
