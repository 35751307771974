import { Box, IconButton, Stack } from '@mui/material';
import WorkbaseTooltip from 'src/lib/components/atoms/tooltip';
import { useTranslation } from 'react-i18next';
import { WorkbaseIcon } from '../../../atoms/icon/WorkbaseIcon';
import {
  WorkbaseCopyDuplicateIcon,
  WorkbaseDeleteIcon,
} from '../../../../../assets/icons/workbaseIcons';

export interface BaseActionsProps {
  onDuplicate: () => void;
  onDelete: () => void;
  hideDuplicate?: boolean;
}

export default function BaseActions({
  onDuplicate,
  onDelete,
  hideDuplicate = false,
}: BaseActionsProps) {
  const { t } = useTranslation();
  return (
    <Stack direction="row" gap="6px" sx={{ padding: '3px 0 5px 5px' }}>
      {!hideDuplicate && (
        <WorkbaseTooltip title={t('editor.topBar.tooltip.duplicate')} placement="top">
          <Box>
            <IconButton
              color="inherit"
              onMouseDown={(e) => {
                e.preventDefault();
                onDuplicate();
              }}
            >
              <WorkbaseIcon
                icon={WorkbaseCopyDuplicateIcon}
                sx={{ '&&&': { width: 16, height: 16 } }}
              />
            </IconButton>
          </Box>
        </WorkbaseTooltip>
      )}
      <WorkbaseTooltip title={t('editor.topBar.tooltip.remove')} placement="top">
        <Box>
          <IconButton
            color="error"
            onMouseDown={(e) => {
              e.preventDefault();
              onDelete();
            }}
          >
            <WorkbaseIcon icon={WorkbaseDeleteIcon} sx={{ '&&&': { width: 16, height: 16 } }} />
          </IconButton>
        </Box>
      </WorkbaseTooltip>
    </Stack>
  );
}
