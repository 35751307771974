import { PermissionCodes } from 'src/lib/enums/permissions';
import { useUserPermissionsQuery } from 'src/store/api/userPermissionsApi';

export default function usePermissions() {
  const { data: userPermissions } = useUserPermissionsQuery();

  const hasAddTrainingsPermission = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.ADD_TRAININGS
  );
  const hasAddPagesPermission = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.ADD_PAGES
  );
  const hasAddEmbeddedAppsPermission = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.ADD_EMBEDDED_APPS
  );
  const hasManageEmployeePermission = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.MANAGE_EMPLOYEES
  );
  const hasAddWhiteboardsPermission = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.ADD_WHITEBOARDS
  );
  const hasAddWikiPermission = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.ADD_WIKIS
  );
  const hasAdminAccessWhiteboardsPermission = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.ADMIN_ACCESS_WHITEBOARDS
  );
  const hasAdminAccessEmbeddedAppsPermission = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.ADMIN_ACCESS_EMBEDDED_APPS
  );
  const hasAdminAccessDashboardAndPagesPermission = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.ADMIN_ACCESS_DASHBOARD_AND_PAGES
  );

  return {
    hasAddTrainingsPermission,
    hasAddPagesPermission,
    hasAddEmbeddedAppsPermission,
    hasManageEmployeePermission,
    hasAddWhiteboardsPermission,
    hasAddWikiPermission,
    hasAdminAccessWhiteboardsPermission,
    hasAdminAccessEmbeddedAppsPermission,
    hasAdminAccessDashboardAndPagesPermission,
  };
}
