import { Box, Grid, SxProps } from '@mui/material';
import { useDrop } from 'react-dnd';
import { BaseGridItem, GridItem, GridRow } from 'src/features/editor/model';
import { useEditor } from 'src/features/editor/controller';
import { COLUMN_COUNT } from '../constants';
import useDebouncedIsOver from './useDebouncedIsOver';

function countConsecutiveEmptyColumns(items: BaseGridItem[], columnStart: number): number {
  const sortedItems = items.slice().sort((a, b) => a.columnStart - b.columnStart);
  const nextItem = sortedItems.find((item) => item.columnStart >= columnStart);

  if (!nextItem) {
    return COLUMN_COUNT - columnStart + 1;
  }

  if (nextItem.columnStart === columnStart) {
    return 0;
  }

  return nextItem.columnStart - columnStart;
}

function countConsecutiveEmptyColumnsBefore(items: BaseGridItem[], columnEnd: number): number {
  const sortedItems = items.slice().sort((a, b) => a.columnEnd - b.columnEnd);
  const prevItem = sortedItems.reverse().find((item) => item.columnEnd <= columnEnd);

  if (!prevItem) {
    return columnEnd - 1;
  }

  if (prevItem.columnEnd === columnEnd) {
    return 0;
  }

  return columnEnd - prevItem.columnEnd;
}

function calculateDimensions(filteredItems: BaseGridItem[], colStart: number, item: BaseGridItem) {
  const itemWidth = item ? item.columnEnd - item.columnStart : 1;
  const emptyColsAfter = countConsecutiveEmptyColumns(filteredItems, colStart);
  const emptyColsBefore = countConsecutiveEmptyColumnsBefore(filteredItems, colStart);

  const itemWidthAfterDrop =
    itemWidth > emptyColsAfter + emptyColsBefore ? emptyColsAfter + emptyColsBefore : itemWidth;
  const emptySpaceBeforeNeeded =
    itemWidthAfterDrop - emptyColsAfter < 0 ? 0 : itemWidthAfterDrop - emptyColsAfter;

  return { itemWidthAfterDrop, emptySpaceBeforeNeeded };
}

export default function DroppableEmptyCell({
  row,
  colStart,
  sx,
  ignoreLastChildStyles = false,
}: {
  row: GridRow;
  colStart: number;
  sx?: SxProps;
  ignoreLastChildStyles?: boolean;
}) {
  const { moveItemToNewPosition } = useEditor();

  const [{ isOver, item }, drop] = useDrop({
    accept: 'GRID_ITEM',
    drop: (_: { rowIndex: number; item: GridItem }, monitor) => {
      if (monitor && item.elementType !== 'heading') {
        const filteredItems = row.items.filter(({ id }) => id !== item?.id);
        const { itemWidthAfterDrop, emptySpaceBeforeNeeded } = calculateDimensions(
          filteredItems,
          colStart,
          item
        );
        moveItemToNewPosition({
          itemId: item.id,
          targetRowId: row.id,
          newColumnStart: colStart - emptySpaceBeforeNeeded,
          newColumnEnd: colStart - emptySpaceBeforeNeeded + itemWidthAfterDrop,
        });
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      item: monitor.getItem()?.item,
    }),
  });

  const debouncedIsOver = useDebouncedIsOver(isOver);

  const filteredItems = row.items.filter(({ id }) => id !== item?.id);

  const { itemWidthAfterDrop, emptySpaceBeforeNeeded } = calculateDimensions(
    filteredItems,
    colStart,
    item
  );

  const translateVal = (1 / itemWidthAfterDrop) * emptySpaceBeforeNeeded * 100;
  const widthDiffDict = {
    1: 16,
    2: 8,
    3: 5.5,
    4: 3,
  };

  const activeBoxStyles = {
    transform: `translateX(calc(-${translateVal}% - ${
      ignoreLastChildStyles
        ? 0
        : widthDiffDict[itemWidthAfterDrop as 1 | 2 | 3 | 4] * emptySpaceBeforeNeeded
    }px))`,
    width: `calc(${100 * itemWidthAfterDrop}% - ${ignoreLastChildStyles ? 0 : 16}px)`,
    visibility: 'visible',
  };

  const isDropActive = debouncedIsOver && item?.elementType !== 'heading';
  const additionalBoxStyles = isDropActive ? activeBoxStyles : {};
  return (
    <Grid
      item
      ref={drop}
      className="grid-cell"
      sx={{
        height: 'calc(100% - 12px)',
        marginTop: '6px',
        marginBottom: '6px',
        position: 'relative',
        zIndex: 1,
        width: '25%',
        '&:not(:last-child)': {
          paddingRight: '16px',
        },
        '&:last-child': ignoreLastChildStyles
          ? {}
          : {
              width: `calc(25% - 16px)`,
              '& > *': isDropActive
                ? {
                    width: `calc(${100 * itemWidthAfterDrop}% + ${
                      16 * itemWidthAfterDrop - 16
                    }px) !important`,
                    transform: `translateX(calc(-${translateVal}% - ${
                      widthDiffDict[itemWidthAfterDrop as 1 | 2 | 3 | 4] * emptySpaceBeforeNeeded
                    }px))`,
                  }
                : {},
            },
        ...sx,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: '-6px',
          height: 'calc(100% + 12px)',
          width: '100%',
          borderStyle: 'solid',
          borderColor: 'info.main',
          backgroundColor: 'alternate.light',
          borderWidth: '1px',
          borderRadius: (theme) => theme.shape.borderRadius * 2.5,
          visibility: 'hidden',
          pointerEvents: 'none',
          transformOrigin: '0 0',
          ...additionalBoxStyles,
        }}
      />
    </Grid>
  );
}
