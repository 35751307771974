import { IconButton, Stack } from '@mui/material';
import WorkbaseTooltip from 'src/lib/components/atoms/tooltip';
import { t } from 'i18next';
import { WorkbaseIcon } from '../../../atoms/icon/WorkbaseIcon';
import { WorkbaseImageIcon, WorkbaseVideoIcon } from '../../../../../assets/icons/workbaseIcons';

export interface UploadActionsProps {
  activeFileType: FileType;
  isLoading?: boolean;
  onChangeActiveFileType: (type: FileType) => void;
}

export type FileType = 'image' | 'video' | 'file';

export default function UploadActions({
  activeFileType,
  isLoading,
  onChangeActiveFileType,
}: UploadActionsProps) {
  const items = [
    {
      key: 'image',
      onClick: () => onChangeActiveFileType('image'),
      icon: WorkbaseImageIcon,
    },
    {
      key: 'video',
      onClick: () => onChangeActiveFileType('video'),
      icon: WorkbaseVideoIcon,
    },
  ];
  return (
    <Stack direction="row" gap={1} sx={{ padding: '3px 5px 5px 0px' }}>
      {items.map(({ key, onClick, icon }) => (
        <WorkbaseTooltip title={t(`editor.topBar.tooltip.${key}`)} placement="top">
          <span>
            <IconButton
              disabled={isLoading}
              key={key}
              onMouseDown={(e) => {
                e.preventDefault();
                onClick();
              }}
            >
              <WorkbaseIcon
                icon={icon}
                color={key === activeFileType ? 'primary.main' : 'text.secondary'}
                sx={{
                  '&&&': {
                    width: 16,
                    height: 16,
                    opacity: isLoading ? 0.5 : 1,
                  },
                }}
              />
            </IconButton>
          </span>
        </WorkbaseTooltip>
      ))}
    </Stack>
  );
}
