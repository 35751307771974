import { Button, Divider, IconButton, Stack, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { WorkbaseTextField } from 'src/lib/components/atoms/text-field/TextField';
import WorkbaseTooltip from 'src/lib/components/atoms/tooltip';
import { useTranslation } from 'react-i18next';
import {
  WorkbaseBackIcon,
  WorkbaseDeleteIcon,
  WorkbaseLinksIcon,
} from '../../../../../assets/icons/workbaseIcons';
import { WorkbaseIcon } from '../../../atoms/icon/WorkbaseIcon';

interface Props {
  onRemove: () => void;
  value: string;
  onApply: (val: string) => void;
  onClear: () => void;
}

export default function LinkInput({ onRemove, value, onApply, onClear }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [val, setVal] = useState(value);
  const hasError = val === '';
  const { t } = useTranslation();

  useEffect(() => {
    setVal(value);
  }, [value]);

  return (
    <Stack direction="row" alignItems="center">
      <WorkbaseIcon
        icon={WorkbaseLinksIcon}
        color="text.secondary"
        sx={{ width: 16, height: 16, mr: '8px' }}
      />
      <WorkbaseTextField
        autoFocus
        placeholder="Link..."
        value={val}
        onChange={(e) => setVal(e.target.value)}
        onMouseDown={() => {
          ref.current?.querySelector('input')?.focus();
        }}
        sx={{
          '& input': {
            paddingLeft: '8px',
            paddingRight: 0,
          },
        }}
        error={hasError}
      />
      <Tooltip title={val === '' ? 'Empty field' : ''}>
        <span>
          <Button
            disabled={!val}
            onClick={() => {
              onApply(val);
              onRemove();
            }}
          >
            Apply
          </Button>
        </span>
      </Tooltip>
      <Divider orientation="vertical" flexItem />
      <Stack gap={0.5} direction="row" sx={{ marginLeft: '12px' }}>
        <WorkbaseTooltip title={t('editor.topBar.tooltip.remove')} placement="top">
          <span>
            <IconButton
              color="error"
              onClick={() => {
                onClear();
              }}
            >
              <WorkbaseIcon icon={WorkbaseDeleteIcon} sx={{ '&&&': { width: 16, height: 16 } }} />
            </IconButton>
          </span>
        </WorkbaseTooltip>
        <WorkbaseTooltip title={t('editor.topBar.tooltip.back')} placement="top">
          <span>
            <IconButton
              color="inherit"
              onClick={() => {
                onRemove();
              }}
            >
              <WorkbaseIcon icon={WorkbaseBackIcon} sx={{ '&&&': { width: 16, height: 16 } }} />
            </IconButton>
          </span>
        </WorkbaseTooltip>
      </Stack>
    </Stack>
  );
}
