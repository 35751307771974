import { IconButton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import WorkbaseTooltip from 'src/lib/components/atoms/tooltip';
import { WorkbaseIcon } from '../../../atoms/icon/WorkbaseIcon';
import {
  WorkbaseCheckmarkBigIcon,
  WorkbaseErrorIcon,
  WorkbaseInfoIcon,
  WorkbaseNotesIcon,
  WorkbaseQuestionBubbleIcon,
  WorkbaseWarningIcon,
} from '../../../../../assets/icons/workbaseIcons';

export interface InfoActionsProps {
  infoType: InfoType;
  onChangeActiveInfoType: (type: InfoType) => void;
}

export type InfoType = 'info' | 'question' | 'notes' | 'success' | 'warning' | 'error';

export default function InfoActions({ infoType, onChangeActiveInfoType }: InfoActionsProps) {
  const { t } = useTranslation();
  const items = [
    {
      key: 'info',
      onClick: () => onChangeActiveInfoType('info'),
      icon: WorkbaseInfoIcon,
      activeColor: 'primary.main',
    },
    {
      key: 'question',
      onClick: () => onChangeActiveInfoType('question'),
      icon: WorkbaseQuestionBubbleIcon,
      activeColor: 'info.main',
    },
    {
      key: 'notes',
      onClick: () => onChangeActiveInfoType('notes'),
      icon: WorkbaseNotesIcon,
      activeColor: 'purple.main',
    },
    {
      key: 'success',
      onClick: () => onChangeActiveInfoType('success'),
      icon: WorkbaseCheckmarkBigIcon,
      activeColor: 'success.main',
    },
    {
      key: 'warning',
      onClick: () => onChangeActiveInfoType('warning'),
      icon: WorkbaseWarningIcon,
      activeColor: 'secondary.main',
    },
    {
      key: 'error',
      onClick: () => onChangeActiveInfoType('error'),
      icon: WorkbaseErrorIcon,
      activeColor: 'error.main',
    },
  ];
  return (
    <Stack direction="row" gap="6px" padding="3px 0 5px">
      {items.map(({ key, onClick, icon, activeColor }) => (
        <WorkbaseTooltip title={t(`editor.topBar.tooltip.${key}`)} placement="top">
          <span>
            <IconButton
              color="inherit"
              key={key}
              onMouseDown={(e) => {
                e.preventDefault();
                onClick();
              }}
            >
              <WorkbaseIcon
                icon={icon}
                hoverColor={activeColor}
                color={key === infoType ? activeColor : 'text.secondary'}
                sx={{
                  '&&&': {
                    width: 16,
                    height: 16,
                  },
                }}
              />
            </IconButton>
          </span>
        </WorkbaseTooltip>
      ))}
    </Stack>
  );
}
