import TextItem from 'src/lib/components/organisms/editor/text-item';
import EditorDivider from 'src/lib/components/organisms/editor/divider';
import Button from 'src/lib/components/organisms/editor/button';
import HeadlineItem from 'src/lib/components/organisms/editor/headline-item';
import InfoPanel from 'src/lib/components/organisms/editor/info-panel';
import UploadItem from 'src/lib/components/organisms/editor/upload-item';
import ImageItem from 'src/lib/components/organisms/editor/image-item';
import SpacerItem from 'src/lib/components/organisms/editor/spacer/SpacerItem';
import { useMemo } from 'react';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import { downloadFile } from 'src/lib/utils';
import TrainingsItem, {
  TrainingsItemProps,
} from 'src/lib/components/organisms/editor/trainings-item/TrainingsItem';
import { useGetTrainingsQuery } from 'src/store/api/trainings/trainingsApi';
import EmbeddedItem from 'src/lib/components/organisms/editor/embedded/EmbeddedItem';
import TrainingModulesWidget from 'src/features/training-course/view/modules-widget/TrainingModulesWidget';
import { useAllTrainingModulesQuery } from 'src/store/api/trainings/trainingModulesApi';
import downloadVideo from 'src/lib/utils/downloadVideo';
import {
  BaseGridItem,
  GridContentItem,
  ElementType,
  GridItem,
  InfoItem,
  GridUploadItem,
  GridTrainingsItem,
  ImageGridItem,
  VideoGridItem,
  GridEmbeddedItem,
  TrainingType,
  GridSpacerItem,
  GridButtonItem,
} from '../../../model/types';
import { useEditor } from '../../../controller/EditorContext';
import VideoWrapper from '../../video/VideoWrapper';

interface RenderFunctionProps {
  item: Partial<GridItem>;
  readOnly: boolean;
  dragging?: {
    dragRef: ConnectDragSource;
    previewRef: ConnectDragPreview;
  };
}

interface TrainingItemWithTrainingsProps extends TrainingsItemProps {
  handleTrainingsTypeChange: (trainingsType: TrainingType) => void;
}

function TrainingItemWithTrainings({ ...props }: TrainingItemWithTrainingsProps) {
  const { data } = useGetTrainingsQuery(undefined, { pollingInterval: 30000 });
  const { data: modulesData } = useAllTrainingModulesQuery();

  return <TrainingsItem {...props} trainings={data} modules={modulesData} />;
}

const useElementConfigs = () => {
  const {
    handleRemoveItem,
    handleEditorChange,
    handleInfoTypeChange,
    handleDuplicateItem,
    handleMediaTypeChange,
    handleImageUpload,
    handleSetImage,
    handleTrainingsTitleChange,
    handleTrainingsIconChange,
    handleSelectedTrainingsChange,
    handleSetVideo,
    handleReplaceVideo,
    handleUpdateVideoPoster,
    handleUpdateVideoTitle,
    handleUpdateEmbeddedItem,
    handleTrainingsTypeChange,
    handleDisableForwardVideo,
    handleRequiredVideo,
    handleHrefChange,
  } = useEditor();
  const elementConfigs = useMemo(() => {
    const configs: Record<
      ElementType,
      {
        render: (props: RenderFunctionProps) => React.ReactElement;
      }
    > = {
      spacer: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const { id, height } = item as GridSpacerItem;
          return (
            <SpacerItem
              onDelete={() => handleRemoveItem(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              height={height}
              readOnly={readOnly}
              dragging={dragging}
              {...props}
            />
          );
        },
      },
      textItem: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const { content, id } = item as GridContentItem;
          return (
            <TextItem
              value={content}
              readOnly={readOnly}
              onDelete={() => handleRemoveItem(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              onChange={handleEditorChange(id)}
              dragging={dragging}
              {...props}
            />
          );
        },
      },
      button: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const { content, href, id } = item as GridButtonItem;
          return (
            <Button
              value={content}
              readOnly={readOnly}
              onDelete={() => handleRemoveItem(id)}
              onChange={handleEditorChange(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              dragging={dragging}
              href={href}
              onHrefChange={handleHrefChange(id)}
              {...props}
            />
          );
        },
      },
      divider: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const { id } = item as BaseGridItem;
          return (
            <EditorDivider
              readOnly={readOnly}
              onDelete={() => handleRemoveItem(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              dragging={dragging}
              {...props}
            />
          );
        },
      },
      heading: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const { content, id } = item as GridContentItem;
          return (
            <HeadlineItem
              value={content}
              readOnly={readOnly}
              onDelete={() => handleRemoveItem(id)}
              onChange={handleEditorChange(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              dragging={dragging}
              {...props}
            />
          );
        },
      },
      infoPanel: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const { content, infoType, id } = item as InfoItem;
          return (
            <InfoPanel
              value={content}
              readOnly={readOnly}
              infoType={infoType}
              handleInfoTypeChange={handleInfoTypeChange(id)}
              onDelete={() => handleRemoveItem(id)}
              onChange={handleEditorChange(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              dragging={dragging}
              {...props}
            />
          );
        },
      },
      upload: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const { id, mediaType } = item as GridUploadItem;
          return (
            <UploadItem
              id={id}
              type={mediaType}
              readOnly={readOnly}
              onDelete={() => handleRemoveItem(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              onChangeMediaType={handleMediaTypeChange(id)}
              onImageUpload={handleSetImage(id)}
              onVideoUpload={handleSetVideo(id)}
              dragging={dragging}
              {...props}
            />
          );
        },
      },
      image: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const { id, url, height, aspectRatio, href, fileName } = item as ImageGridItem;
          return (
            <ImageItem
              id={id}
              url={url}
              href={href}
              fileName={fileName}
              height={height}
              aspectRatio={aspectRatio}
              readOnly={readOnly}
              onDelete={() => handleRemoveItem(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              onHrefUpdate={handleHrefChange(id)}
              onDownload={() => downloadFile(url, fileName)}
              onUploadImage={handleImageUpload(id)}
              onAddCaption={() => {}}
              dragging={dragging}
              {...props}
            />
          );
        },
      },
      trainings: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const i = item as GridTrainingsItem;
          const { id, selectedItems, title = '', icon, mode = 'default', trainingsType } = i;

          return (
            <TrainingItemWithTrainings
              title={title}
              icon={icon}
              mode={mode}
              onTitleChange={handleTrainingsTitleChange(id)}
              onIconChange={handleTrainingsIconChange(id)}
              onTrainingsChange={handleSelectedTrainingsChange(id)}
              selectedItems={selectedItems}
              readOnly={readOnly}
              onDelete={() => handleRemoveItem(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              dragging={dragging}
              trainingsType={trainingsType}
              handleTrainingsTypeChange={handleTrainingsTypeChange(id)}
              trainingId={id}
              columnsCountNum={1}
              {...props}
            />
          );
        },
      },
      video: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const {
            videoId,
            id,
            url,
            fileName,
            height,
            aspectRatio,
            videoPoster,
            title = '',
            isFastForwardDisabled,
            isVideoRequired,
            isVideoUploaded,
          } = item as VideoGridItem;

          return (
            <VideoWrapper
              id={id}
              videoId={videoId}
              title={title}
              url={url}
              videoPoster={videoPoster}
              readOnly={readOnly}
              height={height}
              aspectRatio={aspectRatio}
              onDelete={() => handleRemoveItem(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              onVideoDownload={() => downloadVideo(videoId, fileName)}
              onReplaceVideo={handleReplaceVideo(id)}
              onUpdateVideoTitle={handleUpdateVideoTitle(id)}
              onUpdateVideoPoster={handleUpdateVideoPoster(id)}
              dragging={dragging}
              isFastForwardDisabled={isFastForwardDisabled}
              isVideoRequired={isVideoRequired}
              onDisableFastForward={handleDisableForwardVideo(id)}
              onRequired={handleRequiredVideo(id)}
              isVideoUploaded={isVideoUploaded}
              {...props}
            />
          );
        },
      },
      embedded: {
        render: ({ item, dragging, ...props }) => {
          const { id, height, contentType, content } = item as GridEmbeddedItem;
          return (
            <EmbeddedItem
              onDelete={() => handleRemoveItem(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              dragging={dragging}
              height={height}
              contentType={contentType}
              content={content}
              onUpdate={handleUpdateEmbeddedItem(id)}
              {...props}
            />
          );
        },
      },
      trainingModules: {
        render: ({ dragging, readOnly }) => (
          <TrainingModulesWidget dragging={dragging} readOnly={readOnly} />
        ),
      },
    };
    return configs;
  }, [
    handleRemoveItem,
    handleEditorChange,
    handleInfoTypeChange,
    handleDuplicateItem,
    handleMediaTypeChange,
    handleSetImage,
    handleImageUpload,
    handleReplaceVideo,
    handleSetVideo,
    handleUpdateVideoPoster,
    handleUpdateVideoTitle,
    handleTrainingsTitleChange,
    handleTrainingsIconChange,
    handleSelectedTrainingsChange,
    handleUpdateEmbeddedItem,
    handleTrainingsTypeChange,
    handleDisableForwardVideo,
    handleRequiredVideo,
    handleHrefChange,
  ]);

  return elementConfigs;
};

export default useElementConfigs;
