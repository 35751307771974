import { MenuItem, MenuItemProps, styled } from '@mui/material';

const StyledMenuItem = styled(MenuItem)`
  cursor: pointer;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  margin: 8px 10px;
  padding: 8px 20px !important;
  &.Mui-selected: {
    background-color: theme.palette.backgroundHover.main;
  },
  &:hover: {
    background-color: theme.palette.backgroundHover.main;
  },
`;

function WorkbaseDropdownMenuItem(props: MenuItemProps & { dataCy?: string }) {
  const { onClick, sx, ...otherProps } = props;
  return <StyledMenuItem onClick={onClick} sx={sx} {...otherProps} />;
}

export default WorkbaseDropdownMenuItem;
