import { useSettingsQuery } from 'src/store/api/settingsApi';

export function useBrandingColors() {
  const { data } = useSettingsQuery();
  let primaryColor;

  if (data?.brandingColors) {
    data.brandingColors.forEach(({ type, color }: { type: string; color: string }) => {
      if (type === 'primaryMain') {
        primaryColor = color;
      }
    });
  }

  return primaryColor;
}
