export const SIDEBAR_FULL_WIDTH: number = 268;
export const SIDEBAR_COLLAPSED_WIDTH: number = 108;
export const SIDEBAR_BOTTOM_SPACING: number = 28;

export const CONTENT_RIGHT_GAP: number = 20;

export const TWO_COL_LAYOUT_LEFT_CARD_WIDTH: number = 412;
export const TWO_COL_LAYOUT_LEFT_GAP: number = 426;

export const APP_LOGO_HEIGHT: number = 56;
