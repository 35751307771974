import { useEffect, useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { WorkbaseWikiIcon } from 'src/assets/icons/workbaseIcons';
import Placeholder from 'src/lib/components/molecules/placeholder';
import { PermissionCodes } from 'src/lib/enums/permissions';
import { useUserPermissionsQuery } from 'src/store/api/userPermissionsApi';
import useMaintainEditPath from 'src/lib/hooks/useMaintainEditPath';
import { useShareLevelQuery } from 'src/store/api/shareResourceApi';
import PortalComponent from 'src/lib/components/util/PortalComponent';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/store';
import { ShareLevel } from 'src/lib/types/share-level';
import ModeSwitch from 'src/core/ModeSwitch';
import { useFetchWikiCardsSidebarQuery } from '../../../store/api/wikiApi.slice';
import { convertData } from '../controller/helper';
import WikiSidebar from './WikiSidebar';
import WikiContent from './WikiContent';

export default function Wiki() {
  const { t } = useTranslation();
  const { data: userPermissions } = useUserPermissionsQuery();
  const { smallestIndex: focusedIndex, shareLevelFocused } = useAppSelector(
    (state) => state.wikiCards
  );
  const path = useMaintainEditPath();
  const { id } = useParams<{ id: string }>();
  const { data: sidebarData, isFetching } = useFetchWikiCardsSidebarQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  // for edit mode
  const { error: shareLevelError, data: shareLevelData } = useShareLevelQuery(
    {
      resourceType: 'wikiCard',
      resourceId: id ?? '',
    },
    { refetchOnMountOrArgChange: true }
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasAddWikiPermission = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.ADD_WIKIS
  );
  const canEdit = sidebarData
    ? ![ShareLevel.view, ShareLevel.none].includes(shareLevelFocused)
    : false;
  // handle some random links + maintain edit mode
  useEffect(() => {
    if (shareLevelError) {
      if (!sidebarData?.length || !path) {
        navigate('/wiki');
      } else {
        navigate(`/wiki/${sidebarData[focusedIndex]?.id}/edit`);
      }
    }
  }, [shareLevelError, sidebarData, navigate, focusedIndex, path]);
  // handle permission change
  useEffect(() => {
    if (
      path &&
      !shareLevelError &&
      [ShareLevel.view, ShareLevel.none].includes(shareLevelData?.shareLevel as ShareLevel)
    ) {
      navigate(`/wiki`);
    }
  }, [path, shareLevelData, navigate, shareLevelError]);

  const convertedData = useMemo(
    () => (sidebarData ? convertData(sidebarData, t, dispatch, navigate) : []),
    [sidebarData, navigate, t, dispatch]
  );

  const text = hasAddWikiPermission ? t('wikiPage.addWikiPlaceholder') : undefined;
  return (
    <ModeSwitch
      switchButtonVisible={
        canEdit ||
        [ShareLevel.edit, ShareLevel.manage].includes(shareLevelData?.shareLevel ?? ShareLevel.none)
      }
    >
      {(editMode) => (
        <Stack direction="row" height="100%" alignItems="top">
          <PortalComponent elementSelector="#main-layout-content-card">
            <WikiSidebar
              hideSidebar={!hasAddWikiPermission && sidebarData?.length === 0}
              data={convertedData}
              hasAddPermission={hasAddWikiPermission}
              activeKey={editMode ? id : convertedData[focusedIndex]?.key}
              editMode={editMode}
            />
          </PortalComponent>
          {!sidebarData?.length && !isFetching ? (
            <Stack justifyContent="center" alignItems="center" height="100%" width="100%">
              <Box width={251}>
                <Placeholder
                  icon={WorkbaseWikiIcon}
                  title={t('wikiPage.noWikiPlaceholder')}
                  text={text}
                />
              </Box>
            </Stack>
          ) : (
            <WikiContent
              data={sidebarData || []}
              id={id as string}
              editMode={editMode}
              managePermission={
                shareLevelFocused === ShareLevel.manage ||
                shareLevelData?.shareLevel === ShareLevel.manage
              }
              addPermission={hasAddWikiPermission}
            />
          )}
        </Stack>
      )}
    </ModeSwitch>
  );
}
