import { Avatar, AvatarGroup, Badge, Stack, Tooltip } from '@mui/material';
import { useAppSelector } from 'src/store';
import { useTranslation } from 'react-i18next';
import { AwarenessUserData } from './controller/awareness.slice';

const MAX_AVATARS_TO_DISPLAY_COUNT = 5;

export default function AwarenessAvatars() {
  const awarenessState = useAppSelector((state) => state.awareness.awarenessState);
  const remainingUsers = awarenessState.slice(MAX_AVATARS_TO_DISPLAY_COUNT - 1);
  const { t } = useTranslation();
  const getUserTitle = (user: AwarenessUserData, isEditing: boolean) =>
    `${user.firstName} ${user.lastName} – ${
      isEditing ? t('editor.topBar.avatars.editMode') : t('editor.topBar.avatars.readMode')
    }`;
  return (
    <AvatarGroup
      max={MAX_AVATARS_TO_DISPLAY_COUNT}
      renderSurplus={(surplus) => (
        <Tooltip
          title={
            <Stack>
              {remainingUsers.map(({ clientId, user, isEditing }) => (
                <span key={clientId}>{getUserTitle(user, isEditing)}</span>
              ))}
            </Stack>
          }
        >
          <span>+{surplus.toString()}</span>
        </Tooltip>
      )}
    >
      {awarenessState.map(({ clientId, user, isEditing }) => {
        if (!user) return null;
        const avatar = (
          <Avatar
            sx={{
              width: 32,
              height: 32,
            }}
            src={user.avatarUrl || undefined}
          />
        );
        let content = avatar;
        if (isEditing) {
          content = (
            <Badge
              overlap="circular"
              color="success"
              variant="dot"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              {avatar}
            </Badge>
          );
        }
        return (
          <Tooltip key={clientId} title={getUserTitle(user, isEditing)} placement="top">
            {content}
          </Tooltip>
        );
      })}
    </AvatarGroup>
  );
}
