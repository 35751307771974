import { Box, useTheme, alpha } from '@mui/material';
import { motion } from 'framer-motion';
import Links from 'src/features/editor/view/links';
import { useAppSelector } from 'src/store';

interface LinksContainerProps {
  inView: boolean;
  readOnly: boolean;
}

function CustomSiteLinksContainer(props: LinksContainerProps) {
  const theme = useTheme();
  const { inView, readOnly } = props;
  const links = useAppSelector((state) => state.editor.editorData?.links) || [];
  const stickyMode = !inView && readOnly;

  if (links.length === 0 && readOnly) return null;

  return (
    <Box
      component={motion.div}
      initial={{
        margin: '-40px 20px 20px 20px',
        borderRadius: `0 0 ${theme.shape.borderRadius * 4} ${theme.shape.borderRadius * 4}`,
        backdropFilter: 'none',
      }}
      animate={{
        margin: stickyMode ? '-40px 0px 0px 0px' : '-40px 20px 20px 20px',
        backdropFilter: stickyMode ? 'blur(10px)' : 'none',
        backgroundColor: stickyMode
          ? alpha(theme.palette.common.white, 0.8)
          : theme.palette.background.default,
        boxShadow: stickyMode
          ? `0px 0px 4px 0px ${alpha(theme.palette.common.black, 0.05)}`
          : 'none',
        borderRadius: stickyMode
          ? `0`
          : `0 0 ${theme.shape.borderRadius * 4} ${theme.shape.borderRadius * 4}`,
      }}
      transition={{ type: 'tween', duration: 0.3 }}
      sx={{
        backgroundColor: theme.palette.background.default,
        padding: '36px 16px 6px',
        position: readOnly ? 'sticky' : 'static',
        top: '-40px',
        zIndex: 3,
        borderRadius: ({ shape }) => `0 0 ${shape.borderRadius * 4} ${shape.borderRadius * 4}`,
      }}
    >
      <Box sx={{ maxWidth: 1246, margin: '0 auto' }}>
        <Links readOnly={readOnly} />
      </Box>
    </Box>
  );
}

export default CustomSiteLinksContainer;
