import { Paper, PaperProps, styled } from '@mui/material';

// Define the custom props interface
interface CustomPaperProps extends PaperProps {
  float?: boolean;
}

// Create the styled component with the custom props
const WorkbasePaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'float',
})<CustomPaperProps>(({ theme, float }) => ({
  marginTop: float ? 0.5 : 'inherit',
  marginLeft: '-1px',
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: float
    ? theme.shape.borderRadius * 2.5
    : `0 0 ${theme.shape.borderRadius * 2.5} ${theme.shape.borderRadius * 2.5}`,
  boxShadow: `2px 0 2px -1px ${theme.palette.common.black}04, -2px 0 2px -1px ${theme.palette.common.black}04, 0 2px 2px -1px ${theme.palette.common.black}04`,
  '& .MuiAutocomplete-listbox': {
    maxHeight: 300,
    padding: '0 ',
  },
}));

export default WorkbasePaper;
