import { Checkbox } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/store';
import { Role } from '../../../model/types';
import { checkRoles, uncheckRoles } from '../../../controller/departments.slice';

interface Props {
  role: Role;
}

export default function RoleCheckbox({ role }: Props) {
  const dispatch = useAppDispatch();
  const checkedRoles = useAppSelector((state) => state.departments.checkedRoles);

  return (
    <Checkbox
      data-сy={`role-checkbox-${role.id}`}
      checked={checkedRoles.includes(role.id)}
      onChange={(e) => {
        if (!e.target.checked) {
          dispatch(uncheckRoles([role.id]));
        } else {
          dispatch(checkRoles([role.id]));
        }
      }}
    />
  );
}
