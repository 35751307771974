import { Paper, Stack } from '@mui/material';
import { useState } from 'react';
import { useScreenWidth } from 'src/lib/hooks/use-screen-width';
import Header from './Header';
import { filterItemsBySearchVal } from '../../../lib/utils/search-util';
import {
  SECONDARY_SIDEBAR_WIDTH_BREAKPOINT,
  SECONDARY_SIDEBAR_WIDTH_FULL,
  SECONDARY_SIDEBAR_WIDTH_SMALL,
} from './constants';
import ItemList from './items';
import { SecondarySidebarHeaderIcon, SecondarySidebarItem } from './types';

interface Props {
  items: SecondarySidebarItem[];
  activeKey: string;
  headerIcon?: SecondarySidebarHeaderIcon;
  disableSearchBar?: boolean;
  withDragAndDrop?: boolean;
  reorderFunction?: (obj: { id: string; order: number; source: number }) => void;
  noDivider?: boolean;
  dataCy?: string;
}

export default function SecondarySidebar({
  items,
  activeKey,
  headerIcon,
  disableSearchBar = false,
  withDragAndDrop = false,
  reorderFunction,
  noDivider = false,
  dataCy,
}: Props) {
  const [searchVal, setSearchVal] = useState<string>('');
  const handleSearchValChange = (val: string) => setSearchVal(val);
  const filteredGroups = filterItemsBySearchVal(items, searchVal);
  const screenWidth = useScreenWidth();
  const enableDnD = withDragAndDrop && !!reorderFunction && !searchVal;
  return (
    <Stack
      // TODO: make this width responsive
      width={
        screenWidth > SECONDARY_SIDEBAR_WIDTH_BREAKPOINT
          ? SECONDARY_SIDEBAR_WIDTH_FULL
          : SECONDARY_SIDEBAR_WIDTH_SMALL
      }
      px={2}
      pt={2}
      component={Paper}
      elevation={1}
      height="100%"
    >
      <Stack gap={2} height="100%">
        <Header
          iconData={headerIcon}
          searchVal={searchVal}
          handleSearchValChange={handleSearchValChange}
          disableSearchBar={disableSearchBar}
          dataCy={dataCy}
        />
        <ItemList
          noDivider={noDivider}
          data={filteredGroups}
          activeKey={activeKey}
          dataCy={dataCy}
          onMove={
            enableDnD
              ? ({ id, hoverIndex }: { id: string; hoverIndex: number }) => {
                  const idxOfMoveItem = filteredGroups.findIndex((item) => item.key === id);
                  reorderFunction({
                    id,
                    order: hoverIndex,
                    source: idxOfMoveItem,
                  });
                }
              : undefined
          }
        />
      </Stack>
    </Stack>
  );
}
