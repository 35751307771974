import { Box, styled } from '@mui/material';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import BaseEditor from '../base-editor/BaseEditor';
import HighlightItem from '../highlight-item';
import { ResizeStopCallbackType } from '../highlight-item/resize/ResizableWrapper';

interface Props {
  onDelete: () => void;
  onDuplicate: () => void;
  value?: string;
  readOnly?: boolean;
  onChange?: (val: string) => void;
  onResizeStop?: ResizeStopCallbackType;
  dragging?: {
    dragRef: ConnectDragSource;
    previewRef: ConnectDragPreview;
  };
}

const StyledWrapper = styled(Box)`
  padding: 8px 16px;
  border-radius: ${({ theme }) => theme.shape.borderRadius * 4};
  background: ${({ theme }) => theme.palette.background.default};
`;

export default function HeadlineItem({
  onDelete,
  onDuplicate,
  value,
  readOnly = false,
  onChange,
  onResizeStop,
  dragging,
}: Props) {
  return (
    <StyledWrapper>
      <HighlightItem
        editBarProps={{
          baseActionsProps: { onDelete, onDuplicate },
          textActionsProps: { textAllignmentHidden: false },
        }}
        dragging={dragging}
        sx={{ maxWidth: 1240, margin: '0 auto', backgroundColor: 'background.default' }}
        readOnly={readOnly}
        onResizeStop={onResizeStop}
        borderColor="transparent"
      >
        {({ EditBarComponent }) => (
          <BaseEditor value={value} onChange={onChange} readOnly={readOnly} singleLine>
            {EditBarComponent}
          </BaseEditor>
        )}
      </HighlightItem>
    </StyledWrapper>
  );
}
