import { Box } from '@mui/material';
import { useDrop } from 'react-dnd';
import { useEditor } from 'src/features/editor/controller';
import { GridItem, GridRow } from '../../../model';
import useDebouncedIsOver from './useDebouncedIsOver';

const getIsDroppingActive = ({
  item,
  rowId,
  row,
  itemColumnEnd,
}: {
  item: GridItem;
  rowId: string;
  row?: GridRow;
  itemColumnEnd: number;
}) => {
  const isDraggingWithinTheSameRow = rowId === row?.id;
  const isDraggingItemToSamePosition = itemColumnEnd === item?.columnStart;
  return (
    !(isDraggingWithinTheSameRow && isDraggingItemToSamePosition) && item?.elementType !== 'heading'
  );
};

export default function DroppableBetweenRowItems({
  itemColumnEnd,
  rowId,
}: {
  itemColumnEnd: number;
  rowId: string;
}) {
  const { handleDropIntoFullRow } = useEditor();
  const [{ isOver, item, row }, drop] = useDrop({
    accept: 'GRID_ITEM',
    drop: (i: { rowIndex: number; item: GridItem; row: GridRow }, monitor) => {
      if (monitor && getIsDroppingActive({ item: i.item, rowId, row: i.row, itemColumnEnd })) {
        handleDropIntoFullRow({
          itemId: i.item.id,
          targetRowId: rowId,
          columnEndPosition: itemColumnEnd,
        });
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      row: monitor.getItem()?.row,
      item: monitor.getItem()?.item,
    }),
  });
  const debouncedIsOver = useDebouncedIsOver(isOver);
  const isActive = debouncedIsOver && getIsDroppingActive({ item, rowId, row, itemColumnEnd });
  return (
    <Box
      ref={drop}
      sx={{
        width: '16px',
        position: 'absolute',
        paddingRight: '5px',
        paddingLeft: '5px',
        right: '0px',
        height: 'calc(100% - 12px)',
        zIndex: 4,
        top: 6,
      }}
    >
      <Box
        sx={{
          backgroundColor: isActive ? 'info.main' : 'transparent',
          width: '100%',
          height: '100%',
          borderRadius: (theme) => theme.shape.borderRadius,
        }}
      />
    </Box>
  );
}
