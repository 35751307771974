import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { wikiApi } from 'src/store/api/wikiApi.slice';
import { useNavigate } from 'react-router-dom';
import { AnyAction } from '@reduxjs/toolkit';
import { Box, CircularProgress } from '@mui/material';
import {
  setBlockChangingVisibility,
  setHasMoreElements,
  setLastChunkIds,
  setSmallestIndexForce,
  setWasDragged,
} from '../controller/wikiCards.slice';
import WikiCardList from './card/WikiCardList';
import {
  getEvery10thElementId,
  getNextDivisibleBy10,
  resetToTop,
  scrollToIndex,
} from '../controller/helper';

export default function WikiViewMode({
  lastChunkIds,
  data,
  focusedIndex,
  wasDragged,
  hasMoreElements,
  id,
}: {
  lastChunkIds: any[];
  data: any[];
  focusedIndex: number;
  hasMoreElements: boolean;
  wasDragged: boolean;
  id: string;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!data.length) return;
    dispatch(setBlockChangingVisibility(true));
    if (window.location.pathname.split('/')[3] === '') navigate(`/wiki`); // when left edit mode, dont keep id in url
    dispatch(wikiApi.util.invalidateTags(['WikiSidebar']));
    if (wasDragged) {
      dispatch(setLastChunkIds([null]));
      dispatch(setSmallestIndexForce(null));
      dispatch(setHasMoreElements(true));
      dispatch(setWasDragged(false));
      dispatch(setBlockChangingVisibility(false));
    } else {
      dispatch(setSmallestIndexForce(focusedIndex));
    }
    const element = document.getElementById(id ?? data[focusedIndex]?.id);
    if (element) {
      element.scrollIntoView({ behavior: 'instant', block: 'start' });
    } else {
      dispatch(
        wikiApi.endpoints.fetchWikiCards.initiate({
          startingAfter: null,
          limit: getNextDivisibleBy10(focusedIndex + 10) - 1,
        }) as unknown as AnyAction
      )
        .then((res: any) => {
          const ids = getEvery10thElementId(res.data);
          dispatch(setLastChunkIds([null, ...ids]));

          setTimeout(() => scrollToIndex(dispatch, res.data[focusedIndex].id, focusedIndex));
        })
        .catch(() => {
          resetToTop(dispatch, data[focusedIndex].id);
        });
    }
    // execute only on re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display="flex" flexDirection="column">
      {lastChunkIds.map((chunkId, idx) => (
        <WikiCardList key={chunkId} firstId={chunkId} index={idx} />
      ))}
      {
        // TODO: IMPROVE WITH SKELETONS
        hasMoreElements && (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="100px">
            <CircularProgress />
          </Box>
        )
      }
    </Box>
  );
}
