import { useRef, useState } from 'react';
import { Box, ListItemButton, ListItemIcon, Popover } from '@mui/material';
import SvgIconOrEmoji from 'src/ui-components/utils/icon/SvgIconOrEmoji';
import HierarchicalTree from 'src/ui-components/custom/hierarchical-tree/Tree';
import { TreeNodeModel } from 'src/ui-components/custom/hierarchical-tree/types';

interface Props {
  data: TreeNodeModel;
  descendants: TreeNodeModel[];
}

const popoverId = 'mouse-over-popover';

export default function ShrankTreeItem({ data, descendants }: Props) {
  const [openedPopover, setOpenedPopover] = useState(false);
  const popoverAnchor = useRef(null);

  const popoverEnter = () => {
    setOpenedPopover(true);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };
  const isItemActive = data.data?.active || !!descendants.find((d) => d.data?.active);
  return (
    <>
      <ListItemButton
        data-cy={data.id}
        ref={popoverAnchor}
        aria-owns={openedPopover ? popoverId : undefined}
        aria-haspopup="true"
        selected={isItemActive}
        dense
        onClick={() => {
          data.data?.onClick();
        }}
        onMouseEnter={descendants.length !== 0 ? popoverEnter : undefined}
        onMouseLeave={descendants.length !== 0 ? popoverLeave : undefined}
      >
        {data.data?.icon && (
          <ListItemIcon sx={{ minWidth: 0 }}>
            <SvgIconOrEmoji content={data.data?.icon} fontSize="small" />
          </ListItemIcon>
        )}
      </ListItemButton>
      <Popover
        id={popoverId}
        open={openedPopover}
        sx={{
          pointerEvents: 'none',
        }}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            onMouseEnter: popoverEnter,
            onMouseLeave: popoverLeave,
          },
        }}
        disableRestoreFocus
      >
        <Box
          width={216}
          sx={{
            pointerEvents: 'auto',
          }}
        >
          <HierarchicalTree
            canDrop={() => false}
            canDrag={() => false}
            onDrop={() => {}}
            disableListPaddings
            tree={descendants.map((item) => ({
              ...item,
              parent: item.parent === data.id ? 0 : item.parent,
              data: item.data && {
                ...item.data,
                onClick: () => {
                  item.data?.onClick();
                  popoverLeave();
                },
              },
            }))}
          />
        </Box>
      </Popover>
    </>
  );
}
