import { format, getTime, formatDistanceToNow } from 'date-fns';

type InputValue = Date | string | number | null | undefined;

export function formatDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function formatDateFromTimeStamp(date: InputValue) {
  const fm = 'dd.MM.yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function formatDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function formatTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function formatToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}
