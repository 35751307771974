import { Stack } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PermissionCodes } from 'src/lib/enums/permissions';
import { useUserPermissionsQuery } from 'src/store/api/userPermissionsApi';
import GlobalSearch from 'src/features/global-search';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import Quickmenu from 'src/features/quickmenu';
import { useSettingsQuery } from 'src/store/api/settingsApi';
import ProfileMenu from './nav-buttons/ProfileMenu';
import IconButtons from './nav-buttons/IconButtons';
import getIconData from '../controller/iconsDataController';
import RecommendationPopup from '../../recommendation-popup';

function Navbar() {
  const [isMotivationPopupActive, setMotivationPopupActive] = useState(false);
  const quickMenuAnchorRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const [isQuickMenuOpen, setQuickMenuOpen] = useState<boolean>(false);
  const { data } = useCurrentUserQuery();
  const { data: userPermissions } = useUserPermissionsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const isOwner = data?.isOwner;
  const location = useLocation();
  const { data: settingsData } = useSettingsQuery();

  const memoizedData = useMemo(() => {
    const permissions = {
      hasManageDepartmentsPermission: userPermissions?.some(
        (permission) => permission.code === PermissionCodes.MANAGE_DEPARTMENTS
      ),
      hasViewEmployeePermission: userPermissions?.some(
        (permission) => permission.code === PermissionCodes.VIEW_EMPLOYEES
      ),
      hasManageAllCompanySettingsPermission: userPermissions?.some(
        (permission) => permission.code === PermissionCodes.MANAGE_ALL_COMPANY_SETTINGS
      ),
      hasManageBillingPermission: userPermissions?.some(
        (permission) => permission.code === PermissionCodes.MANAGE_BILLING
      ),
    };

    const icons = getIconData(
      setMotivationPopupActive,
      settingsData,
      navigate,
      location,
      isOwner,
      permissions
    );

    return { permissions, icons };
  }, [userPermissions, settingsData, navigate, location, isOwner]);

  return (
    <Stack py={1} ref={quickMenuAnchorRef} position="relative" bgcolor="background.default">
      <Quickmenu
        dataCy="quickmenu"
        onClose={() => setQuickMenuOpen(false)}
        isOpen={isQuickMenuOpen}
        anchorEl={quickMenuAnchorRef.current}
      />
      <RecommendationPopup
        active={isMotivationPopupActive}
        setActive={setMotivationPopupActive}
        dataCy="employee-recommendation"
      />

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <GlobalSearch />
        <Stack direction="row" spacing={2} alignItems="center">
          <IconButtons data={memoizedData.icons} dataCy="nav-icon-button" />
          <ProfileMenu
            isQuickMenuOpen={isQuickMenuOpen}
            setQuickMenuOpen={setQuickMenuOpen}
            avatarUrl={data?.avatarUrl || ''}
            isImpersonated={data?.isImpersonated || false}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Navbar;
