import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';

interface ProgressWithContentProps {
  children: ReactNode;
  circularProgressProps: CircularProgressProps;
}

function ProgressWithContent({ children, circularProgressProps }: ProgressWithContentProps) {
  return (
    <Box display="inline-flex" position="relative">
      <CircularProgress {...circularProgressProps} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {children}
      </Box>
    </Box>
  );
}

export default ProgressWithContent;
