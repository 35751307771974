import {
  TrainingModuleWithStatus,
  usePostTrainingModuleMutation,
} from 'src/store/api/trainings/trainingModulesApi';
import {
  EstimatedTime,
  LessonsCount,
  TrainingsProgress,
} from 'src/store/api/trainings/trainingsApi';
import { Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import EditingSingleModule from './EditingSingleModule';

interface Props {
  modules: TrainingModuleWithStatus[];
  estimatedTimeData?: EstimatedTime[];
  lessonsCountData?: LessonsCount[];
  progressData?: TrainingsProgress[];
}

export default function EditingModules({
  modules,
  estimatedTimeData,
  lessonsCountData,
  progressData,
}: Props) {
  const [postTrainingModule] = usePostTrainingModuleMutation();
  const params = useParams();
  const { t } = useTranslation();
  return (
    <>
      {modules.map((module, idx) => (
        <EditingSingleModule
          key={module.id}
          modules={modules}
          {...module}
          index={idx}
          lessonsCount={lessonsCountData?.find((item) => item.id === module.id)?.lessonsCount || 0}
          estimatedTime={
            estimatedTimeData?.find((item) => item.id === module.id)?.estimatedTimeToComplete || 0
          }
          progress={progressData?.find((item) => item.id === module.id)?.progress || 0}
        />
      ))}
      <Button
        color="inherit"
        onClick={() => {
          postTrainingModule({ trainingId: params.trainingId as string }).then((resp: any) => {
            if (resp?.data.id) {
              setTimeout(() => {
                document.getElementById(resp?.data.id)?.querySelector('input')?.focus();
              }, 100);
            }
          });
        }}
        sx={{ margin: modules.length >= 1 ? '-5px 20px 5px 20px' : '15px 20px 5px 20px' }}
      >
        <Typography variant="body1" fontWeight="medium">
          {t('trainingDetailPage.addModule')}
        </Typography>
      </Button>
    </>
  );
}
