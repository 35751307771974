import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { SourceProps } from 'react-player/base';
import ReactPlayer from 'react-player';
import throttle from 'lodash.throttle';
import { PlayerAction } from '../Player.reducer';

type UrlType = string | string[] | SourceProps[] | MediaStream | undefined;

export default function usePlayer(title: string, url: UrlType, dispatch: Dispatch<PlayerAction>) {
  const [smallVariant, setSmallVariant] = useState(false);
  const [titleValue, setTitleValue] = useState(title);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const canPlay = ReactPlayer.canPlay(url as string);

  const [visible, setVisible] = useState(true);
  const visibilityTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const resetVisibilityTimeout = useCallback(() => {
    if (visibilityTimeoutRef.current) {
      clearTimeout(visibilityTimeoutRef.current);
    }
    setVisible(true);
    visibilityTimeoutRef.current = setTimeout(() => {
      setVisible(false);
    }, 3000);
  }, []);
  useEffect(() => {
    const handleMouseMove = throttle(() => {
      resetVisibilityTimeout();
    }, 200);

    const playerElement = wrapperRef.current;
    if (playerElement) {
      playerElement.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      if (playerElement) {
        playerElement.removeEventListener('mousemove', handleMouseMove);
      }
      if (visibilityTimeoutRef.current) {
        clearTimeout(visibilityTimeoutRef.current);
      }
    };
  }, [resetVisibilityTimeout]);

  useEffect(() => {
    setSmallVariant(
      (wrapperRef.current?.clientWidth as number) < 400 ||
        (wrapperRef.current?.clientHeight as number) < 200
    );
  }, [wrapperRef.current?.clientWidth, wrapperRef.current?.clientHeight]);

  useEffect(() => {
    if (!canPlay) {
      dispatch({ type: 'LIGHT', payload: true });
    }
  }, [canPlay, dispatch]);

  return {
    smallVariant,
    setSmallVariant,
    titleValue,
    setTitleValue,
    wrapperRef,
    canPlay,
    visible,
  };
}
