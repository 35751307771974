import { Palette } from '@mui/material';

export function isMobile(): boolean {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
}

export async function downloadFile(url: string, fileName: string) {
  const response = await fetch(url);
  const blob = await response.blob();

  const href = URL.createObjectURL(blob);

  const anchorElement = document.createElement('a');
  anchorElement.href = href;
  anchorElement.download = fileName;

  document.body.appendChild(anchorElement);
  anchorElement.click();

  document.body.removeChild(anchorElement);
  window.URL.revokeObjectURL(href);
}

export function isValidUrl(url: string) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(url);
}

export function getErrorText(isUrlValid: boolean, isTextFieldEmpty: boolean) {
  if (isUrlValid) return 'The provided URL is invalid';
  if (isTextFieldEmpty) return 'Empty field';
  return '';
}

export const colorToHexCode = (color: string) => {
  // Returns the color as an array of [r, g, b, a] -- all range from 0 - 255
  // color must be a valid canvas fillStyle. This will cover most anything
  // you'd want to use.
  // Examples:
  // colorToRGBA('red')  # [255, 0, 0, 255]
  // colorToRGBA('#f00') # [255, 0, 0, 255]

  const cvs = document.createElement('canvas');
  cvs.height = 1;
  cvs.width = 1;
  const ctx = cvs.getContext('2d');
  if (!ctx) return undefined;
  ctx.fillStyle = color;
  return ctx.fillStyle;
};

export const getColorCode = (color: string) => {
  const el = document.createElement('div');
  el.style.backgroundColor = color;
  const validColor = el.style.backgroundColor;
  el.remove();
  return validColor ? colorToHexCode(validColor) : undefined;
};

export const getHexColor = (palette: Palette, color?: string) => {
  if (!color) return undefined;

  const colorCode = getColorCode(color);

  if (colorCode) return colorCode;
  const [mainColor, variant] = color.split('.');
  const colorScheme: any = palette[mainColor as keyof typeof palette];
  if (colorScheme) {
    const paletteColor = colorScheme[variant];
    if (paletteColor) {
      return paletteColor;
    }
    return colorScheme.main;
  }
  return undefined;
};

export function isColorCodeLight(color: string) {
  const colorCode = color;

  // Variables for red, green, blue values
  let r = 0;
  let g = 0;
  let b = 0;
  let hsp = 0;

  // Check the format of the color, HEX or RGB?
  if (colorCode.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    const colorCodeArray = colorCode.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    ) as RegExpMatchArray;

    r = +colorCodeArray[1];
    g = +colorCodeArray[2];
    b = +colorCodeArray[3];
  } else {
    // If hex --> Convert it to RGB
    const colorCodeNumber = +`0x${colorCode
      .slice(1)
      .replace(colorCode.length < 5 ? /./g : '', '$&$&')}`;

    // eslint-disable-next-line no-bitwise
    r = colorCodeNumber >> 16;
    // eslint-disable-next-line no-bitwise
    g = (colorCodeNumber >> 8) & 255;
    // eslint-disable-next-line no-bitwise
    b = colorCodeNumber & 255;
  }

  // HSP (Highly Sensitive Poo) equation
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  const isLightColor = hsp > 127.5;
  return isLightColor;
}

export const getRandomDummyVideo = (currentVideoUrl?: string) => {
  const dummpyVideos = [
    {
      src: 'https://storage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
      poster: 'https://storage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg',
    },
    {
      src: 'https://storage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
      poster: 'https://storage.googleapis.com/gtv-videos-bucket/sample/images/ElephantsDream.jpg',
    },
    {
      src: 'https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
      poster: 'https://storage.googleapis.com/gtv-videos-bucket/sample/images/ForBiggerBlazes.jpg',
    },
    {
      src: 'https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4',
      poster: 'https://storage.googleapis.com/gtv-videos-bucket/sample/images/ForBiggerEscapes.jpg',
    },
    {
      src: 'https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4',
      poster: 'https://storage.googleapis.com/gtv-videos-bucket/sample/images/ForBiggerFun.jpg',
    },
    {
      src: 'https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4',
      poster:
        'https://storage.googleapis.com/gtv-videos-bucket/sample/images/ForBiggerJoyrides.jpg',
    },
    {
      src: 'https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4',
      poster:
        'https://storage.googleapis.com/gtv-videos-bucket/sample/images/ForBiggerMeltdowns.jpg',
    },
    {
      src: 'https://storage.googleapis.com/gtv-videos-bucket/sample/SubaruOutbackOnStreetAndDirt.mp4',
      poster:
        'https://storage.googleapis.com/gtv-videos-bucket/sample/images/SubaruOutbackOnStreetAndDirt.jpg',
    },
    {
      src: 'https://storage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4',
      poster: 'https://storage.googleapis.com/gtv-videos-bucket/sample/images/TearsOfSteel.jpg',
    },
  ];

  const filteredVideos = dummpyVideos.filter((video) =>
    currentVideoUrl ? currentVideoUrl !== video.src : true
  );
  return filteredVideos[Math.floor(Math.random() * filteredVideos.length)];
};
