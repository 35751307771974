import React from 'react';
import { MenuItemProps, Stack, Typography, TypographyProps, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import { IconName, WorkbaseIcon, WorkbaseIconByName, WorkbaseIconType } from '../../atoms/icon';
import WorkbaseImage from '../../atoms/image';
import WorkbaseDropdownMenuItem from '../../atoms/menu-item/DropdownMenuItem';

export interface WorkbaseDropdownItemProps extends MenuItemProps {
  children?: React.ReactNode;
  icon?: WorkbaseIconType | IconName | string;
  avatarUrl?: string | null;
  primaryText?: string;
  fontWeight?: TypographyProps['fontWeight'];
  secondaryText?: string;
  textVariant?: TypographyProps['variant'];
  animate?: boolean;
  dataCy?: string;
}

const WorkbaseDropdownItem = React.forwardRef<HTMLDivElement, WorkbaseDropdownItemProps>(
  (
    {
      children,
      icon,
      avatarUrl,
      primaryText,
      textVariant = 'body1',
      fontWeight = 'medium',
      secondaryText,
      onClick,
      animate = false,
      dataCy,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();
    const variants = {
      hidden: { opacity: 0, height: 0 },
      visible: { opacity: 1, height: 'auto' },
    };
    const primaryTextColor: string =
      (props.color as unknown as string) ?? ('secondary.text' as unknown as string);
    function isWorkbaseIconType(ic?: WorkbaseIconType | string | IconName): ic is WorkbaseIconType {
      return typeof ic !== 'string' && !!ic && typeof ic.name === 'string';
    }

    const Item = (
      <WorkbaseDropdownMenuItem onClick={onClick} {...props} dataCy={dataCy}>
        {children || (
          <Stack direction="row" gap={1} sx={{ minWidth: 0 }}>
            {!avatarUrl &&
              (isWorkbaseIconType(icon) ? (
                <WorkbaseIcon icon={icon} size={20} color={props.color} />
              ) : (
                <WorkbaseIconByName iconName={icon as IconName} size={20} color={props.color} />
              ))}
            {avatarUrl && (
              <WorkbaseImage
                src={avatarUrl}
                alt={primaryText || 'avatar'}
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: theme.shape.borderRadius * 2.5,
                  backgroundSize: 'cover',
                }}
              />
            )}
            <Stack sx={{ minWidth: 0 }}>
              <Typography
                variant={textVariant}
                fontWeight={fontWeight}
                color={primaryTextColor}
                noWrap
              >
                {primaryText}
              </Typography>
              <Typography variant="body2" color="secondary" noWrap>
                {secondaryText}
              </Typography>
            </Stack>
          </Stack>
        )}
      </WorkbaseDropdownMenuItem>
    );

    return animate ? (
      <motion.div
        ref={ref}
        variants={variants}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.2, ease: 'easeInOut' }}
      >
        {Item}
      </motion.div>
    ) : (
      Item
    );
  }
);

export default WorkbaseDropdownItem;
