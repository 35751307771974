/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Menu,
  MenuItem,
  Popover,
  PopoverOrigin,
  Stack,
  styled,
  SxProps,
  Theme,
  Typography,
  alpha,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { WorkbaseIconByName, IconName } from '../icon';

export const menuStyles = (theme: Theme) => ({
  '&&&': {
    '& .MuiPaper-root': {
      padding: '10px 0',
      borderRadius: theme.shape.borderRadius * 4,
      border: `1px solid ${theme.palette.grey[100]}`,
      boxShadow: `0px 0px 5px 0px ${alpha(theme.palette.common.black, 0.03)}`,
      backgroundColor: theme.palette.common.white,
      marginTop: '2px',
    },
    '& .MuiList-root': {
      padding: '0 10px',
      maxHeight: '184px',
      overflow: 'auto',
    },
    '& .MuiMenuItem-root': {
      marginBottom: '4px',
      padding: '8px 12px 10px 12px',
      fontSize: '15px',
      lineHeight: '1.2',
      fontWeight: 500,
      borderRadius: theme.shape.borderRadius * 2.5,
      height: '38px',
      maxWidth: '220px',
      color: theme.palette.text.primary,
      gap: '8px',
      '&:hover, &.Mui-selected': {
        backgroundColor: theme.palette.grey[300],
      },
    },
    '& .MuiMenuItem-root:first-of-type': {
      marginTop: '10px',
    },
    '& .MuiMenuItem-root:last-of-type': {
      marginBottom: '10px',
    },
    '& p.MuiTypography-root': {
      fontSize: '15px',
      lineHeight: '1.2',
      fontWeight: 500,
      color: theme.palette.text.primary,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      margin: 0,
    },
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px',
      color: theme.palette.text.secondary,
    },
    '& .MuiPopover-paper': {
      padding: 0,
    },
  },
});

const wrapperStyle: SxProps<Theme> = (theme: Theme) => ({
  marginLeft: '14px',
  borderRadius: ({ shape }) => shape.borderRadius * 4,
  border: `1px solid ${theme.palette.grey[100]}`,
  boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.03)',
  backgroundColor: theme.palette.common.white,
  padding: '0 10px',
  maxHeight: '178px',
  overflow: 'auto',
  '& .MuiMenuItem-root': {
    padding: '8px 12px 10px 12px',
    fontSize: '15px',
    lineHeight: '1.2',
    fontWeight: 500,
    borderRadius: ({ shape }) => shape.borderRadius * 2.5,
    height: '38px',
    maxWidth: '220px',
    color: theme.palette.text.primary,
    gap: '8px',
    '&:hover, &.Mui-selected': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  '& .MuiMenuItem-root:first-of-type': {
    marginTop: '10px',
  },
  '& .MuiMenuItem-root:last-of-type': {
    marginBottom: '10px',
  },
  '& .MuiTypography-root': {
    fontSize: '15px',
    lineHeight: '1.2',
    fontWeight: 500,
    color: theme.palette.text.primary,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 0,
  },
  '& .MuiSvgIcon-root': {
    width: '20px',
    height: '20px',
    color: theme.palette.text.secondary,
  },
});

export const StyledMenu = styled(Menu)(({ theme }) => menuStyles(theme));

export type MenuItemType = {
  value: string;
  label: string;
  startIcon?: IconName;
  startIconColor?: string;
  children?: MenuItemType[];
  endIcon?: IconName;
  endIconColor?: string;
  onClick?: () => void;
  description?: string;
  divider?: boolean;
  prefix?: React.ReactNode;
};

interface ActionMenuProps {
  id?: string;
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  items: MenuItemType[];
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  sx?: SxProps<Theme>;
}

function NestedActionMenuItem({ item, onClose }: { item: MenuItemType; onClose: () => void }) {
  const anchorElementRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  const [anchorState, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setAnchorEl(anchorElementRef.current);
  }, [anchorElementRef]);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const handlePopoverOpen = () => {
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setOpen(false);
  };

  const dividerStyles = item.divider
    ? {
        marginTop: 2.5,
        '&::after': {
          content: "''",
          position: 'absolute',
          top: -10,
          left: -10,
          right: -10,
          height: '1px',
          backgroundColor: 'grey.300',
        },
      }
    : {};
  return (
    <>
      <MenuItem
        data-cy={`${item.value}`}
        key={item.value}
        value={item.value}
        ref={anchorElementRef}
        onClick={() => {
          item.onClick?.();
          handleClose();
        }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          position: 'relative',
          backgroundColor: open ? 'backgroundHover.main' : 'transparent',
          maxWidth: '332px !important',
          ...dividerStyles,
        }}
      >
        {item.prefix}
        {item.startIcon && (
          <WorkbaseIconByName iconName={item.startIcon} color={item.startIconColor} />
        )}
        <Stack
          sx={{
            flexShrink: 1,
            minWidth: 0,
            '&:not(:last-child)': {
              marginRight: 0.5,
            },
          }}
        >
          <Typography variant="body1">{item.label}</Typography>
          {item.description && (
            <Typography variant="body2" color="grey[500]" noWrap>
              {item.description}
            </Typography>
          )}
        </Stack>
        {(item.children || item.endIcon) && (
          <WorkbaseIconByName
            color={item.endIconColor}
            iconName={item.endIcon || 'WorkbaseChevronIcon'}
            sx={{
              '&.MuiSvgIcon-root': {
                width: item.endIcon ? 'auto' : '12px !important',
                height: item.endIcon ? 'auto' : '6.83px !important',
                marginLeft: 'auto',
              },
            }}
          />
        )}
      </MenuItem>
      {item.children && (
        <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorState}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          slotProps={{
            paper: {
              onMouseEnter: handlePopoverOpen,
              onMouseLeave: handlePopoverClose,
              sx: {
                padding: '10px 0',
                boxShadow: 'none',
                backgroundColor: 'transparent',
                ...(open && {
                  pointerEvents: 'auto',
                }),
                '&.MuiPopover-paper': {
                  padding: '0',
                },
              },
            },
          }}
          sx={{
            pointerEvents: 'none',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Stack sx={wrapperStyle}>
            <NestedActionMenu items={item.children} onClose={onClose} />
          </Stack>
        </Popover>
      )}
    </>
  );
}

function NestedActionMenu({ items, onClose }: { items: MenuItemType[]; onClose: () => void }) {
  return (
    <>
      {items.map((item) => (
        <NestedActionMenuItem key={item.value} item={item} onClose={onClose} />
      ))}
    </>
  );
}

export default function WorkbaseActionMenu({
  items,
  onClose,
  open,
  anchorEl,
  anchorOrigin,
  transformOrigin,
  id,
  sx,
}: ActionMenuProps) {
  return (
    <StyledMenu
      id={id}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
        ...anchorOrigin,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
        ...transformOrigin,
      }}
      sx={sx}
    >
      <NestedActionMenu items={items} onClose={onClose} />
    </StyledMenu>
  );
}
