import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DateValidationError } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

export const useApiKeyForm = (
  initialName: string = '',
  initialExpiryDate: Dayjs | null | string = null
) => {
  const { t } = useTranslation();
  const [name, setName] = useState(initialName);
  const [nameError, setNameError] = useState<string | null>(null);
  const [nameTouched, setNameTouched] = useState(false);
  const [expiryDate, setExpiryDate] = useState<Dayjs | null>(
    initialExpiryDate ? dayjs(initialExpiryDate) : null
  );
  const [dateError, setDateError] = useState<DateValidationError | null>(null);

  const validateName = useCallback(
    (value: string) => {
      if (value.trim().length === 0) {
        setNameError(t('companySettings.apiKeys.nameRequired'));
      } else if (value.length > 35) {
        setNameError(t('companySettings.apiKeys.nameTooLong'));
      } else {
        setNameError(null);
      }
    },
    [t]
  );

  useEffect(() => {
    if (nameTouched) {
      validateName(name);
    }
  }, [name, nameTouched, validateName]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleNameBlur = () => {
    setNameTouched(true);
    validateName(name);
  };

  const handleDateError = (error: DateValidationError) => {
    setDateError(error);
  };

  const handleDateChange = (date: Dayjs | null) => {
    setExpiryDate(date);
  };

  const reset = () => {
    setName('');
    setNameError(null);
    setNameTouched(false);
    setExpiryDate(null);
    setDateError(null);
  };

  return {
    name,
    setName,
    nameError,
    nameTouched,
    expiryDate,
    dateError,
    handleNameChange,
    handleNameBlur,
    handleDateError,
    handleDateChange,
    validateName,
    reset,
  };
};
