import { Button, Stack, SvgIcon } from '@mui/material';
import { useBoolean } from 'src/lib/hooks/use-boolean';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/store';
import { Role } from 'src/features/dashboard/controller/api';
import { WorkbaseAddSquareIcon } from 'src/assets/icons/workbaseIcons';
import AddEditLinkModal from './modals/AddEditLinkModal';
import Link from './Link';

export default function Links({ readOnly, roles }: { readOnly: boolean; roles?: Role[] }) {
  const links = useAppSelector((state) => state.editor.editorData?.links) || [];
  const { t } = useTranslation();
  const addLinkDialog = useBoolean();

  return (
    <Stack
      gap={2.5}
      direction="row"
      flexWrap="wrap"
      pt={
        (links && links.length > 0 && roles && roles.length <= 1) ||
        (!readOnly && roles && roles.length <= 1)
          ? 1.25
          : 0
      }
      pb={(links && links.length > 0) || !readOnly ? 1.25 : 0}
    >
      {links?.map(({ url, name, id, faviconUrl }) => (
        // eslint-disable-next-line
        <Link
          key={id}
          id={id}
          url={url}
          imageUrl={faviconUrl || undefined}
          readOnly={readOnly}
          linkName={name}
        />
      ))}
      {!readOnly && (
        <Button
          onClick={() => addLinkDialog.onTrue()}
          startIcon={
            <SvgIcon fontSize="inherit">
              <WorkbaseAddSquareIcon />
            </SvgIcon>
          }
          sx={{
            paddingLeft: '10px',
            paddingRight: '10px',
            flexGrow: 1,
            minWidth: 200,
          }}
        >
          {t('dashboardPage.header.addLinkButton')}
        </Button>
      )}
      {!readOnly && (
        <AddEditLinkModal open={addLinkDialog.value} onClose={() => addLinkDialog.onFalse()} />
      )}
    </Stack>
  );
}
