import axiosInstance from 'src/axios';
import { API_URL } from 'src/global-config';
import * as tus from 'tus-js-client';
import { showNotification } from '../components/atoms/notification';

const getVideoEndpoint = async (documentName?: string) => {
  const resp = await axiosInstance.post(`${API_URL}/videos/upload`, {
    editorDocumentName: documentName,
  });
  return resp.data;
};

interface UploadOptions {
  file: File;
  errorMessage: string;
  setPercentage?: (percentage: number) => void;
  onVideoUploadSuccess: (id: string) => void;
  documentName?: string;
  signal?: AbortSignal;
}

const videoUpload = async ({
  file,
  errorMessage,
  setPercentage,
  onVideoUploadSuccess,
  documentName,
  signal,
}: UploadOptions) => {
  const videoData = await getVideoEndpoint(documentName);
  const localUrl = URL.createObjectURL(file);
  const upload = new tus.Upload(file, {
    endpoint: videoData.endpoint,
    retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
    headers: {
      AuthorizationSignature: videoData.authorizationSignature,
      AuthorizationExpire: videoData.authorizationExpire,
      VideoId: videoData.videoId,
      LibraryId: videoData.libraryId,
    },
    metadata: {
      filename: file.name,
      filetype: file.type,
    },
    onError() {
      showNotification(errorMessage, 'error');
    },
    onProgress(bytesUploaded, bytesTotal) {
      const percentage = Math.ceil((bytesUploaded / bytesTotal) * 100);
      if (setPercentage) setPercentage(percentage);
    },
    async onSuccess() {
      onVideoUploadSuccess(videoData.videoId);
      try {
        await axiosInstance.get(`${API_URL}/videos/${videoData.videoId}`);
      } catch {
        showNotification(errorMessage, 'error');
      }
    },
  });

  signal?.addEventListener('abort', () => {
    upload.abort();
  });

  upload.start();

  return { localUrl, videoId: videoData.videoId };
};

export { getVideoEndpoint, videoUpload };
