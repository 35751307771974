import React from 'react';
import SliderComponent from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, alpha } from '@mui/material';
import { IconName, WorkbaseIconByName } from '../../atoms/icon';
import './slider.css';

export interface SliderProps {
  children: React.ReactNode;
  afterChange?: (index: number) => void;
  initialSlide?: number;
  infinite?: boolean;
}

function CustomPaging(props: any) {
  return (
    <Box {...props} sx={{ paddingTop: 1, paddingBottom: 1 }}>
      <Box
        sx={{
          height: 3,
          width: 20,
          borderRadius: 1.5,
          backgroundColor: 'grey.500',
        }}
      />
    </Box>
  );
}

function AppendDots(dots: React.ReactNode) {
  return (
    <Box
      sx={{
        '& .slick-active > div > div': {
          backgroundColor: 'text.secondary',
        },
        bottom: '-16px !important',
        '& li': {
          height: 'auto',
          margin: '0 2.5px',
        },
      }}
    >
      {dots}
    </Box>
  );
}

function Arrow({
  iconName,
  transform,
  onClick,
  className,
}: {
  iconName: IconName;
  transform: string;
  onClick?: () => void;
  className?: string;
}) {
  return (
    <Box
      className={className}
      onClick={onClick}
      sx={{
        '&::before': {
          content: 'none',
        },
        '&&&.slick-disabled svg': {
          color: 'grey.500',
          cursor: 'auto',
        },
      }}
    >
      <WorkbaseIconByName
        sx={{
          transform: `${transform} !important`,
          transition: 0.15,
          '&&&:hover': {
            color: (theme) => `${alpha(theme.palette.primary.main, 0.9)}`,
          },
        }}
        color="primary.main"
        size={30}
        iconName={iconName}
      />
    </Box>
  );
}

const settings = {
  infinite: false,
  customPaging: CustomPaging,
  appendDots: AppendDots,
  dots: true,
  nextArrow: <Arrow iconName="WorkbaseChevronIcon" transform="translate(-8px, -5px)" />,
  prevArrow: <Arrow iconName="WorkbaseChevronIcon" transform="rotate(180deg) translateY(5px)" />,
};

export default function Slider({
  children,
  afterChange,
  initialSlide,
  infinite = false,
}: SliderProps) {
  return (
    <SliderComponent
      {...settings}
      infinite={infinite}
      afterChange={afterChange}
      initialSlide={initialSlide}
    >
      {children}
    </SliderComponent>
  );
}
