import { Box, alpha } from '@mui/material';
import { TrainingLesson } from 'src/store/api/trainings/trainingLessonsApi';
import { Theme } from '@mui/material/styles';
import FinishLessonButton from './FinishLessonButton';

interface Props {
  lesson: TrainingLesson;
  disableFinishButton: boolean;
}

export default function LessonFooter({ lesson, disableFinishButton }: Props) {
  return (
    <Box
      sx={{
        backgroundColor: (theme: Theme) => alpha(theme.palette.common.white, 0.9),
        zIndex: 10,
        borderTopStyle: 'solid',
        borderTopColor: 'grey.300',
        borderTopWidth: 1,
        backdropFilter: 'blur(5px) saturate(100%)',
        padding: '20px 32px',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
      }}
    >
      <FinishLessonButton
        lessonId={lesson.id}
        nextPublishedTrainingLessonId={lesson.nextPublishedTrainingLessonId}
        disabled={disableFinishButton}
      />
    </Box>
  );
}
