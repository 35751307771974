import { PopperProps, Popper, styled } from '@mui/material';

const StyledPopper = styled(Popper)`
  position: fixed !important;
  width: 100%;
  maxheight: auto;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`;

export default function WorkbasePopper(props: PopperProps) {
  const { disablePortal = true, placement, sx, ...otherProps } = props;
  return (
    <StyledPopper
      container={null}
      disablePortal={disablePortal}
      modifiers={[
        {
          name: 'preventOverflow',
          enabled: false,
        },
        {
          name: 'flip',
          enabled: false,
        },
      ]}
      placement={placement ?? 'bottom-start'}
      sx={sx}
      {...otherProps}
    />
  );
}
