import { Divider, Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { WorkbaseRoleIcon } from 'src/assets/icons/workbaseIcons';
import { PermissionsType } from '../../model/types';
import PermissionGroupComponent from './group/PermissionGroup';

interface PermissionContentProps {
  checkedRoles: string[];
  permissions: PermissionsType;
}

export default function PermissionContent({ checkedRoles, permissions }: PermissionContentProps) {
  const { t } = useTranslation();

  const countRole =
    checkedRoles.length > 1
      ? t('companySettings.sidebar.departments.permissions.roles')
      : t('companySettings.sidebar.departments.permissions.role');

  return (
    <Stack overflow="auto" gap={2}>
      <Stack direction="row" alignItems="center" gap={1.25}>
        <SvgIcon component={WorkbaseRoleIcon} fontSize="small" color="primary" />
        <Typography variant="subtitle1" fontWeight="bold" align="left" color="primary.main">
          {t('companySettings.sidebar.departments.permissions.countTitle', {
            numberRole: [...new Set(checkedRoles)].length,
            countRole,
          })}
        </Typography>
      </Stack>
      {permissions.permissionGroups &&
        permissions.permissionGroups.map((permissionGroup, index: number) => (
          <Stack key={permissionGroup.id} gap={2}>
            <PermissionGroupComponent permissionGroup={permissionGroup} />
            {index < permissions.permissionGroups.length - 1 && <Divider />}
          </Stack>
        ))}
    </Stack>
  );
}
