import { SyntheticEvent, useEffect, useState } from 'react';

export interface WorkbaseImageProps {
  src: string;
  lazy?: boolean;
  height?: number | string;
  width?: number | string;
  alt: string;
  style?: React.CSSProperties;
  onLoad?: (event: SyntheticEvent<HTMLImageElement, Event>) => void;
  onError?: (event: SyntheticEvent<HTMLImageElement, Event>) => void;
  className?: string;
  fallbackSrc?: string | null;
  onFallbackLoaded?: () => void;
  dataCy?: string;
}

export default function WorkbaseImage({
  src,
  alt,
  fallbackSrc,
  onFallbackLoaded,
  dataCy,
  ...props
}: WorkbaseImageProps) {
  const [loadedSrc, setLoadedSrc] = useState<string | undefined | null>(fallbackSrc);
  const [isSrcLoaded, setIsSrcLoaded] = useState(false);

  useEffect(() => {
    const loadImage = (imageSrc: string) => {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        setLoadedSrc(imageSrc);
        if (imageSrc === src) {
          setIsSrcLoaded(true);
          onFallbackLoaded?.();
        }
      };
    };

    if (src !== loadedSrc) {
      setIsSrcLoaded(false);
      setLoadedSrc(fallbackSrc || src);

      if (fallbackSrc && !isSrcLoaded) {
        loadImage(fallbackSrc);
      }
      loadImage(src);
    }
  }, [src, fallbackSrc, isSrcLoaded, loadedSrc, onFallbackLoaded]);

  return <img src={loadedSrc || src} alt={alt} {...props} data-cy={dataCy} />;
}
