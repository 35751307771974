import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/store';

export const useDepartmentValid = (departmentName: string, departmentId?: string) => {
  const { t } = useTranslation();
  const { departmentsDraftState } = useAppSelector((state) => state.departments);
  const [departmentBlurred, setDepartmentBlurred] = useState(false);

  const isDepartmentNameUniq = !departmentsDraftState?.some((department) => {
    if (department.id === departmentId) return false;
    return department.name.toLowerCase().trim() === departmentName.toLowerCase().trim();
  });

  const isDepartmentEmpty = departmentName.trim() === '';
  const isDepartmentNameValid = !isDepartmentEmpty && isDepartmentNameUniq;

  let validationDepartmentMessage = '';
  if (isDepartmentEmpty) {
    validationDepartmentMessage = t('companySettings.sidebar.departments.departmentEmptyMessage');
  }
  if (!isDepartmentNameUniq) {
    validationDepartmentMessage = t(
      'companySettings.sidebar.departments.departmentValidationErrorMessages'
    );
  }

  const showDepartmentError = departmentBlurred && !isDepartmentNameValid;

  return {
    setDepartmentBlurred,
    showDepartmentError,
    validationDepartmentMessage,
    isDepartmentEmpty,
  };
};
