import { List, Stack } from '@mui/material';
import NavItem from './NavItem';
import useNavData from './useNavData';

interface NavGroupProps {
  fullWidth: boolean;
}

function NavGroup({ fullWidth }: NavGroupProps) {
  const data = useNavData();
  return (
    <List>
      <Stack gap={0.25}>
        {data.map((item) => (
          <NavItem key={item.id} item={item} labelVisible={fullWidth} />
        ))}
      </Stack>
    </List>
  );
}

export default NavGroup;
