import { ReactNode, useEffect, useState } from 'react';
import { Autocomplete, AutocompleteProps, Checkbox, Chip, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface SelectionMenuOption {
  id: string;
  label: string;
  additionalLabel?: ReactNode;
}

export interface SelectionMenuProps
  extends Omit<AutocompleteProps<SelectionMenuOption, true, undefined, undefined>, 'renderInput'> {
  disabled?: boolean;
  searchable?: boolean;
  options: SelectionMenuOption[];
  resetSearchTerm?: boolean;
  renderInput?: (params: object) => ReactNode;
}

interface CheckboxSelectionMenuProps extends Omit<SelectionMenuProps, 'onChange'> {
  selected?: string[];
  onChange?: (val: string[]) => void;
  dataCy?: string;
}

function ChipSelectionMenu({
  options,
  selected = [],
  onChange,
  disabled = false,
  dataCy,
  ...restOfProps
}: CheckboxSelectionMenuProps) {
  const { t } = useTranslation();
  const [selectOption, setSelectOption] = useState<SelectionMenuOption[]>([]);
  const [touched, setTouched] = useState(false);
  const hasError = touched && selectOption && selectOption.length === 0;

  useEffect(() => {
    setSelectOption(options.filter((option) => selected.includes(option.id)));
  }, [options, selected]);

  const handleCheck = (newOptions: SelectionMenuOption[]) => {
    setTouched(true);
    onChange?.(newOptions.map(({ id }) => id));
  };

  return (
    <Stack direction="column" gap={1}>
      {disabled ? (
        <Stack direction="row" maxWidth="100%" flexWrap="wrap" gap={1}>
          {selectOption.map((option) => (
            <Chip key={option.id} label={option.label} />
          ))}
        </Stack>
      ) : (
        <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          options={options}
          disabled={disabled}
          value={selectOption}
          onChange={(_, newValue) => handleCheck(newValue)}
          disableCloseOnSelect
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option, { selected: selectedState }) => {
            const { key, ...optionProps } = props;
            return (
              <li key={key} {...optionProps}>
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={selectedState || selected.includes(option.id)}
                />
                {option.label}
              </li>
            );
          }}
          {...restOfProps}
          renderInput={
            restOfProps.renderInput
              ? restOfProps.renderInput
              : (params) => (
                  <TextField
                    {...params}
                    fullWidth
                    placeholder={
                      !selectOption?.length ? t('addEmployees.roleSelectorLabel') : undefined
                    }
                    onBlur={() => setTouched(true)}
                    error={hasError}
                    helperText={
                      hasError ? t('employeePage.employeeDetails.contactInfomation.emptyRoles') : ''
                    }
                  />
                )
          }
        />
      )}
    </Stack>
  );
}

export default ChipSelectionMenu;
