import { Box } from '@mui/material';
import { useScreenWidth } from 'src/lib/hooks/use-screen-width';
import { wikiApi, WikiCardType } from 'src/store/api/wikiApi.slice';
import {
  SECONDARY_SIDEBAR_WIDTH_BREAKPOINT,
  SECONDARY_SIDEBAR_WIDTH_FULL,
  SECONDARY_SIDEBAR_WIDTH_SMALL,
} from 'src/ui-components/layout/secondary-sidebar/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';
import { useAppSelector } from 'src/store';
import { addLastChunkId, setBlockChangingVisibility } from '../controller/wikiCards.slice';
import WikiViewMode from './WikiViewMode';
import WikiEditMode from './WikiEditMode';

interface WikiContentProps {
  managePermission?: boolean;
  data: WikiCardType[];
  addPermission?: boolean;
  editMode: boolean;
  id: string;
}

export default function WikiContent({
  managePermission,
  addPermission,
  data,
  editMode,
  id,
}: WikiContentProps) {
  const screenWidth = useScreenWidth();
  const contentBoxRef = useRef<HTMLDivElement>(null);
  const { hasMoreElements, lastChunkIds, smallestIndex, forceIndex, wasDragged } = useAppSelector(
    (state) => state.wikiCards
  );
  const dispatch = useDispatch();
  const focusedIndex = forceIndex ?? smallestIndex;
  const lastQueryData = useSelector(
    wikiApi.endpoints.fetchWikiCards.select({
      startingAfter: lastChunkIds[lastChunkIds.length - 1],
      limit: 10,
    })
  ).data;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const element = contentBoxRef.current;
    if (element && !editMode) {
      const handleScroll = debounce(() => {
        const isValidElement =
          element?.scrollTop !== undefined &&
          element?.scrollHeight !== undefined &&
          element?.offsetHeight !== undefined;

        if (!isValidElement) return;
        const isScrollPositionValid =
          element.scrollTop >= element.scrollHeight - element.offsetHeight - 900 ||
          element.scrollTop === 0;
        if (!isScrollPositionValid) return;

        const dataItem = lastQueryData?.[lastQueryData.length - 1];
        const isValidDataItem = dataItem?.id && !lastChunkIds.includes(dataItem.id);

        if (isValidDataItem) {
          const newChunkIds = [...lastChunkIds];
          if (!newChunkIds.includes(dataItem.id) && hasMoreElements) {
            dispatch(addLastChunkId(dataItem.id));
          }
        }
      }, 300);

      element.addEventListener('scroll', handleScroll);
      return () => element.removeEventListener('scroll', handleScroll);
    }
  }, [contentBoxRef, lastQueryData, lastChunkIds, dispatch, editMode, hasMoreElements]);

  useEffect(() => {
    const element = contentBoxRef.current;

    const handleScrollEnd = debounce(() => {
      dispatch(setBlockChangingVisibility(false));
    }, 150);

    const handleScroll = () => {
      handleScrollEnd();
    };

    if (element) {
      element.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [dispatch, contentBoxRef]);

  return (
    <Box
      ref={contentBoxRef}
      id="wikiContentBox"
      sx={{
        paddingLeft:
          screenWidth > SECONDARY_SIDEBAR_WIDTH_BREAKPOINT
            ? `${SECONDARY_SIDEBAR_WIDTH_FULL - 12}px`
            : `${SECONDARY_SIDEBAR_WIDTH_SMALL - 12}px`,
        flexGrow: 1,
        position: 'relative',
        overflowY: 'auto',
        height: '100%',
      }}
    >
      {editMode && data ? (
        <WikiEditMode
          id={id}
          managePermission={managePermission}
          addPermission={addPermission}
          wikiCards={data}
          focusedIndex={focusedIndex}
        />
      ) : (
        <WikiViewMode
          hasMoreElements={hasMoreElements}
          lastChunkIds={lastChunkIds}
          data={data}
          focusedIndex={focusedIndex}
          wasDragged={wasDragged}
          id={id}
        />
      )}
    </Box>
  );
}
