import { CircularProgress, IconButton, InputAdornment, alpha } from '@mui/material';
import {
  WorkbaseArrowRightIcon,
  WorkbaseCloseCircleIcon,
  WorkbaseEmailBadgeIcon,
  WorkbaseResetIcon,
} from 'src/assets/icons/workbaseIcons';
import { MouseEventHandler, memo, useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import validateEmail from 'src/lib/utils/validateEmail';
import { WorkbaseTextField, WorkbaseTextFieldProps } from '../text-field/TextField';
import { WorkbaseIcon } from '../icon/WorkbaseIcon';
import { showNotification } from '../notification';
import WorkbaseTextMaxLine from '../text-max-line/TextMaxLine';

export interface ValidationResult {
  isError: boolean;
  message?: string;
}

export type EmailValidationTextFieldProps = Omit<
  WorkbaseTextFieldProps,
  'value' | 'onChange' | 'onSubmit' | 'error'
> & {
  value?: string;
  pendingValue?: string;
  onSubmit: (value: string) => Promise<ValidationResult> | void;
  onCancel: () => Promise<ValidationResult> | void;
  placeholder?: string;
  validateFn?: (value: string) => Promise<ValidationResult> | ValidationResult;
  emailSent?: boolean;
  onChange?: (value: string) => void;
  dataCy?: string;
};

const iconsStyles = {
  width: 14,
  height: 14,
};

function ResetButton({
  isSent,
  handleCancelClick,
  handleResetClick,
  resetButtonDisabled,
}: {
  isSent: boolean;
  handleCancelClick: () => void;
  handleResetClick: MouseEventHandler<HTMLButtonElement>;
  resetButtonDisabled: boolean;
}) {
  if (isSent)
    return (
      <IconButton
        aria-label="reset"
        onMouseDown={handleCancelClick}
        sx={{
          padding: 0,
          color: 'error.main',
          position: 'relative',
          '&:hover': {
            backgroundColor: 'transparent',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              backgroundColor: (theme) => alpha(theme.palette.common.white, 0.1),
            },
          },
          '&.Mui-disabled': {
            color: ({ palette }) => `${palette.grey[300]} !important`,
          },
        }}
      >
        <WorkbaseIcon icon={WorkbaseCloseCircleIcon} />
      </IconButton>
    );

  return (
    <IconButton
      aria-label="reset"
      onMouseDown={handleResetClick}
      disabled={resetButtonDisabled}
      sx={{
        padding: 0,
        color: 'primary.main',
        '&.Mui-disabled': {
          color: ({ palette }) => `${palette.grey[300]} !important`,
        },
      }}
    >
      <WorkbaseIcon icon={WorkbaseResetIcon} />
    </IconButton>
  );
}

function SubmitButton({
  isSent,
  isLoading,
  saveButtonDisabled,
  onSend,
}: {
  isSent: boolean;
  isLoading: boolean;
  saveButtonDisabled: boolean;
  onSend: () => void;
}) {
  if (isSent)
    return (
      <IconButton onClick={onSend}>
        {!isLoading && <WorkbaseIcon icon={WorkbaseEmailBadgeIcon} />}
        {isLoading && <CircularProgress style={iconsStyles} />}
      </IconButton>
    );

  return (
    <IconButton disabled={saveButtonDisabled} onClick={onSend}>
      {!isLoading && <WorkbaseIcon icon={WorkbaseArrowRightIcon} />}
      {isLoading && <CircularProgress style={iconsStyles} />}
    </IconButton>
  );
}

function EmailValidationTextField({
  value,
  onSubmit,
  validateFn,
  onCancel,
  pendingValue,
  emailSent,
  label,
  onChange,
  dataCy,
  ...props
}: EmailValidationTextFieldProps) {
  const [currentVal, setCurrentVal] = useState(pendingValue || value || '');
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const validationResultDict = useRef<Record<string, ValidationResult>>({});
  const [valChangedWhenFocus, setValChangedWhenFocus] = useState(false);
  const [isSent, setIsSent] = useState(!!pendingValue);
  const [previousValue, setPreviousValue] = useState(pendingValue ? value || '' : '');
  const isValChanged = currentVal !== value;
  const isValidEmailSyntax = isValChanged ? validateEmail(currentVal) : true;
  const [pendingEmail, setPendingEmail] = useState(pendingValue);

  useEffect(() => {
    setCurrentVal(pendingValue || value || '');
  }, [value, pendingValue]);

  const handleResetClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setError(false);
    setCurrentVal(pendingEmail || previousValue || (value as string));
    setIsSent(!!pendingValue);
  };

  const saveButtonDisabled =
    isLoading ||
    (currentVal === '' && value === undefined) ||
    !valChangedWhenFocus ||
    !isValChanged;

  const handleValidationResult = (result: any) => {
    if (result?.isError) {
      const message = result.message ? result.message : t('invalidEmail');
      showNotification(message, 'error');

      validationResultDict.current[currentVal] = {
        isError: true,
        message,
      };

      setError(true);
      setIsLoading(false);
    }
  };

  const resetButtonDisabled = !isValChanged || value === undefined;

  const handleSend = async () => {
    setIsLoading(true);
    const validateFunctionResp = await validateFn?.(currentVal);
    validationResultDict.current[currentVal] = {
      isError: validateFunctionResp?.isError ? validateFunctionResp.isError : false,
    };

    if (!validateFunctionResp?.isError) {
      const validationResult = await onSubmit(currentVal);
      handleValidationResult(validationResult);
      setError(validationResult?.isError ? validationResult?.isError : false);
      setIsSent(
        validationResult?.isError && pendingEmail !== currentVal ? !validationResult?.isError : true
      );
      setValChangedWhenFocus(validationResult?.isError ? validationResult?.isError : false);
      if (!validationResult?.isError) {
        setPendingEmail(currentVal);
      }
    } else {
      handleValidationResult(validateFunctionResp);
      setError(validateFunctionResp?.isError);
      setValChangedWhenFocus(false);
    }

    setPreviousValue(value || '');
    setIsLoading(false);
  };

  const handleCancelClick = () => {
    onCancel();
    setCurrentVal(previousValue);
    setIsSent(false);
    setPendingEmail('');
  };

  return (
    <>
      {label && (
        <WorkbaseTextMaxLine
          color="grey[500]"
          line={1}
          variant="body1"
          sx={{ lineHeight: '1.167', height: '14px' }}
        >
          {(!previousValue || previousValue === currentVal) && label}
        </WorkbaseTextMaxLine>
      )}
      <WorkbaseTextField
        data-cy={dataCy}
        {...props}
        fullWidth
        sx={{
          input: {
            fontSize: '15px !important',
          },
          legend: {
            backgroundColor: error || !isValidEmailSyntax ? 'red.main' : 'common.trasparent',
            '& textarea': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'preWrap',
            },
          },
          'fieldset, && .Mui-disabled fieldset': {
            borderWidth: '1px !important',
            borderColor: 'grey.300',
          },
          minWidth: 209.5,
          '& .MuiInputBase-root': {
            pr: '5px',
            '& textarea': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'preWrap',
            },
          },
          ...props.sx,
        }}
        error={error || !isValidEmailSyntax}
        helperText={!isValidEmailSyntax && t('validationErrorMessages.emailErrorMsg')}
        value={currentVal}
        label={previousValue !== currentVal ? previousValue : undefined}
        onChange={(e) => {
          setPreviousValue(value || '');
          setCurrentVal(e.target.value);
          setValChangedWhenFocus(true);
          setIsSent(false);
          onChange?.(e.target.value);
        }}
        InputProps={{
          ...props.InputProps,
          endAdornment: (
            <>
              <InputAdornment position="end">
                <ResetButton
                  isSent={isSent}
                  handleCancelClick={handleCancelClick}
                  handleResetClick={handleResetClick}
                  resetButtonDisabled={resetButtonDisabled}
                />
              </InputAdornment>
              <InputAdornment position="end" sx={{ marginLeft: '8px' }}>
                <SubmitButton
                  isSent={isSent}
                  isLoading={isLoading}
                  saveButtonDisabled={saveButtonDisabled}
                  onSend={handleSend}
                />
              </InputAdornment>
            </>
          ),
        }}
        InputLabelProps={{
          sx: {
            '&:not(.MuiInputLabel-shrink)': {
              maxWidth: 'calc(100% - 34px)',
            },
          },
        }}
      />
    </>
  );
}

export default memo(EmailValidationTextField);
