import { Fragment } from 'react';
import { Box, Divider, IconButton, Stack } from '@mui/material';
import WorkbaseTooltip from 'src/lib/components/atoms/tooltip';
import { t } from 'i18next';
import { WorkbaseIcon } from '../../../atoms/icon/WorkbaseIcon';
import {
  WorkbaseContentNewIcon,
  WorkbaseDownloadIcon,
  WorkbaseLinksIcon,
} from '../../../../../assets/icons/workbaseIcons';

export interface ImageActionsProps {
  onUpload: () => void;
  onDownload: () => void;
  onLinkIconClick: () => void;
}

export default function ImageActions({ onUpload, onDownload, onLinkIconClick }: ImageActionsProps) {
  const items = [
    {
      key: 'upload',
      onClick: () => onUpload(),
      icon: WorkbaseContentNewIcon,
      styles: { padding: '3px 0px 5px 0px' },
    },
    {
      key: 'download',
      onClick: () => onDownload(),
      icon: WorkbaseDownloadIcon,
      divider: true,
      styles: { padding: '3px 7px 5px 0px' },
    },
    {
      key: 'link',
      onClick: () => {
        onLinkIconClick();
      },
      icon: WorkbaseLinksIcon,
      styles: { padding: '3px 5px 5px 7px' },
    },
  ];

  return (
    <Stack direction="row" gap="6px">
      {items.map(({ key, onClick, icon, divider, styles }) => (
        <Fragment key={`item-${key}`}>
          <WorkbaseTooltip title={t(`editor.topBar.tooltip.${key}`)} placement="top">
            <Box sx={{ ...styles }}>
              <IconButton
                onMouseDown={(e) => {
                  e.preventDefault();
                  onClick();
                }}
              >
                <WorkbaseIcon icon={icon} sx={{ '&&&': { width: 16, height: 16 } }} />
              </IconButton>
            </Box>
          </WorkbaseTooltip>
          {divider && <Divider orientation="vertical" flexItem />}
        </Fragment>
      ))}
    </Stack>
  );
}
