import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import WorkbaseBreadcrumbs from 'src/lib/components/molecules/breadcrumbs';
import useMaintainEditPath from 'src/lib/hooks/useMaintainEditPath';

interface Props {
  trainingName: string;
}

export default function TrainingBreadcrumbs({ trainingName }: Props) {
  const { t } = useTranslation();
  const path = useMaintainEditPath();
  const params = useParams();
  const data = [
    {
      key: 'trainings',
      label: t('sidebar.tabTitleTrainings'),
      to: `/trainings${path}`,
    },
    {
      key: 'training',
      label: trainingName,
      to: `/trainings/${params.trainingId}`,
    },
  ];

  return (
    <WorkbaseBreadcrumbs
      data={data}
      sx={{
        mb: '10px',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 1348,
        paddingX: '48px',
      }}
    />
  );
}
