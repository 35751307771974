import { useTheme } from '@mui/material/styles';
import { InputAdornment, TextField } from '@mui/material';
import { forwardRef } from 'react';
import { WorkbaseCloseCircleIcon, WorkbaseSearchIcon } from 'src/assets/icons/workbaseIcons';
import { getHexColor, isColorCodeLight } from 'src/lib/utils';
import { useTranslation } from 'react-i18next';
import { renderToString } from 'react-dom/server';
import { WorkbaseIcon } from '../icon/WorkbaseIcon';

export type WorkbaseTextFieldProps = Omit<
  React.ComponentProps<typeof TextField>,
  'variant' | 'hiddenLabel' | 'margin' | 'component' | 'size'
> & {
  backgroundColor?: string;
  backgroundBlur?: number;
  bordered?: boolean;
  optional?: boolean;
  size?: TextFieldSize;
  dataCy?: string;
};

export type TextFieldSize = 'H1' | 'H2' | 'H4' | 'small' | 'medium';

const labelStylesDict = {
  H1: {
    '.MuiFormLabel-root': {
      transform: 'translate(20px, 19px) scale(1)',
    },
    '.MuiFormLabel-root.Mui-focused, .MuiFormLabel-filled': {
      transform: 'translate(20px, -8px) scale(0.75) !important',
      fontWeight: '700',
    },
  },
  H2: {
    '.MuiFormLabel-root': {
      transform: 'translate(20px, 14px) scale(1)',
    },
    '.MuiFormLabel-root.Mui-focused, .MuiFormLabel-filled': {
      transform: 'translate(20px, -8px) scale(0.75)',
    },
  },
  H4: {
    '.MuiFormLabel-root': {
      transform: 'translate(20px, 11px) scale(1)',
    },
    '.MuiFormLabel-root.Mui-focused, .MuiFormLabel-filled': {
      transform: 'translate(20px, -8px) scale(0.75)',
    },
  },
  small: {
    '.MuiFormLabel-root': {
      fontSize: '0.875rem',
      marginLeft: '-3px',
    },
    '.MuiFormLabel-root.Mui-focused, .MuiFormLabel-filled': {
      fontSize: '1rem',
    },
  },
  medium: {
    '.MuiFormLabel-root': {
      marginLeft: '7px',
    },
  },
};

const sizesDict = {
  H1: {
    padding: '8.5px 20px',
    fontSize: '2rem',
    fontWeight: 700,
  },
  H2: {
    padding: '8.5px 20px',
    fontSize: '1.375rem',
    fontWeight: 700,
  },
  H4: {
    padding: '8.5px 20px',
    fontSize: '1.125rem',
    fontWeight: 700,
  },
  small: {
    fontSize: '0.875rem',
  },
  medium: {
    fontSize: '0.938rem',
    fontWeight: 400,
    lineHeight: 1.2,
  },
};

export const WorkbaseTextField = forwardRef<HTMLDivElement, WorkbaseTextFieldProps>(
  (
    {
      sx,
      backgroundColor,
      backgroundBlur,
      bordered = true,
      size = 'small',
      label,
      required,
      disabled,
      placeholder,
      optional = false,
      dataCy,
      InputLabelProps,
      ...props
    },
    ref
  ) => {
    const { palette } = useTheme();
    const { t } = useTranslation();
    const backgroundColorCode = getHexColor(palette, backgroundColor);

    const color =
      backgroundColorCode && !isColorCodeLight(backgroundColorCode)
        ? palette.common.white
        : palette.text.primary;

    const placeholderColor =
      backgroundColorCode && !isColorCodeLight(backgroundColorCode)
        ? palette.grey[500]
        : palette.text.disabled;

    const focusedLabelColor =
      backgroundColorCode && !isColorCodeLight(backgroundColorCode)
        ? palette.common.white
        : palette.grey[600];

    const backgroundStyle = backgroundColorCode ? { backgroundColor: backgroundColorCode } : {};

    const blurStyle = backgroundBlur
      ? {
          backdropFilter: `blur(${backgroundBlur}px)`,
        }
      : {};

    const inputAdornmentStyles = {
      paddingLeft: props.InputProps?.startAdornment ? '0 !important' : '20px',
      paddingRight: props.InputProps?.endAdornment ? '10px !important' : '20px',
    };
    const adornmentPadding = '20px';

    const optionalText = () => (!required && !disabled && optional ? ` (${t('optional')})` : '');

    return (
      <TextField
        data-cy={dataCy}
        ref={ref}
        InputLabelProps={InputLabelProps}
        sx={{
          '.MuiInputBase-root': {
            paddingLeft: props.InputProps?.startAdornment ? adornmentPadding : 0,
            paddingRight: props.InputProps?.endAdornment ? adornmentPadding : 0,
            paddingTop: 0,
            paddingBottom: 0,
          },
          '.MuiFormLabel-filled, .MuiFormLabel-root.Mui-focused': {
            fontWeight: '700 !important',
          },
          '.MuiInputAdornment-positionStart': {
            marginRight: '10px',
          },
          '.MuiInputAdornment-positionEnd': {
            marginLeft: '10px',
          },
          'input, textarea': {
            ...inputAdornmentStyles,
            ...sizesDict[size as keyof typeof labelStylesDict],
          },
          '.MuiInputLabel-root': {
            color: palette.grey[500],
            fontSize: '1rem',
            '&.Mui-disabled': {
              color: `${palette.grey[500]} !important`,
            },
          },
          '& .MuiInputLabel-root.MuiInputLabel-shrink.Mui-focused, .MuiInputLabel-root.MuiInputLabel-shrink, .MuiInputLabel-root.MuiFormLabel-filled':
            {
              color: palette.grey[600],
            },
          '& .MuiInputLabel-root.MuiFormLabel-filled.Mui-disabled': {
            color,
          },
          '&& .Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.text.primary,
            paddingLeft: '10px',
          },
          textarea: {
            paddingTop: '8.5px',
            paddingBottom: '8.5px',
          },
          ...(props.InputProps?.startAdornment
            ? {
                '.MuiFormLabel-root': {
                  fontWeight: 700,
                },
              }
            : {}),
          ...(props.InputProps?.startAdornment
            ? {}
            : labelStylesDict[size as keyof typeof labelStylesDict]),
          ...(bordered
            ? {
                'fieldset, && .Mui-disabled fieldset': {
                  borderWidth: '1px !important',
                  borderColor: 'grey.300',
                },
              }
            : {
                fieldset: {
                  border: 'none !important',
                },
              }),
          '& .MuiInputBase-input::placeholder': {
            opacity: 1,
            color: placeholderColor,
          },
          ...(backgroundColorCode
            ? {
                '& .MuiInputBase-root': {
                  ...backgroundStyle,
                  ...blurStyle,
                  color,
                },
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: palette.text.disabled,
                },
                '& .MuiInputAdornment-root': {
                  color,
                },
                '& .MuiInputLabel-root': {
                  color,
                },
                '& .MuiInputLabel-standard.MuiInputLabel-root.MuiInputLabel-shrink': {
                  color: palette.text.primary,
                },
                '& .MuiInputLabel-root.MuiInputLabel-shrink.Mui-focused, .MuiInputLabel-root.MuiInputLabel-shrink, .MuiInputLabel-root.MuiFormLabel-filled':
                  {
                    color: focusedLabelColor,
                  },
                '& .MuiInputLabel-root.MuiFormLabel-filled.Mui-disabled': {
                  color,
                },

                ...sx,
              }
            : {
                '& .MuiInputBase-root': {
                  ...blurStyle,
                },
                '& .MuiInputLabel-standard.MuiInputLabel-root.MuiInputLabel-shrink': {
                  color: palette.text.primary,
                },
                ...sx,
              }),
        }}
        size={size as any}
        disabled={disabled}
        required={required}
        label={label && `${label} ${optionalText()}`}
        placeholder={placeholder && `${placeholder} ${optionalText()}`}
        {...props}
      />
    );
  }
);

const svgIconString = renderToString(<WorkbaseCloseCircleIcon width={16} height={16} />);

export const WorkbaseSearchTextField = forwardRef<HTMLDivElement, WorkbaseTextFieldProps>(
  ({ sx, backgroundColor, dataCy, ...props }: WorkbaseTextFieldProps, ref) => {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const color =
      backgroundColor && !isColorCodeLight(backgroundColor)
        ? palette.common.white
        : palette.text.primary;
    const backgroundStyle = backgroundColor ? { backgroundColor } : {};
    const backgroundColorStyle = backgroundColor ? { color } : {};

    const overflowStyles = {
      root: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '100%',
      },
    };

    return (
      <WorkbaseTextField
        data-cy={dataCy}
        ref={ref}
        placeholder={t('searchPlaceholder')}
        type="search"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <WorkbaseIcon icon={WorkbaseSearchIcon} sx={{ color, width: 16, height: 16 }} />
            </InputAdornment>
          ),
          sx: overflowStyles.root,
        }}
        sx={{
          '& .MuiInputBase-input::-webkit-search-cancel-button': {
            WebkitAppearance: 'none',
            height: '16px',
            width: '16px',
            backgroundColor: 'primary.main',
            maskImage: `url('data:image/svg+xml,${svgIconString}');`,
            cursor: 'pointer',
          },
          '& .MuiInputBase-input::placeholder': {
            fontSize: '15px',
            fontWeight: '500',
            ...backgroundColorStyle,
          },
          '& .MuiInputBase-input': {
            lineHeight: 1.2,
            fontSize: '15px',
            pr: '10px',
            pb: '12px',
            pt: 1,
          },
          '& .MuiInputBase-root': {
            ...backgroundStyle,
            ...backgroundColorStyle,
            pl: '20px',
          },
          '& .MuiSvgIcon-root': {
            mt: 1,
            mb: '12px',
          },
          ...sx,
        }}
        {...props}
      />
    );
  }
);

export const WorkbaseSearchTextFieldWithRightIcon = forwardRef<
  HTMLInputElement,
  WorkbaseTextFieldProps
>(({ sx, ...props }: WorkbaseTextFieldProps, ref) => {
  const { t } = useTranslation();

  return (
    <WorkbaseTextField
      data-cy={props.dataCy}
      ref={ref}
      fullWidth
      placeholder={t('searchPlaceholder')}
      size="small"
      sx={{
        '& .MuiInputBase-input::-webkit-search-cancel-button': {
          WebkitAppearance: 'none',
          height: 16,
          width: 16,
          backgroundColor: 'primary.main',
          maskImage: `url('data:image/svg+xml,${svgIconString}');`,
          cursor: 'pointer',
        },
        '& .MuiInputBase-input::placeholder': {
          fontSize: '15px',
          fontWeight: 500,
        },
        '& .MuiInputBase-input': {
          lineHeight: 1.2,
          fontSize: '15px',
          pb: 1.5,
          pt: 1,
          pl: 2.5,
          color: 'text.secondary',
        },
        '& .MuiInputBase-root': {
          pr: '20px',
          borderColor: 'background.hover',
        },
        '& .MuiSvgIcon-root': {
          mt: 1,
          mb: 1.5,
        },
        ...sx,
      }}
      {...props}
    />
  );
});
