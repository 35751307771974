import { useEffect, useState } from 'react';
import { Location, useBlocker } from 'react-router-dom';

export default function useOnLeaveViewBlocker(
  shouldBlock = true,
  blockerFn?: ({
    currentLocation,
    nextLocation,
  }: {
    currentLocation: Location<any>;
    nextLocation: Location<any>;
  }) => boolean,
  onConfirmLeave?: () => void
) {
  const [showModal, setShowModal] = useState(false);
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      shouldBlock &&
      (blockerFn
        ? blockerFn({ currentLocation, nextLocation })
        : currentLocation.pathname !== nextLocation.pathname)
  );

  useEffect(() => {
    if (shouldBlock) {
      const handleBeforeUnload = (event: any) => {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = 'val';
        return 'val';
      };
      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
    return undefined;
  }, [shouldBlock]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      setShowModal(true);
    }
  }, [blocker]);

  const handleConfirm = () => {
    setShowModal(false);
    onConfirmLeave?.();
    blocker.proceed?.();
  };

  const handleCancel = () => {
    setShowModal(false);
    blocker.reset?.();
  };

  return {
    showModal,
    handleConfirm,
    handleCancel,
  };
}
