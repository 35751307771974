import { t } from 'i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Employee } from 'src/features/employees/model';
import IconProgressCard from 'src/lib/components/molecules/progress-card/icon-progress-card/IconProgressCard';
import WorkbaseCollapsible from 'src/lib/components/molecules/сollapsible/Collapsible';
import { throttledShowNotification } from 'src/lib/components/atoms/notification';
import { useTrainingLessonsBySectionAndUserIdQuery } from 'src/store/api/trainings/trainingLessonsApi';
import { TrainingSection } from 'src/store/api/trainings/trainingSectionsApi';
import normalizeString from 'src/lib/utils/normalizeString';
import LessonList from '../lessons/LessonList';

interface Props {
  section: TrainingSection;
  employee: Employee;
  searchVal: string;
  trainingId: string;
  blockAccess?: boolean;
}

export default function SingleSection({
  section,
  employee,
  searchVal,
  trainingId,
  blockAccess = false,
}: Props) {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const { data: lessonData } = useTrainingLessonsBySectionAndUserIdQuery(
    { sectionId: section.id, userId: employee.id },
    { skip: !isOpen }
  );

  const lessonsCountLabel = t('lesson', { count: section.lessonsCount || 0 });
  const estimatedTimeLabel = t('minute', { count: section.estimatedTimeToComplete || 0 });

  const filteredLessons = (lessonData || []).filter(
    ({ name, status }) => normalizeString(name).includes(searchVal || '') && status === 'published'
  );

  if (filteredLessons.length === 0 && !normalizeString(section.name).includes(searchVal || ''))
    return null;

  return (
    <WorkbaseCollapsible
      dataCy="employee-single-section-progress"
      onChange={() => {
        setOpen(!isOpen);
      }}
      backgroundColor="common.white"
      defaultExpanded={isOpen}
      title={
        <IconProgressCard
          dataCy="module-icon-progress-card"
          title={section.name || t('employeePage.progress.unnamedSection')}
          prefix={t('employeePage.progress.prefix.section')}
          description={`${lessonsCountLabel} – ${estimatedTimeLabel}`}
          progress={section.progress}
          icon="WorkbaseSectionIcon"
          onClick={() => {
            if (blockAccess) {
              throttledShowNotification(t('employeePage.progress.noAccessSection'), 'error');
            } else {
              navigate(`/trainings/${trainingId}/${section.trainingModuleId}/${section.id}`);
            }
          }}
        />
      }
    >
      {filteredLessons.length !== 0 && (
        <LessonList
          blockAccess={blockAccess}
          lessonData={filteredLessons}
          trainingId={trainingId}
          trainingModuleId={section.trainingModuleId}
        />
      )}
    </WorkbaseCollapsible>
  );
}
