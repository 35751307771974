import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  SxProps,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WorkbaseCloseIcon } from 'src/assets/icons/workbaseIcons';
import { useTranslation } from 'react-i18next';
import { WorkbaseIcon, WorkbaseIconType } from '../../atoms/icon/WorkbaseIcon';

export const WorkbaseDialog = styled(Dialog)(({ theme }) => ({
  '.MuiModal-backdrop': {
    backgroundColor: alpha(theme.palette.common.black, 0.3),
    backdropFilter: 'blur(2px)',
  },
  '.MuiPaper-rounded': {
    borderRadius: theme.shape.borderRadius * 4,
    padding: '24px 0',
  },
}));
export const WorkbaseDialogTitle = styled(DialogTitle)({
  padding: '0 32px 20px 32px',
  position: 'relative',
  overflowWrap: 'break-word',
  '&&': {
    fontSize: '22px',
    lineHeight: '1.28',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    overflowWrap: 'break-word',
  },
});
export const WorkbaseDialogContent = styled(DialogContent)({
  padding: '0 32px',
});
export const WorkbaseDialogActions = styled(DialogActions)({
  padding: '20px 32px 0 32px',
  width: '100%',
  justifyContent: 'center',
  '.MuiButton-root': {
    flexGrow: 1,
  },
});
export const WorkbaseDialogContentText = styled(DialogContentText)({
  padding: '0',
  lineHeight: '1.2',
});

interface Props {
  open: boolean;
  onClose: () => void;
  title?: React.ReactNode;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  titleIcon?: {
    icon: WorkbaseIconType;
    color?: string;
  };
  confirmButton?: {
    text?: string;
    onClick: () => void;
    disabled?: boolean;
    startIcon?: WorkbaseIconType | undefined;
    type?: ButtonProps['color'];
  };
  closeOnConfirm?: boolean;
  cancelButton?:
    | {
        text?: string;
        onClick?: () => void;
        type?: ButtonProps['color'];
      }
    | boolean;
  closeIconVisibility?: boolean;
  dataCy?: string;
}

export default function WorkbaseDialogComponent({
  open,
  onClose,
  title,
  children,
  sx,
  titleIcon,
  cancelButton = true,
  confirmButton,
  closeOnConfirm = true,
  closeIconVisibility,
  dataCy,
}: Props) {
  const { t } = useTranslation();
  return (
    <WorkbaseDialog
      open={open}
      onClose={onClose}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          confirmButton?.onClick();
        }
      }}
      sx={{
        minWidth: 0,
        '.MuiPaper-root': { borderColor: 'grey.300', borderStyle: 'solid', borderWidth: '1px' },
        ...sx,
      }}
    >
      {title && (
        <WorkbaseDialogTitle>
          {titleIcon && (
            <WorkbaseIcon
              icon={titleIcon.icon}
              color={titleIcon.color ? titleIcon.color : 'grey.300'}
            />
          )}
          <Typography
            variant="h5"
            sx={{
              overflowWrap: 'break-word',
              minWidth: 0,
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {title}
          </Typography>
          {closeIconVisibility && (
            <IconButton
              data-cy="close-button"
              onClick={onClose}
              sx={{ marginLeft: 'auto', marginTop: '-2px' }}
            >
              <WorkbaseIcon icon={WorkbaseCloseIcon} />
            </IconButton>
          )}
        </WorkbaseDialogTitle>
      )}
      <WorkbaseDialogContent sx={{ overflowY: 'hidden' }}>{children}</WorkbaseDialogContent>
      {(confirmButton || cancelButton) && (
        <WorkbaseDialogActions sx={{ gap: 1.5 }}>
          {cancelButton && (
            <Button
              data-cy={dataCy && `${dataCy}-cancel-button`}
              onClick={() => {
                if (typeof cancelButton === 'object') {
                  cancelButton?.onClick?.();
                }
                onClose();
              }}
              color={(typeof cancelButton === 'object' && cancelButton?.type) || 'inherit'}
            >
              {typeof cancelButton === 'object' && cancelButton.text
                ? cancelButton.text
                : t('cancel')}
            </Button>
          )}
          {confirmButton && (
            <Button
              data-cy={dataCy && `${dataCy}-confirm-button`}
              onClick={() => {
                confirmButton.onClick();
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                closeOnConfirm && onClose();
              }}
              color={confirmButton.type || 'primary'}
              disabled={confirmButton.disabled}
              sx={{ paddingTop: '7px', paddingBottom: '7px', marginLeft: '0 !important' }}
            >
              {confirmButton.startIcon && (
                <WorkbaseIcon
                  icon={confirmButton.startIcon}
                  size={20}
                  sx={{ marginRight: '8px' }}
                />
              )}
              {confirmButton.text || 'Confirm'}
            </Button>
          )}
        </WorkbaseDialogActions>
      )}
    </WorkbaseDialog>
  );
}
