import { Box, Card, InputAdornment, Stack, Typography } from '@mui/material';
import { WorkbaseCircularProgress } from 'src/lib/components/atoms/progress/CircularProgress';
import { WorkbaseIcon, WorkbaseIconByName } from 'src/lib/components/atoms/icon/WorkbaseIcon';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WorkbaseLessonIcon } from 'src/assets/icons/workbaseIcons';
import { WorkbaseTextField } from 'src/lib/components/atoms/text-field';
import { useNavigate } from 'react-router-dom';
import { imageUpload } from 'src/lib/utils/imageUpload';
import { showNotification } from 'src/lib/components/atoms/notification';
import useSingleModuleResponsivness from 'src/lib/components/organisms/editor/trainings-item/modules/useSingleModuleResponsivness';
import useVerticalOrderedDnD from 'src/lib/hooks/useVerticalOrderedDnD';
import DndButton from 'src/lib/components/atoms/dnd-button/DndButton';
import DndPreview from 'src/lib/components/util/DndPreviewWrapper';
import {
  TrainingModuleWithStatus,
  useMoveTrainingModuleToNewPositionMutation,
  usePatchTrainingModuleMutation,
} from 'src/store/api/trainings/trainingModulesApi';
import EditableImageUpload from 'src/ui-components/custom/image-edit/EditableImageUpload';
import debounce from 'lodash.debounce';
import DraftModeMenu from 'src/core/DraftModeMenu';
import BrandedDefaultImage from 'src/lib/components/atoms/branded-default-image/BrandedDefaultImage';
import DndIndicator from 'src/ui-components/custom/dnd-indicator/DndIndicator';
import ModuleActions from '../../../../../core/ModuleActions';

interface Props extends Omit<TrainingModuleWithStatus, 'order'> {
  index?: number;
  withDragLayer?: boolean;
  modules: TrainingModuleWithStatus[];
  estimatedTime: number;
  progress: number;
}

const draggingItemType = 'editing-module-item';

export default function EditingSingleModule({
  name,
  imageUrl,
  id,
  status,
  index = 0,
  withDragLayer = true,
  trainingId,
  modules,
  lessonsCount,
  sectionsCount,
  estimatedTime,
  progress,
  defaultImage,
}: Props) {
  const { t } = useTranslation();
  const [localModuleName, setLocalModuleName] = useState(name);

  const [patchModule] = usePatchTrainingModuleMutation();
  const [moveModuleToNewPosition] = useMoveTrainingModuleToNewPositionMutation();

  const { cardRef, isSmallVersion } = useSingleModuleResponsivness();
  const draggingRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const lessonsCountLabel = t('lesson', { count: lessonsCount || 0 });
  const estimatedTimeLabel = t('minute', { count: estimatedTime || 0 });
  useEffect(() => {
    setLocalModuleName(name);
  }, [name]);

  const { isDragging, hoverState, draggingStyles, previewRef } = useVerticalOrderedDnD({
    itemData: { id: id as string, index },
    moveItem: ({ id: i, index: hoverIndex }) => {
      if (i !== id) {
        const movedModuleIdx = modules.findIndex((module) => module.id === i);
        moveModuleToNewPosition({
          trainingId: trainingId as string,
          moduleId: i,
          order: movedModuleIdx < hoverIndex ? hoverIndex : hoverIndex + 1,
        });
      }
    },
    accept: draggingItemType,
    ref: draggingRef,
  });

  const imgChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files?.[0];

    if (!file) return;
    try {
      const response = await imageUpload(file);
      await patchModule({
        module: {
          trainingId: trainingId as string,
          id: id as string,
          imageId: response.data.result.id,
        },
        imageSrc: response.data.result.variants[0],
      });
    } catch (err) {
      showNotification(t('validationErrorMessages.UploadImageErrorMessage'), 'error');
    }
  };

  const removeImage = async (): Promise<void> => {
    await patchModule({
      module: { trainingId: trainingId as string, id: id as string, imageId: null },
      imageSrc: '',
    });
  };
  const dndIndicatorProps = hoverState.isOverUpperHalf ? { top: -12 } : { bottom: -12 };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedModuleTitleChange = useCallback(
    debounce((val: string) => {
      patchModule({
        module: {
          trainingId: trainingId as string,
          id: id as string,
          name: val,
        },
      });
    }, 1000),
    [trainingId, patchModule]
  );

  return (
    <Box
      sx={{ paddingBottom: 2.5 }}
      ref={draggingRef}
      onClick={() => {
        navigate(`/trainings/${trainingId}/${id}/edit`, { relative: 'route' });
      }}
    >
      <Card
        ref={cardRef}
        sx={{
          borderRadius: (theme) => theme.shape.borderRadius * 2.5,
          borderWidth: 1,
          borderColor: 'grey.300',
          borderStyle: 'solid',
          boxShadow: 'none',
          width: '100%',
          position: 'relative',
          overflow: 'visible',
          cursor: 'grab',
          '&:hover .dnd-button': {
            opacity: 1,
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: -1,
            left: '50%',
            transform: 'translate(-50%, -50%)',
            pointerEvents: 'none',
          }}
        >
          <DndButton sx={{ opacity: 0 }} />
        </Box>
        {withDragLayer && (
          <DndPreview previewRef={previewRef}>
            <EditingSingleModule
              name={name}
              imageUrl={imageUrl}
              id={id}
              status={status}
              withDragLayer={false}
              trainingId={trainingId}
              modules={modules}
              lessonsCount={lessonsCount}
              sectionsCount={sectionsCount}
              estimatedTime={estimatedTime}
              progress={progress}
              defaultImage={defaultImage}
            />
          </DndPreview>
        )}
        {hoverState.isHovered && <DndIndicator {...dndIndicatorProps} />}
        <Stack
          direction="row"
          spacing={2.5}
          sx={{
            p: isSmallVersion ? '10px' : '20px',
            width: '100%',
            flexWrap: isSmallVersion ? 'wrap' : 'nowrap',
            opacity: isDragging ? draggingStyles.placeholderOpacity : 1,
          }}
          gap={isSmallVersion ? 2 : 0}
        >
          <Box
            sx={{
              height: isSmallVersion ? '150px' : '90px',
              width: isSmallVersion ? '100%' : '150px',
              borderRadius: (theme) => theme.shape.borderRadius * 2.5,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <EditableImageUpload
              onChange={imgChange}
              src={imageUrl || undefined}
              placeholder={<BrandedDefaultImage name={defaultImage} />}
              onClear={removeImage}
            />
          </Box>
          <Stack
            direction={isSmallVersion ? 'column' : 'row'}
            sx={{ width: '100%', marginLeft: isSmallVersion ? '0px !important' : '20px' }}
            alignItems={isSmallVersion ? 'flex-start' : 'center'}
            justifyContent="space-between"
            gap={2}
          >
            <Stack sx={{ width: '100%' }} gap={0.75} id={withDragLayer ? id : undefined}>
              <WorkbaseTextField
                size="H2"
                backgroundColor="tertiary.main"
                value={localModuleName}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  setLocalModuleName(e.target.value);
                  debouncedModuleTitleChange(e.target.value);
                }}
                sx={{ maxWidth: 536, width: '100%' }}
                placeholder={t('editor.trainingsSection.moduleTitlePlaceholder')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <WorkbaseIconByName iconName="WorkbaseEditIcon" size={16} />
                    </InputAdornment>
                  ),
                }}
              />
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <WorkbaseIcon
                  icon={WorkbaseLessonIcon}
                  sx={{ width: 14, height: 13.49, color: 'common.black', pr: '4px' }}
                />
                {lessonsCountLabel} – {estimatedTimeLabel}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              gap={isSmallVersion ? 1 : 2}
              alignItems="center"
              sx={{ width: isSmallVersion ? '100%' : 'auto' }}
              justifyContent="center"
              onClick={(e) => e.stopPropagation()}
            >
              <DraftModeMenu
                status={status}
                onStatusChange={(newStatus) => {
                  patchModule({
                    module: {
                      status: newStatus,
                      id,
                      trainingId,
                    },
                  });
                }}
              />
              {id && (
                <ModuleActions
                  moduleId={id}
                  lessonsCount={lessonsCount}
                  sectionsCount={sectionsCount}
                />
              )}
              <WorkbaseCircularProgress
                sx={{ marginLeft: isSmallVersion ? 'auto' : '4px' }}
                value={progress || 0}
                size="big"
              />
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
}
