import { Stack } from '@mui/material';
import WorkbaseAvatar from 'src/lib/components/atoms/avatar/Avatar';
import { Employee } from 'src/features/employees/model';
import HeaderReadOnly from './HeaderReadOnly';
import HeaderEdit from './HeaderEdit';
import EditAvatar from './EditAvatar';

export type EmployeeProps = {
  employee?: Employee;
  handleUpdateEmployee: (val: Partial<Employee>) => void;
  readOnly: boolean;
};

export default function Header({ employee, handleUpdateEmployee, readOnly }: EmployeeProps) {
  if (!employee) return null;

  return (
    <Stack direction="row" alignItems="center" gap={1.5}>
      {readOnly ? (
        <WorkbaseAvatar
          dataCy="employee-avatar"
          src={employee?.avatarUrl}
          sx={{
            borderRadius: (theme) => theme.shape.borderRadius * 2.5,
            width: 64,
            height: 64,
            borderColor: 'grey.300',
            borderWidth: 1,
            mt: 0.25,
            borderStyle: 'solid',
            marginTop: '2px',
          }}
        />
      ) : (
        <EditAvatar employee={employee} handleUpdateEmployee={handleUpdateEmployee} />
      )}
      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="center"
        flexGrow={1}
        minWidth={0}
      >
        {readOnly || employee.status === 'invited' ? (
          <HeaderReadOnly employee={employee} />
        ) : (
          <HeaderEdit employee={employee} handleUpdateEmployee={handleUpdateEmployee} />
        )}
      </Stack>
    </Stack>
  );
}
