import { CircularProgress, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ApiKeyPostData,
  useApiKeysQuery,
  useDeleteApiKeyMutation,
  useEditApiKeyMutation,
  useGenerateApiKeyMutation,
} from 'src/store/api/apiKeysApi';
import { useState } from 'react';
import { showNotification } from 'src/lib/components/atoms/notification';
import ApiKeysTable from './view/ApiKeysTable';
import ApiKeysHeader from './view/ApiKeysHeader';
import ApiKeyGenerationDialog from './view/dialogs/ApiKeyGenerationDialog';
import ApiKeyDeleteDialog from './view/dialogs/ApiKeyDeleteDialog';
import ApiKeyEditDialog, { ApiKeyEditData } from './view/dialogs/ApiKeyEditDialog';
import NoApiKeysPlaceholder from './view/NoApiKeysPlaceholder';

export default function ApiKeys() {
  const { t } = useTranslation();
  const { data: apiKeysData, isLoading: isApiKeysDataLoading } = useApiKeysQuery();
  const [isGenerationDialogOpen, setIsGenerationDialogOpen] = useState(false);
  const [deleteDialogData, setDeleteDialogData] = useState<{ id: string; name: string } | null>(
    null
  );
  const [editDialogData, setEditDialogData] = useState<ApiKeyEditData | null>(null);
  const [generateApiKey] = useGenerateApiKeyMutation();
  const [deleteApiKey] = useDeleteApiKeyMutation();
  const [editApiKey] = useEditApiKeyMutation();

  const handleDeleteApiKey = async () => {
    if (deleteDialogData) {
      await deleteApiKey(deleteDialogData.id);
      showNotification(
        t('companySettings.apiKeys.deleteSuccess', { name: deleteDialogData.name }),
        'success'
      );
      setDeleteDialogData(null);
    }
  };
  const handleEditApiKey = async (newName: string) => {
    if (editDialogData) {
      await editApiKey({
        id: editDialogData.id,
        data: { name: newName },
      });
      showNotification(t('companySettings.apiKeys.updateSuccess'), 'success');
      setEditDialogData(null);
    }
  };

  const tableData = {
    rows: apiKeysData || [],
    columnNames: [
      t('companySettings.apiKeys.table.columnNames.keyName'),
      t('companySettings.apiKeys.table.columnNames.createdOn'),
      t('companySettings.apiKeys.table.columnNames.expireOn'),
      t('companySettings.apiKeys.table.columnNames.status'),
      t('companySettings.apiKeys.table.columnNames.actions'),
    ],
    onDelete: (id: string, name: string) => {
      setDeleteDialogData({ id, name });
    },
    onEdit: (id: string, name: string, expiredAt: string) => {
      setEditDialogData({ id, name, expiredAt });
    },
  };

  if (isApiKeysDataLoading) {
    return <CircularProgress style={{ margin: 'auto', display: 'block' }} />;
  }
  const apiKeysExist = apiKeysData && apiKeysData.length > 0;
  return (
    <Stack sx={{ height: '100%', pb: 2 }}>
      {editDialogData && (
        <ApiKeyEditDialog
          apiKeyData={editDialogData as ApiKeyEditData}
          onClose={() => setEditDialogData(null)}
          onSubmit={handleEditApiKey}
        />
      )}
      {deleteDialogData && (
        <ApiKeyDeleteDialog
          open={!!deleteDialogData}
          onClose={() => setDeleteDialogData(null)}
          onDelete={handleDeleteApiKey}
        />
      )}
      <ApiKeyGenerationDialog
        open={isGenerationDialogOpen}
        onSubmit={(data: ApiKeyPostData) => generateApiKey(data) as any}
        onClose={() => setIsGenerationDialogOpen(false)}
      />
      <Stack alignItems="center" width="100%" height="100%">
        <Stack
          gap={2}
          width="100%"
          height="100%"
          sx={{ maxWidth: ({ breakpoints }) => breakpoints.values.lg }}
        >
          <ApiKeysHeader
            displayButton={apiKeysExist}
            onGenerateApiKey={() => {
              setIsGenerationDialogOpen(true);
            }}
          />

          {!apiKeysExist ? (
            <Stack height="60%" alignItems="center" justifyContent="center">
              <NoApiKeysPlaceholder
                onGenerateApiKey={() => {
                  setIsGenerationDialogOpen(true);
                }}
              />
            </Stack>
          ) : (
            <Stack overflow="hidden">
              <ApiKeysTable {...tableData} />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
