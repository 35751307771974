import { Box } from '@mui/material';

export default function RowDashedVerticalLines() {
  const lines = (
    <Box
      sx={{
        border: '1px dashed',
        borderRightColor: 'grey.300',
        borderLeftColor: 'grey.300',
        borderBottom: 'none',
        borderTop: 'none',
        width: '16px',
      }}
    />
  );

  return (
    <Box
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 16,
        display: 'flex',
        justifyContent: 'space-evenly',
      }}
    >
      {lines}
      {lines}
      {lines}
    </Box>
  );
}
