import { Box, styled } from '@mui/material';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useEditor } from 'src/features/editor/controller';
import { showNotification } from 'src/lib/components/atoms/notification';
import { imageUpload } from 'src/lib/utils/imageUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface Props {
  setBackgroundSrc: (src: string) => void;
  headerBackgroundSrc: string;
}

export default function UploadBackgroundImage({ setBackgroundSrc, headerBackgroundSrc }: Props) {
  const { t } = useTranslation();
  const { handleChangeHeaderBackgroundImage } = useEditor();

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBackgroundSrc(reader.result as string);
      };
      reader.readAsDataURL(file);
      try {
        const response = await imageUpload(file);
        const { variants } = response.data.result;

        handleChangeHeaderBackgroundImage(variants[0]);
      } catch (err) {
        showNotification('Error', 'error');
        setBackgroundSrc(headerBackgroundSrc);
      }
    }
  };

  return (
    <Box>
      <Box>{t('changePictureButton')}</Box>
      <VisuallyHiddenInput
        type="file"
        accept=".jpg, .jpeg, .jfif, .pjpeg, .pjp, .gif, .apng, .png, .avif, .svg, .webp"
        onChange={handleFileChange}
      />
    </Box>
  );
}
