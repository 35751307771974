import { Box, Grid } from '@mui/material';
import { useDrag } from 'react-dnd';
import { useAppDispatch } from 'src/store';
import { useEffect } from 'react';
import { BaseGridItem, GridItem, GridRow } from '../../model/types';
import DroppableBetweenRowItems from './droppable-items/DroppableBetweenRowItems';
import { COLUMN_COUNT, GAP_VALUE, ITEM_WIDTH } from './constants';
import useElementConfigs from './useElementConfigs';
import DroppableEmptyCell from './droppable-items/DroppableEmptyCell';
import { setIsDragging } from '../../controller';

const gridCellStyles = {
  paddingTop: 2,
  paddingBottom: 2,
  flexGrow: 1,
  borderStyle: 'dashed',
  borderWidth: '1px',
  borderColor: 'transparent',
  borderLeftStyle: 'dashed',
  borderLeftWidth: '1px',
  borderLeftColor: 'transparent',
  borderRightStyle: 'dashed',
  borderRightWidth: '1px',
  borderRightColor: 'transparent',
};

function hasGapAfter(row: GridItem[], item: BaseGridItem): boolean {
  const itemColEnd = item.columnEnd;
  const itemAfter = row.find(({ columnStart }) => columnStart === itemColEnd);
  return !itemAfter;
}

export default function GridCell({
  item,
  cols,
  readOnly,
  rowIndex,
  row,
}: {
  item: GridItem;
  cols?: number;
  readOnly: boolean;
  rowIndex: number;
  row: GridRow;
}) {
  const dispatch = useAppDispatch();
  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: 'GRID_ITEM',
    item: {
      rowIndex,
      item,
      row,
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      if (item) {
        const { activeElement } = document;
        dispatch(setIsDragging(false));
        const getBtnElement = (): HTMLButtonElement | undefined | null =>
          document.getElementById(item?.id)?.querySelector('.MuiButtonBase-root');
        const btn = getBtnElement();
        if (btn && activeElement !== document.body) {
          btn.style.transitionDuration = '0.15s';
          btn.style.transform = 'translate(0,0)';
          setTimeout(() => {
            getBtnElement()?.focus();
          });
        }
      }
    },
  });

  useEffect(() => {
    if (isDragging) {
      dispatch(setIsDragging(true));
    } else {
      dispatch(setIsDragging(false));
    }
  }, [isDragging, dispatch]);

  const elementConfigs = useElementConfigs(row.id);
  const emptyCell = (
    <Grid
      item
      className="grid-cell"
      sx={{
        ...gridCellStyles,
        '&:not(:last-child)': {
          paddingRight: '16px',
        },
        width: ITEM_WIDTH,
      }}
    />
  );

  if (!item) return emptyCell;

  const element = elementConfigs[item.elementType];

  if (!element) return emptyCell;

  const { render, fullWidth } = element;
  const isLastItem = item.columnEnd === COLUMN_COUNT + 1;

  return (
    <Grid
      item
      ref={readOnly ? undefined : dragRef}
      className="grid-cell"
      id={item.id}
      key={item.id}
      sx={{
        width: fullWidth ? '100%' : `calc(${ITEM_WIDTH} * ${cols})`,
        position: 'relative',
        paddingTop: '10px',
        paddingBottom: '10px',
        zIndex: 1,
        '& > *:nth-child(1)': {
          opacity: isDragging ? 0 : 1,
        },
        '&:not(:last-child)': {
          paddingRight: '16px',
        },
        '&:last-child': {
          width: fullWidth ? '100%' : `calc(${ITEM_WIDTH} * ${cols} - ${GAP_VALUE}px)`,
        },
      }}
    >
      {render({
        item,
        readOnly,
        dragging: {
          dragRef,
          previewRef,
        },
      })}
      {cols && !readOnly && (
        <Box
          sx={{
            position: 'absolute',
            left: '0',
            top: '0',
            bottom: '0',
            right: '0',
            display: 'flex',
            pointerEvents: isDragging ? 'auto' : 'none',
          }}
        >
          {new Array(cols).fill(0).map((_, i) => (
            <DroppableEmptyCell
              row={row}
              colStart={item.columnStart + i}
              ignoreLastChildStyles={isLastItem}
              sx={{
                width: `${100 / cols}% !important`,
                '&:last-child > *': !isLastItem
                  ? {
                      width: `calc(${100 * cols}% - ${GAP_VALUE}px) !important`,
                    }
                  : {},
                paddingRight: isLastItem ? `${GAP_VALUE}px !important` : undefined,
              }}
            />
          ))}
        </Box>
      )}
      {!isLastItem && !hasGapAfter(row.items, item) && !isDragging && (
        <DroppableBetweenRowItems itemColumnEnd={item.columnEnd} rowId={row.id} />
      )}
    </Grid>
  );
}
