import React, { useState } from 'react';
import { TextFieldProps } from '@mui/material';
import SearchTextField from './SearchTextField';
import useDebouncedFunction from '../hooks/use-debounced-value';

export type DebouncedSearchTextFieldProps = Omit<TextFieldProps, 'onChange'> & {
  onChange: (val: string) => void;
};

export default function DebouncedSearchTextField({
  onChange,
  ...props
}: DebouncedSearchTextFieldProps) {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const debouncedOnChange = useDebouncedFunction((value: string) => {
    onChange(value);
  }, 500);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    debouncedOnChange(newSearchTerm);
  };
  // @ts-ignore Type 'unknown' is not assignable to type 'string' although it is string
  return <SearchTextField value={searchTerm} onChange={handleSearchChange} {...props} />;
}
