import { CircularProgress, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseDomainIcon } from 'src/assets/icons/workbaseIcons';
import PortalComponent from 'src/lib/components/util/PortalComponent';
import { useShareLevelQuery } from 'src/store/api/shareResourceApi';
import EditViewProtectedWrapper from 'src/core/EditViewProtectedWrapper';
import ModeSwitch from 'src/core/ModeSwitch';
import { useEmbeddedAppQuery } from 'src/store/api/embeddedAppsApi';
import { useUserPermissionsQuery } from 'src/store/api/userPermissionsApi';
import { PermissionCodes } from 'src/lib/enums/permissions';
import { ShareLevel } from 'src/lib/types/share-level';
import Empty from 'src/ui-components/custom/empty/Empty';
import EmbeddedAppTopBar from './topbar';

const iframeStyle = {
  width: '100%',
  height: '100%',
  border: 'none',
};

export default function EmbeddedApp({ id }: { id: string }) {
  const { data, isLoading, error } = useEmbeddedAppQuery(id);
  const { t } = useTranslation();
  const { data: shareLevelData, error: shareLevelError } = useShareLevelQuery({
    resourceType: 'embeddedApp',
    resourceId: id,
  });

  const { data: userPermissions } = useUserPermissionsQuery();
  const hasAddEmbeddedAppsPermission = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.ADD_EMBEDDED_APPS
  );

  const hasEditingPermissions =
    shareLevelData?.shareLevel !== ShareLevel.view &&
    shareLevelData?.shareLevel !== ShareLevel.none;

  if (isLoading)
    return (
      <Stack justifyContent="center" alignItems="center" height="100%" position="relative">
        <CircularProgress />
      </Stack>
    );

  return (
    <EditViewProtectedWrapper
      canEdit={hasEditingPermissions}
      canView={shareLevelData?.shareLevel !== ShareLevel.none && !shareLevelError && !error}
    >
      <ModeSwitch switchButtonVisible={hasEditingPermissions}>
        {(isEditing) => (
          <>
            <PortalComponent elementSelector="#main-layout-content-card">
              {isEditing && hasEditingPermissions && (
                <EmbeddedAppTopBar
                  id={id}
                  name={data?.name}
                  hasManagePermission={shareLevelData?.shareLevel === ShareLevel.manage}
                  hasAddPermission={hasAddEmbeddedAppsPermission}
                />
              )}
            </PortalComponent>
            <Stack
              justifyContent="center"
              alignItems="center"
              height="100%"
              position="relative"
              pt={isEditing ? 7 : 0}
              overflow="hidden"
            >
              {data && data.url ? (
                <iframe
                  title={data.name}
                  src={data.url}
                  style={{
                    ...iframeStyle,
                  }}
                  sandbox="allow-scripts allow-popups allow-forms allow-same-origin allow-popups-to-escape-sandbox allow-downloads allow-storage-access-by-user-activation"
                />
              ) : (
                <Empty icon={WorkbaseDomainIcon} description={t('embeddedAppPage.noUrlLabel')} />
              )}
            </Stack>
          </>
        )}
      </ModeSwitch>
    </EditViewProtectedWrapper>
  );
}
