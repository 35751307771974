import { Stack, keyframes } from '@mui/material';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import WorkbaseDialogComponent from 'src/lib/components/molecules/dialog/Dialog';
import WorkbaseEditButton from 'src/lib/components/molecules/edit-button/EditButton';
import { validateEmployeeData } from 'src/lib/utils/validateUserData';
import { useBoolean } from 'src/lib/hooks/use-boolean';
import { showNotification } from 'src/lib/components/atoms/notification';
import {
  employeesApi,
  useCancelEmailChangeMutation,
  useChangeEmailMutation,
  useEmailChangeCooldownQuery,
  usePatchEmployeeMutation,
} from 'src/store/api/employees/employeesApi';
import validateEmail from 'src/lib/utils/validateEmail';
import { useAppSelector } from 'src/store';
import {
  clearEmployee,
  setEmployee,
  transformToPatchRequest,
} from '../../controller/employee.slice';
import { Employee } from '../../model';

export interface Props {
  isEditPath: boolean;
  currentEmployee?: Employee;
  changedData: Partial<Employee> | null;
  id: string;
  currentUserRefetch: any;
}
export function SaveCancelChangesButtons({ isEditPath, currentEmployee, changedData, id }: Props) {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const dialog = useBoolean();
  const [changeEmail] = useChangeEmailMutation();
  const employeeDraft = useAppSelector((state) => state.employee.employee);
  const [patchEmployee] = usePatchEmployeeMutation();
  const [cancelEmailChange] = useCancelEmailChangeMutation();

  const editButtonAnimation = keyframes`
    0% {
      bottom: -20px;
    }
    100% {
      bottom: 3px;
    }
    `;

  const additionalEditButtonStyle = {
    position: 'relative',
    bottom: '0',
    right: '0',
    animation: `${editButtonAnimation} 500ms ease-in-out 0s 1`,
  };

  const { data: dataCooldown, isSuccess: isSuccessCooldown } = useEmailChangeCooldownQuery(id, {
    refetchOnMountOrArgChange: false,
    skip: !id,
  });

  useEffect(() => {
    if (isSuccessCooldown && id) {
      const intervalSpan = 1000;
      const interval = setInterval(() => {
        dispatch(
          employeesApi.util.updateQueryData(
            'emailChangeCooldown',
            id,

            (draft) =>
              draft && {
                ...draft,
                cooldown: Math.max(draft.cooldown - intervalSpan, 0),
              }
          )
        );
      }, intervalSpan);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [dispatch, id, isSuccessCooldown]);

  // eslint-disable-next-line max-statements
  async function handleEmailChange(email: string) {
    if (dataCooldown && dataCooldown.cooldown > 0) {
      const time = Math.ceil((dataCooldown.cooldown ?? 0) / 60000);
      const timeUnit =
        time > 1
          ? t('employeePage.employeeDetails.changeEmail.minutes')
          : t('employeePage.employeeDetails.changeEmail.minute');

      showNotification(
        t('employeePage.employeeDetails.changeEmail.waitNotification', {
          time,
          timeUnit,
        }),
        'error'
      );
      return false;
    }
    if (!validateEmail(email)) {
      showNotification(t('invalidEmail'), 'error');
      return false;
    }
    try {
      const resp: any = await changeEmail({
        id,
        email,
      });
      if (resp?.error) {
        // eslint-disable-next-line max-depth
        if (resp?.error.data.code === 'EMAIL_ALREADY_EXISTS') {
          showNotification(t('emailAlreadyInUse'), 'error');
          return false;
        }
        showNotification(t('employeePage.employeeDetails.changeEmail.errorNotification'), 'error');
        return false;
      }
      dispatch(
        employeesApi.util.updateQueryData('employees', undefined, (draft) =>
          draft.map((employee) =>
            employee.id === id ? { ...employee, pendingEmailChange: email } : employee
          )
        )
      );

      return true;
    } catch (error) {
      showNotification(t('employeePage.employeeDetails.changeEmail.errorNotification'), 'error');
      return false;
    }
  }

  // eslint-disable-next-line max-statements
  async function handleSaveClick() {
    /* eslint-disable max-depth */
    try {
      if (changedData && Object.keys(changedData).length !== 0) {
        const { pendingEmailChange, ...otherChangedData } = changedData;

        if (pendingEmailChange) {
          const changeResp = await handleEmailChange(pendingEmailChange);
          if (!changeResp) return;
          if (Object.keys(otherChangedData).length === 0) navigate(id, { relative: 'route' });
          showNotification(t('employeePage.employeeDetails.changeEmail.successNotification'));
        }
        if (Object.keys(otherChangedData).length !== 0) {
          const validationError = validateEmployeeData(employeeDraft as Employee, t);
          if (validationError) {
            showNotification(validationError, 'error');
            return;
          }
          if (
            otherChangedData.phoneNumber &&
            (!isValidPhoneNumber(otherChangedData?.phoneNumber) ||
              otherChangedData?.phoneNumber === '')
          ) {
            showNotification(
              t('employeePage.employeeDetails.changePhoneNumber.errorNotification'),
              'error'
            );
            return;
          }

          const patchRequest = transformToPatchRequest(otherChangedData as Employee);
          const resp: any = await patchEmployee({ id, data: patchRequest });
          if (resp?.error) {
            showNotification(t('employeePage.employeeDetails.contactInfomation.errorMsg'), 'error');
          } else {
            navigate(id, { relative: 'route' });
            showNotification(t('employeePage.employeeDetails.contactInfomation.successMsg'));
          }
        }
        navigate(id, { relative: 'route' });
      } else {
        navigate(id, { relative: 'route' });
      }
    } catch (error) {
      showNotification(t('employeePage.employeeDetails.contactInfomation.errorMsg'), 'error');
    }
  }

  const handleResetChanges = () => {
    dispatch(clearEmployee());
    try {
      if (currentEmployee) dispatch(setEmployee(currentEmployee));
      setTimeout(() => navigate(id, { relative: 'route' }));
    } catch (error) {
      showNotification(t('validationErrorMessages.labelErrorTryAgainButton'), 'error');
    }
  };

  const handleCancelClick = () => {
    if (currentEmployee) cancelEmailChange(currentEmployee.id);
    if (changedData && Object.keys(changedData).length !== 0) {
      dialog.onTrue();
    } else {
      navigate(id, { relative: 'route' });
    }
  };

  return isEditPath ? (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        bottom: '10px',
        right: '15px',
        gap: '10px',
      }}
    >
      <WorkbaseEditButton
        type="cancel"
        text={t('editButton.cancel')}
        onClick={handleCancelClick}
        additionalEditButtonStyle={additionalEditButtonStyle}
      />
      <WorkbaseEditButton
        type="save"
        text={t('editButton.save')}
        onClick={() => handleSaveClick()}
        additionalEditButtonStyle={additionalEditButtonStyle}
      />
      <WorkbaseDialogComponent
        dataCy="employee-unsave-changes"
        open={dialog.value}
        onClose={dialog.onFalse}
        title={t('employeePage.employeeDetails.contactInfomation.cancelQuestion')}
        confirmButton={{
          text: t('confirm'),
          onClick: handleResetChanges,
          type: 'error',
        }}
      />
    </Stack>
  ) : (
    <WorkbaseEditButton
      text={t('editButton.edit')}
      path="./edit"
      editMode={false}
      additionalEditButtonStyle={{
        position: 'absolute',
        bottom: '10px',
        right: '15px',
      }}
    />
  );
}
