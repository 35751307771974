import EditorWithContextProvider from 'src/features/editor/view/EditorWithContextProvider';
import ShareModal from 'src/core/share-modal/ShareModal';
import DeleteDuplicateMenu from 'src/ui-components/custom/delete-duplicate-menu';
import { useTranslation } from 'react-i18next';
import {
  useDeleteWikiCardMutation,
  useDuplicateWikiCardMutation,
  WikiCardType,
} from 'src/store/api/wikiApi.slice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useScreenWidth } from 'src/lib/hooks/use-screen-width';
import {
  SECONDARY_SIDEBAR_WIDTH_BREAKPOINT,
  SECONDARY_SIDEBAR_WIDTH_FULL,
  SECONDARY_SIDEBAR_WIDTH_SMALL,
} from 'src/ui-components/layout/secondary-sidebar/constants';
import WikiCardHeader from './card/WikiCardHeader';
import { removeLastChunkId, setSmallestIndexForce } from '../controller/wikiCards.slice';

interface Props {
  id: string;
  managePermission?: boolean;
  addPermission?: boolean;
  wikiCards: WikiCardType[];
  focusedIndex: number;
}

export default function WikiEditMode({
  id,
  managePermission,
  addPermission,
  wikiCards,
  focusedIndex,
}: Props) {
  const { t } = useTranslation();
  const [deleteWikiCard] = useDeleteWikiCardMutation();
  const [duplicateWikiCard] = useDuplicateWikiCardMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screenWidth = useScreenWidth();
  const sidebarWidth =
    screenWidth > SECONDARY_SIDEBAR_WIDTH_BREAKPOINT
      ? SECONDARY_SIDEBAR_WIDTH_FULL
      : SECONDARY_SIDEBAR_WIDTH_SMALL;

  return (
    <EditorWithContextProvider
      readOnly={false}
      documentName={`wikiCard.${id}`}
      renderHeader={(readOnly) => <WikiCardHeader id={id} readOnly={readOnly} />}
      topBarRightContent={
        <>
          {managePermission && (
            <ShareModal
              name={wikiCards[focusedIndex]?.name || t('wikiPage.unnamedWikiCard')}
              resourceId={id ?? wikiCards[focusedIndex]?.id}
              resourceType="wikiCard"
            />
          )}
          <DeleteDuplicateMenu
            onDuplicate={
              addPermission
                ? () => {
                    duplicateWikiCard(id).then((resp: any) => {
                      if (resp.error) {
                        return;
                      }
                      dispatch(setSmallestIndexForce(focusedIndex + 1));
                      navigate(`/wiki/${resp.data.id}/edit`);
                    });
                  }
                : undefined
            }
            deleteDialogDescription={t('wikiPage.deleteWikiModalText')}
            onDelete={() => {
              deleteWikiCard(id).then((resp: any) => {
                if (resp.error) {
                  return;
                }
                dispatch(removeLastChunkId(id));
                const newFocusedIndex =
                  focusedIndex >= wikiCards.length - 1 ? focusedIndex - 1 : focusedIndex + 1;
                dispatch(setSmallestIndexForce(newFocusedIndex));
                navigate(`/wiki/${wikiCards[newFocusedIndex]?.id}/edit`);
              });
            }}
          />
        </>
      }
      topBarStyles={{
        marginLeft: `${sidebarWidth}px`,
      }}
    />
  );
}
