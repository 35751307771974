import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './api';

type ModuleStatus = 'draft' | 'published';

export interface Module {
  id: string;
  name: string;
  imageUrl: string | null;
  status: ModuleStatus;
  trainingId: string;
}

export const modulesApi = createApi({
  reducerPath: 'modulesApi',
  baseQuery,
  endpoints: (builder) => ({
    moduleById: builder.query<Module, string>({
      query: (id) => ({
        url: `/training-modules/${id}`,
      }),
    }),
  }),
});

export const { useModuleByIdQuery } = modulesApi;
