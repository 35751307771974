import { Box, CircularProgress, SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface WorkbaseProgressNotificationProps {
  text: string | ReactNode;
  uploadPercentage: number;
  displayProgress: boolean;
  sx?: SxProps;
}

function WorkbaseProgressNotification({
  text,
  uploadPercentage,
  displayProgress,
  sx,
}: WorkbaseProgressNotificationProps) {
  return (
    <Typography color="common.white" variant="h4">
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.success.main,
          display: 'flex',
          gap: 1,
          justifyContent: 'center',
          alignContent: 'baseline',
          padding: '10px 20px',
          borderRadius: (theme) => theme.shape.borderRadius * 2.5,
          ...sx,
        }}
      >
        <CircularProgress
          variant={displayProgress ? 'determinate' : 'indeterminate'}
          value={displayProgress ? uploadPercentage : undefined}
          size={20}
          sx={{ color: (theme) => theme.palette.common.white }}
        />
        <Typography color="white.main" variant="subtitle1" align="center">
          {text}
        </Typography>
      </Box>
    </Typography>
  );
}

export default WorkbaseProgressNotification;
