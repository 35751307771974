import { Box, ButtonBase, Card, Stack, SvgIcon } from '@mui/material';
import { WorkbaseAdjustSettings } from 'src/assets/icons/workbaseIcons';
import { Dispatch, SetStateAction } from 'react';
import EmployeeAvatar from 'src/ui-components/utils/employee-avatar/EmployeeAvatar';

interface Props {
  avatarUrl?: string;
  isImpersonated?: boolean;
  isQuickMenuOpen: boolean;
  setQuickMenuOpen: Dispatch<SetStateAction<boolean>>;
}

function ProfileMenu({
  avatarUrl,
  isImpersonated,
  isQuickMenuOpen,
  setQuickMenuOpen,
}: Readonly<Props>) {
  let backgroundColor;
  if (isImpersonated) {
    backgroundColor = 'error.light';
  } else if (isQuickMenuOpen) {
    backgroundColor = 'background.paper';
  } else {
    backgroundColor = 'background.default';
  }

  return (
    <ButtonBase onClick={() => setQuickMenuOpen((prev: boolean) => !prev)} data-cy="nav-quickmenu">
      <Card variant="outlined">
        <Stack direction="row" alignItems="center" gap={2} px={2} py={1} bgcolor={backgroundColor}>
          <SvgIcon component={WorkbaseAdjustSettings} fontSize="medium" color="inherit" />
          <Box>
            <EmployeeAvatar avatarProps={{ src: avatarUrl }} />
          </Box>
        </Stack>
      </Card>
    </ButtonBase>
  );
}

export default ProfileMenu;
