import { Tooltip, TooltipProps, alpha, styled, tooltipClasses } from '@mui/material';

const WorkbaseTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip
    classes={{ popper: className }}
    enterNextDelay={props.enterNextDelay ? props.enterNextDelay : 200}
    {...props}
  >
    <span>{children}</span>
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: alpha(theme.palette.common.white, 0.75),
    color: theme.palette.text.secondary,
    fontSize: '12px',
    lineHeight: '1.167',
    backdropFilter: 'blur(2px)',
    borderRadius: theme.shape.borderRadius,
    padding: '1px 6px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: alpha(theme.palette.common.white, 0.75),
    fontSize: '8px',
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
    marginTop: '8px',
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
    marginBottom: '8px',
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: {
    marginLeft: '8px',
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]: {
    marginRight: '8px',
  },
}));

export default WorkbaseTooltip;
