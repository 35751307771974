import { Button, Stack, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
  WorkbaseCheckmarkBigFilledIcon,
  WorkbasePauseIcon,
  WorkbasePlayIcon,
} from 'src/assets/icons/workbaseIcons';

interface Props {
  completeDisabled?: boolean;
  onPause: () => void;
  onResume: () => void;
  onComplete: () => void;
  isPaused: boolean;
  isNoDevices: boolean;
}

export default function PauseFinishButtons({
  completeDisabled = false,
  onPause,
  onResume,
  isPaused,
  onComplete,
  isNoDevices,
}: Props) {
  const { t } = useTranslation();
  useEffect(() => {
    if (isNoDevices) {
      onPause();
    }
  }, [isNoDevices, onPause]);
  return (
    <Stack direction="row" gap={2.5}>
      <Button
        fullWidth
        endIcon={
          <SvgIcon fontSize="inherit">
            {isPaused ? <WorkbasePlayIcon /> : <WorkbasePauseIcon />}
          </SvgIcon>
        }
        color="inherit"
        onClick={isPaused ? onResume : onPause}
        disabled={isNoDevices}
      >
        {isPaused ? t('recording.resume') : t('recording.pause')}
      </Button>
      <Button
        fullWidth
        endIcon={
          <SvgIcon fontSize="inherit">
            <WorkbaseCheckmarkBigFilledIcon />
          </SvgIcon>
        }
        disabled={completeDisabled}
        color="error"
        onClick={onComplete}
      >
        {t('recording.complete')}
      </Button>
    </Stack>
  );
}
