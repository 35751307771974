import { Alert, Snackbar, Stack } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Logo, usePatchSettingsMutation } from 'src/store/api/settingsApi';
import { imageUpload } from 'src/lib/utils/imageUpload';
import resizeImageFile from 'src/lib/utils/resizeImageFile';
import EditableImageUpload from 'src/ui-components/custom/image-edit/EditableImageUpload';

interface Props {
  text: string;
  url: string;
  name: Logo;
}

export default function UploadLogo({ text, url, name }: Props) {
  const { t } = useTranslation();
  const [patchSettings] = usePatchSettingsMutation();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const snackBarHandler = () => {
    setErrorMessage(t('validationErrorMessages.UploadImageErrorMessage'));
    setShowSnackbar(true);
  };

  const uploadNewImage = async (file: File) => {
    try {
      const response = await imageUpload(file);
      const { id } = response.data.result;
      await patchSettings({ [name]: id });
    } catch (err) {
      snackBarHandler();
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files?.[0];

    if (!file) return;

    if (name === 'siteFavicon' || name === 'siteFaviconDark') {
      const imgSize = 64;
      const croppedFile = await resizeImageFile(file, imgSize);
      if (croppedFile) {
        await uploadNewImage(croppedFile);
      } else {
        snackBarHandler();
      }
    } else {
      await uploadNewImage(file);
    }
  };
  const removeImage = async (): Promise<void> => {
    await patchSettings({ [name]: null });
  };
  const handleClose = () => {
    setShowSnackbar(false);
  };
  const boxStylesDict = {
    siteFavicon: {
      maxWidth: '66px',
    },
    siteFaviconDark: {
      maxWidth: '66px',
    },
    siteLogo: {
      maxWidth: '174px',
    },
    siteLogoDark: {
      maxWidth: '174px',
    },
  };

  return (
    <Stack
      gap={1.5}
      flexDirection="row"
      alignItems="center"
      sx={{ height: '66px', width: '50%', ...boxStylesDict[name] }}
    >
      <EditableImageUpload
        dataCy="upload-logo"
        src={url}
        altText={text}
        onChange={handleFileChange}
        onClear={removeImage}
      />
      <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </Stack>
  );
}
