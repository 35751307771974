import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import ChipSelectionMenu from 'src/ui-components/custom/selection-menu';
import { useTranslation } from 'react-i18next';
import EmailChips from './EmailChips';
import { EmailChipsValueProps } from './controller/use-email-chips';

interface RolesSelectMenu {
  id: string;
  label: string;
}

export default function NewEmployeeDialog({
  open,
  onClose,
  disabledConfirmButton,
  handleEmployeeAdd,
  handleChipsCardOnChange,
  allEmails,
  hasInvalidEmails,
  onRolesChange,
  allRoles,
  rolesSelected,
}: {
  open: boolean;
  onClose: () => void;
  disabledConfirmButton: boolean;
  handleEmployeeAdd: () => void;
  handleChipsCardOnChange: (val: EmailChipsValueProps[]) => void;
  allEmails: EmailChipsValueProps[];
  hasInvalidEmails: boolean;
  onRolesChange: (options: string[]) => void;
  allRoles: RolesSelectMenu[];
  rolesSelected: string[];
}) {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('addEmployees.title')}</DialogTitle>
      <DialogContent>
        <Stack gap={2.5} pt={0.5}>
          <EmailChips
            placeholder={t('addEmployees.emailPlaceholder')}
            values={allEmails}
            onChange={(e) => handleChipsCardOnChange(e)}
            error={hasInvalidEmails}
            autoFocus
          />
          <Typography variant="subtitle1">{t('addEmployees.editingRoleLabel')}</Typography>

          <ChipSelectionMenu
            options={allRoles}
            searchable
            selected={rolesSelected}
            onChange={onRolesChange}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button fullWidth color="inherit" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button
          fullWidth
          color="primary"
          disabled={disabledConfirmButton}
          onClick={handleEmployeeAdd}
        >
          {t('addEmployees.confirmButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
