import { Button, InputAdornment, Stack, SvgIcon, Typography } from '@mui/material';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useEditor } from 'src/features/editor/controller';
import { WorkbaseIconByName } from 'src/lib/components/atoms/icon';
import { WorkbaseTextField } from 'src/lib/components/atoms/text-field';

import { useAppSelector } from 'src/store';
import { trainingLessonsApi } from 'src/store/api/trainings/trainingLessonsApi';
import { WorkbaseArrowLeftIcon } from 'src/assets/icons/workbaseIcons';
import LessonBreadcrumbs from './LessonBreadcrumbs';

interface Props {
  readOnly: boolean;
  showGoBackToTrainingButton: boolean;
  showBreadcrumbs: boolean;
}

export default function LessonHeader({
  readOnly,
  showGoBackToTrainingButton,
  showBreadcrumbs,
}: Props) {
  const { t } = useTranslation();
  const params = useParams<{
    trainingId: string;
    moduleId: string;
    sectionId: string;
    lessonId: string;
  }>();
  const dispatch = useDispatch<any>();
  const lessonTitle = useAppSelector(
    (state) => state.editor.editorData?.lessonTitle,
    shallowEqual || ''
  );
  const [localLessonName, setLocalLessonName] = useState(lessonTitle);
  const navigate = useNavigate();
  const { handleLessonTitleChange } = useEditor();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeDebounced = useCallback(
    debounce((val) => {
      handleLessonTitleChange(val);
      dispatch(
        trainingLessonsApi.util.updateQueryData(
          'trainingLessonsBySectionId',
          params.sectionId as string,
          (draft) =>
            draft.map((lesson) =>
              lesson.id === params.lessonId ? { ...lesson, name: val } : lesson
            )
        )
      );
      dispatch(
        trainingLessonsApi.util.updateQueryData(
          'trainingLesson',
          params.lessonId as string,
          (draft) => ({
            ...draft,
            name: val,
          })
        )
      );
    }, 300),
    [handleLessonTitleChange]
  );

  useEffect(() => {
    setLocalLessonName(lessonTitle);
  }, [lessonTitle]);

  const innerItemStyles = {
    maxWidth: 1336,
    margin: 'auto',
    px: '13px',
    overflowWrap: 'break-word',
  };

  return (
    <Stack gap={1.25} alignItems="flex-start">
      {showBreadcrumbs && <LessonBreadcrumbs sx={innerItemStyles} />}
      {readOnly && showGoBackToTrainingButton && (
        <Button
          sx={{ ...innerItemStyles, marginLeft: '48px' }}
          color="inherit"
          startIcon={
            <SvgIcon fontSize="inherit">
              <WorkbaseArrowLeftIcon />
            </SvgIcon>
          }
          onClick={() => {
            navigate(`/trainings/${params.trainingId}/${params.moduleId}`);
          }}
        >
          {t('lessonPage.backToModule')}
        </Button>
      )}

      {!readOnly && (
        <WorkbaseTextField
          value={localLessonName}
          placeholder={t('writeTitlePlaceholder')}
          onChange={(e) => {
            setLocalLessonName(e.target.value);
            handleChangeDebounced(e.target.value);
          }}
          fullWidth
          sx={{ ...innerItemStyles, padding: '0 48px' }}
          size="H1"
          backgroundColor="tertiary.main"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <WorkbaseIconByName iconName="WorkbaseEditIcon" size={16} />
              </InputAdornment>
            ),
          }}
        />
      )}
      {readOnly && (
        <Typography sx={{ ...innerItemStyles, width: '100%', padding: '0 52px' }} variant="h6">
          {lessonTitle === '' ? t('modulePage.unnamedLesson') : lessonTitle}
        </Typography>
      )}
    </Stack>
  );
}
