import { Dispatch, SetStateAction } from 'react';
import { NavigateFunction, matchPath } from 'react-router-dom';
import {
  WorkbaseMotivationIcon,
  WorkbaseEmployeeCardsIcon,
  WorkbaseSettingsIcon,
} from 'src/assets/icons/workbaseIcons';
import { RecommendationNotificationAdminPreferences, Settings } from 'src/store/api/settingsApi';

// eslint-disable-next-line max-params
function getIconData(
  setMotivationPopupActive: Dispatch<SetStateAction<boolean>>,
  settingsData: Settings | undefined,
  navigate: NavigateFunction,
  location: any,
  isOwner: boolean | undefined,
  hasPermissions: {
    hasManageDepartmentsPermission: any;
    hasViewEmployeePermission: any;
    hasManageAllCompanySettingsPermission: any;
    hasManageBillingPermission: any;
  }
) {
  const {
    hasViewEmployeePermission,
    hasManageDepartmentsPermission,
    hasManageAllCompanySettingsPermission,
    hasManageBillingPermission,
  } = hasPermissions;

  const defaultIconsData = [];

  if (
    (
      settingsData?.recommendationNotificationPreferences as RecommendationNotificationAdminPreferences
    )?.isEmailEnabled ||
    (
      settingsData?.recommendationNotificationPreferences as RecommendationNotificationAdminPreferences
    )?.isWebhookEnabled
  ) {
    defaultIconsData.push({
      key: 'motivation',
      icon: { icon: WorkbaseMotivationIcon },
      onClick: () => setMotivationPopupActive(true),
      isActive: false,
    });
  }

  if (isOwner || hasViewEmployeePermission) {
    defaultIconsData.push({
      key: 'employee',
      icon: { icon: WorkbaseEmployeeCardsIcon },
      onClick: () => navigate('/team'),
      isActive: !!matchPath({ path: 'team', end: false }, location.pathname),
    });
  }

  if (
    hasManageDepartmentsPermission ||
    hasManageAllCompanySettingsPermission ||
    hasManageBillingPermission
  ) {
    defaultIconsData.push({
      key: 'settings',
      icon: { icon: WorkbaseSettingsIcon },
      onClick: () =>
        navigate(
          isOwner || hasManageDepartmentsPermission || hasManageAllCompanySettingsPermission
            ? '/settings/site-information'
            : '/settings/employee-recommendations'
        ),
      isActive: !!matchPath({ path: 'settings', end: false }, location.pathname),
    });
  }

  return defaultIconsData;
}

export default getIconData;
