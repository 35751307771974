import { InputAdornment, Stack, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { WorkbaseChevronBackIcon } from 'src/assets/icons/workbaseIcons';
import BrandedDefaultImage from 'src/lib/components/atoms/branded-default-image/BrandedDefaultImage';
import { WorkbaseIcon, WorkbaseIconByName } from 'src/lib/components/atoms/icon';
import { showNotification } from 'src/lib/components/atoms/notification';
import { WorkbaseCircularProgress } from 'src/lib/components/atoms/progress/CircularProgress';
import { WorkbaseTextField } from 'src/lib/components/atoms/text-field';
import EditableImageUpload from 'src/ui-components/custom/image-edit/EditableImageUpload';

import { imageUpload } from 'src/lib/utils/imageUpload';
import {
  TrainingModule,
  usePatchTrainingModuleMutation,
} from 'src/store/api/trainings/trainingModulesApi';
import { useGetSingleTrainingQuery } from 'src/store/api/trainings/trainingsApi';

export default function EditContent({
  trainingModule,
  mobileView,
  progress,
}: {
  trainingModule: TrainingModule;
  mobileView: boolean;
  progress: number;
}) {
  const [moduleName, setModuleName] = useState(trainingModule.name);
  const [patchModule] = usePatchTrainingModuleMutation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { data: trainingData } = useGetSingleTrainingQuery(trainingModule.trainingId);
  const trainingName = trainingData?.name || t('trainingDetailPage.unnamedTraining');
  const onImgChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files?.[0];

    if (!file) return;
    try {
      const response = await imageUpload(file);
      await patchModule({
        module: {
          trainingId: trainingModule.trainingId,
          id: trainingModule.id,
          imageId: response.data.result.id,
        },
        imageSrc: response.data.result.variants[0],
      });
    } catch (err) {
      showNotification(t('validationErrorMessages.UploadImageErrorMessage'), 'error');
    }
  };

  const removeImage = async (): Promise<void> => {
    await patchModule({
      module: { trainingId: trainingModule.trainingId, id: trainingModule.id, imageId: null },
      imageSrc: '',
    });
  };

  return (
    <>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: mobileView ? 'space-between' : 'flex-start',
          width: mobileView ? '100%' : 'auto',
          order: mobileView ? 1 : 0,
          mt: mobileView ? 2.25 : 0,
          flexGrow: 1,
        }}
      >
        <WorkbaseCircularProgress
          value={progress}
          size="big"
          sx={{
            order: mobileView ? 1 : 0,
            '&&&&': { color: 'text.secondary' },
          }}
        />
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            ml: mobileView ? 0 : '15.5px',
            flexGrow: 1,
            minWidth: 0,
            maxWidth: '350px',
          }}
        >
          <Stack sx={{ flexDirection: 'column', mr: 2 }}>
            {mobileView && (
              <Stack
                sx={{ maxWidth: '300px' }}
                onClick={() =>
                  navigate(`/trainings/${params.trainingId}/edit`, { relative: 'route' })
                }
              >
                <Typography
                  variant="body1"
                  color="grey[500]"
                  noWrap
                  sx={{
                    alignItems: 'center',
                    cursor: 'pointer',
                    width: '100%',
                    '&&&&.MuiTypography-root ': {
                      ':hover': {
                        color: 'text.secondary',
                      },
                    },
                  }}
                >
                  <WorkbaseIcon
                    icon={WorkbaseChevronBackIcon}
                    color="inherit"
                    sx={{ width: mobileView ? 12 : 15, height: mobileView ? 12 : 15 }}
                  />
                  {trainingName}
                </Typography>
              </Stack>
            )}
            <WorkbaseTextField
              value={moduleName}
              onChange={(e) => setModuleName(e.target.value)}
              sx={{
                width: '100%',
                maxWidth: '536px',
                minWidth: 0,
                '&& .MuiInputBase-root': mobileView
                  ? {
                      paddingLeft: 0,
                    }
                  : {},
                '& input': mobileView
                  ? {
                      padding: 0,
                      fontSize: 18,
                      marginLeft: '-6px',
                    }
                  : {},
              }}
              onBlur={() => {
                if (!moduleName) return;
                patchModule({
                  module: {
                    name: moduleName,
                    id: trainingModule.id,
                    trainingId: trainingModule.trainingId,
                  },
                });
              }}
              size="H2"
              bordered={!mobileView}
              backgroundColor={mobileView ? undefined : 'tertiary.main'}
              backgroundBlur={0.9}
              placeholder={t('editor.trainingsSection.moduleTitlePlaceholder')}
              InputProps={
                mobileView
                  ? {
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography variant="h6" color="text.primary">
                            {t('modulePage.moduleTitle')} {trainingModule.order}:
                          </Typography>
                        </InputAdornment>
                      ),
                    }
                  : {
                      endAdornment: (
                        <InputAdornment position="end">
                          <WorkbaseIconByName iconName="WorkbaseEditIcon" size={16} />
                        </InputAdornment>
                      ),
                    }
              }
            />
          </Stack>
        </Stack>
      </Stack>
      {/* until lessons refactor */}
      <Stack width={mobileView ? '100%' : 290} height={160}>
        <EditableImageUpload
          onChange={onImgChange}
          src={trainingModule.imageUrl || undefined}
          placeholder={<BrandedDefaultImage name={trainingModule.defaultImage} />}
          onClear={removeImage}
        />
      </Stack>
    </>
  );
}
