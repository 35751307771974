import { Box, IconButton, SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WorkbaseCloseIcon } from 'src/assets/icons/workbaseIcons';
import { WorkbaseCircularProgress } from 'src/lib/components/atoms/progress/CircularProgress';
import { byteConverter } from 'src/lib/utils/format-number';
import { useTranslation } from 'react-i18next';
import { FileType } from '../edit-bar/UploadActions';

function UploadProgress({
  mediaType,
  size,
  progress,
  onClose,
  sx,
}: {
  mediaType: FileType;
  size: number;
  progress: number;
  onClose?: () => void;
  sx?: SxProps<Theme>;
}) {
  const { t } = useTranslation();
  const uploadInProgress = progress > 0 && progress < 100;
  return (
    <Box
      sx={{
        borderWidth: 1,
        borderColor: 'grey.500',
        borderStyle: 'solid',
        borderRadius: (theme) => theme.shape.borderRadius * 2.5,
        pb: 5.5,
        pt: 4.75,
        px: 2,
        textAlign: 'center',
        position: 'relative',
        ...sx,
      }}
    >
      <WorkbaseCircularProgress value={progress} size="big" />
      <Typography variant="body1" fontWeight="bold">
        {/* {`${mediaType} is ${uploadInProgress ? 'being ' : ''}uploaded`} */}
        {t(`uploadProgress.${mediaType}.title`)}{' '}
        {`${uploadInProgress ? `${t('uploadProgress.beingLabel')} ` : ''}`}
        {t('uploadProgress.uploadedLabel')}
      </Typography>
      <Typography color="secondary" variant="body2">
        {size ? `${byteConverter((progress / 100) * size)} / ${byteConverter(size)}` : null}
      </Typography>
      <IconButton
        onClick={onClose}
        sx={{
          p: 1,
          minWidth: 'auto',
          position: 'absolute',
          right: 20,
          top: 20,
        }}
      >
        <WorkbaseCloseIcon />
      </IconButton>
    </Box>
  );
}

export default UploadProgress;
