import { Components } from '@mui/material';
import { Theme } from '@mui/material/styles';
import MuiCssBaseline from './MuiCssBaseline';
import MuiButton from './MuiButton';
import type {} from '@mui/lab/themeAugmentation';
import MuiTabPanel from './MuiTabPanel';
import MuiTab from './MuiTab';

const components: Components<Theme> = {
  MuiCssBaseline,
  MuiButton,
  MuiTabPanel,
  MuiTab,
};

export default components;
