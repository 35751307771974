import { ReactNode, useEffect, useState } from 'react';
import { createTheme, CssBaseline, PaletteMode, ThemeProvider } from '@mui/material';
import typography from 'src/mui/typography';
import components from 'src/mui/components';
import { useAppSelector } from 'src/store';
import { useBrandingColors } from './useBrandingColors';

export default function CustomThemeProvider({ children }: { children: ReactNode }) {
  const primaryMain = useBrandingColors();
  const { borderRadius, paletteMode } = useAppSelector((state) => state.themeSettings);

  const [effectivePaletteMode, setEffectivePaletteMode] = useState<PaletteMode>(
    paletteMode === 'auto' ? 'dark' : paletteMode
  );

  useEffect(() => {
    if (paletteMode === 'auto') {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

      setEffectivePaletteMode(mediaQuery.matches ? 'dark' : 'light');

      const handleChange = (event: MediaQueryListEvent) => {
        setEffectivePaletteMode(event.matches ? 'dark' : 'light');
      };

      mediaQuery.addEventListener('change', handleChange);

      return () => {
        mediaQuery.removeEventListener('change', handleChange);
      };
    }
    setEffectivePaletteMode(paletteMode);
    return undefined;
  }, [paletteMode]);

  const primaryColor = primaryMain ? { primary: { main: primaryMain } } : {};

  const theme = createTheme({
    palette: {
      mode: effectivePaletteMode,
      ...primaryColor,
    },
    typography,
    components,
    shape: {
      borderRadius,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
