import PortalComponent from 'src/lib/components/util/PortalComponent';
import ModuleActions from 'src/core/ModuleActions';
import { useNavigate, useParams } from 'react-router-dom';
import { TopBar } from 'src/ui-components/layout/top-bar';

export interface ModuleOverviewTopBarProps {
  scrolled?: boolean;
  moduleId: string;
  lessonsCount?: number;
  sectionsCount?: number;
  trainingId: string;
}

export default function ModuleOverviewTopBar({
  scrolled = false,
  moduleId,
  lessonsCount,
  sectionsCount,
  trainingId,
}: ModuleOverviewTopBarProps) {
  const navigate = useNavigate();
  const params = useParams();

  if (!params.moduleId) {
    throw new Error('Module id not found');
  }

  return (
    <PortalComponent elementSelector="#main-layout-content-card">
      <TopBar
        showBorder={scrolled}
        rightContent={
          <ModuleActions
            lessonsCount={lessonsCount}
            sectionsCount={sectionsCount}
            moduleId={moduleId}
            onDelete={() => navigate(`/trainings/${trainingId}`)}
          />
        }
      />
    </PortalComponent>
  );
}
