import { Alert, Link, Snackbar, styled } from '@mui/material';
import WorkbaseImage from 'src/lib/components/atoms/image/Image';
import { useDropzone } from 'react-dropzone';
import { useState } from 'react';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { EDITOR } from 'src/lib/constants/editor';
import { imageUpload } from 'src/lib/utils/imageUpload';
import HighlightItem from '../highlight-item';
import { ResizeStopCallbackType } from '../highlight-item/resize/ResizableWrapper';
import { allowedFileTypes } from '../upload-item/config';
import UploadProgress from '../upload-item/UploadProgress';
import useUploadingMedia from '../hooks/useUploadingMedia';

const EditorImage = styled(WorkbaseImage)`
  border-radius: ${({ theme }) => theme.shape.borderRadius * 2.5};
  width: 100%;
  object-fit: cover;
`;

interface Props {
  id: string;
  onDelete: () => void;
  onDuplicate: () => void;
  onUploadImage: (url: string, fileName: string) => void;
  onAddCaption: () => void;
  onDownload: () => void;
  readOnly?: boolean;
  onResizeStop?: ResizeStopCallbackType;
  url: string;
  fileName: string;
  href?: string;
  height?: number;
  aspectRatio?: number;
  caption?: string;
  dragging?: {
    dragRef: ConnectDragSource;
    previewRef: ConnectDragPreview;
  };
  onHrefUpdate: (href: string) => void;
  onResizeStart?: () => void;
}

export default function ImageItem({
  id,
  onDelete,
  onDuplicate,
  readOnly = false,
  onResizeStop,
  onUploadImage,
  onDownload,
  url,
  href,
  dragging,
  aspectRatio,
  height,
  onResizeStart,
  onHrefUpdate,
}: Props) {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { handleClearUploading, handleAddingUploading } = useUploadingMedia(id, 'image');

  const snackBarHandler = () => {
    setErrorMessage(t('validationErrorMessages.UploadImageErrorMessage'));
    setShowSnackbar(true);
  };

  const resetStates = () => {
    setLoading(false);
    setFile(null);
    setUploadPercentage(0);
    handleClearUploading();
  };

  const handleDropSingleFile = async (acceptedFiles: File[]) => {
    setFile(Object.assign(acceptedFiles[0]));
    const reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);
    try {
      reader.onload = () => sessionStorage.setItem('fallbackSrc', reader.result as string);
      // eslint-disable-next-line no-empty
    } catch (err) {}

    setLoading(true);
    handleAddingUploading();
    const response = await imageUpload(acceptedFiles[0], {
      onUploadProgress: (progressEvent) => {
        if (progressEvent.loaded && progressEvent.total) {
          const percentComplete = (progressEvent.loaded / progressEvent.total) * 100;
          setUploadPercentage(+percentComplete.toFixed(0));
        }
      },
    });

    if (response.status !== 201) {
      sessionStorage.clearItem('fallbackSrc');
      snackBarHandler();
      resetStates();
    }

    const { result } = response.data;
    const { variants } = result;
    const [imageUrl] = variants;
    onUploadImage(imageUrl, acceptedFiles[0].name);

    resetStates();
  };

  const handleClose = () => {
    setShowSnackbar(false);
  };

  const { getInputProps, open } = useDropzone({
    onDrop: handleDropSingleFile,
    noDrag: true,
    accept: {
      ...allowedFileTypes.image,
    },
  });

  const imageComponent = (
    <EditorImage
      className="editor-vertical-resizable"
      src={url}
      fallbackSrc={sessionStorage.getItem('fallbackSrc')}
      onFallbackLoaded={() => sessionStorage.removeItem('fallbackSrc')}
      alt={url}
      style={{
        ...(height && { height: `${height}px` }),
        ...(aspectRatio && { aspectRatio }),
      }}
    />
  );

  const imageComponentWithLink = (
    <Link href={href} target="_blank">
      {imageComponent}
    </Link>
  );

  if (readOnly) {
    return href ? imageComponentWithLink : imageComponent;
  }

  return (
    <HighlightItem
      editBarProps={{
        imageActionsProps: {
          onUpload: () => open(),
          onDownload,
        },
        hrefProps: {
          href,
          onChange: onHrefUpdate,
        },
        baseActionsProps: { onDelete, onDuplicate },
      }}
      dragging={dragging}
      horizontalResize={{}}
      verticalResize={{}}
      minHeight={EDITOR.verticalResizeMinHeightMedia}
      readOnly={readOnly}
      onResizeStop={onResizeStop}
      borderColor="transparent"
      onResizeStart={onResizeStart}
    >
      {({ EditBarComponent }) => (
        <>
          {EditBarComponent}
          {!loading && imageComponent}
          {loading && (
            <UploadProgress
              mediaType="image"
              size={file ? file.size : 0}
              progress={uploadPercentage}
              onClose={resetStates}
            />
          )}
          <input {...getInputProps()} />
          <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleClose}>
            <Alert severity="error">{errorMessage}</Alert>
          </Snackbar>
        </>
      )}
    </HighlightItem>
  );
}
