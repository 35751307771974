import { Fragment } from 'react';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import { WorkbaseFormControlLabel } from 'src/lib/components/atoms/checkbox';
import WorkbaseCheckbox from 'src/lib/components/atoms/checkbox/Checkbox';
import { t } from 'i18next';
import WorkbaseTooltip from 'src/lib/components/atoms/tooltip';
import {
  WorkbaseDownloadIcon,
  WorkbaseImageFrameIcon,
  WorkbaseImageNewIcon,
  WorkbaseRecordIcon,
  WorkbaseVideoUploadIcon,
} from '../../../../../assets/icons/workbaseIcons';
import { WorkbaseIcon } from '../../../atoms/icon/WorkbaseIcon';

export interface VideoActionsProps {
  onUploadVideo: () => void;
  onUploadPosterFromCurrentFrame: () => void;
  onUploadPoster: () => void;
  onVideoDownload: () => void;
  onOpenRecordingModal: () => void;
  onDisableFastForward?: () => void;
  onRequired?: () => void;
  disableFastForward?: boolean;
  showRequired?: boolean;
  isVideoRequired?: boolean;
  downloadDisabled: boolean;
}

export default function VideoActions({
  onUploadPoster,
  onUploadPosterFromCurrentFrame,
  onUploadVideo,
  onVideoDownload,
  onDisableFastForward,
  onRequired,
  isVideoRequired,
  disableFastForward,
  showRequired = false,
  onOpenRecordingModal,
  downloadDisabled,
}: VideoActionsProps) {
  const items = [
    {
      key: 'current-frame-as-thumbnail',
      onClick: () => onUploadPosterFromCurrentFrame(),
      icon: WorkbaseImageFrameIcon,
      styles: { padding: '3px 0px 5px 0px' },
    },
    {
      key: 'upload-thumbnail',
      onClick: () => onUploadPoster(),
      icon: WorkbaseImageNewIcon,
      divider: true,
      styles: { padding: '3px 5px 5px 0px' },
    },
    {
      key: 'new-video',
      onClick: () => onUploadVideo(),
      icon: WorkbaseVideoUploadIcon,
      styles: { padding: '3px 0px 5px 5px' },
    },
    {
      key: 'record-video',
      onClick: onOpenRecordingModal,
      icon: WorkbaseRecordIcon,
      styles: { padding: '3px 0px 5px 5px' },
    },
    {
      key: 'download-video',
      onClick: () => onVideoDownload(),
      icon: WorkbaseDownloadIcon,
      divider: showRequired,
      styles: { padding: '3px 5px 5px 0px' },
      disabled: downloadDisabled,
    },
  ];

  return (
    <Stack direction="row" gap="10px">
      {items.map(({ key, onClick, icon, divider, styles, disabled }) => (
        <Fragment key={`item-${key}`}>
          <WorkbaseTooltip title={t(`editor.topBar.tooltip.${key}`)} placement="top">
            <Box sx={{ ...styles }}>
              <IconButton
                disabled={disabled}
                onMouseDown={(e) => {
                  e.preventDefault();
                  onClick();
                }}
              >
                <WorkbaseIcon icon={icon} sx={{ '&&&': { width: 16, height: 16 } }} />
              </IconButton>
            </Box>
          </WorkbaseTooltip>
          {divider && <Divider orientation="vertical" flexItem />}
        </Fragment>
      ))}
      {showRequired && (
        <>
          <WorkbaseFormControlLabel
            sx={{ mx: 2 }}
            onClick={(e) => {
              if (onRequired) {
                onRequired();
                e.stopPropagation();
                e.preventDefault();
              }
            }}
            label={
              <Typography variant="body1" color="text.secondary" sx={{ marginLeft: 1 }}>
                {t('editor.videoItem.required')}
              </Typography>
            }
            control={<WorkbaseCheckbox onChange={onRequired} checked={isVideoRequired} />}
          />
          <Divider orientation="vertical" flexItem />
          <WorkbaseFormControlLabel
            sx={{ mx: 2 }}
            onClick={(e) => {
              if (onDisableFastForward) {
                onDisableFastForward();
                e.stopPropagation();
                e.preventDefault();
              }
            }}
            label={
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ marginLeft: 1, pointerEvents: 'all' }}
              >
                {t('editor.videoItem.disableFastForward')}
              </Typography>
            }
            control={
              <WorkbaseCheckbox onChange={onDisableFastForward} checked={disableFastForward} />
            }
          />
        </>
      )}
    </Stack>
  );
}
