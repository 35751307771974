import WorkbaseTree from 'src/ui-components/custom/hierarchical-tree/Tree';
import { TreeNodeModel } from 'src/ui-components/custom/hierarchical-tree/types';
import usePermissions from '../../usePermissions';
import useOnDropTree from './useOnDropTree';

interface Props {
  data: TreeNodeModel[];
}

export default function ExpandedTree({ data }: Props) {
  const {
    hasAdminAccessWhiteboardsPermission,
    hasAdminAccessEmbeddedAppsPermission,
    hasAdminAccessDashboardAndPagesPermission,
  } = usePermissions();
  const canDragTree =
    hasAdminAccessWhiteboardsPermission &&
    hasAdminAccessEmbeddedAppsPermission &&
    hasAdminAccessDashboardAndPagesPermission;
  const onDrop = useOnDropTree();

  return <WorkbaseTree tree={data} onDrop={onDrop} canDrag={() => !!canDragTree} />;
}
