import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseIconByName } from 'src/lib/components/atoms/icon';

export default function Title() {
  const { t } = useTranslation();
  return (
    <Stack gap={1} alignItems="center" direction="row" padding={1.5}>
      <WorkbaseIconByName iconName="WorkbaseModuleIcon" size={22} />
      <Typography variant="h6">{t('editor.trainingsSection.modulesTitle')}</Typography>
    </Stack>
  );
}
