import { Box, InputAdornment } from '@mui/material';
import { t } from 'i18next';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { WorkbaseTextField } from 'src/lib/components/atoms/text-field/TextField';
import {
  WorkbaseTab,
  WorkbaseTabContext,
  WorkbaseTabList,
  WorkbaseTabPanel,
} from 'src/lib/components/molecules/tabs-slider';
import WorkbaseDialogComponent from 'src/lib/components/molecules/dialog/Dialog';
import { WorkbaseIcon } from 'src/lib/components/atoms/icon/WorkbaseIcon';
import { WorkbaseDomainIcon } from 'src/assets/icons/workbaseIcons';
import debounce from 'lodash.debounce';
import validateUrl from 'src/lib/utils/validateUrl';
import { usePostIframeMutation } from 'src/store/api/iframeApi';
import { WorkbaseLinearProgress } from 'src/lib/components/atoms/progress/LinearProgress';
import { getEmbeddedUrl } from 'src/lib/utils/getEmbeddedUrl';
import ensureHttpProtocol from 'src/lib/utils/ensureHttpProtocol';
import { EmbeddedItemContentType } from '../../../../../features/editor/model';

// eslint-disable-next-line max-statements
export default function AddEmbeddedItemModal({
  open,
  onClose,
  onConfirm,
  initialContent = '',
  initialContentType = 'url',
  saveButtonText,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: ({
    contentType,
    content,
  }: {
    contentType: EmbeddedItemContentType;
    content: string;
  }) => void;
  initialContent?: string;
  initialContentType?: EmbeddedItemContentType;
  saveButtonText: string;
}) {
  const [contentType, setContentType] = useState<EmbeddedItemContentType>(initialContentType);
  const [content, setContent] = useState(initialContent);
  const [touched, setTouched] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const contentInitialized = useRef(!initialContent);
  const contentTypeInitialized = useRef(!initialContentType);
  const contentChangedRef = useRef(false);
  const [postIframe, { data, error, isLoading }] = usePostIframeMutation({});

  const isValidUrlForIframe = contentChangedRef.current ? !error && data?.success : true;

  useEffect(() => {
    if (!open) {
      debounce(() => {
        setContent('');
        setContentType('url');
        setTouched(false);
        contentInitialized.current = false;
        contentTypeInitialized.current = false;
        contentChangedRef.current = false;
      }, 300)();
    }
  }, [open]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedPostIframe = useCallback(
    debounce((url: string) => {
      const embedUrl = getEmbeddedUrl(url);
      postIframe({ url: embedUrl }).then(() => setTouched(true));
    }, 300),
    []
  );

  useEffect(() => {
    if (contentType === 'url') {
      const isValidUrl = validateUrl(content);
      setIsValid(isValidUrl);
      if (isValidUrl && contentChangedRef.current) {
        debouncedPostIframe(ensureHttpProtocol(content));
      }
    } else {
      setIsValid(content.trim() !== '');
    }
  }, [content, contentType, debouncedPostIframe]);

  useEffect(() => {
    if (contentType !== initialContentType) {
      setContent('');
    } else {
      setContent(initialContent);
    }
    setTouched(false);
  }, [contentType, initialContent, initialContentType]);

  useEffect(() => {
    if (initialContent && open && !contentInitialized.current) {
      setContent(initialContent);
      contentInitialized.current = true;
    }
  }, [initialContent, open]);

  useEffect(() => {
    if (initialContentType && open && !contentTypeInitialized.current) {
      setContentType(initialContentType);
      contentTypeInitialized.current = true;
    }
  }, [initialContentType, open]);

  const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setContent(e.target.value);
    contentChangedRef.current = true;
  };

  const handleBlur = () => {
    setTouched(true);
  };

  useEffect(() => {
    contentChangedRef.current = false;
  }, [contentType]);

  const isErrorState = contentType === 'url' ? !isValid || !isValidUrlForIframe : !isValid;

  const errorMsg = !isValid && !isValidUrlForIframe ? t('invalidURL') : t('cantBeEmbedded');

  const showErrorMessage = isErrorState && touched && !isLoading;
  return (
    <WorkbaseDialogComponent
      title={t('editor.embeddedItemModal.title')}
      open={open}
      onClose={onClose}
      confirmButton={{
        text: saveButtonText,
        onClick: () =>
          onConfirm({
            contentType,
            content: contentType === 'url' ? getEmbeddedUrl(content) : content,
          }),
        disabled: isErrorState || isLoading,
      }}
      sx={{
        '.MuiDialogActions-root': {
          paddingTop: '12px',
        },
        '.MuiDialogContent-root': { overflow: 'hidden' },
      }}
    >
      <Box sx={{ width: 536 }}>
        <WorkbaseTabContext
          value={
            initialContentType && !contentTypeInitialized.current ? initialContentType : contentType
          }
        >
          <WorkbaseTabList
            onChange={(_, newVal) => {
              setContentType(newVal);
            }}
            fullWidth
          >
            <WorkbaseTab
              sx={{ width: '50%' }}
              label={t('editor.embeddedItemModal.urlTab')}
              value="url"
            />
            <WorkbaseTab
              sx={{ width: '50%' }}
              label={t('editor.embeddedItemModal.htmlCodeTab')}
              value="htmlCode"
            />
          </WorkbaseTabList>
          <Box marginTop={2.5}>
            <WorkbaseTabPanel value="url" sx={{ pb: 1, position: 'relative' }}>
              <WorkbaseTextField
                value={content}
                error={showErrorMessage}
                onChange={handleTextFieldChange}
                onBlur={handleBlur}
                fullWidth
                helperText={showErrorMessage ? errorMsg : ''}
                label={t('editor.embeddedItemModal.urlTextField.label')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <WorkbaseIcon
                        icon={WorkbaseDomainIcon}
                        color="text.secondary"
                        sx={{ width: 16, height: 16 }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <WorkbaseLinearProgress
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  visibility: isLoading ? 'visible' : 'hidden',
                }}
              />
            </WorkbaseTabPanel>
            <WorkbaseTabPanel value="htmlCode">
              <WorkbaseTextField
                fullWidth
                value={content}
                error={showErrorMessage}
                onBlur={handleBlur}
                onChange={handleTextFieldChange}
                label={t('editor.embeddedItemModal.htmlCodeTextField.label')}
                maxRows={6}
                sx={{
                  fieldset: {
                    borderRadius: (theme) => theme.shape.borderRadius * 2.5,
                  },
                }}
                helperText={
                  showErrorMessage ? t('editor.embeddedItemModal.htmlCodeTextField.error') : ''
                }
                minRows={6}
                multiline
              />
            </WorkbaseTabPanel>
          </Box>
        </WorkbaseTabContext>
      </Box>
    </WorkbaseDialogComponent>
  );
}
