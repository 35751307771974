import { Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseRoleIcon } from 'src/assets/icons/workbaseIcons';

export default function EmptyPermissionsContent() {
  const { t } = useTranslation();

  return (
    <Stack gap={1} alignItems="center">
      <SvgIcon component={WorkbaseRoleIcon} color="primary" fontSize="large" />
      <Typography variant="subtitle1" fontWeight="bold" align="center" color="primary">
        {t('companySettings.sidebar.departments.permissions.emptyTitle')}
      </Typography>
      <Typography variant="caption" fontWeight="normal" align="center" color="grey[500]">
        {t('companySettings.sidebar.departments.permissions.emptysubText')}
      </Typography>
    </Stack>
  );
}
