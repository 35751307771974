import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import TrainingDetailHeader from 'src/features/editor/view/training-detail-header';
import { showNotification } from 'src/lib/components/atoms/notification';
import {
  useDeleteTrainingsMutation,
  useDuplicateTrainingsMutation,
} from 'src/store/api/trainings/trainingsApi';
import { useAppSelector } from 'src/store';
import { shallowEqual } from 'react-redux';
import ShareModal from 'src/core/share-modal/ShareModal';
import { useShareLevelQuery } from 'src/store/api/shareResourceApi';
import { useUserPermissionsQuery } from 'src/store/api/userPermissionsApi';
import { PermissionCodes } from 'src/lib/enums/permissions';
import useTrainingPermissions from 'src/lib/hooks/useTrainingPermissions';
import { EditorWithModeSwitch } from 'src/features/editor';
import { ShareLevel } from 'src/lib/types/share-level';
import DeleteDuplicateMenu from 'src/ui-components/custom/delete-duplicate-menu';

export default function TrainingCourse() {
  const param = useParams();
  const navigate = useNavigate();
  const { data: userPermissions } = useUserPermissionsQuery();
  const hasAddTrainingsPermission = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.ADD_TRAININGS
  );
  const [handleDeleteTraining] = useDeleteTrainingsMutation();
  const [handleDuplicateTraining] = useDuplicateTrainingsMutation();
  const { t } = useTranslation();
  const trainingName =
    useAppSelector((state) => state.editor.editorData?.trainingsDetailHeader?.name, shallowEqual) ||
    t('trainingDetailPage.unnamedTraining');

  const { data: shareLevelData, error } = useShareLevelQuery({
    resourceType: 'training',
    resourceId: param.trainingId as string,
  });

  const { hasEditingPermissions } = useTrainingPermissions();

  const hasManagePermission = shareLevelData?.shareLevel === ShareLevel.manage;

  return (
    <EditorWithModeSwitch
      canEdit={hasEditingPermissions}
      canView={shareLevelData?.shareLevel !== ShareLevel.none && !error}
      renderHeader={(readOnly) => (
        <TrainingDetailHeader readOnly={readOnly} trainingId={param.trainingId} />
      )}
      topBarRightContent={
        <>
          <ShareModal
            name={trainingName}
            resourceId={param.trainingId as string}
            resourceType="training"
          />
          <DeleteDuplicateMenu
            onDuplicate={
              hasAddTrainingsPermission
                ? () => {
                    handleDuplicateTraining(param.trainingId as string).then((resp: any) => {
                      if (resp?.error) {
                        showNotification(t('notifications.duplicateError'), 'error');
                      } else {
                        showNotification(t('notifications.duplicateSuccess'));
                      }
                    });
                  }
                : undefined
            }
            deleteDialogDescription={t(
              'editor.topBar.customQuestionsOnSettingIcon.deleteTrainingQuestion'
            )}
            onDelete={
              hasManagePermission
                ? () => {
                    handleDeleteTraining(param.trainingId as string).then((resp: any) => {
                      if (resp?.error) {
                        showNotification(t('notifications.deleteError'), 'error');
                      } else {
                        showNotification(t('notifications.deleteSuccess'));
                        navigate('/');
                      }
                    });
                  }
                : undefined
            }
          />
        </>
      }
      documentName={`training.${param.trainingId}`}
    />
  );
}
