import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import WorkbaseBreadcrumbs from 'src/lib/components/molecules/breadcrumbs';
import useMaintainEditPath from 'src/lib/hooks/useMaintainEditPath';
import { useTrainingLessonQuery } from 'src/store/api/trainings/trainingLessonsApi';
import { useGetSingleTrainingQuery } from 'src/store/api/trainings/trainingsApi';

interface Props {
  moduleName: string;
  trainingId: string;
  moduleId: string;
}

export default function ModuleOverviewBreadcrumbs({ moduleName, trainingId, moduleId }: Props) {
  const { t } = useTranslation();
  const params = useParams();
  const { lessonId } = params;
  const { data: trainingData } = useGetSingleTrainingQuery(trainingId);
  const { data: lessonData } = useTrainingLessonQuery(lessonId as string, {
    skip: lessonId === undefined,
  });
  const path = useMaintainEditPath();

  const lessonItem = lessonId
    ? [
        {
          key: 'lesson',
          label: lessonData?.name || t('modulePage.unnamedLesson'),
          to: `/trainings/${trainingId}/${moduleId}/${lessonId}`,
        },
      ]
    : [];

  const trainingsItem = !lessonId
    ? [
        {
          key: 'trainings',
          label: t('sidebar.tabTitleTrainings'),
          to: `/trainings${path}`,
        },
      ]
    : [];

  const data = [
    ...trainingsItem,
    {
      key: 'training',
      label: trainingData?.name || t('trainingDetailPage.unnamedTraining'),
      to: `/trainings/${trainingId}${path}`,
    },
    {
      key: 'module',
      label: moduleName || t('trainingDetailPage.unnamedModule'),
      to: `/trainings/${trainingId}/${moduleId}${path}`,
    },
    ...lessonItem,
  ];

  return (
    <WorkbaseBreadcrumbs
      data={data}
      sx={{ mb: '10px', marginLeft: 'auto', marginRight: 'auto', maxWidth: 1040, width: '100%' }}
    />
  );
}
