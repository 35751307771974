import { alpha, Box, styled, Typography } from '@mui/material';
import { ReactPlayerProps } from 'react-player';
import { useTranslation } from 'react-i18next';
import WorkbaseProgressNotification from 'src/lib/components/molecules/progress-notification/ProgressNotification';
import { WorkbasePlayerProps } from './Player.reducer';

const StyledPlayerOverlay = styled('div')<ReactPlayerProps>`
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: start;
  left: 0;
  z-index: 1;
  top: 0;
  bottom: 0;
  background-color: ${({ state }) => (state.ended ? 'rgba(0, 0, 0, 0.4)' : 'transparent')};

  transition: opacity 0.2s ease-in-out;
  border-radius: ${({ theme }) => theme.shape.borderRadius * 2.5};

  .video-player__overlay-inner {
    display: flex;
    align-items: flex-start;
    padding-left: 10px;
    padding-top: 10px;
    height: ${({ state }) => (state.light ? 'auto' : '100%')};
    width: ${({ state }) => (state.light ? 'auto' : '100%')};
  }
`;

interface PlayerOverlayProps {
  state: WorkbasePlayerProps;
  isVideoUploaded: boolean;
  readOnly: boolean;
  title?: string;
  newUploadPercentage?: number;
}

function PlayerOverlay(props: PlayerOverlayProps) {
  const { state, isVideoUploaded, readOnly, title, newUploadPercentage } = props;
  const { t } = useTranslation();

  const titleComponent = title && readOnly && (
    <Typography
      noWrap
      color="white.main"
      variant="h4"
      sx={{
        zIndex: 2,
        padding: '10px 20px',
        backgroundColor: (theme) => alpha(theme.palette.common.black as string, 0.25),
        borderRadius: (theme) => theme.shape.borderRadius,
      }}
    >
      {title}
    </Typography>
  );
  const displayProgress = newUploadPercentage && newUploadPercentage < 100;
  const uploadingText = (
    <>
      {t(`uploadProgress.video.title`)}{' '}
      {`${displayProgress ? `${t('uploadProgress.beingLabel')} ` : ''}`}
      {t('uploadProgress.uploadedLabel')}
    </>
  );
  return (
    <StyledPlayerOverlay state={state}>
      <Box className="video-player__overlay-inner">
        {isVideoUploaded && !displayProgress ? (
          titleComponent
        ) : (
          <WorkbaseProgressNotification
            text={displayProgress ? uploadingText : t('editor.videoItem.videoProcessingLabel')}
            uploadPercentage={newUploadPercentage || 0}
            displayProgress={displayProgress || false}
            sx={{ marginTop: readOnly ? '10px' : '60px' }}
          />
        )}
      </Box>
    </StyledPlayerOverlay>
  );
}

export default PlayerOverlay;
