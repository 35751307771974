import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import WorkbaseDialogComponent from 'src/lib/components/molecules/dialog/Dialog';

interface DeleteDialogProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export default function ApiKeyDeleteDialog({ open, onClose, onDelete }: DeleteDialogProps) {
  const { t } = useTranslation();
  return (
    <WorkbaseDialogComponent
      dataCy="delete-api-key"
      open={open}
      onClose={onClose}
      title={t('companySettings.apiKeys.deleteWarningTitle')}
      confirmButton={{
        text: t('delete'),
        onClick: onDelete,
        type: 'error',
      }}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
        },
      }}
      cancelButton
    >
      <Typography variant="body1">{t('companySettings.apiKeys.deleteWarningText')}</Typography>
    </WorkbaseDialogComponent>
  );
}
