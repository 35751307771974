import { ButtonBase, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useEffect } from 'react';

interface Props {
  dragRef?: any;
  buttonRef: any;
  dndIndicatorFocusedRef: any;
  sx?: SxProps<Theme>;
  setHovered: (val: boolean) => void;
}

export default function DnDIndicator({
  dragRef,
  buttonRef,
  dndIndicatorFocusedRef,
  setHovered,
  sx,
}: Props) {
  useEffect(
    () => () => {
      const editBar: any = document.querySelector('.edit-bar');
      if (editBar && dragRef) {
        editBar.style.display = 'block';
      }
      if (dragRef && buttonRef.current) {
        // eslint-disable-next-line no-param-reassign
        buttonRef.current.style.transform = 'none';
      }
    },
    [dragRef, buttonRef]
  );

  return (
    <ButtonBase
      ref={dragRef}
      onMouseDown={() => {
        // eslint-disable-next-line no-param-reassign
        dndIndicatorFocusedRef.current = true;
      }}
      onDragStart={() => {
        const editBar: any = document.querySelector('.edit-bar');
        if (editBar && dragRef) {
          editBar.style.display = 'none';
        }
        if (dragRef) {
          // eslint-disable-next-line no-param-reassign
          buttonRef.current.style.transition = 'none';
          // eslint-disable-next-line no-param-reassign
          buttonRef.current.style.transform = 'scale(0.75) translateY(0)';
        }
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => {
        // eslint-disable-next-line no-param-reassign
        dndIndicatorFocusedRef.current = false;
        setHovered(false);
      }}
      onDragEnd={() => {
        const editBar: any = document.querySelector('.edit-bar');
        if (editBar && dragRef) {
          editBar.style.display = 'block';
        }
      }}
      sx={{
        width: `30px`,
        height: `10px`,
        transitionDuration: '.15s',
        borderRadius: (theme) => theme.shape.borderRadius,
        border: '2px solid white',
        position: 'absolute',
        backgroundColor: 'primary.main',
        zIndex: 3,
        top: 2,
        left: '50%',
        cursor: 'grab',
        transform: `translate(-50%, -60%)`,
        '&:hover': {
          transform: `translate(-50%, -60%) scale(1.4)`,
        },
        ...sx,
      }}
    />
  );
}
