import { useCallback, useEffect, useState } from 'react';
import {
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import WorkbaseMenuItem from 'src/lib/components/atoms/menu-item';
import { Theme } from '@mui/material/styles';
import { WorkbaseTextField } from '../../../lib/components/atoms/text-field';
import { IconName, WorkbaseIconByName } from '../../../lib/components/atoms/icon';
import { throttledShowNotification } from '../../../lib/components/atoms/notification';

interface Props {
  selectedValue: string;
  onSelect: (val: string) => void;
  deviceKind: MediaDeviceKind;
  label: {
    icon: IconName;
    text: string;
  };
  disabled?: boolean;
}

const cleanLabel = (label: string) => label.replace(/\([^)]*\)/g, '').trim();

function IconComponent(props: any) {
  return (
    <WorkbaseIconByName
      iconName="WorkbaseArrowDownIcon"
      size={16}
      color="primary.main"
      sx={{ mt: '3px', mr: 1 }}
      {...props}
    />
  );
}

export default function DeviceSelect({
  selectedValue,
  onSelect,
  deviceKind,
  label,
  disabled = false,
}: Props) {
  const { t } = useTranslation();
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [accessError, setAccessError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const getDevices = useCallback(
    async (withAutoSelect = true) => {
      try {
        const getUserMediaType = deviceKind === 'videoinput' ? 'video' : 'audio';
        const stream = await navigator.mediaDevices.getUserMedia({ [getUserMediaType]: true });
        setMediaStream(stream);
        const mediaDevices = await navigator.mediaDevices.enumerateDevices();
        const devicesToSet = mediaDevices.filter((device) => device.kind === deviceKind);
        const defaultDevice = devicesToSet.find((device) => device.deviceId === 'default');
        const filteredDevices = devicesToSet.filter((device) => {
          if (
            defaultDevice &&
            device.groupId === defaultDevice.groupId &&
            device.deviceId !== 'default'
          ) {
            return false;
          }
          return true;
        });
        setDevices(filteredDevices);
        const currentDeviceExists = filteredDevices.some(
          (device) => device.deviceId === selectedValue
        );
        if (!currentDeviceExists && withAutoSelect) {
          onSelect(filteredDevices[0]?.deviceId || '');
        }
        setAccessError(false);
      } catch (error) {
        throttledShowNotification(t('recording.noDeviceAccess'), 'error');
        setAccessError(true);
      }
    },
    [onSelect, deviceKind, t, selectedValue]
  );

  useEffect(() => {
    if (selectedValue === '' && devices.length === 0 && !accessError) {
      getDevices();
    }
  }, [getDevices, selectedValue, devices, accessError]);

  useEffect(() => {
    if (selectedValue) {
      const onDeviceChange = () => getDevices();
      navigator.mediaDevices.addEventListener('devicechange', onDeviceChange);

      return () => {
        navigator.mediaDevices.removeEventListener('devicechange', onDeviceChange);
        if (mediaStream) {
          mediaStream.getTracks().forEach((track) => track.stop());
        }
      };
    }
    return undefined;
  }, [getDevices, selectedValue, mediaStream]);

  const devicesMenuData = devices.map((cam) => ({
    value: cam.deviceId,
    label: cleanLabel(cam.label),
  }));

  const availableDeviceOptions = [
    ...devicesMenuData,
    {
      value: 'noDevice',
      label: t('recording.noDevice'),
    },
  ];
  const isSelectedValueEmpty = selectedValue === '';
  const isNoDevices = devices.length === 0;
  return (
    <Stack gap={0.75}>
      <Stack direction="row" gap={0.5} alignItems="center">
        <WorkbaseIconByName size={12} iconName={label.icon} color="text.secondary" />
        <Typography variant="body2" fontWeight="medium" color="secondary">
          {label.text}
        </Typography>
      </Stack>
      <WorkbaseTextField
        select
        error={accessError}
        SelectProps={{
          disabled,
          open: !isNoDevices && isOpen,
          onClose: () => {
            setIsOpen(false);
          },
          onOpen: () => {
            if (isNoDevices) {
              getDevices(false);
            }
            setIsOpen(true);
          },
          value: selectedValue,
          IconComponent,
          onChange: (e) => onSelect(e.target.value as string),
          MenuProps: {
            sx: {
              display: isNoDevices ? 'none' : 'block',
            },
            MenuListProps: {
              sx: {
                paddingX: '10px',
              },
            },
          },
          renderValue: (selected) => (
            <Typography variant="body1" fontWeight="medium" sx={{ marginLeft: 1 }}>
              {availableDeviceOptions.find(({ value }) => value === selected)?.label}
            </Typography>
          ),
        }}
        InputProps={{
          startAdornment: isSelectedValueEmpty ? (
            <InputAdornment position="start" sx={{ pointerEvents: 'none', position: 'absolute' }}>
              <Typography variant="body1" color="grey[500]">
                {t('recording.selectDevicePlaceholder')}
              </Typography>
            </InputAdornment>
          ) : undefined,
          sx: {
            fieldset:
              selectedValue === 'noDevice' || selectedValue === ''
                ? {
                    border: (theme) => `1px solid ${theme.palette.secondary.main} !important`,
                  }
                : undefined,
            '& .MuiSelect-select': {
              height: '38px !important',
              boxSizing: 'border-box',
            },
            '& .MuiSelect-icon': disabled
              ? {
                  color: (theme) => `${theme.palette.primary.light} !important`,
                }
              : {},
          } as SxProps<Theme>,
        }}
      >
        {availableDeviceOptions.map(({ value, label: optionLabel }) => (
          <WorkbaseMenuItem key={value} value={value}>
            <ListItemText disableTypography>
              <Typography variant="body1" fontWeight="medium" noWrap>
                {optionLabel}
              </Typography>
            </ListItemText>
            {value === selectedValue && (
              <ListItemIcon sx={{ minWidth: 'auto !important' }}>
                <WorkbaseIconByName iconName="WorkbaseCheckmarkBigIcon" color="primary.main" />
              </ListItemIcon>
            )}
          </WorkbaseMenuItem>
        ))}
      </WorkbaseTextField>
    </Stack>
  );
}
