import { t } from 'i18next';
import { WorkbaseWarningIcon } from 'src/assets/icons/workbaseIcons';
import { useEditor } from 'src/features/editor/controller';
import WorkbaseDialogComponent from 'src/lib/components/molecules/dialog/Dialog';

interface Props {
  open: boolean;
  onClose: () => void;
  linkName: string;
  id: string;
}

export default function DeleteLinkModal({ open, onClose, linkName, id }: Props) {
  const { handleRemoveLink } = useEditor();
  return (
    <WorkbaseDialogComponent
      title={t('dashboardPage.header.removeLinkModal.title')}
      titleIcon={{ icon: WorkbaseWarningIcon, color: 'error.main' }}
      open={open}
      onClose={onClose}
      cancelButton={{
        text: t('cancel'),
        type: 'primary',
      }}
      confirmButton={{
        text: t('delete'),
        onClick: () => handleRemoveLink(id),
        type: 'error',
      }}
      sx={{
        '.MuiDialogContent-root': { overflow: 'hidden' },
      }}
    >
      {t('dashboardPage.header.removeLinkModal.contentText', { name: linkName })}
    </WorkbaseDialogComponent>
  );
}
