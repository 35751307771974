import { Button, Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePatchSettingsMutation, useSettingsQuery } from 'src/store/api/settingsApi';
import { WorkbaseResetIcon } from 'src/assets/icons/workbaseIcons';
import ColorConfiguration from './ColorConfiguration';

const defaultPrimaryColor = '#146EB4';

export default function BrandingColors() {
  const { t } = useTranslation();
  const [patchSettings] = usePatchSettingsMutation();
  const { data } = useSettingsQuery();
  const mainColor = data?.brandingColors.find(({ type }) => type === 'primaryMain');
  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="subtitle1" color="text.primary" fontWeight="medium">
          {t('companySettings.siteInformation.brandingColors.brandingColorsTitle')}
        </Typography>
        <Stack justifyContent="right">
          <Button
            data-cy="reset-button"
            color="inherit"
            size="small"
            variant="text"
            startIcon={
              <SvgIcon>
                <WorkbaseResetIcon />
              </SvgIcon>
            }
            onClick={() => {
              patchSettings({
                brandingBaseColor: defaultPrimaryColor,
              });
            }}
            disabled={mainColor?.color === defaultPrimaryColor}
          >
            {t('resettableTextArea.resetDefaultLabel')}
          </Button>
        </Stack>
      </Stack>
      {mainColor && (
        <ColorConfiguration patchSettings={patchSettings} baseColor={mainColor?.color} />
      )}
    </Stack>
  );
}
