import { Box, Grid } from '@mui/material';
import { useAppSelector } from 'src/store';
import { GridRow } from '../../model/types';
import findItemInColumn, { isEmptyRowInReadOnly } from './utils';
import GridCell from './GridCell';
import useElementConfigs from './useElementConfigs';
import DroppableRowInBetween from './droppable-items/DroppableRowInBetween';
import DroppableSideRowItem from './droppable-items/DroppableSideRowItem';
import DroppableEmptyCell from './droppable-items/DroppableEmptyCell';
import { GAP_VALUE } from './constants';
import RowDashedVerticalLines from './RowDashedVerticalLines';

interface Props {
  row: GridRow;
  readOnly: boolean;
  index: number;
}

function RowItemsRenderer({
  gridRow,
  readOnly,
  rowIndex,
  hasFullWidthItem,
}: {
  gridRow: GridRow;
  readOnly: boolean;
  rowIndex: number;
  hasFullWidthItem: boolean;
}) {
  const isDragging = useAppSelector((state) => state.editor.isDragging);
  const resizingRowId = useAppSelector((state) => state.editor.resizingRowId);
  const horizontalLinesVisibility = isDragging || resizingRowId === gridRow.id;
  const cells: JSX.Element[] = [];
  const elementConfigs = useElementConfigs(gridRow.id);
  const renderItem = (column: number) => {
    const item = findItemInColumn(gridRow.items, column);

    if (!item) {
      cells.push(<DroppableEmptyCell key={column} row={gridRow} colStart={column} />);
      return column + 1;
    }
    const element = elementConfigs[item.elementType];
    if (element?.fullWidth) {
      cells.push(
        <GridCell key={item.id} row={gridRow} rowIndex={rowIndex} item={item} readOnly={readOnly} />
      );
      return 5; // Set column value beyond the loop limit to exit the loop.
    }
    const cols = item.columnEnd - item.columnStart;
    cells.push(
      <GridCell
        key={item.id}
        row={gridRow}
        item={item}
        rowIndex={rowIndex}
        cols={cols}
        readOnly={readOnly}
      />
    );
    return column + cols;
  };

  let column = 1;
  while (column <= 4) {
    column = renderItem(column);
  }

  return (
    <Grid
      className={hasFullWidthItem ? 'editor-full-width-item' : ''}
      container
      maxWidth={hasFullWidthItem ? '100%' : 1258}
      sx={{
        marginRight: `-${GAP_VALUE}px`,
        position: 'relative',
        zIndex: 4,
        flex: '1 1 auto',
        minWidth: '0',
      }}
    >
      {!hasFullWidthItem && horizontalLinesVisibility && <RowDashedVerticalLines />}
      {cells}
    </Grid>
  );
}

export default function Row({ row, readOnly, index }: Props) {
  const borderColor = readOnly ? 'transparent' : 'grey.300';
  const isDragging = useAppSelector((state) => state.editor.isDragging);
  const elementConfigs = useElementConfigs(row.id);
  const hasFullWidthItem = row.items.some((item) => elementConfigs[item.elementType]?.fullWidth);

  if (readOnly && isEmptyRowInReadOnly(row)) {
    return null;
  }

  return (
    <Box position="relative">
      {isDragging && <DroppableRowInBetween rowIndex={index} />}
      <Grid
        container
        justifyContent="center"
        sx={{
          borderColor,
          borderStyle: 'dashed',
          borderBottomWidth: '1px',
          borderTop: 'none',
          minHeight: '0',
          minWidth: '0',
          paddingLeft: '20px',
          paddingRight: '20px',
          position: 'relative',
          zIndex: 1,
          borderLeft: 'none',
          borderRight: 'none',
          display: 'flex',
          flexWrap: 'nowrap',
        }}
      >
        {!hasFullWidthItem && (
          <DroppableSideRowItem borderColor={borderColor} row={row} position="left" />
        )}
        <RowItemsRenderer
          gridRow={row}
          hasFullWidthItem={hasFullWidthItem}
          rowIndex={index}
          readOnly={readOnly}
        />
        {!hasFullWidthItem && (
          <DroppableSideRowItem borderColor={borderColor} row={row} position="right" />
        )}
      </Grid>
    </Box>
  );
}
