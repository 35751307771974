import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WorkbaseIconByName } from '../icon';

export type DefaultImage =
  | 'WorkbaseDefault1Icon'
  | 'WorkbaseDefault2Icon'
  | 'WorkbaseDefault3Icon'
  | 'WorkbaseDefault4Icon'
  | 'WorkbaseDefault5Icon'
  | 'WorkbaseDefault6Icon';

export interface BrandedDefaultImageProps {
  name: DefaultImage;
  sx?: SxProps<Theme>;
  className?: string;
  color?: string;
  viewBox?: string;
  backgroundColor?: string;
  dataCy?: string;
}

export default function BrandedDefaultImage({
  name,
  sx,
  className,
  viewBox = '222.5 225 555 550',
  color = 'primary.main',
  backgroundColor = 'primary.light',
  dataCy,
}: BrandedDefaultImageProps) {
  return (
    <WorkbaseIconByName
      data-cy={dataCy}
      iconName={name}
      viewBox={viewBox}
      className={className}
      sx={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        '&&&&': {
          color,
          backgroundColor,
        },
        ...sx,
      }}
    />
  );
}
