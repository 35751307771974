import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import WorkbaseTextMaxLine from '../../atoms/text-max-line/TextMaxLine';

interface List {
  id: string;
  title: string;
  text: string;
}

export type TextSectionProps = {
  title?: string;
  list: List[];
  dataCy?: string;
};

function formatText(text: string, id: string) {
  if (text) {
    if (['dateOfBirth', 'hireDate'].includes(id) && text) {
      return dayjs(text).format('DD.MM.YYYY');
    }
    return text;
  }
  return 'N/A';
}

function TextSection({ title, list, dataCy }: TextSectionProps) {
  return (
    <Grid container spacing={2} sx={{ maxWidth: '100%' }}>
      {title && (
        <Grid item data-cy={dataCy}>
          <WorkbaseTextMaxLine
            data-cy={`${title}`}
            color="text.primary"
            variant="h6"
            line={1}
            sx={{ '&&': { fontSize: '18px', lineHeight: '1.2' } }}
          >
            {title}
          </WorkbaseTextMaxLine>
        </Grid>
      )}
      {list.map((item) => (
        <Grid item key={item.id}>
          <WorkbaseTextMaxLine
            data-cy={`${item.title}`}
            color="grey[500]"
            line={1}
            variant="body1"
            sx={{ lineHeight: '1.167' }}
          >
            {item.title}
          </WorkbaseTextMaxLine>
          <WorkbaseTextMaxLine
            color="text.secondary"
            fontWeight="bold"
            line={1}
            sx={{ lineHeight: '1.2' }}
          >
            {formatText(item.text, item.id)}
          </WorkbaseTextMaxLine>
        </Grid>
      ))}
    </Grid>
  );
}

export default TextSection;
