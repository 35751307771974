import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface EditViewProtectedWrapperProps {
  children: React.ReactNode;
  canEdit?: boolean;
  canView?: boolean;
}

const EditViewProtectedWrapper: React.FC<EditViewProtectedWrapperProps> = ({
  children,
  canEdit = false,
  canView = true,
}) => {
  const location = useLocation();
  const isEditPath = location.pathname.endsWith('/edit');
  let element = children;
  if (!canView) {
    element = <Navigate to="/" replace />;
  } else if (!canEdit && canView && isEditPath) {
    element = <Navigate to={location.pathname.replace('/edit', '')} replace />;
  }
  return element;
};

export default EditViewProtectedWrapper;
