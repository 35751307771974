import { Box, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SelectWithSearch from 'src/lib/components/molecules/select-with-search';
import { setSelectedRole } from '../controller';
import { Role } from '../controller/api';

interface Props {
  roles: Role[];
  selectedRole: string;
}

export default function RoleSelector({ selectedRole, roles }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const options = useMemo(
    () =>
      (roles || []).map((role) => ({
        value: role.id,
        label: `${role.department.name} - ${role.name}`,
      })),
    [roles]
  );

  const handleChange = (selectedItem: string) => {
    dispatch(setSelectedRole(selectedItem));
  };

  if (options.length <= 1) return null;

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography
        sx={{
          color: 'text.primary',
          fontSize: '15px',
          fontWeight: 500,
          lineHeight: 1.2,
        }}
      >
        {t('dashboardPage.header.roleSelectorQuestion')}
      </Typography>
      <Box sx={{ minWidth: 300 }}>
        <SelectWithSearch options={options} value={selectedRole} onChange={handleChange} />
      </Box>
    </Stack>
  );
}
