import { Box, CircularProgress, Container, Grid, Stack } from '@mui/material';
import { useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
import useScrollOnDrag from 'src/lib/hooks/use-scroll-on-drag';
import DepartmentsList from './departments/DepartmentsList';
import { filterDepartmentsBySearchVal } from './departments-search/FilterDepartmentsBySearchVal';
import { addDepartment } from '../controller/departments.slice';
import SaveCancelButtons from './actions/saveCancelButtons/SaveCancelButtons';
import PermissionsSideBar from './permissions-sidebar/PermissionsSideBar';
import { useDepartmentsQuery } from '../controller/departmentsApi';
import DepartmentsHeader from './DepartmentsHeader';

export default function Departments() {
  const [searchVal, setSearchVal] = useState<string>('');
  const inputDepartmentRef = useRef<HTMLInputElement | null>(null);
  const departmentScrollRef = useScrollOnDrag();
  const { isLoading } = useDepartmentsQuery();
  const dispatch = useAppDispatch();
  const { departmentsDraftState } = useAppSelector((state) => state.departments);

  const filteredDepartments = filterDepartmentsBySearchVal(departmentsDraftState, searchVal);

  const handleAddNewDepartment = () => {
    dispatch(addDepartment());
    requestAnimationFrame(() => {
      if (inputDepartmentRef.current) {
        inputDepartmentRef.current.scrollIntoView({
          behavior: 'instant',
          block: 'start',
        });
        inputDepartmentRef.current.focus();
      }
    });
  };

  if (isLoading) {
    return (
      <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Stack height="100%">
      <Container maxWidth="lg" component={Box} maxHeight="100%">
        <Stack width="100%" height="100%" gap={2.5} pb={2}>
          <DepartmentsHeader
            handleAddNewDepartment={handleAddNewDepartment}
            setSearchVal={setSearchVal}
            filteredDepartments={filteredDepartments}
            searchVal={searchVal}
          />
          <Grid container spacing={1} minHeight={0} direction="row">
            <Grid item xs={8} maxHeight="100%" overflow="auto" pb={0.25}>
              {filteredDepartments && (
                <DepartmentsList
                  scrollRef={departmentScrollRef}
                  departments={filteredDepartments}
                  inputDepartmentRef={inputDepartmentRef}
                  onAddDepartment={handleAddNewDepartment}
                />
              )}
            </Grid>
            <Grid item xs={4} maxHeight="100%">
              <PermissionsSideBar />
            </Grid>
          </Grid>
        </Stack>
      </Container>
      <SaveCancelButtons />
    </Stack>
  );
}
