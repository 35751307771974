import { CircularProgress, Stack } from '@mui/material';
import { ChangeEvent, ReactNode, useState } from 'react';
import { WorkbaseAddSquareIcon } from 'src/assets/icons/workbaseIcons';
import { useTranslation } from 'react-i18next';
import ImageEditorWithPreview from './ImageEditorWithPreview';
import ImageEditorWithoutPreview from './ImageEditorWithoutPreview';
import Empty from '../empty/Empty';

export interface EditableImageUploadProps {
  src?: string;
  placeholder?: ReactNode;
  altText?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  onClear: () => Promise<void>;
  dataCy?: string;
}

export default function EditableImageUpload({
  src,
  altText = '',
  onChange,
  placeholder,
  onClear,
  dataCy,
}: EditableImageUploadProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const defaultImage = placeholder || (
    <Empty
      svgIconProps={{ fontSize: 'small' }}
      icon={WorkbaseAddSquareIcon}
      description={t('Upload')}
      typographyProps={{
        variant: 'caption',
      }}
    />
  );

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    await onChange(e);
    setLoading(false);
  };

  const handleClear = async () => {
    setLoading(true);
    await onClear();
    setLoading(false);
  };
  const content = {
    loading: <CircularProgress />,
    withImage: (
      <>
        <img
          data-cy={dataCy && `${dataCy}-image`}
          src={src}
          alt={altText}
          height="100%"
          width="100%"
          style={{ objectFit: 'cover' }}
        />
        <ImageEditorWithPreview onImageChange={handleChange} onImageRemove={handleClear} />
      </>
    ),
    withoutImage: (
      <>
        {defaultImage}
        <ImageEditorWithoutPreview onImageChange={onChange} />
      </>
    ),
  };

  const renderContent = () => {
    if (loading) {
      return content.loading;
    }
    return src ? content.withImage : content.withoutImage;
  };

  return (
    <Stack
      borderRadius={1}
      justifyContent="center"
      alignItems="center"
      bgcolor="background.default"
      width="100%"
      height="100%"
      position="relative"
      overflow="hidden"
    >
      {renderContent()}
    </Stack>
  );
}
