import { createBrowserRouter } from 'react-router-dom';
import UnauthorizedView from '../views/unauthorized/Unauthorized';
import SiteInfoProvider from '../providers/SiteInfoProvider';
import AuthProvider from '../features/auth/AuthProvider';
import AuthorizedRoutes from './AuthorizedRoutes';

const guestRoutes = [
  {
    path: '/unauthorized',
    element: <UnauthorizedView />,
  },
];

const authRoutes = [
  {
    path: '/*',
    element: <AuthorizedRoutes />,
  },
];
const authGuardedRoutes = authRoutes.map((route) => ({
  ...route,
  element: (
    <AuthProvider>
      <SiteInfoProvider>{route.element}</SiteInfoProvider>
    </AuthProvider>
  ),
}));

const guestGuardedRoutes = guestRoutes.map((route) => ({
  ...route,
  element: route.element,
}));

export const router = createBrowserRouter([...guestGuardedRoutes, ...authGuardedRoutes]);
