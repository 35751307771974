import { Box, LinearProgress, LinearProgressProps, Typography } from '@mui/material';

export const WorkbaseLinearProgress = LinearProgress;

export interface WorkbaseLinearProgressWithLabelProps extends LinearProgressProps {
  label?: string;
}

export function WorkbaseLinearProgressWithLabel({
  label,
  value,
  ...props
}: WorkbaseLinearProgressWithLabelProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress value={value} {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body1" color="text.secondary">
          {value ? `${Math.round(value)}%` : ''}
        </Typography>
      </Box>
    </Box>
  );
}

WorkbaseLinearProgressWithLabel.defaultProps = {
  label: undefined,
};
