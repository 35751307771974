import { Stack, TextField } from '@mui/material';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { ChangeEvent, useEffect, useState } from 'react';
import checkIfCountryCode from 'src/lib/utils/checkIfCountryCode';
import validateEmail from 'src/lib/utils/validateEmail';
import { stringValidation } from 'src/lib/utils/validateUserData';
import { useTranslation } from 'react-i18next';
import { RecommendedEmployeeData } from '../types';

interface BlurredFields {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  phoneNumber: boolean;
}

export default function RecommendationForm({
  setValidForm,
  data,
  onChange,
  dataCy,
}: {
  setValidForm: (value: boolean) => void;
  data: RecommendedEmployeeData;
  onChange: (value: Partial<RecommendedEmployeeData>) => void;
  dataCy?: string;
}) {
  const { t } = useTranslation();
  const [blurred, setBlurred] = useState<BlurredFields>({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
  });

  const handleBlur = (field: keyof BlurredFields) => {
    setBlurred((prev) => ({ ...prev, [field]: true }));
  };

  const handleChange =
    (field: keyof RecommendedEmployeeData) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange({ ...data, [field]: e.target.value });
    };

  const { hasError: hasFirstNameError, errorMessage: firstNameErrorMessage } = stringValidation(
    data.firstName,
    t
  );
  const { hasError: hasLastNameError, errorMessage: lastNameErrorMessage } = stringValidation(
    data.lastName,
    t
  );

  const isFirstNameValid = !hasFirstNameError;
  const isLastNameValid = !hasLastNameError;
  const isEmailValid = validateEmail(data.email);

  const showErrorEmailMessage = blurred.email && !isEmailValid;
  const showFirstNameErrorMessage = blurred.firstName && !isFirstNameValid;
  const showLastNameErrorMessage = blurred.lastName && !isLastNameValid;

  const showPhoneNumberErrorMessage =
    blurred.phoneNumber && data.phoneNumber !== '' && !isValidPhoneNumber(data.phoneNumber);

  useEffect(() => {
    onChange({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      extraInfo: '',
    });
    setBlurred({
      firstName: false,
      lastName: false,
      email: false,
      phoneNumber: false,
    });
    // trigger only on re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValidForm(
      isFirstNameValid && isLastNameValid && isEmailValid && !showPhoneNumberErrorMessage
    );
  }, [isFirstNameValid, isLastNameValid, isEmailValid, showPhoneNumberErrorMessage, setValidForm]);

  const phoneErrorMsg =
    t('validationErrorMessages.phoneErrorMsg') +
    (checkIfCountryCode(data.phoneNumber)
      ? ''
      : ` ${t('validationErrorMessages.missingCountryCode')}`);

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={2.5}>
        <TextField
          autoFocus
          size="small"
          data-cy={dataCy && `${dataCy}-firstName-field`}
          fullWidth
          label={t('generalSettings.information.profileInformation.firstName')}
          value={data.firstName}
          onChange={handleChange('firstName')}
          onBlur={() => handleBlur('firstName')}
          required
          error={showFirstNameErrorMessage}
          helperText={showFirstNameErrorMessage && firstNameErrorMessage}
        />
        <TextField
          size="small"
          fullWidth
          data-cy={dataCy && `${dataCy}-lastName-field`}
          label={t('generalSettings.information.profileInformation.familyName')}
          value={data.lastName}
          onChange={handleChange('lastName')}
          required
          onBlur={() => handleBlur('lastName')}
          error={showLastNameErrorMessage}
          helperText={showLastNameErrorMessage && lastNameErrorMessage}
        />
      </Stack>
      <Stack gap={2}>
        <TextField
          size="small"
          fullWidth
          data-cy={dataCy && `${dataCy}-email-field`}
          label={t('employeePage.employeeDetails.contactInfomation.emailAddressLabel')}
          value={data.email}
          required
          onBlur={() => handleBlur('email')}
          error={showErrorEmailMessage}
          helperText={
            showErrorEmailMessage && t('companySettings.employeeRecommendations.email.error')
          }
          onChange={handleChange('email')}
        />
        <TextField
          size="small"
          fullWidth
          data-cy={dataCy && `${dataCy}-phone-field`}
          label={t('generalSettings.information.profileInformation.contactNumber')}
          value={data.phoneNumber}
          onBlur={() => handleBlur('phoneNumber')}
          error={showPhoneNumberErrorMessage}
          helperText={showPhoneNumberErrorMessage && phoneErrorMsg}
          onChange={handleChange('phoneNumber')}
        />
        <TextField
          size="small"
          fullWidth
          data-cy={dataCy && `${dataCy}-extra-info-field`}
          label={t('recommendationPopup.extraInfo')}
          value={data.extraInfo}
          onChange={handleChange('extraInfo')}
        />
      </Stack>
    </Stack>
  );
}
