import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';

interface UserPermissionsType {
  id: string;
  code: string;
}

export const userPermissionsApi = createApi({
  reducerPath: 'userPermissionsApi',
  tagTypes: ['UserPermissions'],
  baseQuery,
  endpoints: (builder) => ({
    userPermissions: builder.query<UserPermissionsType[], void>({
      query: () => '/companies/users/me/permissions',
      providesTags: ['UserPermissions'],
    }),
  }),
});

export const { useUserPermissionsQuery } = userPermissionsApi;
