import { IconName } from '../../../atoms/icon';
import CardContent, { CardContentProps } from '../common/CardContent';
import CardIcon from '../common/CardIcon';
import CardWrapper from '../common/CardWrapper';

export interface IconProgressCardProps extends CardContentProps {
  icon: IconName;
  dataCy?: string;
}

export default function IconProgressCard({
  icon,
  title,
  prefix,
  description,
  progress,
  onClick,
  dataCy,
}: IconProgressCardProps) {
  return (
    <CardWrapper>
      <CardIcon icon={icon} onClick={onClick} data-cy={dataCy} />
      <CardContent
        data-cy={dataCy}
        title={title}
        prefix={prefix}
        description={description}
        progress={progress}
        onClick={onClick}
      />
    </CardWrapper>
  );
}
