import { useRef, useState } from 'react';
import { DropzoneInputProps } from 'react-dropzone';
import { Box, MenuItem, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import {
  WorkbaseImageNewIcon,
  WorkbaseImageRemoveIcon,
  WorkbaseDeleteSquareIcon,
} from '../../../../assets/icons/workbaseIcons';
import { WorkbaseIcon } from '../../atoms/icon/WorkbaseIcon';
import { StyledMenu } from '../../atoms/action-menu/ActionMenu';
import { actionIconBoxStyles, actionIconStyles } from './styles';

interface Props {
  imageUrl?: string;
  onRemoveFavicon?: () => void;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T | null;
  onFaviconChange?: (e: any) => void;
}
export default function FaviconEditor({
  imageUrl,
  onRemoveFavicon,
  getInputProps,
  onFaviconChange,
}: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditFaviconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const hiddenInput = (
    <input
      {...getInputProps()}
      ref={fileInputRef}
      type="file"
      accept=".jpg, .jpeg, .jfif, .pjpeg, .pjp, .gif, .apng, .png, .avif, .svg, .webp"
      onChange={(e: any) => {
        onFaviconChange?.(e.target.files?.[0]);
        handleMenuClose();
      }}
    />
  );

  return imageUrl ? (
    <>
      <Box onClick={handleMenuOpen} sx={actionIconBoxStyles}>
        <WorkbaseIcon
          icon={WorkbaseImageRemoveIcon}
          color={actionIconStyles.color}
          sx={actionIconStyles}
        />
      </Box>
      <StyledMenu
        open={open}
        onClose={handleMenuClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={handleEditFaviconClick}
          sx={{ '&&&.MuiMenuItem-root': { marginBottom: '2px' } }}
        >
          <Stack flexDirection="row" gap={1}>
            <WorkbaseIcon icon={WorkbaseImageNewIcon} color="text.secondary" />
            <Typography variant="body1" fontWeight="bold">
              {t('employeePage.employeeDetails.contactInfomation.changeAvatar')}
            </Typography>
            {hiddenInput}
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onRemoveFavicon?.();
            handleMenuClose();
          }}
        >
          <Stack flexDirection="row" gap={1}>
            <WorkbaseIcon icon={WorkbaseDeleteSquareIcon} color="text.secondary" />
            <Typography variant="body1" fontWeight="bold">
              {t('employeePage.employeeDetails.contactInfomation.removeAvatar')}
            </Typography>
          </Stack>
        </MenuItem>
      </StyledMenu>
    </>
  ) : (
    <Stack sx={actionIconBoxStyles} flexDirection="row" gap={1} onClick={handleEditFaviconClick}>
      <WorkbaseIcon
        icon={WorkbaseImageNewIcon}
        color={actionIconStyles.color}
        sx={actionIconStyles}
      />
      {hiddenInput}
    </Stack>
  );
}
