import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import EditorSlice from 'src/features/editor/controller/Editor.slice';
import AwarenessSlice from 'src/features/awareness-avatars/controller/awareness.slice';
import { persistStore } from 'redux-persist';
import { resetPasswordApi, resendInvitationApi } from 'src/features/employees/controller';
import { dashboardReducer } from 'src/features/dashboard/controller';
import { authApi } from 'src/features/auth/auth.api';
import { departmentsApi } from 'src/features/company-settings/departments/controller/departmentsApi';
import { permissionsApi } from 'src/features/company-settings/departments/controller/permissionsApi';
import authReducer from 'src/features/auth/auth.slice';
import { departmentsSlice } from 'src/features/company-settings/departments/controller/departments.slice';
import employeeSlice from 'src/features/employees/controller/employee.slice';
import { apiKeysApi } from 'src/store/api/apiKeysApi';
import searchHistoryReducer from 'src/features/global-search/controller/search.slice';
import wikiCards from 'src/features/wiki/controller/wikiCards.slice';
import { wikiApi } from './api/wikiApi.slice';
import { reqresApi } from './api/reqres-api';
import { dashboardApi } from '../features/dashboard/controller/api';
import { trainingsApi } from './api/trainings/trainingsApi';
import { settingsApi } from './api/settingsApi';
import { rolesApi } from './api/roles.api';
import { trainingRolesApi } from './api/trainingRolesApi';
import { trainingModulesApi } from './api/trainings/trainingModulesApi';
import { trainingSectionsApi } from './api/trainings/trainingSectionsApi';
import { trainingLessonsApi } from './api/trainings/trainingLessonsApi';
import { searchResourceApi } from './api/searchResourceApi';
import { shareResourceApi } from './api/shareResourceApi';
import { searchApi } from './api/searchApi';
import { userPermissionsApi } from './api/userPermissionsApi';
import { videoProgressApi } from './api/trainings/videoProgressApi';
import { videoRequiredApi } from './api/trainings/videoRequiredApi';
import { whiteboardsApi } from './api/whiteboardsApi';
import { employeesApi } from './api/employees/employeesApi';
import { pagesApi } from './api/pagesApi';
import { embeddedAppsApi } from './api/embeddedAppsApi';
import { newsfeedApi } from './api/newsfeedApi';
import { modulesApi } from './api/modulesApi';
import { currentUserApi } from './api/currentUserApi';
import { iframeApi } from './api/iframeApi';
import { sidebarApi } from './api/sidebarApi';
import themeSettingsReducer from './client/themeSettings.slice';

export const store = configureStore({
  reducer: {
    editor: EditorSlice.reducer,
    awareness: AwarenessSlice.reducer,
    auth: authReducer,
    dashboard: dashboardReducer,
    departments: departmentsSlice.reducer,
    employee: employeeSlice.reducer,
    searchHistory: searchHistoryReducer,
    wikiCards: wikiCards.reducer,
    themeSettings: themeSettingsReducer,
    [wikiApi.reducerPath]: wikiApi.reducer,
    [apiKeysApi.reducerPath]: apiKeysApi.reducer,
    [reqresApi.reducerPath]: reqresApi.reducer,
    [sidebarApi.reducerPath]: sidebarApi.reducer,
    [currentUserApi.reducerPath]: currentUserApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [trainingsApi.reducerPath]: trainingsApi.reducer,
    [employeesApi.reducerPath]: employeesApi.reducer,
    [iframeApi.reducerPath]: iframeApi.reducer,
    [embeddedAppsApi.reducerPath]: embeddedAppsApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [departmentsApi.reducerPath]: departmentsApi.reducer,
    [permissionsApi.reducerPath]: permissionsApi.reducer,
    [trainingModulesApi.reducerPath]: trainingModulesApi.reducer,
    [resetPasswordApi.reducerPath]: resetPasswordApi.reducer,
    [resendInvitationApi.reducerPath]: resendInvitationApi.reducer,
    [trainingRolesApi.reducerPath]: trainingRolesApi.reducer,
    [trainingSectionsApi.reducerPath]: trainingSectionsApi.reducer,
    [trainingLessonsApi.reducerPath]: trainingLessonsApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [searchResourceApi.reducerPath]: searchResourceApi.reducer,
    [shareResourceApi.reducerPath]: shareResourceApi.reducer,
    [userPermissionsApi.reducerPath]: userPermissionsApi.reducer,
    [modulesApi.reducerPath]: modulesApi.reducer,
    [videoProgressApi.reducerPath]: videoProgressApi.reducer,
    [videoRequiredApi.reducerPath]: videoRequiredApi.reducer,
    [whiteboardsApi.reducerPath]: whiteboardsApi.reducer,
    [pagesApi.reducerPath]: pagesApi.reducer,
    [newsfeedApi.reducerPath]: newsfeedApi.reducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ serializableCheck: false }).concat(wikiApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(apiKeysApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(reqresApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(currentUserApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(dashboardApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(trainingsApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(employeesApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(iframeApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(embeddedAppsApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(settingsApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(rolesApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(authApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(departmentsApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(permissionsApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(trainingModulesApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(resetPasswordApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(resendInvitationApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(trainingRolesApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(trainingSectionsApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(trainingLessonsApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(searchApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(searchResourceApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(shareResourceApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(userPermissionsApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(modulesApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(videoProgressApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(videoRequiredApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(whiteboardsApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(pagesApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(newsfeedApi.middleware),
    ...getDefaultMiddleware({ serializableCheck: false }).concat(sidebarApi.middleware),
  ],
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
