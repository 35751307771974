import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './api';

export interface SidebarItem {
  id: string;
  name: string;
  icon: string;
  resourceType: ResourceType;
  position: string;
  parentId: number | null;
}

interface PatchSidebarPayload {
  id: string;
  parentId: string | null;
  position: string;
}

export type ResourceType = 'page' | 'embeddedApp' | 'whiteboard';

export type SidebarItemsGroup = 'sidebarItems';

export type SidebarGroups = Record<SidebarItemsGroup, SidebarItem[]>;

export const sidebarApi = createApi({
  reducerPath: 'sidebarApi',
  tagTypes: ['Sidebar'],
  baseQuery,
  endpoints: (builder) => ({
    sidebar: builder.query<SidebarGroups, void>({
      query: () => ({
        url: '/sidebar',
      }),
      providesTags: ['Sidebar'],
    }),
    patchSidebar: builder.mutation<void, PatchSidebarPayload>({
      query: ({ id, position, parentId }) => ({
        url: `/sidebar/${id}`,
        method: 'PATCH',
        body: {
          parentId,
          position,
        },
      }),
      invalidatesTags: ['Sidebar'],
    }),
  }),
});

export const { useSidebarQuery, usePatchSidebarMutation } = sidebarApi;
