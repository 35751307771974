import { MenuItem, Select, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePatchSettingsMutation } from 'src/store/api/settingsApi';

export default function LanguageSetting({ defaultLanguage }: { defaultLanguage: string }) {
  const { t } = useTranslation();
  const [patchSettings] = usePatchSettingsMutation();
  const [isOpen, setOpen] = useState(false);
  const languages = [
    { value: 'en', label: t('generalSettings.information.languageInformation.englishOption') },
    { value: 'de', label: t('generalSettings.information.languageInformation.germanOption') },
  ];
  const handleChange = (event: any) => {
    const selectedValue = event.target.value;
    const selectedLanguage = languages.find((lang) => lang.value === selectedValue);
    if (selectedLanguage && defaultLanguage !== selectedLanguage.value) {
      patchSettings({ defaultLanguage: selectedLanguage.value });
    }
  };

  return (
    <Stack gap={1.25}>
      <Typography variant="subtitle1" fontWeight="medium">
        {t('companySettings.siteInformation.languageSelector')}
      </Typography>
      <Select
        size="small"
        value={defaultLanguage}
        onChange={handleChange}
        onOpen={() => setOpen(!isOpen)}
        onClose={() => setOpen(false)}
      >
        {languages.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
}
