import { t } from 'i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Employee } from 'src/features/employees/model';
import { ImageProgressCard } from 'src/lib/components/molecules/progress-card';
import WorkbaseCollapsible from 'src/lib/components/molecules/сollapsible/Collapsible';
import { throttledShowNotification } from 'src/lib/components/atoms/notification';
import { useTrainingSectionsByModuleAndUserIdQuery } from 'src/store/api/trainings/trainingSectionsApi';
import {
  TrainingModuleWithStatus,
  useTrainingModulesProgressByTrainingAndUserIdQuery,
} from 'src/store/api/trainings/trainingModulesApi';
import { useAppSelector } from 'src/store';
import { TrainingLesson } from 'src/store/api/trainings/trainingLessonsApi';
import normalizeString from 'src/lib/utils/normalizeString';
import SectionList from '../sections/SectionList';

interface Props {
  module: TrainingModuleWithStatus;
  trainingId: string;
  employee: Employee;
  searchVal: string;
  blockAccess?: boolean;
}

export default function SingleModule({
  module,
  trainingId,
  employee,
  searchVal,
  blockAccess = false,
}: Props) {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const { data: modulesData } = useTrainingModulesProgressByTrainingAndUserIdQuery({
    trainingId,
    userId: employee.id,
  });
  const lessonQueries = useAppSelector((state) => state.trainingLessonsApi.queries);
  const { data: modulesSectionData } = useTrainingSectionsByModuleAndUserIdQuery(
    { moduleId: module.id, userId: employee.id },
    {
      skip: !isOpen,
    }
  );
  const lessonQueriesKeys = Object.keys(lessonQueries);

  const filteredLessons = modulesSectionData
    ?.flatMap(({ id }) => {
      const queryKey = lessonQueriesKeys.find((key) => key.includes(id));
      if (queryKey) {
        const query = lessonQueries[queryKey] as { data: TrainingLesson[] } | undefined;
        return query?.data?.filter((lesson: TrainingLesson) => lesson.status === 'published');
      }
      return [];
    })
    ?.filter((lesson) => !!lesson && normalizeString(lesson.name).includes(searchVal || ''));

  const detailsAdditionalModuleStyles = {
    '&&&.MuiAccordionDetails-root': { padding: '0px 20px 0px' },
  };

  const sectionsCountLabel = t('sections', { count: module.sectionsCount || 0 });
  const lessonsCountLabel = t('lesson', { count: module.lessonsCount || 0 });
  const estimatedTimeLabel = t('minute', { count: module.estimatedTimeToComplete || 0 });

  const filteredSections = (modulesSectionData || []).filter(
    ({ name, status }) => normalizeString(name).includes(searchVal || '') && status === 'published'
  );

  const normalizedModuleName = normalizeString(module.name);
  const isSearchMatch = normalizedModuleName.includes(searchVal);

  const hasFilteredContent =
    isOpen &&
    (filteredSections.length > 0 ||
      isSearchMatch ||
      (filteredLessons && filteredLessons?.length > 0));

  const shouldDisplay = isOpen ? hasFilteredContent : isSearchMatch;

  return (
    <WorkbaseCollapsible
      dataCy="employee-single-module-progress"
      defaultExpanded={isOpen}
      onChange={() => {
        setOpen(!isOpen);
      }}
      backgroundColor="common.white"
      title={
        <ImageProgressCard
          dataCy="module-image-progress-card"
          image={module.imageUrl}
          title={module.name || t('employeePage.progress.unnamedModule')}
          prefix={t('employeePage.progress.prefix.module')}
          description={`${sectionsCountLabel} – ${lessonsCountLabel} – ${estimatedTimeLabel}`}
          progress={modulesData?.find(({ id }) => id === module.id)?.progress || 0}
          onClick={() => {
            if (blockAccess) {
              throttledShowNotification(t('employeePage.progress.noAccessModule'), 'error');
            } else {
              navigate(`/trainings/${trainingId}/${module.id}`);
            }
          }}
        />
      }
      sx={{
        '&&& .MuiAccordionSummary-root.MuiButtonBase-root': { padding: '0 20px 0 12px' },
        '&&&& .MuiAccordionSummary-content': { margin: '14px 16px 14px 0' },
        display: shouldDisplay ? 'block' : 'none',
      }}
      detailsAdditionalStyles={detailsAdditionalModuleStyles}
    >
      <SectionList
        blockAccess={blockAccess}
        modulesSectionData={modulesSectionData || []}
        employee={employee}
        searchVal={searchVal}
        trainingId={trainingId}
      />
    </WorkbaseCollapsible>
  );
}
