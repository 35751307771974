import { Box, Container, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/store';
import { EmployeeProgress } from 'src/store/api/employees/employeesApi';
import TopBarLayout from 'src/ui-components/layout/top-bar/TopBarLayout';
import Header from './header/Header';
import { Employee } from '../../model';
import TabButtons from './header/TabButtons';
import EmployeeDetailsTopBar from './EmployeeDetailsTopBar';
import EmployeeInfo from './contact-info/EmployeeInfo';
import { clearEmployee, setEmployee, updateEmployeeDetails } from '../../controller/employee.slice';
import Progress from './header/Progress';
import TrainingProgress from './training-progress/TrainingProgress';

type Props = {
  employee?: Employee;
  readOnly: boolean;
  employeesRefetch: () => void;
  hasManageEmployeePermission: boolean;
  hasViewProgressPermission: boolean;
  progressData?: EmployeeProgress[];
};

type ButtonTabValue = 'information' | 'roles' | 'progress';

export default function EmployeeDetails({
  employee,
  readOnly,
  employeesRefetch,
  hasManageEmployeePermission,
  hasViewProgressPermission,
  progressData,
}: Props) {
  const { t } = useTranslation();
  const employeeProgress = progressData?.find((prog) => employee && prog.id === employee.id);
  const progressValue = employeeProgress ? Number(employeeProgress.progress) : 0;

  const tabNames: { key: ButtonTabValue; text: string; disabled: boolean }[] = [
    {
      key: 'information',
      text: t('employeePage.employeeDetails.tabs.informationLabel'),
      disabled: false,
    },
    {
      key: 'progress',
      text: t('employeePage.employeeDetails.tabs.progressLabel'),
      disabled: !hasViewProgressPermission,
    },
  ];

  const employeeDraft = useAppSelector((state) => state.employee.employee);
  const dispatch = useDispatch();

  useEffect(() => {
    if (employee) {
      // Fetch employee data from the server based on the employee ID
      dispatch(setEmployee(employee));
    }

    // Clean up when component unmounts
    return () => {
      dispatch(clearEmployee());
    };
  }, [dispatch, employee]);

  const handleUpdateEmployee = (updatedEmployeeDetails: Partial<Employee>) => {
    dispatch(updateEmployeeDetails(updatedEmployeeDetails));
  };

  const [activeTabButton, setActiveTabButton] = useState<ButtonTabValue>(tabNames[0].key);
  const handleTabButtonClick = (value: string) => {
    setActiveTabButton(value as ButtonTabValue);
  };

  if (!employee) return null;
  if (!employeeDraft) return null;

  return (
    <TopBarLayout
      topBar={
        <EmployeeDetailsTopBar
          employee={employee}
          employeesRefetch={employeesRefetch}
          hasManageEmployeePermission={hasManageEmployeePermission}
        />
      }
    >
      <Stack
        pt={2.5}
        px={4}
        sx={{
          display: 'flex',
          backgroundColor: 'tertiary.main',
          borderRadius: (theme) =>
            `${theme.shape.borderRadius * 4} ${theme.shape.borderRadius * 4} 0px 0px`,
        }}
      >
        <Box
          sx={{
            maxWidth: ({ breakpoints }) => breakpoints.values.lg,
            width: '100%',
            alignSelf: 'center',
          }}
        >
          <Header
            employee={employeeDraft}
            readOnly={readOnly}
            handleUpdateEmployee={handleUpdateEmployee}
          />
          <Progress
            value={progressValue}
            status={employeeDraft?.status}
            haveAccess={hasManageEmployeePermission || hasViewProgressPermission}
          />
        </Box>
      </Stack>
      <TabButtons
        buttons={tabNames}
        activeButton={activeTabButton}
        onClick={handleTabButtonClick}
      />
      <Container maxWidth="lg">
        {activeTabButton === 'information' && (
          <EmployeeInfo
            employee={employee}
            employeeDraft={employeeDraft}
            readOnly={readOnly}
            handleUpdateEmployee={handleUpdateEmployee}
          />
        )}
        {activeTabButton === 'progress' && <TrainingProgress employee={employee} />}
      </Container>
    </TopBarLayout>
  );
}
