import { Box, InputAdornment, Stack, Typography } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { WorkbaseIcon, WorkbaseIconByName } from 'src/lib/components/atoms/icon';
import {
  TrainingLesson,
  useDeleteTrainingLessonMutation,
  useDuplicateTrainingLessonMutation,
  useMoveTrainingLessonToNewPositionMutation,
  usePatchTrainingLessonMutation,
} from 'src/store/api/trainings/trainingLessonsApi';
import DraftModeMenu from 'src/core/DraftModeMenu';
import { showNotification } from 'src/lib/components/atoms/notification';
import { t } from 'i18next';
import { imageUpload } from 'src/lib/utils/imageUpload';
import { useNavigate, useParams } from 'react-router-dom';
import useVerticalOrderedDnD from 'src/lib/hooks/useVerticalOrderedDnD';
import debounce from 'lodash.debounce';
import DndButton from 'src/lib/components/atoms/dnd-button/DndButton';
import DndPreview from 'src/lib/components/util/DndPreviewWrapper';
import { WorkbaseTextField } from 'src/lib/components/atoms/text-field';
import { WorkbaseClockIcon } from 'src/assets/icons/workbaseIcons';
import EditableImageUpload from 'src/ui-components/custom/image-edit/EditableImageUpload';
import BrandedDefaultImage from 'src/lib/components/atoms/branded-default-image/BrandedDefaultImage';
import DndIndicator from 'src/ui-components/custom/dnd-indicator/DndIndicator';
import DeleteDuplicateMenu from 'src/ui-components/custom/delete-duplicate-menu';
import useScrollIntoView from './useScrollIntoView';

const draggingItemType = 'editing-lesson-item';
// eslint-disable-next-line max-statements
export default function EditLesson({
  lesson,
  sectionId,
  mobileView,
  withDragLayer = true,
  filteredLessons,
  index = 0,
}: Readonly<{
  lesson: TrainingLesson;
  sectionId: string;
  mobileView: boolean;
  withDragLayer?: boolean;
  index?: number;
  filteredLessons: TrainingLesson[];
}>) {
  const [localLessonName, setLocalLessonName] = useState(lesson.name);
  const [patchTrainingLesson] = usePatchTrainingLessonMutation();
  const [moveTrainingLessonToNewPosition] = useMoveTrainingLessonToNewPositionMutation();
  const [isInteractingWithInput, setIsInteractingWithInput] = useState(false);
  const [duplicateTrainingLesson] = useDuplicateTrainingLessonMutation();
  const [deleteTrainingLesson] = useDeleteTrainingLessonMutation();
  const params = useParams<{ trainingId: string; moduleId: string; lessonId?: string }>();
  const isLessonActive = params.lessonId === lesson.id;
  const navigate = useNavigate();
  const estimatedTimeLabel = t('minute', {
    count: lesson.estimatedTimeToComplete ? lesson.estimatedTimeToComplete : 0,
  });
  const draggingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setLocalLessonName(lesson.name);
  }, [lesson.name]);

  useScrollIntoView(lesson.id, draggingRef);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLessonTitleChange = useCallback(
    debounce((val: string) => {
      if (val !== lesson?.name) {
        patchTrainingLesson({
          name: val,
          id: lesson.id,
          trainingSectionId: lesson.trainingSectionId,
          trainingId: params.trainingId as string,
          moduleId: params.moduleId as string,
        });
      }
    }, 1000),
    [sectionId, lesson, patchTrainingLesson, params.trainingId]
  );

  const onImgChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files?.[0];

    if (!file) return;
    try {
      const response = await imageUpload(file);
      await patchTrainingLesson({
        id: lesson.id,
        trainingSectionId: lesson.trainingSectionId,
        imageId: response.data.result.id,
        imageUrl: response.data.result.variants?.[0],
        trainingId: params.trainingId as string,
        moduleId: params.moduleId as string,
      });
    } catch (err) {
      showNotification(t('validationErrorMessages.UploadImageErrorMessage'), 'error');
    }
  };

  const removeImage = async (): Promise<void> => {
    await patchTrainingLesson({
      id: lesson.id,
      trainingSectionId: lesson.trainingSectionId,
      imageId: null,
      imageUrl: '',
      trainingId: params.trainingId as string,
      moduleId: params.moduleId as string,
    });
  };

  const { isDragging, hoverState, draggingStyles, previewRef } = useVerticalOrderedDnD({
    itemData: { id: lesson.id, index, sectionId: lesson.trainingSectionId },
    moveItem: ({ id: i, index: hoverIndex }) => {
      if (i !== lesson?.id) {
        const isDroppingTheSameSection = !!filteredLessons?.find((les) => les.id === i);
        if (!isDroppingTheSameSection) {
          moveTrainingLessonToNewPosition({
            sectionId,
            idOfMovingLesson: i,
            order: hoverIndex + 1,
          });
        } else {
          const movingItemIdx = filteredLessons?.findIndex((les) => les.id === i);
          moveTrainingLessonToNewPosition({
            sectionId,
            idOfMovingLesson: i,
            order: movingItemIdx < index ? hoverIndex : hoverIndex + 1,
          });
        }
      }
    },
    accept: draggingItemType,
    ref: draggingRef,
  });
  const dndIndicatorProps = hoverState.isOverUpperHalf ? { top: -10 } : { bottom: -10 };

  const draftModeMenu = (
    <DraftModeMenu
      withButton={!mobileView}
      status={lesson.status}
      onStatusChange={(status) => {
        patchTrainingLesson({
          status,
          id: lesson.id,
          trainingSectionId: lesson.trainingSectionId,
          trainingId: params.trainingId as string,
          moduleId: params.moduleId as string,
        });
      }}
    />
  );

  const getInputBoxId = () => {
    if (!withDragLayer) return undefined;
    return lesson.id;
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    const target = event.target as HTMLElement;
    if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {
      setIsInteractingWithInput(true);
    } else {
      setIsInteractingWithInput(false);
    }
  };

  const handleMouseUp = () => {
    setIsInteractingWithInput(false);
  };
  return (
    <Box sx={{ pb: '16px' }} ref={draggingRef}>
      <Stack
        alignItems="center"
        flexDirection="row"
        gap={1}
        justifyContent="space-between"
        onClick={(e) => {
          if (isInteractingWithInput) {
            e.stopPropagation();
          }
          handleMouseUp();
        }}
        sx={{
          padding: '14px 16px 14px 12px',
          backgroundColor: 'common.white',
          border: '1px solid',
          position: 'relative',
          minHeight: 86,
          borderColor: isLessonActive ? 'primary.main' : 'grey.300',
          borderWidth: isLessonActive ? '2px' : '1px',
          borderRadius: (theme) => theme.shape.borderRadius * 2.5,
          '&:hover .dnd-button': {
            opacity: 1,
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: -1,
            left: '50%',
            transform: 'translate(-50%, -50%)',
            pointerEvents: 'none',
          }}
        >
          <DndButton sx={{ opacity: 0 }} />
        </Box>
        {withDragLayer && (
          <DndPreview previewRef={previewRef}>
            <EditLesson
              mobileView={mobileView}
              lesson={lesson}
              withDragLayer={false}
              filteredLessons={filteredLessons}
              sectionId={sectionId}
            />
          </DndPreview>
        )}
        {hoverState.isHovered && <DndIndicator {...dndIndicatorProps} sx={{ right: 0 }} />}
        <Stack
          direction="row"
          gap={1.25}
          alignItems="center"
          sx={{
            flexGrow: 1,
            opacity: isDragging ? draggingStyles.placeholderOpacity : 1,
          }}
          mr={1}
        >
          <Box
            onClick={(e) => {
              e.stopPropagation();
            }}
            sx={{
              borderRadius: (theme: any) => theme.shape.borderRadius,
              overflow: 'hidden',
              width: 96,
              height: 54,
              img: { height: '100%', objectFit: 'cover' },
              flexShrink: 0,
              alignSelf: 'flex-start',
            }}
          >
            <EditableImageUpload
              onChange={onImgChange}
              src={lesson.imageUrl ?? undefined}
              placeholder={<BrandedDefaultImage name={lesson.defaultImage} />}
              onClear={removeImage}
            />
          </Box>
          <Stack
            direction="column"
            sx={{ flexGrow: 1 }}
            gap={mobileView ? 0.5 : 0}
            id={getInputBoxId()}
          >
            <WorkbaseTextField
              onMouseDown={(e) => {
                draggingRef.current?.setAttribute('draggable', 'false');
                handleMouseDown(e);
                e.stopPropagation();
              }}
              onMouseLeave={() => {
                draggingRef.current?.setAttribute('draggable', 'true');
              }}
              value={localLessonName}
              placeholder={t('writeTitlePlaceholder')}
              onChange={(e) => {
                setLocalLessonName(e.target.value);
                debouncedLessonTitleChange(e.target.value);
              }}
              // onMouseUp={handleMouseUp}
              onClick={(e) => {
                e.stopPropagation();
                handleMouseUp();
              }}
              sx={{
                maxWidth: mobileView ? '100%' : '536px',
                minWidth: 0,
                width: '100%',
                pointerEvents: 'all',
                flexShrink: 1,
                '& input': {
                  padding: mobileView ? 0 : '8.5px 20px',
                  fontSize: mobileView ? 16 : 18,
                },
              }}
              size="H4"
              bordered={false}
              backgroundColor={mobileView ? undefined : 'tertiary.main'}
              InputProps={
                mobileView
                  ? undefined
                  : {
                      endAdornment: (
                        <InputAdornment position="end">
                          <WorkbaseIconByName iconName="WorkbaseEditIcon" size={12} />
                        </InputAdornment>
                      ),
                    }
              }
            />
            {mobileView && (
              <Stack flexDirection="row" gap={0.5} alignItems="center">
                <WorkbaseIcon icon={WorkbaseClockIcon} size={14} />
                <Typography fontWeight="medium" variant="body2" color="text.secondary">
                  {estimatedTimeLabel}
                </Typography>
                <Box onClick={(e) => e.stopPropagation()} sx={{ cursor: 'pointer' }}>
                  {draftModeMenu}
                </Box>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack
          direction="row"
          gap={mobileView ? 1.5 : 2}
          sx={{ marginLeft: 'auto' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {!mobileView && draftModeMenu}
          <DeleteDuplicateMenu
            onDelete={() => {
              deleteTrainingLesson({
                trainingSectionId: lesson.trainingSectionId,
                id: lesson.id,
                trainingId: params.trainingId as string,
                moduleId: params.moduleId as string,
              }).then((resp: any) => {
                if (resp.error) {
                  showNotification(t('notifications.deleteError'), 'error');
                } else {
                  if (isLessonActive) {
                    /* eslint-disable max-depth */
                    if (!lesson) {
                      return;
                    }
                    const currentLessonIndex = filteredLessons.findIndex(
                      (less) => less.id === params.lessonId
                    );
                    if (currentLessonIndex === -1) return;
                    const updatedLessons = filteredLessons.filter(
                      (less) => less.id !== params.lessonId
                    );
                    let redirectLessonId = null;

                    if (currentLessonIndex > 0) {
                      redirectLessonId = updatedLessons[currentLessonIndex - 1]?.id;
                    }
                    if (!redirectLessonId && currentLessonIndex < updatedLessons.length) {
                      redirectLessonId = updatedLessons[currentLessonIndex]?.id;
                    }
                    if (redirectLessonId) {
                      navigate(
                        `/trainings/${params.trainingId}/${params.moduleId}/${sectionId}/${redirectLessonId}/edit`
                      );
                    } else {
                      navigate(`/trainings/${params.trainingId}/${params.moduleId}`);
                    }
                  }
                  showNotification(t('notifications.deleteSuccess'));
                }
              });
            }}
            onDuplicate={() => {
              duplicateTrainingLesson({
                lessonId: lesson.id,
                trainingId: params.trainingId as string,
                moduleId: params.moduleId as string,
                trainingSectionId: lesson?.trainingSectionId as string,
              }).then((resp: any) => {
                if (resp.error) {
                  showNotification(t('notifications.duplicateError'), 'error');
                } else {
                  showNotification(t('notifications.duplicateSuccess'));
                }
              });
            }}
            deleteDialogDescription={t('deleteLessonModal.content')}
          />
        </Stack>
      </Stack>
    </Box>
  );
}
