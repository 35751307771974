import { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import VideoItem from 'src/lib/components/organisms/editor/video-item';
import { VideoProps } from 'src/lib/components/organisms/editor/video-item/VideoItem';
import {
  VideoProgress,
  useGetVideoProgressQuery,
  usePostVideoProgressMutation,
} from 'src/store/api/trainings/videoProgressApi';
import { usePatchVideoRequiredMutation } from 'src/store/api/trainings/videoRequiredApi';
import throttle from 'lodash.throttle';
import { DocumentNameContext } from '../DocumentNameContext';

interface Props extends VideoProps {
  videoId: string;
  id: string;
}

export default function VideoWrapper({
  id,
  videoId,
  onDelete,
  onDuplicate,
  readOnly = false,
  onResizeStop,
  onVideoDownload,
  url,
  videoPoster,
  dragging,
  onResizeStart,
  onReplaceVideo,
  onUpdateVideoTitle,
  title,
  height,
  aspectRatio,
  onUpdateVideoPoster,
  onDisableFastForward,
  isFastForwardDisabled = false,
  onRequired,
  isVideoRequired = false,
  isVideoUploaded,
}: Props) {
  const params = useParams<{
    trainingId: string;
    moduleId: string;
    lessonId: string;
    sectionId: string;
  }>();
  const { data: videoProgressData } = useGetVideoProgressQuery(videoId || '', {
    skip: !(params.lessonId || isFastForwardDisabled),
  });
  const documentName = useContext(DocumentNameContext);
  const [postVideoProgress] = usePostVideoProgressMutation();
  const [patchVideoRequired] = usePatchVideoRequiredMutation();

  const handleVideoProgress = useCallback(
    async (data: Partial<VideoProgress>) => {
      await postVideoProgress({ id: videoId || '', data, lessonId: params?.lessonId as string });
    },
    [postVideoProgress, videoId, params]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttleHandleVideoProgress = useCallback(
    throttle((data: Partial<VideoProgress>) => {
      handleVideoProgress(data);
    }, 2000),
    [handleVideoProgress]
  );

  const handleRequiredVideo = async (isRequired: boolean) => {
    onRequired?.(isRequired);
    await patchVideoRequired({
      id: videoId || '',
      isRequired,
      lessonId: params?.lessonId as string,
    });
  };

  return (
    <VideoItem
      id={id}
      title={title}
      url={url}
      videoPoster={videoPoster}
      readOnly={readOnly}
      height={height}
      aspectRatio={aspectRatio}
      onDelete={onDelete}
      onDuplicate={onDuplicate}
      onResizeStop={onResizeStop}
      onVideoDownload={onVideoDownload}
      onReplaceVideo={onReplaceVideo}
      onUpdateVideoTitle={onUpdateVideoTitle}
      onUpdateVideoPoster={onUpdateVideoPoster}
      dragging={dragging}
      onResizeStart={onResizeStart}
      isFastForwardDisabled={isFastForwardDisabled}
      isVideoRequired={isVideoRequired}
      onDisableFastForward={onDisableFastForward}
      onRequired={handleRequiredVideo}
      watchProgress={videoProgressData?.watchedDuration}
      onVideoProgress={throttleHandleVideoProgress}
      documentName={documentName}
      videoId={videoId}
      isVideoUploaded={isVideoUploaded}
    />
  );
}
