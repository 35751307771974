import { Box, Checkbox, FormControlLabel, SxProps, Theme, alpha, styled } from '@mui/material';
import { WorkbaseCheckmarkIcon } from 'src/assets/icons/workbaseIcons';
import { ChangeEvent } from 'react';
import { WorkbaseIcon } from '../icon/WorkbaseIcon';

export interface CheckboxProps {
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  id?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  required?: boolean;
  sx?: SxProps<Theme>;
  value?: boolean;
  indeterminate?: boolean;
  dataCy?: string;
}

function CheckedIcon({ disabled = false }: { disabled?: boolean }) {
  return (
    <Box
      sx={{
        width: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'primary.main',
        border: '1px solid',
        borderColor: 'primary.main',
        borderRadius: (theme) => theme.shape.borderRadius,
        color: disabled ? 'grey.500' : 'common.white',
        transition: 'all .2s ease-in-out',
        cursor: 'not-allowed',
        '&:hover, input:hover ~ & ': {
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.9),
        },
        'input:disabled ~ &': {
          borderColor: 'backgroundHover.main',
          backgroundColor: 'grey.300',
        },
      }}
    >
      <WorkbaseIcon icon={WorkbaseCheckmarkIcon} sx={{ width: 7, height: 7 }} />
    </Box>
  );
}
function UncheckedIcon() {
  return (
    <Box
      sx={{
        width: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        borderRadius: (theme) => theme.shape.borderRadius,
        border: '1px solid',
        borderColor: 'grey.500',
        color: 'transparent',
        transition: 'all .2s ease-in-out',
        '&:hover, input:hover ~ &': { color: 'grey.500' },
        'input:disabled ~ &': {
          borderColor: 'backgroundHover.main',
          backgroundColor: 'grey.300',
          color: 'transparent',
        },
      }}
    >
      <WorkbaseIcon icon={WorkbaseCheckmarkIcon} sx={{ width: 7, height: 7 }} />
    </Box>
  );
}

function IndeterminateIcon() {
  return (
    <Box
      sx={{
        width: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: (theme) => theme.shape.borderRadius,
        border: '1px solid',
        borderColor: 'grey.300',
        transition: 'all .2s ease-in-out',
        '&:hover, input:hover ~ & > div': {
          backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.75),
        },
        'input:disabled ~ &': {
          borderColor: 'backgroundHover.main',
          backgroundColor: 'grey.300',
          color: 'transparent',
        },
      }}
    >
      <Box
        sx={{
          padding: '4px',
          backgroundColor: 'secondary.main',
          width: 8,
          transition: 'all .2s ease-in-out',
          height: 8,
          borderRadius: (theme) => theme.shape.borderRadius * 0.5,
        }}
      />
    </Box>
  );
}

export default function WorkbaseCheckbox({
  checked,
  defaultChecked,
  disabled,
  id,
  onChange,
  required,
  sx,
  value,
  indeterminate = false,
  dataCy,
}: CheckboxProps) {
  return (
    <Checkbox
      data-cy={dataCy}
      checked={checked}
      checkedIcon={<CheckedIcon disabled={disabled} />}
      icon={<UncheckedIcon />}
      indeterminate={indeterminate}
      indeterminateIcon={<IndeterminateIcon />}
      defaultChecked={defaultChecked}
      disabled={disabled}
      id={id}
      onChange={onChange}
      required={required}
      sx={{
        padding: '0 8px',
        '&&&.Mui-disabled': {
          cursor: 'not-allowed',
          pointerEvents: 'inherit',
        },
        ...sx,
      }}
      value={value}
      disableRipple
    />
  );
}

export const WorkbaseFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  minWidth: 0,
  color: theme.palette.text.secondary,
  '.MuiFormControlLabel-label.Mui-disabled': {
    color: alpha(theme.palette.text.secondary!, 0.5),
  },
}));
