import React from 'react';
import { Table, TableProps } from '@mui/material';

interface WorkbaseTableProps extends TableProps {
  children: React.ReactNode;
}

function WorkbaseTable({ children, sx, ...otherProps }: WorkbaseTableProps) {
  return (
    <Table sx={sx} {...otherProps}>
      {children}
    </Table>
  );
}

export default WorkbaseTable;
