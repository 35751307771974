import { Chip, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseDepartmentsIcon } from 'src/assets/icons/workbaseIcons';
import { Employee, EmployeeStatus } from 'src/features/employees/model';
import { WorkbaseIcon } from 'src/lib/components/atoms/icon';

export default function HeaderReadOnly({ employee }: { employee: Employee }) {
  const { t } = useTranslation();

  const employeeStatusDict: Record<
    EmployeeStatus,
    { label: string; color: string; backgroundColor: string }
  > = {
    active: {
      label: t('employeePage.employeeDetails.activeLabelChip'),
      color: 'success.main',
      backgroundColor: 'green.light',
    },
    invited: {
      label: t('employeePage.employeeDetails.invitedLabelChip'),
      color: 'purple.main',
      backgroundColor: 'purple.light',
    },
  };

  const uniqueDepartments =
    Array.from(new Set(employee?.roles.map((role) => role.department.name))) || [];

  return (
    <>
      <Stack direction="row" justifyContent="center" alignItems="center" gap={1.5} maxWidth="100%">
        <Typography variant="h6" color="white.permanent" noWrap data-cy="employee-name">
          {employee?.firstName || employee?.lastName ? `${employee?.firstName}` : employee?.email}
        </Typography>
        <Typography noWrap variant="h6" color="white.permanent" data-cy="employee-lastName">
          {employee?.lastName ? `${employee?.lastName}` : ''}
        </Typography>
        <Chip
          data-cy="employee-status"
          label={employeeStatusDict[employee.status].label}
          sx={{
            borderRadius: (theme) => theme.shape.borderRadius * 2.5,
            height: 23,
            '&.MuiChip-root': {
              backgroundColor: employeeStatusDict[employee.status].backgroundColor,
              color: employeeStatusDict[employee.status].color,
              fontSize: 12,
              lineHeight: 1.167,
              pt: '4px',
              pb: '5px',
              fontWeight: 700,
            },
          }}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap="4.83px"
        sx={{ minWidth: 0, maxWidth: '90%', pr: '6px' }}
      >
        <WorkbaseIcon
          dataCy="workbase-departments-icon"
          icon={WorkbaseDepartmentsIcon}
          color="common.white"
          sx={{ width: '12.24px', height: '14px' }}
        />
        <Typography
          data-cy="employee-unique-departments"
          color="common.white"
          variant="body1"
          fontWeight="medium"
          sx={{
            maxWidth: 'calc(100% - 10px)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textAlign: 'left',
          }}
        >
          {t('employeePage.employeeDetails.departmentLabel')}: {uniqueDepartments.join(', ')}
        </Typography>
      </Stack>
    </>
  );
}
