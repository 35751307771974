import { useDropzone } from 'react-dropzone';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import { Alert, Box, Button, Icon, Snackbar, Stack, Typography } from '@mui/material';
import {
  WorkbaseContentIcon,
  WorkbaseImageDragAndUploadIcon,
  WorkbaseRecordIcon,
  WorkbaseVideoUploadIcon,
} from 'src/assets/icons/workbaseIcons';
import Placeholder from 'src/lib/components/molecules/placeholder/Placeholder';
import { RejectionFiles } from 'src/lib/components/molecules/upload';
import { useTranslation } from 'react-i18next';
import { ImageUploadType, VideoUploadType } from 'src/features/editor/model';
import { imageUpload } from 'src/lib/utils/imageUpload';
import { videoUpload } from 'src/lib/utils/videoUpload';
import { DocumentNameContext } from 'src/features/editor/view/DocumentNameContext';
import { showNotification } from 'src/lib/components/atoms/notification';
import { useDispatch } from 'react-redux';
import {
  addVideoBlobData,
  setRecordingModalPayload,
} from 'src/features/editor/controller/Editor.slice';
import HighlightItem from '../highlight-item';
import { ResizeStopCallbackType } from '../highlight-item/resize/ResizableWrapper';
import { FileType } from '../edit-bar/UploadActions';
import UploadProgress from './UploadProgress';
import { allowedFileTypes, MAX_FILESIZE } from './config';
import useUploadingMedia from '../hooks/useUploadingMedia';

interface Props {
  id: string;
  onDelete: () => void;
  onDuplicate: () => void;
  readOnly?: boolean;
  onResizeStop?: ResizeStopCallbackType;
  onChangeMediaType: (type: FileType) => void;
  onImageUpload: (file: ImageUploadType) => void;
  onVideoUpload: (file: VideoUploadType) => void;
  type: FileType;
  value?: string;
  dragging?: {
    dragRef: ConnectDragSource;
    previewRef: ConnectDragPreview;
  };
  onResizeStart?: () => void;
}

function RenderTitle({ type }: { type: string }) {
  const { t } = useTranslation();

  return (
    <Typography color="text.secondary" fontWeight="medium">
      {t(`uploadItem.${type}.title`)}{' '}
      <Typography color="primary" fontWeight="bold" component="span">
        {t('uploadItem.chooseFileText')}
      </Typography>{' '}
      {t('uploadItem.toUploadText')}
    </Typography>
  );
}

const dummyVideoRatio = 1.777777777777778;

/* eslint-disable-next-line max-statements */
export default function UploadItem({
  id,
  type,
  onDelete,
  onDuplicate,
  readOnly = false,
  onResizeStop,
  onChangeMediaType,
  onImageUpload,
  value,
  dragging,
  onResizeStart,
  onVideoUpload,
}: Props) {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [isEnabled, setIsEnabled] = useState(true);
  const [accept, setAccept] = useState(allowedFileTypes[type]);
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const documentName = useContext(DocumentNameContext);
  const abortControllerRef = useRef<AbortController | null>(null);
  const dispatch = useDispatch();
  const { handleClearUploading, handleAddingUploading } = useUploadingMedia(id, type);

  const placeholderProps = {
    image: {
      icon: WorkbaseImageDragAndUploadIcon,
      title: <RenderTitle type="image" />,
    },
    video: {
      icon: WorkbaseVideoUploadIcon,
      title: <RenderTitle type="video" />,
      additionalContent: (
        <Stack direction="row" justifyContent="center" mt={1.5}>
          <Button
            color="error"
            startIcon={
              <Icon>
                <WorkbaseRecordIcon />
              </Icon>
            }
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                setRecordingModalPayload({
                  id,
                  height: (ref.current?.clientWidth as number) / dummyVideoRatio,
                  onUploadProgress: setUploadPercentage,
                  onLoading: setLoading,
                })
              );
            }}
          >
            {t('recording.recordVideo')}
          </Button>
        </Stack>
      ),
    },
    file: {
      icon: WorkbaseContentIcon,
      title: <RenderTitle type="file" />,
    },
  };

  const snackBarHandler = (
    message: string = t('validationErrorMessages.UploadFileErrorMessage')
  ) => {
    setErrorMessage(message);
    setShowSnackbar(true);
  };

  const handleClose = () => setShowSnackbar(false);

  const resetStates = () => {
    setLoading(false);
    setFile(null);
    setUploadPercentage(0);
    setErrorMessage('');
    handleClearUploading();
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
  };

  const handleImageUpload = async (newFile: File) => {
    setFile(newFile);
    const reader = new FileReader();
    reader.readAsDataURL(newFile);
    try {
      reader.onload = () => sessionStorage.setItem('fallbackSrc', reader.result as string);
      // eslint-disable-next-line no-empty
    } catch (err) {}

    const response = await imageUpload(newFile, {
      onUploadProgress: (progressEvent) => {
        if (progressEvent.loaded && progressEvent.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadPercentage(percentCompleted);
        }
      },
    });

    if (response.status !== 201) {
      throw new Error(`Failed to upload image. Status: ${response.status}`);
    }

    const { result } = response.data;
    const { variants } = result;
    const [imageUrl] = variants;
    onImageUpload({
      url: imageUrl,
      fileName: newFile.name,
    });
    resetStates();
  };

  const handleVideoUpload = useCallback(
    // eslint-disable-next-line max-statements
    async (newFile: File) => {
      const abortController = new AbortController();
      abortControllerRef.current = abortController;
      const { signal } = abortController;

      setFile(newFile);
      const width = ref.current?.clientWidth as number;
      if (newFile.size > MAX_FILESIZE) {
        showNotification(t('validationErrorMessages.UploadFileSizeErrorMessage'), 'error');
        setFile(null);
        setLoading(false);
        handleClearUploading();
        return;
      }

      const uploadVid = (videoId: string) => {
        handleClearUploading();
        onVideoUpload({
          url: null,
          fileName: newFile.name,
          type,
          videoPoster: '',
          height: width / dummyVideoRatio,
          videoId,
          isVideoUploaded: false,
        });
      };

      try {
        const uploadData = await videoUpload({
          file: newFile,
          errorMessage: t('validationErrorMessages.UploadFailed'),
          setPercentage: setUploadPercentage,
          onVideoUploadSuccess: uploadVid,
          documentName,
          signal,
        });
        dispatch(addVideoBlobData({ id: uploadData.videoId, blobUrl: uploadData.localUrl }));
      } catch {
        showNotification(t('validationErrorMessages.UploadFailed'), 'error');
        setFile(null);
        setLoading(false);
        handleClearUploading();
      }
    },
    [dispatch, handleClearUploading, documentName, onVideoUpload, t, type]
  );

  const focusHighlightItem = () => {
    ref.current?.closest<HTMLElement>('.highlight-item-button')?.focus();
    setIsEnabled(true);
  };

  const handleDropSingleFile = async (acceptedFiles: File[]) => {
    try {
      focusHighlightItem();
      setLoading(true);

      if (!acceptedFiles.length) {
        setLoading(false);
        return;
      }

      handleAddingUploading();

      if (acceptedFiles[0].type.includes('video')) {
        onChangeMediaType('video');
        handleVideoUpload(acceptedFiles[0]);
      } else if (acceptedFiles[0].type.includes('image')) {
        onChangeMediaType('image');
        handleImageUpload(acceptedFiles[0]);
      }
    } catch (err) {
      sessionStorage.removeItem('fallbackSrc');
      snackBarHandler();
      resetStates();
    }
  };

  const { getRootProps, getInputProps, isDragActive, fileRejections, isFileDialogActive, open } =
    useDropzone({
      onDrop: handleDropSingleFile,
      noClick: true,
      accept,
    });

  useEffect(() => {
    if (isDragActive) {
      setAccept({
        ...allowedFileTypes.image,
        ...allowedFileTypes.video,
      });
    } else {
      setAccept({
        ...allowedFileTypes[type],
      });
    }
  }, [isDragActive, type]);

  const dropzoneProps = {
    ...getRootProps({
      onClick: () => {
        if (isEnabled) {
          open();
        } else {
          setIsEnabled(true);
        }
      },
      onBlur: () => {
        if (isFileDialogActive) return;
        setIsEnabled(false);
      },
    }),
  };

  if (readOnly && !value) {
    return null;
  }

  return (
    <HighlightItem
      editBarProps={{
        baseActionsProps: { onDelete, onDuplicate },
        uploadActionsProps: {
          isLoading: loading,
          activeFileType: type,
          onChangeActiveFileType: onChangeMediaType,
        },
      }}
      dragging={dragging}
      horizontalResize={{}}
      readOnly={readOnly}
      onResizeStop={onResizeStop}
      borderColor="transparent"
      sx={{
        '&:hover div': {
          borderColor: 'transparent',
        },
      }}
      onResizeStart={onResizeStart}
    >
      {({ EditBarComponent, focused }) => (
        <Box ref={ref}>
          {EditBarComponent}
          <Box
            {...dropzoneProps}
            sx={{
              cursor: isEnabled ? 'pointer' : 'default',
              borderWidth: 1,
              borderColor: focused || isFileDialogActive ? 'transparent' : 'grey.500',
              borderStyle: 'dashed',
              borderRadius: (theme) => theme.shape.borderRadius * 2.5,
              pb: 5.5,
              pt: 4.75,
              px: 2,
              ...(isDragActive && {
                opacity: 0.7,
              }),
              display: loading ? 'none' : 'block',
            }}
          >
            <Placeholder {...placeholderProps[type]} />
            {type === 'video' && placeholderProps[type].additionalContent}
            <RejectionFiles fileRejections={fileRejections} />
          </Box>
          <UploadProgress
            sx={{
              display: loading ? 'block' : 'none',
              borderColor: focused || isFileDialogActive ? 'transparent' : 'grey.500',
            }}
            mediaType={type}
            size={file?.size || 0}
            progress={uploadPercentage}
            onClose={resetStates}
          />
          <input {...getInputProps()} />
          <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleClose}>
            <Alert severity="error">{errorMessage}</Alert>
          </Snackbar>
        </Box>
      )}
    </HighlightItem>
  );
}
