export interface Department {
  id: string;
  name: string;
}

export interface Role {
  department: Department;
  id: string;
  name: string;
}
export interface AssignedRole extends Role {
  assignedToSite: boolean;
}

export interface GetSiteRolesResponse {
  roles: AssignedRole[];
  allRoles: boolean;
}

export interface BaseEmployeeType {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  avatarUrl: string;
}
export interface Employee extends BaseEmployeeType {
  avatarId: string | null;
  pendingEmailChange: string | null;
  phoneNumber: string;
  roles: Role[];
  status: EmployeeStatus;
  isOwner: boolean;
  location: string;
  city: string;
  street: string;
  houseNumber: string;
  bankAccount: string;
  salary: string;
  bonus: string;
  currency: string;
  employeeId: string;
  payFrequency: string;
  employmentType: string;
  citizenship: string;
  gender: string;
  dateOfBirth: string;
  hireDate: string;
}

export interface EmployeePatchRequest {
  firstName: string;
  lastName: string;
  avatarId: string | null;
  email: string;
  phoneNumber: string;
  roleIds: string[];
  status: EmployeeStatus;
  isOwner: boolean;
}

export type EmployeeStatus = 'invited' | 'active';

export enum EmployeeStatuses {
  active = 'active',
  invited = 'invited',
}

export interface NewEmployeesRequestPayload {
  emails: string[];
  roles?: string[];
  allRoles: boolean;
}

export interface PermissionRoleCount {
  id: string;
  permissionsCount: number;
}
