import React from 'react';
import { TableContainer, Paper, useTheme, TableContainerProps } from '@mui/material';

interface WorkbaseTableContainerProps extends TableContainerProps {
  children: React.ReactNode;
}

function WorkbaseTableContainer({
  children,
  sx,
  component = Paper,
  ...otherProps
}: WorkbaseTableContainerProps) {
  const theme = useTheme();

  return (
    <TableContainer
      component={component}
      sx={{
        position: 'relative',
        maxWidth: 1258,
        minHeight: 0,
        minWidth: 0,
        borderRadius: theme.shape.borderRadius * 2.5,
        boxShadow: 'none',
        border: `1px solid ${theme.palette.grey[100]}`,
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </TableContainer>
  );
}

export default WorkbaseTableContainer;
