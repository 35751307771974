import { FunctionComponent, SVGProps } from 'react';
import { Theme } from '@mui/material/styles';
import { SvgIcon, SxProps } from '@mui/material';
import allIcons from 'src/assets/icons';

export type WorkbaseIconType =
  | FunctionComponent<
      SVGProps<SVGSVGElement> & {
        name?: string | undefined;
      }
    >
  | React.ElementType;

export interface WorkbaseIconProps {
  icon: WorkbaseIconType;
  sx?: SxProps<Theme>;
  viewBox?: string;
  color?: string;
  hoverColor?: string;
  className?: string;
  size?: number | string;
  dataCy?: string;
  onClick?: () => void;
}

export function WorkbaseIcon({
  color = 'inherit',
  icon,
  viewBox,
  className,
  hoverColor,
  sx,
  size,
  dataCy,
  onClick,
}: WorkbaseIconProps) {
  return (
    <SvgIcon
      data-cy={dataCy}
      onClick={onClick}
      component={icon}
      viewBox={viewBox || '0 0 24 24'}
      className={className}
      sx={{
        '&&&&:hover': {
          color: hoverColor,
        },
        '& path, g': {
          fill: 'currentcolor',
        },
        '&&&&': {
          color,
        },
        ...(size
          ? {
              width: size,
              height: size,
            }
          : {}),
        fontSize: size,
        ...sx,
      }}
    />
  );
}

export type IconName = keyof typeof allIcons;

export interface WorkbaseIconByNameProps {
  iconName: IconName;
  sx?: SxProps<Theme>;
  color?: string;
  hoverColor?: string;
  size?: number | string;
  className?: string;
  viewBox?: string;
  dataCy?: string;
}

export function WorkbaseIconByName({
  iconName,
  hoverColor,
  color = 'inherit',
  sx,
  size,
  className,
  viewBox,
  dataCy,
}: WorkbaseIconByNameProps) {
  const selectedIcon = allIcons[iconName];

  if (!selectedIcon) {
    return null;
  }

  return (
    <WorkbaseIcon
      data-cy={dataCy}
      icon={selectedIcon}
      className={className}
      viewBox={viewBox}
      hoverColor={hoverColor}
      color={color}
      sx={sx}
      size={size}
    />
  );
}
