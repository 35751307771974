import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseIconByName } from '../../lib/components/atoms/icon';

export default function RecordingPlaceholder() {
  const { t } = useTranslation();
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '100%',
        height: 640,
      }}
    >
      <Stack gap={0.75} alignItems="center" sx={{ width: 251 }}>
        <WorkbaseIconByName iconName="WorkbaseRecordIcon" size={50} color="error.main" />
        <Typography color="primary" variant="subtitle1" align="center">
          {t('recording.noDevicePlaceholder.title')}
        </Typography>
        <Typography color="grey[500]" variant="body1" align="center">
          {t('recording.noDevicePlaceholder.description')}
        </Typography>
      </Stack>
    </Stack>
  );
}
