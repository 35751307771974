import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useAppSelector } from 'src/store';
import debounce from 'lodash.debounce';
import { setBorderRadius } from 'src/store/client/themeSettings.slice';
import { useTranslation } from 'react-i18next';

const MAX = 20;
const MIN = 0;

function BorderRadiusSlider() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const borderRadius = useAppSelector((state) => state.themeSettings.borderRadius);

  const [val, setVal] = useState(borderRadius);

  const debouncedDispatch = useMemo(
    () =>
      debounce((newValue: number) => {
        dispatch(setBorderRadius(newValue));
      }, 300),
    [dispatch]
  );

  const debouncedSetBorderRadius = useCallback(
    (newValue: number) => {
      debouncedDispatch(newValue);
    },
    [debouncedDispatch]
  );

  const handleSliderChange = (_: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setVal(newValue);
      debouncedSetBorderRadius(newValue);
    }
  };

  return (
    <Box>
      <Typography variant="subtitle1" fontWeight="medium" gutterBottom>
        {t('companySettings.siteInformation.borderRadius.label')}: {val}px
      </Typography>
      <Slider
        value={val}
        onChange={handleSliderChange}
        aria-labelledby="border-radius-slider"
        step={1}
        min={MIN}
        max={MAX}
        valueLabelDisplay="auto"
        marks={[
          { value: MIN, label: `${MIN}px` },
          { value: MAX, label: `${MAX}px` },
          { value: val, label: `${val}px` },
        ]}
      />
    </Box>
  );
}

export default BorderRadiusSlider;
