import { Stack, Typography, alpha } from '@mui/material';

const formatDuration = (seconds: number) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  if (h > 0) {
    return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s
      .toString()
      .padStart(2, '0')}`;
  }
  return `${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
};

function DurationText({
  playedTime,
  duration,
  smallVariant,
}: {
  playedTime: number;
  duration: number;
  smallVariant: boolean;
}) {
  return (
    <Stack
      spacing={2}
      direction="row"
      sx={{
        mb: 1,
        px: 1,
        py: smallVariant ? '5px' : '6px',
        backgroundColor: (theme) => alpha(theme.palette.background.default as string, 0.75),
        borderRadius: (theme) => theme.shape.borderRadius,
      }}
      alignItems="center"
    >
      <Typography variant={smallVariant ? 'caption' : 'body1'} fontWeight="medium">
        {formatDuration(playedTime)}
        {' / '}
        {formatDuration(duration)}
      </Typography>
    </Stack>
  );
}

export default DurationText;
