import { Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePatchWhiteboardMutation, useWhiteboardQuery } from 'src/store/api/whiteboardsApi';
import IconOrEmojiSelector from 'src/ui-components/custom/icon-selector/IconOrEmojiSelector';
import useDebouncedFunction from 'src/ui-components/custom/textfield/hooks/use-debounced-value';

interface Props {
  id: string;
}

export default function WhiteboardIconNameSelector({ id }: Props) {
  const [name, setName] = useState('');
  const { t } = useTranslation();
  const { data } = useWhiteboardQuery(id);
  const [patchWhiteboard] = usePatchWhiteboardMutation();

  const debouncedPatchName = useDebouncedFunction((newName: string) => {
    if (newName !== data?.name) {
      patchWhiteboard({ id, name: newName });
    }
  }, 500);

  useEffect(() => {
    if (data?.name) {
      setName(data.name);
    }
  }, [data?.name]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setName(newName);
    debouncedPatchName(newName);
  };

  if (!data) return null;

  return (
    <>
      <IconOrEmojiSelector
        value={data.icon}
        onChange={(i) => {
          patchWhiteboard({ id, icon: i });
        }}
      />
      <Box width={({ breakpoints }) => breakpoints.values.sm} minWidth={0}>
        <TextField
          placeholder={t('whiteboards.nameTextFieldPlaceholder')}
          value={name}
          onChange={handleNameChange}
          size="small"
          fullWidth
        />
      </Box>
    </>
  );
}
