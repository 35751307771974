import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { shallowEqual } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ShareModal from 'src/core/share-modal/ShareModal';
import CustomSiteHeader from 'src/features/editor/view/custom-sites-header/CustomSiteHeader';
import CustomSiteLinksContainer from 'src/features/editor/view/custom-sites-header/CustomSiteLinksContainer';
import EditorWithModeSwitch from 'src/features/editor/view/EditorWithModeSwitch';
import { showNotification } from 'src/lib/components/atoms/notification';
import { PermissionCodes } from 'src/lib/enums/permissions';
import { ShareLevel } from 'src/lib/types/share-level';
import { useAppSelector } from 'src/store';
import { useDeletePageMutation, useDuplicatePageMutation } from 'src/store/api/pagesApi';
import { useShareLevelQuery } from 'src/store/api/shareResourceApi';
import { useUserPermissionsQuery } from 'src/store/api/userPermissionsApi';
import DeleteDuplicateMenu from 'src/ui-components/custom/delete-duplicate-menu';

export default function CustomSite() {
  const param = useParams();
  const [inViewRef, inView] = useInView();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: shareLevelData, error } = useShareLevelQuery({
    resourceType: 'page',
    resourceId: param.id as string,
  });
  const hasEditingPermissions =
    shareLevelData?.shareLevel !== ShareLevel.view &&
    shareLevelData?.shareLevel !== ShareLevel.none;
  const { data: userPermissions } = useUserPermissionsQuery();
  const hasAddPagesPermission = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.ADD_PAGES
  );

  const [deletePage] = useDeletePageMutation();
  const [duplicatePage] = useDuplicatePageMutation();

  const siteName =
    useAppSelector((state) => state.editor.editorData?.header?.name, shallowEqual) ||
    t('customSites.unnamedPage');

  const hasManagePermission = shareLevelData?.shareLevel === ShareLevel.manage;

  return (
    <EditorWithModeSwitch
      canEdit={hasEditingPermissions}
      canView={shareLevelData?.shareLevel !== ShareLevel.none && !error}
      renderHeader={(readOnly) => (
        <>
          <Box ref={inViewRef}>
            <CustomSiteHeader readOnly={readOnly} />
          </Box>
          <CustomSiteLinksContainer inView={inView} readOnly={readOnly} />
        </>
      )}
      topBarRightContent={
        <>
          {hasManagePermission && (
            <ShareModal name={siteName} resourceId={param.id as string} resourceType="page" />
          )}
          <DeleteDuplicateMenu
            onDuplicate={
              hasAddPagesPermission
                ? () => {
                    duplicatePage(param.id as string).then((resp: any) => {
                      if (!resp.error) {
                        navigate(`/page/${resp.data.id}/edit`);
                        showNotification(t('notifications.duplicateSuccess'));
                      } else {
                        showNotification(t('notifications.duplicateError'), 'error');
                      }
                    });
                  }
                : undefined
            }
            deleteDialogDescription={t(
              'editor.topBar.customQuestionsOnSettingIcon.deleteCustomSiteQuestion'
            )}
            onDelete={
              hasManagePermission
                ? () => {
                    deletePage(param.id as string).then((resp: any) => {
                      if (!resp.error) {
                        showNotification(t('notifications.deleteSuccess'));
                        navigate('/');
                      } else {
                        showNotification(t('notifications.deleteError'), 'error');
                      }
                    });
                  }
                : undefined
            }
          />
        </>
      }
      documentName={`page.${param.id as string}`}
    />
  );
}
