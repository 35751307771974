import { useTranslation } from 'react-i18next';
import {
  WorkbaseEmailBadgeIcon,
  WorkbaseDeleteIcon,
  WorkbaseEmailIcon,
  WorkbaseLiveChatIcon,
  WorkbaseResetIcon,
  WorkbaseTelefonWaveIcon,
  WorkbaseErrorIcon,
  WorkbaseAccountLockIcon,
} from 'src/assets/icons/workbaseIcons';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axiosInstance from 'src/axios/axios';
import { showNotification } from 'src/lib/components/atoms/notification';
import WorkbaseDialogComponent from 'src/lib/components/molecules/dialog/Dialog';
import { useBoolean } from 'src/lib/hooks/use-boolean';
import { employeesApi } from 'src/store/api/employees/employeesApi';
import { IconButton, SvgIcon, Tooltip, Typography } from '@mui/material';
import { TopBarWithIcons } from 'src/ui-components/layout/top-bar';
import { Employee } from '../../model';
import {
  resetPasswordApi,
  usePostRequestResendInvitationMutation,
  usePostRequestResetPasswordMutation,
  useResetPasswordCooldownQuery,
  useResendInvitationCooldownQuery,
  resendInvitationApi,
} from '../../controller';

interface Props {
  employee: Employee;
  employeesRefetch: () => void;
  hasManageEmployeePermission: boolean;
}

const openLink = (url: string) => () => {
  const aTag = document.createElement('a');
  aTag.setAttribute('href', url);
  aTag.setAttribute('target', '_blank');
  aTag.click();
};

export default function EmployeeDetailsTopBar({
  employee,
  employeesRefetch,
  hasManageEmployeePermission,
}: Props) {
  const { t } = useTranslation();
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { data, isSuccess } = useResetPasswordCooldownQuery(param.id as string, {
    refetchOnMountOrArgChange: true,
  });
  const { data: dataResendInvitation, isSuccess: isSuccessResend } =
    useResendInvitationCooldownQuery(param.id as string, {
      refetchOnMountOrArgChange: true,
    });

  const [requestResetPassword] = usePostRequestResetPasswordMutation();
  const [requestResendInvitation] = usePostRequestResendInvitationMutation();
  const deleteDialog = useBoolean();
  const makeOwnerDialog = useBoolean();

  const shownName =
    employee.firstName && employee.lastName
      ? `${employee.firstName} ${employee.lastName}`
      : employee.email;

  useEffect(() => {
    if (isSuccess) {
      const intervalSpan = 1000;
      const interval = setInterval(() => {
        dispatch(
          resetPasswordApi.util.updateQueryData(
            'resetPasswordCooldown',
            employee.id,

            (draft) =>
              draft && {
                ...draft,
                cooldown:
                  draft.cooldown > 0
                    ? draft.cooldown - intervalSpan
                    : draft.cooldown - intervalSpan,
              }
          )
        );
      }, intervalSpan);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [dispatch, employee.id, isSuccess]);

  useEffect(() => {
    if (isSuccessResend) {
      const intervalSpan = 1000;
      const interval = setInterval(() => {
        dispatch(
          resendInvitationApi.util.updateQueryData(
            'resendInvitationCooldown',
            employee.id,

            (draft) =>
              draft && {
                ...draft,
                cooldown:
                  draft.cooldown > 0
                    ? draft.cooldown - intervalSpan
                    : draft.cooldown - intervalSpan,
              }
          )
        );
      }, intervalSpan);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [dispatch, employee.id, isSuccessResend]);

  const statusDependentItem =
    employee.status === 'active'
      ? [
          {
            key: 'reset-password',
            tooltip: t('employeePage.employeeDetails.actionButtons.resetPassword'),
            icon: WorkbaseResetIcon,
            onClick: () => {
              if (data && data.cooldown > 0) {
                const time = Math.ceil((data.cooldown ?? 0) / 60000);

                const timeUnit =
                  time > 1
                    ? t('employeePage.employeeDetails.resetPassword.minutes')
                    : t('employeePage.employeeDetails.resetPassword.minute');

                showNotification(
                  t('employeePage.employeeDetails.resetPassword.waitNotification', {
                    time,
                    timeUnit,
                  }),
                  'error'
                );
              } else {
                requestResetPassword(employee.id).then((resp: any) => {
                  if (resp.error) {
                    showNotification(
                      t('employeePage.employeeDetails.resetPassword.errorNotification', {
                        name: `${employee.firstName} ${employee.lastName}`,
                      }),
                      'error'
                    );
                  } else {
                    showNotification(
                      t('employeePage.employeeDetails.resetPassword.successNotification', {
                        name: `${employee.firstName} ${employee.lastName}`,
                      }),
                      'success'
                    );
                  }
                });
              }
            },
          },
        ]
      : [
          {
            key: 'resend-invitation',
            tooltip: t('employeePage.employeeDetails.actionButtons.resendInvitation'),
            icon: WorkbaseEmailBadgeIcon,
            onClick: () => {
              if (dataResendInvitation && dataResendInvitation.cooldown > 0) {
                const time = Math.ceil((dataResendInvitation.cooldown ?? 0) / 60000);

                const timeUnit =
                  time > 1
                    ? t('employeePage.employeeDetails.resendInvitation.minutes')
                    : t('employeePage.employeeDetails.resendInvitation.minute');

                showNotification(
                  t('employeePage.employeeDetails.resendInvitation.waitNotification', {
                    time,
                    timeUnit,
                  }),
                  'error'
                );
              } else {
                requestResendInvitation(employee.id).then((resp: any) => {
                  if (resp.error) {
                    showNotification(
                      t('employeePage.employeeDetails.resendInvitation.errorNotification'),
                      'error'
                    );
                  } else {
                    showNotification(
                      t('employeePage.employeeDetails.resendInvitation.successNotification'),
                      'success'
                    );
                  }
                });
              }
            },
          },
        ];

  const toolItems = [
    {
      key: 'call',
      tooltip: t('employeePage.employeeDetails.actionButtons.call'),
      icon: WorkbaseTelefonWaveIcon,
      onClick: openLink(`tel:+${employee.phoneNumber}`),
      disabled: !employee.phoneNumber.length,
    },
    {
      key: 'email',
      tooltip: t('employeePage.employeeDetails.actionButtons.email'),
      icon: WorkbaseEmailIcon,
      onClick: openLink(`mailto:${employee.email}`),
      disabled: !employee.email.length,
    },
    {
      key: 'whats-app',
      tooltip: t('employeePage.employeeDetails.actionButtons.whatsApp'),
      icon: WorkbaseLiveChatIcon,
      onClick: openLink(`https://wa.me/${employee.phoneNumber}`),
      disabled: !employee.phoneNumber.length,
    },
    ...statusDependentItem,
  ];

  const rightContent = (
    <>
      {employee.status === 'active' && !hasManageEmployeePermission && (
        <Tooltip title={t('makeOwner')}>
          <IconButton
            onClick={() => {
              makeOwnerDialog.onTrue();
            }}
          >
            <SvgIcon component={WorkbaseAccountLockIcon} />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={t('delete')}>
        <IconButton
          onClick={() => {
            deleteDialog.onTrue();
          }}
        >
          <SvgIcon component={WorkbaseDeleteIcon} />
        </IconButton>
      </Tooltip>
    </>
  );

  return (
    <>
      <TopBarWithIcons icons={toolItems} rightContent={rightContent} />
      {!employee.isOwner && (
        <>
          <WorkbaseDialogComponent
            title={t('editor.topBar.customQuestionsOnSettingIcon.areYouSure')}
            open={deleteDialog.value}
            onClose={deleteDialog.onFalse}
            titleIcon={{ icon: WorkbaseErrorIcon, color: 'error.main' }}
            confirmButton={{
              text: t('delete'),
              onClick: () => {
                axiosInstance
                  .delete(`companies/users/${param.id}`)
                  .then(() => {
                    showNotification(t('notifications.deleteSuccess'));
                    dispatch(
                      employeesApi.endpoints.employees.initiate(undefined, { forceRefetch: true })
                    );
                    navigate('/team/');
                  })
                  .catch(() => {
                    showNotification(t('notifications.deleteError'), 'error');
                  });
              },
              type: 'error',
            }}
          >
            <Typography>
              {t('employeePage.employeeDetails.deleteEmployee.question', {
                name: `${shownName}`,
              })}
            </Typography>
          </WorkbaseDialogComponent>
          <WorkbaseDialogComponent
            title={t('editor.topBar.customQuestionsOnSettingIcon.makeOwner', {
              name: `${shownName}`,
            })}
            open={makeOwnerDialog.value}
            onClose={makeOwnerDialog.onFalse}
            titleIcon={{ icon: WorkbaseErrorIcon, color: 'error.main' }}
            confirmButton={{
              text: t('confirm'),
              onClick: () => {
                axiosInstance
                  .post(`companies/users/${param.id}/transfer-ownership`)
                  .then(() => {
                    showNotification(t('notifications.makeOwnerSuccess', { name: `${shownName}` }));
                    employeesRefetch();
                  })
                  .catch(() => {
                    showNotification(
                      t('notifications.makeOwnerError', { name: `${shownName}` }),
                      'error'
                    );
                  });
              },
              type: 'error',
            }}
          >
            <Typography>
              {t('employeePage.employeeDetails.makeOwner.question', {
                name: `${shownName}`,
              })}
            </Typography>
          </WorkbaseDialogComponent>
        </>
      )}
    </>
  );
}
