import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { BaseEmployeeType } from 'src/features/employees/model';
import { WorkbaseEmployeeIcon } from 'src/assets/icons/workbaseIcons';
import EmployeeActionButton from './EmployeeActionButton';

export default function EmployeeRolesList({
  employees,
  roleId,
}: {
  employees: BaseEmployeeType[];
  roleId: string;
}) {
  return (
    <List>
      {employees.map((emp: BaseEmployeeType) => (
        <ListItem disableGutters key={emp.id}>
          {emp.avatarUrl && (
            <ListItemAvatar>
              <Avatar src={emp.avatarUrl} />
            </ListItemAvatar>
          )}
          {!emp.avatarUrl && (
            <ListItemIcon>
              <WorkbaseEmployeeIcon />
            </ListItemIcon>
          )}
          <ListItemText
            primary={`${emp.firstName} ${emp.lastName}`}
            secondary={emp.email ? `${emp.email}` : null}
          />
          <Box ml={2}>
            <EmployeeActionButton userId={emp.id} roleId={roleId} />
          </Box>
        </ListItem>
      ))}
    </List>
  );
}
