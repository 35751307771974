import { Box, IconButton, alpha } from '@mui/material';
import { WorkbaseIconByName } from 'src/lib/components/atoms/icon';

export default function BigPlayIcon({
  onClick,
  isPlaying,
  smallVariant,
}: {
  onClick?: () => void;
  isPlaying?: boolean;
  smallVariant?: boolean;
}) {
  return (
    <Box
      className="big-play-icon"
      height={smallVariant ? 50 : 100}
      width={smallVariant ? 50 : 100}
      borderRadius="100%"
      position="absolute"
      top="50%"
      left="50%"
      sx={{
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        alignItems: 'center',
        zIndex: 2,
        justifyContent: 'center',
        opacity: isPlaying ? 0 : 1,
        transition: '0.3s',
        backgroundColor: (theme) => alpha(theme.palette.primary.main as string, 0.75),
      }}
    >
      <IconButton size={smallVariant ? 'medium' : 'large'} onClick={onClick}>
        <WorkbaseIconByName
          size={100}
          iconName={isPlaying ? 'WorkbasePauseIcon' : 'WorkbasePlayIcon'}
          color="white.main"
        />
      </IconButton>
    </Box>
  );
}
