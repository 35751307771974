import { Box, Stack } from '@mui/material';
import { useDrop } from 'react-dnd';
import { useEditor } from '../../../controller';
import { GridItem, GridRow } from '../../../model';
import useDebouncedIsOver from './useDebouncedIsOver';

interface Props {
  rowIndex: number;
}

const getCanDropItem = (dragItemIndex: number, rowIndex: number, row?: GridRow) => {
  const isItemTheOnlyItemInTheRow = row?.items.length === 1;
  const canDropItem =
    (dragItemIndex === rowIndex && !isItemTheOnlyItemInTheRow) ||
    (dragItemIndex + 1 === rowIndex && !isItemTheOnlyItemInTheRow) ||
    (dragItemIndex !== rowIndex && dragItemIndex + 1 !== rowIndex);
  return canDropItem;
};

export default function DroppableRowInBetween({ rowIndex }: Props) {
  const { moveItemToNewRow } = useEditor();
  const [{ isOver, dragItemIndex, row }, drop] = useDrop({
    accept: 'GRID_ITEM',
    drop: (item: { rowIndex: number; item: GridItem; row: GridRow }, monitor) => {
      if (monitor && getCanDropItem(dragItemIndex, rowIndex, row)) {
        moveItemToNewRow(item?.item.id, rowIndex);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      dragItemIndex: monitor.getItem()?.rowIndex,
      row: monitor.getItem()?.row,
    }),
  });
  const debouncedIsOver = useDebouncedIsOver(isOver);
  const canDropItem = getCanDropItem(dragItemIndex, rowIndex, row);

  return (
    <Stack
      ref={drop}
      sx={{
        height: '40px',
        position: 'absolute',
        width: '100%',
        zIndex: 3,
        maxWidth: 1240,
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        top: '0',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: debouncedIsOver && canDropItem ? 'info.main' : 'transparent',
          height: '10px',
          width: '100%',
          borderRadius: (theme) => theme.shape.borderRadius,
        }}
      />
    </Stack>
  );
}
