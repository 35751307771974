import { useTheme } from '@mui/material';

type ColorObject = {
  name: string;
  color: string;
};

function useGenerateColors(baseColor: string): ColorObject[] {
  const theme = useTheme();

  const { light, dark, contrastText } = theme.palette.augmentColor({
    color: {
      main: baseColor,
    },
    name: 'main',
  });

  return [
    { color: light, name: 'Light' },
    { color: dark, name: 'Dark' },
    { color: contrastText, name: 'ContrastText' },
  ];
}

export default useGenerateColors;
