import { Grid } from '@mui/material';
import { getData } from 'country-list';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import { Employee } from 'src/features/employees/model';
import { WorkbaseTextField } from 'src/lib/components/atoms/text-field';
import checkIfCountryCode from 'src/lib/utils/checkIfCountryCode';
import { showNotification } from 'src/lib/components/atoms/notification';
import EmailValidationTextField from 'src/lib/components/atoms/validation-text-field/EmailValidationTextField';
import validateEmail from 'src/lib/utils/validateEmail';
import WorkbaseTextMaxLine from 'src/lib/components/atoms/text-max-line/TextMaxLine';
import {
  useCancelEmailChangeMutation,
  useChangeEmailMutation,
  useEmailChangeCooldownQuery,
} from 'src/store/api/employees/employeesApi';
import SelectWithSearch from 'src/lib/components/molecules/select-with-search';
import Datepicker from 'src/lib/components/molecules/date-picker';
import dayjs from 'dayjs';

interface Info {
  id: string;
  title: string;
  text: string;
  pendingEmail?: string | undefined;
}
interface Props {
  employeeInfo: Info[];
  employeeId: string;
  handleUpdateEmployee: (val: Partial<Employee>) => void;
}
export default function EditableContactInfo({
  employeeInfo,
  employeeId,
  handleUpdateEmployee,
}: Props) {
  const { t } = useTranslation();
  const [cancelEmailChange] = useCancelEmailChangeMutation();
  const [changeEmail] = useChangeEmailMutation();
  const { data: dataCooldown } = useEmailChangeCooldownQuery(employeeId);

  const handleEmailChange = async (val: string) => {
    if (dataCooldown && dataCooldown.cooldown > 0) {
      const time = Math.ceil((dataCooldown.cooldown ?? 0) / 60000);
      const timeUnit =
        time > 1
          ? t('employeePage.employeeDetails.changeEmail.minutes')
          : t('employeePage.employeeDetails.changeEmail.minute');

      return {
        isError: true,
        message: t('employeePage.employeeDetails.changeEmail.waitNotification', {
          time,
          timeUnit,
        }),
      };
    }

    try {
      const resp: any = await changeEmail({
        id: employeeId,
        email: val,
      });

      if (resp?.error) {
        // eslint-disable-next-line max-depth
        if (resp?.error.data.code === 'EMAIL_ALREADY_EXISTS')
          return { isError: true, message: t('emailAlreadyInUse') };
        return {
          isError: true,
          message: t('employeePage.employeeDetails.changeEmail.errorNotification'),
        };
      }
      showNotification(t('employeePage.employeeDetails.changeEmail.successNotification'));
      return { isError: false };
    } catch (error) {
      return {
        isError: true,
        message: 'there is an error, try again',
      };
    }
  };

  const handleEmailCancel = () => {
    cancelEmailChange(employeeId);
  };
  const handleUpdate = ({ type, val }: { type: keyof Employee; val: string | null }) => {
    const valueToSend = val === undefined ? null : val;
    const updatedField: Partial<Employee> = { [type]: valueToSend };
    handleUpdateEmployee(updatedField);
  };

  const validateEmployeeEmail = (email: string) => {
    if (validateEmail(email)) {
      return { isError: false };
    }
    return { isError: true, message: t('invalidEmail') };
  };
  const payFrequencyOptions = [
    { value: 'monthly', label: t('employeePage.employeeDetails.compensationDetails.monthly') },
    {
      value: 'biWeekly',
      label: t('employeePage.employeeDetails.compensationDetails.biWeekly'),
    },
    { value: 'weekly', label: t('employeePage.employeeDetails.compensationDetails.weekly') },
  ];

  const employmentTypeOptions = [
    { value: 'fullTime', label: t('employeePage.employeeDetails.hrInfomation.fullTime') },
    { value: 'partTime', label: t('employeePage.employeeDetails.hrInfomation.partTime') },
    { value: 'contract', label: t('employeePage.employeeDetails.hrInfomation.contract') },
  ];

  const citizenshipOptions = getData().map((country: any) => ({
    value: country.name,
    label: country.name,
  }));

  const genderOption = [
    { value: 'male', label: t('employeePage.employeeDetails.personalInformation.male') },
    { value: 'female', label: t('employeePage.employeeDetails.personalInformation.female') },
  ];

  const renderFieldById = (info: Info) => {
    switch (info.id) {
      case 'email':
        return (
          <EmailValidationTextField
            dataCy="employee-information-email-field"
            onSubmit={handleEmailChange}
            value={info.text}
            onCancel={handleEmailCancel}
            pendingValue={info.pendingEmail}
            label={info.title}
            validateFn={validateEmployeeEmail}
            onChange={(val) => {
              const pendingEmailValue = info.text !== val ? val : null;
              handleUpdate({ type: 'pendingEmailChange', val: pendingEmailValue });
            }}
          />
        );
      case 'phoneNumber':
        return (
          <WorkbaseTextField
            dataCy="employee-information-phone-field"
            value={info.text}
            onChange={(e) => handleUpdate({ type: 'phoneNumber', val: e.target.value })}
            fullWidth
            error={info.text !== '' && !isValidPhoneNumber(info.text)}
            helperText={
              info.text !== '' &&
              !isValidPhoneNumber(info.text) &&
              t('validationErrorMessages.phoneErrorMsg') +
                (checkIfCountryCode(info.text)
                  ? ''
                  : ` ${t('validationErrorMessages.missingCountryCode')}`)
            }
            sx={{ input: { fontSize: '15px' } }}
          />
        );
      case 'location':
      case 'city':
      case 'street':
      case 'houseNumber':
      case 'employeeId':
      case 'bankAccount':
      case 'salary':
      case 'bonus':
      case 'currency':
        return (
          <WorkbaseTextField
            dataCy={`employee-information-${info.id}-field`}
            value={info.text}
            onChange={(e) => handleUpdate({ type: info.id as keyof Employee, val: e.target.value })}
            fullWidth
          />
        );
      case 'payFrequency':
        return (
          <SelectWithSearch
            dataCy="employee-information-payFrequency-selector"
            options={payFrequencyOptions}
            value={info.text}
            onChange={(val) => info.text !== val && handleUpdate({ type: 'payFrequency', val })}
            // will work after SelectWithSearch refactored
            // @ts-ignore
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'flip',
                    enabled: true,
                  },
                ],
              },
            }}
          />
        );
      case 'employmentType':
        return (
          <SelectWithSearch
            dataCy="employee-information-employmentType-selector"
            options={employmentTypeOptions}
            value={info.text}
            onChange={(val) => info.text !== val && handleUpdate({ type: 'employmentType', val })}
          />
        );
      case 'citizenship':
        return (
          <SelectWithSearch
            dataCy="employee-information-citizenship-selector"
            options={citizenshipOptions}
            value={info.text}
            onChange={(val) => info.text !== val && handleUpdate({ type: 'citizenship', val })}
          />
        );
      case 'gender':
        return (
          <SelectWithSearch
            dataCy="employee-information-gender-selector"
            options={genderOption}
            value={info.text}
            onChange={(val) => info.text !== val && handleUpdate({ type: 'gender', val })}
          />
        );
      case 'hireDate':
      case 'dateOfBirth':
        return (
          <Datepicker
            fullWidth
            value={info.text ? dayjs(info.text) : null}
            onChange={(val) => {
              handleUpdate({
                type: info.id as keyof Employee,
                val: val?.toISOString() as string,
              });
            }}
          />
        );
      default:
        return (
          <WorkbaseTextMaxLine
            color="lightgray.main"
            line={1}
            variant="caption"
            sx={{ lineHeight: '1.167' }}
          >
            {info.title}
          </WorkbaseTextMaxLine>
        );
    }
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        maxWidth: '100%',
        // just hardcode min-width of email field so that all fields are aligned
        '& .MuiInputBase-root': {
          minWidth: 263,
        },
      }}
      wrap="wrap"
    >
      {employeeInfo.map((info) => (
        <Grid item key={info.id} sx={{ minWidth: 'max-content' }}>
          {info.id !== 'email' && (
            <WorkbaseTextMaxLine
              color="grey[500]"
              line={1}
              variant="body1"
              sx={{ lineHeight: '1.167' }}
            >
              {info.title}
            </WorkbaseTextMaxLine>
          )}
          {renderFieldById(info)}
        </Grid>
      ))}
    </Grid>
  );
}
