import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  disabled: boolean;
  onClick: () => void;
}

export default function StartRecordingButton({ disabled, onClick }: Props) {
  const { t } = useTranslation();
  return (
    <Button disabled={disabled} color="error" onClick={onClick}>
      {t('recording.startRecording')}
    </Button>
  );
}
