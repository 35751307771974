import { Stack, SvgIcon, Typography } from '@mui/material';
import { WorkbaseEmployeeIcon } from 'src/assets/icons/workbaseIcons';
import { useTranslation } from 'react-i18next';
import { usePatchCurrentUserMutation } from 'src/store/api/currentUserApi';
import { EmailField, PhoneField, RequiredFieldWithValidation } from './Fields';
import UploadAvatar from './UploadAvatar';

interface DataProps {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  avatarUrl: string | null;
  dataCy?: string;
}

export default function GeneralSettings({
  firstName,
  lastName,
  email,
  phoneNumber,
  avatarUrl,
  dataCy,
}: DataProps) {
  const { t } = useTranslation();
  const [patchCurrentUser] = usePatchCurrentUserMutation();
  const handleUpdate = (name: string, value: string) => {
    patchCurrentUser({ [name]: value });
  };
  return (
    <Stack gap={1.5}>
      <Stack direction="row" gap={1} alignItems="center">
        <SvgIcon component={WorkbaseEmployeeIcon} fontSize="small" />
        <Typography variant="body1" fontWeight="bold">
          {t('generalSettings.information.header')}
        </Typography>
      </Stack>
      <RequiredFieldWithValidation
        dataCy={dataCy && `${dataCy}-firstName-field`}
        label={t('generalSettings.information.profileInformation.firstName')}
        initialVal={firstName}
        name="firstName"
        onUpdate={handleUpdate}
      />
      <RequiredFieldWithValidation
        dataCy={dataCy && `${dataCy}-lastName-field`}
        label={t('generalSettings.information.profileInformation.familyName')}
        initialVal={lastName}
        name="lastName"
        onUpdate={handleUpdate}
      />
      <EmailField
        dataCy={dataCy && `${dataCy}-email-field`}
        label={t('generalSettings.information.profileInformation.mail')}
        initialVal={email}
        name="email"
      />
      <PhoneField
        dataCy={dataCy && `${dataCy}-phone-field`}
        label={t('generalSettings.information.profileInformation.contactNumber')}
        initialVal={phoneNumber}
        name="phoneNumber"
        onUpdate={handleUpdate}
      />
      <UploadAvatar text="Avatar" url={avatarUrl} />
    </Stack>
  );
}
