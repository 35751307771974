import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
} from '@mui/material';
import { WorkbaseAddCircleIcon } from 'src/assets/icons/workbaseIcons';
import { useBoolean } from 'src/lib/hooks/use-boolean';
import NewEmployee from 'src/features/employees/view/new-employee/NewEmployee';
import useMenuData from './useMenuData';
import usePermissions from '../usePermissions';

interface Props {
  isFullWidth?: boolean;
}

const addButtonDataCy = 'sidebarAddButton';

export default function AddButton({ isFullWidth }: Props) {
  const { t } = useTranslation();
  const newEmployeeDialogShow = useBoolean();
  const {
    hasManageEmployeePermission,
    hasAddPagesPermission,
    hasAddEmbeddedAppsPermission,
    hasAddTrainingsPermission,
    hasAddWhiteboardsPermission,
    hasAddWikiPermission,
  } = usePermissions();
  const menuData = useMenuData({ onAddNewEmployeeClick: newEmployeeDialogShow.onTrue });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  if (
    !hasManageEmployeePermission &&
    !hasAddPagesPermission &&
    !hasAddEmbeddedAppsPermission &&
    !hasAddTrainingsPermission &&
    !hasAddWhiteboardsPermission &&
    !hasAddWikiPermission
  )
    return null;

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  const open = !!anchorEl;

  const buttonIcon = (
    <SvgIcon fontSize="small" color="primary">
      <WorkbaseAddCircleIcon />
    </SvgIcon>
  );

  return (
    <Box mt="auto">
      {hasManageEmployeePermission && (
        <NewEmployee open={newEmployeeDialogShow.value} onClose={newEmployeeDialogShow.onFalse} />
      )}

      <ListItemButton data-cy={addButtonDataCy} onClick={handleOpen} dense>
        <ListItemIcon sx={{ minWidth: isFullWidth ? 32 : 0 }}>{buttonIcon}</ListItemIcon>
        {isFullWidth && (
          <ListItemText
            primary={t('sidebar.tabTitleAddButton')}
            primaryTypographyProps={{ noWrap: true, fontWeight: 'medium' }}
          />
        )}
      </ListItemButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {menuData.map(({ icon, label, onClick, key }) => (
          <MenuItem
            key={key}
            data-cy={`${addButtonDataCy}.${key}`}
            onClick={() => {
              onClick();
              handleClose();
            }}
          >
            <ListItemIcon>
              <SvgIcon component={icon} fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={label} primaryTypographyProps={{ noWrap: true }} />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
