import { CHARGEBEE_API_KEY, CHARGEBEE_SITE } from 'src/global-config';

declare global {
  interface Window {
    Chargebee: any;
  }
}

export function handleOpenBillingSettingsPopup() {
  const src = 'https://js.chargebee.com/v2/chargebee.js';
  const existingScript = document.querySelector(`script[src="${src}"]`);

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;

    script.onload = () => {
      window.Chargebee.init({
        site: CHARGEBEE_SITE,
        publishableKey: CHARGEBEE_API_KEY,
        isItemsModel: true,
      });

      const instance = window.Chargebee.getInstance();
      const portal = instance.createChargebeePortal();
      portal.open();
    };

    document.body.appendChild(script);
  } else {
    const instance = window.Chargebee.getInstance();
    const portal = instance.createChargebeePortal();
    portal.open();
  }
}

export default function Billing() {
  handleOpenBillingSettingsPopup();
  return null;
}
