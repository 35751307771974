import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseAccountLockIcon } from 'src/assets/icons/workbaseIcons';
import { WorkbaseIcon } from 'src/lib/components/atoms/icon';

export default function NoApiKeysPlaceholder({
  onGenerateApiKey,
}: {
  onGenerateApiKey: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Stack alignItems="center" justifyContent="center" gap={2.5}>
      <WorkbaseIcon size={64} color="grey[500]" icon={WorkbaseAccountLockIcon} />
      <Stack alignItems="center" justifyContent="center" gap={0.5}>
        <Typography variant="h5">{t('companySettings.apiKeys.noKeys')}</Typography>
      </Stack>
      <Button onClick={onGenerateApiKey}>{t('companySettings.apiKeys.generateApiKey')}</Button>
    </Stack>
  );
}
