import { Stack, SxProps, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import { WorkbaseIconByName } from '../../atoms/icon';

export interface BreadcrumbsProps {
  data: Breadcrumb[];
  sx?: SxProps<Theme>;
}

export interface Breadcrumb {
  key: string;
  to: string;
  label: string;
}

const StyledLink = styled(Link)`
  text-decoration: none;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  overflow: hidden;
  display: flex;
  padding: 6px;
  background-color: transparent;
  color: ${({ theme }) => theme.palette.text.secondary};

  &:hover {
    background-color: ${({ theme }) => theme.palette.background.default};
    color: ${({ theme }) => theme.palette.text.primary};
    text-decoration: underline;
  }
`;

const StyledSpan = styled('span')`
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  display: flex;
  cursor: default;
  margin-left: 6px;
  padding: 6px;
  &:hover {
    background-color: ${({ theme }) => theme.palette.background.default};
  }
  color: ${({ theme }) => theme.palette.text.primary};
`;

export default function WorkbaseBreadcrumbs({ data, sx }: BreadcrumbsProps) {
  return (
    <Stack sx={sx} direction="row" alignItems="center" flexWrap="nowrap" gap={0.25}>
      {data.map(({ key, to, label }, index) => {
        const isLastItem = index === data.length - 1;
        const labelLength = label.length;
        const flexShrinkVal = labelLength;

        let labelMinWidth = 0;
        if (labelLength > 3) {
          labelMinWidth = 26;
        }
        if (labelLength > 4) {
          labelMinWidth = 30;
        }
        if (labelLength > 5) {
          labelMinWidth = 36;
        }
        if (isLastItem) {
          return (
            <StyledSpan
              className="breadcrumb-item"
              key={key}
              style={{
                flexShrink: flexShrinkVal,
                minWidth: labelMinWidth,
              }}
            >
              <Typography variant="body1" noWrap={labelLength > 3}>
                {label}
              </Typography>
            </StyledSpan>
          );
        }
        return (
          <Stack
            className="breadcrumb-item"
            direction="row"
            alignItems="center"
            gap={0.5}
            sx={{
              flexShrink: flexShrinkVal,
              minWidth: labelMinWidth + 34,
              '&:not(:first-child)': {
                marginLeft: '6px',
              },
            }}
            key={key}
          >
            <StyledLink to={to}>
              <Typography variant="body1" noWrap={labelLength > 3}>
                {label}
              </Typography>
            </StyledLink>
            <WorkbaseIconByName
              iconName="WorkbaseChevronIcon"
              color="secondary.text"
              size={12}
              sx={{
                '&:hover': {
                  color: (theme) => theme.palette.text.primary,
                },
              }}
            />
          </Stack>
        );
      })}
    </Stack>
  );
}
