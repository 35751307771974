import { Stack, Typography } from '@mui/material';

interface Props {
  title: string;
  titleColor?: string;
  prefix?: string;
  dataCy?: string;
}

export default function CardTitle({ title, titleColor = 'text.primary', prefix, dataCy }: Props) {
  return (
    <Stack flexDirection="row" gap="2px" sx={{ minWidth: 0 }}>
      {prefix && (
        <Typography variant="subtitle1" fontWeight="bold" color="grey[500]">
          {prefix}
        </Typography>
      )}
      <Typography
        variant="subtitle1"
        fontWeight="bold"
        color={titleColor}
        noWrap
        data-cy={dataCy && `${dataCy}-title`}
      >
        {title}
      </Typography>
    </Stack>
  );
}
