import { useParams } from 'react-router-dom';
import EditViewProtectedWrapper from 'src/core/EditViewProtectedWrapper';
import ModeSwitch from 'src/core/ModeSwitch';
import { useShareLevelQuery } from 'src/store/api/shareResourceApi';
import MultiplayerTldraw from 'src/features/tldraw/Tldraw';
import { Stack, Box } from '@mui/material';
import { useEffect } from 'react';
import { ShareLevel } from 'src/lib/types/share-level';
import WhiteboardTopBar from 'src/features/whiteboard-topbar/WhiteboardTopBar';

const parsedUrl = new URL(import.meta.env.VITE_API_URL);
const socketProtocol = parsedUrl.protocol === 'https:' ? 'wss' : 'ws';
const socketUrl = `${socketProtocol}://${parsedUrl.host}/editor`;
interface MultiplayerWhiteboardProps {
  readonly: boolean;
  id: string;
}

function MultiplayerWhiteboard({ readonly, id }: MultiplayerWhiteboardProps) {
  const TldrawParams = {
    socketUrl,
    documentName: `whiteboard.${id}`,
    readonly,
  };
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.metaKey && event.key === 'a') {
        const tldrawElement = document.querySelector('.tl-container');
        if (tldrawElement && !tldrawElement.contains(event.target)) {
          event.preventDefault();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (readonly) {
    return <MultiplayerTldraw {...TldrawParams} />;
  }

  return (
    <ModeSwitch>
      {(isEditing) => (
        <Stack height="100%">
          {isEditing && <WhiteboardTopBar id={id} />}
          <Box position="relative" height="100%" mt={isEditing ? 7 : 0}>
            <MultiplayerTldraw
              socketUrl={socketUrl}
              documentName={`whiteboard.${id}`}
              readonly={!isEditing}
            />
          </Box>
        </Stack>
      )}
    </ModeSwitch>
  );
}

export default function WhiteboardView() {
  const param = useParams();
  const whiteboardId = param.id;
  const { data: shareLevelData, isSuccess } = useShareLevelQuery(
    { resourceId: whiteboardId || '', resourceType: 'whiteboard' },
    {
      skip: !whiteboardId,
    }
  );
  const canEdit =
    shareLevelData?.shareLevel === ShareLevel.edit ||
    shareLevelData?.shareLevel === ShareLevel.manage;
  if (!whiteboardId || !isSuccess) return null;
  return (
    <EditViewProtectedWrapper
      canEdit={canEdit}
      canView={shareLevelData.shareLevel !== ShareLevel.none}
    >
      <MultiplayerWhiteboard key={whiteboardId} readonly={!canEdit} id={whiteboardId} />
    </EditViewProtectedWrapper>
  );
}
