/* eslint-disable max-statements */
import { CircularProgress, Stack, Box, Typography, SvgIcon, ListItemAvatar } from '@mui/material';
import SecondarySidebar from 'src/ui-components/layout/secondary-sidebar/SecondarySidebar';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import PortalComponent from 'src/lib/components/util/PortalComponent';
import { useTranslation } from 'react-i18next';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import { useScreenWidth } from 'src/lib/hooks/use-screen-width';
import { useAppSelector } from 'src/store';
import useOnLeaveViewBlocker from 'src/lib/hooks/useBlocker';
import { useUserPermissionsQuery } from 'src/store/api/userPermissionsApi';
import { PermissionCodes } from 'src/lib/enums/permissions';
import {
  WorkbaseCheckmarkIcon,
  WorkbaseEmailIcon,
  WorkbaseErrorIcon,
  WorkbaseShareIcon,
} from 'src/assets/icons/workbaseIcons';
import {
  useEmployeesQuery,
  useEmployeesTrainingProgressQuery,
} from 'src/store/api/employees/employeesApi';
import NewEmployee from 'src/features/employees/view/new-employee/NewEmployee';
import { useBoolean } from 'src/lib/hooks/use-boolean';
import WorkbaseDialogComponent from 'src/lib/components/molecules/dialog/Dialog';
import { UUID } from 'uuidjs';
import ProgressWithContent from 'src/ui-components/custom/progress-with-content/ProgressWithContent';
import EmployeeAvatar from 'src/ui-components/utils/employee-avatar/EmployeeAvatar';
import { Employee, EmployeeStatuses } from '../model';
import EmployeeDetails from './employee-detials';
import Subtext from './Subtext';
import { SaveCancelChangesButtons } from './employee-detials/SaveCancelChangesButtons';

const findDifferences = <T extends object>(
  base: T,
  modified: Partial<T> | null | undefined
): Partial<T> => {
  if (!modified) return {}; // Return an empty object if modified is null or undefined

  // Filter out keys with matching values
  const differences = Object.entries(modified)
    .filter(([key, value]) => value !== base[key as keyof T])
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  return differences;
};

export default function Employees() {
  const { t } = useTranslation();
  const {
    data,
    isLoading,
    isSuccess,
    refetch: employeesRefetch,
  } = useEmployeesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { data: progressData } = useEmployeesTrainingProgressQuery();
  const location = useLocation();
  const navigate = useNavigate();
  const screenWidth = useScreenWidth();
  const isEditPath = location.pathname.includes('/edit');
  const { data: currentUserData, refetch: currentUserRefetch } = useCurrentUserQuery();
  const employeeDraft = useAppSelector((state) => state.employee.employee);
  const { pathname } = useLocation();
  const { data: userPermissions } = useUserPermissionsQuery();
  const hasManageEmployeePermission = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.MANAGE_EMPLOYEES
  );
  const hasViewProgressPermission = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.VIEW_EMPLOYEES_DETAILED_TRAINING_PROGRESS
  );
  const hasEditingPermissions = currentUserData?.isOwner;
  const pathArray = pathname.split('/').filter(Boolean);
  const idIndex = pathArray.indexOf('team') + 1;
  const id = idIndex !== 0 ? pathArray[idIndex] : '';

  const newEmployeeDialogShow = useBoolean();
  const headerIcon = {
    text: t('sidebar.tabTitleAddButton'),
    icon: WorkbaseShareIcon,
    onClick: () => newEmployeeDialogShow.onTrue(),
  };

  const readOnlyCondition = !hasEditingPermissions
    ? !isEditPath || !hasManageEmployeePermission
    : !isEditPath || !hasEditingPermissions;

  const currentEmployee = isSuccess ? data?.find((emp) => emp.id === id) : undefined;

  const changedData: Partial<Employee> | null =
    currentEmployee && employeeDraft ? findDifferences(currentEmployee, employeeDraft) : null;

  const { showModal, handleConfirm, handleCancel } = useOnLeaveViewBlocker(
    !!(isEditPath && changedData && Object.keys(changedData).length !== 0)
  );

  if (isLoading)
    return (
      <CircularProgress
        sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
      />
    );

  if (!data) return null;

  const employeeItems = data.map((employee) => {
    const employeeProgress = progressData?.find((prog) => prog.id === employee.id);
    const progressValue = employeeProgress ? Number(employeeProgress.progress) : 0;
    const progressComponent = (
      <ProgressWithContent
        circularProgressProps={{
          variant: 'determinate',
          value: progressValue % 100,
          color: 'success',
        }}
      >
        {progressValue === 100 ? (
          <SvgIcon component={WorkbaseCheckmarkIcon} fontSize="small" color="success" />
        ) : (
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${progressValue}%`}</Typography>
        )}
      </ProgressWithContent>
    );

    return {
      key: employee.id,
      text:
        employee.firstName || employee.lastName
          ? `${employee.firstName} ${employee.lastName}`
          : employee.email,
      subtext: <Subtext isOwner={employee.isOwner} roles={employee.roles} />,
      prefix: (
        <ListItemAvatar>
          <EmployeeAvatar avatarProps={{ src: employee.avatarUrl }} />
        </ListItemAvatar>
      ),
      sufix:
        (hasManageEmployeePermission || hasViewProgressPermission) &&
        (employee.status !== EmployeeStatuses.invited ? (
          progressComponent
        ) : (
          <ProgressWithContent
            circularProgressProps={{
              variant: 'determinate',
              value: 100,
              color: 'secondary',
            }}
          >
            <SvgIcon component={WorkbaseEmailIcon} fontSize="small" color="secondary" />
          </ProgressWithContent>
        )),
      onClick: () => navigate(employee.id, { relative: 'route' }),
    };
  });

  const employeePath =
    !data || data.length === 0 ? `/` : `/team/${currentEmployee?.id ?? data[0].id}`;
  const renderEmployeeDetails = (
    <>
      <EmployeeDetails
        employee={currentEmployee}
        readOnly={readOnlyCondition}
        employeesRefetch={employeesRefetch}
        hasManageEmployeePermission={!!hasManageEmployeePermission}
        hasViewProgressPermission={!!hasViewProgressPermission}
        progressData={progressData}
      />
      {(hasEditingPermissions || hasManageEmployeePermission) && (
        <SaveCancelChangesButtons
          isEditPath={isEditPath}
          currentEmployee={currentEmployee}
          changedData={changedData}
          currentUserRefetch={currentUserRefetch}
          id={id}
        />
      )}
    </>
  );

  return (
    <>
      <WorkbaseDialogComponent
        title={t('areYouSure')}
        open={showModal}
        onClose={handleCancel}
        titleIcon={{ icon: WorkbaseErrorIcon, color: 'red.main' }}
        confirmButton={{
          text: t('proceed'),
          onClick: () => {
            handleConfirm();
          },
          type: 'error',
        }}
      >
        <Typography variant="body1">{t('changesMayNotBeSaved')}</Typography>
      </WorkbaseDialogComponent>
      {(hasEditingPermissions || hasManageEmployeePermission) && (
        <NewEmployee
          open={newEmployeeDialogShow.value}
          onClose={newEmployeeDialogShow.onFalse}
          key={UUID.generate()}
        />
      )}
      <Stack direction="row" sx={{ height: '100%' }}>
        <PortalComponent elementSelector="#main-layout-content-card">
          <SecondarySidebar
            items={employeeItems}
            dataCy="employees-sidebar"
            activeKey={id}
            {...(hasManageEmployeePermission && { headerIcon })}
          />
        </PortalComponent>
        <Box
          sx={{
            paddingLeft: screenWidth > 1520 ? '432px' : '332px',
            pb: 2,
            flexGrow: 1,
            overflowY: 'auto',
          }}
        >
          <Routes>
            <Route path="*" element={<Navigate to={employeePath} replace />} />
            <Route path=":id" element={renderEmployeeDetails} />
            <Route path=":id/edit" element={renderEmployeeDetails} />
          </Routes>
        </Box>
      </Stack>
    </>
  );
}
