import Box from '@mui/material/Box';
import { useDropzone } from 'react-dropzone';
import { Button, CircularProgress, SxProps, alpha } from '@mui/material';
import {
  WorkbaseDeleteSquareIcon,
  WorkbaseEditIcon,
  WorkbaseLinksIcon,
} from '../../../../assets/icons/workbaseIcons';
import { WorkbaseIcon } from '../../atoms/icon/WorkbaseIcon';
import WorkbaseImage from '../../atoms/image/Image';
import { allowedFileTypes } from '../../organisms/editor/upload-item/config';
import FaviconEditor from './FaviconEditor';
import { linkIconBoxStyle, actionIconBoxStyles, linkIconStyle, actionIconStyles } from './styles';

export interface WorkbaseLinkProps {
  id?: string;
  imageUrl?: string;
  children?: React.ReactNode;
  href?: string;
  fullWidth?: boolean;
  isLoading?: boolean;
  editable?: boolean;
  onRemove?: () => void;
  onEdit?: () => void;
  sx?: SxProps;
  onFaviconChange?: (e: any) => void;
  onRemoveFavicon?: () => void;
}

function WorkbaseLink({
  children,
  href,
  imageUrl,
  fullWidth,
  editable,
  onRemove,
  onEdit,
  sx,
  onFaviconChange,
  onRemoveFavicon,
  isLoading,
}: WorkbaseLinkProps) {
  const handleDrop = async (acceptedFiles: File[]) => {
    onFaviconChange?.(acceptedFiles[0]);
  };

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    noClick: true,
    accept: {
      ...allowedFileTypes.image,
    },
  });

  const link = (
    <Button
      href={href || ''}
      target="_blank"
      fullWidth={fullWidth}
      sx={{
        minWidth: 200,
        ...sx,
      }}
    >
      <Box sx={(theme) => linkIconBoxStyle(theme)}>
        {isLoading && <CircularProgress style={{ width: 15, height: 15 }} />}
        {!!imageUrl && !isLoading && <WorkbaseImage src={imageUrl} alt="favicon" />}
        {!imageUrl && !isLoading && (
          <WorkbaseIcon icon={WorkbaseLinksIcon} color={linkIconStyle.color} sx={linkIconStyle} />
        )}
      </Box>
      {children}
    </Button>
  );

  if (!editable) return link;

  return (
    <Box
      sx={{
        position: 'relative',
        flexGrow: 1,
        opacity: isDragActive ? 0.7 : 1,
      }}
      maxWidth="100%"
      {...getRootProps()}
    >
      {link}
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          cursor: 'pointer',
          transition: 'background-color 0.25s ease-out',
          borderRadius: (theme) => theme.shape.borderRadius * 2.5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
          '&:hover': {
            backgroundColor: (theme) => alpha(theme.palette.common.black, 0.85),
            '& > *': {
              opacity: '1',
              transition: 'opacity 0.25s ease-in-out',
            },
          },
        }}
      >
        <Box sx={actionIconBoxStyles} onClick={onRemove}>
          <WorkbaseIcon
            icon={WorkbaseDeleteSquareIcon}
            color={actionIconStyles.color}
            sx={actionIconStyles}
          />
        </Box>
        <Box sx={actionIconBoxStyles} onClick={onEdit}>
          <WorkbaseIcon
            icon={WorkbaseEditIcon}
            color={actionIconStyles.color}
            sx={actionIconStyles}
          />
        </Box>
        <FaviconEditor
          imageUrl={imageUrl}
          onRemoveFavicon={onRemoveFavicon}
          getInputProps={getInputProps}
          onFaviconChange={onFaviconChange}
        />
      </Box>
    </Box>
  );
}

export default WorkbaseLink;
