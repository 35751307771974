import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';
import { PermissionsType } from '../model/types';

export const permissionsApi = createApi({
  reducerPath: 'permissions',
  baseQuery,
  endpoints: (builder) => ({
    permissions: builder.query<PermissionsType, void>({
      query: () => '/authz/permission-groups',
    }),
  }),
});

export const { usePermissionsQuery } = permissionsApi;
