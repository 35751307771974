import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseCheckmarkBigFilledIcon } from 'src/assets/icons/workbaseIcons';
import { WorkbaseIcon } from 'src/lib/components/atoms/icon';

export default function EmptyContent() {
  const { t } = useTranslation();
  return (
    <Stack alignItems="center" justifyContent="center" sx={{ height: 200 }}>
      <WorkbaseIcon
        icon={WorkbaseCheckmarkBigFilledIcon}
        sx={{ marginBottom: 1 }}
        size={50}
        color="success.main"
      />
      <Typography variant="body1" sx={{ fontWeight: 700 }}>
        {t('dashboardPage.header.message')}
      </Typography>
    </Stack>
  );
}
