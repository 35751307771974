import { useState } from 'react';
import validateEmail from 'src/lib/utils/validateEmail';
import validateUrl from 'src/lib/utils/validateUrl';
import { AdminNotificationPreferenceKeys } from 'src/store/api/settingsApi';

interface Props {
  email: string;
  isEmailEnabled: boolean;
  webhookUrl: string;
  isWebhookEnabled: boolean;
  patchSettings: any;
}

export default function useRecommendationForm({
  email,
  webhookUrl,
  isEmailEnabled,
  isWebhookEnabled,
  patchSettings,
}: Props) {
  const [emailValue, setEmailValue] = useState(email);
  const [webhookValue, setWebhookValue] = useState(webhookUrl);
  const [emailEnabled, setEmailEnabled] = useState(isEmailEnabled);
  const [webhookEnabled, setWebhookEnabled] = useState(isWebhookEnabled);
  const showErrorEmailMessage =
    (emailValue !== '' && !validateEmail(emailValue)) || (emailEnabled && emailValue === '');
  const showErrorWebhookMessage =
    (webhookValue !== '' && !validateUrl(webhookValue)) || (webhookEnabled && webhookValue === '');

  const handleBlur = ({
    valKey,
    val,
    defaultVal,
    enabledKey,
    defaultEnable,
    validateFn,
    enable,
  }: {
    valKey: AdminNotificationPreferenceKeys;
    val: string;
    defaultVal: string;
    enabledKey: AdminNotificationPreferenceKeys;
    defaultEnable: boolean;
    validateFn: (val: string) => boolean;
    enable: boolean;
  }) => {
    const currentEnable = val === '' ? false : enable;

    if (val !== defaultVal && (validateFn(val) || val === '')) {
      if (currentEnable !== defaultEnable) {
        patchSettings({ [valKey]: val, [enabledKey]: currentEnable });
      } else {
        patchSettings({ [valKey]: val });
      }
    }
  };

  const handleChecked = ({
    val,
    enable,
    defaultEnable,
    enabledKey,
  }: {
    val: string;
    enable: boolean;
    defaultEnable: boolean;
    enabledKey: AdminNotificationPreferenceKeys;
  }) => {
    const currentEnable = val === '' ? false : enable;
    if (currentEnable !== defaultEnable) {
      patchSettings({ [enabledKey]: currentEnable });
    }
  };

  return {
    showErrorEmailMessage,
    showErrorWebhookMessage,
    emailEnabled,
    setEmailEnabled,
    emailValue,
    setEmailValue,
    webhookEnabled,
    setWebhookEnabled,
    validateEmail,
    webhookValue,
    setWebhookValue,
    handleBlur,
    handleChecked,
  };
}
