import { Card, Divider, Stack, Box } from '@mui/material';
import { useState } from 'react';
import BaseActions, { BaseActionsProps } from './BaseActions';
import TextMenu from './text-menu';
import UploadMenu, { UploadActionsProps } from './UploadActions';
import InfoActions, { InfoActionsProps } from './InfoActions';
import EmbeddedActions, { EmbeddedActionsProps } from './EmbeddedActions';
import CaptionActions, { CaptionActionsProps } from './CaptionActions';
import AIActions, { AIActionsProps } from './AIActions';
import ImageActions, { ImageActionsProps } from './ImageActions';
import TrainingsActions, { TrainingsActionsProps } from './tranings-actions/TrainingsActions';
import VideoActions, { VideoActionsProps } from './VideoActions';
import TextSelectionLinkInput from './TextSelectionLinkInput';
import LinkInput from './LinkInput';

export interface EditBarProps {
  baseActionsProps: BaseActionsProps;
  imageActionsProps?: Omit<ImageActionsProps, 'onLinkIconClick'>;
  videoActionsProps?: VideoActionsProps;
  textActionsProps?: {
    textAllignmentHidden?: boolean;
  };
  uploadActionsProps?: UploadActionsProps;
  infoActionsProps?: InfoActionsProps;
  embeddedActionsProps?: EmbeddedActionsProps;
  captionActionsProps?: CaptionActionsProps;
  aIActionsProps?: AIActionsProps;
  trainingsActionsProps?: TrainingsActionsProps;
  hrefProps?: HrefProps;
}

export interface HrefProps {
  onChange: (href: string) => void;
  href?: string;
}

const divider = (
  <Box>
    <Divider orientation="vertical" sx={{ marginTop: '-4px', height: 'calc(100% + 8px)' }} />
  </Box>
);

export default function EditBar({
  baseActionsProps,
  imageActionsProps,
  videoActionsProps,
  textActionsProps,
  uploadActionsProps,
  infoActionsProps,
  embeddedActionsProps,
  captionActionsProps,
  aIActionsProps,
  trainingsActionsProps,
  hrefProps,
}: EditBarProps) {
  const [linkInputVisible, setLinkInputVisible] = useState(false);

  const handleShowLinkInput = () => setLinkInputVisible(true);
  const handleHideLinkInput = () => setLinkInputVisible(false);

  const actions = (
    <>
      {aIActionsProps && (
        <>
          <AIActions {...aIActionsProps} />
          {divider}
        </>
      )}
      {trainingsActionsProps && (
        <>
          <TrainingsActions {...trainingsActionsProps} />
          {divider}
        </>
      )}

      {textActionsProps && (
        <>
          <TextMenu
            {...textActionsProps}
            onLinkIconClick={handleShowLinkInput}
            linkIconAlwaysClickable={!!hrefProps}
          />
          {divider}
        </>
      )}
      {imageActionsProps && (
        <>
          <ImageActions {...imageActionsProps} onLinkIconClick={handleShowLinkInput} />
          {divider}
        </>
      )}
      {videoActionsProps && (
        <>
          <VideoActions {...videoActionsProps} />
          {divider}
        </>
      )}
      {uploadActionsProps && (
        <>
          <UploadMenu {...uploadActionsProps} />
          {divider}
        </>
      )}
      {infoActionsProps && (
        <>
          <InfoActions {...infoActionsProps} />
          {divider}
        </>
      )}
      {embeddedActionsProps && (
        <>
          <EmbeddedActions {...embeddedActionsProps} />
          {divider}
        </>
      )}
      {captionActionsProps && (
        <>
          <CaptionActions {...captionActionsProps} />
          {divider}
        </>
      )}
      <BaseActions {...baseActionsProps} />
    </>
  );

  let linkInput = null;

  if (hrefProps || textActionsProps) {
    linkInput = hrefProps ? (
      <LinkInput
        onRemove={handleHideLinkInput}
        onApply={hrefProps.onChange}
        onClear={() => hrefProps.onChange('')}
        value={hrefProps.href || ''}
      />
    ) : (
      <TextSelectionLinkInput onRemove={handleHideLinkInput} />
    );
  }

  return (
    <Card
      className="edit-bar"
      sx={{
        position: 'absolute',
        left: 0,
        bottom: '100%',
        paddingLeft: '15px',
        paddingRight: '15px',
        transform: 'translateY(-8px)',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'grey.300',
        zIndex: 10,
        boxShadow: 'none',
        borderRadius: (theme) => theme.shape.borderRadius * 2.5,
      }}
      onMouseDown={(e) => e.preventDefault()}
    >
      <Stack direction="row" gap="8px">
        {!linkInputVisible && actions}
        {linkInputVisible && linkInput}
      </Stack>
    </Card>
  );
}
