import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import WorkbaseEditButton from 'src/lib/components/molecules/edit-button/EditButton';

interface Props {
  children: (isEditMode: boolean) => JSX.Element;
  switchButtonVisible?: boolean;
  switchButtonAdditionalStyles?: (isEditPath: boolean) => object;
}

function ModeSwitch({ children, switchButtonVisible = true, switchButtonAdditionalStyles }: Props) {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const isEditPath = pathname.endsWith('/edit');

  const editButtonTexts = {
    text: isEditPath ? t('editButton.finish') : t('editButton.edit'),
    path: isEditPath ? pathname.replace('/edit', '/') : './edit',
  };

  return (
    <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
      {children(isEditPath)}
      {switchButtonVisible && (
        <WorkbaseEditButton
          {...editButtonTexts}
          additionalEditButtonStyle={switchButtonAdditionalStyles?.(isEditPath)}
          editMode={isEditPath}
        />
      )}
    </Box>
  );
}

export default ModeSwitch;
