import { Box, alpha, useTheme } from '@mui/material';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import BaseEditor from '../base-editor/BaseEditor';
import HighlightItem from '../highlight-item';
import { ResizeStopCallbackType } from '../highlight-item/resize/ResizableWrapper';

export interface ButtonProps {
  value?: string;
  onDelete: () => void;
  onDuplicate: () => void;
  onChange?: (val: string) => void;
  readOnly?: boolean;
  onResizeStop?: ResizeStopCallbackType;
  dragging?: {
    dragRef: ConnectDragSource;
    previewRef: ConnectDragPreview;
  };
  onResizeStart?: () => void;
  href?: string;
  onHrefChange: (href: string) => void;
}

export default function Button({
  onDelete,
  onDuplicate,
  value,
  readOnly = false,
  onChange,
  onResizeStop,
  dragging,
  onResizeStart,
  onHrefChange,
  href,
}: ButtonProps) {
  const theme = useTheme();

  const handleClick = () => {
    if (href && readOnly) {
      window.open(href, '_blank');
    }
  };

  return (
    <HighlightItem
      bordered={false}
      editBarProps={{
        baseActionsProps: { onDelete, onDuplicate },
        textActionsProps: {},
        hrefProps: {
          href,
          onChange: onHrefChange,
        },
      }}
      sx={{
        backgroundColor: 'primary.main',
        transition: '0.15s',
        '&:hover': {
          backgroundColor:
            href && readOnly ? alpha(theme.palette.primary.main, 0.9) : theme.palette.primary.main,
        },
        padding: 0.5,
      }}
      horizontalResize={{}}
      readOnly={readOnly}
      onResizeStop={onResizeStop}
      dragging={dragging}
      onResizeStart={onResizeStart}
    >
      {({ EditBarComponent, focused }) => (
        <Box
          onClick={handleClick}
          sx={{
            cursor: href && readOnly ? 'pointer' : 'auto',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderRadius: theme.shape.borderRadius,
            borderColor: 'transparent',
            transition: '.3s',
            '&:hover': readOnly
              ? { borderColor: 'transparent' }
              : { borderColor: focused ? 'transparent' : 'common.white' },
          }}
        >
          <BaseEditor
            value={value}
            textColor={theme.palette.common.white}
            autoLink={false}
            readOnly={readOnly}
            onChange={onChange}
          >
            {EditBarComponent}
          </BaseEditor>
        </Box>
      )}
    </HighlightItem>
  );
}
