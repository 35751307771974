import {
  WorkbaseResetIcon,
  WorkbasePauseIcon,
  WorkbasePlayIcon,
} from 'src/assets/icons/workbaseIcons';
import { IconButton } from '@mui/material';
import { WorkbaseIcon } from 'src/lib/components/atoms/icon';

function PlayButton({
  isPlaying,
  isEnded,
  onClick,
  smallVariant,
}: {
  isPlaying: boolean;
  isEnded: boolean;
  smallVariant: boolean;
  onClick: () => void;
}) {
  let icon;

  if (isEnded) {
    icon = WorkbaseResetIcon;
  } else if (isPlaying) {
    icon = WorkbasePauseIcon;
  } else {
    icon = WorkbasePlayIcon;
  }

  return (
    <IconButton onClick={onClick} size={smallVariant ? 'small' : 'medium'}>
      <WorkbaseIcon icon={icon} />
    </IconButton>
  );
}

export default PlayButton;
