import { keyframes } from '@mui/material';
import WorkbaseAccordion from 'src/lib/components/molecules/сollapsible';
import { TrainingSectionStatus } from 'src/store/api/trainings/trainingSectionsApi';
import { TrainingLesson } from 'src/store/api/trainings/trainingLessonsApi';
import { useEffect, useState } from 'react';
import Lessons from './Lessons';
import SectionTitle from './SectionTitle';

const flickerEffect = (theme: any) => keyframes`
0%, 100% { background-color: ${theme.palette.background.hover}; }
50% { background-color: ${theme.palette.grey[100]}; }
`;

const detailsAdditionalStyles = {
  '&&&.MuiAccordionDetails-root': {
    padding: '0px 20px',
  },
};

interface Props {
  id: string;
  name: string;
  status: TrainingSectionStatus;
  trainingModuleId: string;
  readOnly: boolean;
  icon: string;
  totalEstimatedLessonsTime: number;
  mobileView: boolean;
  filteredLessons: TrainingLesson[];
  isCurrentSectionLessonActive: boolean;
  onAddLessonCallback?: (lessonId: string) => void;
}

export default function SectionAccordion({
  id,
  mobileView,
  readOnly,
  name,
  trainingModuleId,
  status,
  icon,
  filteredLessons,
  isCurrentSectionLessonActive,
  totalEstimatedLessonsTime,
  onAddLessonCallback,
}: Props) {
  const [expanded, setExpanded] = useState(true);
  const [isOver, setIsOver] = useState(false);
  const showFlicker = isOver && !expanded;
  useEffect(() => {
    if (showFlicker) {
      const timeoutId = setTimeout(() => {
        if (isOver) {
          setExpanded(true);
        }
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
    return () => {};
  }, [showFlicker, isOver]);
  return (
    <WorkbaseAccordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      detailsAdditionalStyles={detailsAdditionalStyles}
      sx={{
        ...(showFlicker && {
          '>.MuiPaper-root.MuiAccordion-root': {
            transition: 'background-color 0.2s',
            animation: (theme) => `${flickerEffect(theme)} 0.25s infinite 0.5s`,
          },
        }),
        ...(mobileView
          ? {
              borderBottomStyle: 'solid',
              borderWidth: 1,
              borderColor: 'grey.300',
              paddingBottom: 0.5,
              marginTop: '5px !important',
              '& .MuiPaper-root': {
                backgroundColor: 'transparent !important',
                border: 'none !important',
              },
              '&:last-child': {
                borderBottomStyle: 'none',
              },
            }
          : {
              '& .MuiAccordionSummary-content': {
                width: '100%',
              },
            }),
      }}
      title={
        <SectionTitle
          sectionName={name}
          trainingModuleId={trainingModuleId}
          readOnly={readOnly}
          lessonsCount={filteredLessons?.length}
          sectionId={id}
          sectionStatus={status}
          mobileView={mobileView}
          icon={icon}
          isCurrentSectionLessonActive={isCurrentSectionLessonActive}
          totalEstimatedLessonsTime={totalEstimatedLessonsTime}
          setIsOver={setIsOver}
        />
      }
    >
      <Lessons
        readOnly={readOnly}
        id={id}
        filteredLessons={filteredLessons}
        mobileView={mobileView}
        onAddLessonCallback={onAddLessonCallback}
      />
    </WorkbaseAccordion>
  );
}
