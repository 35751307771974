import { Box, Stack } from '@mui/material';

export interface TopBarLayoutProps {
  topBar: React.ReactNode;
  children: React.ReactNode;
}

export default function TopBarLayout({ topBar, children }: TopBarLayoutProps) {
  return (
    <Stack maxHeight="100%" overflow="hidden" position="relative">
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
        }}
      >
        {topBar}
      </Box>
      <Box
        flexGrow={1}
        padding={2}
        mt={5}
        sx={{
          overflowY: 'auto',
        }}
      >
        {children}
      </Box>
    </Stack>
  );
}
