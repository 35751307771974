import {
  Box,
  BoxProps,
  CircularProgress,
  CircularProgressProps,
  Stack,
  Typography,
  alpha,
  styled,
  useTheme,
} from '@mui/material';
import { WorkbaseCheckmarkIcon } from 'src/assets/icons/workbaseIcons';
import { WorkbaseIcon } from '../icon';

export interface WorkbaseCircularProgressProps
  extends Omit<CircularProgressProps, 'size' | 'color' | 'variant'> {
  label?: string | React.ReactNode;
  size?: 'medium' | 'big';
  variant?: 'default' | 'dark' | 'white';
  dataCy?: string;
}

const CircularProgressWrapper = styled(Box)<BoxProps>`
  position: relative;
  display: inline-flex;
  width: fit-content;
`;

const CircularProgressBackground = styled(CircularProgress)<CircularProgressProps>`
  position: absolute;
  pointer-events: none;
`;

const CustomBox = styled(Box)<BoxProps>`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function WorkbaseCircularProgress({
  label,
  value,
  size = 'medium',
  sx,
  className,
  variant = 'default',
  dataCy,
  ...props
}: WorkbaseCircularProgressProps) {
  const theme = useTheme();
  const sizeDict = {
    medium: {
      size: 38,
      fontSize: '12px',
      iconSize: 12,
    },
    big: {
      size: 60,
      fontSize: '15px',
      iconSize: 20,
    },
  };
  const variantDict = {
    default: {
      backgroundColor: 'grey.300',
      color: 'success.main',
      fontColor: value === 0 ? 'text.secondary' : 'success.main',
    },
    dark: {
      backgroundColor: 'tertiary.main',
      color: 'green.light',
      fontColor: 'green.light',
    },
    white: {
      backgroundColor: alpha(theme.palette.grey[500], 0.5),
      color: 'common.white',
      fontColor: 'common.white',
    },
  };

  if (value === 100) {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          display: 'inline-flex',
          padding: '13px',
          borderRadius: '50%',
          backgroundColor: 'green.light',
          minWidth: sizeDict[size].size,
          width: sizeDict[size].size,
          height: sizeDict[size].size,
          ...sx,
        }}
      >
        <WorkbaseIcon
          data-cy={dataCy && `${dataCy}-progress-success`}
          icon={WorkbaseCheckmarkIcon}
          color="success"
          sx={{
            width: sizeDict[size].iconSize,
            height: sizeDict[size].iconSize,
            '&&&': { color: `${theme.palette.success.main} !important` },
          }}
        />
      </Stack>
    );
  }

  return (
    <CircularProgressWrapper sx={sx} className={className}>
      <CircularProgressBackground
        value={100}
        variant="determinate"
        size={sizeDict[size].size}
        thickness={2.8}
        sx={{
          color: variantDict[variant].backgroundColor,
          '.MuiCircularProgress-circle': { r: '20.2' },
        }}
      />
      <CircularProgress
        data-cy={dataCy && `${dataCy}-progress`}
        value={value}
        size={sizeDict[size].size}
        sx={{ color: variantDict[variant].color, strokeLinecap: 'round' }}
        variant="determinate"
        {...props}
      />

      <CustomBox>
        <Typography
          sx={{
            lineHeight: 0,
            fontSize: sizeDict[size].fontSize,
            fontWeight: 500,
            color: variantDict[variant].fontColor,
          }}
          component="span"
        >
          {label || `${value}%`}
        </Typography>
      </CustomBox>
    </CircularProgressWrapper>
  );
}

WorkbaseCircularProgress.defaultProps = {
  label: undefined,
};
