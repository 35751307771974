import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BrandedDefaultImage from 'src/lib/components/atoms/branded-default-image/BrandedDefaultImage';
import WorkbaseImage from 'src/lib/components/atoms/image';
import { WorkbaseCircularProgress } from 'src/lib/components/atoms/progress/CircularProgress';

import { TrainingModule } from 'src/store/api/trainings/trainingModulesApi';

export default function ReadOnlyContent({
  trainingModule,
  mobileView,
  progress,
}: {
  trainingModule: TrainingModule;
  mobileView: boolean;
  progress: number;
}) {
  const { t } = useTranslation();
  return (
    <>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          order: mobileView ? 1 : 0,
          gap: mobileView ? 1.5 : 0,
          justifyContent: mobileView ? 'space-between' : 'flex-start',
          width: mobileView ? '100%' : 'auto',
          minWidth: 0,
        }}
      >
        <WorkbaseCircularProgress
          value={progress}
          size="big"
          sx={{
            order: mobileView ? 1 : 0,
            '&&&&': { color: 'text.secondary', minWidth: '60px' },
          }}
        />
        <Stack
          sx={{
            flexDirection: 'column',
            gap: mobileView ? 0.5 : 0,
            ml: mobileView ? 0 : '15.5px',
            minWidth: 0,
          }}
        >
          <Stack sx={{ flexDirection: 'row', alignItems: 'center', color: 'text.primary' }}>
            <Typography variant={mobileView ? 'h4' : 'h1'} noWrap>
              {mobileView && `${t('modulePage.moduleTitle')} ${trainingModule.order}:`}{' '}
              {trainingModule.name || t('trainingDetailPage.unnamedModule')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ width: mobileView ? '100%' : 'auto', mb: mobileView ? 2.25 : 0 }}>
        <Stack
          sx={{
            borderRadius: 1,
            borderColor: 'grey.300',
            borderWidth: 1,
            borderStyle: 'solid',
            width: mobileView ? '100%' : '290px',
            overflow: 'hidden',
            height: '160px',
          }}
        >
          {trainingModule.imageUrl ? (
            <WorkbaseImage
              height="160px"
              width={mobileView ? '100%' : '290px'}
              src={trainingModule.imageUrl || ''}
              alt=""
              style={{
                objectFit: 'cover',
                objectPosition: 'center',
                transition: '0.15s',
                width: '100%',
              }}
            />
          ) : (
            <BrandedDefaultImage
              name={trainingModule.defaultImage}
              className="default-svg"
              sx={{
                transition: '0.15s',
              }}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
}
