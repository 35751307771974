import { Resizable } from 're-resizable';
import { Direction } from 're-resizable/lib/resizer';
import { CSSProperties, useRef, useState } from 'react';
import {
  HorizontalResizeDot,
  VerticalBottomLeftResizeDot,
  VerticalBottomRightResizeDot,
  VerticalResizeDot,
} from './ResizeDot';

const noOfColumns = 4;

const sizeDifferenceFromResizeWrapper = 4;

const editorItemMinWidthOffset = 30;

const defaultIndicatorEnabled = {
  top: false,
  right: false,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
};

export type ColumnType = 1 | 2 | 3 | 4;

interface ColumnResize {
  column: ColumnType;
  direction: Direction;
  height?: number;
  aspectRatio?: number;
}

export type ResizeStopCallbackType = (resize: ColumnResize) => void;

interface ResizableWrapperProps {
  children: React.ReactNode;
  minHeight?: number;
  readOnly?: boolean;
  showHorizontalResize?: boolean;
  showVerticalResize?: boolean;
  onResizeStop?: ResizeStopCallbackType;
  onResizeStart?: () => void;
  setHovered: (val: boolean) => void;
  setColumnsCount: (val: number) => void;
}

function ResizableWrapper({
  children,
  minHeight = 30,
  readOnly,
  showHorizontalResize,
  showVerticalResize,
  onResizeStop,
  onResizeStart,
  setHovered,
  setColumnsCount,
}: ResizableWrapperProps) {
  const ref = useRef<Resizable>(null);
  const [resizeDirection, setResizeDirection] = useState<Direction | null>(null);

  const aspectRatioRef = useRef(NaN);
  const widthRef = useRef(0);
  const maxWidthRef = useRef(NaN);

  if (readOnly) return children;

  const resizeableStyle: CSSProperties =
    resizeDirection === 'left' || resizeDirection === 'bottomLeft' ? { float: 'right' } : {};

  const rowElement = ref.current?.parentNode?.parentElement || undefined;

  const minWidth = rowElement?.offsetWidth
    ? rowElement.offsetWidth / noOfColumns - editorItemMinWidthOffset
    : undefined;

  const CalculatedMininHeight = resizeDirection?.includes('bottom')
    ? minHeight + sizeDifferenceFromResizeWrapper
    : undefined;
  const maxRowWidth = rowElement?.offsetWidth;
  const maxColumnWidth = maxRowWidth! / noOfColumns;
  // eslint-disable-next-line max-statements
  const defineColumns = (resizingWrapperElement: HTMLElement, direction: Direction) => {
    let column: ColumnType = 1;

    const rowClient = rowElement?.getBoundingClientRect();
    const rowStart = rowClient?.left || 0;
    const rowEnd = rowClient?.right || 0;
    const rowSize = rowEnd - rowStart;
    const singleColumnSize = rowSize / noOfColumns;

    const firstColumnStart = rowStart;
    const firstColumnEnd = rowStart + singleColumnSize;
    const firstColumnMiddle = (firstColumnEnd + firstColumnStart) / 2;

    const secondColumnStart = firstColumnEnd;
    const secondColumnEnd = firstColumnEnd + singleColumnSize;
    const secondColumnMiddle = (secondColumnEnd + secondColumnStart) / 2;

    const thirdColumnStart = secondColumnEnd;
    const thirdColumnEnd = secondColumnEnd + singleColumnSize;
    const thirdColumnMiddle = (thirdColumnEnd + thirdColumnStart) / 2;

    const fourthColumnStart = thirdColumnEnd;
    const fourthColumnEnd = thirdColumnEnd + singleColumnSize;
    const fourthColumnMiddle = (fourthColumnEnd + fourthColumnStart) / 2;
    const resizingFromLeft = direction === 'left' || direction === 'bottomLeft';
    const resizingFromRight = direction === 'right' || direction === 'bottomRight';

    const { left, right } = resizingWrapperElement.getBoundingClientRect();
    if (
      (resizingFromLeft && left <= firstColumnMiddle) ||
      (resizingFromRight && right >= firstColumnMiddle && right <= secondColumnMiddle)
    ) {
      column = 1;
    }
    if (
      (resizingFromLeft && left <= secondColumnMiddle && left > firstColumnMiddle) ||
      (resizingFromRight && right >= secondColumnMiddle && right <= thirdColumnMiddle)
    ) {
      column = 2;
    }
    if (
      (resizingFromLeft && left <= thirdColumnMiddle && left > secondColumnMiddle) ||
      (resizingFromRight && right >= thirdColumnMiddle && right <= fourthColumnMiddle)
    ) {
      column = 3;
    }
    if (
      (resizingFromLeft && left <= fourthColumnEnd && left > thirdColumnMiddle) ||
      (resizingFromRight && right >= fourthColumnMiddle)
    ) {
      column = 4;
    }
    return column;
  };

  return (
    <Resizable
      ref={ref}
      style={{ ...resizeableStyle }}
      className={`resizable ${resizeDirection ? 'resizing' : ''}`}
      minWidth={minWidth}
      minHeight={CalculatedMininHeight}
      defaultSize={{ width: '100%', height: 'auto' }}
      bounds={rowElement}
      boundsByDirection
      onResizeStart={(...attrs) => {
        aspectRatioRef.current = attrs[2].clientWidth / attrs[2].clientHeight;
        widthRef.current = attrs[2].clientWidth;
        setResizeDirection(attrs[1]);

        const columnStart =
          attrs[1] === 'bottomRight'
            ? Math.round(
                (attrs[2].getBoundingClientRect().left - rowElement!.getBoundingClientRect().left) /
                  maxColumnWidth
              )
            : Math.round(
                (rowElement!.getBoundingClientRect().right -
                  attrs[2].getBoundingClientRect().right) /
                  maxColumnWidth
              );

        maxWidthRef.current = ((noOfColumns - columnStart) * maxRowWidth!) / noOfColumns;
        onResizeStart?.();
        setTimeout(() => setHovered(true));
      }}
      onResize={(...attrs) => {
        const [event, direction, resizingWrapperElement] = attrs;
        const innerResizableElement = resizingWrapperElement.querySelector<HTMLElement>(
          '.editor-vertical-resizable'
        );
        const column = defineColumns(resizingWrapperElement, direction);
        setColumnsCount(column);
        const resizingWrapperElementHeight = resizingWrapperElement?.clientHeight as number;

        const newInnerResizableElementHeight =
          resizingWrapperElementHeight - sizeDifferenceFromResizeWrapper;

        if (event instanceof MouseEvent)
          if (resizeDirection === 'bottomLeft' || resizeDirection === 'bottomRight') {
            let effectiveChange =
              Math.max(Math.abs(event.movementX), Math.abs(event.movementY)) ===
              Math.abs(event.movementX)
                ? event.movementX
                : event.movementY;

            effectiveChange =
              resizeDirection === 'bottomLeft' &&
              Math.max(Math.abs(event.movementX), Math.abs(event.movementY)) ===
                Math.abs(event.movementX)
                ? -1 * effectiveChange
                : effectiveChange;
            if (
              (effectiveChange > 0 ||
                (widthRef.current + effectiveChange >= minWidth! &&
                  widthRef.current / aspectRatioRef.current >= CalculatedMininHeight!)) &&
              (widthRef.current + effectiveChange <= maxWidthRef.current || effectiveChange < 0)
            )
              widthRef.current += effectiveChange;

            resizingWrapperElement?.style.setProperty(
              'height',
              `${widthRef.current / aspectRatioRef.current}px`
            );
            resizingWrapperElement?.style.setProperty('width', `${widthRef.current}px`);

            innerResizableElement?.style.setProperty(
              'height',
              `${
                (resizingWrapperElement?.clientHeight as number) - sizeDifferenceFromResizeWrapper
              }px`
            );
          } else if (resizeDirection === 'bottom') {
            innerResizableElement?.style.setProperty(
              'height',
              `${newInnerResizableElementHeight}px`
            );
            const embeddedBox = resizingWrapperElement?.querySelector('.embedded-box') as any;
            if (embeddedBox) {
              embeddedBox?.style.setProperty('height', `${newInnerResizableElementHeight}px`);
            }
          } else if (resizeDirection === 'left' || resizeDirection === 'right') {
            resizingWrapperElement?.style.setProperty('height', 'auto');
          }
      }}
      onResizeStop={(...attrs) => {
        const [, direction, resizingWrapperElement] = attrs;

        const column = defineColumns(resizingWrapperElement, direction);

        const resizingWrapperElementHeight = resizingWrapperElement?.clientHeight as number;
        const resizingWrapperElementWidth = resizingWrapperElement?.clientWidth as number;
        const innerResizableElementWidth =
          resizingWrapperElementWidth - sizeDifferenceFromResizeWrapper;
        const innerResizableElementHeight =
          resizingWrapperElementHeight - sizeDifferenceFromResizeWrapper;

        const aspectRatio = innerResizableElementWidth / innerResizableElementHeight;

        const resize: ColumnResize = {
          column,
          direction,
          height:
            direction !== 'bottomLeft' && direction !== 'bottomRight'
              ? innerResizableElementHeight
              : undefined,
          aspectRatio: aspectRatio ? +aspectRatio.toFixed(2) : aspectRatio,
        };

        onResizeStop?.(resize);
        if (direction === 'bottomLeft' || direction === 'bottomRight') {
          const innerResizableElement = resizingWrapperElement.querySelector<HTMLElement>(
            '.editor-vertical-resizable'
          );
          innerResizableElement?.style.setProperty('height', `auto`);
        }

        setResizeDirection(null);

        setTimeout(() => setHovered(false));

        ref.current?.updateSize({ width: '100%', height: 'auto' });
      }}
      enable={{
        ...defaultIndicatorEnabled,
        ...(showHorizontalResize && onResizeStart && { left: true, right: true }),
        ...(showVerticalResize &&
          onResizeStart && { bottomLeft: true, bottomRight: true, bottom: true }),
      }}
      handleComponent={{
        left: <HorizontalResizeDot setHovered={setHovered} />,
        right: <HorizontalResizeDot setHovered={setHovered} />,
        bottomLeft: <VerticalBottomLeftResizeDot setHovered={setHovered} />,
        bottomRight: <VerticalBottomRightResizeDot setHovered={setHovered} />,
        bottom: <VerticalResizeDot setHovered={setHovered} />,
      }}
      handleStyles={{
        left: {
          display: showHorizontalResize ? 'block' : 'none',
          position: 'absolute',
          top: '50%',
          transform: 'translate(-50%, -60%)',
          height: '10px',
          left: 0,
        },
        right: {
          display: showHorizontalResize ? 'block' : 'none',
          position: 'absolute',
          top: '50%',
          transform: 'translate(30%, -60%)',
          height: '10px',
          right: 0,
        },
        bottomLeft: {
          display: showVerticalResize ? 'block' : 'none',
          position: 'absolute',
          bottom: 0,
          transform: 'translate(-50%, 0%)',
          width: '10px',
          height: '10px',
          left: 0,
        },
        bottomRight: {
          display: showVerticalResize ? 'block' : 'none',
          position: 'absolute',
          bottom: 0,
          transform: 'translate(30%, 0%)',
          width: '10px',
          height: '10px',
          right: 0,
        },
        bottom: {
          display: showVerticalResize ? 'block' : 'none',
          position: 'absolute',
          bottom: 0,
          width: '10px',
          left: '50%',
          transform: 'translate(-50%, 40%)',
        },
      }}
    >
      {children}
    </Resizable>
  );
}

export default ResizableWrapper;
