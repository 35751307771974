import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { EditorData } from 'src/features/editor/model';
import { useInView } from 'react-intersection-observer';
import { useAppSelector } from 'src/store';
import { ShareLevel } from 'src/lib/types/share-level';
import Grid from 'src/features/editor/view/grid/Grid';
import {
  addVisibleIndex,
  removeVisibleIndex,
  setShareLevelFocused,
} from '../../controller/wikiCards.slice';
import WikiCardHeader from './WikiCardHeader';

interface WikiCardProps {
  gridData: EditorData;
  index: number;
  sharelevelData: ShareLevel;
}

export default function WikiCard({ gridData, sharelevelData, index }: WikiCardProps) {
  const dispatch = useDispatch();
  const { smallestIndex, forceIndex } = useAppSelector((state) => state.wikiCards);
  const focusedIndex = forceIndex ?? smallestIndex;
  const { inView: isVisible, ref: cardRef } = useInView({ rootMargin: '-80px 0px 100px 0px' });

  useEffect(() => {
    if (index === focusedIndex) dispatch(setShareLevelFocused(sharelevelData));
  }, [focusedIndex, index, sharelevelData, dispatch]);

  useEffect(() => {
    if (isVisible) {
      dispatch(addVisibleIndex(index));
    } else {
      dispatch(removeVisibleIndex(index));
    }
  }, [isVisible, index, dispatch]);

  return (
    <div ref={cardRef} style={{ minHeight: '280px' }}>
      <Stack
        gap={2}
        sx={{
          height: 'auto',
          overflow: 'auto',
          paddingTop: '30px',
          backgroundColor: 'white.main',
        }}
      >
        <WikiCardHeader readOnly readOnlyHeader={gridData.header} index={index} />
        <Grid readOnly grid={gridData.grid} />
      </Stack>
    </div>
  );
}
