import { Switch, alpha, styled } from '@mui/material';

export const WorkbaseSwitch = styled(Switch)(({ theme }) => ({
  width: '26px',
  height: '16px',
  padding: 0,
  borderRadius: theme.shape.borderRadius * 2.5,
  '&& .MuiSwitch-track': {
    backgroundColor: theme.palette.grey[500],
    opacity: 1,
  },
  '& .MuiSwitch-switchBase': {
    padding: 0,
    transform: 'translateX(2px)',
    '&.Mui-checked': {
      color: theme.palette.common.white,
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.success.main,
      },
    },
    ':hover': {
      backgroundColor: 'transparent',
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: `0px 1px 2px  0px ${alpha(theme.palette.common.black, 0.1)}`,
    marginTop: '2px',
    color: theme.palette.common.white,
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    transform: 'translateX(12px)',
  },
  '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
    opacity: 0.5,
  },
  '& .MuiSwitch-switchBase.Mui-disabled': {
    '& .MuiSwitch-thumb': {
      color: alpha(theme.palette.common.white, 0.75),
      boxShadow: 'none',
    },
  },
}));
