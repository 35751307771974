import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseHelpIcon } from 'src/assets/icons/workbaseIcons';
import { WorkbaseIcon, WorkbaseIconType } from '../../atoms/icon';

interface Props {
  text?: string;
  icon?: WorkbaseIconType;
}

export default function ItemNotFound({ text, icon }: Props) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        height: 158,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <WorkbaseIcon
          icon={icon || WorkbaseHelpIcon}
          sx={{ width: 50, height: 50, color: 'text.secondary' }}
        />
        <Typography
          variant="subtitle1"
          color="text.primary"
          sx={{
            pt: '6px',
          }}
        >
          {text || t('notFound')}
        </Typography>
      </Box>
    </Box>
  );
}
