import { Box, Link, useTheme } from '@mui/material';
import { APP_LOGO_HEIGHT } from 'src/layouts/layoutConstants';
import { useSettingsQuery } from 'src/store/api/settingsApi';

interface Props {
  fullWidth: boolean;
}

export default function AppLogo({ fullWidth }: Props) {
  const { data } = useSettingsQuery();
  const { palette } = useTheme();
  const logoData = {
    logo: palette.mode === 'dark' ? data?.siteLogoDarkUrl : data?.siteLogoUrl,
    favicon: palette.mode === 'dark' ? data?.siteFaviconDarkUrl : data?.siteFaviconUrl,
  };

  const src = fullWidth ? logoData.logo : logoData.favicon;

  if (!src) return null;

  return (
    <Link href="/" data-cy="app-logo">
      <Box height={APP_LOGO_HEIGHT} overflow="hidden" display="flex" minWidth={0} pl={2}>
        <Box
          component="img"
          src={src}
          maxWidth="100%"
          height="100%"
          width="auto"
          sx={{ objectFit: 'contain' }}
        />
      </Box>
    </Link>
  );
}
