import debounce from 'lodash.debounce';
import { useCallback, useEffect, useRef } from 'react';

const useDebouncedFunction = <T extends (...args: any[]) => any>(func: T, delay = 500) => {
  const funcRef = useRef(func);

  useEffect(() => {
    funcRef.current = func;
  }, [func]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFunc = useCallback(
    debounce((...args: Parameters<T>) => {
      funcRef.current(...args);
    }, delay),
    [delay]
  );

  useEffect(
    () => () => {
      debouncedFunc.cancel();
    },
    [debouncedFunc]
  );

  return debouncedFunc;
};

export default useDebouncedFunction;
