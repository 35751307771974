import { Chip, ChipProps } from '@mui/material';

type Status = 'success' | 'error' | 'default';

interface WorkbaseStatusChipProps extends ChipProps {
  status?: Status;
}

const statusStyles: Record<Status, { backgroundColor: string; color: string }> = {
  success: {
    backgroundColor: '#dcede1',
    color: '#168835',
  },
  error: {
    backgroundColor: '#f4d1ce',
    color: '#d04035',
  },
  default: {
    backgroundColor: '#fcfcfc',
    color: '#9b9ea5',
  },
};

function WorkbaseStatusChip({
  label,
  status = 'default',
  sx,
  ...otherProps
}: WorkbaseStatusChipProps) {
  const styles = statusStyles[status];

  return (
    <Chip
      label={label}
      sx={{
        ...styles,
        fontWeight: 'bold',
        fontSize: '12px',
        height: '24px',
        ...sx,
      }}
      {...otherProps}
    />
  );
}

export default WorkbaseStatusChip;
