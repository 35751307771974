import { InputAdornment } from '@mui/material';
import { WorkbaseEditIcon } from 'src/assets/icons/workbaseIcons';
import IconOrEmojiSelector from 'src/ui-components/custom/icon-selector';
import { IconOrEmojiSelectorProps } from 'src/ui-components/custom/icon-selector/IconOrEmojiSelector';
import { WorkbaseTextField, WorkbaseTextFieldProps } from '../../atoms/text-field/TextField';

export type TextFieldWithIconSelectorProps = WorkbaseTextFieldProps & {
  changeableIconProps: IconOrEmojiSelectorProps;
};

export default function TextFieldWithIconSelector({
  changeableIconProps,
  InputProps,
  ...props
}: TextFieldWithIconSelectorProps) {
  return (
    <WorkbaseTextField
      backgroundColor="tertiary.main"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconOrEmojiSelector {...changeableIconProps} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <WorkbaseEditIcon width={16} height={16} />
          </InputAdornment>
        ),
        ...InputProps,
      }}
      bordered={false}
      size="H1"
      {...props}
    />
  );
}
