import { IconButton, InputAdornment, Stack, SvgIcon, TextField, Tooltip } from '@mui/material';
import { WorkbaseSearchIcon } from 'src/assets/icons/workbaseIcons';
import { t } from 'i18next';
import { SecondarySidebarHeaderIcon } from './types';

interface Props {
  searchVal: string;
  handleSearchValChange: (val: string) => void;
  iconData?: SecondarySidebarHeaderIcon;
  disableSearchBar?: boolean;
  dataCy?: string;
}

export default function Header({
  iconData,
  searchVal,
  handleSearchValChange,
  disableSearchBar,
  dataCy,
}: Props) {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <TextField
        disabled={disableSearchBar}
        fullWidth
        size="small"
        placeholder={t('searchPlaceholder')}
        value={searchVal}
        data-cy={dataCy && `${dataCy}-search-input`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon component={WorkbaseSearchIcon} fontSize="small" />
            </InputAdornment>
          ),
        }}
        onChange={(e) => handleSearchValChange(e.target.value)}
      />
      {iconData && (
        <Tooltip title={iconData.text} placement="top">
          <IconButton
            onClick={iconData.onClick}
            size="small"
            data-cy={dataCy && `${dataCy}-header-button`}
          >
            <SvgIcon fontSize="small" component={iconData.icon} />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
}
