import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { WorkbaseArrowDownIcon } from 'src/assets/icons/workbaseIcons';
import { Box, Icon, IconButton, Stack, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

export interface WorkbaseCollapsibleProps {
  defaultExpanded?: boolean;
  children?: React.ReactNode;
  title?: React.ReactNode;
  accordionAdditionalStyles?: object;
  summaryAdditionalStyles?: object;
  detailsAdditionalStyles?: object;
  showExpandIcon?: boolean;
  alwaysExpanded?: boolean;
  onBlur?: () => void;
  sx?: SxProps<Theme>;
  onChange?: () => void;
  expanded?: boolean;
  backgroundColor?: string;
  dataCy?: string;
}

function WorkbaseCollapsible({
  defaultExpanded = true,
  children,
  title = 'Title',
  summaryAdditionalStyles = {},
  detailsAdditionalStyles = {},
  accordionAdditionalStyles = {},
  showExpandIcon = true,
  alwaysExpanded = false,
  onBlur,
  sx,
  onChange,
  expanded,
  backgroundColor = 'secondary.background',
  dataCy,
}: WorkbaseCollapsibleProps) {
  return (
    <Stack onBlur={onBlur} sx={sx}>
      <Accordion
        expanded={alwaysExpanded || expanded}
        onChange={onChange}
        defaultExpanded={defaultExpanded}
        sx={{
          '&&&': {
            borderRadius: (theme) => theme.shape.borderRadius * 2.5,
            backgroundColor,
            boxShadow: 'none',
            borderColor: 'grey.300',
            borderWidth: 1,
            borderStyle: 'solid',
            ...accordionAdditionalStyles,
          },
          '.MuiAccordionSummary-root': {
            minHeight: 'auto',
          },
        }}
      >
        <AccordionSummary
          sx={{
            '&&&&.Mui-focusVisible': { backgroundColor: 'transparent' },
            '&&&& .MuiAccordionSummary-content': {
              margin: '16px 16px 16px 0',
              minHeight: '0',
              minWidth: 0,
            },
            '&&&&.MuiAccordionSummary-root': { padding: '0 20px' },
            ...summaryAdditionalStyles,
          }}
          expandIcon={
            showExpandIcon && (
              <IconButton
                aria-label="expand"
                data-cy={dataCy && `${dataCy}-expand-button`}
                color="inherit"
              >
                <Icon>
                  <WorkbaseArrowDownIcon />
                </Icon>
              </IconButton>
            )
          }
        >
          <Box sx={{ width: '100%' }}>{title}</Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            '&&&.MuiAccordionDetails-root': { padding: '0px 20px 16px' },
            ...detailsAdditionalStyles,
          }}
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}

export default WorkbaseCollapsible;
