import React from 'react';
import { TableCell, TableCellProps } from '@mui/material';

interface WorkbaseTableCellProps extends TableCellProps {
  children: React.ReactNode;
}

function WorkbaseTableCell({ children, ...tableCellProps }: WorkbaseTableCellProps) {
  return (
    <TableCell sx={{ padding: '12px 16px' }} {...tableCellProps}>
      {children}
    </TableCell>
  );
}

export default WorkbaseTableCell;
