import { Stack, SxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseEmailIcon } from 'src/assets/icons/workbaseIcons';
import { WorkbaseIcon } from 'src/lib/components/atoms/icon';
import { WorkbaseCircularProgress } from 'src/lib/components/atoms/progress/CircularProgress';

export default function Progress({
  value,
  status,
  haveAccess,
}: {
  value: number;
  status: string;
  haveAccess: boolean;
}) {
  const { t } = useTranslation();

  const additionalStyles: SxProps =
    status === 'invited'
      ? {
          '&&& .MuiSvgIcon-root': { color: 'purple.light' },
          '&&& .MuiCircularProgress-root': { color: 'purple.light' },
        }
      : {};

  return (
    <Stack mt={1.75} mb={3.125}>
      {haveAccess && (
        <Stack direction="row">
          <Typography
            color="common.white"
            textAlign="left"
            fontWeight={700}
            fontSize={22}
            lineHeight={1.273}
            data-cy="employee-details-header-progress-text"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%',
            }}
          >
            {t('employeePage.employeeDetails.overAllProgressLabel')}
          </Typography>
          <Stack data-cy="circular-progress">
            {status === 'invited' ? (
              <WorkbaseCircularProgress
                size="medium"
                label={<WorkbaseIcon icon={WorkbaseEmailIcon} color="purple.main" size={14} />}
              />
            ) : (
              <WorkbaseCircularProgress
                value={Number(value)}
                variant={Number(value) === 0 ? 'white' : 'dark'}
                size="medium"
                sx={{
                  alignSelf: 'flex-start',
                  ...additionalStyles,
                }}
              />
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
