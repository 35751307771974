import { IconButton } from '@mui/material';
import { IconName, WorkbaseIconByName } from 'src/lib/components/atoms/icon';

export default function CardIcon({
  icon,
  onClick,
  dataCy,
}: {
  icon: IconName;
  onClick?: () => void;
  dataCy?: string;
}) {
  return (
    <IconButton
      data-cy={dataCy}
      color="inherit"
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          e.stopPropagation();
          onClick();
        }
      }}
    >
      <WorkbaseIconByName iconName={icon} />
    </IconButton>
  );
}
