import { Box } from '@mui/material';

export default function HighlightBorder({
  color,
  borderStyle,
}: {
  color: string;
  borderStyle?: string;
}) {
  return (
    <Box
      className="higlight-border"
      sx={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        transitionDuration: '.15s',
        borderWidth: 2,
        borderStyle: borderStyle ?? 'solid',
        borderColor: color,
        pointerEvents: 'none',
        zIndex: 1,
        borderRadius: (theme) => theme.shape.borderRadius * 2.5,
      }}
    />
  );
}
