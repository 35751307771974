import { IconButton, Stack } from '@mui/material';
import WorkbaseTooltip from 'src/lib/components/atoms/tooltip';
import { t } from 'i18next';
import { WorkbaseIcon } from '../../../atoms/icon/WorkbaseIcon';
import { WorkbaseEditIcon } from '../../../../../assets/icons/workbaseIcons';

export interface EmbeddedActionsProps {
  onClick: () => void;
}

export default function EmbeddedActions({ onClick }: EmbeddedActionsProps) {
  return (
    <Stack direction="row" gap={1} padding="3px 0 5px ">
      <WorkbaseTooltip title={t(`editor.topBar.tooltip.edit`)} placement="top">
        <span>
          <IconButton
            onMouseDown={(e) => {
              e.preventDefault();
              onClick();
            }}
          >
            <WorkbaseIcon icon={WorkbaseEditIcon} sx={{ '&&&': { width: 16, height: 16 } }} />
          </IconButton>
        </span>
      </WorkbaseTooltip>
    </Stack>
  );
}
