import { WorkbaseExitFullscreenIcon, WorkbaseFullscreenIcon } from 'src/assets/icons/workbaseIcons';
import { IconButton } from '@mui/material';
import screenfull from 'screenfull';
import { WorkbaseIcon } from 'src/lib/components/atoms/icon';

function FullscreenButton({
  isFullscreen,
  wrapperRef,
  smallVariant,
}: {
  isFullscreen: boolean;
  wrapperRef: any;
  smallVariant: boolean;
}) {
  const handleFullscreen = () => {
    if (wrapperRef.current) {
      screenfull.toggle(wrapperRef.current);
    }
  };

  return (
    <IconButton onClick={handleFullscreen} size={smallVariant ? 'small' : 'medium'}>
      <WorkbaseIcon
        icon={isFullscreen ? WorkbaseExitFullscreenIcon : WorkbaseFullscreenIcon}
        color="primary.text"
      />
    </IconButton>
  );
}

export default FullscreenButton;
