import { ButtonBase, SxProps } from '@mui/material';

interface DotProps {
  sx?: SxProps;
  setHovered: (val: boolean) => void;
}

export default function ResizeDot({ sx, setHovered }: DotProps) {
  const computedTransform = sx && 'transform' in sx ? sx.transform : '';
  return (
    <ButtonBase
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      sx={{
        width: `12px`,
        height: `12px`,
        transitionDuration: '.15s',
        borderRadius: '50%',
        border: '1px solid white',
        position: 'absolute',
        backgroundColor: 'primary.main',
        zIndex: 1,
        ...sx,
        transform: computedTransform?.toString() || 'none',
        '&:hover': {
          transform: `scale(1.4) ${computedTransform}`,
        },
      }}
    />
  );
}

export function HorizontalResizeDot({ setHovered, sx }: DotProps) {
  return <ResizeDot sx={{ cursor: 'ew-resize', ...sx }} setHovered={setHovered} />;
}
export function VerticalResizeDot({ setHovered, sx }: DotProps) {
  return <ResizeDot sx={{ cursor: 'ns-resize', ...sx }} setHovered={setHovered} />;
}
export function VerticalBottomLeftResizeDot({ setHovered, sx }: DotProps) {
  return <ResizeDot sx={{ cursor: 'sw-resize', ...sx }} setHovered={setHovered} />;
}

export function VerticalBottomRightResizeDot({ setHovered, sx }: DotProps) {
  return <ResizeDot sx={{ cursor: 'nw-resize', ...sx }} setHovered={setHovered} />;
}
