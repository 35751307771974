import { Button, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { WorkbaseCheckmarkBigFilledIcon } from 'src/assets/icons/workbaseIcons';
import { useMarkTrainingLessonAsDoneMutation } from 'src/store/api/trainings/trainingLessonsApi';

interface Props {
  lessonId: string;
  nextPublishedTrainingLessonId: string | null;
  disabled: boolean;
}

export default function FinishLessonButton({
  lessonId,
  nextPublishedTrainingLessonId,
  disabled,
}: Props) {
  const { t } = useTranslation();
  const params = useParams<{
    lessonId: string;
    trainingId: string;
    sectionId: string;
    moduleId: string;
  }>();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [markLessonAsDone] = useMarkTrainingLessonAsDoneMutation();

  return (
    <Button
      sx={{ marginLeft: 'auto', padding: '5px 20px 7px 20px' }}
      disabled={disabled}
      onClick={() => {
        markLessonAsDone({
          lessonId,
          sectionId: params.sectionId as string,
          trainingId: params.trainingId,
          moduleId: params.moduleId,
        }).then(() => {
          if (nextPublishedTrainingLessonId !== null) {
            const newPath = pathname.replace(
              params.lessonId as string,
              nextPublishedTrainingLessonId
            );
            navigate(newPath);
          }
        });
      }}
      startIcon={
        <SvgIcon fontSize="inherit">
          <WorkbaseCheckmarkBigFilledIcon />
        </SvgIcon>
      }
    >
      {t('lessonPage.finishLesson')}
    </Button>
  );
}
