import { Box, Stack, Typography } from '@mui/material';
import { WorkbaseAddContentIcon, WorkbaseModuleIcon } from 'src/assets/icons/workbaseIcons';
import { WorkbaseIcon } from 'src/lib/components/atoms/icon/WorkbaseIcon';
import { useTranslation } from 'react-i18next';
import { TrainingModule } from 'src/store/api/trainings/trainingModulesApi';
import SingleModule from './SingleModule';

interface Props {
  readOnly: boolean;
  modules: TrainingModule[];
  selectedItems?: string[];
  trainingId?: string;
  selectedAllItems?: boolean;
}

export default function ModulesContent({
  modules,
  selectedItems = [],
  readOnly,
  trainingId,
}: Props) {
  const { t } = useTranslation();

  const modulesToDisplay = modules.filter(({ id }) => selectedItems.includes(id));
  const isEmptyNoPermissionContent = modules?.length === 0 && selectedItems?.length !== 0;

  return (
    <Box>
      {modulesToDisplay.length === 0 && (
        <Stack alignItems="center" sx={{ marginTop: '12px' }} gap={0.5}>
          <WorkbaseIcon icon={WorkbaseModuleIcon} sx={{ width: 60, height: 57 }} />
          {isEmptyNoPermissionContent ? (
            <Typography color="text.secondary">
              {t('editor.trainingsSection.noTrainingsAcessMessage')}
            </Typography>
          ) : (
            <Stack
              direction="row"
              flexWrap="wrap"
              justifyContent="center"
              gap={0.5}
              sx={{ textAlign: 'center' }}
            >
              <Typography color="text.secondary">
                {t('editor.trainingsSection.moduleSelectionMessage.useLabel')}
              </Typography>
              <WorkbaseIcon icon={WorkbaseAddContentIcon} color="text.secondary" />
              <Typography color="text.secondary">
                {t('editor.trainingsSection.moduleSelectionMessage.selectModuleLabel')}
              </Typography>
            </Stack>
          )}
        </Stack>
      )}
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          gap: 2.5,
        }}
      >
        {modulesToDisplay.map((module) => (
          <SingleModule {...module} key={module.id} readOnly={readOnly} trainingId={trainingId} />
        ))}
      </Box>
    </Box>
  );
}
