import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import { EditorWithModeSwitch } from 'src/features/editor';
import TrainingsHeader from 'src/features/editor/view/trainings-header/TrainingsHeader';
import { useGetTrainingsQuery } from 'src/store/api/trainings/trainingsApi';
import { useUserPermissionsQuery } from 'src/store/api/userPermissionsApi';
import { PermissionCodes } from 'src/lib/enums/permissions';
import { useSettingsQuery } from 'src/store/api/settingsApi';

export default function TrainingsPage() {
  const { data: userData } = useCurrentUserQuery();
  const { data: userPermissions } = useUserPermissionsQuery();
  const hasAdminAccessToTrainings = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.ADMIN_ACCESS_TRAININGS
  );
  const hasEditingPermissions = userData?.isOwner || hasAdminAccessToTrainings;
  const settings = useSettingsQuery();
  useGetTrainingsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <EditorWithModeSwitch
      canEdit={!!hasEditingPermissions}
      canView
      renderHeader={(readOnly) => <TrainingsHeader readOnly={readOnly} />}
      documentName={`trainingPage.${settings?.data?.id}`}
    />
  );
}
