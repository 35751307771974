import { Box, Stack, alpha, Typography, IconButton } from '@mui/material';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  WorkbaseCheckmarkIcon,
  WorkbaseClockIcon,
  WorkbaseResetIcon,
} from 'src/assets/icons/workbaseIcons';
import BrandedDefaultImage from 'src/lib/components/atoms/branded-default-image/BrandedDefaultImage';
import { WorkbaseIcon } from 'src/lib/components/atoms/icon';
import WorkbaseImage from 'src/lib/components/atoms/image';
import {
  TrainingLesson,
  useMarkTrainingLessonAsUndoneMutation,
} from 'src/store/api/trainings/trainingLessonsApi';
import useScrollIntoView from './useScrollIntoView';

export default function ReadOnlyLesson({ lesson }: { lesson: TrainingLesson }) {
  const { t } = useTranslation();
  const [undoneLesson] = useMarkTrainingLessonAsUndoneMutation();
  const params = useParams<{ trainingId: string; moduleId: string; lessonId?: string }>();
  const isLessonActive = params.lessonId === lesson.id;
  const estimatedTimeLabel = t('minute', {
    count: lesson.estimatedTimeToComplete ? lesson.estimatedTimeToComplete : 0,
  });
  const containerRef = useRef<HTMLDivElement>(null);

  useScrollIntoView(lesson.id, containerRef);

  return (
    <Box sx={{ pb: '16px' }} ref={containerRef}>
      <Stack
        alignItems="center"
        flexDirection="row"
        gap={1}
        sx={{
          padding: '14px 16px 14px 12px',
          minHeight: 86,
          backgroundColor: 'common.white',
          borderStyle: 'solid',
          borderWidth: isLessonActive ? '2px' : '1px',
          borderColor: isLessonActive ? 'primary.main' : 'grey.300',
          borderRadius: (theme) => theme.shape.borderRadius * 2.5,
          transition: 'all .15s ease-in-out',
          'img, .default-svg': {
            transition: 'transform .15s ease-in-out',
          },
          '&:hover': {
            boxShadow: (theme) => `0px 0px 4px 0px ${alpha(theme.palette.common.black, 0.05)}`,
            backgroundColor: 'secondary.background',
            cursor: 'pointer',
            'img, .default-svg': {
              transform: 'scale(1.2)',
            },
          },
        }}
      >
        <Box
          sx={{
            borderRadius: (theme: any) => theme.shape.borderRadius,
            overflow: 'hidden',
            width: 96,
            height: 54,
            flexShrink: 0,
            img: { height: '100%', objectFit: 'cover', width: '100%' },
          }}
        >
          {lesson.imageUrl ? (
            <WorkbaseImage src={lesson.imageUrl} alt={lesson.name} />
          ) : (
            <BrandedDefaultImage className="default-svg" name={lesson.defaultImage} />
          )}
        </Box>
        <Box
          sx={{
            minWidth: 0,
          }}
        >
          <Typography
            fontWeight="bold"
            variant="body1"
            color="text.secondary"
            noWrap
            sx={{
              mb: 0.5,
            }}
          >
            {lesson.name || t('modulePage.unnamedLesson')}
          </Typography>
          <Stack flexDirection="row" gap={0.5} alignItems="center">
            <WorkbaseIcon icon={WorkbaseClockIcon} size={14} />
            <Typography fontWeight="medium" variant="body2" color="text.secondary">
              {estimatedTimeLabel}
            </Typography>
          </Stack>
        </Box>
        {lesson.done && (
          <IconButton
            size="small"
            color="success"
            sx={{
              marginLeft: 'auto',
              '& > *:nth-child(2)': {
                display: 'none',
              },
              '&:hover > *:nth-child(2)': {
                display: 'block !important',
              },
              '&:hover > *:first-child': {
                display: 'none',
              },
            }}
            onClick={(e) => {
              undoneLesson({
                lessonId: lesson.id,
                sectionId: lesson.trainingSectionId,
                trainingId: params.trainingId,
                moduleId: params.moduleId,
              });
              e.stopPropagation();
            }}
          >
            <WorkbaseIcon icon={WorkbaseCheckmarkIcon} />
            <WorkbaseIcon icon={WorkbaseResetIcon} />
          </IconButton>
        )}
      </Stack>
    </Box>
  );
}
