import { Box, ButtonBase, MenuItem, Stack, styled, Typography } from '@mui/material';
import { Employee } from 'src/features/employees/model';
import { ChangeEvent, useRef, useState } from 'react';
import { imageUpload } from 'src/lib/utils/imageUpload';
import { t } from 'i18next';
import { showNotification } from 'src/lib/components/atoms/notification';
import WorkbaseImage from 'src/lib/components/atoms/image';
import { WorkbaseIcon, WorkbaseIconByName } from 'src/lib/components/atoms/icon';
import { StyledMenu } from 'src/lib/components/atoms/action-menu/ActionMenu';
import { WorkbaseDeleteSquareIcon, WorkbaseImageNewIcon } from 'src/assets/icons/workbaseIcons';

import EditableImageUpload from 'src/ui-components/custom/image-edit/EditableImageUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface Props {
  employee?: Employee;
  handleUpdateEmployee: (val: Partial<Employee>) => void;
}

export default function EditAvatar({ employee, handleUpdateEmployee }: Props) {
  const [loading, setLoading] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState<null | HTMLElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleCloseMenu = () => setMenuOpen(null);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    handleCloseMenu();

    const file = e.target.files?.[0];
    if (file) {
      try {
        const response = await imageUpload(file);
        const { id, variants } = response.data.result;

        await handleUpdateEmployee({ avatarId: id, avatarUrl: variants[0] });
      } catch (err) {
        showNotification(t('validationErrorMessages.UploadImageErrorMessage'), 'error');
      } finally {
        setLoading(false);
      }
    }
  };

  const removeAvatar = async (): Promise<void> => {
    setLoading(true);
    await handleUpdateEmployee({ avatarId: null, avatarUrl: '' });
    setLoading(false);
  };

  const handleFileMenuItemClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Box width={64} height={64} minWidth={64}>
      {employee?.avatarUrl && !loading ? (
        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            position: 'relative',
            borderRadius: '10px',
            border: '1px solid',
            borderColor: 'grey.300',
            overflow: 'hidden',
          }}
        >
          <WorkbaseImage
            dataCy="employee-avatar"
            src={employee.avatarUrl}
            alt={`${employee?.firstName} ${employee?.lastName}`}
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
          />
          <ButtonBase
            component="label"
            data-cy="edit-employee-avatar-button"
            onClick={(event) => setMenuOpen(event.currentTarget)}
            sx={{
              width: '100%',
              height: '100%',
              zIndex: '1',
              minWidth: 'unset',
              pointerEvents: 'all',
              position: 'absolute',
              bottom: 0,
              transition: 'background-color .2s ease-in',
              left: 0,
              borderRadius: 0,
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                ' .MuiSvgIcon-root': {
                  opacity: 1,
                },
              },
            }}
          >
            <WorkbaseIconByName
              iconName="WorkbaseEditIcon"
              color="common.white"
              sx={{
                width: '24px',
                height: '24px',
                opacity: 0,
                transition: 'opacity .2s ease-in',
              }}
            />
          </ButtonBase>
          <StyledMenu
            open={Boolean(isMenuOpen)}
            onClose={handleCloseMenu}
            anchorEl={isMenuOpen}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem
              onClick={handleFileMenuItemClick}
              sx={{ '&&&.MuiMenuItem-root': { marginBottom: '2px' } }}
            >
              <Stack flexDirection="row" gap={1} data-cy="upload-new-image">
                <WorkbaseIcon icon={WorkbaseImageNewIcon} color="text.secondary" />
                <Typography variant="body1" fontWeight="bold">
                  {t('employeePage.employeeDetails.contactInfomation.changeAvatar')}
                </Typography>
                <VisuallyHiddenInput
                  ref={fileInputRef}
                  type="file"
                  accept="image/jpeg,image/png"
                  onChange={handleFileChange}
                />
              </Stack>
            </MenuItem>
            <MenuItem onClick={removeAvatar}>
              <Stack flexDirection="row" gap={1} data-cy="delete-current-image">
                <WorkbaseIcon icon={WorkbaseDeleteSquareIcon} color="text.secondary" />
                <Typography variant="body1" fontWeight="bold">
                  {t('employeePage.employeeDetails.contactInfomation.removeAvatar')}
                </Typography>
              </Stack>
            </MenuItem>
          </StyledMenu>
        </Stack>
      ) : (
        <EditableImageUpload
          dataCy="edit-avatar"
          src={employee?.avatarUrl || ''}
          altText={`${employee?.firstName} ${employee?.lastName}`}
          onChange={handleFileChange}
          onClear={removeAvatar}
        />
      )}
    </Box>
  );
}
