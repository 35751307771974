import { Collapse, Stack, SxProps, Typography } from '@mui/material';
import {
  WorkbaseArrowDownIcon,
  WorkbaseArrowUpIcon,
  WorkbaseInfoFilledIcon,
} from 'src/assets/icons/workbaseIcons';
import { ReactNode, useState } from 'react';
import { WorkbaseIcon } from '../../atoms/icon';

export type Type = 'info' | 'warning' | 'error';

export const typeDict = {
  info: {
    icon: WorkbaseInfoFilledIcon,
    color: 'primary.main',
    backgroundColor: 'primary.light',
  },
  warning: {
    icon: WorkbaseInfoFilledIcon,
    color: 'secondary.main',
    backgroundColor: 'secondary.light',
  },
  error: {
    icon: WorkbaseInfoFilledIcon,
    color: 'error.main',
    backgroundColor: 'red.light',
  },
};

interface Props {
  children?: ReactNode;
  message: string;
  sx?: SxProps;
  type?: Type;
}

export default function WorkbaseAlert({ children, message, type = 'info', sx }: Readonly<Props>) {
  const [open, setOpen] = useState(false);
  const isExpandable = !!children;
  if (!message) return null;

  return (
    <Stack
      direction="column"
      sx={{
        backgroundColor: `${typeDict[type].backgroundColor}`,
        p: '10px 10px 12px',
        borderRadius: (th) => th.shape.borderRadius * 2.5,
        ...sx,
      }}
    >
      <Stack
        sx={{ cursor: isExpandable ? 'pointer' : 'default' }}
        direction="row"
        gap="6px"
        alignItems="center"
        justifyContent="space-between"
        onClick={isExpandable ? () => setOpen(!open) : undefined}
      >
        <Stack direction="row" gap="6px" alignItems="center">
          <WorkbaseIcon
            icon={typeDict[type].icon}
            color={typeDict[type].color}
            size={16}
            sx={{ mt: '2px' }}
          />
          <Typography variant="body1" color="secondary">
            {message}
          </Typography>
        </Stack>
        {isExpandable && (
          <WorkbaseIcon
            icon={open ? WorkbaseArrowUpIcon : WorkbaseArrowDownIcon}
            color={typeDict[type].color}
            size={16}
            sx={{ mt: '2px' }}
          />
        )}
      </Stack>
      <Collapse
        in={open}
        sx={{
          maxHeight: '160px',
          overflow: 'auto',
          padding: '0 10px 0 22px',
        }}
      >
        {children}
      </Collapse>
    </Stack>
  );
}
