import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import MoreIconsDropdownButton from './MoreIconsDropdownButton';
import { IconButtonData } from './iconButtonGroup.types';

interface Props {
  data: IconButtonData[];
  dropdownItemsCount: number;
}

export default function IconButtonGroup({ data, dropdownItemsCount }: Props) {
  const iconsToDisplay = data.slice(0, data.length - dropdownItemsCount);
  const iconsInDropdown = dropdownItemsCount ? data.slice(-dropdownItemsCount) : null;
  return (
    <>
      {iconsToDisplay.map(({ icon, onClick, tooltip, disabled, key }) => (
        <Tooltip title={tooltip} key={key}>
          <IconButton onClick={onClick} disabled={disabled}>
            <SvgIcon component={icon} fontSize="small" />
          </IconButton>
        </Tooltip>
      ))}
      {iconsInDropdown && <MoreIconsDropdownButton data={iconsInDropdown} />}
    </>
  );
}
