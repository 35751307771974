import { ButtonBase, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

interface Props {
  dragRef?: any;
  sx?: SxProps<Theme>;
}

export default function DndButton({ dragRef, sx }: Props) {
  return (
    <ButtonBase
      className="dnd-button"
      ref={dragRef}
      sx={{
        width: `30px`,
        height: `10px`,
        transitionDuration: '.15s',
        borderRadius: (theme) => theme.shape.borderRadius,
        border: '2px solid white',
        backgroundColor: 'primary.main',
        cursor: 'grab',
        '&:hover': {
          transform: `scale(1.4)`,
        },
        ...sx,
      }}
    />
  );
}
