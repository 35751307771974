import { Stack, SxProps, Theme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useHocuspocusProvider, {
  HocuspocusProviderProps,
} from 'src/lib/hooks/use-hocuspocus-provider';
import useScrollOnDrag from 'src/lib/hooks/use-scroll-on-drag';
import { useAppSelector } from 'src/store';
import Grid from './grid/Grid';
import EditorTopBar from './top-bar/EditorTopBar';
import { setEditorData } from '../controller/Editor.slice';
import { useEditor } from '../controller/EditorContext';
import BlockerComponent from './blocker/BlockerComponent';
import { DocumentNameContext } from './DocumentNameContext';
import RecordingModal from './RecordingModal';

const parsedUrl = new URL(import.meta.env.VITE_API_URL);

export interface EditorProps {
  readOnly: boolean;
  renderHeader?: (readOnly: boolean) => React.ReactNode;
  topBarRightContent?: React.ReactNode;
  documentName: string;
  onEditorDataChangeCallback?: () => void;
  // remove after layouts / bouncing revamp
  topBarStyles?: SxProps<Theme>;
}

export default function Editor({
  readOnly,
  renderHeader,
  topBarRightContent,
  documentName,
  onEditorDataChangeCallback,
  topBarStyles,
}: EditorProps) {
  const dispatch = useDispatch<any>();
  const editorData = useAppSelector((state) => state.editor.editorData);
  const { yjsToEditorData, ydoc, undoManager } = useEditor();
  const [isScrolled, setIsScrolled] = useState(false);
  const scrollAreaRef = useScrollOnDrag();
  const socketProtocol = parsedUrl.protocol === 'https:' ? 'wss' : 'ws';
  const socketUrl = `${socketProtocol}://${parsedUrl.host}/editor`;

  useHocuspocusProvider({
    documentName,
    socketUrl,
    readOnly,
    ydoc,
  } as HocuspocusProviderProps);

  const onEditorChange = useCallback(() => {
    const updatedEditorData = yjsToEditorData();
    dispatch(setEditorData(updatedEditorData));
    onEditorDataChangeCallback?.();
  }, [yjsToEditorData, dispatch, onEditorDataChangeCallback]);

  useEffect(() => {
    ydoc.getMap('editorData').observeDeep(onEditorChange);

    return () => {
      ydoc.getMap('editorData').unobserveDeep(onEditorChange);
    };
  }, [ydoc, onEditorChange]);

  const handleScroll = useCallback(() => {
    if (scrollAreaRef.current) {
      const isContentScrolled = scrollAreaRef.current.scrollTop > 0;
      setIsScrolled(isContentScrolled);
    }
  }, [scrollAreaRef]);

  // cleanup
  useEffect(
    () => () => {
      dispatch(setEditorData(undefined));
    },
    [dispatch]
  );

  useEffect(() => {
    // clear undo history when finished editing
    if (readOnly) {
      undoManager.clear();
    }
  }, [readOnly, undoManager]);

  return (
    <Stack sx={{ maxHeight: '100%' }} gap={2}>
      {!readOnly && (
        <EditorTopBar sx={topBarStyles} scrolled={isScrolled} rightContent={topBarRightContent} />
      )}
      {!readOnly && <BlockerComponent />}
      <Stack
        ref={scrollAreaRef}
        onScroll={handleScroll}
        gap={2}
        sx={{
          maxHeight: '100%',
          height: '100%',
          overflow: 'auto',
          paddingTop: readOnly ? '20px' : '50px',
          marginTop: readOnly ? 0 : `calc(-64px + ${readOnly ? 22 : 78}px)`,
          backgroundColor: 'background.paper',
        }}
      >
        {renderHeader?.(readOnly)}
        <DocumentNameContext.Provider value={documentName}>
          {!readOnly && <RecordingModal />}
          <Grid readOnly={readOnly} grid={editorData?.grid ?? []} />
        </DocumentNameContext.Provider>
      </Stack>
    </Stack>
  );
}
