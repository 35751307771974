const resizeImageFile = async (file: File, size: number): Promise<File | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const aspectRatio = img.width / img.height;
        let width = size;
        let height = size;

        if (aspectRatio > 1) {
          // Landscape orientation
          height = size / aspectRatio;
        } else {
          // Portrait or square orientation
          width = size * aspectRatio;
        }

        // Set canvas size
        canvas.width = size;
        canvas.height = size;

        if (ctx) {
          // Draw the image onto the canvas, resizing it to fit within set size
          const x = (size - width) / 2;
          const y = (size - height) / 2;
          ctx.drawImage(img, x, y, width, height);

          canvas.toBlob((blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, { type: 'image/png' });
              resolve(resizedFile);
            } else {
              reject(new Error('Failed to create blob'));
            }
          }, 'image/png');
        } else {
          reject(new Error('Failed to get 2d context'));
        }
      };
      img.onerror = () => {
        reject(new Error('Failed to load image'));
      };

      img.src = event.target?.result as string;
    };
    reader.onerror = () => {
      reject(new Error('Failed to read file'));
    };
    // Read the file asynchronously as a data URL
    reader.readAsDataURL(file);
  });

export default resizeImageFile;
