import CardContent, { CardContentProps } from '../common/CardContent';
import CardImage from '../common/CardImage';
import CardWrapper from '../common/CardWrapper';

export interface ImageProgressCardProps extends CardContentProps {
  image: string | null;
  dataCy?: string;
}

export default function ImageProgressCard({
  image,
  prefix,
  title,
  description,
  progress,
  onClick,
  dataCy,
}: ImageProgressCardProps) {
  return (
    <CardWrapper>
      <CardImage image={image} title={title} onClick={onClick} data-cy={dataCy} />
      <CardContent
        data-cy={dataCy}
        title={title}
        prefix={prefix}
        description={description}
        progress={progress}
        onClick={onClick}
      />
    </CardWrapper>
  );
}
