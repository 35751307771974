import { MenuItem, styled } from '@mui/material';

const WorkbaseMenuItem = styled(MenuItem)`
  padding: 10px 0px;
  &.MuiMenuItem-gutters {
    padding: 10px 12px;
  }
  border-radius: ${({ theme }) => theme.shape.borderRadius};

  &.Mui-selected {
    background-color: transparent;
  }
  &&:hover,
  &&.Mui-focusVisible {
    background-color: ${({ theme }) => theme.palette.grey[300]};
  }
`;

export default WorkbaseMenuItem;
