import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseTrainingsIcon } from 'src/assets/icons/workbaseIcons';
import Placeholder from 'src/lib/components/molecules/placeholder';

export default function EmptyTrainingProgressPlaceholder() {
  const { t } = useTranslation();

  return (
    <Stack justifyContent="center" alignItems="center" height="100%">
      <Box width={251}>
        <Placeholder
          icon={WorkbaseTrainingsIcon}
          title={t('employeePage.progress.emptyProgressTitle')}
          text={t('employeePage.progress.emptyProgressText')}
        />
      </Box>
    </Stack>
  );
}
