import { CircularProgress, Stack } from '@mui/material';
import { useState } from 'react';
import { WorkbaseSearchTextField } from 'src/lib/components/atoms/text-field';
import { Employee } from 'src/features/employees/model';
import normalizeString from 'src/lib/utils/normalizeString';
import {
  TrainingsProgress,
  useTrainingsByUserIdQuery,
  useTrainingsProgressByUserIdQuery,
  useTrainingsAccessQuery,
  DetailedTrainingWithAccess,
} from 'src/store/api/trainings/trainingsApi';
import TrainingList from './TrainingList';
import EmptyTrainingProgressPlaceholder from './EmptyTrainingProgressPlaceholder';

export interface TrainingProgressProps {
  employee: Employee;
}

export default function TrainingProgress({ employee }: TrainingProgressProps) {
  const [searchVal, setSearchVal] = useState<string>('');
  const { data: trainingsProgress } = useTrainingsProgressByUserIdQuery(employee.id);
  const { data: trainings, isLoading } = useTrainingsByUserIdQuery(employee.id);
  const { data: trainingsAceess } = useTrainingsAccessQuery();
  const handleSearchValChange = (val: string) => setSearchVal(val);
  const trainingsWithAccessData =
    trainings?.map((training) => {
      const hasTrainingAccess = !!trainingsAceess?.find((access) => access.id === training.id);
      return { ...training, blockAccess: !hasTrainingAccess };
    }) || [];

  return (
    <Stack
      mt={2.5}
      sx={{
        width: '100%',
        '& .MuiGrid-root .MuiTypography-body1': {
          fontSize: 15,
          fontWeight: 500,
          alignItems: 'center',
        },
      }}
    >
      {isLoading && (
        <CircularProgress
          sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
        />
      )}
      {!isLoading && trainings && trainings?.length > 0 && (
        <>
          <Stack mb={2.5}>
            <WorkbaseSearchTextField
              dataCy="employee-roles-search-textField"
              fullWidth
              onChange={(e) => handleSearchValChange(e.target.value)}
              value={searchVal}
            />
          </Stack>
          <TrainingList
            trainingsProgress={trainingsProgress as TrainingsProgress[]}
            trainingsDetailes={trainingsWithAccessData as DetailedTrainingWithAccess[]}
            employee={employee}
            searchVal={normalizeString(searchVal)}
          />
        </>
      )}
      {!isLoading && trainings?.length === 0 && <EmptyTrainingProgressPlaceholder />}
    </Stack>
  );
}
