import React from 'react';
import { Autocomplete, AutocompleteProps } from '@mui/material';
import WorkbasePopper from 'src/lib/components/atoms/popper/Popper';
import WorkbasePaper from 'src/lib/components/atoms/paper/Paper';

interface Option {
  id: string;
  name?: string;
  navigateRoute?: string;
  [key: string]: any; // Allow for additional properties
}

export interface CustomAutocompleteProps<T extends Option>
  extends AutocompleteProps<T, boolean, boolean, boolean, any> {
  open: boolean;
  dataCy?: string;
  selectedValue?: T | null;
  searchValue: string;
  isLoading?: boolean;
  loadingComponent?: React.ReactNode;
  noOptionsComponent?: React.ReactNode;
  isFetching?: boolean;
  popperWidth?: number | string;
  popperMaxHeight?: number | string;
  options: T[];
  onOpen: () => void;
  onClose: () => void;
  onChange?: (event: React.SyntheticEvent, value: any) => void;
}

function SearchWithDropdown<T extends Option>({
  open,
  searchValue,
  isLoading,
  dataCy,
  loadingComponent,
  popperWidth,
  popperMaxHeight,
  options,
  noOptionsComponent,
  onOpen,
  onClose,
  onChange,
  renderInput,
  renderOption,
  slotProps,
  ...otherProps
}: Readonly<CustomAutocompleteProps<T>>) {
  return (
    <Autocomplete
      open={open}
      freeSolo={searchValue === '' && !isLoading}
      inputValue={searchValue}
      onOpen={onOpen}
      onClose={onClose}
      options={options}
      onChange={onChange}
      loading={isLoading}
      loadingText={loadingComponent}
      noOptionsText={noOptionsComponent}
      filterOptions={(x) => x}
      PopperComponent={WorkbasePopper}
      PaperComponent={WorkbasePaper}
      renderInput={renderInput}
      renderOption={renderOption}
      slotProps={slotProps}
      {...otherProps}
    />
  );
}

export default SearchWithDropdown;
