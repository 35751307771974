import { Box } from '@mui/material';
import { memo, useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import Row from './Row';
import DroppableBottomRow from './droppable-items/DroppableBottomRow';
import OneColRow from './one-col-row/OneColRow';
import { GridRow } from '../../model/types';

interface GridProps {
  readOnly?: boolean;
  grid: GridRow[];
}

function Grid({ readOnly = false, grid }: GridProps) {
  const [isOneColView, setIsOneColView] = useState(false);
  const gridRef = useRef(null);
  useEffect(() => {
    const handleResize = debounce((entries) => {
      const entry = entries[0];
      if (entry) {
        const { width } = entry.contentRect;

        setIsOneColView(width <= 620);
      }
    }, 100);

    const resizeObserver = new ResizeObserver((entries) => handleResize(entries));
    const currentGridRef = gridRef.current;

    if (currentGridRef) {
      resizeObserver.observe(currentGridRef);
    }
    return () => {
      resizeObserver.disconnect();
      handleResize.cancel();
    };
  }, []);

  const oneColViewBoxStyles = isOneColView ? { margin: '0 20px' } : {};
  const lastRowIndex = (grid.length || 1) - 1;
  const lastGridItem = grid[lastRowIndex]?.items[(grid[lastRowIndex]?.items.length || 1) - 1]?.id;

  return (
    <Box
      className={`editor-grid ${isOneColView ? 'editor-grid-one-col-view' : ''}`}
      ref={gridRef}
      sx={{
        flexShrink: 1,
        minHeight: 0,
        flexGrow: 1,
        maxHeight: '100%',
        height: 'calc(100% + 64px)',
        ...oneColViewBoxStyles,
      }}
    >
      {grid.map((row, index) =>
        isOneColView ? (
          <OneColRow key={row.id} row={row} readOnly={readOnly} />
        ) : (
          <Row key={row.id} row={row} index={index} readOnly={readOnly} />
        )
      )}
      <DroppableBottomRow
        oneColMode={isOneColView}
        lastGridItemId={lastGridItem}
        lastRowIndex={lastRowIndex}
      />
    </Box>
  );
}

export default memo(Grid);
