import { Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseTwoEmployeesIcon } from 'src/assets/icons/workbaseIcons';

export default function EmptyEmployeeRolesListPlaceholder() {
  const { t } = useTranslation();

  return (
    <Stack justifyContent="center" alignItems="center" height="100%" gap={1}>
      <SvgIcon component={WorkbaseTwoEmployeesIcon} />
      <Typography color="text.primary" variant="body1" fontWeight="bold">
        {t('companySettings.sidebar.departments.employeePopup.placeholderTitle')}
      </Typography>
      <Typography color="action" variant="caption">
        {t('companySettings.sidebar.departments.employeePopup.placeholderMessage')}
      </Typography>
    </Stack>
  );
}
