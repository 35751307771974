import { Components, Theme } from '@mui/material';

const MuiTab: Components<Theme>['MuiTab'] = {
  styleOverrides: {
    root: {
      minWidth: 40,
    },
  },
};

export default MuiTab;
