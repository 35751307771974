import React from 'react';
import { TableBody, TableBodyProps } from '@mui/material';

interface WorkbaseTableBodyProps extends TableBodyProps {
  children: React.ReactNode;
}

function WorkbaseTableBody({ children, ...tableBodyProps }: Readonly<WorkbaseTableBodyProps>) {
  return <TableBody {...tableBodyProps}>{children}</TableBody>;
}

export default WorkbaseTableBody;
