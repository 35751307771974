import { Box, BoxProps, useTheme } from '@mui/material';

interface ColorBoxProps {
  bgColor?: BoxProps['bgcolor'];
  width?: number | string;
  height?: number | string;
  elevation?: number;
}

export default function ColorBox({
  bgColor = 'primary.main',
  width = '100%',
  height,
  elevation = 0,
}: ColorBoxProps) {
  const theme = useTheme();

  return (
    <Box
      borderRadius={1}
      bgcolor={bgColor}
      sx={{
        boxShadow: theme.shadows[elevation],
        width,
        height,
      }}
    />
  );
}
