import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';

interface RequestPasswordResponse {
  cooldown: number;
}

export const resetPasswordApi = createApi({
  reducerPath: 'resetPasswordApi',
  baseQuery,
  endpoints: (builder) => ({
    resetPasswordCooldown: builder.query<RequestPasswordResponse, string>({
      query: (id) => `/companies/users/${id}/request-reset-password`,
    }),
    postRequestResetPassword: builder.mutation<RequestPasswordResponse, string>({
      query: (id) => ({
        url: `/companies/users/${id}/request-reset-password`,
        method: 'POST',
      }),
      onQueryStarted: (id, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((data) => {
          dispatch(
            resetPasswordApi.util.updateQueryData('resetPasswordCooldown', id, () => data.data)
          );
        });
      },
    }),
  }),
});

export const { useResetPasswordCooldownQuery, usePostRequestResetPasswordMutation } =
  resetPasswordApi;
