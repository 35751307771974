import { Container, IconButton, Popover } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkbaseAddContentIcon } from 'src/assets/icons/workbaseIcons';
import { WorkbaseIcon } from 'src/lib/components/atoms/icon/WorkbaseIcon';
import ChipSelectionMenu from 'src/ui-components/custom/selection-menu/ChipSelectionMenu';
import WorkbaseTooltip from 'src/lib/components/atoms/tooltip';

export interface TrainingsSelectionProps {
  options: { id: string; label: string }[];
  selected: string[];
  onChange: (val: string[]) => void;
}

export default function TrainingsSelection({
  options = [],
  selected,
  onChange,
}: TrainingsSelectionProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = !!anchorEl;
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <WorkbaseTooltip title={t(`editor.topBar.tooltip.addContent`)} placement="top">
        <span>
          <IconButton aria-describedby={id} onClick={handleClick}>
            <WorkbaseIcon icon={WorkbaseAddContentIcon} sx={{ '&&&': { width: 16, height: 16 } }} />
          </IconButton>
        </span>
      </WorkbaseTooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          transform: 'translate(-16px, 8px)',
          '& .MuiPaper-root': {
            padding: 0,
            boxShadow: 'none',
            backgroundColor: 'common.white',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {/* TODO: adapt it to trainings widget */}
        <Container maxWidth="lg">
          <ChipSelectionMenu
            options={options}
            selected={selected}
            onChange={onChange}
            title={t('editor.trainingsSection.selectionMenuTitle')}
          />
        </Container>
      </Popover>
    </>
  );
}
