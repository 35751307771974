import { Stack, styled } from '@mui/material';
import { ReactPlayerProps } from 'react-player';
import screenfull from 'screenfull';
import { useEffect } from 'react';
import SeekSlider from './controls/SeekSlider';
import PlaybackRateSelector from './controls/PlaybackRateSelector';
import FullscreenButton from './controls/FullScreenButton';
import PlayButton from './controls/PlayButton';
import SoundSlider from './controls/SoundSlider';
import DurationText from './controls/DurationText';
import usePlayerKeyboardControls from './controls/controller/usePlayerKeyboardControls';

const StyledPlayerControls = styled('div')`
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  opacity: 0;
  transition: all 0.2s ease-in-out;

  .video-player__slider {
    color: ${({ theme }) => theme.palette.primary.main};
    .MuiSlider-rail {
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
    box-sizing: border-box;

    &--seek {
      margin-left: 12px;
      margin-right: 12px;
    }

    .MuiSlider-track {
      border: none;
      height: 6px;
    }
    .MuiSlider-rail {
      border: none;
      height: 6px;
    }

    .MuiSlider-thumb {
      background-color: ${({ theme }) => theme.palette.common.white};
      height: 12px;
      width: 12px;
      &:before: {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
      }

      &:hover,
      &.Mui-focusVisible,
      &.Mui-active {
        box-shadow: none;
      }
    }
  }
`;

function PlayerControls(props: ReactPlayerProps) {
  const {
    state,
    dispatch,
    wrapperRef,
    playerRef,
    smallVariant,
    disableFastForward,
    readOnly,
    watchProgress,
  } = props;

  const handleSound = (newValue: number) => {
    dispatch({ type: 'VOLUME', payload: newValue });
  };

  const handleMute = () => {
    dispatch({ type: 'TOGGLE_MUTE', payload: !state.muted });
  };

  const handleSeek = (newValue: number | number[]) => {
    if (state.ended) {
      dispatch({ type: 'ENDED', payload: false });
    }
    if ((newValue as number) > watchProgress && disableFastForward && readOnly) return;
    playerRef.current.seekTo(newValue as number, 'seconds');
  };

  const handlePlaybackRate = (rate: number) => {
    dispatch({ type: 'PLAYBACK_RATE', payload: rate });
  };

  useEffect(() => {
    screenfull.on('change', () => {
      dispatch({ type: 'FULLSCREEN', payload: screenfull.isFullscreen });
    });
    return () => {
      screenfull.off('change', () => {
        dispatch({ type: 'FULLSCREEN', payload: screenfull.isFullscreen });
      });
    };
  }, [dispatch]);
  usePlayerKeyboardControls({
    seekForward: () => handleSeek(state.progress.playedSeconds + 5),
    seekBackward: () => handleSeek(state.progress.playedSeconds - 5),
    togglePlayPause: () => dispatch({ type: 'TOGGLE_PLAY' }),
    toggleMute: () => handleMute(),
    toggleFullscreen: () => screenfull.toggle(wrapperRef.current),
    isActive: state.active,
  });

  return (
    <StyledPlayerControls
      className="video-player__controls"
      sx={{
        padding: smallVariant ? '16px 20px' : '16px 30px',
      }}
    >
      <Stack direction="row" alignItems="center">
        <SeekSlider
          progress={state.progress}
          duration={state.duration}
          handleSeek={(val) => handleSeek(val)}
          watchProgress={watchProgress}
          disableFastForward={disableFastForward}
          readOnly={readOnly}
          playerRef={playerRef}
        />
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={smallVariant ? 1 : 2}>
          <PlayButton
            isPlaying={state.playing}
            isEnded={state.ended}
            smallVariant={smallVariant}
            onClick={() => {
              dispatch({ type: 'TOGGLE_PLAY' });
              dispatch({ type: 'ENDED', payload: false });
            }}
          />
          <SoundSlider
            muted={state.muted}
            volume={state.volume}
            onMute={handleMute}
            onVolumeChange={handleSound}
            smallVariant={smallVariant}
          />
          <DurationText
            smallVariant={smallVariant}
            playedTime={state.progress.playedSeconds}
            duration={state.duration}
          />
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <PlaybackRateSelector
            playbackRate={state.playbackRate}
            smallVariant={smallVariant}
            handlePlaybackRate={handlePlaybackRate}
          />
          <FullscreenButton
            isFullscreen={state.fullscreen}
            wrapperRef={wrapperRef}
            smallVariant={smallVariant}
          />
        </Stack>
      </Stack>
    </StyledPlayerControls>
  );
}

export default PlayerControls;
