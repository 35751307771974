import { useFetchWikiCardsQuery } from 'src/store/api/wikiApi.slice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import WikiCard from './WikiCard';
import { removeLastChunkId } from '../../controller/wikiCards.slice';

interface WikiCardListProps {
  firstId: string | null;
  index: number;
}

export default function WikiCardList({ firstId, index }: WikiCardListProps) {
  const dispatch = useDispatch();
  const { data } = useFetchWikiCardsQuery(
    { startingAfter: firstId, limit: 10 },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (data?.length === 0 && firstId !== null) {
      dispatch(removeLastChunkId(firstId));
    }
  }, [data, dispatch, firstId]);

  return data?.map((item, idx) => (
    <div key={item.id} id={item.id}>
      <WikiCard
        gridData={item.editorJson}
        sharelevelData={item.shareLevel}
        index={idx + index * 10}
      />
    </div>
  ));
}
