import { useState } from 'react';
import { WorkbaseIcon } from 'src/lib/components/atoms/icon/WorkbaseIcon';
import { WorkbaseErrorIcon, WorkbaseThreePointsIcon } from 'src/assets/icons/workbaseIcons';
import { StyledMenu } from 'src/lib/components/atoms/action-menu/ActionMenu';
import { useTranslation } from 'react-i18next';
import { showNotification } from 'src/lib/components/atoms/notification';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'src/lib/hooks/use-boolean';
import WorkbaseDialogComponent from 'src/lib/components/molecules/dialog/Dialog';
import { IconButton, Typography } from '@mui/material';
import {
  useDeleteTrainingModuleMutation,
  useDuplicateTrainingModuleMutation,
} from 'src/store/api/trainings/trainingModulesApi';
import WorkbaseDropdownItem from 'src/lib/components/molecules/menu/DropdownItem';

export default function ModuleActions({
  moduleId,
  onDelete,
  sectionsCount = 0,
  lessonsCount = 0,
}: {
  moduleId: string;
  onDelete?: () => void;
  sectionsCount?: number;
  lessonsCount?: number;
}) {
  const [isOpen, setOpen] = useState<null | HTMLElement>(null);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => setOpen(event.currentTarget);
  const handleClose = () => setOpen(null);
  const { t } = useTranslation();
  const param = useParams() as { trainingId: string };
  const deleteModal = useBoolean();

  const [deleteModule] = useDeleteTrainingModuleMutation();
  const [duplicateModule] = useDuplicateTrainingModuleMutation();

  const menuItems = [
    {
      value: 'duplicate',
      label: t('duplicate'),
      startIcon: 'WorkbaseCopyDuplicateIcon' as const,
      onClick: () => {
        duplicateModule({ trainingId: param.trainingId, moduleId }).then((resp: any) => {
          if (resp.error) {
            showNotification(t('notifications.duplicateError'), 'error');
          } else {
            showNotification(t('notifications.duplicateSuccess'));
          }
        });
      },
    },
    {
      value: 'delete',
      label: t('delete'),
      startIcon: 'WorkbaseDeleteIcon' as const,
      onClick: () => {
        deleteModal.onTrue();
      },
    },
  ];

  const sectionsText = t('deleteModuleModal.section', { count: sectionsCount });
  const lessonsText = t('deleteModuleModal.lesson', { count: lessonsCount });

  return (
    <>
      <WorkbaseDialogComponent
        title={t('areYouSure')}
        open={deleteModal.value}
        onClose={deleteModal.onFalse}
        titleIcon={{ icon: WorkbaseErrorIcon, color: 'error.main' }}
        confirmButton={{
          text: t('delete'),
          onClick: () => {
            deleteModule({ id: moduleId, trainingId: param.trainingId }).then((resp: any) => {
              if (resp.error) {
                showNotification(t('notifications.deleteError'), 'error');
              } else {
                onDelete?.();
                showNotification(t('notifications.deleteSuccess'));
              }
            });
          },
          type: 'error',
        }}
      >
        <Typography variant="body1">
          {t('deleteModuleModal.content', { sections: sectionsText, lessons: lessonsText })}
        </Typography>
      </WorkbaseDialogComponent>
      <IconButton
        onClick={(e) => {
          handleOpen(e);
        }}
      >
        <WorkbaseIcon icon={WorkbaseThreePointsIcon} />
      </IconButton>
      <StyledMenu anchorEl={isOpen} onClose={handleClose} open={Boolean(isOpen)}>
        {menuItems.map((item) => (
          <WorkbaseDropdownItem
            key={item.value}
            primaryText={item.label}
            icon={item.startIcon}
            onClick={() => {
              item.onClick?.();
              handleClose();
            }}
            sx={{
              '& .MuiList-root': {
                padding: 0,
              },
            }}
          />
        ))}
      </StyledMenu>
    </>
  );
}
