import { Box, Tooltip, MenuItem } from '@mui/material';
import { Emoji as EmojiMartEmoji } from '@emoji-mart/data';
import Grid from '@mui/material/Grid2';
import { t } from 'i18next';
import SvgIconByName, { IconName } from 'src/ui-components/utils/icon/SvgIconByName';
import Emoji from '../../emoji/Emoji';

interface IconGridProps {
  emojis?: EmojiMartEmoji[];
  icons?: IconName[];
  selectedIcon: string;
  onClick: (icon: string) => void;
}

const commonTooltipProps = {
  disableInteractive: true,
  enterNextDelay: 200,
};

const renderEmojiElement = (
  emoji: EmojiMartEmoji,
  selectedIcon: string,
  onClick: (val: string) => void
) => (
  <Grid size={1} key={emoji.id}>
    <Tooltip title={emoji.name} {...commonTooltipProps}>
      <MenuItem
        key={emoji.name}
        selected={selectedIcon === emoji.skins[0].native}
        onClick={() => onClick(emoji.skins[0].native)}
      >
        <Box display="flex" width="100%" justifyContent="center">
          <Emoji emoji={emoji.skins[0].native} fontSize="medium" />
        </Box>
      </MenuItem>
    </Tooltip>
  </Grid>
);

const renderIconElement = (
  value: IconName,
  selectedIcon: string,
  onClick: (val: string) => void
) => (
  <Grid size={1} key={value}>
    <Tooltip title={t(value)} {...commonTooltipProps}>
      <MenuItem key={value} selected={selectedIcon === value} onClick={() => onClick(value)}>
        <Box display="flex" width="100%" justifyContent="center">
          <SvgIconByName iconName={value} fontSize="small" />
        </Box>
      </MenuItem>
    </Tooltip>
  </Grid>
);

export default function IconOrEmojiGrid({ emojis, icons, selectedIcon, onClick }: IconGridProps) {
  return (
    <>
      {Array.isArray(icons) &&
        icons.map((value) => renderIconElement(value, selectedIcon, onClick))}
      {Array.isArray(emojis) &&
        emojis.map((emoji) => renderEmojiElement(emoji, selectedIcon, onClick))}
    </>
  );
}
