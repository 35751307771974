import { createApi } from '@reduxjs/toolkit/query/react';
import { dashboardApi } from 'src/features/dashboard/controller';
import { resetToTop } from 'src/features/wiki/controller/helper';
import { baseQuery } from './api';
import { shareResourceApi } from './shareResourceApi';
import { trainingLessonsApi } from './trainings/trainingLessonsApi';
import { trainingModulesApi } from './trainings/trainingModulesApi';
import { trainingsApi } from './trainings/trainingsApi';
import { employeesApi } from './employees/employeesApi';
import { wikiApi } from './wikiApi.slice';
import { sidebarApi } from './sidebarApi';

interface BaseCurrentUser {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  language: string;
  adminAccessToAllContent: boolean;
  isOwner: boolean;
  accessToMultipleCompanies: boolean;
}

interface CurrentUserResponse extends BaseCurrentUser {
  avatarUrl: string | null;
  isImpersonated: boolean;
}

interface CurrentUserPatchPayload extends Partial<BaseCurrentUser> {
  avatarId?: string | null;
  avatarUrl?: string;
}

export const currentUserApi = createApi({
  reducerPath: 'currentUser',
  baseQuery,
  endpoints: (builder) => ({
    currentUser: builder.query<CurrentUserResponse, void>({
      query: () => ({
        url: '/companies/users/me',
      }),
    }),
    patchCurrentUser: builder.mutation<void, CurrentUserPatchPayload>({
      query: (data) => ({
        url: `/companies/users/me`,
        body: { ...data, avatarUrl: undefined },
        method: 'PATCH',
      }),
      onQueryStarted: (data, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(
            currentUserApi.util.updateQueryData('currentUser', undefined, (prev) => ({
              ...prev,
              ...data,
              avatarId: undefined,
            }))
          );
          if (data.adminAccessToAllContent !== undefined) {
            dispatch(employeesApi.util.invalidateTags(['Employees']));
            dispatch(shareResourceApi.util.invalidateTags(['ShareLevel']));
            dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
            dispatch(trainingLessonsApi.util.invalidateTags(['TrainingLesson']));
            dispatch(trainingModulesApi.util.invalidateTags(['TrainingModule']));
            dispatch(trainingsApi.util.invalidateTags(['Trainings']));
            dispatch(trainingsApi.util.invalidateTags(['TrainingsAccess']));
            dispatch(dashboardApi.util.invalidateTags(['Dashboard']));
            // manually fetch the first chunk after providing/removing access to all content and clear all the chunks,
            // because the order of elements might have changed, causing some elements to become unreachable
            dispatch(wikiApi.endpoints.fetchWikiCards.initiate({ limit: 10 })).then((res: any) => {
              resetToTop(dispatch, res.data[0].id);
            });
            dispatch(wikiApi.util.invalidateTags(['WikiSidebar', 'Wiki']));
            dispatch(trainingModulesApi.util.invalidateTags(['AllTrainingModules']));
          }
        });
      },
    }),
  }),
});

export const { useCurrentUserQuery, usePatchCurrentUserMutation } = currentUserApi;
