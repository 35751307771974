import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  SvgIcon,
} from '@mui/material';
import { t } from 'i18next';
import { WorkbaseDeleteIcon, WorkbaseThreePointsIcon } from 'src/assets/icons/workbaseIcons';
import { showNotification } from 'src/lib/components/atoms/notification';
import { MouseEvent, useState } from 'react';
import { useAppDispatch } from 'src/store';
import { Department } from '../../../model/types';
import { deleteDepartment } from '../../../controller/departments.slice';

interface DepartmentsActionProps {
  departmentId?: string;
  usersCount?: number;
  departments: Department[];
}

export default function DepartmentsAction({
  departmentId,
  usersCount,
  departments,
}: DepartmentsActionProps) {
  const dispatch = useAppDispatch();
  const options = [
    {
      value: 'delete',
      label: 'Delete',
      startIcon: WorkbaseDeleteIcon,
      onClick: () => {
        if (usersCount && usersCount > 0) {
          showNotification(
            t('companySettings.sidebar.departments.removeLinkModal.deleteAssignedDepartmentError'),
            'error'
          );
        } else if (departments.length === 1) {
          showNotification(
            t(
              'companySettings.sidebar.departments.removeLinkModal.deleteLastDepartmentErrorMessage'
            ),
            'error'
          );
        } else if (departmentId) {
          dispatch(deleteDepartment({ departmentId }));
        }
      },
    },
  ];
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <Stack>
      <IconButton data-cy="department-action-button" onClick={handleClick}>
        <SvgIcon component={WorkbaseThreePointsIcon} fontSize="small" color="action" />
      </IconButton>
      <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
        {options.map((option) => (
          <MenuItem onClick={option.onClick} key={option.value}>
            <ListItemIcon>
              <option.startIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
}
