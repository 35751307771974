import { memo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditViewProtectedWrapper from 'src/core/EditViewProtectedWrapper';
import ModeSwitch from 'src/core/ModeSwitch';
import ModuleOverview from 'src/features/module-overview/view';
import { useShareLevelQuery } from 'src/store/api/shareResourceApi';
import { useTrainingLessonQuery } from 'src/store/api/trainings/trainingLessonsApi';
import { ShareLevel } from 'src/lib/types/share-level';

function ModuleOverviewWithModeSwitch() {
  const param = useParams();
  const { data: shareLevelData, isSuccess } = useShareLevelQuery({
    resourceType: 'training',
    resourceId: param.trainingId as string,
  });
  const navigate = useNavigate();
  const { data } = useTrainingLessonQuery(param.lessonId as string);

  const canEdit =
    shareLevelData?.shareLevel === ShareLevel.edit ||
    shareLevelData?.shareLevel === ShareLevel.manage;
  if (!isSuccess) return null;
  return (
    <EditViewProtectedWrapper
      canEdit={canEdit}
      canView={shareLevelData.shareLevel !== ShareLevel.none}
    >
      <ModeSwitch
        switchButtonVisible={false}
        switchButtonAdditionalStyles={(editMode) =>
          !editMode && data?.status === 'published' && !data.done
            ? {
                bottom: 88,
                right: 28,
              }
            : {
                right: 28,
              }
        }
      >
        {(editMode) => (
          <ModuleOverview
            editMode={canEdit ? editMode : false}
            hideTopbar
            onAddLessonCallback={(id) =>
              navigate(
                `/trainings/${param.trainingId}/${param.moduleId}/${param.sectionId}/${id}/edit`
              )
            }
          />
        )}
      </ModeSwitch>
    </EditViewProtectedWrapper>
  );
}

export default memo(ModuleOverviewWithModeSwitch);
