import { Box, SvgIconProps, useTheme } from '@mui/material';

export interface EmojiProps {
  emoji: string;
  fontSize: SvgIconProps['fontSize'];
}

export default function Emoji({ emoji, fontSize = 'small' }: EmojiProps) {
  const theme = useTheme();

  const fontSizeMapping: Record<Exclude<SvgIconProps['fontSize'], undefined>, string | number> = {
    small: theme.typography.pxToRem(20),
    medium: theme.typography.pxToRem(24),
    large: theme.typography.pxToRem(35),
    inherit: 'inherit',
  };

  const mappedFontSize = fontSizeMapping[fontSize] || fontSize;
  return (
    <Box
      fontSize={mappedFontSize}
      lineHeight={mappedFontSize}
      display="inline-flex"
      alignItems="center"
    >
      {emoji}
    </Box>
  );
}
