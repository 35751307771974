import { createApi } from '@reduxjs/toolkit/query/react';
import { IconName } from 'src/lib/components/atoms/icon';
import { baseQuery } from 'src/store/api/api';
import { sidebarApi } from './sidebarApi';

interface Page {
  id: string;
  name: string;
  icon: IconName;
}

export const pagesApi = createApi({
  reducerPath: 'pagesApi',
  tagTypes: [],
  baseQuery,
  endpoints: (builder) => ({
    postPage: builder.mutation<Page, void>({
      query: () => ({
        url: '/pages',
        method: 'POST',
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
        });
      },
    }),
    deletePage: builder.mutation<void, string>({
      query: (id) => ({
        url: `/pages/${id}`,
        method: 'DELETE',
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
        });
      },
    }),
    duplicatePage: builder.mutation<void, string>({
      query: (id) => ({
        url: `/pages/${id}/duplicate`,
        method: 'POST',
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
        });
      },
    }),
  }),
});

export const { usePostPageMutation, useDeletePageMutation, useDuplicatePageMutation } = pagesApi;
