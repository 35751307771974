import { useEffect, useState } from 'react';

export default function useElementWidth(el?: HTMLElement | null): number {
  const [elementWidth, setElementWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    if (!el) return;

    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setElementWidth(entry.contentRect.width);
      });
    });

    resizeObserver.observe(el);

    setElementWidth(el.clientWidth);
    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.unobserve(el);
      resizeObserver.disconnect();
    };
  }, [el]);

  return elementWidth;
}

export function useResizeObserver(ref: React.RefObject<HTMLDivElement>) {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (ref.current) {
      const handleResize = (entries: ResizeObserverEntry[]) => {
        const entry = entries[0];
        setDimensions({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        });
      };

      const observer = new ResizeObserver(handleResize);
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [ref]);

  return dimensions;
}
