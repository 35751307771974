import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  SvgIcon,
} from '@mui/material';
import { t } from 'i18next';
import { showNotification } from 'src/lib/components/atoms/notification';
import { WorkbaseDeleteIcon, WorkbaseThreePointsIcon } from 'src/assets/icons/workbaseIcons';
import { MouseEvent, useState } from 'react';
import { useAppDispatch } from 'src/store';
import { deleteRole, uncheckRoles } from '../../../controller/departments.slice';

interface RoleActionsMenuProps {
  roleId?: string;
  departmentId?: string;
  rolesLength?: number;
  usersCount?: number;
}

export default function RoleActionsMenu({
  roleId,
  departmentId,
  rolesLength,
  usersCount,
}: RoleActionsMenuProps) {
  const dispatch = useAppDispatch();
  const options = [
    {
      value: 'delete',
      label: 'Delete',
      startIcon: WorkbaseDeleteIcon,
      onClick: () => {
        if (usersCount && usersCount > 0) {
          showNotification(
            t('companySettings.sidebar.departments.removeLinkModal.deleteAssignedRoleError'),
            'error'
          );
        } else if (rolesLength && rolesLength < 2) {
          showNotification(
            t('companySettings.sidebar.departments.removeLinkModal.deleteOneRoleError'),
            'error'
          );
        } else if (departmentId && roleId) {
          dispatch(deleteRole({ departmentId, roleId }));
          dispatch(uncheckRoles([roleId]));
        }
      },
    },
  ];
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <Stack>
      <IconButton data-cy="role-action-button" onClick={handleClick} size="small">
        <SvgIcon component={WorkbaseThreePointsIcon} fontSize="small" color="action" />
      </IconButton>
      <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
        {options.map((option) => (
          <MenuItem onClick={option.onClick} key={option.value}>
            <ListItemIcon>
              <option.startIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
}
