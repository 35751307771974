import { t } from 'i18next';
import { Typography } from '@mui/material';
import { Role } from '../model';

interface SubtextProps {
  roles: Role[];
  isOwner: boolean;
}

export default function Subtext({ roles, isOwner }: SubtextProps) {
  const allRoles =
    roles
      .filter(({ name }) => name.trim() !== '')
      .map(({ name }) => name)
      .join(', ') || '';
  return (
    <>
      {isOwner ? (
        <>
          <Typography color="error.main" fontWeight="medium" variant="caption">
            {t('employeePage.roles.roleOwner')}
          </Typography>{' '}
          &nbsp;-&nbsp;
        </>
      ) : null}
      {allRoles}
    </>
  );
}
