import { Stack, SvgIcon, Tab, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseDarkmodeIcon } from 'src/assets/icons/workbaseIcons';
import { useAppDispatch, useAppSelector } from 'src/store';
import { setPaletteMode } from 'src/store/client/themeSettings.slice';
import darkMode from 'src/assets/images/darkMode.png';
import lightMode from 'src/assets/images/lightMode.png';
import autoMode from 'src/assets/images/autoMode.png';

export default function ThemeSettings() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const paletteMode = useAppSelector((state) => state.themeSettings.paletteMode);

  const modes = [
    {
      key: 'light',
      img: lightMode,
      label: t('generalSettings.themeSettings.lightMode'),
    },
    {
      key: 'dark',
      img: darkMode,
      label: t('generalSettings.themeSettings.darkMode'),
    },
    {
      key: 'auto',
      img: autoMode,
      label: t('generalSettings.themeSettings.autoMode'),
    },
  ] as const;

  return (
    <Stack gap={1.5}>
      <Stack direction="row" gap={1} alignItems="center">
        <SvgIcon component={WorkbaseDarkmodeIcon} />
        <Typography variant="body1" fontWeight="bold">
          {t('generalSettings.themeSettings.title')}
        </Typography>
      </Stack>
      <Tabs
        value={paletteMode}
        variant="fullWidth"
        onChange={(_, newVal) => dispatch(setPaletteMode(newVal))}
      >
        {modes.map((option) => (
          <Tab
            key={option.key}
            label={
              <Stack gap={0.5}>
                <img src={option.img} alt={option.key} style={{ width: '100%' }} />
                <Typography variant="inherit" textTransform="none">
                  {option.label}
                </Typography>
              </Stack>
            }
            value={option.key}
          />
        ))}
      </Tabs>
    </Stack>
  );
}
