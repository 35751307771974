import { useTranslation } from 'react-i18next';
import PortalComponent from 'src/lib/components/util/PortalComponent';
import { UUID } from 'uuidjs';
import { TopBarWithIcons } from 'src/ui-components/layout/top-bar';
import { Box, SxProps, Theme } from '@mui/material';
import AwarenessAvatars from 'src/features/awareness-avatars';
import { useEditor } from '../../controller/EditorContext';
import EmbeddedItemModal from '../../../../lib/components/organisms/editor/embedded/EmbeddedItemModal';
import HistoryItems from './HistoryItems';
import useBaseEditorTopBarItems from './useBaseEditorTopBarItems';

export interface EditorTopBarProps {
  scrolled?: boolean;
  rightContent?: React.ReactNode;
  // remove after bounce revamp
  sx?: SxProps<Theme>;
}

export default function EditorTopBar({ scrolled = false, rightContent, sx }: EditorTopBarProps) {
  const { t } = useTranslation();
  const { handleAddItem } = useEditor();
  const { baseEditorTopBarItems, addEmbeddedDialogOpen, setAddEmbeddedDialogOpen } =
    useBaseEditorTopBarItems();

  return (
    <PortalComponent elementSelector="#main-layout-content-card">
      <EmbeddedItemModal
        open={addEmbeddedDialogOpen}
        onClose={() => setAddEmbeddedDialogOpen(false)}
        saveButtonText={t('editor.embeddedItemModal.addConfirmButton')}
        onConfirm={({ content, contentType }) => {
          handleAddItem({
            id: UUID.generate(),
            columnEnd: 5,
            columnStart: 1,
            elementType: 'embedded',
            content,
            contentType,
          });
        }}
      />
      <Box sx={sx}>
        <TopBarWithIcons
          icons={baseEditorTopBarItems}
          rightContent={
            <>
              <AwarenessAvatars />
              <HistoryItems />
              {rightContent}
            </>
          }
          showBorder={scrolled}
        />
      </Box>
    </PortalComponent>
  );
}
