import { Button, Stack } from '@mui/material';
import {
  TrainingSection,
  usePostTrainingSectionMutation,
} from 'src/store/api/trainings/trainingSectionsApi';
import { useTranslation } from 'react-i18next';
import useScrollOnDrag from 'src/lib/hooks/use-scroll-on-drag';
import Section from './Section';

export default function Sections({
  data,
  searchTerm,
  readOnly,
  mobileView,
  moduleId,
  onAddLessonCallback,
}: {
  data: TrainingSection[];
  searchTerm: string;
  readOnly: boolean;
  mobileView: boolean;
  moduleId: string;
  onAddLessonCallback?: (id: string) => void;
}) {
  const { t } = useTranslation();
  const [postTrainingSection] = usePostTrainingSectionMutation();
  const scrollRef = useScrollOnDrag();

  return (
    <Stack
      sx={{
        height: '100%',
        overflowY: mobileView ? 'auto' : 'visible',
        paddingBottom: mobileView && readOnly ? '25px' : '0',
      }}
    >
      <Stack
        ref={scrollRef}
        spacing={1.5}
        sx={{
          overflowX: 'hidden',
          margin: mobileView ? '0 -20px 0 -19px' : 0,
          backgroundColor: 'common.white',
          borderBottomLeftRadius: (theme) => theme.shape.borderRadius * 4,
        }}
      >
        {data?.map((section) => (
          <Section
            {...section}
            mobileView={mobileView}
            readOnly={readOnly}
            key={section.id}
            searchTerm={searchTerm}
            onAddLessonCallback={onAddLessonCallback}
          />
        ))}
      </Stack>
      {!readOnly && (
        <Button
          color="inherit"
          onClick={() => {
            postTrainingSection({ trainingModuleId: moduleId }).then((resp: any) => {
              if (resp.data.id) {
                setTimeout(() => {
                  document.getElementById(resp.data.id)?.querySelector('input')?.focus();
                }, 250);
              }
            });
          }}
          sx={{ margin: '20px 0 25px' }}
        >
          {t('modulePage.addSection')}
        </Button>
      )}
    </Stack>
  );
}
