import { useState } from 'react';
import { Stack, Slider, Collapse, alpha } from '@mui/material';
import {
  WorkbaseSoundMutedIcon,
  WorkbaseSoundVol1Icon,
  WorkbaseSoundVol2Icon,
  WorkbaseSoundVol3Icon,
} from 'src/assets/icons/workbaseIcons';
import { WorkbaseIcon } from 'src/lib/components/atoms/icon';

function SoundSlider({
  muted,
  volume,
  onMute,
  onVolumeChange,
  smallVariant,
}: {
  volume: number;
  muted: boolean;
  onMute: () => void;
  onVolumeChange: (volume: number) => void;
  smallVariant: boolean;
}) {
  const [iconHover, setIconHover] = useState(false);

  const handleSound = (_event: Event, newValue: number | number[]) => {
    onVolumeChange?.(newValue as number);
  };

  const handleMute = () => {
    onMute?.();
  };

  const renderSoundIcon = (vol: number) => {
    if (vol === 0 || muted) return WorkbaseSoundMutedIcon;
    if (vol > 0 && vol <= 0.33) return WorkbaseSoundVol1Icon;
    if (vol > 0.33 && vol <= 0.66) return WorkbaseSoundVol2Icon;
    return WorkbaseSoundVol3Icon;
  };

  const SoundIcon = renderSoundIcon(volume);

  return (
    <Stack
      direction="row"
      sx={{
        mb: 1,
        backgroundColor: (theme) => alpha(theme.palette.background.default as string, 0.75),
        borderRadius: (theme) => theme.shape.borderRadius,
        cursor: 'pointer',
      }}
      alignItems="center"
      justifyContent="center"
      onMouseEnter={() => setIconHover(true)}
      onMouseLeave={() => setIconHover(false)}
    >
      <Stack onClick={handleMute} sx={{ padding: smallVariant ? '4px' : '8px' }}>
        <WorkbaseIcon icon={SoundIcon} size={smallVariant ? 12 : 14} color="secondary.text" />
      </Stack>
      <Collapse
        in={iconHover}
        orientation="horizontal"
        sx={{
          '& .MuiCollapse-wrapperInner': {
            display: 'flex',
            pr: 1,
            pl: smallVariant ? 0.1 : 1,
          },
        }}
      >
        <Slider
          aria-label="Volume"
          max={1}
          className="video-player__slider video-player__slider--sound"
          step={0.01}
          value={muted ? 0 : volume}
          onChange={handleSound}
          sx={{
            ' &.MuiSlider-root': {
              padding: smallVariant ? 0 : '13px 0',
              width: smallVariant ? '40px' : '100px',
            },
          }}
        />
      </Collapse>
    </Stack>
  );
}

export default SoundSlider;
