import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import MaskedScrollBox from 'src/ui-components/utils/masked-scroll-box';
import NavGroup from './nav-group/NavGroup';
import ToggleFullWidthArrow, { toggleFullWidthArrowClassName } from './ToggleFullWidthArrow';
import AppLogo from './AppLogo';
import AddButton from './add-button';
import SidebarTree from './tree';

interface SidebarProps {
  isFullWidth: boolean;
  onFullWidthChange: (val: boolean) => void;
}

export default function Sidebar({ onFullWidthChange, isFullWidth }: SidebarProps) {
  return (
    <Box
      height="100%"
      position="relative"
      pr={3}
      pl={2.5}
      pt={1}
      zIndex={20}
      sx={{
        '&:hover': {
          [`.${toggleFullWidthArrowClassName}`]: {
            opacity: 1,
          },
        },
      }}
    >
      <Stack gap={1} height="100%" alignItems={isFullWidth ? 'stretch' : 'center'}>
        <AppLogo fullWidth={isFullWidth} />
        <MaskedScrollBox>
          <NavGroup fullWidth={isFullWidth} />
          <SidebarTree expanded={isFullWidth} />
        </MaskedScrollBox>
        <ToggleFullWidthArrow onFullWidthChange={onFullWidthChange} isFullWidth={isFullWidth} />
        <AddButton isFullWidth={isFullWidth} />
      </Stack>
    </Box>
  );
}
