import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';
import { userPermissionsApi } from 'src/store/api/userPermissionsApi';
import { rolesApi } from 'src/store/api/roles.api';
import { Department } from '../model/types';
import { setDepartments } from './departments.slice';

export const departmentsApi = createApi({
  reducerPath: 'departmentsApi',
  tagTypes: ['Departments'],
  baseQuery,
  endpoints: (builder) => ({
    departments: builder.query<Department[], void>({
      query: () => '/departments',
      providesTags: ['Departments'],
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((resp) => {
          dispatch(setDepartments(resp.data));
        });
      },
    }),
    postDepartmentData: builder.mutation<void, Department[]>({
      query: (data) => ({
        url: `/departments`,
        body: {
          departments: data.map((department) => ({
            id: department.id,
            name: department.name,
            roles: department.roles.map((role) => ({
              id: role.id,
              name: role.name,
              order: role.order,
              permissionIds: role.permissionIds || [],
            })),
          })),
        },
        method: 'POST',
      }),
      onQueryStarted: (data, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(rolesApi.util.invalidateTags(['Roles', 'PermissionRoleCount']));
          dispatch(userPermissionsApi.util.invalidateTags(['UserPermissions']));
        });
        const disp = dispatch(
          departmentsApi.util.updateQueryData('departments', undefined, () => data)
        );
        queryFulfilled.catch(disp.undo);
      },
    }),
  }),
});

export const { useDepartmentsQuery, usePostDepartmentDataMutation } = departmentsApi;
