import { Box, InputAdornment, Stack, alpha, useTheme, Typography } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import {
  WorkbaseContentIcon,
  WorkbaseEditIcon,
  WorkbaseTrainingsIcon,
} from 'src/assets/icons/workbaseIcons';
import { WorkbaseIcon } from 'src/lib/components/atoms/icon';
import WorkbaseImage from 'src/lib/components/atoms/image';
import { WorkbaseCircularProgress } from 'src/lib/components/atoms/progress/CircularProgress';
import { WorkbaseTextField } from 'src/lib/components/atoms/text-field';

import EditableImageUpload from 'src/ui-components/custom/image-edit/EditableImageUpload';
import MenuItem from 'src/lib/components/molecules/menu-item';
import { useAppSelector } from 'src/store';
import { imageUpload } from 'src/lib/utils/imageUpload';
import { showNotification } from 'src/lib/components/atoms/notification';
import debounce from 'lodash.debounce';
import {
  useTrainingLessonsCountByIdQuery,
  useTrainingEstimatedTimeByIdQuery,
  useTrainingProgressByIdQuery,
} from 'src/store/api/trainings/trainingsApi';
import BrandedDefaultImage from 'src/lib/components/atoms/branded-default-image/BrandedDefaultImage';
import { useEditor } from '../../controller';
import TrainingBreadcrumbs from './TrainingBreadcrumbs';

interface Props {
  readOnly: boolean;
  trainingId?: string;
}

const MAX_CONTENT_WIDTH = 1240;

// eslint-disable-next-line max-statements
export default function TrainingDetailHeader({ readOnly, trainingId }: Props) {
  const theme = useTheme();
  const header = useAppSelector(
    (state) => state.editor.editorData?.trainingsDetailHeader,
    shallowEqual
  );
  const [name, setName] = useState<string>(header?.name || '');
  const [description, setDescription] = useState<string>(header?.description || '');
  const [image, setImage] = useState<string>(header?.imageSrc || '');
  const { data: estimatedTimeData } = useTrainingEstimatedTimeByIdQuery(trainingId as string, {
    skip: !trainingId,
  });
  const { data: lessonsData } = useTrainingLessonsCountByIdQuery(trainingId as string, {
    skip: !trainingId,
  });
  const { data: progressData } = useTrainingProgressByIdQuery(trainingId as string, {
    refetchOnMountOrArgChange: true,
  });
  const lessonsCount = lessonsData?.reduce(
    (acc, obj) => acc + (obj.status === 'published' ? obj.lessonsCount : 0),
    0
  );
  const estimatedTime = estimatedTimeData?.reduce(
    (acc, obj) => acc + (obj.status === 'published' ? obj.estimatedTimeToComplete : 0),
    0
  );

  const nameRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const lessonsCountLabel = t('lesson', { count: lessonsCount || 0 });
  const estimatedTimeLabel = t('minute', { count: estimatedTime || 0 });

  const { handleChangeHeaderName, handleHeaderDescriptionChange, handleHeaderImageChange } =
    useEditor();

  useEffect(() => {
    nameRef?.current?.focus();
  }, []);

  useEffect(() => {
    if (header?.name !== undefined) {
      setName(header?.name);
    }
  }, [header?.name]);

  useEffect(() => {
    if (header?.description !== undefined) {
      setDescription(header?.description);
    }
  }, [header?.description]);

  useEffect(() => {
    if (header?.imageSrc !== undefined) {
      setImage(header?.imageSrc);
    }
  }, [header?.imageSrc]);

  const imgChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files?.[0];

    if (!file) return;
    try {
      const response = await imageUpload(file);
      const { variants, id } = response.data.result;
      await handleHeaderImageChange(variants[0], id);
    } catch (err) {
      showNotification(t('validationErrorMessages.UploadImageErrorMessage'), 'error');
    }
  };
  const removeImage = async (): Promise<void> => {
    await handleHeaderImageChange('', '');
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleNameChangeDebounced = useCallback(
    debounce((val) => {
      handleChangeHeaderName(val);
    }, 300),
    [handleChangeHeaderName]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDescriptionChangeDebounced = useCallback(
    debounce((val) => {
      handleHeaderDescriptionChange(val);
    }, 300),
    [handleHeaderDescriptionChange]
  );

  if (!header) return null;

  const imageSize = {
    width: '290px',
    height: '160px',
  };

  const trainingNameToDisplay = name || t('trainingDetailPage.unnamedTraining');

  return (
    <Box>
      <TrainingBreadcrumbs trainingName={trainingNameToDisplay} />
      <Box
        sx={{
          borderRadius: theme.shape.borderRadius * 4,
          backgroundColor: 'background.default',
          marginLeft: '20px',
          marginRight: '20px',
        }}
      >
        <Box
          sx={{
            height: 202,
            flexShrink: 0,
            borderRadius: theme.shape.borderRadius * 4,
            color: 'white.permanent',
            overflow: 'hidden',
            padding: '20px 32px 22px',
            backgroundImage: `url(${header.imageSrc})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backdropFilter: 'blur(10px)',
              backgroundColor: alpha(theme.palette.primary.dark, 0.7),
            },
          }}
        >
          <Stack
            sx={{
              maxWidth: MAX_CONTENT_WIDTH,
              margin: 'auto',
              height: '100%',
              position: 'relative',
              zIndex: 2,
            }}
            alignItems="center"
            flexDirection="row"
            gap={2}
          >
            {readOnly && (
              <>
                <Box sx={{ maxWidth: 654 }}>
                  <Stack flexDirection="row" gap={2} mb={2}>
                    <WorkbaseCircularProgress
                      value={progressData?.progress || 0}
                      variant="white"
                      size="big"
                      sx={{
                        alignSelf: 'flex-start',
                      }}
                    />
                    <Stack gap={0.75} sx={{ minWidth: 0 }}>
                      <Typography
                        variant="h6"
                        noWrap
                        sx={{
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {trainingNameToDisplay}
                      </Typography>
                      <Typography variant="body1" sx={{ display: 'flex' }}>
                        <WorkbaseIcon
                          icon={WorkbaseContentIcon}
                          sx={{ width: 16, height: 16, mr: '2px' }}
                        />
                        {lessonsCountLabel} – {estimatedTimeLabel}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Typography
                    sx={{
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    fontWeight="medium"
                    variant="body1"
                  >
                    {description}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginLeft: 'auto',
                    borderRadius: theme.shape.borderRadius * 2.5,
                    overflow: 'hidden',
                    backgroundColor: 'primary.light',
                    ...imageSize,
                  }}
                >
                  {image ? (
                    <WorkbaseImage
                      src={image}
                      alt="Training img"
                      style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                      }}
                    />
                  ) : (
                    <BrandedDefaultImage name={header.defaultImage} />
                  )}
                </Box>
              </>
            )}
            {!readOnly && (
              <>
                <Box sx={{ maxWidth: 654, minWidth: 0 }}>
                  <Stack flexDirection="row" gap={2} mb={1.75} alignItems="center">
                    <WorkbaseCircularProgress
                      value={progressData?.progress || 0}
                      size="big"
                      variant="white"
                    />
                    <WorkbaseTextField
                      inputRef={nameRef}
                      autoFocus
                      size="H1"
                      value={name}
                      backgroundColor="tertiary.main"
                      placeholder={`${t('trainingDetailPage.unnamedTraining')}...`}
                      bordered={false}
                      onChange={(e) => {
                        setName(e.target.value);
                        handleNameChangeDebounced(e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <WorkbaseIcon
                              icon={WorkbaseEditIcon}
                              color="common.white"
                              sx={{ width: '16px', height: '16px' }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      sx={{ flexGrow: 1, '.MuiInputBase-root input': { height: '1.35em' } }}
                    />
                  </Stack>
                  <WorkbaseTextField
                    sx={{
                      width: '645px',
                      maxWidth: '100%',
                      '.MuiInputBase-root': {
                        backgroundColor: 'common.white',
                        borderColor: 'grey.300',
                        color: 'text.secondary',
                      },
                      '.Mui-focused:not(.Mui-error) fieldset, .MuiInputBase-root:hover fieldset': {
                        borderColor: `${theme.palette.grey[100]} !important`,
                      },
                      textarea: { padding: '0 6px' },
                    }}
                    multiline
                    value={description}
                    placeholder={t('editor.trainingsSection.moduleTitlePlaceholder')}
                    onChange={(e) => {
                      setDescription(e.target.value);
                      handleDescriptionChangeDebounced(e.target.value);
                    }}
                    maxRows={3}
                    minRows={3}
                  />
                </Box>
                <Box {...imageSize} ml="auto">
                  <EditableImageUpload
                    src={image}
                    onChange={imgChange}
                    placeholder={<BrandedDefaultImage name={header.defaultImage} />}
                    onClear={removeImage}
                  />
                </Box>
              </>
            )}
          </Stack>
        </Box>
        <Box sx={{ padding: '20px 32px 22px' }}>
          <Stack
            sx={{ maxWidth: MAX_CONTENT_WIDTH, margin: 'auto', height: '100%' }}
            alignItems="center"
            flexDirection="row"
            gap={2}
          >
            <MenuItem
              label={t('trainingDetailPage.courseOverviewButton')}
              iconColor="text.primary"
              labelColor="text.primary"
              labelVisible
              active
              icon={<WorkbaseIcon icon={WorkbaseTrainingsIcon} />}
              sx={{
                width: 'fit-content',
                minHeight: 0,
                '.MuiTypography-root': {
                  textTransform: 'none',
                },
                '.MuiListItemIcon-root': { width: 20, height: 20 },
              }}
            />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
