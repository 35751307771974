import { Box } from '@mui/material';
import HighlightItem from '../highlight-item';
import { ResizeStopCallbackType } from '../highlight-item/resize/ResizableWrapper';

interface Props {
  onDelete: () => void;
  onDuplicate: () => void;
  readOnly?: boolean;
  height?: number;
  onResizeStop?: ResizeStopCallbackType;
  dragging?: any;
  onResizeStart?: () => void;
}

export default function SpacerItem({
  onDelete,
  onDuplicate,
  readOnly,
  onResizeStop,
  height,
  dragging,
  onResizeStart,
}: Props) {
  const spacerComponent = (
    <Box
      className="editor-vertical-resizable"
      sx={{ backgroundColor: readOnly ? 'transparent' : 'secondary.background' }}
      style={{ height, minHeight: '30px' }}
    />
  );

  return (
    <HighlightItem
      editBarProps={{ baseActionsProps: { onDelete, onDuplicate } }}
      horizontalResize={{}}
      verticalResize={{}}
      dragging={dragging}
      bordered
      borderColor="'grey.300'"
      borderStyle="dashed"
      readOnly={readOnly}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
    >
      {({ EditBarComponent }) => (
        <>
          {EditBarComponent}
          {spacerComponent}
        </>
      )}
    </HighlightItem>
  );
}
