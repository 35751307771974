import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Button, Card, CardContent, CardHeader, Stack } from '@mui/material';
import WorkbaseImage from '../lib/components/atoms/image/Image';

interface Icon {
  icon: string;
  alt: string;
}

export type UnauthorizedLayoutProps = {
  favicon: Icon;
  logo: Icon;
  title?: string;
  children: React.ReactNode;
  languageDropdown?: React.ReactNode;
};

function UnauthorizedLayout({
  favicon,
  logo,
  title,
  children,
  languageDropdown,
}: UnauthorizedLayoutProps) {
  const { t } = useTranslation();
  return (
    <Box p={2} height="100vh" display="flex" justifyContent="space-between" flexDirection="column">
      <Box display="flex" justifyContent="space-between">
        <WorkbaseImage src={favicon.icon} alt={favicon.alt} width={24} />
        {languageDropdown}
      </Box>
      <Stack gap={3} alignItems="center" width="100%">
        <WorkbaseImage src={logo.icon} alt={logo.alt} height={28} />
        <Card sx={{ maxWidth: 450, width: '100%' }}>
          <CardHeader
            title={title}
            sx={{ px: 4, py: 3 }}
            titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
          />
          <CardContent sx={{ px: 4, py: 3 }}>{children}</CardContent>
        </Card>
      </Stack>
      <Stack gap={1} direction="row" alignSelf="center">
        <Button
          variant="text"
          color="inherit"
          size="small"
          href="https://www.workbase.com/"
          target="_blank"
        >
          Workbase © 2023
        </Button>
        <Button
          variant="text"
          color="inherit"
          size="small"
          href="https://www.workbase.com/legal"
          target="_blank"
        >
          {t('unauthorizedPage.footer.legalLabel')}
        </Button>
        <Button
          variant="text"
          color="inherit"
          size="small"
          href="https://www.workbase.com/help"
          target="_blank"
        >
          {t('unauthorizedPage.footer.supportLabel')}
        </Button>
      </Stack>
    </Box>
  );
}

export default UnauthorizedLayout;
