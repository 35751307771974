import { DropOptions, NodeModel } from '@minoru/react-dnd-treeview';
import { TreeNodeData } from './types';

interface Props {
  tree: NodeModel<TreeNodeData>[];
  toggle: (id: number | string) => void;
  openedNodes: (number | string)[];
  onDrop: (tree: NodeModel<TreeNodeData>[], options: DropOptions<TreeNodeData>) => void;
}

export default function useOnDrop({ tree, toggle, openedNodes, onDrop }: Props) {
  const isDroppingIntoItself = (options: DropOptions<TreeNodeData>) =>
    options.dropTargetId === options.dragSourceId;

  const getOldSiblings = (options: DropOptions<TreeNodeData>) =>
    tree.filter((node) => node.parent === options.dropTargetId);

  const getDropIndex = (
    oldSiblings: NodeModel<TreeNodeData>[],
    options: DropOptions<TreeNodeData>
  ) => oldSiblings.findIndex((node) => node.id === options.dragSourceId);

  const isDroppingIntoSameIndex = (options: DropOptions<TreeNodeData>, dropIndex: number) =>
    options.dragSource?.parent === 0 &&
    options.dropTarget === undefined &&
    (options.relativeIndex === dropIndex || options.relativeIndex === dropIndex + 1);

  const handleDropIntoOpenedNode = (
    newTree: NodeModel<TreeNodeData>[],
    options: DropOptions<TreeNodeData>
  ) => {
    const parentNodeId = options.dropTargetId;
    const draggedNodeIndex = newTree.findIndex((node) => node.id === options.dragSourceId);
    const draggedNode = newTree[draggedNodeIndex];

    const filteredTree = newTree.filter((node) => node.id !== options.dragSourceId);

    const siblings = filteredTree.filter((node) => node.parent === parentNodeId);
    const newTreeWithDraggedNodeAtEnd = [
      ...filteredTree.slice(0, draggedNodeIndex),
      ...filteredTree.slice(draggedNodeIndex + 1),
      ...siblings,
      draggedNode,
    ];

    onDrop(newTreeWithDraggedNodeAtEnd, options);
    toggle(options.dropTargetId);
  };

  const handleDrop = (newTree: NodeModel<TreeNodeData>[], options: DropOptions<TreeNodeData>) => {
    if (isDroppingIntoItself(options)) return;

    const oldSiblings = getOldSiblings(options);
    const dropIndex = getDropIndex(oldSiblings, options);

    if (isDroppingIntoSameIndex(options, dropIndex)) return;

    const isDroppingIntoOpenedNode = openedNodes.includes(options.dropTargetId);

    if (!isDroppingIntoOpenedNode && options.dropTargetId !== options.dragSourceId) {
      handleDropIntoOpenedNode(newTree, options);
    } else {
      onDrop(newTree, options);
    }
  };
  return handleDrop;
}
