import { Alert, Snackbar } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { videoUpload } from 'src/lib/utils/videoUpload';
import { showNotification } from 'src/lib/components/atoms/notification';
import { VideoProgress } from 'src/store/api/trainings/videoProgressApi';
import { useAppSelector } from 'src/store';
import {
  addVideoBlobData,
  setRecordingModalPayload,
} from 'src/features/editor/controller/Editor.slice';
import { useDispatch } from 'react-redux';
import { EDITOR } from 'src/lib/constants/editor';
import { imageUpload } from 'src/lib/utils/imageUpload';
import ReactPlayer from 'react-player';
import HighlightItem from '../highlight-item';
import { ResizeStopCallbackType } from '../highlight-item/resize/ResizableWrapper';
import { allowedFileTypes, MAX_FILESIZE } from '../upload-item/config';
import useUploadingMedia from '../hooks/useUploadingMedia';
import WorkbasePlayer from './player/Player';

export interface VideoProps {
  id: string;
  title: string;
  onDelete: () => void;
  onDuplicate: () => void;
  onReplaceVideo: (videoId: string, poster: string) => void;
  onUpdateVideoPoster: (poster?: string) => void;
  onUpdateVideoTitle: (title: string) => void;
  onVideoDownload: () => void;
  readOnly?: boolean;
  onResizeStop?: ResizeStopCallbackType;
  url: string;
  videoPoster?: string;
  dragging?: {
    dragRef: ConnectDragSource;
    previewRef: ConnectDragPreview;
  };
  onResizeStart?: () => void;
  height?: number;
  aspectRatio?: number;
  isFastForwardDisabled?: boolean;
  onDisableFastForward?: (isFastForwardDisabled: boolean) => void;
  isVideoRequired?: boolean;
  onRequired?: (isVideoRequired: boolean) => void;
  watchProgress?: number;
  onVideoProgress?: (data: Partial<VideoProgress>) => void;
  documentName?: string;
  videoId: string;
  isVideoUploaded?: boolean;
}

// eslint-disable-next-line max-statements
export default function VideoItem({
  id,
  onDelete,
  onDuplicate,
  readOnly = false,
  onResizeStop,
  onVideoDownload,
  url,
  videoPoster,
  dragging,
  onResizeStart,
  onReplaceVideo,
  onUpdateVideoTitle,
  title,
  height,
  aspectRatio,
  onUpdateVideoPoster,
  onDisableFastForward,
  isFastForwardDisabled = false,
  onRequired,
  isVideoRequired = false,
  watchProgress = 0,
  onVideoProgress,
  documentName,
  videoId,
  isVideoUploaded,
}: VideoProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const videoRef = useRef<ReactPlayer | null>(null);
  const [percentage, setPercentage] = useState(0);
  const snackBarHandler = useCallback(() => {
    setErrorMessage(t('validationErrorMessages.generalUploadErrorMessage'));
    setShowSnackbar(true);
  }, [t]);
  const blobData = useAppSelector((state) => state.editor.videoBlobData) || {};
  const { handleClearUploading, handleAddingUploading } = useUploadingMedia(id, 'video');

  const handleClose = () => setShowSnackbar(false);
  const handleDisableForward = () => onDisableFastForward?.(!isFastForwardDisabled);
  const handleRequired = () => onRequired?.(!isVideoRequired);
  const dispatch = useDispatch();

  function getCurrentFrame(videoForFrame: any): Promise<Blob | null> {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      canvas.width = videoForFrame.videoWidth;
      canvas.height = videoForFrame.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(videoForFrame, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg');
    });
  }

  const handleThumbnailUpload = useCallback(
    async (thumbnail: Blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(thumbnail);
      try {
        reader.onload = () =>
          sessionStorage.setItem('fallbackVideoPosterSrc', reader.result as string);
        // eslint-disable-next-line no-empty
      } catch (err) {}

      setLoading(true);
      const response = await imageUpload(new File([thumbnail], 'thumbnail.jpg'));

      if (response.status !== 201) {
        sessionStorage.removeItem('fallbackVideoPosterSrc');
        snackBarHandler();
        setLoading(false);
      }

      const { result } = response.data;
      const { variants } = result;
      const [imageUrl] = variants;
      onUpdateVideoPoster(imageUrl);
      setLoading(false);
    },
    [onUpdateVideoPoster, snackBarHandler]
  );
  useEffect(() => {
    const videoElement = videoRef.current?.getInternalPlayer();
    if (videoElement && !videoPoster) {
      const handleLoadedData = async () => {
        // eslint-disable-next-line no-promise-executor-return -- delay slightly to ensure video is ready
        await new Promise((resolve) => setTimeout(resolve, 100));
        const frameBlob = await getCurrentFrame(videoElement);
        if (frameBlob) {
          handleThumbnailUpload(frameBlob);
        }
      };

      videoElement.addEventListener('canplay', handleLoadedData);

      return () => {
        videoElement.removeEventListener('canplay', handleLoadedData);
      };
    }
    return undefined;
  }, [handleThumbnailUpload, videoPoster]);

  const handleVideoUpload = async (acceptedFiles: File[]) => {
    const newFile = acceptedFiles[0];
    if (newFile.size > MAX_FILESIZE) {
      showNotification(t('validationErrorMessages.UploadFileSizeErrorMessage'), 'error');
      setLoading(false);
      return;
    }
    const onVideoUpload = (vId: string) => {
      onReplaceVideo(vId, '');
      handleClearUploading();
    };
    try {
      handleAddingUploading();
      const uploadData = await videoUpload({
        file: newFile,
        errorMessage: t('validationErrorMessages.UploadFailed'),
        onVideoUploadSuccess: onVideoUpload,
        setPercentage,
        documentName,
      });
      dispatch(addVideoBlobData({ id: uploadData.videoId, blobUrl: uploadData.localUrl }));
    } catch {
      showNotification(t('validationErrorMessages.UploadFailed'), 'error');
      handleClearUploading();
    }
  };

  const handleThumbnailUploadFromCurrentFrame = async () => {
    const player = videoRef.current?.getInternalPlayer();
    player?.pause();

    if (player?.currentTime === 0) {
      onUpdateVideoPoster();
    } else {
      const currentFrame = await getCurrentFrame(player);
      if (currentFrame !== null) handleThumbnailUpload(currentFrame);
    }
  };

  const handleTitleChange = (newTitle: string) => onUpdateVideoTitle(newTitle);

  const { open: openForPoster } = useDropzone({
    onDrop: (acceptedFiles: File[]) => handleThumbnailUpload(acceptedFiles[0]),
    noDrag: true,
    accept: {
      ...allowedFileTypes.image,
    },
  });

  const { getInputProps, open: openForVideo } = useDropzone({
    onDrop: handleVideoUpload,
    noDrag: true,
    accept: {
      ...allowedFileTypes.video,
    },
  });
  const videoComponent = (
    <WorkbasePlayer
      url={blobData[videoId || ''] || url}
      width="100%"
      light={videoPoster}
      ref={videoRef}
      height={height}
      title={title}
      readOnly={readOnly}
      fallbackPosterSrc={sessionStorage.getItem('fallbackVideoPosterSrc')}
      onFallbackPosterLoaded={() => sessionStorage.removeItem('fallbackVideoPosterSrc')}
      aspectRatio={aspectRatio}
      isVideoUploaded={isVideoUploaded}
      onVideoProgress={onVideoProgress}
      onTitleChange={handleTitleChange}
      disableFastForward={isFastForwardDisabled}
      required={isVideoRequired}
      newUploadPercentage={percentage}
      watchProgress={watchProgress}
    />
  );

  if (readOnly) {
    return videoComponent;
  }
  return (
    <HighlightItem
      editBarProps={{
        videoActionsProps: {
          onUploadPoster: openForPoster,
          onUploadVideo: () => openForVideo(),
          onUploadPosterFromCurrentFrame: handleThumbnailUploadFromCurrentFrame,
          onVideoDownload,
          onDisableFastForward: handleDisableForward,
          onRequired: handleRequired,
          isVideoRequired,
          disableFastForward: isFastForwardDisabled,
          showRequired: documentName?.split('.')[0] === 'trainingLesson',
          onOpenRecordingModal: () => {
            dispatch(
              setRecordingModalPayload({
                id,
                height: videoRef.current?.getInternalPlayer().clientHeight || (160 as number),
                onUploadProgress: setPercentage,
              })
            );
          },
          downloadDisabled: loading || !isVideoUploaded,
        },
        baseActionsProps: {
          onDelete,
          onDuplicate,
          hideDuplicate: documentName?.split('.')[0] === 'trainingLesson',
        },
      }}
      dragging={dragging}
      horizontalResize={{}}
      verticalResize={{}}
      readOnly={readOnly}
      onResizeStop={onResizeStop}
      bordered
      onResizeStart={onResizeStart}
      minHeight={EDITOR.verticalResizeMinHeightMedia}
    >
      {({ EditBarComponent }) => (
        <>
          {EditBarComponent}
          <>
            {videoComponent}
            <input {...getInputProps()} />
            <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleClose}>
              <Alert severity="error">{errorMessage}</Alert>
            </Snackbar>
          </>
        </>
      )}
    </HighlightItem>
  );
}
