import WorkbaseDialogComponent from 'src/lib/components/molecules/dialog/Dialog';
import { WorkbaseIconByName } from 'src/lib/components/atoms/icon';
import ScreenCameraRecorder from 'src/features/screen-camera-recorder';
import { useAppDispatch, useAppSelector } from 'src/store';
import {
  addVideoBlobData,
  setRecordingModalPayload,
} from 'src/features/editor/controller/Editor.slice';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { videoUpload } from 'src/lib/utils/videoUpload';
import { showNotification } from 'src/lib/components/atoms/notification';
import { IconButton } from '@mui/material';
import { useEditor } from '../controller';
import { DocumentNameContext } from './DocumentNameContext';

export default function RecordingModal() {
  const dispatch = useAppDispatch();
  const recordingModalPayload = useAppSelector((state) => state.editor.recordingModalPayload);
  const { handleSetVideo } = useEditor();
  const documentName = useContext(DocumentNameContext);
  const { t } = useTranslation();

  const handleClearRecordingModalPayload = useCallback(
    () => dispatch(setRecordingModalPayload(undefined)),
    [dispatch]
  );
  const uploadVideo = useCallback(
    async (newFile: File) => {
      if (!recordingModalPayload) return undefined;
      recordingModalPayload.onLoading?.(true);
      const abortController = new AbortController();
      const { signal } = abortController;

      const uploadVid = (videoId: string) => {
        handleSetVideo(recordingModalPayload.id)({
          url: null,
          fileName: newFile.name,
          type: 'video',
          videoPoster: '',
          height: recordingModalPayload.height,
          videoId,
          isVideoUploaded: false,
        });
        recordingModalPayload.onLoading?.(false);
      };
      try {
        const uploadData = await videoUpload({
          file: newFile,
          errorMessage: t('validationErrorMessages.UploadFailed'),
          setPercentage: recordingModalPayload.onUploadProgress,
          onVideoUploadSuccess: uploadVid,
          documentName,
          signal,
        });
        dispatch(addVideoBlobData({ id: uploadData.videoId, blobUrl: uploadData.localUrl }));
      } catch {
        showNotification(t('validationErrorMessages.UploadFailed'), 'error');
        recordingModalPayload.onLoading?.(false);
      }
      return undefined;
    },
    [dispatch, documentName, handleSetVideo, t, recordingModalPayload]
  );

  const onRecordingFinish = useCallback(
    (blob: Blob) => {
      const f = new File([blob], 'recording.mp4', { type: 'video/mp4' });
      uploadVideo(f);
      handleClearRecordingModalPayload();
    },
    [uploadVideo, handleClearRecordingModalPayload]
  );

  return (
    <WorkbaseDialogComponent
      onClose={() => {}}
      open={!!recordingModalPayload}
      cancelButton={false}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: 1240,
          width: '100%',
        },
        '.MuiDialog-container': {
          overflow: 'auto',
        },
        '& .MuiDialogContent-root': {
          display: 'flex',
        },
      }}
    >
      <IconButton
        onClick={handleClearRecordingModalPayload}
        sx={{ position: 'fixed', right: 20, top: 20 }}
      >
        <WorkbaseIconByName iconName="WorkbaseCloseIcon" />
      </IconButton>
      <ScreenCameraRecorder onRecordingFinish={onRecordingFinish} />
    </WorkbaseDialogComponent>
  );
}
