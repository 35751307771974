import { Employee, EmployeeStatuses } from 'src/features/employees/model';

// eslint-disable-next-line no-useless-escape
export const noSpecialCharsRegex = /^[\u00C0-\u017Fa-zA-Z\-\' ]+$/;

export function validateEmployeeData(employeeDraft: Employee, t: Function): string | null {
  if (employeeDraft.status === EmployeeStatuses.invited) return null;

  if (
    (employeeDraft?.lastName.trim() === '' || employeeDraft?.firstName.trim() === '') &&
    employeeDraft?.status === EmployeeStatuses.active
  ) {
    return t('employeePage.employeeDetails.contactInfomation.emptyName');
  }
  if (employeeDraft?.lastName.length > 35 || employeeDraft?.firstName.length > 35) {
    return t('employeePage.employeeDetails.contactInfomation.nameLengthError');
  }
  if (
    !noSpecialCharsRegex.test(employeeDraft?.lastName.trim() as string) ||
    !noSpecialCharsRegex.test(employeeDraft?.firstName.trim() as string)
  ) {
    return t('employeePage.employeeDetails.contactInfomation.specialCharsError');
  }
  return null;
}

export const stringValidation = (
  value: string,
  t: (key: string) => string
): { hasError: boolean; errorMessage: string } => {
  let errorMessage = '';

  if (value.trim() === '') {
    errorMessage = t('validationErrorMessages.requiredErrorMsg');
  } else if (!noSpecialCharsRegex.test(value)) {
    errorMessage = t('validationErrorMessages.specialCharsErrorMsg');
  } else if (value.length > 35) {
    errorMessage = t('validationErrorMessages.fieldLengthErrorMsg');
  }

  return {
    hasError: errorMessage !== '',
    errorMessage,
  };
};
