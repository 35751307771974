import { Box, Divider, IconButton, Stack } from '@mui/material';
import { TrainingType } from 'src/features/editor/model';
import WorkbaseTooltip from 'src/lib/components/atoms/tooltip';
import { t } from 'i18next';
import { WorkbaseIcon, WorkbaseIconType } from '../../../../atoms/icon/WorkbaseIcon';
import {
  WorkbaseTrainingsIcon,
  WorkbaseModuleIcon,
} from '../../../../../../assets/icons/workbaseIcons';
import TrainingsSelection, { TrainingsSelectionProps } from './TrainingsSelection';

export interface TrainingsActionsProps extends TrainingsSelectionProps {
  handleTrainingsTypeChange: (TrainingsType: TrainingType) => void;
}

export default function TrainingsActions({
  options,
  selected,
  onChange,
  handleTrainingsTypeChange,
}: TrainingsActionsProps) {
  const items: { key: TrainingType; icon: WorkbaseIconType }[] = [
    {
      key: 'trainings',
      icon: WorkbaseTrainingsIcon,
    },
    {
      key: 'modules',
      icon: WorkbaseModuleIcon,
    },
  ];
  return (
    <>
      <Box sx={{ padding: '3px 5px 5px 0' }}>
        <TrainingsSelection options={options} selected={selected} onChange={onChange} />
      </Box>
      <Divider orientation="vertical" flexItem />
      <Stack direction="row" gap="6px" sx={{ padding: '3px 5px 5px 5px' }}>
        {items.map(({ key, icon }) => (
          <WorkbaseTooltip title={t(`editor.topBar.tooltip.${key}`)} placement="top">
            <span>
              <IconButton
                key={key}
                color="inherit"
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
                onClick={() => handleTrainingsTypeChange(key)}
              >
                <WorkbaseIcon icon={icon} sx={{ '&&&': { width: 16, height: 16 } }} />
              </IconButton>
            </span>
          </WorkbaseTooltip>
        ))}
      </Stack>
    </>
  );
}
