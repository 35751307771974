import { GridItem, GridRow, GridTrainingsItem } from '../../model/types';

const findItemInColumn = (items: GridItem[], column: number) =>
  items.find((it) => it.columnStart <= column && it.columnEnd > column);

export default findItemInColumn;

export const isItemEmpty = (item: GridItem) => {
  if (item.elementType === 'upload') return true;
  if (item.elementType === 'trainings' && (item as GridTrainingsItem)?.selectedItems?.length === 0)
    return true;
  return false;
};

export const isEmptyRowInReadOnly = (row: GridRow): boolean =>
  row.items.every((item) => isItemEmpty(item));
