import { TopBar } from 'src/ui-components/layout/top-bar';
import EmbeddedAppInputs from './EmbeddedAppInputs';
import EmbeddedAppActions from './EmbeddedAppActions';

interface Props {
  id: string;
  name?: string;
  hasManagePermission?: boolean;
  hasAddPermission?: boolean;
}

function EmbeddedAppTopBar({
  id,
  name,
  hasManagePermission = false,
  hasAddPermission = false,
}: Props) {
  return (
    <TopBar
      leftContent={<EmbeddedAppInputs id={id} />}
      rightContent={
        <EmbeddedAppActions
          id={id}
          name={name}
          hasManagePermission={hasManagePermission}
          hasAddPermission={hasAddPermission}
        />
      }
    />
  );
}

export default EmbeddedAppTopBar;
