import normalizeString from 'src/lib/utils/normalizeString';
import { Department } from '../../model/types';

export function filterDepartmentsBySearchVal(data: Department[], searchVal: string): Department[] {
  // Normalize the searchVal
  const searchTerm = normalizeString(searchVal);

  // Filter the groupData array and keep sections with matching items
  const filteredGroups = data
    .map((group) => {
      // Filter the data array inside each group
      const normalizedGroupName = normalizeString(group.name);
      const isSearchTermMatchGroupName = normalizedGroupName.includes(searchTerm);
      if (isSearchTermMatchGroupName) return group;

      const filteredRoles = group.roles.filter((item) => {
        // Normalize and compare strings
        const searchText = normalizeString(item.name);
        return searchText.includes(searchTerm);
      });
      // Include the group only if it has matching items
      return { ...group, roles: filteredRoles };
    })
    .filter((group) => group.roles.length !== 0);
  return filteredGroups;
}
