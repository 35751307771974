import { enUS as enUSAdapter, de as deDEAdapter } from 'date-fns/locale';
// core
import { enUS as enUSCore, deDE as deDECore } from '@mui/material/locale';
// date-pickers
import { enUS as enUSDate, deDE as deDEDate } from '@mui/x-date-pickers/locales';
import merge from 'lodash.merge';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enUSDate, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:gb-nir',
  },
  {
    label: 'German',
    value: 'de',
    systemValue: merge(deDEDate, deDECore),
    adapterLocale: deDEAdapter,
    icon: 'flagpack:de',
  },
];

export const defaultLang = allLangs[0]; // English

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
