import { Box, Paper } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { useRef } from 'react';
import { CONTENT_RIGHT_GAP, SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_FULL_WIDTH } from './layoutConstants';

function LayoutWithOneCard({ children }: Readonly<{ children: any }>) {
  const contentBoxRef = useRef<HTMLDivElement>(null);
  const { isSidebarFullWidth } = useOutletContext<{
    isSidebarFullWidth: boolean;
  }>();

  return (
    <>
      <Box
        id="main-layout-content-card"
        sx={{
          mt: 'auto',
          ml: 'auto',
          position: 'fixed',
          left: isSidebarFullWidth ? `${SIDEBAR_FULL_WIDTH}px` : `${SIDEBAR_COLLAPSED_WIDTH}px`,
          right: `${CONTENT_RIGHT_GAP}px`,
          top: '74px',
          bottom: '20px',
          zIndex: 5,
          pointerEvents: 'none',
          overflow: 'hidden',
          '& > *': {
            pointerEvents: 'auto',
          },
        }}
      />
      <Paper
        ref={contentBoxRef}
        elevation={1}
        sx={{
          mt: '76px',
          ml: 'auto',
          mr: 0,
          width: `calc(100% - ${
            isSidebarFullWidth ? `${SIDEBAR_FULL_WIDTH}px` : `${SIDEBAR_COLLAPSED_WIDTH}px`
          })`,
          height: 'calc(100% - 64px)',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {children}
      </Paper>
    </>
  );
}

export default LayoutWithOneCard;
