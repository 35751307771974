import { Avatar, AvatarProps, SvgIcon, SvgIconProps } from '@mui/material';
import { WorkbaseEmployeeIcon } from 'src/assets/icons/workbaseIcons';

export interface EmployeeAvatarProps {
  avatarProps?: AvatarProps;
  iconProps?: SvgIconProps;
}

export default function EmployeeAvatar({ avatarProps, iconProps }: EmployeeAvatarProps) {
  return (
    <Avatar data-cy="employee-avatar" {...avatarProps}>
      <SvgIcon component={WorkbaseEmployeeIcon} fontSize="small" {...iconProps} />
    </Avatar>
  );
}
