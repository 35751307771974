import { t } from 'i18next';
import { useEffect, useRef } from 'react';
import { throttledShowNotification } from 'src/lib/components/atoms/notification';

const useScreenStream = (
  screenStream: MediaStream | null,
  onScreenStreamSelect: (val: MediaStream | null) => void
) => {
  const trackRefs = useRef<MediaStreamTrack[]>([]);

  useEffect(() => {
    const handleTrackEnded = () => {
      onScreenStreamSelect(null);
    };
    if (screenStream) {
      screenStream.getTracks().forEach((track) => {
        track.addEventListener('ended', handleTrackEnded);
        trackRefs.current.push(track);
      });
    }

    return () => {
      trackRefs.current.forEach((track) => {
        track.removeEventListener('ended', handleTrackEnded);
      });
      if (screenStream) {
        screenStream.getTracks().forEach((track) => track.stop());
      }
      trackRefs.current = [];
    };
  }, [screenStream, onScreenStreamSelect]);

  const getScreenStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          displaySurface: 'monitor',
        },
      });
      onScreenStreamSelect(stream);
    } catch (err: any) {
      if (err.name === 'NotAllowedError' && err.message !== 'Permission denied') {
        throttledShowNotification(t('recording.recordingPermissionDenied'), 'error');
      }
    }
  };

  const stopScreenStream = () => {
    if (screenStream) {
      screenStream.getTracks().forEach((track) => track.stop());
      onScreenStreamSelect(null);
    }
  };

  return { getScreenStream, stopScreenStream };
};

export default useScreenStream;
