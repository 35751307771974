import { forwardRef } from 'react';
import { alpha, Box, Stack } from '@mui/material';

export interface TopBarProps {
  leftContent?: React.ReactNode;
  showBorder?: boolean;
  rightContent?: React.ReactNode;
}
export default forwardRef(({ leftContent, rightContent, showBorder }: TopBarProps, ref) => (
  <Box
    width="100%"
    zIndex={4}
    py={1}
    px={2}
    position="relative"
    sx={{
      backdropFilter: 'blur(8px)',
      backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.6),
      borderBottomStyle: showBorder ? 'solid' : 'none',
      borderBottomWidth: 1,
      borderColor: (theme) => theme.palette.divider,
      pointerEvents: 'auto',
    }}
    ref={ref}
  >
    <Stack direction="row" justifyContent="space-between" gap={2} alignItems="center">
      <Stack gap={1} direction="row" alignItems="center" flexShrink={1} minWidth={0}>
        {leftContent}
      </Stack>
      <Stack gap={1} direction="row" alignItems="center">
        {rightContent}
      </Stack>
    </Stack>
  </Box>
));
