import { Box, Button, Stack, SvgIcon, Typography } from '@mui/material';
import WorkbaseImage from 'src/lib/components/atoms/image';
import BrandedDefaultImage from 'src/lib/components/atoms/branded-default-image';
import { useTranslation } from 'react-i18next';
import { WorkbaseCircularProgress } from 'src/lib/components/atoms/progress/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { WorkbaseIconByName } from 'src/lib/components/atoms/icon';
import { NewsfeedItem, NewsfeedLessonItemId } from 'src/store/api/newsfeedApi';
import { WorkbaseArrowRightIcon } from 'src/assets/icons/workbaseIcons';

const imageSize = {
  width: 230,
  height: 130,
};

export default function NewsfeedSliderItem({
  imageUrl,
  defaultImage,
  progress,
  name,
  id,
  type,
}: NewsfeedItem) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const idAsLessonId = id as NewsfeedLessonItemId;
  const typeDict = {
    training: {
      header: t('dashboardPage.header.newTrainingForYou'),
      buttonText: t('dashboardPage.header.startNewTraining'),
      buttonNavigatePath: `/trainings/${id}`,
      unnamedText: t('trainingDetailPage.unnamedTraining'),
    },
    trainingLesson: {
      header: t('dashboardPage.header.newLessonForYou'),
      buttonText: t('dashboardPage.header.startNewLesson'),
      buttonNavigatePath: `/trainings/${idAsLessonId.training}/${idAsLessonId.module}/${idAsLessonId.section}/${idAsLessonId.lesson}`,
      unnamedText: t('modulePage.unnamedLesson'),
    },
  };
  const typeData = typeDict[type];
  return (
    <Stack gap={2.5} sx={{ maxWidth: 699, margin: 'auto' }}>
      <Stack gap={1} direction="row" justifyContent="center" alignItems="center">
        <WorkbaseIconByName iconName="WorkbaseTrainingsIcon" size={22} color="text.primary" />
        <Typography variant="h5" color="text.primary">
          {typeData.header}
        </Typography>
      </Stack>
      <Stack direction="row" gap={2.5}>
        {imageUrl && (
          <Box
            sx={{
              border: (theme) => `1px solid ${theme.palette.grey[100]}`,
              borderRadius: (theme) => theme.shape.borderRadius * 2.5,
              overflow: 'hidden',
              objectFit: 'cover',
              backgroundSize: 'cover',
              ...imageSize,
            }}
          >
            <WorkbaseImage
              style={{ objectFit: 'cover', width: '100%', height: '100%' }}
              src={imageUrl}
              alt=""
            />
          </Box>
        )}
        {!imageUrl && (
          <BrandedDefaultImage
            sx={{
              ...imageSize,
              borderRadius: (theme) => theme.shape.borderRadius * 2.5,
              border: (theme) => `1px solid ${theme.palette.grey[100]}`,
            }}
            name={defaultImage}
          />
        )}
        <Stack gap={1.25} sx={{ flexGrow: 1, minWidth: 0 }}>
          <Box
            sx={{
              backgroundColor: 'backgroundHover.main',
              borderRadius: (theme) => theme.shape.borderRadius * 2.5,
              padding: '4px 10px',
              width: 'max-content',
            }}
          >
            <Typography variant="body2" fontWeight="medium" color="text.secondary">
              {t('required').toUpperCase()}
            </Typography>
          </Box>
          <Stack direction="row" gap={1.25} alignItems="center">
            <WorkbaseCircularProgress value={progress} />
            <Typography variant="h5" noWrap color="text.primary">
              {name || typeData.unnamedText}
            </Typography>
          </Stack>
          <Button
            sx={{ mt: 1.5 }}
            fullWidth
            onClick={() => navigate(typeData.buttonNavigatePath)}
            endIcon={
              <SvgIcon>
                <WorkbaseArrowRightIcon />
              </SvgIcon>
            }
          >
            {typeData.buttonText}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
