import { Box, Grid } from '@mui/material';
import { useDrop } from 'react-dnd';
import { useEditor } from 'src/features/editor/controller';
import { GridItem, GridRow } from 'src/features/editor/model';

interface Props {
  borderColor: string;
  position: 'left' | 'right';
  row: GridRow;
}

const getCanDrop = ({
  row,
  position,
  dragItemRow,
  dragItem,
}: {
  row: GridRow;
  position: 'left' | 'right';
  dragItemRow: GridRow;
  dragItem: GridItem;
}) => {
  const isSideOccupied = row.items.find(
    (i) => i[position === 'left' ? 'columnStart' : 'columnEnd'] === (position === 'left' ? 1 : 5)
  );
  const isDraggingWithinCurrentRow = dragItemRow?.id === row.id;
  let isDraggingItemToSamePosition = false;
  if (isDraggingWithinCurrentRow) {
    if (position === 'left') {
      isDraggingItemToSamePosition = dragItem.columnStart === 1;
    } else {
      isDraggingItemToSamePosition = dragItem.columnEnd === 5;
    }
  } else {
    isDraggingItemToSamePosition = false;
  }
  const isDraggingHeadingItem = dragItem?.elementType === 'heading';
  const canDrop =
    isSideOccupied &&
    (isDraggingWithinCurrentRow || true) &&
    !isDraggingItemToSamePosition &&
    !isDraggingHeadingItem;
  return canDrop;
};

export default function DroppableSideRowItem({ borderColor, position, row }: Props) {
  const { handleDragAndDropToSideOfRow } = useEditor();
  const [{ isOver, dragItemRow, dragItem }, drop] = useDrop({
    accept: 'GRID_ITEM',
    drop: (item: { rowIndex: number; item: GridItem; row: GridRow }, monitor) => {
      if (monitor && getCanDrop({ row, position, dragItemRow, dragItem })) {
        handleDragAndDropToSideOfRow(item.item.id, row.id, position);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      dragItemRow: monitor.getItem()?.row,
      dragItem: monitor.getItem()?.item,
    }),
  });
  const canDrop = getCanDrop({ row, position, dragItemRow, dragItem });
  const borderStyles =
    position === 'left' ? { borderRightWidth: '1px' } : { borderLeftWidth: '1px' };
  return (
    <Grid
      item
      ref={drop}
      className="side-row-item"
      sx={{
        borderColor,
        borderWidth: 0,
        borderStyle: 'dashed',
        minWidth: '32px',
        paddingRight: '5px',
        paddingColor: 'primary.main',
        position: 'relative',
        flexShrink: 1,
        ...borderStyles,
      }}
    >
      <Box
        sx={{
          width: '5px',
          height: 'calc(100% - 10px)',
          marginTop: '5px',
          marginBottom: '5px',
          marginRight: position === 'left' ? '5px' : 'auto',
          backgroundColor: 'info.main',
          borderRadius: (theme) => theme.shape.borderRadius,
          opacity: isOver && canDrop ? 1 : 0,
          marginLeft: position === 'left' ? 'auto' : '5px',
        }}
      />
    </Grid>
  );
}
