import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';
import { localStorageGetItem } from '../utils/storage-available';
import { allLangs, defaultLang } from '../../locales/config-lang';
import { LocalStorageKey } from '../enums/local-storage';
import { useCurrentUserQuery } from '../../store/api/currentUserApi';

export default function useLocales(skipFetchingUserData = false) {
  const { i18n, t } = useTranslation();
  const { data: currentUser } = useCurrentUserQuery(undefined, { skip: skipFetchingUserData });

  const langStorage = localStorageGetItem(LocalStorageKey.I18_NEXT_LNG, currentUser?.language);

  const currentLang = allLangs.find((lang) => lang.value === langStorage) || defaultLang;
  const onChangeLang = useCallback(
    (newlang: string) => {
      i18n.changeLanguage(newlang);
    },
    [i18n]
  );

  useEffect(() => {
    if (currentUser && currentUser.language === currentLang.value) return;
    onChangeLang(currentUser?.language || currentLang.value || defaultLang.value);
  }, [currentUser, onChangeLang, currentLang]);

  return {
    allLangs,
    t,
    currentLang,
    onChangeLang,
  };
}
