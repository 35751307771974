import { Card, CardContent, Divider, Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbasePermissions } from 'src/assets/icons/workbaseIcons';
import { useAppSelector } from 'src/store';
import EmptyPermissionsContent from './EmptyPermissionsContent';
import { usePermissionsQuery } from '../../controller/permissionsApi';
import PermissionsContent from './PermissionsContent';

export default function PermissionsSideBar() {
  const { t } = useTranslation();
  const checkedRoles = useAppSelector((state) => state.departments.checkedRoles);
  const { data } = usePermissionsQuery();

  return (
    <Card variant="outlined" component={Stack} maxHeight="100%">
      <CardContent>
        <Stack direction="row" alignItems="center" gap={1}>
          <SvgIcon component={WorkbasePermissions} fontSize="small" color="inherit" />
          <Typography variant="h6" fontWeight="bold" color="text.primary">
            {t('companySettings.sidebar.departments.permissions.title')}
          </Typography>
        </Stack>
        <Typography variant="caption" fontWeight="normal" color="text.secondary">
          {t('companySettings.sidebar.departments.permissions.subText')}
        </Typography>
      </CardContent>
      <Divider />
      <CardContent component={Stack} minHeight={0}>
        {!checkedRoles.length ? (
          <EmptyPermissionsContent />
        ) : (
          data && <PermissionsContent checkedRoles={checkedRoles} permissions={data} />
        )}
      </CardContent>
    </Card>
  );
}
