export const allowedFileTypes = {
  image: {
    'image/jpeg': ['.jpg', '.jpeg', '.jfif', '.pjpeg', '.pjp'],
    'image/gif': ['.gif'],
    'image/png': ['.png', '.apng'],
    'image/avif': ['.avif'],
    'image/svg+xml': ['.svg'],
    'image/webp': ['.webp'],
  },
  video: {
    'video/mp4': ['.mp4'],
    'video/wmv': ['.wmv'],
    'video/mov': ['.mov'],
  },
  //   To allow all types of files
  file: {},
};

export const MAX_FILESIZE = 100 * 1024 * 1024 * 1024; // 100GB
