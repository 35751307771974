import { Box } from '@mui/material';
import WorkbaseImage from 'src/lib/components/atoms/image';
import BrandedDefaultImage from 'src/lib/components/atoms/branded-default-image';

interface Props {
  image: string | null;
  title: string;
  onClick?: () => void;
  dataCy?: string;
}

export default function CardImage({ image, title, onClick, dataCy }: Props) {
  // const randomDefaultImage = useMemo(() => Math.floor(Math.random() * 5), []);

  return (
    <Box
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          e.stopPropagation();
          onClick();
        }
      }}
      sx={{
        width: '60px',
        minWidth: '60px',
        height: '34px',
        borderRadius: ({ shape }) => shape.borderRadius,
        overflow: 'hidden',
        cursor: 'pointer',
      }}
    >
      {image && (
        <WorkbaseImage
          data-cy={dataCy}
          src={image}
          alt={title}
          style={{ objectFit: 'cover', width: '100%', height: '100%' }}
        />
      )}
      {!image && (
        <BrandedDefaultImage
          data-cy={dataCy}
          name="WorkbaseDefault1Icon"
          className="default-svg"
          sx={{
            transition: '0.15s',
          }}
        />
      )}
    </Box>
  );
}
