import { Box } from '@mui/material';
import { useDrop } from 'react-dnd';
import { useEditor } from '../../../controller';
import { GridItem } from '../../../model';

interface Props {
  lastRowIndex: number;
  oneColMode: boolean;
  lastGridItemId?: string;
}

export default function DroppableBottomRow({ lastRowIndex, oneColMode, lastGridItemId }: Props) {
  const { moveItemToEndAndExpand } = useEditor();
  const [{ isOver, dragItemIndex, item }, drop] = useDrop({
    accept: 'GRID_ITEM',
    drop: (i: { rowIndex: number; item: GridItem }, monitor) => {
      if (
        monitor &&
        !(i.item.columnStart === 1 && i.item.columnEnd === 5 && lastRowIndex === dragItemIndex)
      ) {
        moveItemToEndAndExpand(i?.item.id);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      dragItemIndex: monitor.getItem()?.rowIndex,
      item: monitor.getItem()?.item,
    }),
  });
  const canDropItem =
    !(item?.columnStart === 1 && item?.columnEnd === 5 && lastRowIndex === dragItemIndex) &&
    oneColMode
      ? lastGridItemId !== item?.id
      : true;
  return (
    <Box ref={drop} sx={{ height: 50, margin: '0 auto' }}>
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: isOver && canDropItem ? 'info.main' : 'transparent',
          height: '10px',
          borderRadius: (theme) => theme.shape.borderRadius,
        }}
      />
    </Box>
  );
}
