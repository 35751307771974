import { InputAdornment, Stack, alpha } from '@mui/material';
import { WorkbaseEditIcon } from 'src/assets/icons/workbaseIcons';
import { Employee, EmployeeStatus } from 'src/features/employees/model';
import { WorkbaseTextField } from 'src/lib/components/atoms/text-field';
import { t } from 'i18next';
import { noSpecialCharsRegex } from 'src/lib/utils/validateUserData';

export type Props = {
  employee: Employee;
  handleUpdateEmployee: (val: Partial<Employee>) => void;
};

function NameTextField({
  value,
  onChange,
  status,
  type,
}: {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  status: EmployeeStatus;
  type?: string;
}) {
  const validate = (): string | null => {
    if (value === '' && status === 'active') {
      return t('validationErrorMessages.requiredErrorMsg');
    }
    if (!noSpecialCharsRegex.test(value) && status === 'active') {
      return t('validationErrorMessages.specialCharsErrorMsg');
    }
    if (value.length > 35) {
      return t('validationErrorMessages.fieldLengthErrorMsg');
    }
    return null;
  };
  const error = validate();
  return (
    <WorkbaseTextField
      data-cy={`${type}-edit-field`}
      value={value}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <WorkbaseEditIcon width={16} height={16} />
          </InputAdornment>
        ),
      }}
      size="H1"
      backgroundColor="tertiary.main"
      backgroundBlur={2}
      bordered={!!error}
      error={!!error}
      helperText={error}
      sx={{
        '.MuiFormHelperText-root ': {
          position: 'absolute',
          bottom: '-20px',
        },
        '.MuiInputBase-root': {
          overflow: 'hidden',
          '&:after': {
            content: '""',
            backgroundColor: (theme) => alpha(theme.palette.common.white, 0.1),
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            position: 'absolute',
          },
          '.MuiInputBase-input': {
            py: '2px',
            height: '100%',
          },
        },
      }}
    />
  );
}

export default function HeaderEdit({ employee, handleUpdateEmployee }: Props) {
  const handleUpdate = ({ type, val }: { type: keyof Employee; val: string }) => {
    const updatedField: Partial<Employee> = { [type]: val };
    handleUpdateEmployee(updatedField);
  };
  const nameFields: { type: keyof Employee; value: string }[] = [
    { type: 'firstName', value: employee?.firstName },
    { type: 'lastName', value: employee?.lastName },
  ];

  return (
    <Stack direction="row" justifyContent="center" alignItems="center" gap={2.5} maxWidth="100%">
      {nameFields.map(({ type, value }) => (
        <NameTextField
          key={type}
          type={type}
          status={employee?.status}
          value={value}
          onChange={(e) => {
            handleUpdate({ type, val: e.target.value });
          }}
        />
      ))}
    </Stack>
  );
}
