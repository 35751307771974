import { Box } from '@mui/material';
import { ReactNode, memo, useEffect, useState } from 'react';
import { WorkbaseIconByName } from 'src/lib/components/atoms/icon';
import WorkbaseImage from 'src/lib/components/atoms/image';
import EditButtons from './edit-buttons/EditButtons';
import { TrainingsHeader, CustomSiteHeader } from '../../model';

interface Props {
  readOnly: boolean;
  header: TrainingsHeader | CustomSiteHeader;
  children: ReactNode;
}

function BaseHeader({ readOnly, children, header }: Props) {
  const [backgroundSrc, setBackgroundSrc] = useState(header?.backgroundSrc);

  useEffect(() => {
    if (header?.backgroundSrc !== undefined) {
      setBackgroundSrc(header?.backgroundSrc);
    }
  }, [header?.backgroundSrc]);

  if (!header) return null;

  return (
    <Box
      sx={{
        height: 281,
        flexShrink: 0,
        borderRadius: (theme) => theme.shape.borderRadius * 4,
        marginLeft: '20px',
        marginRight: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',
        gap: 2,
        zIndex: 4,
        backgroundSize: 'cover',
        '&:hover .edit-buttons': {
          visibility: 'visible',
        },
        maxWidth: '100%',
      }}
    >
      {backgroundSrc ? (
        <WorkbaseImage
          src={backgroundSrc}
          alt="Header background"
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      ) : (
        <WorkbaseIconByName
          iconName="WorkbaseBackground"
          viewBox="0 0 6715 1185"
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            '&&&&': {
              color: header.darkMode ? 'text.primary' : 'common.white',
              backgroundColor: header.darkMode ? 'tertiary.main' : 'grey.300',
            },
          }}
        />
      )}
      {children}
      {!readOnly && (
        <EditButtons
          darkMode={header.darkMode}
          backgroundSrc={backgroundSrc}
          setBackgroundSrc={setBackgroundSrc}
        />
      )}
    </Box>
  );
}
export default memo(BaseHeader);
