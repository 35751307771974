import {
  Box,
  CircularProgress,
  ClickAwayListener,
  InputAdornment,
  Typography,
} from '@mui/material';
import debounce from 'lodash.debounce';
import { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  WorkbaseArrowDownIcon,
  WorkbaseHelpIcon,
  WorkbaseSearchIcon,
} from 'src/assets/icons/workbaseIcons';
import { WorkbaseIcon, IconName } from 'src/lib/components/atoms/icon';
import { WorkbaseSearchTextFieldWithRightIcon } from 'src/lib/components/atoms/text-field';
import { ResourceType, useSearchResultsByResourceQuery } from 'src/store/api/searchResourceApi';
import { usePostShareResourceMutation } from 'src/store/api/shareResourceApi';
import SearchWithDropdown from 'src/lib/components/molecules/search-with-dropdown/SearchWithDropdown';
import WorkbaseDropdownItem from 'src/lib/components/molecules/menu/DropdownItem';
import { TabValue } from '../access-list/TrainingAccessControlList';

type OptionType = 'companyUserId' | 'departmentId' | 'roleId';

interface Option {
  id: string;
  name: string;
  type: OptionType;
  description: string;
  avatarUrl: string | null;
}

interface Props {
  resourceId: string;
  resourceType: ResourceType;
  activeTabButton?: TabValue;
}

function ItemNotFound() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        height: 280,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <WorkbaseIcon
          icon={WorkbaseHelpIcon}
          sx={{ width: 50, height: 50, color: 'text.secondary' }}
        />
        <Typography
          sx={{
            pt: '6px',
            color: 'text.primary',
            fontWeight: 700,
            lineHeight: 1.25,
          }}
        >
          {t('notFound')}
        </Typography>
      </Box>
    </Box>
  );
}

function Spinner() {
  return (
    <Box
      sx={{
        height: 280,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
}

function SearchResourceTextField({ resourceId, resourceType, activeTabButton }: Props) {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState<boolean>(false);
  const textFieldRef = useRef<null | HTMLInputElement>(null);
  const [inputVal, setInputVal] = useState('');
  const [queryToSend, setQueryToSend] = useState(inputVal);
  const [shareResource] = usePostShareResourceMutation();

  const queryParameters = useMemo(
    () => ({
      resourceId,
      resourceType,
      query: queryToSend,
    }),
    [resourceId, resourceType, queryToSend]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleQueryToSendSet = useCallback(
    debounce((val: string) => {
      setQueryToSend(val);
    }, 220),
    []
  );

  useEffect(() => {
    debouncedHandleQueryToSendSet(inputVal);
  }, [inputVal, debouncedHandleQueryToSendSet]);

  useEffect(() => {
    textFieldRef.current?.querySelector('input')?.focus();
  }, []);

  const { data, isFetching } = useSearchResultsByResourceQuery(queryParameters, {
    skip: !queryParameters.query,
    refetchOnMountOrArgChange: true,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setInputVal('');
    setOpen(false);
  };

  const required = activeTabButton === 'required';

  const renderIcon = (type: OptionType): IconName => {
    switch (type) {
      case 'companyUserId':
        return 'WorkbaseEmployeeIcon';
      case 'departmentId':
        return 'WorkbaseDepartmentsIcon';
      case 'roleId':
        return 'WorkbaseRoleIcon';
      default:
        return 'WorkbaseEmployeeIcon';
    }
  };

  const handleSearchResultItemClick = (
    keyName: 'companyUserId' | 'roleId' | 'departmentId',
    id: string
  ) => {
    shareResource({ resourceId, resourceType, [keyName]: id, required });
    handleClose();
  };

  const sortedOptions: Option[] = useMemo(() => {
    if (!data) return [];
    return [
      ...data.companyUsers.map((user) => ({
        id: user.id,
        type: 'companyUserId' as const,
        name: `${user.details.firstName} ${user.details.lastName}`,
        description: user.details.email,
        avatarUrl: user.details.avatarUrl,
      })),
      ...data.departments.map((dept) => ({
        id: dept.id,
        type: 'departmentId' as const,
        name: dept.details.name,
        description: '',
        avatarUrl: null,
      })),
      ...data.roles.map((role) => ({
        id: role.id,
        type: 'roleId' as const,
        name: `${role.details.department.name} – ${role.details.name}`,
        description: '',
        avatarUrl: null,
      })),
    ];
  }, [data]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box>
        <SearchWithDropdown
          dataCy="share-modal-search"
          onOpen={handleOpen}
          open={isOpen && !!queryToSend}
          onClose={handleClose}
          popperWidth={textFieldRef.current?.clientWidth || 'auto'}
          popperMaxHeight={280}
          options={(sortedOptions as any) || []}
          searchValue={inputVal}
          noOptionsComponent={!isFetching && <ItemNotFound />}
          loadingComponent={<Spinner />}
          isLoading={isFetching}
          getOptionLabel={() => ''}
          onChange={(_, value) => {
            handleSearchResultItemClick(value.type, value.id);
          }}
          slotProps={{
            paper: {
              // @ts-ignore custom prop for WorkbasePaper
              float: true,
            },
          }}
          renderInput={(params) => (
            <WorkbaseSearchTextFieldWithRightIcon
              dataCy="share-modal-search-textfield"
              {...params}
              size="H1"
              sx={{
                '&&& .MuiInputBase-root': {
                  height: 50,
                  padding: '0 20px',
                },
              }}
              autoComplete="off"
              value={inputVal}
              onChange={(e) => setInputVal(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  handleClose();
                }
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment
                    sx={{
                      cursor: !isOpen ? 'pointer' : 'default',
                    }}
                    position="end"
                  >
                    <WorkbaseIcon
                      icon={isOpen ? WorkbaseSearchIcon : WorkbaseArrowDownIcon}
                      sx={{
                        color: isOpen ? 'text.secondary' : 'primary.main',
                        width: 16,
                        height: 16,
                        '&.MuiSvgIcon-root': {
                          mt: 1.25,
                          color: ({ palette }) => palette.text.secondary,
                        },
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              placeholder={t('add')}
            />
          )}
          renderOption={(props, option) => (
            <WorkbaseDropdownItem
              dataCy="share-modal-search-item"
              {...props}
              key={option.id}
              primaryText={option.name as string}
              secondaryText={option.description}
              avatarUrl={option.avatarUrl}
              icon={renderIcon(option.type)}
            />
          )}
        />
      </Box>
    </ClickAwayListener>
  );
}

export default SearchResourceTextField;
