import { Button, CircularProgress, Dialog, DialogContent, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { useEmployeesByRoleIdQuery } from 'src/store/api/employees/employeesApi';
import SearchEmployeesRolesTextField from './SearchEmployeesRolesTextField';
import EmployeeRolesList from './EmployeeRolesList';
import EmptyEmployeeRolesListPlaceholder from './EmptyEmployeeRolesListPlaceholder';

interface Props {
  isPopupActive: boolean;
  setPopupActive: (val: boolean) => void;
  name: string;
  roleId: string;
}

export default function EmployeesListedRolesPopup({
  isPopupActive,
  setPopupActive,
  name,
  roleId,
}: Props) {
  const { data: employees, isLoading } = useEmployeesByRoleIdQuery(roleId, {
    skip: !isPopupActive,
  });

  return (
    <Dialog open={isPopupActive} onClose={() => setPopupActive(false)}>
      <DialogContent>
        <Stack gap={1}>
          <Typography>
            {`${t('companySettings.sidebar.departments.employeePopup.header')} "${name}"`}
          </Typography>
          <SearchEmployeesRolesTextField
            roleId={roleId}
            dataCy="employees-roles-search-textField"
          />
          {isLoading && (
            <Stack position="absolute" left="50%" top="50%">
              <CircularProgress />
            </Stack>
          )}
          {employees && !isLoading && <EmployeeRolesList employees={employees} roleId={roleId} />}
          {!employees?.length && !isLoading && <EmptyEmployeeRolesListPlaceholder />}
          <Button fullWidth onClick={() => setPopupActive(false)}>
            {t('permissionsShare.doneButton')}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
