import { FormControl, FormHelperText, IconButton, InputAdornment, Stack } from '@mui/material';
import { useState } from 'react';
import { WorkbaseTextField } from 'src/lib/components/atoms/text-field';
import Datepicker from 'src/lib/components/molecules/date-picker';
import WorkbaseDialogComponent from 'src/lib/components/molecules/dialog/Dialog';
import {
  WorkbaseCheckmarkBigFilledIcon,
  WorkbaseCopyDuplicateIcon,
} from 'src/assets/icons/workbaseIcons';
import { WorkbaseIcon } from 'src/lib/components/atoms/icon';
import WorkbaseAlert from 'src/lib/components/molecules/alert';
import { showNotification } from 'src/lib/components/atoms/notification';
import { ApiKey, ApiKeyPostData } from 'src/store/api/apiKeysApi';
import { useTranslation } from 'react-i18next';
import { shouldDisableDate } from '../../controller/helpers';
import { useApiKeyForm } from '../../controller/useApiKeyForm';

export default function ApiKeyGenerationDialog({
  open,
  onClose,
  onSubmit,
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: ApiKeyPostData) => Promise<{ data: ApiKey; status: number }>;
}) {
  const { t } = useTranslation();
  const [apiKeyData, setApiKeyData] = useState<ApiKey | null>(null);

  const {
    name,
    nameError,
    expiryDate,
    dateError,
    handleNameChange,
    handleNameBlur,
    handleDateError,
    handleDateChange,
    reset,
  } = useApiKeyForm();

  const handleGenerateApiKey = () => {
    onSubmit({ name, expiredAt: expiryDate?.toISOString() } as ApiKeyPostData).then(
      (res: { data: ApiKey }) => {
        setApiKeyData(res.data);
        reset();
      }
    );
  };

  const handleCopyApiKey = () => {
    navigator.clipboard.writeText(apiKeyData?.apiKey || '');
    showNotification(t('companySettings.apiKeys.copiedToClipboard'), 'success');
  };

  const handleCloseDialog = () => {
    onClose();
    setApiKeyData(null);
    reset();
  };

  const formDataValid = !dateError && !nameError && !!name.trim();
  return (
    <WorkbaseDialogComponent
      dataCy="generate-api"
      titleIcon={
        apiKeyData ? { icon: WorkbaseCheckmarkBigFilledIcon, color: 'success.main' } : undefined
      }
      confirmButton={{
        text: apiKeyData
          ? t('companySettings.apiKeys.copyApiKey')
          : t('companySettings.apiKeys.generateApiKey'),
        onClick: apiKeyData ? handleCopyApiKey : handleGenerateApiKey,
        startIcon: apiKeyData ? WorkbaseCopyDuplicateIcon : undefined,
        disabled: !apiKeyData && !formDataValid,
      }}
      closeOnConfirm={false}
      cancelButton={{
        text: apiKeyData ? t('close') : t('cancel'),
        onClick: handleCloseDialog,
      }}
      title={
        apiKeyData
          ? t('companySettings.apiKeys.apiKeyGenerated')
          : t('companySettings.apiKeys.generateApiKey')
      }
      open={open}
      onClose={handleCloseDialog}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
        },
      }}
    >
      <Stack direction="column" gap={3} paddingBottom={2} paddingTop={1}>
        {apiKeyData ? (
          <Stack spacing={2} alignItems="flex-start">
            <WorkbaseTextField
              dataCy="api-key-textfield"
              label={t('companySettings.apiKeys.label')}
              value={apiKeyData?.name}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root:hover': {
                  borderColor: 'transparent',
                },
                '&&&& .MuiOutlinedInput-notchedOutline ': {
                  borderColor: 'grey.300',
                },
              }}
              inputProps={{
                readOnly: true,
              }}
            />
            <WorkbaseTextField
              dataCy="api-key-inner-header-textfield"
              label={t('companySettings.apiKeys.innerHeader')}
              value={apiKeyData?.apiKey}
              fullWidth
              sx={{
                '& .Mui-focused, .MuiInputBase-root': {
                  paddingRight: 1,
                },
                '&&&& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'grey.300',
                },
              }}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleCopyApiKey}>
                      <WorkbaseIcon icon={WorkbaseCopyDuplicateIcon} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <WorkbaseAlert message={t('companySettings.apiKeys.noteWarning')} type="warning" />
          </Stack>
        ) : (
          <Stack spacing={2} alignItems="flex-start">
            <FormControl fullWidth error={!!nameError}>
              <WorkbaseTextField
                dataCy="api-keys--textfield"
                label={t('companySettings.apiKeys.label')}
                value={name}
                onChange={handleNameChange}
                onBlur={handleNameBlur}
                placeholder={t('companySettings.apiKeys.namePlaceholder')}
                fullWidth
                error={!!nameError}
              />
              {nameError && <FormHelperText>{t(nameError)}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth>
              <Datepicker
                label={t('companySettings.apiKeys.expiryDate')}
                fullWidth
                onError={handleDateError}
                value={expiryDate}
                shouldDisableDate={shouldDisableDate}
                disablePast
                onChange={handleDateChange}
              />
              <FormHelperText error={!!dateError}>
                {dateError
                  ? t(`companySettings.apiKeys.${dateError}`)
                  : t('companySettings.apiKeys.expiryDateHelperText')}
              </FormHelperText>
            </FormControl>
          </Stack>
        )}
      </Stack>
    </WorkbaseDialogComponent>
  );
}
