import { Divider, IconButton, Stack } from '@mui/material';
import { useActive, useCommands, useRemirrorContext } from '@remirror/react';
import React, { useEffect, useRef, useState } from 'react';
import WorkbaseTooltip from 'src/lib/components/atoms/tooltip';
import { useTranslation } from 'react-i18next';
import { WorkbaseIcon } from '../../../../atoms/icon/WorkbaseIcon';
import {
  WorkbaseBoldIcon,
  WorkbaseItalicIcon,
  WorkbaseLinksIcon,
  WorkbaseTextCenterIcon,
  WorkbaseTextLeftIcon,
  WorkbaseTextRightIcon,
  WorkbaseUnderlineIcon,
} from '../../../../../../assets/icons/workbaseIcons';
import TextStylesDropdown from './text-styles/TextStylesDropdown';

interface Props {
  onLinkIconClick: () => void;
  linkIconAlwaysClickable?: boolean;
  textAllignmentHidden?: boolean;
}

function TextAlignmentButtons() {
  const { leftAlign, centerAlign, rightAlign } = useCommands();
  const { t } = useTranslation();

  const handleAlignment = (alignFunction: any) => {
    try {
      return alignFunction.active?.();
    } catch (err) {
      return false;
    }
  };

  const data = [
    {
      key: 'align-left',
      onClick: leftAlign,
      icon: WorkbaseTextLeftIcon,
      active: handleAlignment(leftAlign),
      hint: t('editor.topBar.tooltip.alignLeft'),
    },
    {
      key: 'align-center',
      onClick: centerAlign,
      icon: WorkbaseTextCenterIcon,
      active: handleAlignment(centerAlign),
      hint: t('editor.topBar.tooltip.alignCenter'),
    },
    {
      key: 'align-right',
      onClick: rightAlign,
      icon: WorkbaseTextRightIcon,
      active: handleAlignment(rightAlign),
      hint: t('editor.topBar.tooltip.alignRight'),
    },
  ];
  return data.map(({ key, onClick, icon, hint }) => (
    <React.Fragment key={key}>
      <WorkbaseTooltip title={hint} placement="top">
        <span>
          <IconButton
            key={key}
            onMouseDown={(e) => {
              e.preventDefault();
              onClick();
            }}
          >
            <WorkbaseIcon icon={icon} sx={{ '&&&': { width: 16, height: 16 } }} />
          </IconButton>
        </span>
      </WorkbaseTooltip>
    </React.Fragment>
  ));
}

export default function TextMenu({
  onLinkIconClick,
  textAllignmentHidden = false,
  linkIconAlwaysClickable = false,
}: Props) {
  const { toggleBold, toggleItalic, toggleUnderline } = useCommands();
  const { bold, underline, italic, heading } = useActive();
  const titleActive = heading({ level: 1 });
  const headingActive = heading({ level: 2 });
  const subtitleActive = heading({ level: 3 });
  const isAnyHeadingActive = titleActive || headingActive || subtitleActive;

  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  const { getState } = useRemirrorContext();

  const selectionEmpty = getState().selection.empty;
  const { t } = useTranslation();

  const iconsData = [
    {
      key: 'strong',
      onClick: toggleBold,
      icon: WorkbaseBoldIcon,
      active: bold() && !isAnyHeadingActive,
      disabled: isAnyHeadingActive,
      divider: false,
      hint: t('editor.topBar.tooltip.bold'),
    },
    {
      key: 'italic',
      onClick: toggleItalic,
      icon: WorkbaseItalicIcon,
      divider: false,
      active: italic(),
      hint: t('editor.topBar.tooltip.italic'),
    },
    {
      key: 'underline',
      onClick: toggleUnderline,
      divider: false,
      icon: WorkbaseUnderlineIcon,
      active: underline(),
      hint: t('editor.topBar.tooltip.underline'),
    },
    {
      key: 'link',
      onClick: () => {
        onLinkIconClick();
      },
      icon: WorkbaseLinksIcon,
      disabled: linkIconAlwaysClickable ? false : selectionEmpty,
      divider: !textAllignmentHidden,
      active: false,
      hint: t('editor.topBar.tooltip.link'),
    },
  ];

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setContainerWidth(entry.contentRect.width);
      });
    });

    const ref = containerRef.current;

    if (ref) {
      resizeObserver.observe(ref);
    }

    return () => {
      if (ref) {
        resizeObserver.unobserve(ref);
      }
    };
  }, []);

  const alignmentSectionWidth = textAllignmentHidden ? 0 : 120;
  return (
    <Stack direction="row" ref={containerRef} gap={0.5} alignItems="center">
      <TextStylesDropdown menuWidth={containerWidth - alignmentSectionWidth} />
      {iconsData.map(({ key, onClick, icon, divider, disabled, hint }) => (
        <React.Fragment key={key}>
          <WorkbaseTooltip title={hint} placement="top">
            <span>
              <IconButton
                disabled={disabled}
                key={key}
                onMouseDown={(e) => {
                  e.preventDefault();
                  onClick();
                }}
              >
                <WorkbaseIcon icon={icon} sx={{ '&&&': { width: 16, height: 16 } }} />
              </IconButton>
            </span>
          </WorkbaseTooltip>
          {divider && (
            <Divider orientation="vertical" sx={{ ml: 0.5, mr: 0.5, height: 'calc(100% + 8px)' }} />
          )}
        </React.Fragment>
      ))}
      {!textAllignmentHidden && <TextAlignmentButtons />}
    </Stack>
  );
}
