import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ShareModal from 'src/core/share-modal/ShareModal';
import { showNotification } from 'src/lib/components/atoms/notification';
import { PermissionCodes } from 'src/lib/enums/permissions';
import { useShareLevelQuery } from 'src/store/api/shareResourceApi';
import { useUserPermissionsQuery } from 'src/store/api/userPermissionsApi';
import {
  useDeleteWhiteboardMutation,
  useDuplicateWhiteboardMutation,
  useWhiteboardQuery,
} from 'src/store/api/whiteboardsApi';
import { ShareLevel } from 'src/lib/types/share-level';
import DeleteDuplicateMenu from 'src/ui-components/custom/delete-duplicate-menu';
import AwarenessAvatars from '../awareness-avatars';

interface Props {
  id: string;
}

export default function WhiteboardActionButtons({ id }: Props) {
  const [deleteWhiteboard] = useDeleteWhiteboardMutation();
  const [duplicateWhiteboard] = useDuplicateWhiteboardMutation();
  const { data: userPermissions } = useUserPermissionsQuery();
  const hasAddWhiteboardsPermission = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.ADD_WHITEBOARDS
  );
  const { data: shareLevelData } = useShareLevelQuery({
    resourceId: id,
    resourceType: 'whiteboard',
  });

  const { data } = useWhiteboardQuery(id);
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <AwarenessAvatars />
      {data && (
        <ShareModal
          name={data?.name || t('whiteboards.unnamedWhiteboard')}
          resourceType="whiteboard"
          resourceId={id}
        />
      )}
      <DeleteDuplicateMenu
        onDelete={
          shareLevelData?.shareLevel === ShareLevel.manage
            ? () => {
                deleteWhiteboard(id).then((resp: any) => {
                  if (resp.error) {
                    showNotification(t('notifications.deleteError'), 'error');
                  } else {
                    showNotification(t('notifications.deleteSuccess'));
                    navigate('/');
                  }
                });
              }
            : undefined
        }
        onDuplicate={
          hasAddWhiteboardsPermission
            ? () => {
                duplicateWhiteboard(id).then((resp: any) => {
                  if (resp.error) {
                    showNotification(t('notifications.duplicateError'), 'error');
                  } else {
                    navigate(`/whiteboard/${resp.data.id}/edit`);
                    showNotification(t('notifications.duplicateSuccess'));
                  }
                });
              }
            : undefined
        }
        deleteDialogDescription={t('whiteboards.deleteWhiteboardModal.content')}
      />
    </>
  );
}
