import { forwardRef } from 'react';
import Link, { LinkProps } from '@mui/material/Link';
import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';

type IProps = TypographyProps & LinkProps;

interface TextMaxLineProps extends IProps {
  line?: number;
  asLink?: boolean;
  dataCy?: string;
}

const WorkbaseTextMaxLine = forwardRef<HTMLAnchorElement, TextMaxLineProps>(
  ({ asLink, variant = 'body1', line = 2, children, sx, dataCy, ...other }, ref) => {
    const styles = {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
      whiteSpace: 'nowrap',
      WebkitLineClamp: line,
      WebkitBoxOrient: 'vertical',
      ...sx,
    } as const;

    if (asLink) {
      return (
        <Link color="inherit" ref={ref} variant={variant} sx={{ ...styles }} {...other}>
          {children}
        </Link>
      );
    }

    return (
      <Typography ref={ref} variant={variant} sx={{ ...styles }} {...other} data-cy={dataCy}>
        {children}
      </Typography>
    );
  }
);

export default WorkbaseTextMaxLine;
