import { Box, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DraftModeMenu from 'src/core/DraftModeMenu';
import { WorkbaseIconByName } from 'src/lib/components/atoms/icon';
import { showNotification } from 'src/lib/components/atoms/notification';
import {
  TrainingSectionStatus,
  useDeleteTrainingSectionMutation,
  useDuplicateTrainingSectionMutation,
  usePatchTrainingSectionMutation,
} from 'src/store/api/trainings/trainingSectionsApi';
import TextFieldWithIconSelector from 'src/lib/components/molecules/text-field-with-icon-selector';
import { useNavigate, useParams } from 'react-router-dom';
import { WorkbaseTextField } from 'src/lib/components/atoms/text-field';
import debounce from 'lodash.debounce';
import { useMoveTrainingLessonToNewPositionMutation } from 'src/store/api/trainings/trainingLessonsApi';
import { useDrop } from 'react-dnd';
import DndIndicator from 'src/ui-components/custom/dnd-indicator/DndIndicator';
import SvgIconOrEmoji from 'src/ui-components/utils/icon/SvgIconOrEmoji';
import DeleteDuplicateMenu from 'src/ui-components/custom/delete-duplicate-menu';
import IconOrEmojiSelector from 'src/ui-components/custom/icon-selector';

// eslint-disable-next-line max-statements
export default function SectionTitle({
  sectionName = '',
  lessonsCount,
  sectionStatus,
  readOnly,
  sectionId,
  trainingModuleId,
  mobileView,
  icon = 'WorkbaseSectionIcon',
  isCurrentSectionLessonActive,
  totalEstimatedLessonsTime,
  setIsOver,
}: Readonly<{
  sectionName: string;
  lessonsCount: number;
  icon: string;
  readOnly?: boolean;
  sectionId: string;
  sectionStatus: TrainingSectionStatus;
  trainingModuleId?: string;
  mobileView: boolean;
  isCurrentSectionLessonActive: boolean;
  totalEstimatedLessonsTime: number;
  setIsOver?: (val: boolean) => void;
}>) {
  const { t } = useTranslation();
  const [localSectionName, setLocalSectionName] = useState(sectionName);
  const [patchTrainingSection] = usePatchTrainingSectionMutation();
  const [duplicateTrainingSection] = useDuplicateTrainingSectionMutation();
  const [deleteTrainingSection] = useDeleteTrainingSectionMutation();
  const [moveTrainingLessonToNewPosition] = useMoveTrainingLessonToNewPositionMutation();
  const navigate = useNavigate();
  const params = useParams();
  const lessonsCountLabel = t('lesson', { count: lessonsCount || 0 });
  const estimatedTimeCountLabel = t('minute', {
    count: totalEstimatedLessonsTime || 0,
  });

  useEffect(() => {
    setLocalSectionName(sectionName);
  }, [sectionName, readOnly]);

  const [{ isOver }, drop] = useDrop({
    accept: 'editing-lesson-item',
    drop: (i: { id: string }) => {
      moveTrainingLessonToNewPosition({
        sectionId,
        idOfMovingLesson: i.id,
        order: 1,
      });
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  useEffect(() => {
    setIsOver?.(isOver);
  }, [isOver, setIsOver]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSectionTitleChange = useCallback(
    debounce((val: string) => {
      patchTrainingSection({
        trainingSection: { name: val, id: sectionId, trainingModuleId },
        trainingId: params.trainingId as string,
        trainingModuleId: trainingModuleId as string,
      });
    }, 1000),
    [trainingModuleId, patchTrainingSection, sectionId]
  );

  const lessonDurationCountText = (
    <Typography fontWeight="medium" variant="body2" color="text.secondary">
      {lessonsCountLabel} – {estimatedTimeCountLabel}
    </Typography>
  );

  const readOnlyContent = (
    <>
      <IconButton size="small">
        <SvgIconOrEmoji content={icon} />
      </IconButton>
      <Box sx={{ flexShrink: 1, minWidth: 0, textOverflow: 'hidden' }}>
        <Typography fontWeight="bold" variant="body1" noWrap>
          {sectionName || t('modulePage.unnamedSection')}
        </Typography>
        {lessonDurationCountText}
      </Box>
    </>
  );

  const draftModeMenu = (
    <DraftModeMenu
      withButton={!mobileView}
      status={sectionStatus}
      onStatusChange={(status) => {
        patchTrainingSection({
          trainingSection: { status, id: sectionId, trainingModuleId },
          trainingId: params.trainingId as string,
          trainingModuleId: trainingModuleId as string,
        });
      }}
    />
  );
  const editTextFieldProps = {
    value: localSectionName,
    onChange: (e: any) => {
      setLocalSectionName(e.target.value);
      debouncedSectionTitleChange(e.target.value);
    },
    placeholder: t('writeTitlePlaceholder'),
    size: 'H4' as any,
    onClick: (e: any) => {
      e.stopPropagation();
    },
  };

  const changeableIconProps = {
    value: icon,
    onChange: (iconName: string) => {
      patchTrainingSection({
        trainingSection: { icon: iconName, id: sectionId, trainingModuleId },
        trainingId: params.trainingId as string,
        trainingModuleId: trainingModuleId as string,
      });
    },
  };
  const editContent = (
    <Stack
      direction="row"
      justifyContent="space-between"
      gap={mobileView ? 1.5 : 2}
      width="100%"
      mr={0}
      my="-20px"
      py="20px"
      alignItems="center"
      ref={drop}
      id={sectionId}
    >
      {isOver && <DndIndicator bottom={7} sx={{ right: '21px', width: 'calc(100% - 42px)' }} />}
      {!mobileView && (
        <TextFieldWithIconSelector
          sx={{
            width: '536px',
            minWidth: 0,
            flexShrink: 1,
          }}
          backgroundColor="tertiary.main"
          changeableIconProps={changeableIconProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <WorkbaseIconByName iconName="WorkbaseEditIcon" size={12} />
              </InputAdornment>
            ),
          }}
          {...editTextFieldProps}
        />
      )}
      {mobileView && (
        <Stack direction="row" alignItems="center" gap={1.25} onClick={(e) => e.stopPropagation()}>
          <IconOrEmojiSelector {...changeableIconProps} />
          <Stack>
            <WorkbaseTextField
              {...editTextFieldProps}
              bordered={false}
              id={sectionId}
              sx={{
                width: '100%',
                minWidth: 0,
                flexShrink: 1,
                pointerEvents: 'all',
                '& input': {
                  padding: 0,
                  fontSize: 16,
                },
              }}
            />
            <Stack direction="row" gap={0.5}>
              {lessonDurationCountText}
              {draftModeMenu}
            </Stack>
          </Stack>
        </Stack>
      )}
      <Stack
        onClick={(e) => e.stopPropagation()}
        direction="row"
        gap={mobileView ? 1.5 : 2}
        alignItems="center"
        sx={{ marginLeft: 'auto' }}
      >
        {!mobileView && draftModeMenu}
        <DeleteDuplicateMenu
          onDelete={() => {
            deleteTrainingSection({
              trainingModuleId: trainingModuleId as string,
              id: sectionId,
              trainingId: params.trainingId as string,
              moduleId: params.moduleId as string,
            }).then((resp: any) => {
              if (resp.error) {
                showNotification(t('notifications.deleteError'), 'error');
              } else {
                if (isCurrentSectionLessonActive) {
                  navigate(`/trainings/${params.trainingId}/modules/${params.moduleId}`);
                }
                showNotification(t('notifications.deleteSuccess'));
              }
            });
          }}
          onDuplicate={() => {
            duplicateTrainingSection({
              moduleId: trainingModuleId as string,
              sectionId,
            }).then((resp: any) => {
              if (resp.error) {
                showNotification(t('notifications.duplicateError'), 'error');
              } else {
                showNotification(t('notifications.duplicateSuccess'));
              }
            });
          }}
          deleteDialogDescription={t('modulePage.deleteSectionModal.content')}
        />
      </Stack>
    </Stack>
  );

  return (
    <Stack gap={1.25} flexDirection="row" alignItems="center" sx={{ margin: '4px 0', minWidth: 0 }}>
      {readOnly ? readOnlyContent : editContent}
    </Stack>
  );
}
