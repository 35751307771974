import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import { WorkbaseHelpIcon } from 'src/assets/icons/workbaseIcons';
import { useTranslation } from 'react-i18next';
import { AdminSettings, useSettingsQuery } from 'src/store/api/settingsApi';
import LogoSettingsList from './logo-settings/LogoSettingsList';
import InformationSettings from './InformationSettings';
import LanguageSetting from './LanguageSetting';
import BrandingColors from './branding-colors/BrandingColors';
import BorderRadiusSlider from './BorderRadiusSlider';

const centeredProgress = (
  <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
    <CircularProgress />
  </Box>
);
const parsedUrl = new URL(import.meta.env.VITE_WORKBASE_LANDING_PAGE_URL);

export default function SiteInfo() {
  const { t } = useTranslation();
  const { data, isLoading, error, refetch } = useSettingsQuery();
  const adminSettings = data as AdminSettings | undefined;

  if (isLoading) return centeredProgress;

  if (error) {
    return (
      <Alert
        severity="error"
        action={
          <Button onClick={refetch} size="small">
            {t('validationErrorMessages.labelErrorTryAgainButton')}
          </Button>
        }
      >
        {t('companySettings.siteInformation.labelErrorDescription')}
      </Alert>
    );
  }

  return (
    <Stack width="100%" alignItems="center">
      <Stack gap={2} maxWidth={(theme) => theme.breakpoints.values.lg} width="100%">
        <Stack direction="row" gap={1} alignItems="flex-start">
          <Typography variant="h4" data-cy="site-information-header">
            {t('companySettings.siteInformation.header')}
          </Typography>
          <IconButton
            href={`${parsedUrl.href}`}
            target="_blank"
            data-cy="site-information-icon"
            size="small"
          >
            <SvgIcon fontSize="inherit" component={WorkbaseHelpIcon} />
          </IconButton>
        </Stack>
        {data && (
          <Stack gap={2.5}>
            <InformationSettings name={data.siteName} description={data.description} />
            <Divider />
            <LogoSettingsList
              siteLogo={data.siteLogoUrl}
              siteLogoDark={data.siteLogoDarkUrl}
              siteFavicon={data.siteFaviconUrl}
              siteFaviconDark={data.siteFaviconDarkUrl}
            />
            <Divider />
            <BrandingColors />
            <Divider />
            <BorderRadiusSlider />
            {adminSettings && <LanguageSetting defaultLanguage={adminSettings.defaultLanguage} />}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
