import checkIfEmoji, { Emoji } from 'src/lib/utils/checkIfEmoji';
import allIcons from 'src/assets/icons';
import { SxProps, Theme } from '@mui/material';
import { IconName, WorkbaseIcon, WorkbaseIconType } from './WorkbaseIcon';
import WorkbaseEmoji from './WorkbaseEmoji';

export interface WorkbaseIconOrEmojiProps {
  content: string | WorkbaseIconType;
  sx?: SxProps<Theme>;
  viewBox?: string;
  color?: string;
  className?: string;
  hoverColor?: string;
  size?: number;
  dataCy?: string;
}

export default function WorkbaseIconOrEmoji({
  content,
  hoverColor,
  color = 'inherit',
  sx,
  size = 24,
  className,
  viewBox,
  dataCy,
}: WorkbaseIconOrEmojiProps) {
  const isEmoji = checkIfEmoji(content as string);
  const workbaseIcon = allIcons[content as IconName] as WorkbaseIconType;
  const renderIcon = workbaseIcon ? (
    <WorkbaseIcon
      icon={workbaseIcon}
      data-cy={dataCy}
      className={className}
      viewBox={viewBox}
      color={color}
      hoverColor={hoverColor}
      sx={sx}
      size={size}
    />
  ) : null;
  const renderEmoji = isEmoji && (
    <WorkbaseEmoji className={className} emoji={content as Emoji} fontSize={size} sx={sx} />
  );

  return isEmoji ? renderEmoji : renderIcon;
}
