import { Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Employee } from 'src/features/employees/model';
import TextSection from 'src/lib/components/molecules/text-section';
import EditableContactInfo from './EditableContactInfo';
import Roles from '../roles/Roles';

interface Props {
  employee: Employee;
  employeeDraft: Employee;
  readOnly: boolean;
  handleUpdateEmployee: (val: Partial<Employee>) => void;
}

type Category =
  | 'contact-information'
  | 'personal-information'
  | 'hr-information'
  | 'compensation-details';

const placeholder = 'N/A';

export default function EmployeeInfo({
  employee,
  employeeDraft,
  readOnly,
  handleUpdateEmployee,
}: Props) {
  const { t } = useTranslation();

  const listOptions = [
    {
      id: 'email',
      title: t('employeePage.employeeDetails.contactInfomation.emailAddressLabel'),
      text: employee?.email || placeholder,
      pendingEmail: employee?.pendingEmailChange || undefined,
      category: 'contact-information',
    },
    {
      id: 'phoneNumber',
      title: t('employeePage.employeeDetails.contactInfomation.mobileNumberLabel'),
      text: employeeDraft.phoneNumber || '',
      category: 'contact-information',
    },
    {
      id: 'location',
      title: t('employeePage.employeeDetails.contactInfomation.locationLabel'),
      text: employeeDraft.location || '',
      category: 'contact-information',
    },
    {
      id: 'dateOfBirth',
      title: t('employeePage.employeeDetails.personalInformation.dateOfBirthLabel'),
      text: employeeDraft.dateOfBirth || '',
      category: 'personal-information',
    },
    {
      id: 'gender',
      title: t('employeePage.employeeDetails.personalInformation.genderLabel'),
      text: employeeDraft.gender
        ? t(`employeePage.employeeDetails.personalInformation.${employeeDraft.gender}`)
        : '',
      category: 'personal-information',
    },
    {
      id: 'citizenship',
      title: t('employeePage.employeeDetails.personalInformation.citizenshipLabel'),
      text: employeeDraft.citizenship || '',
      category: 'personal-information',
    },
    {
      id: 'city',
      title: t('employeePage.employeeDetails.personalInformation.cityLabel'),
      text: employeeDraft.city || '',
      category: 'personal-information',
    },
    {
      id: 'street',
      title: t('employeePage.employeeDetails.personalInformation.streetLabel'),
      text: employeeDraft.street || '',
      category: 'personal-information',
    },
    {
      id: 'houseNumber',
      title: t('employeePage.employeeDetails.personalInformation.houseLabel'),
      text: employeeDraft.houseNumber || '',
      category: 'personal-information',
    },
    {
      id: 'employeeId',
      title: t('employeePage.employeeDetails.hrInfomation.employeeIdLabel'),
      text: employeeDraft.employeeId || '',
      category: 'hr-information',
    },
    {
      id: 'hireDate',
      title: t('employeePage.employeeDetails.hrInfomation.hireDateLabel'),
      text: employeeDraft.hireDate || '',
      category: 'hr-information',
    },
    {
      id: 'employmentType',
      title: t('employeePage.employeeDetails.hrInfomation.employmentTypeLabel'),
      text: employeeDraft.employmentType
        ? t(`employeePage.employeeDetails.hrInfomation.${employeeDraft.employmentType}`)
        : '',
      category: 'hr-information',
    },
    {
      id: 'bankAccount',
      title: t('employeePage.employeeDetails.compensationDetails.bankAccountLabel'),
      text: employeeDraft.bankAccount || '',
      category: 'compensation-details',
    },
    {
      id: 'salary',
      title: t('employeePage.employeeDetails.compensationDetails.salaryLabel'),
      text: employeeDraft.salary || '',
      category: 'compensation-details',
    },
    {
      id: 'bonus',
      title: t('employeePage.employeeDetails.compensationDetails.bonusLabel'),
      text: employeeDraft.bonus || '',
      category: 'compensation-details',
    },
    {
      id: 'currency',
      title: t('employeePage.employeeDetails.compensationDetails.currencyLabel'),
      text: employeeDraft.currency || '',
      category: 'compensation-details',
    },
    {
      id: 'payFrequency',
      title: t('employeePage.employeeDetails.compensationDetails.payFrequencyLabel'),
      text: employeeDraft.payFrequency
        ? t(`employeePage.employeeDetails.compensationDetails.${employeeDraft.payFrequency}`)
        : '',
      category: 'compensation-details',
    },
  ];

  const groupedOptions = listOptions.reduce<Record<string, any[]>>((groups, option) => {
    const newGroups = { ...groups };

    if (!newGroups[option.category]) {
      newGroups[option.category] = [];
    }

    newGroups[option.category].push(option);

    return newGroups;
  }, {});

  const titleDict: Record<Category, string> = {
    'contact-information': t('employeePage.employeeDetails.contactInfomation.header'),
    'personal-information': t('employeePage.employeeDetails.personalInformation.header'),
    'hr-information': t('employeePage.employeeDetails.hrInfomation.header'),
    'compensation-details': t('employeePage.employeeDetails.compensationDetails.header'),
  };

  return (
    <Stack
      mt={2.5}
      sx={{
        height: '100%',
        paddingBottom: '20px',
        '& .MuiGrid-root .MuiTypography-body1': {
          fontSize: 15,
          fontWeight: 500,
        },
      }}
    >
      <Typography variant="h6" sx={{ mb: '12px' }}>
        {t('employeePage.roles.title')}
      </Typography>
      <Roles
        employeeDraft={employeeDraft}
        readOnly={readOnly}
        handleUpdateEmployee={handleUpdateEmployee}
      />
      <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />

      {Object.keys(groupedOptions).map((category, index) => (
        <Stack key={category}>
          <Typography variant="h6" sx={{ marginBottom: '12px' }}>
            {titleDict[category as Category]}
          </Typography>
          {readOnly ? (
            <TextSection list={groupedOptions[category]} dataCy={`${category}-text-section`} />
          ) : (
            <EditableContactInfo
              employeeInfo={groupedOptions[category]}
              employeeId={employee.id}
              handleUpdateEmployee={handleUpdateEmployee}
            />
          )}
          {index < Object.keys(groupedOptions).length - 1 && (
            <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
          )}
        </Stack>
      ))}
    </Stack>
  );
}
