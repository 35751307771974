import { Box, styled } from '@mui/material';

const StyledWrapper = styled(Box)`
  padding-top: 3px;
  padding-bottom: 3px;
  position: relative;
  .treeRoot {
    list-style-type: none;
    padding-inline-start: 0px;
    padding: 10px 0px;
    position: relative;
    margin: 0;
  }

  .treeRoot ul {
    list-style-type: none;
    padding-inline-start: 0px;
    position: relative;
    padding-bottom: 5px;
  }

  ul > li {
    margin-top: 2px;
  }

  .treeRoot > li:after {
    display: none;
  }

  .draggingSource {
    opacity: 0.3;
  }

  .placeholder {
    position: relative;
  }

  li:has(> .dropTarget) {
    z-index: 10;
    position: relative;
    outline: 2px solid ${({ theme }) => theme.palette.primary.main};
  }
`;

export default StyledWrapper;
