import { useEffect, useState, useRef, cloneElement, memo } from 'react';
import debounce from 'lodash.debounce';
import { Box, Card } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import {
  CONTENT_RIGHT_GAP,
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_FULL_WIDTH,
  TWO_COL_LAYOUT_LEFT_CARD_WIDTH,
  TWO_COL_LAYOUT_LEFT_GAP,
} from './layoutConstants';

const InvertedBorderRadiusBox = memo(({ sx }: { sx?: any }) => (
  <Box sx={{ ...sx }}>
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
      <path d="M 0,0 L 20,0 Q 1.75,1.75 0,20 L 0,20 Z" fill="#F6F6F6" />
    </svg>
  </Box>
));

function LayoutWithTwoCards({
  contentLeft,
  contentRight,
}: Readonly<{ contentLeft: React.ReactElement; contentRight: React.ReactElement }>) {
  const [smallVersion, setSmallVersion] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { isQuickMenuOpen, isSidebarFullWidth } = useOutletContext<{
    isQuickMenuOpen: boolean;
    isSidebarFullWidth: boolean;
  }>();
  useEffect(() => {
    const handleResize = debounce(() => {
      if (containerRef.current && containerRef.current.clientWidth < 1000) {
        setSmallVersion(true);
      } else {
        setSmallVersion(false);
      }
    }, 100);

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      handleResize.cancel(); // cancel any pending debounced calls on cleanup
    };
  }, [isQuickMenuOpen, isSidebarFullWidth]);

  const cornerStyles = {
    position: 'absolute',
    width: '20px',
    height: '20px',
    zIndex: 10,
  };

  const fixedMainContentStyles = {
    mt: 'auto',
    ml: 'auto',
    position: 'fixed',
    left: isSidebarFullWidth ? `${SIDEBAR_FULL_WIDTH}px` : `${SIDEBAR_COLLAPSED_WIDTH}px`,
    right: `${CONTENT_RIGHT_GAP}px`,
    bottom: '20px',
    top: '72px',
  };

  const maskCorners = (
    <Box
      sx={{
        ...fixedMainContentStyles,
        zIndex: 10,
        pointerEvents: 'none',
      }}
    >
      <InvertedBorderRadiusBox
        sx={{ ...cornerStyles, top: -1, left: smallVersion ? 0 : TWO_COL_LAYOUT_LEFT_GAP }}
      />
      <InvertedBorderRadiusBox
        sx={{ ...cornerStyles, top: -1, right: 0, transform: 'rotate(90deg)' }}
      />
      <InvertedBorderRadiusBox
        sx={{ ...cornerStyles, bottom: 0, right: 0, transform: 'rotate(180deg)' }}
      />
      <InvertedBorderRadiusBox
        sx={{
          ...cornerStyles,
          bottom: 0,
          left: smallVersion ? 0 : TWO_COL_LAYOUT_LEFT_GAP,
          transform: 'rotate(-90deg)',
        }}
      />
    </Box>
  );

  return (
    <>
      <Box sx={fixedMainContentStyles} ref={containerRef}>
        <Card
          sx={{
            height: '100%',
            boxShadow: 'none',
            backgroundColor: 'background.default',
            borderRadius: (theme) => theme.shape.borderRadius * 4,
          }}
        />
      </Box>
      {!smallVersion && (
        <Box
          sx={{
            position: 'fixed',
            top: '72px',
            width: TWO_COL_LAYOUT_LEFT_CARD_WIDTH,
            backgroundColor: 'common.white',
            left: isSidebarFullWidth ? `${SIDEBAR_FULL_WIDTH}px` : `${SIDEBAR_COLLAPSED_WIDTH}px`,
            border: ({ palette }) => `1px solid ${palette.grey[100]}`,
            height: 'calc(100% - 92px)',
            borderRadius: (theme) => theme.shape.borderRadius * 4,
            overflow: 'hidden',
            zIndex: 2,
          }}
        >
          {cloneElement(contentLeft, { smallVersion })}
        </Box>
      )}

      <Box
        id="main-layout-content-card"
        sx={{
          mt: 'auto',
          ml: 'auto',
          position: 'fixed',
          left: `calc( ${
            isSidebarFullWidth ? `${SIDEBAR_FULL_WIDTH}px` : `${SIDEBAR_COLLAPSED_WIDTH}px`
          } + ${smallVersion ? '0px' : `${TWO_COL_LAYOUT_LEFT_GAP}px`} )`,
          right: `${CONTENT_RIGHT_GAP}px`,
          border: ({ palette }) => `1px solid ${palette.grey[100]}`,
          top: '72px',
          bottom: '20px',
          zIndex: 6,
          pointerEvents: 'none',
          overflow: 'hidden',
          borderRadius: (theme) => theme.shape.borderRadius * 4,
          display: 'flex',
          flexDirection: 'column',
        }}
      />
      <Box
        sx={{
          mt: 'auto',
          ml: 'auto',
          position: 'fixed',
          left: `calc( ${
            isSidebarFullWidth ? `${SIDEBAR_FULL_WIDTH}px` : `${SIDEBAR_COLLAPSED_WIDTH}px`
          } + ${smallVersion ? '0px' : `${TWO_COL_LAYOUT_LEFT_GAP}px`} )`,
          right: `${CONTENT_RIGHT_GAP}px`,
          backgroundColor: 'common.white',
          top: '72px',
          bottom: '20px',
          zIndex: 5,
          pointerEvents: 'none',
          overflow: 'hidden',
          borderRadius: (theme) => theme.shape.borderRadius * 4,
          '& > *': {
            pointerEvents: 'auto',
          },
        }}
      />
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          height: 20,
          backgroundColor: 'background.default',
          zIndex: 12,
        }}
      />
      {maskCorners}
      <Box
        sx={{
          mt: '64px',
          ml: 'auto',
          mr: 0,
          width: `calc(100% - ${
            isSidebarFullWidth ? `${SIDEBAR_FULL_WIDTH}px` : `${SIDEBAR_COLLAPSED_WIDTH}px`
          } + 16px ${!smallVersion ? `- ${TWO_COL_LAYOUT_LEFT_GAP}px` : ''})`,
          height: 'calc(100% - 64px)',
          overflow: 'hidden',
          position: 'relative',
          zIndex: 5,
        }}
      >
        {cloneElement(contentRight, { smallVersion })}
      </Box>
    </>
  );
}

export default memo(LayoutWithTwoCards);
