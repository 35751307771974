import { EmojiMartData } from '@emoji-mart/data';
import { useTranslation } from 'react-i18next';
import { IconName } from 'src/ui-components/utils/icon/SvgIconByName';
import Grid from '@mui/material/Grid2';
import IconOrEmojiGrid from './grid/IconOrEmojiGrid';
import Empty from '../empty/Empty';
import { useFilteredEmojisAndIcons } from './hooks/useFilteredEmojiAndIcons';

interface Props {
  searchVal: string;
  emojiData: EmojiMartData;
  icons: IconName[];
  selectedIcon: string;
  handleIconClick: (value: string) => void;
}

export default function SearchIcons({
  searchVal,
  emojiData,
  icons,
  selectedIcon,
  handleIconClick,
}: Props) {
  const { t } = useTranslation();

  const filteredElements = useFilteredEmojisAndIcons(searchVal, emojiData.emojis, icons);
  const filteredEmojis = filteredElements.emojis;
  const filteredIcons = filteredElements.icons;
  const gridColCount = emojiData.categories.length + 1;

  return (
    <Grid container columns={gridColCount}>
      <IconOrEmojiGrid
        emojis={filteredEmojis}
        icons={filteredIcons}
        selectedIcon={selectedIcon}
        onClick={handleIconClick}
      />
      {filteredEmojis?.length === 0 && filteredIcons?.length === 0 && (
        <Grid size={emojiData.categories.length + 1}>
          <Empty description={t('notFound')} />
        </Grid>
      )}
    </Grid>
  );
}
