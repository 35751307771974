import React from 'react';
import { Paper, TableHead, TableHeadProps } from '@mui/material';

interface WorkbaseTableHeadProps extends TableHeadProps {
  children: React.ReactNode;
}

function WorkbaseTableHead({
  children,
  sx,
  component = Paper,
  ...otherProps
}: WorkbaseTableHeadProps) {
  return (
    <TableHead
      component={component}
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        boxShadow: 'none',
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </TableHead>
  );
}

export default WorkbaseTableHead;
