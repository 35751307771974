import { FormControl, FormHelperText, Stack, Typography } from '@mui/material';
import { WorkbaseTextField } from 'src/lib/components/atoms/text-field';

import WorkbaseDialogComponent from 'src/lib/components/molecules/dialog/Dialog';
import { ApiKeyPostData } from 'src/store/api/apiKeysApi';
import { useTranslation } from 'react-i18next';
import { useApiKeyForm } from '../../controller/useApiKeyForm';

export type ApiKeyEditData = ApiKeyPostData & { id: string };

export default function ApiKeyEditDialog({
  apiKeyData,
  onClose,
  onSubmit,
}: {
  apiKeyData: ApiKeyEditData;
  onClose: () => void;
  onSubmit: (newName: string) => void;
}) {
  const { name, nameError, handleNameChange, handleNameBlur } = useApiKeyForm(apiKeyData.name);

  const { t } = useTranslation();
  const handleCloseDialog = () => {
    onClose();
  };

  return (
    <WorkbaseDialogComponent
      dataCy="edit-api-key"
      confirmButton={{
        text: t('companySettings.apiKeys.saveChanges'),
        onClick: () => onSubmit(name),
        disabled: !!nameError,
      }}
      cancelButton={{
        text: t('cancel'),
        onClick: handleCloseDialog,
      }}
      title={t('companySettings.apiKeys.editApiKey')}
      open={!!apiKeyData}
      onClose={handleCloseDialog}
      sx={{
        '& h2': {
          paddingBottom: '10px',
        },
        '& .MuiDialog-paper': {
          width: '100%',
        },
      }}
    >
      <Stack direction="column" gap={3}>
        <Typography variant="body1" color="text.secondary" />
        <Stack spacing={2} alignItems="flex-start">
          <FormControl fullWidth error={!!nameError}>
            <WorkbaseTextField
              dataCy="edit-api-key-textfield"
              sx={{ width: '100%' }}
              label={t('companySettings.apiKeys.table.columnNames.keyName')}
              name="name"
              error={!!nameError}
              onBlur={handleNameBlur}
              value={name}
              onChange={(e: any) => {
                handleNameChange(e);
              }}
              placeholder={t('companySettings.apiKeys.namePlaceholder')}
              fullWidth
            />
            {!!nameError && <FormHelperText>{t(nameError)}</FormHelperText>}
          </FormControl>
        </Stack>
      </Stack>
    </WorkbaseDialogComponent>
  );
}
