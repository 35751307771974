import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface User {
  id: number;
  name: string;
  avatar: string;
}

export const reqresApi = createApi({
  reducerPath: 'reqresApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://reqres.in/api' }),
  endpoints: (builder) => ({
    listUsers: builder.query<User[], void>({
      query: () => `/users`,
      transformResponse: (response: any) =>
        response.data.map((user: any) => ({
          id: user.id,
          name: `${user.first_name}`,
          avatar: user.avatar,
        })),
    }),
    updateUser: builder.mutation<void, Pick<User, 'id' | 'name'>>({
      query: ({ id, name }) => ({
        url: `/users/${id}`,
        body: { first_name: name },
        method: 'PATCH',
      }),
      onQueryStarted: (payload, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          reqresApi.util.updateQueryData('listUsers', undefined, (draft) => {
            const updatedUser = draft.find((user) => user.id === payload.id);
            if (updatedUser) updatedUser.name = payload.name;
          })
        );

        queryFulfilled.catch(patch.undo);
      },
    }),
  }),
});

export const { useListUsersQuery, useUpdateUserMutation } = reqresApi;
