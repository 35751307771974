import { Stack } from '@mui/material';
import { getDescendants } from '@minoru/react-dnd-treeview';
import { TreeNodeModel } from 'src/ui-components/custom/hierarchical-tree/types';
import ShrankTreeItem from './ShrankTreeItem';

interface Props {
  data: TreeNodeModel[];
}

export default function ShrankTree({ data }: Props) {
  return (
    <Stack mt="15px" gap={0.25} alignItems="center">
      {data
        .filter(({ parent }) => parent === 0)
        .map((item) => (
          <ShrankTreeItem key={item.id} data={item} descendants={getDescendants(data, item.id)} />
        ))}
    </Stack>
  );
}
