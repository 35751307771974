import { ReactNode } from 'react';
import { Box, SvgIcon } from '@mui/material';
import { WorkbaseEditIcon } from 'src/assets/icons/workbaseIcons';

interface ImageEditButtonProps {
  onClick?: (e: any) => void;
  children?: ReactNode;
}

export default function ImageEditOverlay({ onClick, children }: ImageEditButtonProps) {
  return (
    <Box
      onClick={(e) => {
        onClick?.(e);
      }}
      component="label"
      color="common.white"
      position="absolute"
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      zIndex={2}
      bottom={0}
      borderRadius={1}
      left={0}
      sx={{
        backgroundColor: 'action.active',
        opacity: 0,
        cursor: 'pointer',
        pointerEvents: 'all',
        transition: 'opacity .2s ease-in',
        '&:hover': {
          opacity: 1,
        },
      }}
    >
      {children}
      <SvgIcon component={WorkbaseEditIcon} />
    </Box>
  );
}
