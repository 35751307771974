import { Box } from '@mui/material';
import { WorkbaseIconByName } from '../../lib/components/atoms/icon';
import ColoredCircle from './ColoredCircle';

export default function CenteredPauseCircle() {
  return (
    <Box sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
      <ColoredCircle backgroundColor="secondary.main">
        <WorkbaseIconByName iconName="WorkbasePauseIcon" color="common.white" size={32} />
      </ColoredCircle>
    </Box>
  );
}
