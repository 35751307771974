import { Box, Typography } from '@mui/material';
import { WorkbaseIcon, WorkbaseIconType } from '../../atoms/icon/WorkbaseIcon';

export type PlaceholderProps = {
  icon?: WorkbaseIconType;
  title?: string | React.ReactNode;
  text?: string;
};

function Placeholder({ icon, title, text }: PlaceholderProps) {
  return (
    <Box textAlign="center">
      {icon && <WorkbaseIcon icon={icon} color="text.secondary" sx={{ width: 50, height: 50 }} />}
      <Typography color="text.primary" variant="body1" fontWeight="bold">
        {title}
      </Typography>
      <Typography color="grey[500]" mt="6px" variant="body1">
        {text}
      </Typography>
    </Box>
  );
}

export default Placeholder;
