import Slider from 'src/lib/components/organisms/slider/Slider';
import { NewsfeedItem } from 'src/store/api/newsfeedApi';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/store';
import TrainingItem from './NewsfeedSliderItem';
import { setSelectedNewsfeedItemIdx } from '../../controller/slice';

interface Props {
  items: NewsfeedItem[];
}

export default function NewsfeedSlider({ items }: Props) {
  const selectedIdx = useAppSelector((state) => state.dashboard.selectedNewsfeedItemIdx);
  const dispatch = useAppDispatch();
  return (
    <Box sx={{ width: '100%' }}>
      <Slider
        infinite
        initialSlide={selectedIdx}
        afterChange={(idx) => dispatch(setSelectedNewsfeedItemIdx(idx))}
      >
        {items?.map((item) => (
          <TrainingItem key={item.type === 'training' ? item.id : item.id.lesson} {...item} />
        ))}
      </Slider>
    </Box>
  );
}
