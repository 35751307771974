import { useState } from 'react';
import { t } from 'i18next';
import axiosInstance from 'src/axios/axios';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import { showNotification } from 'src/lib/components/atoms/notification';
import { WorkbaseMotivationIcon, WorkbaseInfoIcon } from 'src/assets/icons/workbaseIcons';
import { useSettingsQuery } from 'src/store/api/settingsApi';
import RecomendationForm from './RecommendationForm';
import { RecommendedEmployeeData } from '../types';

interface WorkbaseRecommendationPopupProps {
  active: boolean;
  setActive: (value: boolean) => void;
  dataCy?: string;
}

export default function RecommendationPopup({
  active,
  setActive,
  dataCy,
}: WorkbaseRecommendationPopupProps) {
  const { data } = useSettingsQuery();
  const [validForm, setValidForm] = useState(false);
  const [employeeData, setEmployeeData] = useState<RecommendedEmployeeData>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    extraInfo: '',
  });

  const submitRecommendation = async () => {
    axiosInstance
      .post('/employee-referrals', employeeData)
      .then(() => {
        showNotification(t('recommendationPopup.notification.sendSuccess'));
        setActive(false);
      })
      .catch(() => {
        showNotification(t('recommendationPopup.notification.sendError'), 'error');
      });
  };

  return (
    <Dialog open={active} onClose={() => setActive(false)} maxWidth="sm" fullWidth>
      <DialogTitle component={Stack} gap={1} direction="row">
        {t('recommendationPopup.recommendationMessage')}
        <SvgIcon component={WorkbaseMotivationIcon} fontSize="small" />
      </DialogTitle>
      <DialogContent>
        {data && (
          <Stack direction="column" gap={1} overflow="auto">
            <Typography variant="body1">
              {data.recommendationNotificationPreferences.descriptionText !== ''
                ? data.recommendationNotificationPreferences.descriptionText
                : t('companySettings.employeeRecommendations.resettableTextarea.placeholder')}
            </Typography>

            <Stack direction="row" gap={1} color="success.main">
              <SvgIcon component={WorkbaseInfoIcon} fontSize="small" htmlColor="inherit" />
              <Typography variant="caption" color="inherit">
                {t('recommendationPopup.infoMessage')}
              </Typography>
            </Stack>
            <Typography fontWeight="bold">
              {t('recommendationPopup.contactDetailsTitle')}
            </Typography>
            <RecomendationForm
              dataCy={dataCy}
              setValidForm={setValidForm}
              data={employeeData}
              onChange={(value: Partial<RecommendedEmployeeData>) =>
                setEmployeeData((prevData) => ({ ...prevData, ...value }))
              }
            />
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setActive(false)}
          fullWidth
          color="inherit"
          data-cy={dataCy && `${dataCy}-cancel-button`}
        >
          {t('cancel')}
        </Button>
        <Button
          fullWidth
          type="submit"
          disabled={!validForm}
          onClick={submitRecommendation}
          data-cy={dataCy && `${dataCy}-submit-button`}
        >
          {t('recommendationPopup.submitButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
