import { useTranslation } from 'react-i18next';
import TextFieldWithIconSelector from 'src/lib/components/molecules/text-field-with-icon-selector';
import TextWithIcon from 'src/lib/components/molecules/text-with-icon';
import { useAppSelector } from 'src/store';
import { shallowEqual, useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import { sidebarApi } from 'src/store/api/sidebarApi';
import { useEditor } from '../../controller';
import BaseHeader from '../base-header/BaseHeader';

interface Props {
  readOnly: boolean;
}

export default function CustomSiteHeader({ readOnly }: Props) {
  const { t } = useTranslation();
  const header = useAppSelector((state) => state.editor.editorData?.header, shallowEqual);
  const dispatch = useDispatch<ThunkDispatch<any, any, AnyAction>>();
  const params = useParams();
  const [name, setName] = useState(header?.name || '');
  const { handleChangeHeaderName, handleIconChange } = useEditor();

  useEffect(() => {
    if (header?.name !== undefined) {
      setName(header?.name);
    }
  }, [header?.name]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeDebounced = useCallback(
    debounce((val) => {
      handleChangeHeaderName(val);
      dispatch(
        sidebarApi.util.updateQueryData('sidebar', undefined, (draft) => {
          // eslint-disable-next-line no-param-reassign
          draft.sidebarItems = draft.sidebarItems.map((site) =>
            site.id === params.id ? { ...site, name: val } : site
          );
        })
      );
    }, 300),
    [handleChangeHeaderName, params.id]
  );

  if (!header) return null;

  const unnamedPageText = t('unnamedPageLabel');
  const headerIcon = header.icon || 'WorkbaseEmptyContentPageIcon';
  return (
    <BaseHeader readOnly={readOnly} header={header}>
      {!readOnly && (
        <TextFieldWithIconSelector
          className="custom-site-header-text-field-with-icon-selector"
          size="H1"
          value={name}
          autoFocus
          onChange={(e) => {
            setName(e.target.value);
            handleChangeDebounced(e.target.value);
          }}
          placeholder={`${unnamedPageText}...`}
          changeableIconProps={{
            value: headerIcon,
            onChange: (val) => {
              handleIconChange(val);
              dispatch(
                sidebarApi.util.updateQueryData('sidebar', undefined, (draft) => {
                  // eslint-disable-next-line no-param-reassign
                  draft.sidebarItems = draft.sidebarItems.map((site) =>
                    site.id === params.id ? { ...site, icon: val } : site
                  );
                })
              );
            },
          }}
          backgroundColor={header.darkMode ? 'tertiary.main' : 'white'}
          sx={{ maxWidth: '80%' }}
        />
      )}
      {readOnly && (
        <TextWithIcon
          sx={{ position: 'relative', maxWidth: '80%' }}
          color={header.darkMode ? 'common.white' : 'text.primary'}
          icon={headerIcon}
          title={!name ? unnamedPageText : name}
        />
      )}
    </BaseHeader>
  );
}
