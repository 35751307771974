import { Box, ButtonBase, SxProps } from '@mui/material';
import React, { useRef, useState } from 'react';
import { Theme } from '@mui/material/styles';
import DnDIndicator from './DnDIndicator';
import EditBar, { EditBarProps } from '../edit-bar/EditBar';
import ResizableWrapper, { ResizeStopCallbackType } from './resize/ResizableWrapper';
import HighlightBorder from './HighlightBorder';

interface ChildrenProps {
  EditBarComponent: React.ReactNode;
  focused: boolean;
  hovered: boolean;
  columnsCount: number;
}

interface HighlightItemProps {
  children: (props: ChildrenProps) => React.ReactNode;
  minHeight?: number;
  verticalResize?: {};
  horizontalResize?: {};
  dragging?: { dragRef?: any; previewRef?: any; sx?: SxProps };
  editBarProps?: EditBarProps;
  bordered?: boolean;
  sx?: SxProps<Theme>;
  readOnly?: boolean;
  onResizeStop?: ResizeStopCallbackType;
  borderColor?: string;
  borderStyle?: string;
  onResizeStart?: () => void;
  hideEditbar?: boolean;
  columnsCountNum?: number;
}

export default function HighlightItem({
  children,
  minHeight,
  verticalResize,
  horizontalResize,
  dragging,
  editBarProps,
  bordered = true,
  sx,
  readOnly,
  onResizeStop,
  borderColor = 'grey.300',
  borderStyle,
  onResizeStart,
  hideEditbar = false,
  columnsCountNum,
}: HighlightItemProps) {
  const [columnsCount, setColumnsCount] = useState(columnsCountNum || 4);
  const [focused, setFocused] = useState(false);
  const dndIndicatorFocusedRef = useRef(false);
  const buttonRef = useRef(null);
  const shouldShowEditTools = focused && !readOnly;
  const [isHovered, setIsHovered] = useState(false);
  const editBarRenderProp =
    shouldShowEditTools && !hideEditbar && editBarProps ? (
      <EditBar
        {...editBarProps}
        baseActionsProps={{
          ...editBarProps.baseActionsProps,
          onDuplicate: () => {
            editBarProps.baseActionsProps.onDuplicate();
            setIsHovered(false);
          },
        }}
      />
    ) : null;
  const baseBorderColor = bordered && !readOnly ? borderColor : 'transparent';

  return (
    <ResizableWrapper
      setColumnsCount={setColumnsCount}
      showHorizontalResize={
        (shouldShowEditTools && !!horizontalResize) || (isHovered && !!horizontalResize)
      }
      showVerticalResize={
        (shouldShowEditTools && !!verticalResize) || (isHovered && !!verticalResize)
      }
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
      setHovered={setIsHovered}
      minHeight={minHeight}
    >
      <Box
        ref={dragging?.previewRef}
        onMouseDown={(e) => e.stopPropagation()}
        style={{ transform: 'translate(0,0)' }}
      >
        <ButtonBase
          className="highlight-item-button"
          component="div"
          ref={buttonRef}
          disableRipple
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            if (!dndIndicatorFocusedRef.current) {
              setFocused(false);
              setIsHovered(false);
            }
          }}
          onMouseMove={readOnly ? undefined : () => setIsHovered(true)}
          onMouseLeave={readOnly ? undefined : () => setIsHovered(false)}
          onMouseDown={(e) => e.stopPropagation()}
          sx={{
            borderRadius: (theme) => theme.shape.borderRadius,
            position: 'relative',
            display: 'block',
            width: '100%',
            cursor: 'auto',
            transform: 'translate(0,0)',
            '.higlight-border':
              isHovered && bordered && !readOnly ? { borderColor: 'primary.main' } : {},
            '&:hover > .higlight-border': readOnly
              ? {}
              : {
                  borderColor: bordered ? 'primary.main' : 'transparent',
                },
            ...sx,
          }}
        >
          <HighlightBorder
            color={shouldShowEditTools && bordered ? 'primary.main' : baseBorderColor}
            borderStyle={!isHovered && !focused ? borderStyle : 'solid'}
          />
          {children({
            EditBarComponent: editBarRenderProp,
            focused,
            hovered: isHovered,
            columnsCount,
          })}
        </ButtonBase>
      </Box>
      {dragging && (shouldShowEditTools || isHovered) && (
        <DnDIndicator
          {...dragging}
          dndIndicatorFocusedRef={dndIndicatorFocusedRef}
          setHovered={setIsHovered}
          buttonRef={buttonRef}
        />
      )}
    </ResizableWrapper>
  );
}
