import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'src/store';
import { ResultData, ResourceType } from 'src/store/api/searchApi';
import { t } from 'i18next';
import React from 'react';
import SvgIconOrEmoji from 'src/ui-components/utils/icon/SvgIconOrEmoji';
import { Avatar, ListItemAvatar, ListItemIcon } from '@mui/material';
import { setSearchHistory } from './search.slice';
import { getNavigationRoute } from './utils';

interface ResultVariables {
  name: string;
  navigateRoute: string;
  description: string | null;
  prefix: React.ReactNode;
}

export const useSearchResultsDict = (item: ResultData) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const setSearchResult = (newSearchResult: { name: string; navigateRoute: string }) => {
    dispatch(setSearchHistory(newSearchResult));
  };

  const searchResultDict: Record<ResourceType, ResultVariables> = {
    page: {
      name: item.name || t('customSites.unnamedPage'),
      navigateRoute: getNavigationRoute(item),
      description: item.context,
      prefix: item.icon && (
        <ListItemIcon>
          <SvgIconOrEmoji content={item.icon} fontSize="medium" />
        </ListItemIcon>
      ),
    },
    training: {
      name: item.name || t('trainingDetailPage.unnamedTraining'),
      navigateRoute: getNavigationRoute(item),
      description: item.context,
      prefix: (
        <ListItemIcon>
          <SvgIconOrEmoji content="WorkbaseTrainingsIcon" fontSize="medium" />
        </ListItemIcon>
      ),
    },
    training_module: {
      name: item.name || 'trainingModule',
      navigateRoute: getNavigationRoute(item),
      description: item.context,
      prefix: (
        <ListItemIcon>
          <SvgIconOrEmoji content="WorkbaseModuleIcon" fontSize="medium" />
        </ListItemIcon>
      ),
    },
    training_lesson: {
      name: item.name || 'trainingLesson',
      navigateRoute: getNavigationRoute(item),
      description: item.context,
      prefix: (
        <ListItemIcon>
          <SvgIconOrEmoji content="WorkbaseLessonIcon" fontSize="medium" />
        </ListItemIcon>
      ),
    },
    company_user: {
      name: `${item.name}`,
      navigateRoute: getNavigationRoute(item),
      description: item.email,
      prefix: (
        <ListItemAvatar>
          <Avatar src={item.imageUrl || undefined} />
        </ListItemAvatar>
      ),
    },
    embedded_app: {
      name: item.name || t('embeddedApps.unnamedApp'),
      navigateRoute: getNavigationRoute(item),
      description: item.context,
      prefix: item.icon && (
        <ListItemIcon>
          <SvgIconOrEmoji content={item.icon} fontSize="medium" />
        </ListItemIcon>
      ),
    },
  };

  const handleClick = () => {
    navigate(searchResultDict[item.source].navigateRoute, { relative: 'route' });
    const searchResult = {
      name: searchResultDict[item.source].name,
      navigateRoute: searchResultDict[item.source].navigateRoute,
    };
    setSearchResult(searchResult);
  };

  return {
    searchResultDict,
    handleClick,
  };
};
