import { Theme } from '@mui/material/styles';

export const linkIconBoxStyle = (theme: Theme) => ({
  display: 'flex',
  marginRight: 1,
  background: 'white',
  borderColor: 'grey.300',
  borderStyle: 'solid',
  borderWidth: 1,
  flexShrink: 0,
  width: 22,
  height: 22,
  padding: '2px',
  borderRadius: theme.shape.borderRadius,
  '& img': {
    borderRadius: theme.shape.borderRadius * 0.5,
  },
  justifyContent: 'center',
});

export const actionIconBoxStyles = (theme: Theme) => ({
  padding: '4px',
  backgroundColor: 'transparent',
  display: 'flex',
  borderRadius: theme.shape.borderRadius,
  transition: 'opacity 0.2s ease-out',
  opacity: '0',
  '&:hover': {
    backgroundColor: 'common.white',
    '&&&& > *': {
      color: 'primary.main',
    },
  },
});

export const actionIconStyles = {
  width: 16,
  height: 16,
  color: 'common.white',
};

export const linkIconStyle = {
  width: 15,
  height: 15,
  color: 'text.primary',
};
