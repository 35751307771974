import React, { useEffect, useState } from 'react';
import { getParents, NodeModel, TreeMethods } from '@minoru/react-dnd-treeview';
import { TreeNodeData } from './types';

const useTreeOpenHandler = (tree: NodeModel<TreeNodeData>[]) => {
  const ref = React.useRef<TreeMethods | null>(null);

  const [openIds, setOpenIds] = React.useState<(string | number)[]>([]);
  const [initialOpenNodesSet, setInitialOpenNodesSet] = useState(false);

  const open = (id: number | string) => {
    ref.current?.open(id);
    setOpenIds((p) => (p.includes(id) ? p : [...p, id]));
  };
  const close = (id: number | string) => {
    ref.current?.close(id);
    setOpenIds((p) => [...p.filter((v) => v !== id)]);
  };
  const toggle = (id: number | string) => {
    if (openIds.includes(id)) {
      close(id);
    } else {
      open(id);
    }
  };

  useEffect(() => {
    if (initialOpenNodesSet) return undefined;
    const activeNode = tree.find(({ data }) => data?.active);
    if (activeNode) {
      const parents = getParents(tree, activeNode.id);
      setOpenIds(parents.map(({ id }) => id));
      setInitialOpenNodesSet(true);
    }
    return undefined;
  }, [tree, initialOpenNodesSet]);

  return { ref, toggle, openIds, setOpenIds };
};

export default useTreeOpenHandler;
