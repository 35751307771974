import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  GeneralAccessType,
  ResourceType,
  useGeneralAccessQuery,
} from 'src/store/api/shareResourceApi';
import { IconName } from 'src/lib/components/atoms/icon';

import SingleAccessControlItem from './access-list/SingleAccessControlItem';

interface Props {
  resourceId: string;
  resourceType: ResourceType;
}

export default function GeneralAccess({ resourceId, resourceType }: Props) {
  const { t } = useTranslation();
  const { data } = useGeneralAccessQuery({ resourceId, resourceType });
  const isTrainingResourceType = resourceType === 'training';
  const iconPropsDict: Record<
    GeneralAccessType,
    {
      backgroundColor: string;
      name: IconName;
      color: string;
    }
  > = {
    restricted: {
      backgroundColor: 'grey.300',
      name: 'WorkbaseLockIcon',
      color: 'text.primary',
    },
    everyone: {
      backgroundColor: 'green.light',
      name: 'WorkbaseBusinessPlusIcon',
      color: 'success.main',
    },
  };
  return (
    <Stack gap={1.5}>
      <Typography variant="h5">{t('permissionsShare.generalAccess')}</Typography>
      <SingleAccessControlItem
        title={t('permissionsShare.adminAccess')}
        description={t('permissionsShare.adminAccessDescription')}
        icon={iconPropsDict[data?.generalAccess.type || 'everyone']}
        withRequiredSwitch={isTrainingResourceType}
        resourceId={resourceId}
        resourceType={resourceType}
        canEditShareLevel
        required={data?.generalAccess.required}
        shareLevel={data?.generalAccess.shareLevel}
        isGeneralAccess
        generalAccessType={data?.generalAccess.type}
      />
    </Stack>
  );
}
