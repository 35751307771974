import { ReactPlayerProps } from 'react-player';
import { OnProgressProps } from 'react-player/base';

export interface WorkbasePlayerProps extends ReactPlayerProps {
  active: boolean;
  ended: boolean;
  fullscreen: boolean;
  muted: boolean;
  duration: number;
  progress: OnProgressProps;
}

export type PlayerAction =
  | { type: 'ACTIVE'; payload: boolean }
  | { type: 'PLAY' }
  | { type: 'PAUSE' }
  | { type: 'ENDED'; payload: boolean }
  | { type: 'TOGGLE_PLAY' }
  | { type: 'DURATION'; payload: number }
  | { type: 'SEEK'; payload: OnProgressProps }
  | { type: 'VOLUME'; payload: number }
  | { type: 'TOGGLE_MUTE'; payload: boolean }
  | { type: 'LIGHT'; payload: boolean }
  | { type: 'FULLSCREEN' }
  | { type: 'PLAYBACK_RATE'; payload: number };

const INITIAL_STATE: WorkbasePlayerProps = {
  active: false,
  playing: false,
  controls: false,
  fullscreen: false,
  playbackRate: 1,
  volume: 0.8,
  light: true,
  ended: false,
  muted: false,
  progress: {
    playedSeconds: 0,
    loaded: 0,
    played: 0,
    loadedSeconds: 0,
  },
  duration: 0,
};

const reducer = (state: WorkbasePlayerProps, action: PlayerAction): WorkbasePlayerProps => {
  switch (action.type) {
    case 'ACTIVE':
      return { ...state, active: action.payload };
    case 'PLAY':
      return { ...state, playing: true };
    case 'PAUSE':
      return { ...state, playing: false };
    case 'ENDED':
      return { ...state, ended: action.payload };
    case 'TOGGLE_PLAY':
      return { ...state, playing: !state.playing };
    case 'DURATION':
      return { ...state, duration: action.payload };
    case 'SEEK':
      return {
        ...state,
        progress: action.payload,
      };
    case 'VOLUME':
      return { ...state, volume: action.payload };
    case 'TOGGLE_MUTE':
      return { ...state, muted: action.payload };
    case 'LIGHT':
      return { ...state, light: action.payload };
    case 'FULLSCREEN':
      return { ...state, fullscreen: !state.fullscreen };
    case 'PLAYBACK_RATE':
      return { ...state, playbackRate: action.payload };
    default:
      return state;
  }
};

export { reducer, INITIAL_STATE };
