import { Box, Divider } from '@mui/material';
import HighlightItem from '../highlight-item';
import { ResizeStopCallbackType } from '../highlight-item/resize/ResizableWrapper';

interface Props {
  onDelete: () => void;
  onDuplicate: () => void;
  readOnly?: boolean;
  onResizeStop?: ResizeStopCallbackType;
  dragging?: any;
  onResizeStart?: () => void;
}

export default function EditorDivider({
  onDelete,
  onDuplicate,
  readOnly,
  onResizeStop,
  dragging,
  onResizeStart,
}: Props) {
  return (
    <HighlightItem
      editBarProps={{ baseActionsProps: { onDelete, onDuplicate } }}
      horizontalResize={{}}
      dragging={{
        sx: {
          top: '60%',
        },
        ...dragging,
      }}
      bordered={false}
      sx={{ border: 'none' }}
      readOnly={readOnly}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
    >
      {({ EditBarComponent, focused, hovered }) => (
        <>
          {EditBarComponent}
          <Box
            sx={{
              '.MuiDivider-root':
                hovered && !readOnly
                  ? {
                      borderColor: 'primary.main',
                    }
                  : {},
              '&:hover .MuiDivider-root': readOnly
                ? {}
                : {
                    borderColor: 'primary.main',
                  },
              paddingTop: 0.5,
              paddingBottom: 0.5,
            }}
          >
            <Divider
              sx={{
                transition: '.15s',
                borderColor: !readOnly && focused ? 'primary.main' : 'grey.300',
                borderTopWidth: '2px',
              }}
            />
          </Box>
        </>
      )}
    </HighlightItem>
  );
}
