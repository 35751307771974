import { WorkbaseIcon, WorkbaseIconType } from 'src/lib/components/atoms/icon/WorkbaseIcon';
import {
  WorkbaseCheckmarkBigFilledIcon,
  WorkbaseErrorFilledIcon,
  WorkbaseInfoFilledIcon,
  WorkbaseNotesIcon,
  WorkbaseQuestionBubbleFilledIcon,
  WorkbaseWarningFilledIcon,
} from 'src/assets/icons/workbaseIcons';
import { Stack } from '@mui/material';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import BaseEditor from '../base-editor/BaseEditor';
import HighlightItem from '../highlight-item';
import { InfoType } from '../edit-bar/InfoActions';
import { ResizeStopCallbackType } from '../highlight-item/resize/ResizableWrapper';

interface Props {
  onDelete: () => void;
  onDuplicate: () => void;
  onResizeStop?: ResizeStopCallbackType;
  value?: string;
  infoType?: InfoType;
  readOnly?: boolean;
  onChange?: (val: string) => void;
  handleInfoTypeChange: (type: InfoType) => void;
  dragging?: {
    dragRef: ConnectDragSource;
    previewRef: ConnectDragPreview;
  };
  onResizeStart?: () => void;
}

const infoTypesDict: Record<
  InfoType,
  { color: string; icon: { icon: WorkbaseIconType; color: string } }
> = {
  info: { color: 'primary.light', icon: { icon: WorkbaseInfoFilledIcon, color: 'primary.main' } },
  question: {
    color: 'alternate.light',
    icon: { icon: WorkbaseQuestionBubbleFilledIcon, color: 'info.main' },
  },
  notes: { color: 'purple.light', icon: { icon: WorkbaseNotesIcon, color: 'purple.main' } },
  success: {
    color: 'green.light',
    icon: { icon: WorkbaseCheckmarkBigFilledIcon, color: 'success.main' },
  },
  warning: {
    color: 'secondary.light',
    icon: { icon: WorkbaseWarningFilledIcon, color: 'secondary.main' },
  },
  error: { color: 'red.light', icon: { icon: WorkbaseErrorFilledIcon, color: 'error.main' } },
};

export default function InfoPanel({
  onDelete,
  onDuplicate,
  value,
  infoType = 'info',
  readOnly = false,
  onChange,
  onResizeStop,
  handleInfoTypeChange,
  dragging,
  onResizeStart,
}: Props) {
  const { color, icon } = infoTypesDict[infoType];
  return (
    <HighlightItem
      editBarProps={{
        baseActionsProps: { onDelete, onDuplicate },
        textActionsProps: {},
        infoActionsProps: {
          infoType,
          onChangeActiveInfoType: (type) => handleInfoTypeChange(type as InfoType),
        },
      }}
      borderColor={color}
      dragging={dragging}
      horizontalResize={{}}
      readOnly={readOnly}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
    >
      {({ EditBarComponent }) => (
        <Stack
          direction="row"
          sx={{
            backgroundColor: color,
            paddingLeft: 1,
            borderRadius: (theme) => theme.shape.borderRadius * 2.5,
          }}
        >
          <WorkbaseIcon
            icon={icon.icon}
            sx={{ marginTop: 1 }}
            color={icon.color}
            hoverColor={icon.color}
            size={16}
          />
          <BaseEditor
            value={value}
            sx={{ flexGrow: 1, maxWidth: 'calc(100% - 24px)' }}
            readOnly={readOnly}
            onChange={onChange}
          >
            {EditBarComponent}
          </BaseEditor>
        </Stack>
      )}
    </HighlightItem>
  );
}
