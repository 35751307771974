import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseModuleIcon, WorkbaseAddModuleIcon } from 'src/assets/icons/workbaseIcons';
import { WorkbaseIcon } from 'src/lib/components/atoms/icon';

export default function EmptyPlaceholder() {
  const { t } = useTranslation();
  return (
    <Stack
      alignItems="center"
      gap={0.5}
      sx={{
        backgroundColor: 'background.default',
        padding: '56px 20px',
        borderRadius: (theme) => theme.shape.borderRadius * 4,
      }}
    >
      <WorkbaseIcon icon={WorkbaseModuleIcon} size={50} />
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="center"
        gap={0.5}
        sx={{ textAlign: 'center' }}
        alignItems="center"
      >
        <Typography variant="body1" color="text.secondary" fontWeight="medium">
          {t('editor.trainingCoursePage.trainingModulesWidget.emptyPlaceholderLabelBeforeIcon')}
        </Typography>
        <WorkbaseIcon icon={WorkbaseAddModuleIcon} size={18} color="text.secondary" />
        <Typography variant="body1" color="text.secondary" align="center" fontWeight="medium">
          {t('editor.trainingCoursePage.trainingModulesWidget.emptyPlaceholderLabelAfterIcon')}
        </Typography>
      </Stack>
    </Stack>
  );
}
