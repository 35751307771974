import { Stack, styled, Slider, alpha, LinearProgress } from '@mui/material';

export const StyledSlider = styled(Slider)(({ theme }) => ({
  width: '100%',
  color: theme.palette.primary.main,
  '& .MuiSlider-rail': {
    backgroundColor: alpha(theme.palette.text.secondary as string, 0.15),
    opacity: 1,
    zIndex: 0,
    height: '8px',
  },
  '& .MuiSlider-track': {
    border: 'none',
    height: '8px',
    transition: 'width 0.15s linear',
    zIndex: 3,
  },
  '& .MuiSlider-track::after': {
    width: '12px',
    height: '12px',
    backgroundColor: theme.palette.common.white,
  },
  '& .MuiSlider-thumb': {
    backgroundColor: theme.palette.common.white,
    transition: 'left 0.15s linear',
    height: '12px',
    zIndex: 4,
    width: '12px',
    '&:before': {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.4)',
    },
    '&:hover, &.Mui-focusVisible, &.Mui-active': {
      boxShadow: 'none',
    },
  },
}));

export const StyledProgressBar = styled(LinearProgress)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: 0,
  right: 0,
  height: 8,
  borderRadius: 4,
  opacity: 1,
  transform: 'translateY(-50%)',
  backgroundColor: alpha(theme.palette.text.secondary as string, 0.75),
  '& .MuiLinearProgress-bar': {
    backgroundColor: alpha(theme.palette.common.white as string, 0.75),
  },
}));

export interface Progress {
  finishedSeconds: number;
  playedSeconds: number;
  loaded: number;
}

function SeekSlider({
  progress,
  duration,
  handleSeek,
  watchProgress,
  disableFastForward,
  readOnly,
  playerRef,
}: {
  progress: Progress;
  duration: number;
  handleSeek: (newValue: number) => void;
  watchProgress: number;
  disableFastForward: boolean;
  readOnly: boolean;
  playerRef: any;
}) {
  return (
    <Stack width="100%" position="relative">
      <StyledProgressBar variant="determinate" value={progress.loaded * 100} />
      <StyledSlider
        min={0}
        max={duration}
        step={0.01}
        value={progress.playedSeconds}
        onChange={(_e, newValue) => {
          if ((newValue as number) > watchProgress && disableFastForward && readOnly) {
            playerRef.current.seekTo(watchProgress as number, 'seconds');
            return;
          }
          handleSeek(newValue as number);
        }}
      />
    </Stack>
  );
}

export default SeekSlider;
