import { IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import { WorkbaseHelpIcon } from 'src/assets/icons/workbaseIcons';
import { useTranslation } from 'react-i18next';
import { Logo } from 'src/store/api/settingsApi';
import UploadLogo from './UploadLogo';

export interface LogoSettingsProps {
  title: string;
  lightLogoKey: Logo;
  darkLogoKey: Logo;
  lightLogoUrl: string;
  darkLogoUrl: string;
}
const parsedUrl = new URL(import.meta.env.VITE_WORKBASE_LANDING_PAGE_URL);
export default function LogoSettings({
  title,
  lightLogoUrl,
  darkLogoUrl,
  lightLogoKey,
  darkLogoKey,
}: LogoSettingsProps) {
  const { t } = useTranslation();
  return (
    <Stack gap={2} maxWidth={(theme) => theme.breakpoints.values.md} width="100%">
      <Stack direction="row" alignItems="center" gap={0.25}>
        <Typography variant="subtitle1" fontWeight="medium">
          {title}
        </Typography>
        <IconButton
          href={`${parsedUrl.href}`}
          target="_blank"
          data-cy="site-information-icon"
          size="small"
        >
          <SvgIcon fontSize="inherit" component={WorkbaseHelpIcon} />
        </IconButton>
      </Stack>
      <Stack direction="row" gap={2.5}>
        <UploadLogo
          text={t('companySettings.siteInformation.logoInfo.lightLogobutton')}
          name={lightLogoKey}
          url={lightLogoUrl}
        />
        <UploadLogo
          text={t('companySettings.siteInformation.logoInfo.darkLogobutton')}
          name={darkLogoKey}
          url={darkLogoUrl}
        />
      </Stack>
    </Stack>
  );
}
