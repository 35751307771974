import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Employee } from 'src/features/employees/model';

import ChipSelectionMenu from 'src/ui-components/custom/selection-menu';
import { usePermissionRoleCountQuery, useRolesQuery } from 'src/store/api/roles.api';

interface Props {
  employeeDraft: Employee;
  readOnly: boolean;
  handleUpdateEmployee: (val: Partial<Employee>) => void;
}

export default function Roles({ employeeDraft, readOnly, handleUpdateEmployee }: Props) {
  const { t } = useTranslation();
  const { data } = useRolesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { data: permissionRoleData } = usePermissionRoleCountQuery();

  const rolesSelected = employeeDraft.roles.map((obj) => obj.id);
  const roles = useMemo(
    () =>
      data
        ? data.map((role) => {
            const rolesPermissionCount = permissionRoleData?.find(
              (item) => item.id === role.id
            )?.permissionsCount;
            const permissionsCountLabel = (
              <Typography variant="body2" fontWeight="medium" sx={{ pl: '8px' }}>
                {t('employeePage.roles.permissionCount', {
                  count: rolesPermissionCount || 0,
                })}
              </Typography>
            );
            return {
              id: role.id,
              label: `${role.department.name} - ${role.name}`,
              additionalLabel: permissionsCountLabel,
            };
          })
        : [],
    [data, permissionRoleData, t]
  );
  const handleOnChange = (val: string[]) => {
    const changedRoles = data?.filter((role) => val.includes(role.id));
    handleUpdateEmployee({ roles: changedRoles });
  };

  if (!roles.length) return null;

  return (
    <Box>
      <ChipSelectionMenu
        dataCy="employee-roles"
        options={roles}
        disabled={readOnly}
        selected={rolesSelected}
        onChange={handleOnChange}
      />
    </Box>
  );
}
