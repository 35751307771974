import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ListItemIcon, Stack, SvgIcon } from '@mui/material';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import SecondarySidebar from 'src/ui-components/layout/secondary-sidebar/SecondarySidebar';
import {
  WorkbaseCreditcardIcon,
  WorkbaseDomainIcon,
  WorkbaseMotivationIcon,
  WorkbaseDepartmentsIcon,
  WorkbaseCloudKeyIcon,
} from 'src/assets/icons/workbaseIcons';
import PortalComponent from 'src/lib/components/util/PortalComponent';
import { useScreenWidth } from 'src/lib/hooks/use-screen-width';
import { PermissionCodes } from 'src/lib/enums/permissions';
import { useUserPermissionsQuery } from 'src/store/api/userPermissionsApi';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import { SecondarySidebarItem } from 'src/ui-components/layout/secondary-sidebar/types';
import SiteInfo from '../site-info/view/SiteInfo';
import EmployeeRecommendations from '../employee-recommendations';
import Departments from '../departments';
import { useRouteChecker } from './useRouteChecker';
import Billing, { handleOpenBillingSettingsPopup } from '../billing/view/Billing';
import { ApiKeys } from '../api-keys';

export default function CompanySettings() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const screenWidth = useScreenWidth();
  const { data: currentUserData, isSuccess: isCurrentUserDataSuccess } = useCurrentUserQuery();
  const { data: userPermissions, isSuccess: isPermissionsSuccess } = useUserPermissionsQuery(
    undefined,
    { refetchOnMountOrArgChange: true }
  );
  const hasManageDepartmentsPermission = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.MANAGE_DEPARTMENTS
  );
  const hasManageAllCompanySettingsPermission = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.MANAGE_ALL_COMPANY_SETTINGS
  );
  const hasManageBillingPermission = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.MANAGE_BILLING
  );

  const memoizedGroups = useMemo(() => {
    const groupsData: SecondarySidebarItem[] = [
      {
        key: 'site-information',
        text: t('companySettings.siteInformation.header'),
        subtext: t('companySettings.siteInformation.subText'),
        prefix: (
          <ListItemIcon>
            <SvgIcon component={WorkbaseDomainIcon} />
          </ListItemIcon>
        ),
        onClick: () => navigate('site-information', { relative: 'route' }),
        hide: isPermissionsSuccess ? !hasManageAllCompanySettingsPermission : true,
      },
      {
        key: 'employee-recommendations',
        text: t('companySettings.employeeRecommendations.header'),
        subtext: t('companySettings.employeeRecommendations.subText'),
        prefix: (
          <ListItemIcon>
            <SvgIcon component={WorkbaseMotivationIcon} />
          </ListItemIcon>
        ),
        onClick: () => navigate('employee-recommendations', { relative: 'route' }),
        hide: isPermissionsSuccess ? !hasManageAllCompanySettingsPermission : true,
      },
      {
        key: 'departments',
        text: t('companySettings.sidebar.departments.header'),
        subtext: t('companySettings.sidebar.departments.subText'),
        prefix: (
          <ListItemIcon>
            <SvgIcon component={WorkbaseDepartmentsIcon} />
          </ListItemIcon>
        ),
        onClick: () => navigate('departments', { relative: 'route' }),
        hide: isPermissionsSuccess ? !hasManageDepartmentsPermission : true,
      },
      {
        key: 'api-keys',
        text: t('companySettings.apiKeys.header'),
        subtext: t('companySettings.apiKeys.subText'),
        prefix: (
          <ListItemIcon>
            <SvgIcon component={WorkbaseCloudKeyIcon} />
          </ListItemIcon>
        ),
        onClick: () => navigate('api-keys', { relative: 'route' }),
        hide: isCurrentUserDataSuccess ? !currentUserData.isOwner : true,
      },
      {
        key: 'billing',
        text: t('companySettings.sidebar.billing.header'),
        subtext: t('companySettings.sidebar.billing.subText'),
        prefix: (
          <ListItemIcon>
            <SvgIcon component={WorkbaseCreditcardIcon} />
          </ListItemIcon>
        ),
        onClick: () => handleOpenBillingSettingsPopup(),
        hide: isPermissionsSuccess ? !hasManageBillingPermission : true,
      },
    ];

    return groupsData;
  }, [
    hasManageDepartmentsPermission,
    hasManageAllCompanySettingsPermission,
    hasManageBillingPermission,
    isPermissionsSuccess,
    isCurrentUserDataSuccess,
    currentUserData?.isOwner,
    navigate,
    t,
  ]);

  useRouteChecker(memoizedGroups, isPermissionsSuccess);

  const pathArray = pathname.split('/');
  const activeKey = pathArray[pathArray.length - 1];

  return (
    <Stack direction="row" sx={{ height: '100%' }}>
      <PortalComponent elementSelector="#main-layout-content-card">
        <SecondarySidebar activeKey={activeKey} items={memoizedGroups} dataCy="settings-sidebar" />
      </PortalComponent>
      <Box
        sx={{
          pt: 2,
          paddingLeft: screenWidth > 1520 ? '460px' : '360px',
          pr: 6,
          pb: 0,
          flexGrow: 1,
          position: 'relative',
          overflowY: 'auto',
        }}
      >
        <Routes>
          <Route
            path="site-information"
            element={hasManageAllCompanySettingsPermission && <SiteInfo />}
          />
          <Route path="employee-recommendations" element={<EmployeeRecommendations />} />
          <Route path="departments" element={<Departments />} />
          <Route path="api-keys" element={<ApiKeys />} />
          <Route path="billing" element={<Billing />} />
          <Route path="*" element={<Navigate to="/settings/site-information" replace />} />
        </Routes>
      </Box>
    </Stack>
  );
}
