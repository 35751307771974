import { Box, SxProps, Typography } from '@mui/material';
import { IconName } from '../../atoms/icon/WorkbaseIcon';
import WorkbaseIconOrEmoji from '../../atoms/icon/WorkbaseIconOrEmoji';

export interface TextWithIconProps {
  icon: IconName;
  title: string;
  color?: string;
  sx?: SxProps;
}

export default function TextWithIcon({ title, icon, color, sx }: TextWithIconProps) {
  return (
    <Box display="flex" alignItems="center" gap={2} sx={{ maxWidth: '100%', ...sx }}>
      <WorkbaseIconOrEmoji content={icon} size={40} color={color} />
      <Typography variant="h6" color={color} noWrap>
        {title}
      </Typography>
    </Box>
  );
}
