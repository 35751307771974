export const WORKBASE = {
  favicon: {
    icon: '/logo/logo_short.svg',
    alt: 'Workbase',
  },
  logo: {
    icon: '/logo/logo_long.svg',
    alt: 'Workbase',
  },
};
