import { Alert, Box, Button, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTrainingModuleQuery } from 'src/store/api/trainings/trainingModulesApi';
import { useCallback, useEffect, useState } from 'react';
import useTrainingPermissions from 'src/lib/hooks/useTrainingPermissions';
import useScrollOnDrag from 'src/lib/hooks/use-scroll-on-drag';
import ModuleHeader from './module-header/ModuleHeader';
import ModuleContent from './ModuleContent';
import ModuleOverviewTopBar from './ModuleOverviewTopBar';
import ModuleOverviewBreadcrumbs from './ModuleOverviewBreadcrumbs';

interface Props {
  hideTopbar?: boolean;
  editMode?: boolean;
  onAddLessonCallback?: (id: string) => void;
}

export default function ModuleOverview({
  hideTopbar = false,
  editMode = false,
  onAddLessonCallback,
}: Props) {
  const { t } = useTranslation();
  const param = useParams();
  if (param.moduleId === undefined) throw new Error('no module Id');
  const { data, isLoading, error, refetch, isSuccess } = useTrainingModuleQuery(param.moduleId);
  const [isScrolled, setIsScrolled] = useState(false);
  const scrollAreaRef = useScrollOnDrag();
  const [mobileView, setMobileView] = useState<boolean>();

  const { hasEditingPermissions } = useTrainingPermissions();

  const handleScroll = useCallback(() => {
    if (scrollAreaRef.current) {
      const isContentScrolled = scrollAreaRef.current.scrollTop > 0;
      setIsScrolled(isContentScrolled);
    }
  }, [scrollAreaRef]);

  useEffect(() => {
    if (!isSuccess) return undefined;
    function handleResize() {
      if (scrollAreaRef.current && scrollAreaRef.current.clientWidth < 800) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isSuccess, scrollAreaRef]);

  if (isLoading) return <CircularProgress sx={{ position: 'absolute', left: '50%', top: '50%' }} />;
  if (error) {
    return (
      <Alert
        severity="error"
        action={
          <Button onClick={refetch} size="small">
            {t('validationErrorMessages.labelErrorTryAgainButton')}
          </Button>
        }
      >
        {t('modulePage.moduleErrorDescription')}
      </Alert>
    );
  }

  if (!data) return null;

  const paddingWhenEditPath = mobileView ? '84px' : '70px';

  return (
    <Box
      ref={scrollAreaRef}
      onScroll={handleScroll}
      sx={{
        width: '100%',
        padding: '20px',
        maxHeight: '100%',
        height: mobileView ? '100%' : 'auto',
        paddingTop: editMode && !hideTopbar ? paddingWhenEditPath : '20px',
        paddingBottom: mobileView ? 0 : '20px',
        overflow: mobileView ? 'visible' : 'auto',
        visibility: mobileView === undefined ? 'hidden' : 'visible',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {editMode && hasEditingPermissions && !hideTopbar && (
        <ModuleOverviewTopBar
          lessonsCount={data.lessonsCount}
          sectionsCount={data.sectionsCount}
          scrolled={isScrolled}
          moduleId={param.moduleId}
          trainingId={data.trainingId}
        />
      )}
      <ModuleOverviewBreadcrumbs
        moduleName={data.name}
        moduleId={data.id}
        trainingId={data.trainingId}
      />
      <ModuleHeader data={data} readOnly={!editMode} mobileView={!!mobileView} />
      <ModuleContent
        moduleId={param.moduleId}
        mobileView={!!mobileView}
        readOnly={!editMode}
        onAddLessonCallback={onAddLessonCallback}
      />
    </Box>
  );
}
