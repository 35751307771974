import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { localStorageGetItem } from '../lib/utils/storage-available';
import { defaultLang } from './config-lang';
import translationEn from './langs/en.json';
import translationDe from './langs/de.json';
import workbaseEmojisDe from './langs/emojis/workbaseEmojis-de.json';
import workbaseEmojisEn from './langs/emojis/workbaseEmojis-en.json';
import { LocalStorageKey } from '../lib/enums/local-storage';

const lng = localStorageGetItem(LocalStorageKey.I18_NEXT_LNG, defaultLang.value);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: { ...translationEn, ...workbaseEmojisEn } },
      de: { translations: { ...translationDe, ...workbaseEmojisDe } },
    },
    lng,
    fallbackLng: lng,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
