import { Box } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import WorkbaseAvatar from 'src/lib/components/atoms/avatar/Avatar';
import DashboardHeader from 'src/lib/components/organisms/editor/dashboard-header';
import { useAppSelector } from 'src/store';
import { PermissionCodes } from 'src/lib/enums/permissions';
import { useUserPermissionsQuery } from 'src/store/api/userPermissionsApi';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import EditorWithModeSwitch from 'src/features/editor/view/EditorWithModeSwitch';
import { useNewsfeedQuery } from 'src/store/api/newsfeedApi';
import { setSelectedRole, useDashboardQuery } from '../controller';
import NewsfeedSlider from './neewsfeed/NewsfeedSlider';
import RoleSelectorWithLinks from './RoleSelectorWithLinks';
import { setSelectedNewsfeedItemIdx } from '../controller/slice';

// eslint-disable-next-line max-statements
export default function Dashboard() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { data } = useCurrentUserQuery();
  const {
    data: dashboardData,
    isSuccess,
    isLoading,
  } = useDashboardQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { data: newsfeedData, isLoading: isLoadingNewsfeed } = useNewsfeedQuery();
  const { firstName, avatarUrl } = data || {};
  const selectedRole = useAppSelector((state) => state.dashboard.selectedRole);
  const links = useAppSelector((state) => state.editor.editorData?.links);
  const { data: userPermissions } = useUserPermissionsQuery();
  const hasAdminAccessToDashboardsPages = userPermissions?.some(
    (permission) => permission.code === PermissionCodes.ADMIN_ACCESS_DASHBOARD_AND_PAGES
  );
  const hasEditingPermissions = data?.isOwner || hasAdminAccessToDashboardsPages;

  useEffect(() => {
    if (selectedRole === undefined && dashboardData?.roles && dashboardData?.roles.length > 0) {
      dispatch(setSelectedRole(dashboardData.roles[0].id));
    }
  }, [selectedRole, dashboardData?.roles, dispatch]);

  useEffect(() => {
    dispatch(setSelectedNewsfeedItemIdx(0));
  }, [dispatch]);

  useEffect(() => {
    if (dashboardData?.roles && dashboardData.roles.length > 0) {
      const roleExists = dashboardData.roles.some((role) => role.id === selectedRole);

      if (selectedRole === undefined || !roleExists) {
        dispatch(setSelectedRole(dashboardData.roles[0].id));
      }
    }
  }, [selectedRole, dashboardData?.roles, dispatch]);

  const renderHeader = useCallback(
    (readOnly: boolean) => (
      <Box sx={{ padding: '20px', paddingTop: 0, height: '100%', position: 'relative' }}>
        <DashboardHeader
          firstName={firstName || ''}
          avatarUrl={avatarUrl || WorkbaseAvatar.toString()}
          bottomContent={
            !(
              (readOnly &&
                dashboardData &&
                dashboardData.roles.length <= 1 &&
                links &&
                links?.length === 0) ||
              isLoading
            ) && (
              <RoleSelectorWithLinks
                roles={dashboardData?.roles || []}
                links={links || []}
                isEditing={!readOnly}
              />
            )
          }
          content={
            isLoadingNewsfeed ? (
              <div />
            ) : (
              newsfeedData && newsfeedData.length !== 0 && <NewsfeedSlider items={newsfeedData} />
            )
          }
        />
      </Box>
    ),
    [newsfeedData, firstName, avatarUrl, dashboardData, links, isLoading, isLoadingNewsfeed]
  );

  if (!selectedRole || !isSuccess) return null;

  if (!['/', '/edit'].includes(location.pathname)) {
    return <Navigate to="/" replace />;
  }

  return (
    <EditorWithModeSwitch
      canEdit={!!hasEditingPermissions}
      canView
      renderHeader={renderHeader}
      documentName={`role.${selectedRole}`}
    />
  );
}
