import { Avatar, Box, SxProps } from '@mui/material';
import { WorkbaseEmployeeIcon } from 'src/assets/icons/workbaseIcons';
import { Theme } from '@mui/material/styles';
import { WorkbaseIcon } from '../icon/WorkbaseIcon';

interface WorkbaseAvatarProps {
  src?: string;
  alt?: string;
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
  dataCy?: string;
}

export default function WorkbaseAvatar({ alt, src, sx, children, dataCy }: WorkbaseAvatarProps) {
  return (
    <Avatar
      sx={{
        backgroundColor: 'grey.300',
        color: 'text.primary',
        ...sx,
      }}
      alt={alt}
      src={src}
      data-cy={dataCy}
    >
      <Box sx={{ display: 'flex' }}>
        <WorkbaseIcon icon={WorkbaseEmployeeIcon} color="inherit" sx={{ width: 16, height: 16 }} />
      </Box>
      {children}
    </Avatar>
  );
}
