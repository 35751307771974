import { Stack, styled } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch } from 'react-redux';
import { useEditor } from 'src/features/editor/controller';
import TextFieldWithIconSelector from 'src/lib/components/molecules/text-field-with-icon-selector';
import TextWithIcon from 'src/lib/components/molecules/text-with-icon';
import { useAppSelector } from 'src/store';
import debounce from 'lodash.debounce';
import { AnyAction } from '@reduxjs/toolkit';
import { CustomSiteHeader } from 'src/features/editor/model';
import { wikiApi, WikiCardType } from '../../../../store/api/wikiApi.slice';
import { setSmallestIndexForce } from '../../controller/wikiCards.slice';

const CardHeaderContainer = styled(Stack)`
  padding: 0 32px;
  width: 100%;
`;

const HeaderWrapper = styled(Stack)`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  border-radius: ${({ theme }) => theme.shape.borderRadius * 4};
  height: 170px;
  justify-content: center;
  align-items: center;
  min-width: 0;
`;

export default function WikiCardHeader({
  readOnly = false,
  id,
  readOnlyHeader,
  index,
}: {
  readOnlyHeader?: CustomSiteHeader;
  readOnly?: boolean;
  id?: string;
  index?: number;
}) {
  const { t } = useTranslation();
  const editorHeader = useAppSelector((state) => state.editor.editorData?.header, shallowEqual);
  const header = readOnlyHeader ?? editorHeader;
  const headerRef = useRef<HTMLDivElement>(null);
  const { handleChangeHeaderName, handleIconChange } = useEditor();
  const [name, setName] = useState(header?.name ?? '');
  const dispatch = useDispatch();
  useEffect(() => {
    if (header?.name !== undefined) {
      setName(header?.name);
    }
  }, [header?.name]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeDebounced = useCallback(
    debounce((val: string) => {
      handleChangeHeaderName(val);
      dispatch(
        wikiApi.util.updateQueryData(
          'fetchWikiCardsSidebar',
          undefined,
          (draft: WikiCardType[]) => {
            const cardToUpdate = draft?.find((item) => item.id === id);
            if (cardToUpdate) {
              cardToUpdate.name = val;
            }
          }
        ) as unknown as AnyAction
      );
    }, 300),
    [handleChangeHeaderName, dispatch]
  );

  const changeIcon = (icon: string) => {
    handleIconChange(icon);
    dispatch(
      wikiApi.util.updateQueryData('fetchWikiCardsSidebar', undefined, (draft) => {
        const cardToUpdate = draft?.find((item) => item.id === id);
        if (cardToUpdate) {
          cardToUpdate.icon = icon;
        }
      }) as unknown as AnyAction
    );
  };
  const scrollToTop = () => {
    if (headerRef?.current) {
      dispatch(setSmallestIndexForce(index));
      headerRef.current.scrollIntoView({ behavior: 'instant', block: 'start' });
    }
  };

  const headerIcon = header?.icon || 'WorkbaseWikiIcon';

  if (!header) return null;

  return (
    <CardHeaderContainer ref={headerRef} onClick={readOnly ? scrollToTop : undefined}>
      <HeaderWrapper>
        {readOnly ? (
          <TextWithIcon
            sx={{ position: 'relative', maxWidth: '80%' }}
            color="common.white"
            icon={headerIcon}
            title={name || t('wikiPage.unnamedWikiCard')}
          />
        ) : (
          <TextFieldWithIconSelector
            value={name}
            autoFocus
            autoComplete="off"
            placeholder={`${t('wikiPage.unnamedWikiCard')}...`}
            onChange={(e) => {
              setName(e.target.value);
              handleChangeDebounced(e.target.value);
            }}
            backgroundColor={header?.darkMode ? 'tertiary.main' : 'white'}
            changeableIconProps={{
              value: headerIcon,
              onChange: changeIcon,
            }}
          />
        )}
      </HeaderWrapper>
    </CardHeaderContainer>
  );
}
