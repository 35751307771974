import { useRef } from 'react';
import { Button, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Department } from '../../../model/types';
import { addRole } from '../../../controller/departments.slice';
import RolesList from '../../roles/RolesList';

interface DepartmentsCollapsibleContentProps {
  department: Department;
}

export default function DepartmentContent({ department }: DepartmentsCollapsibleContentProps) {
  const inputRoleRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleAddNewRole = () => {
    dispatch(addRole(department.id));

    requestAnimationFrame(() => {
      if (inputRoleRef.current) {
        inputRoleRef.current.focus();
      }
    });
  };

  return (
    <Stack gap={0.5}>
      <RolesList department={department} inputRoleRef={inputRoleRef} />
      <Button color="inherit" onClick={handleAddNewRole}>
        {t('companySettings.sidebar.departments.roleAddButton')}
      </Button>
    </Stack>
  );
}
