import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import EditViewProtectedWrapper from 'src/core/EditViewProtectedWrapper';
import ModeSwitch from 'src/core/ModeSwitch';
import Lesson from 'src/features/lesson/view/Lesson';
import { useShareLevelQuery } from 'src/store/api/shareResourceApi';
import { useTrainingLessonQuery } from 'src/store/api/trainings/trainingLessonsApi';
import { ShareLevel } from 'src/lib/types/share-level';

export default function LessonView({ smallVersion }: { smallVersion?: boolean }) {
  const param = useParams();
  const { data: shareLevelData, isSuccess } = useShareLevelQuery({
    resourceType: 'training',
    resourceId: param.trainingId as string,
  });
  const { data } = useTrainingLessonQuery(param.lessonId as string);

  const canEdit =
    shareLevelData?.shareLevel === ShareLevel.edit ||
    shareLevelData?.shareLevel === ShareLevel.manage;
  if (!isSuccess) return null;
  return (
    <EditViewProtectedWrapper
      canEdit={canEdit}
      canView={shareLevelData.shareLevel !== ShareLevel.none}
    >
      <ModeSwitch
        switchButtonVisible={canEdit}
        switchButtonAdditionalStyles={(editMode) =>
          !editMode && data?.status === 'published' && !data.done
            ? {
                bottom: 88,
                right: 12,
              }
            : {
                right: 12,
                bottom: 10,
              }
        }
      >
        {(editMode) => (
          <Stack
            sx={{
              height: '100%',
              paddingRight: '5px',
            }}
          >
            <Lesson
              editMode={editMode}
              showGoBackToTrainingButton={(!editMode || !canEdit) && smallVersion}
              showBreadcrumbs={smallVersion}
            />
          </Stack>
        )}
      </ModeSwitch>
    </EditViewProtectedWrapper>
  );
}
