import { Typography, Stack, Button, SvgIcon, TextField } from '@mui/material';
import { WorkbaseResetIcon } from 'src/assets/icons/workbaseIcons';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useRef } from 'react';
import debounce from 'lodash.debounce';
import { usePatchSettingsMutation } from 'src/store/api/settingsApi';

interface Props {
  value: string;
}

const defaultVal = '';

export default function EmployeeRecommendationTextarea({ value }: Props) {
  const { t } = useTranslation();
  const [patchSettings] = usePatchSettingsMutation();
  const ref = useRef<HTMLInputElement>(null);

  const debouncedFunction = useMemo(
    () =>
      debounce((val) => {
        patchSettings({
          recommendationNotificationPreferences: {
            descriptionText: val,
          },
        });
      }, 800),
    [patchSettings]
  );

  const debouncedOnChange = useCallback(
    (val: string) => {
      if (value !== val) {
        debouncedFunction(val);
      }
    },
    [value, debouncedFunction]
  );

  const resetDefaultButtonDisabled = value === defaultVal;

  const handleResetDefaultButtonClick = () => {
    patchSettings({
      recommendationNotificationPreferences: {
        descriptionText: defaultVal,
      },
    }).then((resp: any) => {
      if (!resp.error && ref.current) {
        ref.current.value = defaultVal;
      }
    });
  };

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography color="text.primary" variant="subtitle1" fontWeight="medium">
          {t('companySettings.employeeRecommendations.resettableTextarea.label')}
        </Typography>
        <Stack justifyContent="right">
          <Button
            data-cy="reset-button"
            variant="text"
            startIcon={<SvgIcon fontSize="inherit" component={WorkbaseResetIcon} />}
            disabled={resetDefaultButtonDisabled}
            onClick={handleResetDefaultButtonClick}
          >
            {t('resettableTextArea.resetDefaultLabel')}
          </Button>
        </Stack>
      </Stack>
      <TextField
        inputRef={ref}
        defaultValue={value}
        placeholder={t('companySettings.employeeRecommendations.resettableTextarea.placeholder')}
        multiline
        data-cy="employee-recommendations-resettable-textarea"
        minRows={7}
        maxRows={7}
        onChange={(e) => {
          debouncedOnChange?.(e.target.value);
        }}
        helperText={t('companySettings.employeeRecommendations.resettableTextarea.helperText')}
      />
    </Stack>
  );
}
