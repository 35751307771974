import { t } from 'i18next';
import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { WorkbaseErrorIcon } from 'src/assets/icons/workbaseIcons';
import { Typography } from '@mui/material';

import WorkbaseDialogComponent from 'src/lib/components/molecules/dialog/Dialog';
import { FileType } from 'src/lib/components/organisms/editor/edit-bar/UploadActions';
import { useAppSelector } from 'src/store';

export default function BlockerComponent() {
  const uploadingMedia = useAppSelector((state) => state.editor.uploadingMedia);

  const mediaType = uploadingMedia.map((el) => el.mediaType)[uploadingMedia.length - 1];

  const mediaDict: Record<FileType, string> = {
    image: t('editor.uploadMediaModal.image'),
    video: t('editor.uploadMediaModal.video'),
    file: t('editor.uploadMediaModal.file'),
  };
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && uploadingMedia.length !== 0
  );

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      if (uploadingMedia?.length !== 0) {
        e.returnValue = 'val';
        return 'val';
      }
      return undefined;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [uploadingMedia]);

  return (
    <WorkbaseDialogComponent
      title={t('editor.uploadMediaModal.title')}
      open={blocker.state === 'blocked' && !!mediaType}
      onClose={() => blocker.reset?.()}
      titleIcon={{ icon: WorkbaseErrorIcon, color: 'error.main' }}
      confirmButton={{
        text: t('proceed'),
        onClick: () => {
          blocker.proceed?.();
        },
        type: 'error',
      }}
    >
      <Typography variant="body1">
        {t('editor.uploadMediaModal.content', { media: mediaDict[mediaType] })}
      </Typography>
    </WorkbaseDialogComponent>
  );
}
