import { generateJitteredKeyBetween } from 'fractional-indexing-jittered';
import { usePatchSidebarMutation } from 'src/store/api/sidebarApi';
import { HierarchicalTreeProps } from 'src/ui-components/custom/hierarchical-tree/Tree';

export default function useOnDropTree() {
  const [patchSidebar] = usePatchSidebarMutation();
  const onDrop: HierarchicalTreeProps['onDrop'] = (treeData, options) => {
    const parentNodeId = options.dropTargetId;

    const siblings = treeData.filter((node) => node.parent === parentNodeId);

    const dropIndex = siblings.findIndex((node) => node.id === options.dragSourceId);

    const aboveOrder = dropIndex > 0 ? (siblings[dropIndex - 1].data?.position as string) : null;
    const belowOrder =
      dropIndex < siblings.length - 1 ? (siblings[dropIndex + 1].data?.position as string) : null;
    const newPosition = generateJitteredKeyBetween(aboveOrder, belowOrder);
    patchSidebar({
      id: options.dragSourceId as string,
      parentId: (parentNodeId as string) || null,
      position: newPosition,
    });
  };
  return onDrop;
}
