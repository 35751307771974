import { Stack } from '@mui/material';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { WorkbaseAddSquareIcon } from 'src/assets/icons/workbaseIcons';
import { showNotification } from 'src/lib/components/atoms/notification';
import SecondarySidebar from 'src/ui-components/layout/secondary-sidebar/SecondarySidebar';
import {
  useUpdateCardsOrderMutation,
  useCreateWikiCardMutation,
} from 'src/store/api/wikiApi.slice';
import { generateKeyBetween } from 'fractional-indexing-jittered';
import { PositionedSecondarySidebarItem } from 'src/ui-components/layout/secondary-sidebar/types';
import { setSmallestIndexForce, setWasDragged } from '../controller/wikiCards.slice';

interface WikiSidebarProps {
  data: PositionedSecondarySidebarItem[];
  hasAddPermission?: boolean;
  activeKey?: string;
  hideSidebar: boolean;
  editMode?: boolean;
}

export default function WikiSidebar({
  data,
  hasAddPermission,
  hideSidebar,
  activeKey,
  editMode,
}: WikiSidebarProps) {
  const [updateOrder] = useUpdateCardsOrderMutation();
  const [createWikiCard] = useCreateWikiCardMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCreateCard = () => {
    createWikiCard().then((resp: any) => {
      if (!resp.error) {
        navigate(`/wiki/${resp.data.id}/edit`, { replace: true });
        dispatch(setSmallestIndexForce(data.length));
      } else {
        showNotification(t('notifications.addingError'), 'error');
      }
    });
  };

  const handleUpdateOrder = ({
    id,
    order,
    source,
  }: {
    id: string;
    order: number;
    source: number;
  }) => {
    dispatch(setWasDragged(true));
    const prev = data[order - 1]?.position ?? null;
    const next = data[order]?.position ?? null;
    const pos = generateKeyBetween(prev, next);
    updateOrder({ id, position: pos }).then((resp: any) => {
      if (resp.error) {
        dispatch(setSmallestIndexForce(source));
      }
    });
  };
  const withDragAndDrop = hasAddPermission && editMode;
  if (hideSidebar) return null;
  return (
    <Stack position="absolute" zIndex={2} height="100%">
      <SecondarySidebar
        noDivider
        withDragAndDrop={withDragAndDrop}
        reorderFunction={handleUpdateOrder}
        items={data}
        dataCy="wiki-sidebar"
        disableSearchBar={!data?.length}
        activeKey={activeKey as string}
        headerIcon={
          hasAddPermission
            ? {
                icon: WorkbaseAddSquareIcon,
                text: t('wikiPage.headerAddButton'),
                onClick: handleCreateCard,
              }
            : undefined
        }
      />
    </Stack>
  );
}
