import { useNavigate } from 'react-router-dom';
import {
  WorkbaseCheckmarkBigFilledIcon,
  WorkbaseCloseCircleIcon,
  WorkbaseEditIcon,
} from 'src/assets/icons/workbaseIcons';
import { Button, Stack, alpha, keyframes, useTheme } from '@mui/material';
import { WorkbaseIcon } from '../../atoms/icon/WorkbaseIcon';

export interface WorkbaseEditButtonProps {
  path?: string;
  text: string;
  editMode?: boolean;
  type?: ButtonType;
  onClick?: () => void;
  additionalEditButtonStyle?: object;
}

export type ButtonType = 'edit' | 'finish' | 'save' | 'cancel';

export const startIconColorStyles = {
  edit: 'text.secondary',
  finish: 'text.secondary',
  save: 'success.main',
  cancel: 'error.main',
};

export type IconColor = keyof typeof startIconColorStyles;

export const iconDic = {
  edit: WorkbaseCheckmarkBigFilledIcon,
  finish: WorkbaseEditIcon,
  save: WorkbaseCheckmarkBigFilledIcon,
  cancel: WorkbaseCloseCircleIcon,
};
export type IconType = keyof typeof iconDic;

const slideInFromBottom = keyframes`
  0% {
    bottom: -30px;
  }
  100% {
    bottom: 20px;
  }
`;

function WorkbaseEditButton({
  type = 'edit',
  path,
  text,
  editMode,
  onClick,
  additionalEditButtonStyle,
}: WorkbaseEditButtonProps) {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const typesStyles = {
    edit: {
      backgroundColor: `${alpha(palette.background.default, 0.75)} !important`,
      boxShadow: `0px 0px 5px 0px ${alpha(palette.common.black, 0.01)}`,
      '&:hover': {
        backgroundColor: `${palette.primary.light} !important`,
      },
      color: 'text.secondary',
    },
    finish: {
      backgroundColor: `${alpha(palette.background.default, 0.75)} !important`,
      boxShadow: `0px 0px 5px 0px ${alpha(palette.common.black, 0.01)}`,
      '&:hover': {
        backgroundColor: `${palette.primary.light} !important`,
      },
      color: 'text.secondary',
    },
    save: {
      backgroundColor: 'green.light',
      boxShadow: `0px 0px 5px 0px ${alpha(palette.common.black, 0.05)}`,
      backdropFilter: 'blur(20px) saturate(20%)',
      position: 'relative',
      overflow: 'hidden',
      '&:hover': {
        backgroundColor: 'green.light',
        '&:after': {
          content: '""',
          backgroundColor: `${alpha(palette.success.main, 0.1)} !important`,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        },
        boxShadow: `0px 0px 5px 0px ${alpha(palette.common.black, 0.05)}`,
      },
      color: 'success.main',
    },
    cancel: {
      backgroundColor: 'red.light',
      boxShadow: `0px 0px 5px 0px ${alpha(palette.common.black, 0.05)}`,
      backdropFilter: 'blur(20px) saturate(20%)',
      position: 'relative',
      overflow: 'hidden',
      '&:hover': {
        backgroundColor: 'red.light',
        '&:after': {
          content: '""',
          backgroundColor: `${alpha(palette.error.main, 0.1)} !important`,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        },
        boxShadow: `0px 0px 5px 0px ${alpha(palette.common.black, 0.05)}`,
      },
      color: 'error.main',
    },
  };

  let iconComponent;
  if (editMode === true) {
    iconComponent = iconDic.edit;
  } else if (editMode === false) {
    iconComponent = iconDic.finish;
  } else if (type in iconDic) {
    iconComponent = iconDic[type];
  }

  const handleClick = () => {
    if (path) {
      navigate(path);
    }
    onClick?.();
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        position: 'absolute',
        bottom: '12px',
        right: '12px',
        animation: `${slideInFromBottom} 500ms ease-out 0s 1`,
        transition: 'all 500ms',
        zIndex: 3,
        '&:hover': {
          transform: 'translateY(-6px)',
        },
        ...additionalEditButtonStyle,
      }}
    >
      <Button
        data-cy={`${text}-button`}
        color="secondary"
        sx={{
          ...typesStyles[type],
          padding: '8px 20px 12px 20px',
          borderRadius: (theme) => theme.shape.borderRadius * 2.5,
          lineHeight: '1.25',
          width: '140px',
          '.MuiButton-startIcon': {
            marginRight: '6px',
            marginLeft: '0px',
          },
        }}
        variant="contained"
        onClick={handleClick}
        startIcon={
          iconComponent && (
            <WorkbaseIcon
              sx={{ width: 16, height: 16 }}
              icon={iconComponent}
              color={startIconColorStyles[type]}
            />
          )
        }
      >
        {text}
      </Button>
    </Stack>
  );
}

export default WorkbaseEditButton;
