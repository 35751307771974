import { Box, Card, Stack, Typography } from '@mui/material';
import WorkbaseImage from 'src/lib/components/atoms/image/Image';
import { WorkbaseCircularProgress } from 'src/lib/components/atoms/progress/CircularProgress';
import {
  Training,
  useTrainingEstimatedTimeQuery,
  useTrainingLessonsCountQuery,
  useTrainingProgressQuery,
} from 'src/store/api/trainings/trainingsApi';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import BrandedDefaultImage from 'src/lib/components/atoms/branded-default-image/BrandedDefaultImage';

export default function SingleTraining({ name, imageUrl, hasAccess, id, defaultImage }: Training) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: estimatedTimeData } = useTrainingEstimatedTimeQuery();
  const { data: lessonsData } = useTrainingLessonsCountQuery();
  const { data: progressData } = useTrainingProgressQuery();
  const { pathname } = useLocation();

  const lessonsCount = lessonsData?.find((item) => item.id === id)?.lessonsCount;
  const estimatedTime = estimatedTimeData?.find((item) => item.id === id)?.estimatedTimeToComplete;
  const progress = progressData?.find((item) => item.id === id)?.progress;

  const lessonsCountLabel = t('lesson', { count: lessonsCount || 0 });
  const estimatedTimeLabel = t('minute', { count: Number(estimatedTime) || 0 });

  const isEditing = pathname.includes('/edit');

  const handleClick = () => {
    navigate(`/trainings/${id}${isEditing ? '/edit' : ''}`, { relative: 'route' });
  };
  return (
    <Card
      onClick={handleClick}
      sx={{
        '&:hover img, &:hover .default-svg': hasAccess
          ? {
              transform: 'scale(1.2)',
            }
          : {},
        cursor: hasAccess ? 'pointer' : 'auto',
        borderRadius: (theme) => theme.shape.borderRadius * 2.5,
        borderWidth: 1,
        borderColor: 'grey.300',
        borderStyle: 'solid',
        overflow: 'hidden',
        boxShadow: 'none',
      }}
    >
      <Stack>
        <Box
          sx={{
            aspectRatio: '29/16',
            overflow: 'hidden',
            borderRadius: (theme) => theme.shape.borderRadius * 2.5,
            borderWidth: 1,
            borderColor: 'grey.300',
            borderStyle: 'solid',
            margin: '-1px',
          }}
        >
          {imageUrl && (
            <WorkbaseImage
              src={imageUrl || ''}
              alt=""
              style={{
                objectFit: 'cover',
                objectPosition: 'center',
                transition: '0.15s',
                width: '100%',
                height: '100%',
              }}
            />
          )}
          {!imageUrl && (
            <BrandedDefaultImage
              viewBox="222.5 225 555 555"
              className="default-svg"
              sx={{ transition: '0.15s' }}
              name={defaultImage}
            />
          )}
        </Box>
        <Stack
          direction="row"
          sx={{ padding: 2, paddingTop: '14px', justifyContent: 'space-between' }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack sx={{ minWidth: 0 }}>
            <Typography variant="h6" noWrap>
              {name || t('trainingDetailPage.unnamedTraining')}
            </Typography>
            <Typography variant="body2" fontWeight="bold" color="grey[500]">
              {lessonsCountLabel} – {estimatedTimeLabel}
            </Typography>
          </Stack>
          <WorkbaseCircularProgress value={progress || 0} size="big" />
        </Stack>
      </Stack>
    </Card>
  );
}
