import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/store';
import { shallowEqual } from 'react-redux';
import TextWithIcon from 'src/lib/components/molecules/text-with-icon';
import BaseHeader from '../base-header/BaseHeader';

interface Props {
  readOnly: boolean;
}

export default function TrainingsHeader({ readOnly }: Props) {
  const { t } = useTranslation();
  const header = useAppSelector((state) => state.editor.editorData?.trainingsHeader, shallowEqual);

  if (!header) return null;

  return (
    <BaseHeader readOnly={readOnly} header={header}>
      <TextWithIcon
        sx={{ position: 'relative', maxWidth: '80%' }}
        color={header.darkMode ? 'common.white' : 'text.primary'}
        icon="WorkbaseTrainingsIcon"
        title={t('sidebar.tabTitleTrainings')}
      />
    </BaseHeader>
  );
}
