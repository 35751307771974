import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../api';
import { trainingsApi } from './trainingsApi';
import { trainingModulesApi } from './trainingModulesApi';
import { employeesApi } from '../employees/employeesApi';

export interface TrainingSection {
  id: string;
  name: string;
  status: TrainingSectionStatus;
  trainingModuleId: string;
  icon: string;
  progress: number;
  lessonsCount: number;
  estimatedTimeToComplete: number;
}

export type TrainingSectionStatus = 'draft' | 'published';

export const trainingSectionsApi = createApi({
  reducerPath: 'trainingSectionsApi',
  baseQuery,
  tagTypes: ['TrainingSectionsByModuleAndUserId'],
  endpoints: (builder) => ({
    // queries
    trainingSectionsByModuleId: builder.query<TrainingSection[], string>({
      query: (moduleId) => ({
        url: `/training-modules/${moduleId}/sections`,
      }),
    }),
    trainingSectionsByModuleAndUserId: builder.query<
      TrainingSection[],
      { moduleId: string; userId: string }
    >({
      query: ({ moduleId, userId }) => ({
        url: `/training-modules/${moduleId}/sections?userId=${userId}`,
      }),
      providesTags: (_, __, { moduleId }) => [
        { type: 'TrainingSectionsByModuleAndUserId', id: moduleId },
      ],
    }),
    // mutations
    patchTrainingSection: builder.mutation<
      void,
      { trainingSection: Partial<TrainingSection>; trainingId: string; trainingModuleId: string }
    >({
      query: ({ trainingSection }) => ({
        url: `/training-sections/${trainingSection.id}`,
        method: 'PATCH',
        body: { ...trainingSection, id: undefined, trainingModuleId: undefined },
      }),
      invalidatesTags: (_, __, { trainingModuleId }) => [
        { type: 'TrainingSectionsByModuleAndUserId', id: trainingModuleId },
      ],
      onQueryStarted: ({ trainingSection, trainingId }, { dispatch, queryFulfilled }) => {
        const disp = dispatch(
          trainingSectionsApi.util.updateQueryData(
            'trainingSectionsByModuleId',
            trainingSection.trainingModuleId as string,
            (draft) =>
              draft.map((section) =>
                section.id === trainingSection.id ? { ...section, ...trainingSection } : section
              )
          )
        );
        queryFulfilled
          .then(() => {
            dispatch(
              trainingsApi.util.invalidateTags([
                'TrainingProgress',
                'TrainingsProgressByUserId',
                'TrainingsByUserId',
                { type: 'TrainingProgressById', id: trainingId },
              ])
            );
            dispatch(
              trainingModulesApi.util.invalidateTags([
                { type: 'TrainingModulesByTrainingId', id: trainingId },
                { type: 'TrainingModulesProgressByTrainingId', id: trainingId },
                { type: 'TrainingModulesProgressByTrainingAndUserId', id: trainingId },
                { type: 'TrainingModulesProgress', id: trainingSection.trainingModuleId },
              ])
            );
          })
          .catch(disp.undo);
      },
    }),
    duplicateTrainingSection: builder.mutation<
      TrainingSection,
      { moduleId: string; sectionId: string; trainingId?: string }
    >({
      query: ({ sectionId }) => ({
        url: `/training-sections/${sectionId}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: (_, __, { moduleId }) => [
        { type: 'TrainingSectionsByModuleAndUserId', id: moduleId },
      ],
      onQueryStarted: ({ moduleId, sectionId, trainingId }, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((response) => {
          dispatch(
            trainingSectionsApi.util.updateQueryData(
              'trainingSectionsByModuleId',
              moduleId,
              (draft) => {
                const sectionIdx = draft.findIndex((section) => section.id === sectionId);
                if (sectionIdx >= 0) {
                  draft.splice(sectionIdx + 1, 0, response.data);
                }
                return draft;
              }
            )
          );
          dispatch(
            trainingsApi.util.invalidateTags([
              'TrainingLessonsCount',
              'TrainingEstimatedTime',
              'TrainingProgress',
              'TrainingsProgressByUserId',
              'TrainingsByUserId',
              { type: 'TrainingLessonsCountById', id: trainingId },
              { type: 'TrainingEstimatedTimeById', id: trainingId },
              { type: 'TrainingProgressById', id: trainingId },
            ])
          );
          dispatch(
            trainingModulesApi.util.invalidateTags([
              'TrainingModuleLessonsCount',
              'TrainingModuleEstimatedTime',
              { type: 'TrainingModulesProgressByTrainingId', id: trainingId },
              { type: 'TrainingModulesProgressByTrainingAndUserId', id: trainingId },
              { type: 'TrainingModulesProgress', id: moduleId },
              { type: 'TrainingModulesByTrainingId', id: trainingId },
            ])
          );
          dispatch(employeesApi.util.invalidateTags(['EmployeesTrainingProgress']));
        });
      },
    }),
    deleteTrainingSection: builder.mutation<
      void,
      Pick<TrainingSection, 'id' | 'trainingModuleId'> & { trainingId?: string; moduleId?: string }
    >({
      query: ({ id }) => ({
        url: `/training-sections/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { moduleId }) => [
        { type: 'TrainingSectionsByModuleAndUserId', id: moduleId },
      ],
      onQueryStarted: (
        { id, trainingModuleId, trainingId, moduleId },
        { dispatch, queryFulfilled }
      ) => {
        const disp = dispatch(
          trainingSectionsApi.util.updateQueryData(
            'trainingSectionsByModuleId',
            trainingModuleId,
            (draft) => draft.filter((section) => section.id !== id)
          )
        );
        queryFulfilled
          .then(() => {
            dispatch(
              trainingsApi.util.invalidateTags([
                'TrainingLessonsCount',
                'TrainingEstimatedTime',
                'TrainingProgress',
                'TrainingsProgressByUserId',
                'TrainingsByUserId',
                { type: 'TrainingLessonsCountById', id: trainingId },
                { type: 'TrainingEstimatedTimeById', id: trainingId },
                { type: 'TrainingProgressById', id: trainingId },
              ])
            );
            dispatch(
              trainingModulesApi.util.invalidateTags([
                'TrainingModuleLessonsCount',
                'TrainingModuleEstimatedTime',
                { type: 'TrainingModulesProgressByTrainingId', id: trainingId },
                { type: 'TrainingModulesProgressByTrainingAndUserId', id: trainingId },
                { type: 'TrainingModulesProgress', id: moduleId },
                { type: 'TrainingModulesByTrainingId', id: trainingId },
              ])
            );
            dispatch(employeesApi.util.invalidateTags(['EmployeesTrainingProgress']));
          })
          .catch(disp.undo);
      },
    }),
    postTrainingSection: builder.mutation<
      TrainingSection,
      {
        name?: string;
        trainingModuleId: string;
        status?: TrainingSectionStatus;
        icon?: string;
      }
    >({
      query: (section) => ({
        url: `/training-sections`,
        method: 'POST',
        body: section,
      }),
      onQueryStarted: (section, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((response) => {
          dispatch(
            trainingSectionsApi.util.updateQueryData(
              'trainingSectionsByModuleId',
              section.trainingModuleId,
              (draft) => [...draft, response.data]
            )
          );
        });
      },
    }),
  }),
});

export const {
  useTrainingSectionsByModuleIdQuery,
  useTrainingSectionsByModuleAndUserIdQuery,
  usePatchTrainingSectionMutation,
  useDuplicateTrainingSectionMutation,
  useDeleteTrainingSectionMutation,
  usePostTrainingSectionMutation,
} = trainingSectionsApi;
