import { Button, Stack } from '@mui/material';
import {
  WorkbaseDarkmodeIcon,
  WorkbaseImageIcon,
  WorkbaseImageRemoveIcon,
  WorkbaseLightmodeIcon,
} from 'src/assets/icons/workbaseIcons';
import { WorkbaseIcon } from 'src/lib/components/atoms/icon/WorkbaseIcon';
import { useEditor } from 'src/features/editor/controller';
import UploadBackgroundImage from './UploadBackgroundImage';

interface EditButtonsProps {
  darkMode: boolean;
  setBackgroundSrc: (src: string) => void;
  backgroundSrc?: string;
}

export default function EditButtons({
  darkMode,
  setBackgroundSrc,
  backgroundSrc,
}: EditButtonsProps) {
  const { handleToggleDarkMode, handleChangeHeaderBackgroundImage } = useEditor();
  const sharedIconStyles = { width: 16, height: 16, color: 'inherit' };
  return (
    <Stack
      className="edit-buttons"
      direction="row"
      gap={2}
      sx={{
        position: 'absolute',
        left: 20,
        bottom: 20,
        visibility: 'hidden',
      }}
    >
      <Button color="inherit" component="label">
        <Stack direction="row" gap={1} alignItems="center">
          <WorkbaseIcon icon={WorkbaseImageIcon} sx={sharedIconStyles} />
          <UploadBackgroundImage
            setBackgroundSrc={setBackgroundSrc}
            headerBackgroundSrc={backgroundSrc || ''}
          />
        </Stack>
      </Button>
      {backgroundSrc && (
        <Button
          color="inherit"
          onClick={() => {
            handleChangeHeaderBackgroundImage('');
            setBackgroundSrc('');
          }}
        >
          <WorkbaseIcon icon={WorkbaseImageRemoveIcon} sx={sharedIconStyles} />
        </Button>
      )}
      <Button color="inherit" onClick={handleToggleDarkMode}>
        <WorkbaseIcon
          icon={darkMode ? WorkbaseDarkmodeIcon : WorkbaseLightmodeIcon}
          sx={sharedIconStyles}
        />
      </Button>
    </Stack>
  );
}
