import { Stack } from '@mui/material';
import { Employee } from 'src/features/employees/model';
import { TrainingSection } from 'src/store/api/trainings/trainingSectionsApi';
import SingleSection from './SingleSection';

interface Props {
  modulesSectionData: TrainingSection[];
  employee: Employee;
  searchVal: string;
  trainingId: string;
  blockAccess?: boolean;
}

export default function SectionList({
  modulesSectionData,
  employee,
  searchVal,
  trainingId,
  blockAccess = false,
}: Props) {
  return (
    <Stack
      sx={{
        '&&& .MuiPaper-root': {
          border: 'none',
          borderTop: ({ palette }) => `1px solid ${palette.grey[100]}`,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          mx: '-20px',
        },
      }}
    >
      {modulesSectionData
        .filter(({ status }) => status === 'published')
        .map((section) => (
          <SingleSection
            blockAccess={blockAccess}
            key={section.id}
            section={section}
            employee={employee}
            searchVal={searchVal}
            trainingId={trainingId}
          />
        ))}
    </Stack>
  );
}
