import { Button, Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LanguageDropdown from 'src/core/LanguageDropdown';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'src/store';
import UnauthorizedLayout from '../../layouts/UnauthorizedLayout';
import { WORKBASE } from '../../lib/constants';
import { AUTH_URL } from '../../global-config';
import { WorkbaseTwoEmployeesIcon } from '../../assets/icons/workbaseIcons';

function UnauthorizedView() {
  const { t } = useTranslation();
  const refreshToken = useAppSelector((state) => state.auth.refreshToken);
  if (refreshToken) {
    return <Navigate to="/" />;
  }
  const redirectionUrl = new URL(`login?redirect=${window.location.hostname}`, `${AUTH_URL}`).href;
  return (
    <UnauthorizedLayout
      favicon={WORKBASE.favicon}
      logo={WORKBASE.logo}
      title={t('unauthorizedPage.title')}
      languageDropdown={<LanguageDropdown />}
    >
      <Stack gap={5} alignItems="center">
        <Stack alignItems="center">
          <SvgIcon fontSize="large">
            <WorkbaseTwoEmployeesIcon />
          </SvgIcon>
          <Typography color="text.primary" align="center" variant="body1" fontWeight="medium">
            {t('unauthorizedPage.title')}
          </Typography>
        </Stack>
        <Button color="primary" size="medium" fullWidth href={redirectionUrl}>
          {t('unauthorizedPage.messageButton')}
        </Button>
      </Stack>
    </UnauthorizedLayout>
  );
}

export default UnauthorizedView;
