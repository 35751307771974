import { Stack } from '@mui/material';
import { TrainingLesson } from 'src/store/api/trainings/trainingLessonsApi';
import SingleLesson from './SingleLesson';

interface Props {
  lessonData: TrainingLesson[];
  trainingId: string;
  trainingModuleId: string;
  blockAccess?: boolean;
}

export default function LessonList({
  lessonData,
  trainingId,
  trainingModuleId,
  blockAccess = false,
}: Props) {
  return (
    <Stack
      gap="10px"
      sx={{
        backgroundColor: 'secondary.background',
        border: ({ palette }) => `1px solid ${palette.grey[100]}`,
        borderRadius: ({ shape }) => shape.borderRadius,
        padding: '12px',
        width: 'calc(100% - 28px)',
        marginLeft: '-6px',
      }}
    >
      {lessonData
        .filter(({ status }) => status === 'published')
        .map((lesson) => (
          <SingleLesson
            blockAccess={blockAccess}
            key={lesson.id}
            lesson={lesson}
            trainingId={trainingId}
            trainingModuleId={trainingModuleId}
          />
        ))}
    </Stack>
  );
}
