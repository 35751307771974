import { Stack, TextField, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePatchSettingsMutation } from 'src/store/api/settingsApi';

interface Props {
  name: string;
  description: string;
}

function SavingTextField({
  label,
  initialVal,
  name,
  validation = () => true,
  dataCy,
  required,
}: {
  label: string;
  initialVal: string;
  name: 'siteName' | 'description';
  validation?: (val: string) => boolean;
  dataCy?: string;
  required?: boolean;
}) {
  const { t } = useTranslation();
  const [patchSettings] = usePatchSettingsMutation();
  const [val, setVal] = useState(initialVal);
  const hasError = !validation?.(val);

  useEffect(() => {
    setVal(initialVal);
  }, [initialVal]);
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setVal(e.target.value);
  };
  const handleBlur = () => {
    if (initialVal !== val && !hasError) patchSettings({ [name]: val });
  };

  return (
    <TextField
      data-cy={dataCy}
      value={val}
      onChange={(e) => handleChange(e)}
      size="small"
      error={hasError}
      helperText={hasError ? t('validationErrorMessages.requiredErrorMsg') : undefined}
      onBlur={handleBlur}
      label={label}
      required={required}
    />
  );
}

export default function InformationSettings({ name, description }: Props) {
  const { t } = useTranslation();
  return (
    <Stack gap={2}>
      <Typography variant="subtitle1" data-cy="site-information-subtitle" fontWeight="medium">
        {t('companySettings.siteInformation.subHeader')}
      </Typography>
      <SavingTextField
        dataCy="site-name-textfield"
        label={t('companySettings.siteInformation.siteDetails.labelSiteName')}
        initialVal={name}
        name="siteName"
        validation={(val) => val !== ''}
        required
      />
      <SavingTextField
        dataCy="site-description-textfield"
        label={t('companySettings.siteInformation.siteDetails.labelLinkDescription')}
        initialVal={description}
        name="description"
      />
    </Stack>
  );
}
