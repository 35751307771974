import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCreateWikiCardMutation } from 'src/store/api/wikiApi.slice';
import { showNotification } from 'src/lib/components/atoms/notification';
import { usePostEmbeddedAppMutation } from 'src/store/api/embeddedAppsApi';
import { usePostPageMutation } from 'src/store/api/pagesApi';
import { usePostTrainingMutation } from 'src/store/api/trainings/trainingsApi';
import { usePostWhiteboardMutation } from 'src/store/api/whiteboardsApi';
import {
  WorkbaseAppIcon,
  WorkbaseEmptyContentPageIcon,
  WorkbaseShareIcon,
  WorkbaseTrainingsIcon,
  WorkbaseWhiteboardIcon,
  WorkbaseWikiIcon,
} from 'src/assets/icons/workbaseIcons';
import usePermissions from '../usePermissions';

interface Props {
  onAddNewEmployeeClick: () => void;
}

interface MenuItem {
  icon: React.ElementType;
  key: string;
  label: string;
  onClick: () => void;
}

export default function useMenuData({ onAddNewEmployeeClick }: Props) {
  const {
    hasManageEmployeePermission,
    hasAddPagesPermission,
    hasAddEmbeddedAppsPermission,
    hasAddTrainingsPermission,
    hasAddWhiteboardsPermission,
    hasAddWikiPermission,
  } = usePermissions();

  const [postWhiteboard] = usePostWhiteboardMutation();
  const [postPage] = usePostPageMutation();
  const [postTraining] = usePostTrainingMutation();
  const [postEmbeddedApp] = usePostEmbeddedAppMutation();
  const [postWiki] = useCreateWikiCardMutation();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const createMenuItem = ({ icon, key, label, onClick }: MenuItem): MenuItem => ({
    icon,
    key,
    label,
    onClick,
  });

  const handlePostAction =
    (postFunction: () => Promise<any>, navigatePath: string): (() => void) =>
    () => {
      postFunction().then((resp: any) => {
        if (!resp.error) {
          navigate(navigatePath.replace(':id', resp.data.id));
          showNotification(t('notifications.addingSuccess'), 'success');
        } else {
          showNotification(t('notifications.addingError'), 'error');
        }
      });
    };

  const buttonMenuData: MenuItem[] = [
    hasAddPagesPermission &&
      createMenuItem({
        icon: WorkbaseEmptyContentPageIcon,
        key: 'add-custom-page',
        label: t('customSites.newCustomSite'),
        onClick: handlePostAction(postPage, 'page/:id/edit'),
      }),
    hasAddEmbeddedAppsPermission &&
      createMenuItem({
        icon: WorkbaseAppIcon,
        key: 'add-embedded-app',
        label: t('sidebar.addButtonMenu.addNewEmbeddedApp'),
        onClick: handlePostAction(postEmbeddedApp, 'embedded/:id/edit'),
      }),
    hasManageEmployeePermission &&
      createMenuItem({
        icon: WorkbaseShareIcon,
        key: 'add-employee',
        label: t('addEmployees.newEmployee'),
        onClick: onAddNewEmployeeClick,
      }),
    hasAddTrainingsPermission &&
      createMenuItem({
        icon: WorkbaseTrainingsIcon,
        key: 'add-training',
        label: t('trainingsPage.newTraining'),
        onClick: handlePostAction(postTraining, '/trainings/:id/edit'),
      }),
    hasAddWikiPermission &&
      createMenuItem({
        icon: WorkbaseWikiIcon,
        key: 'add-wiki',
        label: t('sidebar.addButtonMenu.addNewWiki'),
        onClick: handlePostAction(postWiki, '/wiki/:id/edit'),
      }),
    hasAddWhiteboardsPermission &&
      createMenuItem({
        icon: WorkbaseWhiteboardIcon,
        key: 'add-whiteboard',
        label: t('whiteboards.newWhiteboard'),
        onClick: handlePostAction(postWhiteboard, 'whiteboard/:id/edit'),
      }),
  ].filter(Boolean) as MenuItem[];

  return buttonMenuData;
}
