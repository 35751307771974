import { PaletteMode } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

interface ThemeSettingsState {
  borderRadius: number;
  paletteMode: CustomPaletteMode;
}

type CustomPaletteMode = PaletteMode | 'auto';

const authPersistConfig = {
  key: 'borderRadiusSettings',
  storage,
};

const initialState: ThemeSettingsState = {
  borderRadius: 4,
  paletteMode: 'dark',
};

const themeSettingsSlice = createSlice({
  name: 'themeSettigns',
  initialState,
  reducers: {
    setBorderRadius: (state, action: PayloadAction<number>) => ({
      ...state,
      borderRadius: action.payload,
    }),
    setPaletteMode: (state, action: PayloadAction<CustomPaletteMode>) => ({
      ...state,
      paletteMode: action.payload,
    }),
  },
});

export const { setBorderRadius, setPaletteMode } = themeSettingsSlice.actions;
const themeSettingsReducer = persistReducer(authPersistConfig, themeSettingsSlice.reducer);
export default themeSettingsReducer;
