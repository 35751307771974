import { Stack, SvgIcon, SvgIconProps, Typography, TypographyProps } from '@mui/material';
import { ElementType } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkbaseHelpIcon } from 'src/assets/icons/workbaseIcons';

export interface EmptyProps {
  description?: string;
  icon?: ElementType;
  svgIconProps?: SvgIconProps;
  typographyProps?: TypographyProps;
}

export default function Empty({ description, icon, svgIconProps, typographyProps }: EmptyProps) {
  const { t } = useTranslation();
  return (
    <Stack justifyContent="center" alignItems="center" flexDirection="column" gap={1} py={8}>
      <SvgIcon fontSize="large" {...svgIconProps} component={icon ?? WorkbaseHelpIcon} />
      <Typography variant="h5" {...typographyProps}>
        {description ?? t('placeholder')}
      </Typography>
    </Stack>
  );
}
