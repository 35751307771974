import { useState } from 'react';
import Box from '@mui/material/Box';
import { SvgIcon, Typography, Button, MenuItem } from '@mui/material';
import { StyledMenu } from 'src/lib/components/atoms/action-menu/ActionMenu';
import { allLangs } from '../locales/config-lang';
import useLocales from '../lib/hooks/use-locales';
import { WorkbaseGlobalIcon } from '../assets/icons/workbaseIcons';

function LanguageDropdown() {
  const { onChangeLang, currentLang } = useLocales(true);

  const [isOpen, setOpen] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => setOpen(event.currentTarget);

  const handleClose = () => setOpen(null);

  const changeLanguageHandler = (value: string) => () => {
    onChangeLang(value);
    handleClose();
  };

  const icon = (
    <SvgIcon>
      <WorkbaseGlobalIcon />
    </SvgIcon>
  );

  return (
    <Box>
      <Button onClick={handleOpen} startIcon={icon} variant="text" color="inherit" size="small">
        {currentLang.value.toUpperCase()}
      </Button>
      <StyledMenu id="language-menu" anchorEl={isOpen} onClose={handleClose} open={Boolean(isOpen)}>
        {allLangs.map(({ label, value }) => (
          <MenuItem
            key={value}
            selected={value === currentLang.value}
            onClick={changeLanguageHandler(value)}
          >
            {icon}
            <Typography ml={1} variant="body1" color="text.secondary" fontWeight="bold">
              {label}
            </Typography>
          </MenuItem>
        ))}
      </StyledMenu>
    </Box>
  );
}
export default LanguageDropdown;
