import { Box, TextField } from '@mui/material';
import validateUrl from 'src/lib/utils/validateUrl';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ensureHttpProtocol from 'src/lib/utils/ensureHttpProtocol';
import { useEmbeddedAppQuery, usePatchEmbeddedAppMutation } from 'src/store/api/embeddedAppsApi';
import IconOrEmojiSelector from 'src/ui-components/custom/icon-selector/IconOrEmojiSelector';
import useDebouncedFunction from 'src/ui-components/custom/textfield/hooks/use-debounced-value';
import EmbeddedLinkValidationTextField, {
  ValidationResult,
} from './EmbeddedLinkValidationTextField';

interface Props {
  id: string;
}

function EmbeddedAppInputs({ id }: Props) {
  const { t } = useTranslation();
  const { data } = useEmbeddedAppQuery(id);
  const [patchEmbeddedApp] = usePatchEmbeddedAppMutation();
  const [name, setName] = useState(data?.name || '');
  const dispatch = useDispatch<any>();
  useEffect(() => {
    if (data?.name) {
      setName(data.name);
    }
  }, [data?.name, dispatch]);

  const onSubmitUrlInput = useCallback(
    async (val: string): Promise<ValidationResult> => {
      try {
        const resp: any = await patchEmbeddedApp({ id, url: ensureHttpProtocol(val) });

        if (resp?.error) {
          return { isError: true, message: t('cantBeEmbedded') };
        }
        return { isError: false };
      } catch (error) {
        return { isError: true, message: t('invalidURL') };
      }
    },
    [patchEmbeddedApp, id, t]
  );

  const handleValidateFunction = (str: string) => ({
    isError: !validateUrl(str),
    message: !validateUrl(str) ? t('invalidURL') : '',
  });

  const debouncedPatchName = useDebouncedFunction((newName: string) => {
    if (newName !== data?.name) {
      patchEmbeddedApp({ id, name: newName });
    }
  }, 500);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setName(newName);
    debouncedPatchName(newName);
  };

  return (
    <>
      {data && (
        <IconOrEmojiSelector
          value={data?.icon}
          onChange={(i) => {
            patchEmbeddedApp({ id, icon: i });
          }}
        />
      )}
      <Box
        width={({ breakpoints }) => breakpoints.values.sm}
        minWidth={0}
        display="flex"
        flexDirection="row"
        gap={1}
      >
        <TextField
          autoFocus
          value={name}
          placeholder={t('embeddedAppPage.nameTextFieldPlaceholder')}
          size="small"
          fullWidth
          onChange={handleNameChange}
        />
        <EmbeddedLinkValidationTextField
          placeholder={t('embeddedAppPage.urlTextFieldPlaceholder')}
          value={data?.url || ''}
          validateFn={handleValidateFunction}
          onSubmit={onSubmitUrlInput}
        />
      </Box>
    </>
  );
}

export default EmbeddedAppInputs;
