import { EditorProvider } from '../controller';
import Editor, { EditorProps } from './Editor';

export default function EditorWithContextProvider({ documentName, ...otherProps }: EditorProps) {
  return (
    <EditorProvider key={documentName}>
      <Editor documentName={documentName} {...otherProps} />
    </EditorProvider>
  );
}
