export enum PermissionCodes {
  MANAGE_ALL_COMPANY_SETTINGS = 'MANAGE_ALL_COMPANY_SETTINGS',
  MANAGE_DEPARTMENTS = 'MANAGE_DEPARTMENTS',
  MANAGE_PERMISSIONS = 'MANAGE_PERMISSIONS',
  MANAGE_BILLING = 'MANAGE_BILLING',

  ADMIN_ACCESS_DASHBOARD_AND_PAGES = 'ADMIN_ACCESS_DASHBOARD_AND_PAGES',
  ADMIN_ACCESS_EMBEDDED_APPS = 'ADMIN_ACCESS_EMBEDDED_APPS',
  ADMIN_ACCESS_WIKIS = 'ADMIN_ACCESS_WIKIS',
  // ADMIN_ACCESS_NEWS = 'ADMIN_ACCESS_NEWS',
  // ADMIN_ACCESS_SURVEYS = 'ADMIN_ACCESS_SURVEYS',
  ADMIN_ACCESS_TRAININGS = 'ADMIN_ACCESS_TRAININGS',
  ADMIN_ACCESS_WHITEBOARDS = 'ADMIN_ACCESS_WHITEBOARDS',

  MANAGE_EMPLOYEES = 'MANAGE_EMPLOYEES',
  VIEW_EMPLOYEES = 'VIEW_EMPLOYEES',
  VIEW_EMPLOYEES_DETAILED_TRAINING_PROGRESS = 'VIEW_EMPLOYEES_DETAILED_TRAINING_PROGRESS',

  ADD_PAGES = 'ADD_PAGES',
  ADD_EMBEDDED_APPS = 'ADD_EMBEDDED_APPS',
  ADD_WIKIS = 'ADD_WIKIS',
  // ADD_NEWS = 'ADD_NEWS',
  // ADD_SURVEYS = 'ADD_SURVEYS',
  ADD_TRAININGS = 'ADD_TRAININGS',
  ADD_WHITEBOARDS = 'ADD_WHITEBOARDS',
}

export type PermissionCode = keyof typeof PermissionCodes;
