import { Stack } from '@mui/material';
import { Employee } from 'src/features/employees/model';
import {
  TrainingsProgress,
  DetailedTrainingWithAccess,
} from 'src/store/api/trainings/trainingsApi';
import SingleTraining from './SingleTraining';

interface Props {
  trainingsProgress: TrainingsProgress[];
  trainingsDetailes: DetailedTrainingWithAccess[];
  employee: Employee;
  searchVal: string;
}

export default function TrainingList({
  trainingsProgress,
  trainingsDetailes,
  employee,
  searchVal,
}: Props) {
  return (
    <Stack gap={2.5}>
      {trainingsDetailes
        .filter(({ status }) => status === 'published')
        .map((training) => (
          <SingleTraining
            key={training.id}
            training={training}
            trainingsProgress={trainingsProgress}
            employee={employee}
            searchVal={searchVal}
          />
        ))}
    </Stack>
  );
}
